import { useEffect, useMemo, useState } from 'react'

import {
  useGetValorantLeaderboardSnapshotQuery,
  useGetValorantMatchesQuery,
  ValorantAgent,
  ValorantMatchDiscoveryPreviewFragment,
} from '__generated__'
import { groupBy, sortBy } from 'lodash'

export const hashRisingGod = (
  channelId: string,
  start: string,
  end: string
) => {
  // hash these keys to get a unique key for the cache with base64 encoding
  return Buffer.from(`${channelId}${start}${end}`).toString('base64')
}

export interface ChannelInfo {
  id: string
  profileImageUrl: string
  name: string
  accountId: string
}

export interface Stats {
  startRank: number
  endRank: number
  rankChange: number
  numWins: number
  numGames: number
  winPercent: number
}

export interface AgentStats {
  agent: string
  numWins: number
  numLosses: number
  numGames: number
  kills: number
  deaths: number
}

export interface MatchPlayerData {
  matchId: string
  rank?: number
  win: boolean
  agent: ValorantAgent
  isMVP: boolean
  isTeamMVP: boolean
  kills: number
  deaths: number
  position: number
  start: string
  end: string
}

export const useGetValorantRankedStats = (
  userId: string,
  startTime: string,
  endTime: string,
  rankAfterMatch: Record<string, number>
) => {
  const [finishedFetching, setFinishedFetching] = useState(false)
  const [allMatchData, setAllMatchData] = useState<
    ValorantMatchDiscoveryPreviewFragment[]
  >([])
  const [cursor, setCursor] = useState<string | null>(null)
  const { data: startResult } = useGetValorantLeaderboardSnapshotQuery({
    variables: {
      twitchUserIds: [userId],
      snapshotTime: startTime,
    },
  })
  const { data: endResult } = useGetValorantLeaderboardSnapshotQuery({
    variables: {
      twitchUserIds: [userId],
      snapshotTime: endTime,
    },
  })

  // const { data: videosData } = useGetTwitchVideosQuery({
  //   variables: {
  //     userId,
  //     start: startTime,
  //     end: endTime,
  //   },
  // })

  const startData = useMemo(() => {
    return startResult?.valorantLeaderboard?.data.find(
      (item) => item.account?.channel?.id === userId
    )
  }, [startResult?.valorantLeaderboard?.data, userId])
  const endData = useMemo(() => {
    return endResult?.valorantLeaderboard?.data.find(
      (item) => item.account?.channel?.id === userId
    )
  }, [endResult?.valorantLeaderboard?.data, userId])

  const { data: matchesData } = useGetValorantMatchesQuery({
    variables: {
      playerAccountIds: [startData?.account?.id ?? ''],
      start: startTime,
      end: endTime,
      after: cursor,
      first: 100,
    },
    skip: startData?.account?.id === undefined,
  })

  useEffect(() => {
    if (!matchesData?.valorantMatches) return
    setAllMatchData((prev) => [
      ...prev,
      ...(matchesData.valorantMatches?.data ?? []),
    ])
    if (matchesData?.valorantMatches?.pagination?.hasNextPage) {
      setCursor(matchesData?.valorantMatches?.pagination?.endCursor ?? null)
    } else {
      setFinishedFetching(true)
    }
  }, [matchesData?.valorantMatches])

  const matchPlayerData = useMemo(() => {
    return allMatchData.map((match) => {
      const player = match.players.find(
        (player) => player.account?.id === startData?.account?.id
      )
      const sortedPlayers = sortBy(match.players, 'score').reverse()
      const isMVP = sortedPlayers[0].account?.id === player?.account?.id
      const teammates = match.players.filter((p) => p.team === player?.team)
      const isTeamMVP = teammates[0].account?.id === player?.account.id
      const position =
        sortedPlayers.findIndex((p) => p.account?.id === player?.account?.id) +
        1

      return {
        matchId: match.id,
        win: match.winningTeam === player?.team,
        agent: player?.agent,
        isMVP,
        isTeamMVP,
        position,
        stats: player?.stats,
        start: match.startedAt,
        end: match.endedAt ?? '',
        rank: rankAfterMatch[match.id],
      }
    })
  }, [allMatchData, rankAfterMatch, startData?.account?.id])

  const agentStats = useMemo(() => {
    if (!matchPlayerData) return undefined
    const agentGroups = groupBy(matchPlayerData, 'agent')
    const stats = Object.entries(agentGroups).map(([agent, matches]) => {
      const numWins = matches.filter((match) => match.win).length
      const numLosses = matches.length - numWins
      const numGames = matches.length
      const kills = matches.reduce((acc, match) => acc + match.stats?.kills, 0)
      const deaths = matches.reduce(
        (acc, match) => acc + match.stats?.deaths,
        0
      )
      return {
        agent,
        numWins,
        numLosses,
        numGames,
        kills,
        deaths,
      }
    })
    return sortBy(stats, 'numGames').reverse()
  }, [matchPlayerData])

  const parsedMatchData = useMemo(() => {
    if (!matchPlayerData) return undefined
    return sortBy(
      matchPlayerData.map((match) => {
        return {
          matchId: match.matchId,
          win: match.win,
          agent: match.agent ?? ValorantAgent.Unknown,
          position: match.position,
          isMVP: match.isMVP,
          isTeamMVP: match.isTeamMVP,
          kills: match.stats?.kills ?? 0,
          deaths: match.stats?.deaths ?? 0,
          start: match.start,
          end: match.end,
          rank: match.rank,
        }
      }),
      'end'
    )
  }, [matchPlayerData])

  const channelInfo: ChannelInfo = useMemo(() => {
    return {
      id: startData?.account?.channel?.id ?? '',
      profileImageUrl: startData?.account?.channel?.profileImageUrl ?? '',
      name: startData?.account?.channel?.name ?? '',
      accountId: startData?.account?.id ?? '',
    }
  }, [startData])

  const stats = useMemo(() => {
    if (!startData || !endData || allMatchData.length === 0) return undefined
    const numWins = parsedMatchData?.filter((match) => match.win).length ?? 0
    const numGames = allMatchData.length
    return {
      startRank: startData.leaderboardRank,
      endRank: endData.leaderboardRank,
      rankChange: startData.leaderboardRank - endData.leaderboardRank,
      numWins,
      numGames,
      winPercent: Math.round((numWins / numGames) * 100),
    }
  }, [allMatchData.length, endData, parsedMatchData, startData])

  return useMemo(
    () => ({
      channelInfo,
      stats,
      agentStats,
      matchPlayerData: parsedMatchData,
      finishedFetching,
    }),
    [agentStats, channelInfo, finishedFetching, parsedMatchData, stats]
  )
}
