import { Button } from 'components/core/button'
import { Content, HStack, VStack } from 'components/core/layout'
import { useMemo } from 'react'
import { useQueryParamFilters } from 'utils/browser'
import { VideoSegments } from './activations/video-segment'

export const GamesActivationPage = () => {
  const { params, setParam } = useQueryParamFilters()

  const currentPage = useMemo(() => {
    const pageStr = params['page']
    return pageStr && typeof pageStr === 'string' ? parseInt(pageStr, 10) : 0
  }, [params])
  const gameId = '518873'
  const channelIds = [
    '156037856',
    '21390470',
    '71672341',
    '25575995',
    '61294188',
    '26513896',
    '62300805',
    '43494917',
    '55943318',
    '94432456',
    '210983926',
    '48827570',
    '412027243',
    '58506546',
    '145574736',
    '64140092',
    '48201326',
    '9173550',
    '29158331',
    '36066935',
    '39067173',
    '39317849',
    '49456129',
    '50911855',
    '52858375',
    '56331611',
    '69214305',
    '111971097',
    '128856353',
    '186818440',
    '239474978',
    '599726412',
  ]
  // startTime November 30th 1am PST in iso format
  const startTime = '2022-11-28T08:00:00.000Z'
  // endTime December 3rd 11:59pm PST in iso format
  const endTime = '2022-12-05T07:59:00.000Z'

  return (
    <Content>
      <VStack>
        <div>
          {channelIds
            .slice(currentPage * 5, currentPage * 5 + 5)
            .map((channelId) => {
              return (
                <VideoSegments
                  channelId={channelId}
                  gameId={gameId}
                  startTime={startTime}
                  endTime={endTime}
                />
              )
            })}
        </div>
        <HStack>
          <Button
            onClick={() => {
              const nextPage = currentPage + 1
              setParam('page', nextPage === 0 ? undefined : nextPage.toString())
            }}
          >
            Next
          </Button>
        </HStack>
      </VStack>
    </Content>
  )
}
