import { useCallback, useEffect, useMemo } from 'react'

import { CloseModalButton } from 'components/close-modal/close-button'
import { HStack, Spacing } from 'components/core/layout'
import { Text } from 'components/core/text'
import { Portal } from 'components/dialog/portal'
import { useComposerState } from 'core/composer/context'
import {
  ExportFormat,
  ExportTemplate,
  IG_FORMAT,
  IG_SQUARE_FORMAT,
  SAMPLE_TEMPLATES,
  STANDARD_FORMAT,
  TIKTOK_FORMAT,
} from 'core/exporter/constants'
import {
  Backdrop,
  PreviewModalRoot,
} from 'core/feed/components/preview-modal/styled'
import { SAVE_LEVELS } from 'core/shared/templates/constants'
import { getTemplatesFromLocalStorage } from 'core/shared/templates/helpers'

import {
  ModalContainerDiv,
  ModalContentDiv,
  TemplateContainerDiv,
} from './styled'
import { TemplatePreview } from './preview'
import { randomUniqueId } from 'utils'

interface TemplatePreviewProps {
  format: ExportFormat
  template?: ExportTemplate
  setTemplate: (nextTemplate: ExportTemplate | undefined) => void
  close: () => void
}

export const TemplatePresets: React.FC<TemplatePreviewProps> = ({
  format,
  template,
  setTemplate,
  close,
}) => {
  const { momentId, primaryVideoId } = useComposerState()
  const templateOptions = useMemo(() => {
    const hardcoded = SAMPLE_TEMPLATES.filter(
      (temp) => temp.format.id === format.id
    )
    const momentLevel = getTemplatesFromLocalStorage(
      format.id,
      SAVE_LEVELS.MOMENT,
      momentId
    )
    const videoLevel = getTemplatesFromLocalStorage(
      format.id,
      SAVE_LEVELS.VIDEO,
      primaryVideoId
    )
    const globalLevel = getTemplatesFromLocalStorage(
      format.id,
      SAVE_LEVELS.GLOBAL
    )
    return [...hardcoded, ...momentLevel, ...videoLevel, ...globalLevel]
  }, [format.id, momentId, primaryVideoId])

  const selectTemplate = (nextTemplate: ExportTemplate) => {
    setTemplate(nextTemplate)
    close()
  }

  useEffect(() => {
    if (template === undefined && templateOptions.length > 0) {
      setTemplate(templateOptions[0])
    }
  }, [setTemplate, template, templateOptions])

  return (
    <Portal>
      <PreviewModalRoot>
        <Backdrop $opacity={0.5} />
        <ModalContainerDiv>
          <Text variant="h5" textAlign="center">
            Import a Preset Template
          </Text>
          <ModalContentDiv>
            <Spacing top={32}>
              <HStack
                gap={12}
                flexWrap="wrap"
                alignItems="center"
                justifyContent="center"
              >
                {templateOptions.map((templateOption, idx) => (
                  <TemplateOption
                    key={idx}
                    template={templateOption}
                    format={format}
                    setTemplate={selectTemplate}
                  />
                ))}
              </HStack>
            </Spacing>
          </ModalContentDiv>

          <CloseModalButton onClick={close} />
        </ModalContainerDiv>
      </PreviewModalRoot>
    </Portal>
  )
}

interface TemplateOptionProps {
  format: ExportFormat
  template: ExportTemplate
  setTemplate: (nextTemplate: ExportTemplate) => void
}

const WIDTHS = {
  [TIKTOK_FORMAT.id]: 120,
  [IG_SQUARE_FORMAT.id]: 200,
  [IG_FORMAT.id]: 200,
  [STANDARD_FORMAT.id]: 200,
}
export const TemplateOption = ({
  template,
  format,
  setTemplate,
}: TemplateOptionProps) => {
  const formatWidth = useMemo(() => WIDTHS[format.id], [format.id])
  const containerDimensions = useMemo(
    () => ({
      height:
        (format.aspectRatio.height / format.aspectRatio.width) * formatWidth,
      width: formatWidth,
    }),
    [format.aspectRatio.height, format.aspectRatio.width, formatWidth]
  )

  const selectTemplateOption = useCallback(() => {
    // set localIds for crops
    const nextTemplate = {
      ...template,
      crops: template.crops.map((crop, idx) => ({
        ...crop,
        localId: randomUniqueId(),
        primary: idx === 0,
      })),
    }
    setTemplate(nextTemplate)
  }, [setTemplate, template])

  return (
    <TemplateContainerDiv
      style={containerDimensions}
      onClick={selectTemplateOption}
    >
      <TemplatePreview template={template} />
    </TemplateContainerDiv>
  )
}
