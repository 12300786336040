import { SVGProps } from 'react'
const Logo = ({
  width = '100',
  height = '22',
  color,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 100 22"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M78.8358 20.3867C80.127 21.4622 81.8682 22 84.0594 22C85.468 22 86.7005 21.7458 87.757 21.2373C88.833 20.7289 89.6644 20.0151 90.2514 19.096C90.8383 18.1573 91.1317 17.0818 91.1317 15.8693C91.1317 14.2462 90.6818 12.8871 89.7818 11.792C88.8819 10.6773 87.5809 9.90489 85.8788 9.47467L83.3257 8.77067C82.5627 8.55556 81.9758 8.19378 81.565 7.68533C81.1737 7.15733 80.9781 6.52178 80.9781 5.77867C80.9781 4.97689 81.2324 4.35111 81.7411 3.90133C82.2693 3.432 83.0225 3.19733 84.0007 3.19733C84.9984 3.19733 85.7712 3.44178 86.319 3.93067C86.8668 4.4 87.1407 5.05511 87.1407 5.896H90.8089C90.7894 4.09689 90.1731 2.66933 88.9601 1.61333C87.7472 0.537777 86.1038 0 84.03 0C82.6606 0 81.4672 0.244444 80.4498 0.733333C79.4521 1.22222 78.6793 1.91644 78.1315 2.816C77.5837 3.71555 77.3098 4.77156 77.3098 5.984C77.3098 7.568 77.7598 8.91733 78.6597 10.032C79.5792 11.1467 80.8802 11.9191 82.5627 12.3493L85.0278 13.024C85.7908 13.2391 86.3875 13.6107 86.8179 14.1387C87.2483 14.6667 87.4635 15.2924 87.4635 16.016C87.4635 16.8764 87.1603 17.5511 86.5538 18.04C85.9669 18.5289 85.1452 18.7733 84.0887 18.7733C82.9931 18.7733 82.1226 18.5289 81.4769 18.04C80.8509 17.5316 80.5379 16.8373 80.5379 15.9573H76.8696C76.8892 17.8151 77.5446 19.2916 78.8358 20.3867Z" />
    <path d="M40.0635 21.7067V0.293333H44.5534L50.5106 17.6C50.4519 16.9742 50.3933 16.2409 50.3346 15.4C50.2759 14.5396 50.2172 13.6693 50.1585 12.7893C50.1194 11.9093 50.0998 11.1271 50.0998 10.4427V0.293333H53.4452V21.7067H48.9553L43.0568 4.4C43.0959 4.96711 43.1448 5.65156 43.2035 6.45333C43.2622 7.25511 43.3111 8.07644 43.3503 8.91733C43.3894 9.75822 43.4089 10.5307 43.4089 11.2347V21.7067H40.0635Z" />
    <path d="M63.5287 21.7067V3.696H57.9237V0.293333H72.8314V3.696H67.2263V21.7067H63.5287Z" />
    <path d="M0 21.7067V0.293333H4.34319L6.45609 7.09867C6.65173 7.78311 6.80824 8.40889 6.92562 8.976C7.06257 9.52356 7.15061 9.93422 7.18974 10.208C7.22886 9.93422 7.30712 9.52356 7.4245 8.976C7.56145 8.40889 7.73752 7.78311 7.95273 7.09867L9.97759 0.293333H14.3208V21.7067H10.8873V5.03378L8.74507 12.496H5.54637L3.43347 5.28174V21.7067H0Z" />
    <path d="M19.797 0.293333V21.7067H23.2305V5.28174L25.3434 12.496H28.5421L30.6843 5.03378V21.7067H34.1178V0.293333H29.7746L27.7497 7.09867C27.5345 7.78311 27.3584 8.40889 27.2215 8.976C27.1041 9.52356 27.0259 9.93422 26.9867 10.208C26.9476 9.93422 26.8596 9.52356 26.7226 8.976C26.6052 8.40889 26.4487 7.78311 26.2531 7.09867L24.1402 0.293333H19.797Z" />
    <path d="M97.3322 16.3733C95.8594 16.3733 94.6644 17.5672 94.6644 19.04C94.6644 20.5127 95.8594 21.7067 97.3322 21.7067C98.805 21.7067 100 20.5127 100 19.04C100 17.5672 98.805 16.3733 97.3322 16.3733Z" />
  </svg>
)

export default Logo
