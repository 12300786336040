import { useMemo } from 'react'
import { Route, Switch, useHistory, useParams } from 'react-router'
import styled from 'styled-components/macro'

import { Content, HStack } from 'components/core/layout'
import { Spinner } from 'components/core/spinner'

import { RosterVideoList } from './video-list'
import { RosterList } from './sidebar'
import { RecentMoments } from './recent-moments'
import { ChannelHeading } from './channel-heading'
import { useGetChannelInfoQuery } from '__generated__'
import { VideoDetailPage } from './video-detail'
import { QuickPreviewModal } from './preview-modal'
import { VideoSection } from './videos'
import { SavedMoments } from './saved'

export const RosterPage = () => {
  const { channelName } = useParams<{ channelName?: string }>()
  const { location, goBack, replace } = useHistory()
  const { data } = useGetChannelInfoQuery({
    variables: { channelName: channelName ?? '' },
    skip: channelName === undefined,
  })
  const hasHistory = useMemo(() => {
    const state = location?.state as { referrer: string } | undefined
    const referrer = state?.referrer as any
    return referrer !== undefined
  }, [location?.state])

  return (
    <Content background={'secondary'}>
      <RosterPageContainerDiv>
        <RosterList selectedChannelName={channelName} />
        <RosterVideosDiv>
          {data?.channel ? (
            <>
              <ChannelHeading channelName={channelName ?? ''} />
              <Switch>
                <Route exact={true} path="/roster/:channelName/videos">
                  <VideoSection />
                </Route>
                <Route path="/roster/:channelName/saved">
                  <SavedMoments channelId={data.channel.id} />
                </Route>
                <Route path="/roster/:channelName/videos/:videoId">
                  <VideoDetailPage channelId={data.channel.id} />
                </Route>
                <Route path="/roster/:channelName">
                  <RecentMoments channelId={data.channel.id} />
                  <RosterVideoList channelId={data.channel.id} />
                </Route>
              </Switch>
              <Route path="/roster/:channelName/moment/:signalId">
                <QuickPreviewModal
                  onClose={() =>
                    hasHistory ? goBack() : replace(`/roster/${channelName}`)
                  }
                />
              </Route>
            </>
          ) : (
            <HStack fullHeight alignItems="center" justifyContent="center">
              <Spinner size={32}></Spinner>
            </HStack>
          )}
        </RosterVideosDiv>
      </RosterPageContainerDiv>
    </Content>
  )
}

const RosterPageContainerDiv = styled.div`
  position: relative;
  width: 100%;
  display: grid;
  grid-template-areas: 'roster-sidebar roster-videos';
  grid-template-columns: 300px 804px;
  column-gap: 32px;
`

const RosterVideosDiv = styled.div`
  grid-area: roster-videos;
  display: flex;
  flex-direction: column;
  gap: 16px;
`
