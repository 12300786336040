import { Content } from 'components/core/layout'
import { ChatSignalGridItem } from 'core/feed/multi-feed'
import { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { GridContainer } from 'page/feed/styles'
import { useQueryParamFilters } from 'utils/browser'
import { useGetMomentsFromVideoSectionQuery } from '__generated__'
import { Select } from 'components/core/select'

enum SortOptions {
  BY_POPULARITY = 'byPopularity',
  BY_TIME = 'byTime',
}

export const VideoSubsectionPage = () => {
  const { channelId, startTime, endTime } = useGetMomentData()
  const { data } = useGetMomentsFromVideoSectionQuery({
    variables: { channelId, startTime, endTime },
  })
  const [selectedSortOption, setSelectedSortOption] = useState(
    SortOptions.BY_POPULARITY
  )

  const sortedData = useMemo(() => {
    if (
      selectedSortOption === SortOptions.BY_POPULARITY &&
      data?.moments?.data
    ) {
      const sortedData = [...data?.moments?.data].sort((a, b) => {
        if (a.__typename === 'ChatMoment' && b.__typename === 'ChatMoment') {
          return b.metadata.score - a.metadata.score
        }
        return 0
      })
      return sortedData
    } else {
      return data?.moments?.data
    }
  }, [data?.moments?.data, selectedSortOption])

  return (
    <Content>
      <label>
        Sort by:
        <Select
          value={selectedSortOption}
          onChange={(e) => setSelectedSortOption(e.target.value as SortOptions)}
        >
          <option value={SortOptions.BY_POPULARITY}>By Popularity</option>
          <option value={SortOptions.BY_TIME}>By Time</option>
        </Select>
      </label>
      <GridContainer $paddingBottom={16}>
        {sortedData?.map((sigData) => {
          if (sigData) {
            return (
              <Link
                key={sigData.id}
                to={{
                  pathname: `/review/${sigData.id}`,
                  state: { referrer: 'feed' },
                }}
              >
                <ChatSignalGridItem key={sigData.id} momentId={sigData.id} />
              </Link>
            )
          }
          return null
        })}
      </GridContainer>
    </Content>
  )
}

const useGetMomentData = () => {
  const { params } = useQueryParamFilters()
  const channelId = Array.isArray(params.channelId)
    ? params.channelId[0]
    : params.channelId
  const startTime = Array.isArray(params.startTime)
    ? params.startTime[0]
    : params.startTime
  const endTime = Array.isArray(params.endTime)
    ? params.endTime[0]
    : params.endTime

  return useMemo(
    () => ({ channelId, startTime, endTime }),
    [channelId, endTime, startTime]
  )
}
