import { Rect } from 'core/exporter/constants'

export const cropRectInPx = (
  containerRect: DOMRect | undefined,
  cropRect: Rect
) => {
  // container should always be set, set things to 1 so I don't accidentally divide by 0 if not
  if (!containerRect) return { x: 1, y: 1, width: 1, height: 1 }
  const x = containerRect.width * cropRect.x
  const y = containerRect.height * cropRect.y
  const width = containerRect.width * cropRect.width
  const height = containerRect.height * cropRect.height

  return {
    x,
    y,
    width,
    height,
  }
}
