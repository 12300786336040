import React, { useMemo } from 'react'
import { MultiMomentFragment, useGetEditingVideoQuery } from '__generated__'
import { MultiMomentsSidebarItem } from './item'

interface MultiMomentsSidebarProps {
  videoId: string
}

export const MultiMomentsSidebarList: React.FC<MultiMomentsSidebarProps> = ({
  videoId,
}) => {
  const { moments } = useMultiMomentsSidebarData(videoId)

  return (
    <div>
      {moments?.map((m) => (
        <MultiMomentsSidebarItem moment={m} />
      ))}
    </div>
  )
}

const useMultiMomentsSidebarData = (videoId: string) => {
  const { data, loading } = useGetEditingVideoQuery({
    variables: { id: videoId },
  })

  const multiMoments = useMemo(
    () =>
      (data?.video?.moments.data.filter(
        ({ __typename }) => __typename === 'MultiPerspectiveMoment'
      ) ?? []) as MultiMomentFragment[],
    [data?.video?.moments.data]
  )

  return useMemo(
    () => ({ moments: multiMoments, loading }),
    [loading, multiMoments]
  )
}
