import { useEffect, useMemo } from 'react'

import { ValorantMatchEditor } from 'core/valorant-match/editor'
import { ValorantMatchEditorProvider } from 'core/valorant-match/editor/context'
import { useGetSignalDetailQuery } from '__generated__'
import { useValorantMatchPregameOffset } from 'core/shared/valorant/hooks'
import { EditorProvider } from 'core/editor/context'
import { TimelineProvider } from 'core/editor/timeline/context'
import { VideoSyncProvider } from 'core/sync/context'

import { MetaScoreboard } from './components/scoreboard'
import { useSignalReviewState } from './context'
import { useSignalData, useSignalReviewHelpers } from './helpers'
// import { MomentQuickActionsModal } from './controls/actions-modal'
import { Controls } from './controls'
import { Sidebar } from './sidebar'
import { ExportView } from './exports'
import {
  ControlsDiv,
  MetadataSidebarDiv,
  PlayerDiv,
  ValorantMatchOverviewDiv,
} from './styled'
import { Route, Switch, useRouteMatch } from 'react-router'
import { RelatedVideoSelectorModal } from './controls/related-modal'

export const ValorantMultiKillSignalReview = () => {
  const { path } = useRouteMatch()
  const { signalId, showRelatedVideoModal } = useSignalReviewState()
  // fetch moment here
  const signalData = useSignalData(signalId)
  const { setSelectedRelatedVideoIds } = useSignalReviewHelpers()
  const connectedMoment = useMemo(
    () => signalData?.data.connectedMoments[0],
    [signalData]
  )

  useEffect(() => {
    setSelectedRelatedVideoIds(
      signalData?.data.relatedVideoData.map(({ id }) => id) ?? []
    )
  }, [setSelectedRelatedVideoIds, signalData?.data.relatedVideoData])

  return signalData && signalData.type === 'ValorantMoment' ? (
    <EditorProvider>
      <TimelineProvider>
        <ControlsDiv>
          <Controls />
        </ControlsDiv>
        <Switch>
          <Route path={`${path}`} exact>
            <ValorantMatchOverviewDiv>
              <PlayerDiv>
                {/* This defaults to connected moment if it has it, otherwise it needs to use the signal data which still needs to calculate offsets with matchId + videoId */}
                {connectedMoment && signalData.data.valorantMatchId ? (
                  <ValorantMatchEditorProvider>
                    <ValorantMatchEditor
                      userId={signalData.data.userId}
                      matchId={signalData.data.valorantMatchId}
                      start={new Date(connectedMoment.startsAt).getTime()}
                      end={new Date(connectedMoment.endsAt).getTime()}
                    ></ValorantMatchEditor>
                  </ValorantMatchEditorProvider>
                ) : signalData.data.valorantMatchId ? (
                  <ValorantMatchEditorWithOffset
                    userId={signalData.data.userId}
                    videoId={signalData.data.videoId}
                    matchId={signalData.data.valorantMatchId}
                    start={signalData.data.signalStartTime}
                    end={signalData.data.signalEndTime}
                  ></ValorantMatchEditorWithOffset>
                ) : null}
              </PlayerDiv>
              <MetadataSidebarDiv>
                <Sidebar>
                  {signalData.data.valorantMatchId && (
                    <MetaScoreboard
                      key={signalData.data.valorantMatchId}
                      matchId={signalData.data.valorantMatchId}
                    />
                  )}

                  {/* <ValorantRelatedVideoControls /> */}
                </Sidebar>
              </MetadataSidebarDiv>
            </ValorantMatchOverviewDiv>
          </Route>

          <Route path={`${path}/exports`} exact>
            {connectedMoment && (
              <VideoSyncProvider>
                <ExportView momentId={connectedMoment.id} />
              </VideoSyncProvider>
            )}
          </Route>
        </Switch>
        {showRelatedVideoModal && <RelatedVideoSelectorModal />}
        {/* {showMomentActionsModal && <MomentQuickActionsModal />} */}
      </TimelineProvider>
    </EditorProvider>
  ) : null
}

interface Props {
  matchId: string
  userId: string
  videoId: string
  start: number
  end: number
}
export const ValorantMatchEditorWithOffset = ({
  matchId,
  userId,
  videoId,
  start,
  end,
}: Props) => {
  const offsetTimes = useGetMomentOffset(matchId, videoId, start, end)
  return offsetTimes ? (
    <ValorantMatchEditorProvider>
      <ValorantMatchEditor
        userId={userId}
        matchId={matchId}
        start={offsetTimes.start}
        end={offsetTimes.end}
      ></ValorantMatchEditor>
    </ValorantMatchEditorProvider>
  ) : null
}

export const useValorantSignalData = (chatMomentId: string) => {
  const { data, loading } = useGetSignalDetailQuery({
    variables: { id: chatMomentId },
  })

  const signalData = useMemo(() => {
    if (loading || data?.moment?.__typename !== 'ValorantMoment') {
      return undefined
    } else {
      const valorantSignal = data.moment

      const videoId = valorantSignal.video.id
      const signalStartTime = new Date(valorantSignal.startsAt).getTime()
      const signalEndTime = new Date(valorantSignal.endsAt).getTime()

      // filter for overlapping related videos
      const relatedVideoIds = valorantSignal.video.relatedVideos
        .filter((relatedVid) => {
          return relatedVid.overlaps.some((overlap) => {
            return (
              signalStartTime > new Date(overlap.from).getTime() &&
              signalEndTime < new Date(overlap.to).getTime()
            )
          })
        })
        .map((relatedVid) => relatedVid.video.id)

      // look for ValorantMatch
      const valorantMatchId = valorantSignal.match.id

      const userId = valorantSignal.channel.id
      const kills = valorantSignal.kills
      const connectedMoments = valorantSignal.usedIn

      return {
        userId,
        videoId,
        signalStartTime,
        signalEndTime,
        relatedVideoIds,
        valorantMatchId,
        kills,
        connectedMoments,
      }
    }
  }, [data?.moment, loading])

  return useMemo(() => signalData, [signalData])
}

const useGetMomentOffset = (
  matchId: string,
  videoId: string,
  momentStart: number,
  momentEnd: number
) => {
  const pregameOffset = useValorantMatchPregameOffset(matchId, videoId)

  return useMemo(() => {
    if (pregameOffset) {
      return {
        start: momentStart + pregameOffset,
        end: momentEnd + pregameOffset,
      }
    } else {
      return {
        start: momentStart,
        end: momentEnd,
      }
    }
  }, [momentEnd, momentStart, pregameOffset])
}
