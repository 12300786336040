import React, { useMemo } from 'react'

import { Text } from 'components/core/text'
import { useComposerState } from 'core/composer/context'
import { useEditorState } from 'core/editor/context'
import { ValorantAgent } from '__generated__'

import { KillsByPlayer, useGetKD, DeathsByPlayer, useKDAData } from './hooks'
import {
  AgentImg,
  ContentsDiv,
  PlayerDisplayScaledContainer,
  PositioningContainerDiv,
} from './styled'
import { AddonContainer } from './styled'
import { AGENT_THUMB_IMAGES } from 'components/valorant/agent'
import { STANDARD_FORMAT } from 'core/exporter/constants'

export const KDAWithComposerContext = () => {
  const { selection, currentTime } = useEditorState()
  const { primaryVideoId, previewRect, KDAOptions, selectedFormat } =
    useComposerState()
  const { width, height } = useMemo(() => {
    const width = selectedFormat?.id === STANDARD_FORMAT.id ? 1920 : 1080
    const height = selectedFormat?.id === STANDARD_FORMAT.id ? 1080 : 1920
    return { width, height }
  }, [selectedFormat])

  const scale = useMemo(() => {
    const height = selectedFormat?.id === STANDARD_FORMAT.id ? 1080 : 1920
    return (previewRect?.height ?? 0) / height
  }, [previewRect?.height, selectedFormat])
  const props = {
    y: KDAOptions.y,
    x: KDAOptions.x,
  }

  return selection?.startTime && primaryVideoId ? (
    <AddonContainer>
      <KDAOverlay
        width={width}
        height={height}
        scale={scale}
        startTime={selection?.startTime ?? 0}
        endTime={selection?.endTime ?? 0}
        videoId={primaryVideoId}
        currentTime={currentTime}
        {...props}
      />
    </AddonContainer>
  ) : (
    <React.Fragment />
  )
}

interface Props {
  width: number
  height: number
  scale: number
  videoId: string
  startTime: number
  endTime: number
  currentTime: number | undefined
  x: number
  y: number
}

export const KDAOverlay = ({
  width,
  height,
  videoId,
  startTime,
  currentTime,
  scale,
  y,
  x,
}: Props) => {
  const { accountId, playerKills, playerDeaths, agent } = useKDAData(
    videoId,
    startTime
  )

  return (
    <KDAOverlayStill
      width={width}
      height={height}
      accountId={accountId}
      killsByPlayer={playerKills}
      deathsByPlayer={playerDeaths}
      currentTime={currentTime}
      agent={agent ?? undefined}
      scale={scale}
      y={y}
      x={x}
    />
  )
}

interface StillProps {
  width: number
  height: number
  accountId?: string
  killsByPlayer?: KillsByPlayer
  deathsByPlayer?: DeathsByPlayer
  currentTime?: number
  scale: number
  y: number
  x: number
  agent?: ValorantAgent
}

export const KDAOverlayStill = ({
  width,
  height,
  accountId,
  killsByPlayer,
  deathsByPlayer,
  currentTime,
  scale,
  y,
  x,
  agent,
}: StillProps) => {
  const { numDeaths, numKills } = useGetKD(
    accountId ?? '',
    currentTime,
    killsByPlayer ?? {},
    deathsByPlayer ?? {}
  )

  return numKills && agent ? (
    <PlayerDisplayScaledContainer
      $scale={scale}
      $top={y}
      $left={x}
      $width={width}
      $height={height}
    >
      <PositioningContainerDiv $left={x} $top={y}>
        <ContentsDiv>
          <AgentImg src={AGENT_THUMB_IMAGES[agent]} />
          <Text variant="h2" color="white">
            {numKills} - {numDeaths}
          </Text>
        </ContentsDiv>
      </PositioningContainerDiv>
    </PlayerDisplayScaledContainer>
  ) : (
    <div />
  )
}
