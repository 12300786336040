import { Text } from 'components/core/text'
import {
  ScoreboardEntry,
  useScoreboard,
} from 'core/feed/components/scoreboard/hooks'
import { flattenDeep, uniq } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'

import {
  PostBuyEditFragment,
  useGetPostBuyMomentEditQuery,
} from '__generated__'

import {
  MOBILE_MATCH_HIGHLIGHTS,
  MATCH_HIGHLIGHTS,
  RISING_GODS,
  ACES,
  CLUTCHES,
  FUNNY,
} from './data'
import { PlayerHighlightData, useGetMatchHighlights } from './matches/hooks'
import { RisingGodsProps, RisingGodsWithData } from './rising-god'

export const RankedGodsGenerator = () => {
  const [matchData, setMatchData] = useState<{
    [matchId: string]: MatchHighlightData
  }>({})
  const [editData, setEditData] = useState<{
    [editId: string]: PostBuyEditFragment
  }>({})
  const [risingGods, setRisingGods] = useState<{
    [hash: string]: RisingGodsProps
  }>({})

  const setEditDataById = useCallback(
    (editId: string, editData: PostBuyEditFragment) => {
      setEditData((currentEditData) => ({
        ...currentEditData,
        [editId]: editData,
      }))
    },
    []
  )

  const setMatchDataById = useCallback(
    (matchId: string, data: MatchHighlightData) => {
      setMatchData((currentMatchData) => ({
        ...currentMatchData,
        [matchId]: data,
      }))
    },
    []
  )

  const setRisingGodsById = useCallback(
    (hash: string, data: RisingGodsProps) => {
      setRisingGods((currentRisingGods) => ({
        ...currentRisingGods,
        [hash]: data,
      }))
    },
    []
  )

  const editIds = useMemo(
    () =>
      uniq(
        flattenDeep([
          RISING_GODS.map(({ editIds }) => editIds),
          MATCH_HIGHLIGHTS.map(({ editIds }) => editIds),
          MOBILE_MATCH_HIGHLIGHTS.map(({ editIds }) => editIds),
          FUNNY.editIds,
          ACES.editIds,
          CLUTCHES.editIds,
        ])
      ),
    []
  )
  const data = useMemo(() => {
    return JSON.stringify({
      edits: editData,
      matchesPerformances: matchData,
      risingGods: risingGods,
    })
  }, [editData, matchData, risingGods])

  return (
    <>
      {MATCH_HIGHLIGHTS.map(({ matchId, accountIds }) => (
        <MatchDataGenerator
          key={matchId}
          matchId={matchId}
          accountIds={accountIds}
          setMatchData={setMatchDataById}
        />
      ))}
      {MOBILE_MATCH_HIGHLIGHTS.map(({ matchId, accountIds }) => (
        <MatchDataGenerator
          key={matchId}
          matchId={matchId}
          accountIds={accountIds}
          setMatchData={setMatchDataById}
        />
      ))}
      {editIds.map((editId) => (
        <MomentDataGenerator
          key={editId}
          editId={editId}
          setData={setEditDataById}
        />
      ))}
      {RISING_GODS.map((data) => (
        <RisingGodsWithData
          key={`${data.channelId}-${data.start}-${data.end}}`}
          channelId={data.channelId}
          end={data.end}
          start={data.start}
          saveData={setRisingGodsById}
        />
      ))}
      <pre>
        <Text mono variant="small" as="pre">
          {data}
        </Text>
      </pre>
    </>
  )
}

type MatchHighlightData = {
  players: PlayerHighlightData[]
  teams: {
    [key: string]: ScoreboardEntry[]
  }
}

interface MatchDataProps {
  matchId: string
  accountIds: string[]
  setMatchData: (matchId: string, data: MatchHighlightData) => void
}

const MatchDataGenerator = ({
  matchId,
  accountIds,
  setMatchData,
}: MatchDataProps) => {
  const players = useGetMatchHighlights(matchId, accountIds)
  const { teams } = useScoreboard(matchId)

  useEffect(() => {
    if (players && teams) {
      setMatchData(matchId, { players, teams })
    }
  }, [players, teams, matchId, setMatchData])

  return <></>
}

interface EditDataGenerator {
  editId: string
  setData: (editId: string, data: PostBuyEditFragment) => void
}

const MomentDataGenerator = ({ editId, setData }: EditDataGenerator) => {
  const { data } = useGetPostBuyMomentEditQuery({ variables: { id: editId } })

  useEffect(() => {
    if (data?.momentEdit) {
      setData(editId, data.momentEdit)
    }
  }, [data, editId, setData])
  return <></>
}
