import styled from 'styled-components/macro'

export const MomentCollectionPageDiv = styled.div<{ $hasNavigation: boolean }>`
  position: relative;
  margin: 0 auto;
  margin-top: 16px;
  max-width: 1136px;
  width: 100%;
  display: grid;
  padding: 16px;
  grid-template-areas:
    'collection-title'
    'moment-viewer';
  grid-template-rows: 60px 1fr;
`

export const MomentCollectionHeadingDiv = styled.div`
  position: relative;
  grid-area: collection-title;
`

export const MomentCollectionMomentViewerDiv = styled.div`
  position: relative;
  grid-area: moment-viewer;
`
