import React from 'react'
import ReactDOM from 'react-dom'
import AuthorizedApolloProvider from 'core/auth/AuthorizedApolloProvider'
import { BrowserRouter as Router } from 'react-router-dom'

import './styles/fonts.css'

import App from './App'
import reportWebVitals from './reportWebVitals'
import Auth0ProviderWithHistory from 'core/auth/Auth0ProviderWithHistory'
import { AppThemeProvider } from 'theme/context'

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Auth0ProviderWithHistory>
        <AuthorizedApolloProvider>
          <AppThemeProvider>
            <App />
          </AppThemeProvider>
        </AuthorizedApolloProvider>
      </Auth0ProviderWithHistory>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
