import React from 'react'

import { useOffsetEditing, useVideoSyncHelpers } from './hooks'

interface VideoSyncProps {
  momentId: string
}

export const VideoSyncSelect: React.FC<VideoSyncProps> = ({ momentId }) => {
  const { offsetMap, videoIds } = useOffsetEditing(momentId)
  const { syncVideos } = useVideoSyncHelpers()
  // const {videoIds} = useVideoSyncSelectData(momentId)

  return (
    <div>
      {videoIds.map((primaryVideoId: string) => (
        <div>
          {primaryVideoId}
          <div>
            {videoIds.map((secondaryVideoId: string) =>
              primaryVideoId !== secondaryVideoId ? (
                <div
                  onClick={() =>
                    syncVideos({
                      momentId: momentId,
                      primaryVideoId,
                      secondaryVideoId,
                    })
                  }
                >{`${secondaryVideoId} - ${offsetMap?.[primaryVideoId]?.[secondaryVideoId]?.audioOffset}`}</div>
              ) : null
            )}
          </div>
        </div>
      ))}
    </div>
  )
}

// const useVideoSyncSelectData = (momentId: string) => {
//   const { offsetMap, videoIds } = useOffsetEditing(momentId)
// }
