import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTheme } from 'styled-components/macro'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import SimpleBar from 'simplebar-react'

import { HStack, Spacing } from 'components/core/layout'
import { Text } from 'components/core/text'
import { DroppableContainer } from 'core/moment-viewer/styled'
import { ReactComponent as LayerPlusIcon } from 'icons/svgs/regular/layer-plus.svg'
import { ReactComponent as ImportIcon } from 'icons/svgs/regular/folder-tree.svg'
import { ReactComponent as SaveIcon } from 'icons/svgs/regular/folder-download.svg'
import { ReactComponent as LockIcon } from 'icons/svgs/solid/lock.svg'
import { ReactComponent as LockOpenIcon } from 'icons/svgs/solid/lock-open.svg'
import { useEditor } from 'core/editor/hooks'
import { useTimelineHelpers } from 'core/editor/timeline/hooks'
import { useEditorState } from 'core/editor/context'

import { useComposerState } from '../context'
import { useComposerHelper } from '../hooks'
import {
  TemplateAddonsContainerDiv,
  TemplateSettingsContentDiv,
  TemplateSettingsDiv,
  TemplateSettingsGrid,
  TemplateSettingsLayersRow,
  TemplateSettingsPropertiesRow,
} from '../styled'
import { LayerOption } from './option'
import { TemplateSettingsDisplay } from './settings'
import { CreateTemplateCrop } from './create-crop'
import { AdminControlsDiv, IconButton } from './styled'
import { transparentize } from 'polished'
import { TemplatePresets } from './presets'
import { SavePreset } from './presets/save'
import { SidebarAddons } from './addons'
import { Button } from 'components/core/button'
import { RootOnly } from 'core/auth/components'

enum EditingModes {
  LAYERS = 'LAYERS',
  ADDONS = 'ADDONS',
}

export const TemplateSidebar = () => {
  const { relativeSeekTo } = useEditor()
  const [importPresetOpen, setImportPresetOpen] = useState(false)
  const [savePresetOpen, setSavePresetOpen] = useState(false)
  const [addOpen, setAddOpen] = useState(false)
  const { selectedTemplate, selectedFormat, scenes, selectedSceneId } =
    useComposerState()
  const { setTemplate, orderTemplateCrops } = useComposerHelper()
  const { setSelectionToTime } = useTimelineHelpers()
  const { currentTime, selection } = useEditorState()
  const [hotkeysActive, setHotkeysActive] = useState(false)
  const [editingMode, setEditingMode] = useState(EditingModes.LAYERS)

  const simplebarStyles = useMemo(
    () => ({
      width: '100%',
      height: '100%',
    }),
    []
  )

  const onOrderEnd = useCallback(
    (result) => {
      if (result.destination === null) return
      const nextTemplateCropIds =
        selectedTemplate?.crops.map(({ localId }) => localId) ?? []
      const [reorderedItem] = nextTemplateCropIds.splice(result.source.index, 1)
      nextTemplateCropIds.splice(result.destination.index, 0, reorderedItem)
      orderTemplateCrops(nextTemplateCropIds)
    },
    [orderTemplateCrops, selectedTemplate?.crops]
  )
  const theme = useTheme()

  const prevScene = useMemo(() => {
    const idx = scenes.findIndex(({ id }) => id === selectedSceneId)
    return idx === -1 ? undefined : scenes[idx - 1]
  }, [scenes, selectedSceneId])

  const nextScene = useMemo(() => {
    const idx = scenes.findIndex(({ id }) => id === selectedSceneId)
    return idx === -1 ? undefined : scenes[idx + 1]
  }, [scenes, selectedSceneId])

  const onKeyDown = useCallback(
    (evt: KeyboardEvent) => {
      switch (evt.key) {
        case '1':
          relativeSeekTo(-10 * 1000)
          break
        case '2':
          relativeSeekTo(10 * 1000)
          break
        case 'q':
          relativeSeekTo(-5 * 1000)
          break
        case 'w':
          relativeSeekTo(5 * 1000)
          break
        case 'a':
          relativeSeekTo(-1 * 1000)
          break
        case 's':
          relativeSeekTo(1 * 1000)
          break
        case 'z':
          relativeSeekTo(-1000 / 30)
          break
        case 'x':
          relativeSeekTo(1000 / 30)
          break
        case 'c':
          relativeSeekTo(-1000 / 60)
          break
        case 'v':
          relativeSeekTo(1000 / 60)
          break
        case '[':
          if (selection?.endTime)
            setSelectionToTime(currentTime, selection.endTime)
          break
        case ']':
          if (selection?.startTime)
            setSelectionToTime(selection.startTime, currentTime)
          break
        case '<':
          if (selection?.endTime && prevScene?.end)
            setSelectionToTime(prevScene.end, selection.endTime)
          break
        case '>':
          if (selection?.startTime && nextScene?.start)
            setSelectionToTime(selection.startTime, nextScene.start)
          break

        default:
          break
      }
    },
    [
      currentTime,
      nextScene?.start,
      prevScene?.end,
      relativeSeekTo,
      selection?.endTime,
      selection?.startTime,
      setSelectionToTime,
    ]
  )

  useEffect(() => {
    if (hotkeysActive) {
      document.addEventListener('keydown', onKeyDown, false)
      return () => document.removeEventListener('keydown', onKeyDown, false)
    }
  }, [onKeyDown, hotkeysActive])

  return (
    <TemplateSettingsDiv>
      <TemplateSettingsContentDiv>
        <SimpleBar style={simplebarStyles}>
          <EditingModeTabs
            editingMode={editingMode}
            setEditingMode={setEditingMode}
          />
          {editingMode === EditingModes.LAYERS ? (
            <TemplateSettingsGrid>
              <TemplateSettingsLayersRow>
                {selectedFormat && importPresetOpen && (
                  <TemplatePresets
                    format={selectedFormat}
                    template={selectedTemplate}
                    setTemplate={setTemplate}
                    close={() => setImportPresetOpen(false)}
                  />
                )}

                {selectedFormat && savePresetOpen && (
                  <SavePreset
                    close={() => setSavePresetOpen(false)}
                    format={selectedFormat}
                  />
                )}

                <Spacing bottom={8}>
                  <HStack alignItems="center" justifyContent="space-between">
                    <Text variant="text-2" weight="semibold">
                      Layers
                    </Text>
                    <HStack gap={8}>
                      <IconButton
                        color={transparentize(
                          0.5,
                          theme.colors.static.neutrals.n4
                        )}
                        size="small"
                        style={{ padding: '6px 6px' }}
                        onClick={() => setImportPresetOpen(true)}
                      >
                        <ImportIcon width={12} height={12} fill="white" />
                      </IconButton>
                      <IconButton
                        color={transparentize(
                          0.5,
                          theme.colors.static.neutrals.n4
                        )}
                        size="small"
                        style={{ padding: '6px 6px' }}
                        onClick={() => setSavePresetOpen(true)}
                      >
                        <SaveIcon width={12} height={12} fill="white" />
                      </IconButton>
                    </HStack>
                  </HStack>
                </Spacing>
                <DragDropContext onDragEnd={onOrderEnd}>
                  <Droppable droppableId="main">
                    {(droppableProvided) => (
                      <DroppableContainer
                        ref={droppableProvided.innerRef}
                        {...droppableProvided.droppableProps}
                      >
                        {selectedTemplate?.crops.map((layerSettings, index) => (
                          <Draggable
                            key={layerSettings.localId}
                            draggableId={layerSettings.localId}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <LayerOption
                                  key={layerSettings.localId}
                                  layerSettings={layerSettings}
                                />
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {droppableProvided.placeholder}
                      </DroppableContainer>
                    )}
                  </Droppable>
                </DragDropContext>

                <IconButton
                  color={transparentize(0.5, theme.colors.static.neutrals.n4)}
                  onClick={() => setAddOpen((currentOpen) => !currentOpen)}
                >
                  <LayerPlusIcon
                    fill={theme.colors.static.neutrals.n8}
                    width={16}
                  />
                  <span>Add Layer</span>
                </IconButton>
                {addOpen && (
                  <CreateTemplateCrop close={() => setAddOpen(false)} />
                )}
              </TemplateSettingsLayersRow>
              <TemplateSettingsPropertiesRow>
                <TemplateSettingsDisplay />
              </TemplateSettingsPropertiesRow>
            </TemplateSettingsGrid>
          ) : (
            <TemplateAddonsContainerDiv>
              <SidebarAddons />
            </TemplateAddonsContainerDiv>
          )}
        </SimpleBar>
        <RootOnly>
          <AdminControlsDiv>
            <IconButton
              color={transparentize(0.5, theme.colors.static.neutrals.n4)}
              size="small"
              style={{ padding: '6px 6px' }}
              onClick={() => setHotkeysActive((currentState) => !currentState)}
            >
              {hotkeysActive ? (
                <LockOpenIcon width={12} height={12} fill="white" />
              ) : (
                <LockIcon width={12} height={12} fill="white" />
              )}
            </IconButton>
          </AdminControlsDiv>
        </RootOnly>
      </TemplateSettingsContentDiv>
    </TemplateSettingsDiv>
  )
}

export const EditingModeTabs = ({
  editingMode,
  setEditingMode,
}: {
  editingMode: EditingModes
  setEditingMode: (mode: EditingModes) => void
}) => {
  return (
    <HStack gap={8}>
      <Button
        size="small"
        variant={editingMode === EditingModes.LAYERS ? 'primary' : 'secondary'}
        onClick={() => setEditingMode(EditingModes.LAYERS)}
      >
        Video
      </Button>
      <Button
        size="small"
        variant={editingMode === EditingModes.ADDONS ? 'primary' : 'secondary'}
        onClick={() => setEditingMode(EditingModes.ADDONS)}
      >
        Add-ons
      </Button>
    </HStack>
  )
}
