import { useCallback, useState } from 'react'
import styled, { useTheme } from 'styled-components/macro'

import { ReactComponent as PlaybackSpeedIcon } from 'icons/svgs/custom/playback-speed.svg'
import { Button } from 'components/core/button'
import { monoFontStyle } from 'theme/typography'

import { useTimeRangePlayerState, useTimeRangePlayerUpdater } from './context'
import { transparentize } from 'polished'

export const PlaybackRateController = () => {
  const [open, setOpen] = useState(false)
  const theme = useTheme()
  const { playbackRate } = useTimeRangePlayerState()
  const dispatch = useTimeRangePlayerUpdater()

  const getColor = useCallback(
    (rate: number) =>
      playbackRate === rate
        ? theme.colors.static.neutrals.n8
        : theme.colors.static.neutrals.n5,
    [
      playbackRate,
      theme.colors.static.neutrals.n8,
      theme.colors.static.neutrals.n5,
    ]
  )

  const setPlaybackRate = useCallback(
    (rate: number) => () => {
      dispatch({
        type: useTimeRangePlayerUpdater.ACTION_TYPES.SET_PLAYBACK_RATE,
        payload: {
          playbackRate: rate,
        },
      })
      setOpen(false)
    },
    [dispatch]
  )

  const toggleOpen = useCallback(() => {
    setOpen(!open)
  }, [open])

  return (
    <PlaybackRateControllerContainer>
      {open && (
        <PlaybackRateListContainer>
          <PlaybackRateList>
            <PlaybackRateListItem>
              <Button
                onClick={setPlaybackRate(4.0)}
                variant="plain"
                size="small"
                color={getColor(4.0)}
                hoverColor={theme.colors.static.neutrals.n6}
              >
                Fastest (4.0x)
              </Button>
            </PlaybackRateListItem>
            <PlaybackRateListItem>
              <Button
                onClick={setPlaybackRate(2.0)}
                variant="plain"
                size="small"
                color={getColor(2.0)}
                hoverColor={theme.colors.static.neutrals.n6}
              >
                Faster (2.0x)
              </Button>
            </PlaybackRateListItem>
            <PlaybackRateListItem>
              <Button
                onClick={setPlaybackRate(1.5)}
                variant="plain"
                size="small"
                color={getColor(1.5)}
                hoverColor={theme.colors.static.neutrals.n6}
              >
                Fast (1.5x)
              </Button>
            </PlaybackRateListItem>
            <PlaybackRateListItem>
              <Button
                onClick={setPlaybackRate(1.0)}
                variant="plain"
                size="small"
                color={getColor(1.0)}
                hoverColor={theme.colors.static.neutrals.n6}
              >
                Normal (1.0x)
              </Button>
            </PlaybackRateListItem>
          </PlaybackRateList>
        </PlaybackRateListContainer>
      )}
      <PlaybackSpeedIcon cursor="pointer" onClick={toggleOpen} />
    </PlaybackRateControllerContainer>
  )
}

const PlaybackRateControllerContainer = styled.div`
  position: relative;
  padding-left: 16px;
`
const PlaybackRateListContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 32px;
`

const PlaybackRateList = styled.ul`
  background-color: ${({ theme }) =>
    transparentize(0.15, theme.colors.static.neutrals.n3)};
  border-radius: 8px;
`

const PlaybackRateListItem = styled.li`
  display: block;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.static.neutrals.n6};
  padding: 2px;
  text-align: right;

  button {
    ${monoFontStyle};
    font-size: 11px;
  }
`
