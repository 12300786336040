import React, { ForwardedRef, forwardRef, ReactNode } from 'react'
import { ButtonSize, ButtonVariant, StyledButton } from './styled'

type AnchorType = React.AnchorHTMLAttributes<HTMLAnchorElement>
type ButtonType = React.ButtonHTMLAttributes<HTMLButtonElement>

export type ButtonProps = {
  variant?: ButtonVariant
  size?: ButtonSize
  rounded?: boolean
  hoverColor?: string
  noPadding?: boolean
  regular?: boolean
  isLoading?: boolean
  children?: ReactNode
} & (({ as?: 'a' } & AnchorType) | ({ as?: 'button' } & ButtonType))

const Button = forwardRef(
  (
    {
      children,
      color,
      hoverColor,
      as = 'button',
      variant = 'primary',
      size = 'large',
      rounded = false,
      noPadding = false,
      regular = false,
      isLoading = false,
      ...rest
    }: ButtonProps,
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    return (
      <StyledButton
        ref={ref}
        variant={variant}
        size={size}
        rounded={rounded}
        as={as}
        color={color}
        hoverColor={hoverColor}
        noPadding={noPadding}
        regular={regular}
        isLoading={isLoading}
        {...rest}
      >
        {children}
      </StyledButton>
    )
  }
)

export default Button
