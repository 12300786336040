import styled from 'styled-components/macro'
import { ChangeEvent, useCallback, useMemo } from 'react'
import { Column } from 'react-table'
import { AdminSearchResultFragment } from '__generated__'

import { Button } from 'components/core/button'
import { Card, Container, Spacing } from 'components/core/layout'
import { Table } from 'components/core/table'
import { TextField } from 'components/core/textfield'

import { useSearch } from './hooks'
import { SearchUserCard } from './components/search-user-card'
import { Switch } from 'components/core/switch'

export const ChannelsPage = () => {
  const {
    search,
    setSearch,
    results,
    nextPage,
    prevPage,
    hasNextPage,
    hasPrevPage,
    onJoinChatChange,
    onSyncValorantChange,
    directHit,
  } = useSearch()

  const columns: Column<AdminSearchResultFragment>[] = useMemo(
    () => [
      { accessor: 'id', Header: 'id', width: 100 },
      {
        accessor: 'name',
        Header: 'name',
        width: 300,
        Cell: ({ row, value }) => {
          return <a href={`/channel/${row.original.name}`}>{value}</a>
        },
      },
      {
        align: 'right',
        accessor: 'dontJoinChat',
        Header: 'Join Chat',
        Cell: (row) => (
          <Switch
            checked={!row.value}
            onChange={() => onJoinChatChange(row.row.original.id, !row.value)}
          />
        ),
      },
      {
        align: 'right',
        accessor: 'autoSyncValorantMatches',
        Header: 'Sync Valorant',
        Cell: (row) => (
          <Switch
            checked={row.value}
            onChange={() =>
              onSyncValorantChange(row.row.original.id, !row.value)
            }
          />
        ),
      },
    ],
    [onJoinChatChange, onSyncValorantChange]
  )

  const data = useMemo(() => results || [], [results])

  const onChange = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      setSearch(evt.target.value)
    },
    [setSearch]
  )

  return (
    <Wrapper>
      <Spacing bottom={32}>
        <Card neutralOffset={2} body>
          <TextField
            placeholder="Streamer name"
            label="filter"
            value={search}
            onChange={onChange}
          />
        </Card>
      </Spacing>
      <Table data={data} columns={columns} />
      {search.length > 0 && (data.length === 0 || !directHit) && (
        <Spacing vertical={16}>
          <SearchUserCard name={search} />
        </Spacing>
      )}

      {(hasNextPage || hasPrevPage) && (
        <Spacing top={16}>
          <Container direction="row" justifyContent="space-evenly">
            <Button onClick={prevPage} disabled={!hasPrevPage}>
              Prev
            </Button>
            <Button onClick={nextPage} disabled={!hasNextPage}>
              Next
            </Button>
          </Container>
        </Spacing>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  a {
    text-decoration: underline;
  }
`
