import React, { createContext, useContext, useReducer } from 'react'

type Collection = {
  id: string
  title: string
  slug: string
  publicPassword: boolean
}

type State = {
  selected: Collection | null
}

const initialState: State = {
  selected: null,
}

export enum CollectionAction {
  SetSelectedCollection = 'SetSelectedCollection',
}

interface SetSelectedCollection {
  type: CollectionAction.SetSelectedCollection
  payload: {
    value: Collection | null
  }
}

type ActionTypes = SetSelectedCollection

const reducer = (state: State, action: ActionTypes) => {
  switch (action.type) {
    case CollectionAction.SetSelectedCollection: {
      return {
        ...state,
        selected: action.payload.value,
      }
    }
  }
}

type Dispatch = (action: ActionTypes) => void

const CollectionContext = createContext(initialState)
const CollectionDispatchContext = createContext<Dispatch | undefined>(undefined)

export const CollectionContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <CollectionContext.Provider value={state}>
      <CollectionDispatchContext.Provider value={dispatch}>
        {children}
      </CollectionDispatchContext.Provider>
    </CollectionContext.Provider>
  )
}

export const useCollectionContext = () => {
  const context = useContext(CollectionContext)
  if (context === undefined) {
    throw new Error(
      'useCollectionContext must be used within CollectionContextProvider'
    )
  }
  return context
}

export const useCollectionDispatch = () => {
  const context = useContext(CollectionDispatchContext)
  if (context === undefined) {
    throw new Error(
      'useCollectionDispatch must be used within CollectionContextProvider'
    )
  }
  return context
}
