import { Button } from 'components/core/button'
import { Rect } from 'core/exporter/constants'
import { useCropDrag, useCropResize } from 'core/shared/cropping/hooks'
import { useCallback, useMemo, useState } from 'react'
import { useDimensions } from 'utils/browser'
import { useComposerState } from '../../context'
import {
  CropBackgroundDiv,
  CropResizeSquareDiv,
  CropSelectionContainerDiv,
  FacecamContainerDiv,
  FacecamSettingOptionsDiv,
} from '../styled'
import { useLoadFacecamData, useStoreLocalStorage } from './hooks'

const DEFAULT_RECT = {
  x: 0,
  y: 0,
  width: 0.25,
  height: 0.25,
}

interface Props {
  containerRect: DOMRect
  close: () => void
}

enum FacecamMode {
  ContainerRect = 'ContainerRect',
  FaceRect = 'FaceRect',
}

export const EditFacecamSettings = ({ containerRect, close }: Props) => {
  const { selectedTemplateCrop, cropSettings, momentId, videosData } =
    useComposerState()
  const videoId = useMemo(() => {
    if (selectedTemplateCrop?.title) {
      const selectedCropData = Object.values(cropSettings).find(
        ({ title }) => selectedTemplateCrop?.title === title
      )
      return selectedCropData?.videoId
    }
  }, [cropSettings, selectedTemplateCrop?.title])
  const userId = videosData.find((vid) => vid.videoId === videoId)?.userId ?? ''
  const [mode, setMode] = useState<FacecamMode>(FacecamMode.ContainerRect)
  const localstorageRect = useLoadFacecamData(momentId, videoId ?? '', userId)
  const [facecamContainerRect, setFacecamContainerRect] = useState<Rect>(
    localstorageRect.cameraRect
  )
  const [faceRect, setFaceRect] = useState(localstorageRect.faceLocationRect)
  const [containerRef, facecamContainerDOMRect] = useDimensions()

  // autosave to moment
  useStoreLocalStorage(
    'facecamContainerRect',
    `m_${momentId}_v_${videoId}`,
    facecamContainerRect,
    setFacecamContainerRect,
    localstorageRect.cameraRect ?? DEFAULT_RECT
  )
  useStoreLocalStorage(
    'faceRect',
    `m_${momentId}_v_${videoId}`,
    faceRect,
    setFaceRect,
    localstorageRect.faceLocationRect ?? DEFAULT_RECT
  )

  const manuallySetLocalStorage = useCallback(
    (localStorageKey: string, objectKey: string, value: Rect) => {
      const currentRects = JSON.parse(
        localStorage.getItem(localStorageKey) ?? '{}'
      )
      const nextData = {
        ...currentRects,
        [objectKey]: value,
      }
      localStorage.setItem(localStorageKey, JSON.stringify(nextData))
      window.dispatchEvent(new Event('storage'))
    },
    []
  )

  const saveForVideo = useCallback(() => {
    const key = `v_${videoId}`
    manuallySetLocalStorage('facecamContainerRect', key, facecamContainerRect)
    manuallySetLocalStorage('faceRect', key, faceRect)
  }, [faceRect, facecamContainerRect, manuallySetLocalStorage, videoId])

  const saveForUser = useCallback(() => {
    const key = `u_${userId}`
    manuallySetLocalStorage('facecamContainerRect', key, facecamContainerRect)
    manuallySetLocalStorage('faceRect', key, faceRect)
  }, [faceRect, facecamContainerRect, manuallySetLocalStorage, userId])

  const containerRectStyles = useMemo(
    () => ({
      left: `${facecamContainerRect.x * 100}%`,
      top: `${facecamContainerRect.y * 100}%`,
      width: `${facecamContainerRect.width * 100}%`,
      height: `${facecamContainerRect.height * 100}%`,
    }),
    [
      facecamContainerRect.height,
      facecamContainerRect.width,
      facecamContainerRect.x,
      facecamContainerRect.y,
    ]
  )

  return (
    <>
      {mode === FacecamMode.ContainerRect && (
        <EditFacecamContainer
          containerRect={containerRect}
          rect={facecamContainerRect}
          setRect={setFacecamContainerRect}
        />
      )}
      {mode === FacecamMode.FaceRect && (
        <FacecamContainerDiv style={containerRectStyles} ref={containerRef}>
          {facecamContainerDOMRect && (
            <EditFacecamContainer
              containerRect={facecamContainerDOMRect}
              rect={faceRect}
              setRect={setFaceRect}
            />
          )}
        </FacecamContainerDiv>
      )}
      <FacecamSettingOptionsDiv>
        <Button onClick={() => setMode(FacecamMode.ContainerRect)}>
          Container
        </Button>
        <Button onClick={() => setMode(FacecamMode.FaceRect)}>Face</Button>
        <Button onClick={saveForVideo}>Save Video</Button>
        <Button onClick={saveForUser}>Save User</Button>
        <Button onClick={close}>Done</Button>
      </FacecamSettingOptionsDiv>
    </>
  )
}

interface ContainerProps {
  containerRect: DOMRect
  rect: Rect
  setRect: (nextRect: Rect) => void
}

export const EditFacecamContainer = ({
  containerRect,
  rect,
  setRect,
}: ContainerProps) => {
  const { ref: dragRef, dragging } = useCropDrag(
    rect,
    containerRect,
    setRect,
    true
  )
  const { ref: resizeRef } = useCropResize(
    rect,
    containerRect,
    setRect,
    {},
    true
  )

  const cropStyles = useMemo(
    () =>
      rect
        ? {
            left: `${rect.x * 100}%`,
            top: `${rect.y * 100}%`,
            width: `${rect.width * 100}%`,
            height: `${rect.height * 100}%`,
          }
        : {},
    [rect]
  )

  const backgroundStyles = useMemo(() => {
    if (!rect) return {}
    const polygon = `
      0 0,
      0 100%,
      100% 100%,
      100% 0,
      0 0,
      ${rect.x * 100}% ${rect.y * 100}%,
      ${(rect.x + rect.width) * 100}% ${rect.y * 100}%,
      ${(rect.x + rect.width) * 100}% ${(rect.y + rect.height) * 100}%,
      ${rect.x * 100}% ${(rect.y + rect.height) * 100}%,
      ${rect.x * 100}% ${rect.y * 100}%,
      0 0
    `
    return {
      clipPath: `polygon(${polygon})`,
    }
  }, [rect])

  return (
    <>
      <CropBackgroundDiv style={backgroundStyles}></CropBackgroundDiv>
      <CropSelectionContainerDiv
        ref={dragRef}
        style={cropStyles}
        $dragging={dragging}
        $editing={true}
      >
        <CropResizeSquareDiv ref={resizeRef} $editing={true} />
      </CropSelectionContainerDiv>
    </>
  )
}
