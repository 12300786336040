import styled, { css } from 'styled-components/macro'

export const PlaylistContainerDiv = styled.div<{ $open: boolean }>`
  position: relative;
  grid-area: playlist;
  margin-top: 16px;
  margin-left: 16px;
  width: 250px;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.neutrals.n7};
  background-color: ${({ theme }) => theme.colors.neutrals.n8};
  border-radius: 4px;

  ${({ $open }) =>
    $open
      ? css`
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        `
      : css`
          overflow: hidden;
        `}
`

export const SelectedItemDiv = styled.div`
  width: 100%;
  padding: 0 16px;
  cursor: pointer;
  overflow: hidden;
`

export const PlaylistContentDiv = styled.div<{ $open: boolean }>`
  position: absolute;
  left: -1px;
  top: 59px;
  width: 250px;
  height: 400px;
  transition: all 200ms ease-in-out;
  border: 1px solid ${({ theme }) => theme.colors.neutrals.n7};
  border-top-width: 0;
  background-color: ${({ theme }) => theme.colors.neutrals.n8};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
  overflow: hidden;

  ${({ $open }) =>
    $open
      ? css`
          opacity: 1;
        `
      : css`
          opacity: 0;
          pointer-events: none;
        `}
`

export const PlaylistItemDiv = styled.div<{ $selected: boolean }>`
  width: 100%;
  height: 60px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  /* cursor: pointer; */

  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  /* &:hover {
    background-color: ${({ theme }) => theme.colors.neutrals.n6};
  } */

  ${({ $selected, theme }) =>
    $selected
      ? css`
          background-color: ${theme.colors.neutrals.n7};
        `
      : ``}
`

export const Icon = styled.div`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);

  svg {
    display: block;
  }
`
