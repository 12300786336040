import { sortBy } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import Simplebar from 'simplebar-react'

import {
  DebugChatMomentFragment,
  useGetSpecificChatMomentsMutation,
} from '__generated__'
import { useVideoMomentsData } from '../hooks'
import { ChatMomentsFilter } from './filter'
import { ReviewVideoMoment } from './moment'

interface ReviewVideoSidebarProps {
  videoId: string
}

export const ReviewVideoSidebar: React.FC<ReviewVideoSidebarProps> = ({
  videoId,
}) => {
  const { chatMoments, ...rest } = useVideoReviewFilter(videoId)

  const simplebarStyles = useMemo(() => {
    return {
      height: 'calc(100% - 40px)',
      width: '100%',
    }
  }, [])

  return (
    <>
      <ChatMomentsFilter videoId={videoId} {...rest} />
      <Simplebar style={simplebarStyles}>
        {chatMoments?.map((momentData, i) => {
          return <ReviewVideoMoment key={`${i}`} momentData={momentData} />
        })}
      </Simplebar>
    </>
  )
}

const useVideoReviewFilter = (videoId: string) => {
  const { chatMoments, channelId } = useVideoMomentsData(videoId)
  const [timeframe, setTimeframe] = useState<
    { from: string; to: string } | undefined
  >()
  // const [selectedSponsorship, setSelectedSponsorship] = useState<string | undefined>()
  // const [selectedEvent, setSelectedEvent] = useState()
  const [selectedCampaignId, setSelectedCampaignId] = useState<
    string | undefined
  >()
  const [queryFilteredMoments, { data: filteredChatData }] =
    useGetSpecificChatMomentsMutation()

  const filteredChatMoments = useMemo(() => {
    if (timeframe) {
      return sortBy(
        filteredChatData?.analyzeChannel.filter(
          (moment) => moment.__typename === 'ChatMoment'
        ) as DebugChatMomentFragment[],
        (m) => -m.metadata.score
      )
    } else {
      return chatMoments
    }
  }, [chatMoments, filteredChatData, timeframe])

  useEffect(() => {
    if (
      timeframe !== undefined &&
      channelId !== undefined &&
      timeframe.from !== undefined &&
      timeframe.to !== undefined
    ) {
      queryFilteredMoments({
        variables: {
          channelId,
          dryRun: true,
          input: {
            ...timeframe,
          },
        },
      })
    }
  }, [channelId, queryFilteredMoments, timeframe])

  return useMemo(
    () => ({
      timeframe,
      setTimeframe,
      selectedCampaignId,
      setSelectedCampaignId,
      chatMoments: filteredChatMoments,
    }),
    [filteredChatMoments, selectedCampaignId, timeframe]
  )
}
