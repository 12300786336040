import { VStack } from 'components/core/layout'
import {
  GridItemContainer,
  GridItemImageContainer,
  GridItemInfo,
  SkeletonEditsText,
  SkeletonTitleText,
} from './styled'

export const GridItemSkeleton = () => (
  <GridItemContainer>
    <GridItemImageContainer></GridItemImageContainer>
    <GridItemInfo>
      <VStack>
        <SkeletonTitleText />
        <SkeletonEditsText />
      </VStack>
    </GridItemInfo>
  </GridItemContainer>
)
