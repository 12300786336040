import { Text } from 'components/core/text'
import { AGENT_THUMB_IMAGES } from 'components/valorant/agent'
import {
  KILL_FEED_ABILITIES_BY_AGENT,
  KILL_FEED_WEAPONS,
} from 'components/valorant/killfeed-weapon'

import {
  FeedItemContainer,
  KillerItemContainer,
  KillFeedScaledContainer,
  LeftAgentImg,
  RightAgentImg,
  RightArrowDiv,
  RightArrowThreeDiv,
  RightArrowTwoDiv,
  VictimItemContainer,
  WeaponImg,
} from '../styled'
import { StillPropsTypes } from '..'
import { ValorantAgent } from '__generated__'

export const KillFeedStillDefault = ({
  width,
  height,
  accountId,
  playerStats,
  gameName,
  scale,
  y,
  justify,
  kills,
}: StillPropsTypes) => {
  if (!playerStats) return null
  const agent = playerStats[accountId ?? '']?.agent ?? ValorantAgent.Chamber
  return (
    <KillFeedScaledContainer
      $scale={scale}
      $top={y}
      $justify={justify}
      $width={width}
      $height={height}
    >
      {kills?.map((kf, idx) => {
        let killFeedWeapon
        switch (kf.type) {
          case 'Ability':
            killFeedWeapon =
              KILL_FEED_ABILITIES_BY_AGENT[agent][kf.weapon ?? '']
            break
          case 'Melee':
            killFeedWeapon = KILL_FEED_WEAPONS['Melee']
            break
          case 'Weapon':
            killFeedWeapon = KILL_FEED_WEAPONS[kf.weapon ?? '']
            break
          default:
            killFeedWeapon = KILL_FEED_WEAPONS['Vandal']
            break
        }
        return (
          <FeedItemContainer key={idx}>
            <KillerItemContainer>
              <LeftAgentImg
                src={
                  AGENT_THUMB_IMAGES[
                    playerStats[accountId ?? ''].agent ?? 'CHAMBER'
                  ]
                }
              />
              <Text variant="h6" weight="bold">
                {gameName}
              </Text>
              <WeaponImg src={killFeedWeapon} alt="" />
              <RightArrowDiv />
              {/* I added a couple trailing arrows since there isn't always pixel perfection */}
              <RightArrowTwoDiv />
              <RightArrowThreeDiv />
            </KillerItemContainer>
            <VictimItemContainer>
              <Text variant="h6" weight="bold">
                {kf.victim.name}
              </Text>
              <RightAgentImg
                src={
                  AGENT_THUMB_IMAGES[
                    playerStats
                      ? playerStats[kf.victim.id].agent ?? 'CHAMBER'
                      : 'CHAMBER'
                  ]
                }
              />
            </VictimItemContainer>
          </FeedItemContainer>
        )
      })}
    </KillFeedScaledContainer>
  )
}

export const numberToRomanNumeral = (num: number) => {
  const romanNumeralMap = {
    M: 1000,
    CM: 900,
    D: 500,
    CD: 400,
    C: 100,
    XC: 90,
    L: 50,
    XL: 40,
    X: 10,
    IX: 9,
    V: 5,
    IV: 4,
    I: 1,
  }
  let roman = ''
  for (let key in romanNumeralMap) {
    while (num >= romanNumeralMap[key]) {
      roman += key
      num -= romanNumeralMap[key]
    }
  }
  return roman
}
