import { ReactNode, useEffect } from 'react'

import { useTimelineUpdater } from 'core/editor/timeline/context'
import {
  useTimelineDetailData,
  useTimelineHelpers,
} from 'core/editor/timeline/hooks'
import {
  TimelineContainerDiv,
  TimelineContentDiv,
  TimelineMainContentDiv,
} from 'core/shared/timeline/detail/styled'
import { CurrentTimeIndicator } from 'core/shared/timeline/detail/current-time'
import { TimelineSelection } from 'core/shared/timeline/detail/selection'
import { useDragTimeline } from 'core/shared/timeline/hooks'
import { TimelineTimeDisplay } from 'core/shared/timeline/detail/time-display'

// import { ValorantTimelineTimeDisplay } from '../time-display'

interface Props {
  children: ReactNode
}

export const TimelineDetail = ({ children }: Props) => {
  // const { ref: containerRef } = useTimeline()
  const { containerRef, containerStyles, containerRect, seekToPageX } =
    useTimelineDetailData()
  const { contentRef } = useDragTimeline(seekToPageX)
  useTimeline(containerRect)

  return (
    <TimelineContainerDiv ref={containerRef}>
      <TimelineContentDiv style={containerStyles}>
        <TimelineMainContentDiv ref={contentRef}>
          <TimelineTimeDisplay />
          {children}
        </TimelineMainContentDiv>
        <CurrentTimeIndicator />
        <TimelineSelection containerRect={containerRect} />
      </TimelineContentDiv>
    </TimelineContainerDiv>
  )
}

const useTimeline = (containerRect: DOMRect | undefined) => {
  const dispatch = useTimelineUpdater()
  const { setTimelineLength } = useTimelineHelpers()

  useEffect(() => {
    // This dictates how much time is shown to the user at once
    setTimelineLength(200 * 1000)

    // we track the content rect for resizes, so we can make sure it always displays X amount of timeline length
    dispatch({
      type: useTimelineUpdater.ACTION_TYPES.SET_CONTENT_RECT,
      payload: {
        containerRect,
      },
    })
  }, [containerRect, dispatch, setTimelineLength])
}
