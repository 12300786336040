import { Text } from 'components/core/text'
import { flatMap } from 'lodash'
import momentjs from 'moment'
import { useMemo } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'

import { useGetValorantMatchesByChannelQuery } from '__generated__'
import { ValorantMatchPreview } from '../match/match-preview'

export const ValorantMatchesByChannelPage = () => {
  const { channelId } = useParams<{ channelId: string }>()
  const { data } = useGetValorantMatchesByChannelQuery({
    variables: { channelId },
  })

  const matches = useMemo(
    () =>
      flatMap(
        data?.channel?.videos.data ?? [],
        ({ valorantMatches }) => valorantMatches
      ),
    [data?.channel?.videos.data]
  )

  return (
    <div>
      {data?.channel?.videos.data.map((vid) => (
        <div>
          <Link to={`/valorant/video/${vid.id}`}>
            <Text variant="text-3" mono={true}>
              {momentjs(vid.publishedAt).format('llll')} - {vid.title}
            </Text>
          </Link>
        </div>
      ))}

      {matches.map((m) => (
        <Link
          to={`/valorant/match/${m.id}?user=${channelId}&start=${momentjs(
            m.startedAt
          )
            .add(2, 'minutes')
            .toISOString()}&end=${momentjs(m.startedAt)
            .add(2.5, 'minutes')
            .toISOString()}`}
        >
          <ValorantMatchPreview matchId={m.id} />
        </Link>
      ))}
    </div>
  )
}
