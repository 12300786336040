import React, { createContext, ReactNode, useContext, useReducer } from 'react'

export type SignalTypes = 'ChatMoment' | 'ValorantMoment'

interface SignalPlayerState {
  showPreviews: boolean
  visibleRelatedVideoIds: string[]
  selectedVideoId: string
  primaryVideoId: string
  relatedVideoData: { id: string; offset?: number }[]
}

const INITAL_STATE = {
  showPreviews: true,
  visibleRelatedVideoIds: [],
}

enum ACTION_TYPES {
  SET_VISIBLE_RELATED_VIDEOS = 'SET_VISIBLE_RELATED_VIDEOS',
  SET_SHOW_PREVIEW = 'SET_SHOW_PREVIEW',
  SET_SELECTED_VIDEO_ID = 'SET_SELECTED_VIDEO_ID',
}

interface setRelatedVideosAction {
  type: ACTION_TYPES.SET_VISIBLE_RELATED_VIDEOS
  payload: {
    relatedVideoIds: string[]
  }
}

interface setShowPreviewAction {
  type: ACTION_TYPES.SET_SHOW_PREVIEW
  payload: {
    showPreviews: boolean
  }
}

interface setSelectedVideoIdAction {
  type: ACTION_TYPES.SET_SELECTED_VIDEO_ID
  payload: {
    selectedVideoId: string
  }
}

type SignalPlayerActions =
  | setRelatedVideosAction
  | setShowPreviewAction
  | setSelectedVideoIdAction

type Dispatch = (action: SignalPlayerActions) => void

const reducer = (
  state: SignalPlayerState,
  action: SignalPlayerActions
): SignalPlayerState => {
  switch (action.type) {
    case ACTION_TYPES.SET_VISIBLE_RELATED_VIDEOS: {
      return {
        ...state,
        visibleRelatedVideoIds: action.payload.relatedVideoIds,
      }
    }

    case ACTION_TYPES.SET_SHOW_PREVIEW: {
      return {
        ...state,
        showPreviews: action.payload.showPreviews,
      }
    }

    case ACTION_TYPES.SET_SELECTED_VIDEO_ID: {
      return {
        ...state,
        selectedVideoId: action.payload.selectedVideoId,
      }
    }

    default:
      return state
  }
}

const SignalPlayerContext = createContext<SignalPlayerState | undefined>(
  undefined
)
const SignalPlayerUpdaterContext = createContext<Dispatch | undefined>(
  undefined
)

interface Props {
  children: ReactNode
  videoId: string
  relatedVideoData: { id: string; offset?: number }[]
}

export const SignalPlayerProvider = ({
  children,
  videoId,
  relatedVideoData,
}: Props) => {
  const [state, dispatch] = useReducer(reducer, {
    ...INITAL_STATE,
    selectedVideoId: videoId,
    primaryVideoId: videoId,
    relatedVideoData: relatedVideoData,
  })

  return (
    <SignalPlayerContext.Provider value={state}>
      <SignalPlayerUpdaterContext.Provider value={dispatch}>
        {children}
      </SignalPlayerUpdaterContext.Provider>
    </SignalPlayerContext.Provider>
  )
}

export const useSignalPlayerState = () => {
  const context = useContext(SignalPlayerContext)
  if (context === undefined) {
    throw new Error(
      'useSignalPlayerState must be used within a SignalPlayerProvider'
    )
  }
  return context
}

export const useSignalPlayerUpdater = () => {
  const context = useContext(SignalPlayerUpdaterContext)
  if (context === undefined) {
    throw new Error(
      'useSignalPlayerUpdater must be used within a SignalPlayerProvider'
    )
  }
  return context
}

useSignalPlayerUpdater.ACTION_TYPES = ACTION_TYPES
