import { useMemo, useState } from 'react'

import {
  TimeframeOptions,
  useFindValorantMoments,
  ValorantMomentHighlight,
} from '../hooks'
import { ValorantMatchPage } from '../match'
import { ValorantMatchProvider } from '../match/context'
import { ValorantMomentsPlaylist } from '../playlist'
import { SidebarPlaylist, ValorantMatchPageDiv } from '../styled'
import { ValorantCollectionFilter, VALORANT_TEAM_COLLECTIONS } from './filter'

// 48389176 ASUNA
// 36340781 TARIK
// 103262684 JASONR
// 244407024 SYDEON
// 173758090 QUARTERJADE

export const ValorantMomentPlayer = () => {
  const [collectionId, setCollectionId] = useState<string>(
    VALORANT_TEAM_COLLECTIONS[0].id
  )

  const highlights = useFindValorantMoments(collectionId, TimeframeOptions.Day)
  const [selectedHighlight, setSelectedHighlight] = useState<
    ValorantMomentHighlight | undefined
  >()

  const filteredHighlights = useMemo(
    () => highlights,
    // () => highlights.filter(({ name }) => name === 'quarterjade'),
    [highlights]
  )

  return (
    <ValorantMatchPageDiv>
      <SidebarPlaylist>
        <ValorantCollectionFilter
          setCollectionId={setCollectionId}
          collectionId={collectionId}
        />
        <ValorantMomentsPlaylist
          selectedHighlight={selectedHighlight}
          setSelectedHighlight={setSelectedHighlight}
          highlights={filteredHighlights}
        ></ValorantMomentsPlaylist>
      </SidebarPlaylist>
      {selectedHighlight ? (
        <ValorantMatchProvider>
          <ValorantMatchPage
            matchId={selectedHighlight.matchId}
            highlight={selectedHighlight}
          />
        </ValorantMatchProvider>
      ) : null}
    </ValorantMatchPageDiv>
  )
}
