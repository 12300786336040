import { ReactNode, useEffect, useMemo } from 'react'
import momentjs from 'moment'

import { useGetVideoSrcUrlsQuery } from '__generated__'

import { useTimeRangePlayerState } from './context'
import { useTimeRangePlayerHelpers } from './helpers'
import { TimeRangeVideoPlayer } from './player'

interface Props {
  children: ReactNode
}

export const TimeRangeVideoController = ({ children }: Props) => {
  const { srcUrl, ready } = useTimeRangeController()
  return (
    <>
      {ready ? <TimeRangeVideoPlayer src={srcUrl} /> : null}
      {children}
    </>
  )
}

const useTimeRangeController = () => {
  const { videoId, startTime, endTime, videoStartTime, currentTime } =
    useTimeRangePlayerState()
  const { pause, seekTo } = useTimeRangePlayerHelpers()
  const { data, loading } = useGetVideoSrcUrlsQuery({ variables: { videoId } })
  const { setVideoStartTime } = useTimeRangePlayerHelpers()

  const srcUrl = useMemo(() => {
    return getPreferredSrc(data?.video?.vod ?? [], ['720p60', '720p'])
  }, [data?.video?.vod])

  useEffect(() => {
    if (data?.video?.stream?.startedAt) {
      setVideoStartTime(momentjs(data?.video?.stream?.startedAt).valueOf())
    }
  }, [data?.video?.stream?.startedAt, setVideoStartTime])

  const ready = useMemo(() => {
    return !loading && data && videoStartTime !== 0
  }, [data, loading, videoStartTime])

  // check to make sure it isn't finished
  useEffect(() => {
    if (currentTime > endTime) {
      pause()
      seekTo(startTime)
    }
  }, [currentTime, endTime, pause, seekTo, startTime])

  return useMemo(
    () => ({
      srcUrl,
      ready,
    }),
    [srcUrl, ready]
  )
}

const getPreferredSrc = (
  srcs: { resolution: string; url: string }[],
  options: string[]
) => {
  for (const option of options) {
    const src = srcs.find((src) => src.resolution === option)
    if (src) {
      return src.url
    }
  }
  return srcs.find(({ resolution }) => resolution === 'best')?.url ?? ''
}
