import { useAuth0 } from '@auth0/auth0-react'
import React, { useEffect } from 'react'
import { AuthorizationAction, useAuthorizationDispatch } from './context'

export const Auth0AuthProvider: React.FC = ({ children }) => {
  const dispatch = useAuthorizationDispatch()
  const { isAuthenticated, getAccessTokenSilently } = useAuth0()

  useEffect(() => {
    if (!isAuthenticated) {
      return
    }

    const getToken = async () => {
      const token = await getAccessTokenSilently()
      if (token) {
        dispatch({
          type: AuthorizationAction.SetAccessToken,
          payload: { provider: 'auth0', accessToken: token },
        })
      }
    }
    getToken()
  }, [isAuthenticated, getAccessTokenSilently, dispatch])

  return <>{children}</>
}
