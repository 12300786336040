import momentjs from 'moment'
import { useSignalData } from 'core/signal-review/helpers'
import { getValorantOffsets } from 'core/signal-review/reaction'
import { useCallback, useMemo } from 'react'
import {
  useCreateEmptyMomentcollectionMutation,
  useCreateMultiPerspectiveMomentFromReactionSignalMutation,
  useGetMatchMomentCollectionQuery,
  useUpdateMomentCollectionMutation,
  VideoWithOffsetsInput,
} from '__generated__'

export const useMatchAutoCutMoments = (matchId: string) => {
  const slug = useMemo(() => {
    return `match-${matchId}`
  }, [matchId])
  const { data } = useGetMatchMomentCollectionQuery({ variables: { slug } })
  const [createCollection, { called }] =
    useCreateEmptyMomentcollectionMutation()
  const [updateCollection] = useUpdateMomentCollectionMutation()

  const initializeCollection = useCallback(async () => {
    if (!called) {
      const { data } = await createCollection({
        variables: {
          title: `Match ${matchId}`,
        },
      })
      const collectionId = data?.createMomentCollection?.id ?? ''
      // set slug
      await updateCollection({
        variables: {
          momentCollectionId: collectionId,
          momentCollectionInput: { slug },
        },
      })
    }
  }, [called, createCollection, matchId, slug, updateCollection])

  const toggleMoment = useCallback(
    async (signalId: string) => {
      let nextMomentIds: string[] = []
      const currentSignalIds =
        data?.momentCollection?.moments.data.map(({ id }) => id) ?? []
      if (currentSignalIds.includes(signalId)) {
        nextMomentIds = currentSignalIds.filter((id) => id !== signalId) ?? []
      } else {
        nextMomentIds = [...currentSignalIds, signalId]
        // adding, so we need to turn signal into a moment
      }
      await updateCollection({
        variables: {
          momentCollectionId: data?.momentCollection?.id ?? '',
          momentCollectionInput: {
            momentIds: nextMomentIds,
          },
        },
      })
    },
    [data?.momentCollection, updateCollection]
  )

  const includedSignalIds = useMemo(() => {
    return data?.momentCollection?.moments.data.reduce((acc, moment) => {
      return { ...acc, [moment.id]: true }
    }, {})
  }, [data?.momentCollection])

  return useMemo(() => {
    return {
      includedSignalIds,
      toggleMoment,
      initializeCollection,
      hasCollection:
        data?.momentCollection !== undefined && data?.momentCollection !== null,
      moments: data?.momentCollection?.moments.data,
    }
  }, [
    data?.momentCollection,
    includedSignalIds,
    initializeCollection,
    toggleMoment,
  ])
}

export const useTransformSignalToMoment = (signalId: string) => {
  const signalData = useSignalData(signalId)
  const [createMomentMutation] =
    useCreateMultiPerspectiveMomentFromReactionSignalMutation()

  const relatedVideoIds = useMemo(
    () =>
      signalData?.data.relatedVideoData.map((video) => {
        return video.id
      }) ?? [],
    [signalData?.data.relatedVideoData]
  )

  return useCallback(async () => {
    if (!signalData) return

    let offsets: VideoWithOffsetsInput[] = []
    if (
      signalData.type === 'ValorantMoment' ||
      (signalData.type === 'ChatMoment' && signalData.data.valorantMatchId)
    ) {
      offsets = getValorantOffsets(
        signalData.data.relatedVideoData,
        signalData.data.videoId
      )
    } else if (signalData.type === 'ChatMoment') {
      offsets = getValorantOffsets(
        signalData.data.relatedVideoData,
        signalData.data.videoId
      )
    }

    const input = {
      title: `Auto Cut ${signalId}`,
      startsAt: momentjs(signalData.data.signalStartTime).toISOString(),
      endsAt: momentjs(signalData.data.signalEndTime).toISOString(),
      published: true,
      videoIds: [signalData.data.videoId, ...relatedVideoIds],
      mainVideoId: signalData.data.videoId,
      edits: [],
      offsets: offsets,
      tags: [],
      signalIds: [signalId],
    }

    const { data } = await createMomentMutation({
      variables: { createMultiPerspectiveMomentInput: input },
    })
    const createdMomentId = data?.createMultiPerspectiveMoment?.id
    return createdMomentId
  }, [createMomentMutation, relatedVideoIds, signalData, signalId])
}
