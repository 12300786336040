import { useEffect } from 'react'
import { useTheme } from 'styled-components/macro'
import { transparentize } from 'polished'

import { HStack, Spacing } from 'components/core/layout'
import { Switch } from 'components/core/switch'

import { useComposerState } from '../../context'
import { useComposerHelper } from '../../hooks'
import {
  // CropRectContainer,
  // CropRectContainerRow,
  ToggleAddonButton,
  // UnderlineInput,
  // UnderlineInputLabel,
} from '../styled'
import { usePositionInput } from './hooks'

export enum Justifications {
  left = 'left',
  center = 'center',
  right = 'right',
}

export const KDAAddonSettings = () => {
  const { KDAAddon, KDAOptions } = useComposerState()
  const { setKDA, setKDAOptions } = useComposerHelper()

  const y = usePositionInput(KDAOptions.y ?? 545)
  const x = usePositionInput(KDAOptions.x ?? 422)

  useEffect(() => {
    setKDAOptions({
      y: y.value,
      x: x.value,
    })
  }, [y, x, setKDAOptions])

  const theme = useTheme()
  return (
    <>
      <Spacing top={8}>
        <ToggleAddonButton
          color={transparentize(0.5, theme.colors.static.neutrals.n4)}
          onClick={(e) => {
            e.preventDefault()
            setKDA(!KDAAddon)
          }}
        >
          <HStack alignItems="center" gap={8}>
            <Switch checked={KDAAddon} onChange={() => {}} />
            <Spacing right={8}>
              <span>KDA</span>
            </Spacing>
          </HStack>
        </ToggleAddonButton>
      </Spacing>

      <Spacing top={8}>
        {/* {KDAAddon && (
          <>
            <Spacing top={16}>
              <CropRectContainer>
                <CropRectContainerRow>
                  <UnderlineInputLabel>
                    <span>X</span>
                    <UnderlineInput {...x.bind} value={x.value} />
                  </UnderlineInputLabel>
                </CropRectContainerRow>
              </CropRectContainer>
            </Spacing>
            <CropRectContainer>
              <CropRectContainerRow>
                <UnderlineInputLabel>
                  <span>Y</span>
                  <UnderlineInput {...y.bind} value={y.value} />
                </UnderlineInputLabel>
              </CropRectContainerRow>
            </CropRectContainer>
          </>
        )} */}
      </Spacing>
    </>
  )
}
