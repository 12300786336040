import { useCallback, useMemo } from 'react'

import {
  useGetCurrentOrganizationRosterQuery,
  useGetReactionSignalQuery,
  useSetMomentRatingMutation,
} from '__generated__'
import { useWatchLocalStorage } from 'utils/browser'

import { ROSTER_FAVORITE_KEY } from './sidebar/user-item'
import { useCreateMultiPerspectiveMoment } from 'core/shared/moments/hooks'
import { translateRatingInt } from 'core/signal-review/helpers'

const ROSTER_FAVORITES_ONLY_KEY = 'roster_favorites_only'

export const useRosterData = () => {
  const { data: rosterData } = useGetCurrentOrganizationRosterQuery()
  const onlyFavorites = useWatchLocalStorage<boolean>(ROSTER_FAVORITES_ONLY_KEY)
  const localStorageFavorites = useWatchLocalStorage<{
    [key: string]: boolean
  }>(ROSTER_FAVORITE_KEY)

  const toggleOnlyFavorites = useCallback(() => {
    const nextOnlyFavorites =
      onlyFavorites === undefined ? true : !onlyFavorites
    localStorage.setItem(
      ROSTER_FAVORITES_ONLY_KEY,
      JSON.stringify(nextOnlyFavorites)
    )
    window.dispatchEvent(new Event('storage'))
  }, [onlyFavorites])

  const roster = useMemo(() => {
    const rosterList = rosterData?.viewer?.organization?.roster.data
    if (onlyFavorites) {
      return rosterList?.filter(
        ({ id }) => localStorageFavorites?.[id] === true
      )
    }
    return rosterList
  }, [
    localStorageFavorites,
    onlyFavorites,
    rosterData?.viewer?.organization?.roster.data,
  ])

  return useMemo(
    () => ({ roster, toggleOnlyFavorites, onlyFavorites }),
    [onlyFavorites, roster, toggleOnlyFavorites]
  )
}

export const useQuickSave = (
  signalId: string,
  title: string,
  rating?: number
) => {
  const createMomentEditsAndExports = useCreateMultiPerspectiveMoment()
  const { data, refetch } = useGetReactionSignalQuery({
    variables: { signalId },
  })
  const [setRating] = useSetMomentRatingMutation()

  const inputData = useMemo(() => {
    if (!data || !data.moment || data.moment.__typename !== 'ChatMoment') {
      return
    }

    const exportVideoInfo = [
      {
        videoId: data.moment.video.id,
        userId: data.moment.channel.id,
        name: data.moment.channel.name,
      },
    ]

    const signalIds = [data?.moment.id]

    return {
      startTime: data.moment.startsAt,
      endTime: data.moment.endsAt,
      mainVideoId: data.moment.video.id,
      exportVideoInfo,
      signalIds,
    }
  }, [data])

  const save = useCallback(async () => {
    if (!inputData) {
      return
    }
    const momentId = await createMomentEditsAndExports({
      title,
      ...inputData,
      offsets: [],
      relatedVideoIds: [],
    })

    if (momentId && rating) {
      await setRating({
        variables: {
          momentId,
          rating: translateRatingInt(rating),
        },
      })
    }
    await refetch()
  }, [
    createMomentEditsAndExports,
    inputData,
    rating,
    refetch,
    setRating,
    title,
  ])

  return save
}
