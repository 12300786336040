import { useCallback, useMemo } from 'react'
import { useGetSignalPlayerVideoTimelineQuery } from '__generated__'
import { useSignalPlayerUpdater } from './context'

export const useSignalPlayerHelpers = () => {
  const dispatch = useSignalPlayerUpdater()

  const setVisibleRelatedVideoIds = useCallback(
    (nextVisibleIds: string[]) => {
      dispatch({
        type: useSignalPlayerUpdater.ACTION_TYPES.SET_VISIBLE_RELATED_VIDEOS,
        payload: {
          relatedVideoIds: nextVisibleIds,
        },
      })
    },
    [dispatch]
  )

  const setSelectedVideo = useCallback(
    (videoId: string) => {
      dispatch({
        type: useSignalPlayerUpdater.ACTION_TYPES.SET_SELECTED_VIDEO_ID,
        payload: { selectedVideoId: videoId },
      })
    },
    [dispatch]
  )

  return useMemo(
    () => ({
      setVisibleRelatedVideoIds,
      setSelectedVideo,
    }),
    [setSelectedVideo, setVisibleRelatedVideoIds]
  )
}

export const useVideoTimelineData = (videoId: string) => {
  const { data, loading } = useGetSignalPlayerVideoTimelineQuery({
    variables: { videoId },
  })

  // this should be where more helper functions exist
  return useMemo(
    () => ({
      data,
      loading,
    }),
    [data, loading]
  )
}
