import { ReactNode, useMemo } from 'react'
import { useTheme } from 'styled-components/macro'
import momentjs from 'moment'
import { Link } from 'react-router-dom'

import {
  ReactionFeedDetailFragment,
  useGetReactionSignalQuery,
} from '__generated__'
import { HStack, Spacing, VStack } from 'components/core/layout'
import { Text } from 'components/core/text'
import { TimeRangePlayer } from 'components/timerange-player'
import { ReactComponent as CheckCircleIcon } from 'icons/svgs/solid/check-circle.svg'
import { RootOnly } from 'core/auth/components'
import {
  useValorantMultiPerspectiveMoments,
  valorantMomentIdHash,
} from 'page/feed/helpers'

import {
  Backdrop,
  CardContent,
  PreviewModalContent,
  PreviewModalContainer,
  PreviewModalRoot,
  ProfileImg,
  UserInfoRow,
  VideoContainer,
  CloseButton,
} from './styled'
import { Portal } from './portal'
import { CloseIcon } from './close-icon'
import { Button } from 'components/core/button'
import { EditPlayer } from './edit-player'

type Props = {
  openReview?: () => void
  signalId: string
  onClose?: () => void
  useEditIfAvailable?: boolean
  children?: ReactNode
}

export const ReactionPreviewModal = ({
  openReview,
  signalId,
  onClose,
  children,
  useEditIfAvailable = false,
}: Props) => {
  const { data } = useGetReactionSignalQuery({ variables: { signalId } })
  const moment = useMemo(() => {
    if (data?.moment && data.moment.__typename === 'ChatMoment')
      return data.moment
  }, [data?.moment])

  return moment ? (
    <ReactionPreviewModalContent
      useEditIfAvailable={useEditIfAvailable}
      openReview={openReview}
      moment={moment}
      onClose={onClose}
      children={children}
    />
  ) : null
}

type ContentProps = {
  openReview?: () => void
  moment: ReactionFeedDetailFragment
  onClose?: () => void
  useEditIfAvailable?: boolean
  children?: ReactNode
}

export const ReactionPreviewModalContent = ({
  openReview,
  moment,
  onClose,
  children,
  useEditIfAvailable = false,
}: ContentProps) => {
  const [valorantMultiPerspectiveMoments] = useValorantMultiPerspectiveMoments()

  const { name, profileImageUrl } = useFormattedMoment(moment)

  // const scoreboardProps = useMatchScoreboardData(moment)
  const theme = useTheme()
  const editId = useMemo(() => {
    if (
      moment.usedIn &&
      moment.usedIn[0] &&
      moment.usedIn[0].__typename === 'MultiPerspectiveMoment'
    ) {
      return moment.usedIn[0].edits[0]?.id
    }
  }, [moment])

  const multiPerspectiveMoment = useMemo(() => {
    const hashId = valorantMomentIdHash(
      moment.channel.id,
      moment.startsAt,
      moment.endsAt
    )
    const moments = valorantMultiPerspectiveMoments[hashId]
    if (!moments) return undefined
    return moments[0]
  }, [
    moment.channel.id,
    moment.endsAt,
    moment.startsAt,
    valorantMultiPerspectiveMoments,
  ])

  return (
    <Portal>
      <PreviewModalRoot>
        <Backdrop $opacity={0.5} />
        <PreviewModalContainer>
          <PreviewModalContent>
            <CloseButton onClick={onClose} style={{ zIndex: 10 }}>
              <CloseIcon />
            </CloseButton>
            <VideoContainer>
              {useEditIfAvailable && editId ? (
                <EditPlayer editId={editId} />
              ) : (
                <MomentPreviewPlayer
                  videoId={moment.video.id}
                  startsAt={moment.startsAt}
                  endsAt={moment.endsAt}
                  autoPlay={true}
                />
              )}
            </VideoContainer>
            <CardContent>
              <VStack>
                <UserInfoRow $bottomPadding={0}>
                  <HStack alignItems="center">
                    <ProfileImg src={profileImageUrl} />
                    <Spacing left="8px">
                      <VStack>
                        <Text variant="text-2" weight="semibold">
                          {name}
                        </Text>
                        <HStack gap={8} alignItems="baseline">
                          <Text variant="text-3" weight="semibold">
                            {moment.type}
                          </Text>
                          <Text variant="small" weight="semibold">
                            {(
                              parseFloat(moment.metadata?.score ?? 0) * 100
                            ).toFixed(0)}
                            %
                          </Text>
                        </HStack>
                      </VStack>
                    </Spacing>
                  </HStack>
                  <HStack justifyContent="center" alignItems="center">
                    <RootOnly>
                      {multiPerspectiveMoment ? (
                        <>
                          <Link to={`/moment/${multiPerspectiveMoment}`}>
                            <CheckCircleIcon
                              width={24}
                              height={24}
                              fill={theme.colors.green}
                            />
                          </Link>
                          <Spacing right={8} />
                        </>
                      ) : null}
                    </RootOnly>
                    {children}
                    {openReview && (
                      <Button size="small" onClick={openReview}>
                        Review Moment
                      </Button>
                    )}
                  </HStack>
                </UserInfoRow>
                {/* <MatchDataRow>
                  <HighlightDataContainer>
                    <ul>
                      <li>
                        <HStack gap={4}>
                          <Text
                            variant="text-2"
                            color={theme.colors.text.secondary}
                            weight="semibold"
                          >
                            Score:
                          </Text>
                          <Text
                            variant="text-2"
                            color={theme.colors.text.secondary}
                          >
                            {parseFloat(moment.metadata?.score ?? 0) * 100}
                          </Text>
                        </HStack>
                      </li>
                      <li>
                        <HStack gap={4}>
                          <pre>
                            <Text
                              variant="small"
                              color={theme.colors.text.secondary}
                              mono={true}
                            >
                              {JSON.stringify(moment.metadata, null, 2)}
                            </Text>
                          </pre>
                        </HStack>
                      </li>
                    </ul>
                  </HighlightDataContainer>
                  <ScoreboardContainer>
                    //  <MatchScoreboard {...scoreboardProps} /> 
                  </ScoreboardContainer>
                </MatchDataRow> */}
              </VStack>
            </CardContent>
          </PreviewModalContent>
        </PreviewModalContainer>
      </PreviewModalRoot>
    </Portal>
  )
}

const useFormattedMoment = (m: ReactionFeedDetailFragment) => {
  return useMemo(
    () => ({
      name: m.channel.name,
      profileImageUrl: m.channel.profileImageUrl ?? '',
      duration: Math.ceil(
        (new Date(m.endsAt).getTime() - new Date(m.startsAt).getTime()) / 1000
      ),
    }),
    [m]
  )
}

// const useMatchScoreboardData = (m: FeedValorantMomentFragment) => {
//   return useMemo(
//     () => ({
//       matchId: m.match.id,
//       matchStartedAt: m.match.startedAt,
//       matchEndedAt: m.match.endedAt,
//     }),
//     [m]
//   )
// }

type MomentPreviewPlayerProps = {
  videoId: string
  startsAt: string
  endsAt: string
  autoPlay?: boolean
}

const MomentPreviewPlayer = ({
  videoId,
  startsAt,
  endsAt,
  autoPlay,
}: MomentPreviewPlayerProps) => {
  const absoluteTimes = useMemo(() => {
    return {
      startTime: momentjs(startsAt).valueOf(),
      endTime: momentjs(endsAt).valueOf(),
    }
  }, [endsAt, startsAt])

  return (
    <TimeRangePlayer {...absoluteTimes} videoId={videoId} autoPlay={autoPlay} />
  )
}
