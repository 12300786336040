import React, { useEffect } from 'react'
import momentjs from 'moment'

import { SyncedVideo } from 'core/editor/synced-video'
import { Timeline } from 'core/editor/timeline'
import { useTimelineHelpers } from 'core/editor/timeline/hooks'
import { Editor } from 'core/editor'

import { useVideoMomentsData } from './hooks'
import { MomentChatDisplay } from './chat-display'
import { ReviewVideoSidebar } from './sidebar'
import {
  VideoReviewChatDiv,
  VideoReviewContentDiv,
  VideoReviewPageDiv,
  VideoReviewSidebarDiv,
  VideoReviewTimelineDiv,
} from './styled'

interface VideoReviewProps {
  videoId: string
}

export const VideoReview: React.FC<VideoReviewProps> = ({ videoId }) => {
  useVideoReviewData(videoId)

  return (
    <Editor>
      <VideoReviewPageDiv>
        <VideoReviewSidebarDiv>
          <ReviewVideoSidebar videoId={videoId} />
        </VideoReviewSidebarDiv>
        <VideoReviewContentDiv>
          <SyncedVideo
            id={videoId}
            videoId={videoId}
            primaryVideoId={videoId}
            controller={true}
          ></SyncedVideo>
        </VideoReviewContentDiv>
        <VideoReviewTimelineDiv>
          <Timeline>
            <div style={{ width: '100%', height: 100 }}></div>
          </Timeline>
        </VideoReviewTimelineDiv>
        <VideoReviewChatDiv>
          <MomentChatDisplay videoId={videoId} />
        </VideoReviewChatDiv>
      </VideoReviewPageDiv>
    </Editor>
  )
}

const useVideoReviewData = (videoId: string) => {
  const { streamEnd, streamStart } = useVideoMomentsData(videoId)
  const { setTimelineStartAndEnd, setTimelineLength } = useTimelineHelpers()

  useEffect(() => {
    if (streamStart && streamEnd) {
      const streamStartInMs = momentjs(streamStart).valueOf()
      const streamEndInMs = momentjs(streamEnd).valueOf()
      setTimelineStartAndEnd(streamStartInMs, streamEndInMs)
    }
  }, [setTimelineStartAndEnd, streamStart, streamEnd, setTimelineLength])
}
