import momentjs from 'moment'
import { useCallback, useEffect, useMemo } from 'react'

import { Scene, TemplateCropSettings } from 'core/exporter/constants'
import { DEFAULT_CONTENT_TYPES } from 'core/shared/templates/constants'
import { cloneWithoutTypename, randomUniqueId } from 'utils'
import { useLocalTheme } from 'utils/hooks'
import { Button } from 'components/core/button'
import { useExportEdit } from 'page/moment/exporter/hooks'
import {
  MultiPerspectiveMomentDetailFragment,
  SceneFragment,
  TemplateCropFragment,
  useGetMomentDetailQuery,
  useGetMomentEditLazyQuery,
  useGetValorantMatchesForVideoLazyQuery,
} from '__generated__'
import { SceneEditor } from './editor'
import { useComposerHelper, useUpdateTimesForPerspectiveSwitch } from './hooks'
import { SceneTimeline } from './scene-timeline'
import {
  EditOptionsDiv,
  EditorPageContainerDiv,
  SceneEditorDiv,
} from './styled'
import { useComposerState } from './context'
import { useSceneDisplay } from './scene-timeline/item'
import { useHistory } from 'react-router'

interface Props {
  momentId: string
  editId: string
}

export const Composer = ({ momentId, editId }: Props) => {
  const { selectedFormat, ready } = useComposerEditorData(momentId, editId)
  const { location, goBack } = useHistory()
  const exportEdit = useExportEdit(momentId, editId)
  useEditLoad(editId)
  useLocalTheme('dark')

  // this is for tracking where we came from, to know if we can adjust history
  const handleExport = useCallback(() => {
    const state = location?.state as { referrer: string } | undefined
    const referrer = state?.referrer as any
    exportEdit()
    if (referrer !== undefined) {
      goBack()
    }
  }, [exportEdit, goBack, location?.state])

  return (
    <EditorPageContainerDiv>
      <SceneEditorDiv>
        {ready && selectedFormat && (
          <SceneEditor momentId={momentId} editId={editId} />
        )}
      </SceneEditorDiv>
      <SceneTimeline />
      <EditOptionsDiv>
        <Button size="small" onClick={handleExport}>
          Export
        </Button>
      </EditOptionsDiv>
    </EditorPageContainerDiv>
  )
}

const useEditLoad = (editId: string) => {
  const [query, { data, called }] = useGetMomentEditLazyQuery()
  const { setFormat, addScene, setTitle, clearScenes } = useComposerHelper()
  const { loadSceneData } = useSceneDisplay()
  const { selectedSceneId } = useComposerState()

  useEffect(() => {
    if (editId && !called) {
      query({ variables: { editId } })
    }
  }, [called, editId, query])

  useEffect(() => {
    if (data?.momentEdit) {
      setFormat(data.momentEdit.format)

      // console.log(data.momentEdit.scenes.map((sceneFrag) => sceneFrag.id))
      clearScenes()
      data.momentEdit.scenes.forEach((sceneFragment: SceneFragment) => {
        const scene = sceneFragmentToScene(sceneFragment)
        addScene(scene)
      })

      setTitle(data.momentEdit.title ?? '')
    }
  }, [addScene, clearScenes, data, setFormat, setTitle])

  useEffect(() => {
    if (data?.momentEdit && selectedSceneId === undefined) {
      const sceneFrag = data?.momentEdit.scenes[0]
      if (sceneFrag) {
        const scene = sceneFragmentToScene(sceneFrag)
        loadSceneData(scene)
      }
    }
  }, [data?.momentEdit, loadSceneData, selectedSceneId])
}

const useComposerEditorData = (momentId, editId) => {
  const { data } = useGetMomentDetailQuery({ variables: { momentId } })
  const [getValorantMatchInfo, { data: valorantMatchData }] =
    useGetValorantMatchesForVideoLazyQuery()
  const { selectedFormat, primaryVideoId } = useComposerState()
  const {
    setMomentId,
    setFormat,
    setPrimaryVideoId,
    setValorantMatchId,
    setEditId,
    setVideosData,
  } = useComposerHelper()
  const switchPerspectives = useUpdateTimesForPerspectiveSwitch()

  const moment = useMemo(() => {
    return data?.moment as MultiPerspectiveMomentDetailFragment | undefined
  }, [data?.moment])
  const mainVideoId = useMemo(() => moment?.mainVideo.id, [moment])

  useEffect(() => {
    if (valorantMatchData?.video?.valorantMatches) {
      const momentStartInMs = momentjs(moment?.startsAt).valueOf()
      const momentEndInMs = momentjs(moment?.endsAt).valueOf()
      const match = valorantMatchData.video.valorantMatches.find(
        ({ startedAt, endedAt }) => {
          const matchStartInMs = momentjs(startedAt).valueOf()
          const matchEndInMs = momentjs(endedAt).valueOf() + 120 * 1000
          return (
            momentStartInMs < matchEndInMs && momentEndInMs > matchStartInMs
          )
        }
      )
      if (match) {
        setValorantMatchId(match.id)
      }
    }
  }, [
    moment?.endsAt,
    moment?.startsAt,
    setValorantMatchId,
    valorantMatchData?.video?.valorantMatches,
  ])

  useEffect(() => {
    if (primaryVideoId) {
      getValorantMatchInfo({ variables: { videoId: primaryVideoId } })
    }
  }, [getValorantMatchInfo, primaryVideoId])

  useEffect(() => {
    if (primaryVideoId === '' && mainVideoId) {
      setPrimaryVideoId(mainVideoId)
    }
  }, [mainVideoId, primaryVideoId, setPrimaryVideoId])

  useEffect(() => {
    setEditId(editId)
  }, [editId, setEditId])

  useEffect(() => {
    setMomentId(momentId)
  }, [momentId, setMomentId])

  useEffect(() => {
    const nextData =
      moment?.videos.map((vid) => ({
        videoId: vid.id,
        userId: vid.user?.id ?? '',
      })) ?? []
    setVideosData(nextData)
  }, [moment, setVideosData])

  // check for primary perspective switch so we can update time and
  // offsets to be based on primary stream
  useEffect(() => {
    if (primaryVideoId !== '') {
      switchPerspectives()
    }
    // console.log({ primaryVideoId })
  }, [primaryVideoId, switchPerspectives])

  return useMemo(
    () => ({
      ready: primaryVideoId !== '',
      selectedFormat,
      setFormat,
    }),
    [primaryVideoId, selectedFormat, setFormat]
  )
}

const sceneFragmentToScene: (sceneFragment: SceneFragment) => Scene = (
  sceneFragment: SceneFragment
) => {
  const sceneData = cloneWithoutTypename(sceneFragment)
  let primaryTitle = sceneData.cropData[0]?.title ?? ''
  Object.entries(sceneData.cropData).forEach(([_, v]) => {
    if (v.muted === false) {
      primaryTitle = v.title
    }
  })

  // we need to set primary since the backend and the front-end work differently
  // backend relies on `muted` for content while front-end uses primary for
  // 1 template crop
  const parsedTemplateCrops: TemplateCropSettings[] =
    sceneData.template.crops.map((tc: TemplateCropFragment) => {
      return {
        ...tc,
        primary: primaryTitle === tc.title,
        localId: randomUniqueId(),
        defaultContent: DEFAULT_CONTENT_TYPES.CUSTOM,
      }
    })

  const overlaysInputFormat =
    sceneData?.overlays?.map((overlay) => {
      return {
        overlayType: overlay.overlayType,
        data: overlay.data,
      }
    }) ?? []

  return {
    id: sceneData.id,
    start: momentjs(sceneData.start).valueOf(),
    end: momentjs(sceneData.end).valueOf(),
    template: { ...sceneData.template, crops: parsedTemplateCrops },
    cropData: sceneData.cropData,
    index: sceneData.index,
    overlays: overlaysInputFormat,
  }
}
