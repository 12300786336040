import styled from 'styled-components/macro'

export const MomentEditorPage = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.background.secondary};
`

export const EditorPageContainerDiv = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-areas:
    'scene-editor'
    'scene-timeline';
  grid-template-rows: 1fr 100px;
`

export const SceneEditorDiv = styled.div`
  grid-area: scene-editor;
  background-color: ${({ theme }) => theme.colors.neutrals.n6};
  overflow: hidden;
`

export const SceneTimelineDiv = styled.div`
  width: 100%;
  height: 100%;
  grid-area: scene-timeline;
  // background-color: ${({ theme }) => theme.colors.neutrals.n5};
  padding: 24px 0;
  border-top: 1px solid ${({ theme }) => theme.colors.neutrals.n6};
`

export const SceneItemDiv = styled.div<{ $selected: boolean; $width?: string }>`
  position: relative;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 8px;
  // width: ${({ $width }) => $width};
  background-color: ${({ theme }) => theme.colors.neutrals.n6};
  color: ${({ theme, $selected }) =>
    $selected ? theme.colors.neutrals.n8 : theme.colors.neutrals.n8};
  cursor: pointer;
  border: 1px solid
    ${({ $selected, theme }) =>
      $selected ? theme.colors.gold : theme.colors.neutrals.n6};

  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`

export const SceneListDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 1px;
`

export const SceneContentDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.neutrals.n1};

  & > div:last-child {
    padding: 8px;
  }
`

export const PreviewContainerDiv = styled.div`
  position: relative;
  display: grid;
  height: 100%;
  width: 100%;
  grid-column-gap: 32px;
  grid-template-columns: 1fr 290px;
  grid-template-rows: minmax(0, 1fr) auto;
  grid-template-areas:
    'template-preview   template-settings'
    'timeline-controls  timeline-controls';
`

export const TimelineContainerDiv = styled.div`
  position: relative;
  grid-area: timeline-controls;
`

export const TemplateSettingsDiv = styled.div`
  grid-area: template-settings;
`
export const TemplateSettingsContentDiv = styled.div`
  position: relative;
  margin: 32px 32px 0 0;
  border-radius: 16px;
  padding: 16px;
  background-color: #353945;
  height: calc(100% - 32px);
  width: calc(100% - 32px);
  overflow: hidden;
`

export const TemplateSettingsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  gap: 32px 0px;
  grid-template-areas: 'layers' 'settings';
  margin-top: 12px;
`

export const TemplateAddonsContainerDiv = styled.div`
  position: relative;
  height: 100%;
  margin-top: 12px;
`

export const TemplateSettingsLayersRow = styled.div`
  grid-area: layers;
`

export const TemplateSettingsPropertiesRow = styled.div`
  grid-area: settings;
`

export const AddonsHeadingDiv = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 24px;
`

export const TemplatePreviewContainerDiv = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  grid-area: template-preview;
`

export const TemplatePreviewContentsDiv = styled.div`
  position: relative;
  margin: 32px 0 0 32px;
  background-color: #353945;
  border-radius: 16px;
  width: calc(100% - 32px);
  height: calc(100% - 32px);
  overflow: hidden;
`

export const AspectRatioContainerDiv = styled.div`
  position: relative;
  aspect-ratio: 16/9;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`

export const ContentDiv = styled.div<{ $width: number; $height: number }>`
  position: absolute;
  aspect-ratio: ${({ $width, $height }) => `${$width}/${$height}`};
  overflow: hidden;
`

export const SceneEditOptionsDiv = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: calc(100% + 5px);
  /* height: 32px; */
  border-radius: 4px;
  padding: 8px;
  background: ${({ theme }) => theme.colors.neutrals.n8};
  color: ${({ theme }) => theme.colors.neutrals.n2};
  border: 1px solid ${({ theme }) => theme.colors.neutrals.n3};
`

export const CropSettingsDiv = styled.div<{ $selected: boolean }>`
  position: relative;
  width: 100%;
  border: 1px solid ${(p) => (p.$selected ? 'red' : '#ccc')};
  margin: 8px 0;
  padding: 12px;
  border-radius: 4px;
  /* cursor: pointer; */
`

export const CropSettingsActionsDiv = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  select {
    height: 32px;
  }
`

export const EditOptionsDiv = styled.div`
  position: fixed;
  right: 8px;
  bottom: 8px;
`
