import styled from 'styled-components/macro'

export const ValorantMatchPageDiv = styled.div`
  position: relative;
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-areas:
    'sidebar-playlist match-video'
    'sidebar-playlist match-timeline';
  grid-template-columns: 250px auto;
  grid-template-rows: 1fr 250px;
  grid-row-gap: 24px;
`

export const SidebarPlaylist = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  grid-area: sidebar-playlist;
  overflow: hidden;
  padding: 16px 0 0 16px;
`

export const ValorantMatchVideoDiv = styled.div`
  grid-area: match-video;
  background-color: #ccc;
`

export const ValorantMatchTimelineDiv = styled.div`
  grid-area: match-timeline;
  background-color: #eee;
`

export const TimelineContainer = styled.div`
  position: relative;
  width: 100%;
  height: 50px;
  display: flex;
  border: 1px solid ${({ theme }) => theme.colors.neutrals.n5};
  border-width: 1px 0;
`
