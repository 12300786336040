import { useEffect, useMemo, useState } from 'react'

export interface SubtitleData {
  text: string
  start: number
  end: number
}

export const useSubtitles = (
  videoId: string,
  startsAt: number,
  endsAt: number
) => {
  const [subtitleData, setSubtitleData] = useState<SubtitleData[]>()
  const startTime = useMemo(() => new Date(startsAt).toISOString(), [startsAt])
  const endTime = useMemo(() => new Date(endsAt).toISOString(), [endsAt])
  const url = `https://scribe-api.dustin-lakin6545.workers.dev/transcript`

  useEffect(() => {
    const fetchSubtitles = async () => {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ videoId, startTime, endTime }),
      })
      const data = (await response.json()) as {
        data: {
          text: string
          start: string
          end: string
        }[]
      }
      const parsedData = data?.data.map((d) => ({
        ...d,
        start: new Date(d.start + 'Z').valueOf(),
        end: new Date(d.end + 'Z').valueOf(),
      }))

      setSubtitleData(parsedData)
    }

    fetchSubtitles()
  }, [url, videoId, startTime, endTime])

  return useMemo(
    () => ({
      subtitleData,
    }),
    [subtitleData]
  )
}

export const useFindAllSubtitlesSlideshow = (
  subtitleData: SubtitleData[] | undefined,
  startTime: number,
  endTime: number
) => {
  return useMemo(() => {
    // find all subtitles that start after startTime and end before endTime
    const results = subtitleData?.filter(
      (subtitle) => subtitle.start >= startTime && subtitle.end <= endTime
    )

    return results?.map((subtitle) => subtitle.start) ?? []
  }, [subtitleData, startTime, endTime])
}

// We should rework this for useMemo
export const useGetSubtitleFrame = (
  subtitleData: SubtitleData[] | undefined,
  currentTime: number | undefined
) => {
  const [currentSubtitle, setCurrentSubtitle] = useState<SubtitleData>()

  useEffect(() => {
    if (!subtitleData || !currentTime) return

    const currentSubtitle = subtitleData.find(
      (subtitle) => currentTime >= subtitle.start && currentTime <= subtitle.end
    )

    setCurrentSubtitle(currentSubtitle)
  }, [subtitleData, currentTime])

  return useMemo(
    () => ({
      currentSubtitle,
    }),
    [currentSubtitle]
  )
}
