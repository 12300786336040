import React from 'react'
import styled from 'styled-components/macro'

import { Card, Container, Content, Spacing } from 'components/core/layout'
import { Logo } from 'components/core/logo'
import { Login } from 'core/navigation/login'

export const LoginPage: React.FC = () => (
  <Content>
    <Container alignItems="center" flex="1">
      <Logo />
    </Container>
    <StyledContainer alignSelf="center">
      <Spacing top={128}>
        <Card>
          <Card.Body>
            <Card.Title>Login</Card.Title>
            <Spacing bottom={64}>
              <Card.Text>Please login to access your organization</Card.Text>
            </Spacing>
          </Card.Body>
          <Card.Footer alignItems="flex-end">
            <Login variant="primary" rounded={false} size="small" />
          </Card.Footer>
        </Card>
      </Spacing>
    </StyledContainer>
  </Content>
)

const StyledContainer = styled(Container)`
  max-width: 420px;
  width: 100%;
`
