import styled, { css } from 'styled-components/macro'
import { darken } from 'polished'

import { fontWeights } from 'theme/typography'

export const Icon = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  svg {
    display: block;
  }
`

export const FilterGroupTitle = styled.div<{ indicator?: boolean }>`
  padding: 12px 0;
  position: relative;
  font-weight: ${fontWeights.semibold};
  cursor: pointer;
  user-select: none;

  &::before {
    content: '';
    position: absolute;
    width: 4px;
    height: 4px;
    border-radius: 2px;
    background-color: ${({ theme }) => darken(0.1, theme.colors.blue)};
    left: -8px;
    top: 50%;
    transform: translateY(-50%);

    transition: opacity 0.15s ease-in-out;

    opacity: 0;
  }

  ${({ indicator }) => css`
    &::before {
      opacity: ${indicator ? 1 : 0};
    }
  `}
`

export const FilterList = styled.ul``
export const FilterRow = styled.li`
  padding: 6px 0;
`

export const FilterRowLabel = styled.span`
  margin-left: 4px;
  color: ${({ theme }) => theme.colors.text.secondary};
`

export const FilterGroupContainer = styled.div`
  padding: 8px 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutrals.n6};

  &:last-child {
    border-bottom: none;
  }
`
