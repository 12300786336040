import styled, { css } from 'styled-components/macro'
import { Button } from 'components/core/button'
import { monoFontStyle } from 'theme/typography'

export const LayerOptionDiv = styled.div<{
  $selected: boolean
  $layerColor: string
}>`
  position: relative;
  width: 100%;
  height: 32px;

  background-color: rgba(119, 126, 144, 0.2);
  border-radius: 4px;
  margin-bottom: 8px;
  border: 1px solid rgba(119, 126, 144, 0.2);
  border-width: 1px 1px 1px 5px;
  padding: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;

  border-left-color: ${({ $layerColor }) => $layerColor};

  ${({ $selected, $layerColor }) =>
    $selected &&
    css`
      border-color: ${$layerColor};
    `}
`

export const UnderlineInputLabel = styled.label`
  grid-column-end: span 13;
  display: flex;
  width: 100%;
  align-items: center;

  &:last-child {
    margin-left: 16px;
  }

  & > span {
    user-select: none;
    display: block;
    height: 32px;
    width: 32px;
    flex: 0 0 32px;
    line-height: 32px;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.neutrals.n4};
  }
`
export const UnderlineInput = styled.input.attrs({ type: 'text' })`
  width: 100%;
  padding: 0 0 0 7px;
  background-color: transparent;
  border-bottom: 2px solid ${({ theme }) => theme.colors.neutrals.n5};
  color: ${({ theme }) => theme.colors.neutrals.n3};

  font-size: 12px;
  outline: none;

  ${monoFontStyle};
`

export const CropRectContainer = styled.div``

export const CropRectContainerRow = styled.div`
  display: grid;
  grid-template-columns: repeat(28, 8px);
`

export const IconButton = styled(Button).attrs({
  variant: 'primary',
  size: 'small',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`

export const AdminControlsDiv = styled.div`
  position: sticky;
  bottom: 8px;
  right: 8px;
  display: flex;
  justify-content: flex-end;
`

export const ToggleAddonButton = styled(Button).attrs({
  size: 'small',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 2px 4px;
`
