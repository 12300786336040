import React, { useMemo } from 'react'
import { useEditorState } from '../context'
import { useTimelineState } from './context'
import { CurrentTimeDiv } from './styled'

export const CurrentTimeIndicator: React.FC = () => {
  const { currentTime } = useEditorState()
  const { startTime, endTime } = useTimelineState()

  const style = useMemo(
    () => ({
      left: `${((currentTime - startTime) / (endTime - startTime)) * 100}%`,
    }),
    [currentTime, endTime, startTime]
  )

  return <CurrentTimeDiv style={style} />
}
