import { useContext, useMemo, useState, createContext } from 'react'
import { useTheme } from 'styled-components/macro'
import momentjs from 'moment'

import {
  ContentMomentFragment,
  FeedValorantMomentFragment,
  useGetContentMomentDetailQuery,
} from '__generated__'
import { HStack, Spacing, VStack } from 'components/core/layout'
import { Text } from 'components/core/text'
import { TimeRangePlayer } from 'components/timerange-player'
import { uppercaseFirstLetter } from 'utils/strings'
import { ReactComponent as StarFullIcon } from 'icons/svgs/solid/star.svg'

import {
  Backdrop,
  CardContent,
  HighlightDataContainer,
  MatchDataRow,
  PreviewModalContainer,
  PreviewModalRoot,
  ProfileImg,
  ScoreboardContainer,
  UserInfoRow,
  VideoContainer,
  CloseButton,
} from 'core/feed/components/preview-modal/styled'
import { CloseIcon } from 'core/feed/components/preview-modal/close-icon'
import { MatchScoreboard } from 'core/feed/components/scoreboard'
import { Portal } from 'components/dialog/portal'
import { translateRatingEnum } from 'core/signal-review/helpers'
import { MomentEdits } from './moment-edits'
import {
  MomentEditsContainerDiv,
  MomentEditsWrapperDiv,
  PreviewModalContentDiv,
} from './styled'
import { PreviewEditVideo } from './preview-video'
import { AddToCollectionButton } from 'core/moment-collections/add-button'
// import { CreateNewEditButton } from 'core/feed/components/create-edit'
import { PreviewStarContainerDiv } from 'core/moment-viewer/styled'
import { PreviewStarDiv } from 'core/signal-review/controls/rating'
// This should be reworked to work with signal + moment
// import { RootOnly } from 'core/auth/components'
// import {
//   useValorantMultiPerspectiveMoments,
//   valorantMomentIdHash,
// } from 'page/feed/helpers'
// import { Link } from 'react-router-dom'

type SharedProps = {
  onClose?: () => void
}

type Props = {
  momentId: string
} & SharedProps

export type MomentModalContextProps = {
  momentId: string
  selectedEditId?: string
  setSelectedEditId: (selectedId: string | undefined) => void
}
export const MomentModalContext = createContext<MomentModalContextProps>({
  momentId: '',
  selectedEditId: undefined,
  setSelectedEditId: () => {},
})

export const MomentModal = ({ momentId, ...rest }: Props) => {
  const [selectedEditId, setSelectedEditId] = useState<string | undefined>()
  const contextValue = useMemo(
    () => ({ selectedEditId, setSelectedEditId, momentId }),
    [momentId, selectedEditId]
  )
  const { data } = useGetContentMomentDetailQuery({
    variables: { momentId },
  })
  const moment = useMemo(() => {
    if (data?.moment?.__typename === 'MultiPerspectiveMoment') {
      return data.moment
    }
  }, [data?.moment])

  return moment ? (
    <MomentModalContext.Provider value={contextValue}>
      <MomentModalContent moment={moment} {...rest} />
    </MomentModalContext.Provider>
  ) : null
}

type ContentProps = { moment: ContentMomentFragment } & SharedProps

const MomentModalContent = ({ moment, onClose }: ContentProps) => {
  const { momentId, selectedEditId } = useContext(MomentModalContext)
  const signal = useMemo(() => moment.signals[0], [moment])
  const rating = useMemo(
    () =>
      moment.ratings.length > 0
        ? translateRatingEnum(moment.ratings[0].rating)
        : undefined,
    [moment.ratings]
  )

  // TODO: DUSTIN This should be reworked to work with signal + moment
  // const multiPerspectiveMoment = useMemo(() => {
  //   const hashId = valorantMomentIdHash(moment.channel.id, start, end)
  //   const moments = valorantMultiPerspectiveMoments[hashId]
  //   if (!moments) return undefined
  //   return moments[0]
  // }, [end, moment.channel.id, start, valorantMultiPerspectiveMoments])

  return (
    <Portal>
      <PreviewModalRoot>
        <Backdrop $opacity={0.8} />
        <PreviewModalContainer>
          <CloseButton onClick={onClose}>
            <CloseIcon />
          </CloseButton>
          <HStack gap={12}>
            {selectedEditId ? (
              <PreviewEditVideo momentId={momentId} editId={selectedEditId} />
            ) : (
              <PreviewModalContentDiv>
                <VideoContainer>
                  <MomentPreviewPlayer
                    videoId={moment.video.id}
                    startsAt={moment.startsAt}
                    endsAt={moment.endsAt}
                  />
                </VideoContainer>
                <CardContent>
                  <VStack>
                    <UserInfoRow>
                      <HStack alignItems="center">
                        <ProfileImg
                          src={moment.channel.profileImageUrl ?? ''}
                        />
                        <Spacing left="8px">
                          <VStack>
                            <Text variant="text-2" weight="semibold">
                              {moment.channel.name}
                            </Text>
                            {/* <Text variant="text-3">{agent}</Text> */}
                          </VStack>
                        </Spacing>
                      </HStack>
                      <HStack justifyContent="center" alignItems="center">
                        {/* <AddToCollectionButton momentId={moment.id} /> */}
                        {/* <Button size="small" onClick={setEdit}>
                        Create edit
                      </Button> */}
                        {/* <RootOnly>
                      {multiPerspectiveMoment ? (
                        <>
                          <Link to={`/moment/${multiPerspectiveMoment}`}>
                            <CheckCircleIcon
                              width={24}
                              height={24}
                              fill={theme.colors.green}
                            />
                          </Link>
                          <Spacing right={8} />
                        </>
                      ) : null}
                    </RootOnly> */}
                        {rating ? (
                          <PreviewStarContainerDiv>
                            {Array(5)
                              .fill('')
                              .map((_, idx) => (
                                <PreviewStarDiv
                                  key={idx}
                                  $selected={idx + 1 <= rating}
                                >
                                  <StarFullIcon />
                                </PreviewStarDiv>
                              ))}
                          </PreviewStarContainerDiv>
                        ) : null}
                      </HStack>
                    </UserInfoRow>
                    <MatchDataRow>
                      {signal && signal.__typename === 'ValorantMoment' && (
                        <ValorantSignalInfo signal={signal} />
                      )}
                      {/* <HighlightDataContainer>
                    <ul>
                      <li>
                        <HStack gap={4}>
                          <Text
                            variant="text-2"
                            color={theme.colors.text.secondary}
                            weight="semibold"
                          >
                            Score:
                          </Text>
                          <Text
                            variant="text-2"
                            color={theme.colors.text.secondary}
                          >
                            {parseFloat(moment.metadata?.score ?? 0) * 100}
                          </Text>
                        </HStack>
                      </li>
                      <li>
                        <HStack gap={4}>
                          <pre>
                            <Text
                              variant="small"
                              color={theme.colors.text.secondary}
                              mono={true}
                            >
                              {JSON.stringify(moment.metadata, null, 2)}
                            </Text>
                          </pre>
                        </HStack>
                      </li>
                    </ul>
                  </HighlightDataContainer> */}
                      {/* </HighlightDataContainer> */}
                      {/* <ScoreboardContainer>
                    <MatchScoreboard {...scoreboardProps} />
                  </ScoreboardContainer> */}
                    </MatchDataRow>
                  </VStack>
                </CardContent>
              </PreviewModalContentDiv>
            )}
            <MomentEditsWrapperDiv>
              <VStack justifyContent="space-between">
                <MomentEditsContainerDiv>
                  <MomentEdits momentId={moment.id} />
                </MomentEditsContainerDiv>
                <Spacing bottom={16}>
                  <VStack gap={8} alignItems="center">
                    {/* <CreateNewEditButton momentId={moment.id} /> */}
                    <AddToCollectionButton
                      momentId={moment.id}
                      variant="primary"
                    />
                  </VStack>
                </Spacing>
              </VStack>
            </MomentEditsWrapperDiv>
          </HStack>
        </PreviewModalContainer>
      </PreviewModalRoot>
    </Portal>
  )
}

interface ValorantSignalProps {
  signal: FeedValorantMomentFragment
}

const ValorantSignalInfo = ({ signal }: ValorantSignalProps) => {
  const { kills, duration, map, combatScore } = useFormattedMoment(signal)
  const scoreboardProps = useMatchScoreboardData(signal)
  const theme = useTheme()

  return (
    <>
      <HighlightDataContainer>
        <ul>
          <li>
            <HStack gap={4}>
              <Text
                variant="text-2"
                color={theme.colors.text.secondary}
                weight="semibold"
              >
                {kills} kills over {duration} seconds
              </Text>
            </HStack>
          </li>
          <li>
            <HStack gap={4}>
              <Text
                variant="text-2"
                color={theme.colors.text.secondary}
                weight="semibold"
              >
                Map:
              </Text>
              <Text variant="text-2" color={theme.colors.text.secondary}>
                {map}
              </Text>
            </HStack>
          </li>
          <li>
            <HStack gap={4}>
              <Text
                variant="text-2"
                color={theme.colors.text.secondary}
                weight="semibold"
              >
                Score:
              </Text>
              <Text variant="text-2" color={theme.colors.text.secondary}>
                {combatScore}
              </Text>
            </HStack>
          </li>
        </ul>
      </HighlightDataContainer>
      <ScoreboardContainer>
        <MatchScoreboard {...scoreboardProps} />
      </ScoreboardContainer>
    </>
  )
}

const useFormattedMoment = (m: FeedValorantMomentFragment) => {
  return useMemo(
    () => ({
      name: m.channel.name,
      profileImageUrl: m.channel.profileImageUrl ?? '',
      kills: m.kills.length,
      duration: Math.ceil(
        (new Date(m.endsAt).getTime() - new Date(m.startsAt).getTime()) / 1000
      ),
      map: uppercaseFirstLetter(m.match.map || 'Unknown'),
      agent: uppercaseFirstLetter(m.agent),
      combatScore: m.combatScore,
    }),
    [m]
  )
}

const useMatchScoreboardData = (m: FeedValorantMomentFragment) => {
  return useMemo(
    () => ({
      matchId: m.match.id,
    }),
    [m]
  )
}

type MomentPreviewPlayerProps = {
  videoId: string
  startsAt: string
  endsAt: string
}

const MomentPreviewPlayer = ({
  videoId,
  startsAt,
  endsAt,
}: MomentPreviewPlayerProps) => {
  const absoluteTimes = useMemo(() => {
    return {
      startTime: momentjs(startsAt).valueOf(),
      endTime: momentjs(endsAt).valueOf(),
    }
  }, [endsAt, startsAt])

  return <TimeRangePlayer {...absoluteTimes} videoId={videoId} />
}
