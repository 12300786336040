import { AspectRatioContainer } from 'components/aspect-ratio/container'
import React, { useState } from 'react'

import {
  AspectRatio,
  MomentExportFragment,
  RecordingStatus,
} from '__generated__'
import { ReactComponent as PlayCircleIcon } from 'icons/svgs/solid/play-circle.svg'
import { ReactComponent as FileDownloadIcon } from 'icons/svgs/solid/file-download.svg'
import { ReactComponent as CloseIcon } from 'icons/svgs/solid/times.svg'

import {
  ActionBarDiv,
  ClosePreviewDiv,
  ExportPreviewDiv,
  PreviewVideoContentDiv,
  PreviewVideoDiv,
} from '../styled'
import { Text } from 'components/core/text'

interface MomentExportPreviewProps {
  momentExport: MomentExportFragment
  formatAspectRatio: AspectRatio
}

export const MomentExportPreview: React.FC<MomentExportPreviewProps> = ({
  momentExport,
  formatAspectRatio,
}) => {
  const [showingVideo, setShowingVideo] = useState(false)
  const downloadUrl = momentExport.output?.videos?.[0]?.url
  const previewUrl = momentExport.output?.thumbnails?.[0]?.url

  return (
    <ExportPreviewDiv>
      <AspectRatioContainer
        height={formatAspectRatio.height}
        width={formatAspectRatio.width}
      >
        {momentExport.status === RecordingStatus.Completed ? (
          <>
            {previewUrl ? <img src={previewUrl} alt="export thumb" /> : null}
            <ActionBarDiv>
              <PlayCircleIcon
                onClick={() => {
                  setShowingVideo(!showingVideo)
                }}
              />
              <a href={downloadUrl}>
                <FileDownloadIcon />
              </a>
            </ActionBarDiv>
            {showingVideo && (
              <PreviewVideoDiv>
                <AspectRatioContainer
                  height={formatAspectRatio.height}
                  width={formatAspectRatio.width}
                >
                  <PreviewVideoContentDiv>
                    <video src={downloadUrl} controls={true} />
                  </PreviewVideoContentDiv>
                </AspectRatioContainer>
                <ClosePreviewDiv onClick={() => setShowingVideo(false)}>
                  <CloseIcon />
                </ClosePreviewDiv>
              </PreviewVideoDiv>
            )}
          </>
        ) : (
          <Text variant="h6" textAlign="center">
            {momentExport.status}
          </Text>
        )}
      </AspectRatioContainer>
    </ExportPreviewDiv>
  )
}

/* <div> */
/* {momentExport.id}- {momentExport.status}{' '} */
/* {url ? <a href={url}>download</a> : null} */
/* </div> */
