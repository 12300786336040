import React, { useCallback, useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Route, Switch, useHistory, useRouteMatch } from 'react-router'
import { LOGIN_PATH, LOGOUT_PATH } from 'core/auth/constants'
import {
  GetCurrentOrganizationQuery,
  useGetCurrentOrganizationLazyQuery,
  useRedeemBetaKeyMutation,
} from '__generated__'
import {
  Card,
  Container,
  Content,
  HStack,
  Spacing,
  VStack,
} from 'components/core/layout'
import { StyledContainer } from './styled'
import { Logo } from 'components/core/logo'
import { Button } from 'components/core/button'
import styled from 'styled-components/macro'
// import jwtDecode from 'jwt-decode'
// import { UserPayload } from 'core/auth/protected-route'

export const AuthPage: React.FC = () => {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <Route path={`${path}/login`}>
        <Login />
      </Route>
      <Route path={`${path}/logout`}>
        <Logout />
      </Route>
    </Switch>
  )
}

export const UnauthorizedPage = () => {
  const { replace } = useHistory()
  const [error, setError] = useState<boolean>(false)
  const [betaKey, setBetaKey] = useState<string>('')
  const [redeemBetaKey] = useRedeemBetaKeyMutation()

  //focus on the input when the page loads
  useEffect(() => {
    const input = document.querySelector('input')
    input?.focus()
  }, [])

  const submitKey = async () => {
    if (!betaKey || betaKey === '') return

    try {
      const { data, errors } = await redeemBetaKey({
        variables: {
          betaKey: betaKey,
        },
      })
      // console.log(data?.useBetaKey?.organization?.name)
      if (data && data.useBetaKey?.organization && !errors) {
        replace(`/`)
      }
    } catch (e) {
      setError(true)
      setBetaKey('')
    }
  }

  return (
    <Content>
      <Container alignItems="center" flex="1">
        <Logo />
      </Container>
      <StyledContainer alignSelf="center">
        <Spacing top={128}>
          <Card>
            <Card.Body>
              <VStack alignItems="flex-end" gap={16}>
                <HStack alignItems="center" gap={8}>
                  <Card.Title style={{ width: 200 }}>Beta Key:</Card.Title>
                  <StyledInput
                    type="text"
                    value={betaKey}
                    onChange={(event) => {
                      setBetaKey(event.target.value)
                      setError(false)
                    }}
                    $error={error}
                  />
                </HStack>
                <div>
                  <Button onClick={submitKey}>Enter Beta</Button>
                </div>
              </VStack>
              {/* <Card.Text>
                You're not part of any organization yet. Please contact a mmnts
                administrator.
              </Card.Text> */}
            </Card.Body>
            <Card.Footer alignItems="flex-end">
              <Button
                as="a"
                href={LOGOUT_PATH}
                size="small"
                variant="secondary"
              >
                Log out
              </Button>
            </Card.Footer>
          </Card>
        </Spacing>
      </StyledContainer>
    </Content>
  )
}

const Login = () => {
  // const { isAuthenticated, getAccessTokenSilently } = useAuth0()
  const { isAuthenticated } = useAuth0()
  const [fetch, { data, called }] = useGetCurrentOrganizationLazyQuery()
  const history = useHistory()
  useEffect(() => {
    if (!called && isAuthenticated) {
      fetch()
    }
  }, [isAuthenticated, called, fetch])

  const fn = useCallback(
    async (data: GetCurrentOrganizationQuery) => {
      // const token = await getAccessTokenSilently()
      // const payload = jwtDecode<UserPayload>(token)
      // const isRootUser = payload.permissions?.includes('root') || false
      // const slug = data.viewer?.organization?.slug
      // if (isRootUser) {
      //   if (slug) {
      //     history.push(`/o/${slug}`)
      //   } else {
      //     history.push(`/unauthorized`)
      //   }
      //   return
      // }
      const roster = data?.viewer?.organization?.roster?.data

      if (
        data.viewer?.organization === undefined ||
        roster === undefined ||
        roster.length === 0
      ) {
        history.push(`/unauthorized`)
        return
      }
      if (roster.length === 1) {
        history.push(`/channel/${roster[0].name}`)
      } else {
        history.push(`/roster`)
      }
    },
    [history]
  )

  useEffect(() => {
    if (!data) {
      return
    }

    fn(data)
  }, [data, fn])

  return <></>
}

const Logout = () => {
  const { logout } = useAuth0()
  const returnTo = new URL(LOGIN_PATH, window.location.origin)
  logout({ returnTo: returnTo.toString() })
  return <></>
}

const StyledInput = styled.input<{ $error: boolean }>`
  height: 60px;
  width: 100%;
  font-size: 24px;
  padding: 8px;
  border: 1px solid
    ${({ theme, $error }) =>
      $error ? theme.colors.rose : theme.colors.neutrals.n6};
  border-radius: 4px;
`
