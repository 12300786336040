import React, { createContext, ReactNode, useContext, useReducer } from 'react'

export type SignalTypes = 'ChatMoment' | 'ValorantMoment' | 'ManualSignalMoment'

export enum MomentViews {
  OVERVIEW = 'OVERVIEW',
  EXPORTS = 'EXPORTS',
  ORGANIZE = 'ORGANIZE',
}

interface SignalReviewState {
  signalType: SignalTypes
  signalId: string
  momentId?: string // this is a connected MultiPerspective moment for editing
  rating: number | undefined
  tags: string[]
  title: string
  relatedVideoIds: string[]
  showRelatedVideoModal: boolean
  showMomentActionsModal: boolean
  localOffsets: { [videoId: string]: number }
}

const INITAL_STATE = {
  momentId: undefined,
  rating: undefined,
  tags: [],
  title: '',
  relatedVideoIds: [],
  showRelatedVideoModal: false,
  showMomentActionsModal: false,
  localOffsets: {},
}

enum ACTION_TYPES {
  SET_RATING = 'SET_RATING',
  SET_TAGS = 'SET_TAGS',
  SET_TITLE = 'SET_TITLE',
  SET_MOMENT_ID = 'SET_MOMENT_ID',
  SET_RELATED_VIDEO_IDS = 'SET_RELATED_VIDEO_IDS',
  SET_SHOW_RELATED_VIDEO_MODAL = 'SET_SHOW_RELATED_VIDEO_MODAL',
  SET_SHOW_MOMENT_ACTIONS_MODAL = 'SET_SHOW_MOMENT_ACTIONS_MODAL',
  SET_LOCAL_OFFSETS = 'SET_LOCAL_OFFSETS',
}

interface setRatingAction {
  type: ACTION_TYPES.SET_RATING
  payload: {
    rating: number | undefined
  }
}

interface setTagsAction {
  type: ACTION_TYPES.SET_TAGS
  payload: {
    tags: string[]
  }
}

interface setTitleAction {
  type: ACTION_TYPES.SET_TITLE
  payload: {
    title: string
  }
}

interface setMomentIdAction {
  type: ACTION_TYPES.SET_MOMENT_ID
  payload: {
    momentId: string | undefined
  }
}

interface setRelatedVideoIdsAction {
  type: ACTION_TYPES.SET_RELATED_VIDEO_IDS
  payload: {
    relatedVideoIds: string[]
  }
}

interface setShowRelatedVideosModalAction {
  type: ACTION_TYPES.SET_SHOW_RELATED_VIDEO_MODAL
  payload: {
    showRelatedVideoModal: boolean
  }
}

interface setShowMomentActionsModalAction {
  type: ACTION_TYPES.SET_SHOW_MOMENT_ACTIONS_MODAL
  payload: {
    showMomentActionsModal: boolean
  }
}

interface setLocalOffsetsAction {
  type: ACTION_TYPES.SET_LOCAL_OFFSETS
  payload: {
    offsets: { [videoId: string]: number }
  }
}

type SignalReviewActions =
  | setRatingAction
  | setTagsAction
  | setTitleAction
  | setMomentIdAction
  | setRelatedVideoIdsAction
  | setShowRelatedVideosModalAction
  | setShowMomentActionsModalAction
  | setLocalOffsetsAction

type Dispatch = (action: SignalReviewActions) => void

const reducer = (
  state: SignalReviewState,
  action: SignalReviewActions
): SignalReviewState => {
  switch (action.type) {
    case ACTION_TYPES.SET_RATING: {
      return {
        ...state,
        rating: action.payload.rating,
      }
    }

    case ACTION_TYPES.SET_TAGS:
      return {
        ...state,
        tags: action.payload.tags,
      }

    case ACTION_TYPES.SET_TITLE:
      return {
        ...state,
        title: action.payload.title,
      }

    case ACTION_TYPES.SET_MOMENT_ID: {
      return {
        ...state,
        momentId: action.payload.momentId,
      }
    }

    case ACTION_TYPES.SET_RELATED_VIDEO_IDS: {
      return {
        ...state,
        relatedVideoIds: action.payload.relatedVideoIds,
      }
    }
    case ACTION_TYPES.SET_SHOW_RELATED_VIDEO_MODAL: {
      return {
        ...state,
        showRelatedVideoModal: action.payload.showRelatedVideoModal,
      }
    }

    case ACTION_TYPES.SET_SHOW_MOMENT_ACTIONS_MODAL: {
      return {
        ...state,
        showMomentActionsModal: action.payload.showMomentActionsModal,
      }
    }

    case ACTION_TYPES.SET_LOCAL_OFFSETS: {
      return {
        ...state,
        localOffsets: action.payload.offsets,
      }
    }

    default:
      return state
  }
}

const SignalReviewContext = createContext<SignalReviewState | undefined>(
  undefined
)
const SignalReviewUpdaterContext = createContext<Dispatch | undefined>(
  undefined
)

interface Props {
  signalType: SignalTypes
  signalId: string
  children: ReactNode
}

export const SignalReviewProvider = ({
  children,
  signalType,
  signalId,
}: Props) => {
  const [state, dispatch] = useReducer(reducer, {
    ...INITAL_STATE,
    signalType,
    signalId,
  })

  return (
    <SignalReviewContext.Provider value={state}>
      <SignalReviewUpdaterContext.Provider value={dispatch}>
        {children}
      </SignalReviewUpdaterContext.Provider>
    </SignalReviewContext.Provider>
  )
}

export const useSignalReviewState = () => {
  const context = useContext(SignalReviewContext)
  if (context === undefined) {
    throw new Error(
      'useSignalReviewState must be used within a SignalReviewProvider'
    )
  }
  return context
}

export const useSignalReviewUpdater = () => {
  const context = useContext(SignalReviewUpdaterContext)
  if (context === undefined) {
    throw new Error(
      'useSignalReviewUpdater must be used within a SignalReviewProvider'
    )
  }
  return context
}

useSignalReviewUpdater.ACTION_TYPES = ACTION_TYPES
