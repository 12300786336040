import React, { useCallback, useMemo } from 'react'
import { useTemplateEditorState } from './context'
import { useTemplateEditorHelpers } from './hooks'

interface PreviewContentSelectProps {
  videosData: { videoId: string; userId: string; displayName: string }[]
  cropTitle: string
}

export const PreviewContentSelect: React.FC<PreviewContentSelectProps> = ({
  videosData,
  cropTitle,
}) => {
  const { content, setContent } = usePreviewContentSelectData(cropTitle)

  return (
    <select
      value={content}
      onChange={(evt) => setContent(evt.currentTarget.value)}
    >
      <option value="">Select Preview</option>
      {videosData.map(({ videoId, displayName }) => (
        <option key={videoId} value={videoId}>
          {displayName}
        </option>
      ))}
    </select>
  )
}

const usePreviewContentSelectData = (cropTitle: string) => {
  const { previewContent } = useTemplateEditorState()
  const { setPreviewContent } = useTemplateEditorHelpers()

  const content = useMemo(() => {
    return previewContent[cropTitle]
  }, [cropTitle, previewContent])

  const setContent = useCallback(
    (videoId: string) => {
      const nextVideoId = videoId === '' ? undefined : videoId
      setPreviewContent(cropTitle, nextVideoId)
    },
    [cropTitle, setPreviewContent]
  )

  return useMemo(
    () => ({
      content,
      setContent,
    }),
    [content, setContent]
  )
}
