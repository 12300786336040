import { useEffect, useMemo } from 'react'

import { FilterGroup } from 'core/feed/components/filter-group'
import { useQueryParamFilters } from 'utils/browser'
import { useFilterForm } from 'core/feed/helpers'
import { usePrevious } from 'utils'
import { isEqual } from 'lodash'

const savedOptions = [{ label: 'Only Saved Moments', value: 'true' }]

export const MomentFeedFilters = () => {
  const { params, setParam } = useQueryParamFilters()

  const savedParam = useMemo(() => {
    const savedParams = params['saved']
    if (!savedParams) return []
    if (Array.isArray(savedParams)) {
      return savedParams
    } else {
      return [savedParams]
    }
  }, [params])
  const {
    data: savedData,
    bind: savedBind,
    selected: savedSelected,
  } = useFilterForm(savedOptions, false, savedParam)

  const momentFilters = useMemo(() => {
    return {
      saved: savedSelected,
    }
  }, [savedSelected])

  const prevFilters = usePrevious(momentFilters)

  useEffect(() => {
    for (const [key, value] of Object.entries(momentFilters)) {
      if (
        value === undefined ||
        (typeof value === 'object' && value.length === 0)
      ) {
        setParam(key, undefined)
        continue
      }
      setParam(key, value)
    }
  }, [momentFilters, setParam])

  useEffect(() => {
    if (!isEqual(prevFilters, momentFilters) && prevFilters !== undefined) {
      setParam('page', undefined)
    }
  }, [prevFilters, momentFilters, setParam])

  return (
    <div>
      <FilterGroup title="Saved" data={savedData} {...savedBind} />
    </div>
  )
}
