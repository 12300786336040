import React, { useMemo } from 'react'

import { useResizeSelection } from 'core/editor/timeline/hooks'
import { useTimelineSelection } from 'core/editor/timeline/selection-area'
import { SceneContainerDiv, SceneDragDiv } from './styled'

interface Props {
  containerRect: DOMRect | undefined
}

export const TimelineSelection = ({ containerRect }: Props) => {
  const {
    localSelection,
    updateLocalSelection,
    contentWidth,
    setGlobalSelection,
  } = useTimelineSelection(containerRect)

  const { sliderRef: leftSlider } = useResizeSelection(
    contentWidth,
    'startPct',
    localSelection ?? { startPct: 0, endPct: 0 },
    updateLocalSelection,
    setGlobalSelection
  )

  const { sliderRef: rightSlider } = useResizeSelection(
    contentWidth,
    'endPct',
    localSelection ?? { startPct: 0, endPct: 0 },
    updateLocalSelection,
    setGlobalSelection
  )

  const selectionStyles = useMemo(() => {
    if (localSelection === undefined) {
      return {
        display: 'none',
      }
    }

    return {
      width: `${(localSelection.endPct - localSelection.startPct) * 100}%`,
      left: `${localSelection.startPct * 100}%`,
    }
  }, [localSelection])

  return localSelection ? (
    <SceneContainerDiv style={selectionStyles}>
      <SceneDragDiv $type="left" ref={leftSlider} />
      <SceneDragDiv $type="right" ref={rightSlider} />
    </SceneContainerDiv>
  ) : null
}
