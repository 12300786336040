import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Link } from 'react-router-dom'

import { HStack, Spacing } from 'components/core/layout'
import { Text } from 'components/core/text'
import { usePrevious } from 'utils'
import { ReactComponent as CircleIcon } from 'icons/svgs/solid/circle.svg'
import { ReactComponent as StarFullIcon } from 'icons/svgs/solid/star.svg'

import { useMomentViewerState } from './context'
import { EditSelector } from './edit-selector'
import { useMomentViewerData, useMomentViewerHelpers } from './hooks'
import {
  IsUnreadContainerDiv,
  MomentItemDiv,
  MomentPreviewImageDiv,
  MomentTextDiv,
  PreviewStarContainerDiv,
  PreviewStarDiv,
} from './styled'
import { RootOnly } from 'core/auth/components'

interface Props {
  momentId: string
  includeUnapproved: boolean
}

export const MomentItem = forwardRef<HTMLDivElement, Props>(
  ({ momentId, includeUnapproved }, ref) => {
    const { selectedMomentId, selectedEditId } = useMomentViewerState()
    const { momentData, defaultEdit, defaultImg } = useMomentViewerData(
      momentId,
      selectedEditId,
      includeUnapproved
    )
    const { setSelectedMomentId, setSelectedEditId } = useMomentViewerHelpers()
    const [selectEditWhenReady, setSelectEditWhenReady] = useState(false)
    const prevMomentId = usePrevious(selectedMomentId)
    const isSelected = useMemo(
      () => selectedMomentId === momentId,
      [momentId, selectedMomentId]
    )
    const selectMoment = useCallback(() => {
      setSelectedMomentId(momentId)
    }, [momentId, setSelectedMomentId])
    const rawRating = window.localStorage.getItem(`moment-rating:${momentId}`)
    const rating = rawRating ? parseInt(rawRating, 10) : undefined

    // this switches a flag, since defaultEdit.id isn't always ready yet
    useEffect(() => {
      if (prevMomentId !== selectedMomentId && selectedMomentId === momentId) {
        setSelectEditWhenReady(true)
      }
    }, [
      defaultEdit,
      momentId,
      prevMomentId,
      selectEditWhenReady,
      selectedMomentId,
      setSelectedEditId,
    ])

    useEffect(() => {
      if (selectEditWhenReady && defaultEdit?.id !== undefined) {
        setSelectedEditId(defaultEdit?.id ?? '')
        setSelectEditWhenReady(false)
      }
    }, [
      defaultEdit?.id,
      momentId,
      selectEditWhenReady,
      selectedEditId,
      selectedMomentId,
      setSelectedEditId,
    ])

    const editCount = useMemo(
      () => momentData?.edits.length ?? 0,
      [momentData?.edits.length]
    )

    return (
      <MomentItemDiv $selected={isSelected} onClick={selectMoment} ref={ref}>
        <Spacing all={16}>
          <UnreadMarker isUnread={momentData?.isUnread ?? false} />
          <HStack>
            <MomentPreviewImageDiv $backgroundUrl={defaultImg} />
            <MomentTextDiv>
              <Text variant="text-2" as="p" weight="semibold">
                {momentData?.title}
              </Text>
              <Text variant="text-3" as="p">
                {editCount} Edit{editCount > 1 ? 's' : null}
              </Text>
              <RootOnly>
                {rating ? (
                  <Link to={`/moment/${momentId}`}>
                    <PreviewStarContainerDiv>
                      {Array(5)
                        .fill('')
                        .map((_, idx) => (
                          <PreviewStarDiv
                            key={idx}
                            $selected={idx + 1 <= rating}
                          >
                            <StarFullIcon />
                          </PreviewStarDiv>
                        ))}
                    </PreviewStarContainerDiv>
                  </Link>
                ) : null}
              </RootOnly>
            </MomentTextDiv>
          </HStack>
        </Spacing>
        {isSelected ? (
          <EditSelector includeUnapproved={includeUnapproved} />
        ) : null}
      </MomentItemDiv>
    )
  }
)

interface UnreadMarkerProps {
  isUnread: boolean
}

const UnreadMarker: React.FC<UnreadMarkerProps> = ({ isUnread }) => {
  return isUnread ? (
    <IsUnreadContainerDiv>
      {' '}
      <CircleIcon />{' '}
    </IsUnreadContainerDiv>
  ) : null
}
