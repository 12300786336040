import momentjs from 'moment'
import { useMemo } from 'react'

import { durationFormatHours } from 'utils/time'
import { Spacing, VStack } from 'components/core/layout'
import { Text } from 'components/core/text'
import {
  VideoHeadingContainerDiv,
  VideoHeadingImage,
  VideoHeadingImageContainerDiv,
} from './styled'

type Props = {
  title: string
  startedAt: string
  endedAt?: string
  thumbnailUrl?: string
  fullImage?: boolean
}
export const VideoHeading = ({
  title,
  thumbnailUrl,
  startedAt,
  endedAt,
}: Props) => {
  const duration = useMemo(() => {
    if (!endedAt) return undefined
    return durationFormatHours(
      (momentjs(endedAt).valueOf() - momentjs(startedAt).valueOf()) / 1000
    )
  }, [endedAt, startedAt])

  const thumbnail = useMemo(() => {
    if (!thumbnailUrl) return undefined
    return thumbnailUrl.replace('%{width}', '1280').replace('%{height}', '720')
  }, [thumbnailUrl])
  return (
    <VideoHeadingContainerDiv>
      <VideoHeadingImageContainerDiv>
        <VideoHeadingImage backgroundImageUrl={thumbnail || ''} />
      </VideoHeadingImageContainerDiv>
      <div>
        <VStack>
          <Spacing bottom={8}>
            <Text variant="text-1" weight="bold" as="p">
              {title}
            </Text>
          </Spacing>

          <Spacing bottom={8}>
            <VStack>
              <Text variant="text-2">
                {momentjs(startedAt).format('MMMM Do, YYYY - h:mm:ss a')}
              </Text>
              <Text variant="text-3">
                ({momentjs(startedAt || '').fromNow()})
              </Text>
            </VStack>
          </Spacing>

          {duration && <Text variant="text-3">{duration}</Text>}
        </VStack>
      </div>
    </VideoHeadingContainerDiv>
  )
}
