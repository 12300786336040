import { keyBy } from 'lodash'
import { useCallback, useMemo, useState } from 'react'

import { ReactComponent as SyncIcon } from 'icons/svgs/solid/sync.svg'
import { HStack } from 'components/core/layout'
import { Text } from 'components/core/text'
import { SyncPlayerButtonDiv } from 'core/valorant-match/editor/styled'
import {
  useAddRelatedVideoMutation,
  useGetEventCollectionQuery,
  useUpdateRelatedVideoMutation,
} from '__generated__'

import { EVENT_DATA, USERS } from './data'
import { Portal } from 'components/dialog/portal'
import { FullPageModalDiv } from './styled'
import { RelatedSync } from 'core/related-sync'

export const EventCreator = () => {
  // const [unsavedSyncs, setUnsavedSyncs] = useState<{
  //   [syncId: string]: {
  //     primaryVideoId: string
  //     secondaryVideoId: string
  //     offset: number
  //   }
  // }>({})
  const { dataByVideoId, relatedVideos, savedRelatedDataById, refetch } =
    useEventData()
  const { save: saveMutation } = useRelatedVideoMutations(
    relatedVideos ?? {},
    savedRelatedDataById
  )
  const [syncingVideo, setSyncingVideo] = useState<
    | {
        primaryVideoId: string
        secondaryVideoId: string
        start: string
        end: string
      }
    | undefined
  >()

  const setSync = useCallback(
    (
      primaryVideoId: string,
      secondaryVideoId: string,
      start: string,
      end: string
    ) => {
      setSyncingVideo({ primaryVideoId, secondaryVideoId, start, end })
    },
    []
  )

  // const unsavedOptions = useMemo(() => {
  //   const unsaved = Object.values(unsavedSyncs)
  //   const suggestions = []

  //   return [...unsaved, ...suggestions]
  // }, [unsavedSyncs])

  // const save = useCallback(
  //   ({
  //     primaryVideoId,
  //     secondaryVideoId,
  //     offset,
  //   }: {
  //     primaryVideoId: string
  //     secondaryVideoId: string
  //     offset: number
  //   }) => {
  //     setUnsavedSyncs((currentState) => ({
  //       ...currentState,
  //       [`${primaryVideoId}_${secondaryVideoId}`]: {
  //         primaryVideoId,
  //         secondaryVideoId,
  //         offset,
  //       },
  //     }))
  //   },
  //   []
  // )

  return (
    <>
      <Text variant="h1">{EVENT_DATA.title}</Text>
      {dataByVideoId &&
        relatedVideos &&
        Object.values(relatedVideos).map(({ primary, secondary, overlap }) => {
          return (
            <HStack>
              <Text variant="small">
                {dataByVideoId[primary].username} --&gt;
                {savedRelatedDataById[`${primary}_${secondary}`]?.offset} --&gt;
                {dataByVideoId[secondary].username} [{overlap.length}]
              </Text>
              <SyncPlayerButtonDiv
                onClick={() =>
                  setSync(primary, secondary, overlap[0].start, overlap[0].end)
                }
                $active={false}
              >
                <SyncIcon />
              </SyncPlayerButtonDiv>
            </HStack>
          )
        })}
      {/* {dataByVideoId &&
        unsavedOptions.map((option) => (
          <Text variant="small">
            {dataByVideoId[option.primaryVideoId].username} --&gt;{' '}
            {option.offset} --&gt;
            {dataByVideoId[option.secondaryVideoId].username}
          </Text>
        ))} */}
      {syncingVideo && (
        <Portal>
          <FullPageModalDiv>
            <RelatedSync
              {...syncingVideo}
              offset={0}
              save={async (offset: number) => {
                await saveMutation(
                  syncingVideo.primaryVideoId,
                  syncingVideo.secondaryVideoId,
                  offset
                )
                refetch()
                setSyncingVideo(undefined)
              }}
              cancel={() => setSyncingVideo(undefined)}
            />

            {/* primaryVideoId={syncingVideo.primaryId} secondaryVideoId={syncingVideo.secondaryId} start={} end={} offset={0} /> */}
          </FullPageModalDiv>
        </Portal>
      )}
    </>
  )
}

const useEventData = () => {
  const { data, refetch } = useGetEventCollectionQuery({
    variables: {
      collectionId: EVENT_DATA.channelCollectionId,
      start: EVENT_DATA.start,
      end: EVENT_DATA.end,
    },
    errorPolicy: 'all',
  })

  // const dataWithVideos = useMemo(
  //   () =>
  //     data?.channelCollection?.channels.data.filter(
  //       (item) => item.streams.data[0]?.video
  //     ),
  //   [data?.channelCollection?.channels.data]
  // )

  const videoData = useMemo(() => {
    if (!data) return undefined
    return data?.channelCollection?.channels.data
      .filter((channel) => channel.id !== USERS.Mizkif)
      ?.map((channel) => {
        const vid = channel.streams.data[0]?.video
        return {
          userId: channel.id,
          username: channel.name,
          videoId: vid?.id,
          relatedVideos:
            vid?.relatedVideos.map(({ offset, video }) => ({
              id: `${vid.id}_${video.id}`,
              primaryVideoId: vid.id,
              secondaryVideoId: video.id,
              offset,
            })) ?? [],
        }
      })
      .filter((item) => item !== undefined)
  }, [data])

  const savedRelatedDataById = useMemo(() => {
    if (!videoData) return {}
    return keyBy(
      videoData.map(({ relatedVideos }) => relatedVideos?.flat() ?? []).flat(),
      'id'
    )
  }, [videoData])

  const dataByUserId = useMemo(() => {
    if (!videoData) return undefined
    return keyBy(videoData, 'userId')
  }, [videoData])

  const dataByVideoId = useMemo(() => {
    if (!videoData) return undefined
    return keyBy(videoData, 'videoId')
  }, [videoData])

  const requiredVideoSyncs = useMemo(() => {
    if (!dataByUserId) return undefined
    const matchInfo: { videoData; start: string; end: string }[] = []
    for (const match of EVENT_DATA.matches) {
      const usersInMatch = match.teams.reduce((acc, item) => {
        return [...acc, ...item.users.flat()]
      }, [] as string[])
      matchInfo.push({
        start: match.start,
        end: match.end,
        videoData: usersInMatch.map((userId) => dataByUserId[userId]),
      })
    }
    return matchInfo
  }, [dataByUserId])

  const relatedVideos = useMemo(() => {
    if (!requiredVideoSyncs) return undefined
    const relatedContent: { [key: string]: any } = {}

    for (const match of requiredVideoSyncs) {
      const overlap = { start: match.start, end: match.end }
      for (const primaryVideoData of match.videoData) {
        for (const secondaryVideoData of match.videoData) {
          if (!primaryVideoData || !secondaryVideoData) continue
          if (primaryVideoData.videoId === secondaryVideoData.videoId) continue
          const mapping = {
            id: `${primaryVideoData.videoId}_${secondaryVideoData.videoId}`,
            primary: primaryVideoData.videoId,
            secondary: secondaryVideoData.videoId,
          }
          if (!relatedContent[mapping.id]) {
            relatedContent[mapping.id] = {
              ...mapping,
              overlap: [],
            }
          }
          const currentOverlap = relatedContent[mapping.id].overlap
          relatedContent[mapping.id].overlap = [...currentOverlap, overlap]
        }
      }
    }
    return relatedContent
  }, [requiredVideoSyncs])

  return useMemo(
    () => ({ relatedVideos, dataByVideoId, savedRelatedDataById, refetch }),
    [dataByVideoId, refetch, relatedVideos, savedRelatedDataById]
  )
}

const useRelatedVideoMutations = (
  videoData: { [key: string]: any },
  savedRelatedDataById: {
    [id: string]: {
      id: string
      primaryVideoId: string
      secondaryVideoId: string
      offset: null | number | undefined
    }
  }
) => {
  const [addMutation] = useAddRelatedVideoMutation()
  const [updateMutation] = useUpdateRelatedVideoMutation()

  const save = useCallback(
    async (
      primaryVideoId: string,
      secondaryVideoId: string,
      offset: number
    ) => {
      const overlaps = videoData[
        `${primaryVideoId}_${secondaryVideoId}`
      ].overlap.map(({ start, end }) => ({ from: start, to: end }))
      // check to see if it exists
      const input = { primaryVideoId, secondaryVideoId, offset, overlaps }
      if (savedRelatedDataById[`${primaryVideoId}_${secondaryVideoId}`]) {
        await updateMutation({
          variables: input,
        })
      } else {
        await addMutation({
          variables: input,
        })
      }

      const secondaryInput = {
        primaryVideoId: secondaryVideoId,
        secondaryVideoId: primaryVideoId,
        offset: -offset,
        overlaps,
      }
      if (savedRelatedDataById[`${secondaryVideoId}_${primaryVideoId}`]) {
        await updateMutation({
          variables: secondaryInput,
        })
      } else {
        await addMutation({
          variables: secondaryInput,
        })
      }
    },
    [addMutation, savedRelatedDataById, updateMutation, videoData]
  )

  return useMemo(() => ({ save }), [save])
}
