import { throttle } from 'lodash'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useDimensions } from 'utils/browser'
import { useEditor } from '../hooks'

import { useTimelineState, useTimelineUpdater } from './context'

export const useTimelineHelpers = () => {
  const { containerRect, endTime, startTime, timelineLength } =
    useTimelineState()
  const dispatch = useTimelineUpdater()
  const { setSelection, seekTo } = useEditor()
  const getContentWidthFromTime = useGetTimeToContentsWidth(
    containerRect?.width ?? 1,
    timelineLength
  )

  const setOffsetPct = useCallback(
    (nextOffsetPct: number) => {
      dispatch({
        type: useTimelineUpdater.ACTION_TYPES.SET_OFFSET_PCT,
        payload: {
          offsetPct: nextOffsetPct,
        },
      })
    },
    [dispatch]
  )

  const setTimelineStartAndEnd = useCallback(
    (startTime: number, endTime: number) => {
      dispatch({
        type: useTimelineUpdater.ACTION_TYPES.SET_TIMES,
        payload: {
          startTime,
          endTime,
        },
      })
    },
    [dispatch]
  )

  // offset helper
  const centerTimelineAtTime = useCallback(
    (timeToCenter: number) => {
      const timeFromStreamStart = timeToCenter - startTime
      const nextPct =
        timeFromStreamStart / (endTime - startTime - timelineLength)
      const halfTimelineWindowPct =
        timelineLength / 2 / (endTime - startTime - timelineLength)
      const nextPctCentered = nextPct - halfTimelineWindowPct
      return setOffsetPct(nextPctCentered)
    },
    [endTime, setOffsetPct, startTime, timelineLength]
  )

  const setSelectionPct = useCallback(
    (nextSelectionPct: { startPct: number; endPct: number }) => {
      dispatch({
        type: useTimelineUpdater.ACTION_TYPES.SET_SELECTION_PCT,
        payload: {
          selectionPct: nextSelectionPct,
        },
      })
    },
    [dispatch]
  )

  // setting with start and end time in ms
  const setSelectionToTime = useCallback(
    (selectionStartTime: number, selectionEndTime: number) => {
      setSelection({
        startTime: selectionStartTime,
        endTime: selectionEndTime,
      })

      const timelineTotalTime = endTime - startTime
      const startPct = (selectionStartTime - startTime) / timelineTotalTime
      const endPct = (selectionEndTime - startTime) / timelineTotalTime

      setSelectionPct({ startPct, endPct })
    },
    [endTime, setSelection, setSelectionPct, startTime]
  )

  const jumpToNewSelection = useCallback(
    (nextStartTime: number, nextEndTime: number, nextPlayWhenReady = true) => {
      setSelectionToTime(nextStartTime, nextEndTime)
      centerTimelineAtTime((nextStartTime + nextEndTime) / 2)
      seekTo(nextStartTime, nextPlayWhenReady)
    },
    [centerTimelineAtTime, seekTo, setSelectionToTime]
  )

  const setTimelineLength = useCallback(
    (nextTimelineLength) => {
      dispatch({
        type: useTimelineUpdater.ACTION_TYPES.SET_TIMELINE_LENGTH,
        payload: { timelineLength: nextTimelineLength },
      })
    },
    [dispatch]
  )

  const contentWidth = useMemo(() => {
    const timelineTotalTime = endTime - startTime
    return getContentWidthFromTime(timelineTotalTime)
  }, [endTime, getContentWidthFromTime, startTime])

  return useMemo(
    () => ({
      containerRect,
      setOffsetPct,
      setSelectionPct,
      setTimelineLength,
      setTimelineStartAndEnd,
      getContentWidthFromTime,
      contentWidth,
      centerTimelineAtTime,
      setSelectionToTime,
      jumpToNewSelection,
    }),
    [
      containerRect,
      setOffsetPct,
      setSelectionPct,
      setTimelineLength,
      setTimelineStartAndEnd,
      getContentWidthFromTime,
      contentWidth,
      centerTimelineAtTime,
      setSelectionToTime,
      jumpToNewSelection,
    ]
  )
}

export const useGetTimeToContentsWidth = (
  parentWidth: number,
  timelineTime: number
) => {
  return useCallback(
    (timeInMs: number) => {
      return (timeInMs * parentWidth) / timelineTime
    },
    [parentWidth, timelineTime]
  )
}

export const useTimelineDetailData = () => {
  const { startTime, endTime, offsetPct, timelineLength } = useTimelineState()
  const { seekTo } = useEditor()
  const [containerRef, parentSize] = useDimensions()

  const getContentsWidthInPx = useGetTimeToContentsWidth(
    parentSize?.width ?? 1,
    timelineLength
  )

  const seekToPageX = useCallback(
    (pageX: number) => {
      if (parentSize) {
        const pct = (pageX - parentSize.left) / parentSize.width
        const screenStartTime =
          startTime + (endTime - startTime - timelineLength) * offsetPct
        const timeSinceScreenStart = pct * timelineLength
        seekTo(screenStartTime + timeSinceScreenStart)
      }
    },
    [endTime, offsetPct, parentSize, seekTo, startTime, timelineLength]
  )

  const seekToClick = useCallback(
    (evt: React.MouseEvent) => {
      seekToPageX(evt.pageX)
    },
    [seekToPageX]
  )

  const contentWidth = useMemo(() => {
    return getContentsWidthInPx(endTime - startTime)
  }, [endTime, getContentsWidthInPx, startTime])

  const containerStyles = useMemo(() => {
    if (endTime === 0 && startTime === 0) return {}
    const timelineTimeLength = endTime - startTime

    const offsetPx = getContentsWidthInPx(
      offsetPct * (timelineTimeLength - timelineLength)
    )

    return {
      width: contentWidth,
      transform: `translate3d(${-offsetPx}px, 0, 0)`,
    }
  }, [
    endTime,
    startTime,
    getContentsWidthInPx,
    offsetPct,
    timelineLength,
    contentWidth,
  ])

  return useMemo(
    () => ({
      containerRef,
      containerStyles,
      containerWidth: parentSize?.width ?? 1,
      containerRect: parentSize,
      seekToClick,
      seekToPageX,
    }),
    [containerRef, containerStyles, parentSize, seekToClick, seekToPageX]
  )
}

export const useThumbDrag = (
  parentWidth: number,
  offsetPct: number,
  setOffsetPct: (nextOffsetPct: number) => void,
  timelineInfo: { start: number; end: number }, // in ms
  timelineLength: number
) => {
  const ref = useRef<HTMLDivElement>(null)
  const startingOffset = useRef<number>(0)
  const startingClientX = useRef<number>(0)
  const [dragging, setDragging] = useState(false)

  const getTimeToWidth = useCallback(
    (timeInMs: number) => {
      return timelineInfo
        ? (timeInMs * parentWidth) / (timelineInfo.end - timelineInfo.start)
        : 1
    },
    [parentWidth, timelineInfo]
  )

  const thumbWidth = useMemo(
    () => getTimeToWidth(timelineLength),
    [getTimeToWidth, timelineLength]
  )

  const onMouseMove = useCallback(
    (event: MouseEvent) => {
      const diff = startingClientX.current - event.clientX
      let nextOffset = startingOffset.current - diff

      // control max/min setup
      if (nextOffset < 0) {
        nextOffset = 0
      } else if (nextOffset > parentWidth - thumbWidth) {
        nextOffset = parentWidth - thumbWidth
      }

      const nextOffsetPct = nextOffset / (parentWidth - thumbWidth)

      setOffsetPct(nextOffsetPct)
    },
    [setOffsetPct, parentWidth, thumbWidth]
  )

  const throttledMouseMove = useMemo(() => {
    return throttle(onMouseMove, 1000 / 60)
  }, [onMouseMove])

  // cleanup!
  const onMouseUp = useCallback(() => {
    window.removeEventListener('mousemove', throttledMouseMove)
    window.removeEventListener('mouseup', onMouseUp)
    startingOffset.current = 0
    startingClientX.current = 0
    setDragging(false)
  }, [throttledMouseMove])

  // Setup events and initialize the positions
  const onMouseDown = useCallback(
    (event: MouseEvent) => {
      window.addEventListener('mousemove', throttledMouseMove)
      window.addEventListener('mouseup', onMouseUp)

      //initialize positions
      startingOffset.current = offsetPct * (parentWidth - thumbWidth)
      startingClientX.current = event.clientX

      setDragging(true)
    },
    [throttledMouseMove, onMouseUp, offsetPct, parentWidth, thumbWidth]
  )

  useEffect(() => {
    if (ref.current) {
      const element = ref.current
      element.addEventListener('mousedown', onMouseDown)

      return () => {
        element.removeEventListener('mousedown', onMouseDown)
      }
    }
  }, [onMouseDown])

  return useMemo(
    () => ({
      ref,
      dragging,
    }),
    [ref, dragging]
  )
}

export const useSelectPct = (
  contentWidth: number,
  parentRect: DOMRect | undefined,
  contentOffset: number,
  setCurrentSelectionPct: (nextSelection: {
    startPct: number
    endPct: number
  }) => void,
  onDoneSelecting: () => void
) => {
  const ref = useRef<HTMLDivElement>(null)
  const windowPositionOffset = useRef(0)
  const startingClientX = useRef<number>(0)
  const [dragging, setDragging] = useState(false)

  const onMouseMove = useCallback(
    (event: MouseEvent) => {
      // const diff = startingClientX.current - event.clientX
      let nextPos = contentOffset + event.clientX - windowPositionOffset.current

      const startOffsetPct = startingClientX.current / contentWidth
      const endOffsetPct = nextPos / contentWidth

      setCurrentSelectionPct({
        startPct: startOffsetPct,
        endPct: endOffsetPct,
      })
    },
    [contentOffset, contentWidth, setCurrentSelectionPct]
  )

  // cleanup!
  const onMouseUp = useCallback(() => {
    window.removeEventListener('mousemove', onMouseMove)
    window.removeEventListener('mouseup', onMouseUp)
    // startingOffset.current = 0
    startingClientX.current = 0
    setDragging(false)
    onDoneSelecting()
  }, [onMouseMove, setDragging, onDoneSelecting])

  // Setup events and initialize the positions
  const onMouseDown = useCallback(
    (event: MouseEvent) => {
      window.addEventListener('mousemove', onMouseMove)
      window.addEventListener('mouseup', onMouseUp)
      windowPositionOffset.current = parentRect?.left ?? 0

      //initialize positions
      // startingOffset.current = offsetPct * parentWidth
      startingClientX.current =
        contentOffset + event.clientX - windowPositionOffset.current

      const startOffsetPct = startingClientX.current / contentWidth
      setCurrentSelectionPct({
        startPct: startOffsetPct,
        endPct: startOffsetPct,
      })

      setDragging(true)
    },
    [
      onMouseMove,
      onMouseUp,
      parentRect,
      contentOffset,
      contentWidth,
      setCurrentSelectionPct,
    ]
  )

  useEffect(() => {
    if (ref.current) {
      const element = ref.current
      element.addEventListener('mousedown', onMouseDown)

      return () => {
        element.removeEventListener('mousedown', onMouseDown)
      }
    }
  }, [onMouseDown])

  return useMemo(
    () => ({
      ref,
      dragging,
    }),
    [ref, dragging]
  )
}

// this type needs to match for updating next offset
export type SliderType = 'startPct' | 'endPct'
export const useResizeSelection = (
  parentWidth: number,
  type: SliderType,
  selection: { startPct: number; endPct: number },
  updateSelection: (nextSelection: {
    startPct: number
    endPct: number
  }) => void,
  onUpdateDone: () => void
) => {
  const sliderRef = useRef<HTMLDivElement>(null)
  const startingMousePos = useRef(0)
  const startingSliderPos = useRef(0)

  const selectionInPx = useMemo(() => {
    return parentWidth * selection[type]
  }, [parentWidth, selection, type])

  const onMouseMove = useCallback(
    (event: MouseEvent) => {
      const diff = startingMousePos.current - event.clientX
      let nextOffset = startingSliderPos.current - diff

      // control max/min setup
      if (nextOffset < 0) {
        nextOffset = 0
      } else if (nextOffset > parentWidth) {
        nextOffset = parentWidth
      }

      // we could set min or max times
      if (type === 'startPct') {
        if (nextOffset > selection.endPct * parentWidth) {
          nextOffset = selection.endPct * parentWidth
        }
      } else {
        if (nextOffset < selection.startPct * parentWidth) {
          nextOffset = selection.startPct * parentWidth
        }
      }

      const nextOffsetPct = nextOffset / parentWidth

      // updateSelection((selectionState) => ({
      //   ...selectionState,
      //   [type]: nextOffsetPct,
      // }))

      updateSelection({
        startPct: selection.startPct,
        endPct: selection.endPct,
        [type]: nextOffsetPct,
      })
    },
    [parentWidth, type, updateSelection, selection.startPct, selection.endPct]
  )

  const onMouseUp = useCallback(() => {
    window.removeEventListener('mouseup', onMouseUp)
    window.removeEventListener('mousemove', onMouseMove)
    onUpdateDone()
  }, [onMouseMove, onUpdateDone])

  const onMouseDown = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation()
      startingMousePos.current = event.clientX
      startingSliderPos.current = selectionInPx

      window.addEventListener('mousemove', onMouseMove)
      window.addEventListener('mouseup', onMouseUp)
    },
    [selectionInPx, onMouseUp, onMouseMove]
  )

  useEffect(() => {
    if (sliderRef.current) {
      const elem = sliderRef.current
      elem.addEventListener('mousedown', onMouseDown)
      return () => {
        elem.removeEventListener('mousedown', onMouseDown)
      }
    }
  }, [onMouseDown])

  return useMemo(
    () => ({
      sliderRef,
    }),
    [sliderRef]
  )
}
