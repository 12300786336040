import Ares from 'assets/valorant/icons/killfeed-guns/Ares.png'
import Bucky from 'assets/valorant/icons/killfeed-guns/Bucky.png'
import Bulldog from 'assets/valorant/icons/killfeed-guns/Bulldog.png'
import Classic from 'assets/valorant/icons/killfeed-guns/Classic.png'
import Frenzy from 'assets/valorant/icons/killfeed-guns/Frenzy.png'
import Ghost from 'assets/valorant/icons/killfeed-guns/Ghost.png'
import Guardian from 'assets/valorant/icons/killfeed-guns/Guardian.png'
import Judge from 'assets/valorant/icons/killfeed-guns/Judge.png'
import Knife from 'assets/valorant/icons/killfeed-guns/Knife.png'
import Marshal from 'assets/valorant/icons/killfeed-guns/Marshal.png'
import Odin from 'assets/valorant/icons/killfeed-guns/Odin.png'
import Operator from 'assets/valorant/icons/killfeed-guns/Operator.png'
import Phantom from 'assets/valorant/icons/killfeed-guns/Phantom.png'
import Sheriff from 'assets/valorant/icons/killfeed-guns/Sheriff.png'
import Shorty from 'assets/valorant/icons/killfeed-guns/Shorty.png'
import Spectre from 'assets/valorant/icons/killfeed-guns/Spectre.png'
import Stinger from 'assets/valorant/icons/killfeed-guns/Stinger.png'
import Vandal from 'assets/valorant/icons/killfeed-guns/Vandal.png'

import Breach_Aftershock from 'assets/valorant/icons/abilities/Breach_Aftershock.png'
import Brimstone_Incendiary from 'assets/valorant/icons/abilities/Brimstone_Incendiary.png'
import Brimstone_Orbital_Strike from 'assets/valorant/icons/abilities/Brimstone_Orbital_Strike.png'
import Chamber_Tour_De_Force from 'assets/valorant/icons/abilities/Chamber_Tour_De_Force.png'
import Jett_Blade_Storm from 'assets/valorant/icons/abilities/Jett_Blade_Storm.png'
import Kayo_Fragment from 'assets/valorant/icons/abilities/Kayo_Fragment.png'
import Killjoy_Nanoswarm from 'assets/valorant/icons/abilities/Killjoy_Nanoswarm.png'
import Killjoy_Turret from 'assets/valorant/icons/abilities/Killjoy_Turret.png'
import Neon_Overdrive from 'assets/valorant/icons/abilities/Neon_Overdrive.png'
import Phoenix_Blaze from 'assets/valorant/icons/abilities/Phoenix_Blaze.png'
import Phoenix_Hot_Hands from 'assets/valorant/icons/abilities/Phoenix_Hot_Hands.png'
import Raze_Boom_Bot from 'assets/valorant/icons/abilities/Raze_Boom_Bot.png'
import Raze_Paint_Shells from 'assets/valorant/icons/abilities/Raze_Paint_Shells.png'
import Raze_Blast_Pack from 'assets/valorant/icons/abilities/Raze_Blast_Pack.png'
import Raze_Showstopper from 'assets/valorant/icons/abilities/Raze_Showstopper.png'
import Skye_Trailblazer from 'assets/valorant/icons/abilities/Skye_Trailblazer.png'
import Sova_Shock_Bolt from 'assets/valorant/icons/abilities/Sova_Shock_Bolt.png'
import Sova_Hunters_Fury from 'assets/valorant/icons/abilities/Sova_Hunters_Fury.png'
import Viper_Snake_Bite from 'assets/valorant/icons/abilities/Viper_Snake_Bite.png'
import { ValorantAgent } from '__generated__'

export const KILL_FEED_WEAPONS: { [key: string]: string } = {
  Ares: Ares,
  Bucky: Bucky,
  Bulldog: Bulldog,
  Classic: Classic,
  Frenzy: Frenzy,
  Ghost: Ghost,
  Guardian: Guardian,
  Judge: Judge,
  Knife: Knife,
  Marshal: Marshal,
  Odin: Odin,
  Operator: Operator,
  Phantom: Phantom,
  Sheriff: Sheriff,
  Shorty: Shorty,
  Spectre: Spectre,
  Stinger: Stinger,
  Vandal: Vandal,
  Overdrive: Neon_Overdrive,
}

export const KILL_FEED_ABILITIES_BY_AGENT: {
  [key: string]: { [ability: string]: string }
} = {
  [ValorantAgent.Breach]: {
    Ability1: Breach_Aftershock,
  },
  [ValorantAgent.Brimstone]: {
    Ability2: Brimstone_Incendiary,
    Ultimate: Brimstone_Orbital_Strike,
  },
  [ValorantAgent.Chamber]: {
    Ultimate: Chamber_Tour_De_Force,
  },
  [ValorantAgent.Jett]: {
    Ultimate: Jett_Blade_Storm,
  },
  [ValorantAgent.Kayo]: {
    Ability1: Kayo_Fragment,
  },
  [ValorantAgent.Killjoy]: {
    Ability1: Killjoy_Nanoswarm,
    Ability3: Killjoy_Turret,
  },
  [ValorantAgent.Neon]: {
    Ultimate: Neon_Overdrive,
  },
  [ValorantAgent.Phoenix]: {
    Ability1: Phoenix_Blaze,
    Ability3: Phoenix_Hot_Hands,
  },
  [ValorantAgent.Raze]: {
    Ability3: Raze_Boom_Bot,
    Ability2: Raze_Paint_Shells,
    Ability1: Raze_Blast_Pack,
    Ultimate: Raze_Showstopper,
  },
  [ValorantAgent.Skye]: {
    Ability2: Skye_Trailblazer,
  },
  [ValorantAgent.Sova]: {
    Ability1: Sova_Shock_Bolt,
    Ultimate: Sova_Hunters_Fury,
  },
  [ValorantAgent.Viper]: {
    Ability1: Viper_Snake_Bite,
  },
}
