import styled from 'styled-components/macro'

export const CampaignPageDiv = styled.div`
  position: relative;
  margin: 0 auto;
  width: 1136px;
  height: calc(100vh - 118px - 32px);
  display: grid;
  grid-template-areas:
    'campaign-title'
    'campaign-segments'
    'moment-viewer';
  grid-template-rows: 60px 114px 1fr;
`

export const CampaignTitleDiv = styled.div`
  grid-area: campaign-title;
`

export const CampaignSegmentsDiv = styled.div`
  grid-area: campaign-segments;
`

export const MomentViewerDiv = styled.div`
  grid-area: moment-viewer;
`

export const SegmentListDiv = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
`

export const SegmentOptionDiv = styled.div<{ $selected: boolean }>`
  position: relative;
  border-radius: 8px;
  margin-bottom: 16px;
  border: 1px solid ${({ theme }) => theme.colors.neutrals.n6};
  cursor: pointer;
  padding: 12px;
  margin-right: 12px;

  transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

  ${({ $selected, theme }) =>
    $selected
      ? `
    background-color: ${theme.colors.neutrals.n8};
    box-shadow: ${theme.elevation.depth2};
  `
      : ``}

  &:hover {
    background-color: ${({ theme }) => theme.colors.neutrals.n8};
    box-shadow: ${({ theme }) => theme.elevation.depth2};
  }
`

export const CampaignHeadingDiv = styled.div`
  position: relative;
  width: 1136px;
  margin: 0 auto;
`

export const CampaignUserPreviewImg = styled.img`
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  margin-right: 4px;
`
