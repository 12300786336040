import { Spacing } from 'components/core/layout'
import { Text } from 'components/core/text'
import { CampaignPreviewItem } from 'core/campaign/preview/preview-item'

export const AdminCampaignPage = () => {
  return (
    <Spacing top={8}>
      <Text variant="h4" weight="semibold">
        Campaign Admin Page
      </Text>

      <Spacing top={8}>
        <CampaignPreviewItem campaignId="5d92d8f4-44b5-4baf-b8f5-a6b52e0d0d6e" />
      </Spacing>
    </Spacing>
  )
}
