import styled, { css } from 'styled-components/macro'

export const EditorContainerDiv = styled.div<{ $hasGuests: boolean }>`
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: 100%;
  grid-template-areas:
    'video-player'
    'timeline';
  width: 100%;
  height: 100%;
  background-color: #23262f;
  color: white;
`

export const VideoPlayerAreaDiv = styled.div`
  position: relative;
  grid-area: video-player;
`

export const TimelineAreaDiv = styled.div`
  position: relative;
  grid-area: timeline;
`

export const VsDiv = styled.div`
  width: 40px;
  height: 22px;
  /* border-right: 1px solid ${({ theme }) => theme.colors.neutrals.n3}; */
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); */
  /* clip-path: inset(0px -15px 0px 0px); */
  display: flex;
  align-items: center;
  justify-content: center;
`

export const VsByRoundDiv = styled.div`
  height: 22px;
`

export const TeamTimelineMatchDetailDiv = styled.div``

export const SyncPlayerButtonDiv = styled.div<{
  $disabled?: boolean
  $active?: boolean
}>`
  position: relative;
  padding: 8px;
  height: 24px;
  /* background-color: #eee; */
  border-radius: 4px;
  /* box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1); */
  background: #353945;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & > span {
    font-size: 10px;
    margin: 0 4px;
  }

  &:hover {
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  }

  & > svg {
    fill: #b1b5c3;
    ${(p) =>
      p.$disabled
        ? css`
            fill: #efefef;
          `
        : p.$active
        ? css`
            fill: #7bff7b;
          `
        : null}
    width: 100%;
    height: 100%;
  }
`

export const VideoSyncControlsDiv = styled.div<{ $hasOffset: boolean }>`
  position: absolute;
  right: 8px;
  top: 8px;
  padding: 8px;
  padding-right: 0;
  background-color: #ccc;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
`

export const ExportingModalDiv = styled.div`
  width: 400px;
  height: 200px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

export const CustomCheckboxContainerDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
`

export const CustomCheckboxDiv = styled.div<{ $checked: boolean }>`
  position: relative;
  width: 12px;
  height: 12px;
  border: 1px solid #777e90;
  border-radius: 4px;
  margin-right: 8px;

  ${({ $checked }) =>
    $checked
      ? css`
          &:after {
            content: '';
            position: absolute;
            width: 6px;
            height: 6px;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            background: #3772ff;
            border-radius: 2px;
          }
        `
      : null}
`

export const PlayerPerspectiveOptionsDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
`

export const AspectRatioLabelDiv = styled.div`
  color: ${({ theme }) => theme.colors.neutrals.n6};
  background-color: ${({ theme }) => theme.colors.gold};
  border-radius: 4px;
  margin-right: 8px;
  padding: 0 4px;

  span {
    font-size: 11px;
  }
`
