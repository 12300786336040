import React from 'react'
import { Route, Switch, useRouteMatch, useParams } from 'react-router'

import { EditorProvider } from 'core/editor/context'
import { TimelineProvider } from 'core/editor/timeline/context'

import { ExportEditor } from './exporter'
import { ExporterProvider } from './exporter/context'
import { SyncModal } from 'core/sync'
import { VideoSyncProvider } from 'core/sync/context'
import { MomentHome } from './home'
import { TemplateEditorProvider } from 'core/template-editor/context'

export const MomentPage: React.FC = () => {
  const { path } = useRouteMatch()
  const { momentId } = useParams<{ momentId: string }>()

  return (
    <>
      <VideoSyncProvider>
        <Switch>
          <Route path={`${path}/editor/:editId`}>
            <TemplateEditorProvider>
              <ExporterProvider>
                <EditorProvider>
                  <TimelineProvider>
                    <ExportEditor momentId={momentId} />
                  </TimelineProvider>
                </EditorProvider>
              </ExporterProvider>
            </TemplateEditorProvider>
          </Route>
          <MomentHome momentId={momentId} />
        </Switch>
        <SyncModal />
      </VideoSyncProvider>
    </>
  )
}
