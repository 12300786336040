import { useMemo } from 'react'
import styled, { useTheme } from 'styled-components/macro'

import { VStack } from 'components/core/layout'
import { Spinner } from 'components/core/spinner'
import { RecordingStatus, useGetEditPlayerQuery } from '__generated__'
import { Text } from 'components/core/text'

interface Props {
  editId: string
}

export const EditPlayer = ({ editId }: Props) => {
  const theme = useTheme()
  // This could need Polling for the status, but right now we have
  // the download button polling, and this will get the updates.
  // There is def a cleaner way to get this figured out though
  const { data } = useGetEditPlayerQuery({
    variables: { editId },
  })

  const { status, url } = useMemo(() => {
    const recentExport = data?.momentEdit?.exports[0]
    if (data && recentExport) {
      return {
        url: recentExport.output?.videos?.[0]?.url,
        status: recentExport.status,
      }
    }
    return { status: undefined, url: undefined }
  }, [data])

  return status === RecordingStatus.Completed && url ? (
    <VideoContainerDiv>
      <video src={url} controls autoPlay />
    </VideoContainerDiv>
  ) : (
    <VideoContainerDiv>
      <VStack
        fullHeight
        fullWidth
        justifyContent="center"
        alignItems="center"
        gap={16}
      >
        <Text variant="text-2" weight="semibold" as="div">
          {status === RecordingStatus.Inprogress && 'Processing'}
          {status === RecordingStatus.Created && 'Queued for Processing'}
          {status === RecordingStatus.Failed && 'There was an issue processing'}
        </Text>
        <Spinner size={32} color={theme.colors.neutrals.n1} />
      </VStack>
    </VideoContainerDiv>
  )
}

const VideoContainerDiv = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  & > video {
    width: 100%;
    height: 100%;
  }
`
