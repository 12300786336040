/* eslint-disable react-hooks/exhaustive-deps */
import { useEditorState } from 'core/editor/context'
import { useEditorOffsets } from 'core/editor/hooks'
import { useTimelineHelpers } from 'core/editor/timeline/hooks'
import {
  ExportFormat,
  ExportTemplate,
  Scene,
  TemplateCrop,
  TemplateCropSettings,
} from 'core/exporter/constants'
import { useCallback, useEffect, useMemo } from 'react'
import { usePrevious } from 'utils'
import { Rect } from '__generated__'
import {
  CropSettings,
  KDAOptions,
  KillfeedOptions,
  PlayerDisplayOptions,
  SceneEditingModes,
  SubtitlesOptions,
  useComposerState,
  useComposerUpdater,
} from './context'

export const useComposerHelper = () => {
  const dispatch = useComposerUpdater()

  const setTitle = useCallback(
    (nextTitle: string) => {
      dispatch({
        type: useComposerUpdater.ACTION_TYPES.SET_TITLE,
        payload: {
          title: nextTitle,
        },
      })
    },
    [dispatch]
  )

  const setTemplate = useCallback(
    (nextTemplate: ExportTemplate | undefined) => {
      dispatch({
        type: useComposerUpdater.ACTION_TYPES.SET_TEMPLATE,
        payload: {
          selectedTemplate: nextTemplate,
        },
      })
    },
    [dispatch]
  )

  const setMomentId = useCallback(
    (nextMomentId: string) => {
      dispatch({
        type: useComposerUpdater.ACTION_TYPES.SET_MOMENT_ID,
        payload: {
          momentId: nextMomentId,
        },
      })
    },
    [dispatch]
  )

  const setEditId = useCallback(
    (nextEditId: string) => {
      dispatch({
        type: useComposerUpdater.ACTION_TYPES.SET_EDIT_ID,
        payload: {
          editId: nextEditId,
        },
      })
    },
    [dispatch]
  )

  const setFormat = useCallback(
    (nextFormat: ExportFormat) => {
      dispatch({
        type: useComposerUpdater.ACTION_TYPES.SET_FORMAT,
        payload: {
          selectedFormat: nextFormat,
        },
      })
    },
    [dispatch]
  )

  const setVideosData = useCallback(
    (videosData: { videoId: string; userId: string }[]) => {
      dispatch({
        type: useComposerUpdater.ACTION_TYPES.SET_VIDEOS_DATA,
        payload: {
          videosData,
        },
      })
    },
    [dispatch]
  )

  const setCropSettings = useCallback(
    (nextCropSettings: CropSettings) => {
      dispatch({
        type: useComposerUpdater.ACTION_TYPES.SET_CROP_SETTINGS,
        payload: {
          cropSettings: nextCropSettings,
        },
      })
    },
    [dispatch]
  )

  const setPrimaryVideoId = useCallback(
    (nextPrimaryVideoId: string) => {
      dispatch({
        type: useComposerUpdater.ACTION_TYPES.SET_PRIMARY_VIDEO_ID,
        payload: {
          primaryVideoId: nextPrimaryVideoId,
        },
      })
    },
    [dispatch]
  )

  const setPrimaryTemplateCrop = useCallback(
    (title: string) => {
      dispatch({
        type: useComposerUpdater.ACTION_TYPES.SET_PRIMARY_CROP,
        payload: {
          title,
        },
      })
    },
    [dispatch]
  )

  const setPreviewRect = useCallback(
    (nextPreviewRect: DOMRect | undefined) => {
      dispatch({
        type: useComposerUpdater.ACTION_TYPES.SET_PREVIEW_RECT,
        payload: {
          previewRect: nextPreviewRect,
        },
      })
    },
    [dispatch]
  )

  const setContainerPreviewRect = useCallback(
    (nextPreviewContainerRect: DOMRect | undefined) => {
      dispatch({
        type: useComposerUpdater.ACTION_TYPES.SET_PREVIEW_CONTAINER_RECT,
        payload: {
          previewContainerRect: nextPreviewContainerRect,
        },
      })
    },
    [dispatch]
  )

  const setSelectedTemplateCrop = useCallback(
    (nextSelectedTemplateCrop: TemplateCrop | undefined) => {
      dispatch({
        type: useComposerUpdater.ACTION_TYPES.SET_SELECTED_TEMPLATE_CROP,
        payload: {
          selectedTemplateCrop: nextSelectedTemplateCrop,
        },
      })
    },
    [dispatch]
  )

  const setSelectedSceneId = useCallback(
    (nextSelectedSceneId: string | undefined) => {
      dispatch({
        type: useComposerUpdater.ACTION_TYPES.SET_SELECTED_SCENE_ID,
        payload: {
          selectedSceneId: nextSelectedSceneId,
        },
      })
    },
    [dispatch]
  )

  const setCropRectSetting = useCallback(
    (title: string, rect: Rect) => {
      dispatch({
        type: useComposerUpdater.ACTION_TYPES.SET_CROP_RECT_DATA,
        payload: {
          title,
          rect,
        },
      })
    },
    [dispatch]
  )

  const setTemplateCropRect = useCallback(
    (title: string, rect: Rect) => {
      dispatch({
        type: useComposerUpdater.ACTION_TYPES.SET_TEMPLATE_CROP_RECT,
        payload: {
          title,
          rect,
        },
      })
    },
    [dispatch]
  )

  const addNewTemplateCrop = useCallback(
    (templateCrop: TemplateCropSettings) => {
      dispatch({
        type: useComposerUpdater.ACTION_TYPES.ADD_TEMPLATE_CROP,
        payload: {
          templateCrop,
        },
      })
    },
    [dispatch]
  )

  const addScene = useCallback(
    (scene: Scene) => {
      dispatch({
        type: useComposerUpdater.ACTION_TYPES.ADD_SCENE,
        payload: {
          scene,
        },
      })
    },
    [dispatch]
  )

  const deleteScene = useCallback(
    (idx: number) => {
      dispatch({
        type: useComposerUpdater.ACTION_TYPES.DELETE_SCENE,
        payload: {
          idx,
        },
      })
    },
    [dispatch]
  )

  const editScene = useCallback(
    (idx: number) => {
      dispatch({
        type: useComposerUpdater.ACTION_TYPES.DELETE_SCENE,
        payload: {
          idx,
        },
      })
    },
    [dispatch]
  )

  const setSceneOrder = useCallback(
    (nextSceneOrder: string[]) => {
      dispatch({
        type: useComposerUpdater.ACTION_TYPES.SET_SCENE_ORDER,
        payload: {
          sceneIds: nextSceneOrder,
        },
      })
    },
    [dispatch]
  )

  const clearScenes = useCallback(() => {
    dispatch({
      type: useComposerUpdater.ACTION_TYPES.CLEAR_SCENES,
    })
  }, [dispatch])

  const setSceneEditingMode = useCallback(
    (sceneEditingMode: SceneEditingModes) => {
      dispatch({
        type: useComposerUpdater.ACTION_TYPES.SET_SCENE_EDITING_MODE,
        payload: {
          sceneEditingMode,
        },
      })
    },
    [dispatch]
  )

  const removeTemplateCrop = useCallback(
    (title: string) => {
      dispatch({
        type: useComposerUpdater.ACTION_TYPES.REMOVE_TEMPLATE_CROP,
        payload: {
          title,
        },
      })
    },
    [dispatch]
  )

  const orderTemplateCrops = useCallback(
    (templateCropIds: string[]) => {
      dispatch({
        type: useComposerUpdater.ACTION_TYPES.ORDER_TEMPLATE_CROPS,
        payload: {
          nextIdOrder: templateCropIds,
        },
      })
    },
    [dispatch]
  )

  const setValorantMatchId = useCallback(
    (valorantMatchId: string | undefined) => {
      dispatch({
        type: useComposerUpdater.ACTION_TYPES.SET_VALORANT_MATCH_ID,
        payload: {
          valorantMatchId,
        },
      })
    },
    [dispatch]
  )

  const setKillfeed = useCallback(
    (killfeed: boolean) => {
      dispatch({
        type: useComposerUpdater.ACTION_TYPES.SET_KILLFEED,
        payload: {
          killfeed,
        },
      })
    },
    [dispatch]
  )

  const setKillfeedOptions = useCallback(
    (options: Partial<KillfeedOptions>) => {
      dispatch({
        type: useComposerUpdater.ACTION_TYPES.SET_KILLFEED_OPTIONS,
        payload: {
          options,
        },
      })
    },
    [dispatch]
  )

  const setPlayerDisplay = useCallback(
    (playerDisplay: boolean) => {
      dispatch({
        type: useComposerUpdater.ACTION_TYPES.SET_PLAYER_DISPLAY,
        payload: {
          playerDisplay,
        },
      })
    },
    [dispatch]
  )

  const setPlayerDisplayOptions = useCallback(
    (options: Partial<PlayerDisplayOptions>) => {
      dispatch({
        type: useComposerUpdater.ACTION_TYPES.SET_PLAYER_DISPLAY_OPTIONS,
        payload: {
          options,
        },
      })
    },
    [dispatch]
  )

  const setKDA = useCallback(
    (KDA: boolean) => {
      dispatch({
        type: useComposerUpdater.ACTION_TYPES.SET_KDA,
        payload: {
          KDA,
        },
      })
    },
    [dispatch]
  )

  const setKDAOptions = useCallback(
    (options: Partial<KDAOptions>) => {
      dispatch({
        type: useComposerUpdater.ACTION_TYPES.SET_KDA_OPTIONS,
        payload: {
          options,
        },
      })
    },
    [dispatch]
  )

  const setMatchIntro = useCallback(
    (matchIntro: boolean) => {
      dispatch({
        type: useComposerUpdater.ACTION_TYPES.SET_MATCH_INTRO,
        payload: {
          matchIntro,
        },
      })
    },
    [dispatch]
  )

  const setMatchIntroOptions = useCallback(
    (options: Partial<KDAOptions>) => {
      dispatch({
        type: useComposerUpdater.ACTION_TYPES.SET_MATCH_INTRO_OPTIONS,
        payload: {
          options,
        },
      })
    },
    [dispatch]
  )

  const setSubtitles = useCallback(
    (subtitles: boolean) => {
      dispatch({
        type: useComposerUpdater.ACTION_TYPES.SET_SUBTITLES,
        payload: {
          subtitles,
        },
      })
    },
    [dispatch]
  )

  const setSubtitlesOptions = useCallback(
    (options: Partial<SubtitlesOptions>) => {
      dispatch({
        type: useComposerUpdater.ACTION_TYPES.SET_SUBTITLES_OPTIONS,
        payload: {
          options,
        },
      })
    },
    [dispatch]
  )

  return useMemo(
    () => ({
      setTitle,
      setCropRectSetting,
      setTemplateCropRect,
      addNewTemplateCrop,
      removeTemplateCrop,
      setSelectedSceneId,
      setTemplate,
      setMomentId,
      setEditId,
      setFormat,
      setCropSettings,
      setPrimaryVideoId,
      setPreviewRect,
      setContainerPreviewRect,
      setSelectedTemplateCrop,
      addScene,
      deleteScene,
      editScene,
      clearScenes,
      setSceneEditingMode,
      orderTemplateCrops,
      setPrimaryTemplateCrop,
      setSceneOrder,
      setValorantMatchId,
      setVideosData,
      setKillfeed,
      setKillfeedOptions,
      setPlayerDisplay,
      setPlayerDisplayOptions,
      setKDA,
      setKDAOptions,
      setMatchIntro,
      setMatchIntroOptions,
      setSubtitles,
      setSubtitlesOptions,
    }),
    [
      setTitle,
      setCropRectSetting,
      setTemplateCropRect,
      addNewTemplateCrop,
      setSelectedSceneId,
      removeTemplateCrop,
      setTemplate,
      setMomentId,
      setEditId,
      setFormat,
      setCropSettings,
      setPrimaryVideoId,
      setPreviewRect,
      setContainerPreviewRect,
      setSelectedTemplateCrop,
      addScene,
      deleteScene,
      editScene,
      clearScenes,
      setSceneEditingMode,
      orderTemplateCrops,
      setPrimaryTemplateCrop,
      setSceneOrder,
      setValorantMatchId,
      setVideosData,
      setKillfeed,
      setKillfeedOptions,
      setPlayerDisplay,
      setPlayerDisplayOptions,
      setKDA,
      setKDAOptions,
      setMatchIntro,
      setMatchIntroOptions,
      setSubtitles,
      setSubtitlesOptions,
    ]
  )
}

/*
  When primary video switches, we need to update the selection time
  based off the offset difference between the two
*/
export const useUpdateTimesForPerspectiveSwitch = () => {
  const { primaryVideoId, selectedSceneId } = useComposerState()
  const lastPrimaryVideoId = usePrevious(primaryVideoId)
  const lastSelectedSceneId = usePrevious(selectedSceneId)
  const offsets = useEditorOffsets()
  const { jumpToNewSelection } = useTimelineHelpers()
  const { selection } = useEditorState()

  return useCallback(() => {
    /*
      if scenes switch, we should ignore making a change and let it
      initialize, but if just primary video changes, use the offset to
      adjust selection
    */
    if (
      lastSelectedSceneId === selectedSceneId &&
      lastPrimaryVideoId !== primaryVideoId &&
      lastPrimaryVideoId
    ) {
      const offsetAdjustment = offsets?.[lastPrimaryVideoId]?.[primaryVideoId]
      if (offsetAdjustment) {
        const nextStart = (selection?.startTime ?? 0) - offsetAdjustment * 1000
        const nextEnd = (selection?.endTime ?? 0) - offsetAdjustment * 1000
        jumpToNewSelection(nextStart, nextEnd, false)
      }
    }
  }, [
    primaryVideoId,
    offsets,
    lastPrimaryVideoId,
    selectedSceneId,
    lastSelectedSceneId,
  ])
}

export const useComposerController = () => {
  const { selectedTemplate } = useComposerState()
  const { setPrimaryTemplateCrop } = useComposerHelper()

  useEffect(() => {
    if (selectedTemplate) {
      const hasPrimary = selectedTemplate?.crops.some(({ primary }) => primary)
      if (!hasPrimary && selectedTemplate.crops[0]) {
        setPrimaryTemplateCrop(selectedTemplate.crops[0].title)
      }
    }
  }, [selectedTemplate, setPrimaryTemplateCrop])
}
