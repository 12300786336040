import { useAuth0 } from '@auth0/auth0-react'
import { Button, ButtonProps } from 'components/core/button'

const Login = ({
  variant = 'plain',
  rounded = true,
  ...props
}: ButtonProps) => {
  const { loginWithRedirect } = useAuth0()

  const loginOnClick = () => {
    const redirectUri = new URL(
      '/auth/login',
      window.location.origin
    ).toString()
    loginWithRedirect({ redirectUri })
  }

  return (
    <Button
      variant={variant}
      rounded={rounded}
      onClick={loginOnClick}
      {...props}
    >
      Log in
    </Button>
  )
}

export { Login }
