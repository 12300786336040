import styled, { css } from 'styled-components/macro'
import { useCallback } from 'react'

import { Text } from 'components/core/text'
import { StreamerAvatar } from 'components/streamer-avatar'
import { RosterPreviewUserFragment } from '__generated__'
import { HStack } from 'components/core/layout'
import { ReactComponent as StarFullIcon } from 'icons/svgs/solid/star.svg'
import { useWatchLocalStorageValue } from 'core/composer/template/facecam/hooks'

interface Props {
  user: RosterPreviewUserFragment
  selected: boolean
  setSelected: () => void
}

export const ROSTER_FAVORITE_KEY = 'roster_favorites'

export const UserSidebarItem = ({ user, selected, setSelected }: Props) => {
  const favorited = useWatchLocalStorageValue<boolean>(
    ROSTER_FAVORITE_KEY,
    user.id
  )

  const setFavorited = useCallback(
    (value: boolean) => {
      const currentFavorites = JSON.parse(
        localStorage.getItem(ROSTER_FAVORITE_KEY) ?? '{}'
      )
      const nextData = {
        ...currentFavorites,
        [user.id]: value,
      }
      localStorage.setItem(ROSTER_FAVORITE_KEY, JSON.stringify(nextData))
      window.dispatchEvent(new Event('storage'))
    },
    [user.id]
  )

  return (
    <ItemDiv $selected={selected} onClick={() => setSelected()}>
      <HStack gap={16} alignItems="center">
        <AvatarContainerDiv>
          <StreamerAvatar
            height={36}
            width={36}
            profileImageUrl={user.profileImageUrl ?? ''}
          />
        </AvatarContainerDiv>
        <Text variant="text-2">{user.displayName}</Text>
      </HStack>
      <FavoriteContainerDiv
        onClick={(e) => {
          e.stopPropagation()
          setFavorited(!favorited)
        }}
        $favorited={favorited ?? false}
      >
        <StarFullIcon />
      </FavoriteContainerDiv>
    </ItemDiv>
  )
}

const ItemDiv = styled.div<{ $selected: boolean }>`
  position: relative;
  padding: 12px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutrals.n7};
  background-color: ${({ $selected, theme }) =>
    $selected ? theme.colors.neutrals.n6 : theme.colors.neutrals.n8};
  transition: transform 100ms ease-in-out;
  cursor: pointer;

  /* ${({ $selected }) =>
    $selected
      ? css`
          transform: translate3d(0px, 0, 0) scale(1.02);
        `
      : css`
          transform: translate3d(0, 0, 0);
        `}; */
`

const AvatarContainerDiv = styled.div`
  border-radius: 50%;
  width: 36px;
  height: 36px;
  overflow: hidden;
`

const FavoriteContainerDiv = styled.div<{ $favorited: boolean }>`
  width: 14px;
  height: 14px;
  cursor: pointer;

  & > svg {
    width: 100%;
    height: 100%;
    fill: ${({ $favorited, theme }) =>
      $favorited ? theme.colors.gold : theme.colors.neutrals.n5};
  }
`
