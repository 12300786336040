import styled from 'styled-components/macro'

export const CollectionsListContainer = styled.aside`
  max-width: 264px;
  width: 100%;
`

export const CollectionsListGrid = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
`
