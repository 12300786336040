import React, { useCallback, useEffect, useMemo } from 'react'

import { SyncedVideo } from 'core/editor/synced-video'
import {
  CropSettings,
  CropSettingType,
  useExporterState,
} from 'page/moment/exporter/context'
import { useExporterHelper } from 'page/moment/exporter/hooks'

import { TemplateCrop } from '../constants'
import { TemplateCropContainerDiv, VideoContentDiv } from './styled'

interface CropContainerProps {
  templateCrop: TemplateCrop
}

export const CropContainer: React.FC<CropContainerProps> = ({
  templateCrop,
}) => {
  const { primaryVideoId } = useExporterState()
  const {
    containerStyles,
    videoId,
    contentStyle,
    selectTemplateCrop,
    isSelected,
    playerOptions,
  } = useCropContainerData(templateCrop)

  return (
    <TemplateCropContainerDiv
      style={containerStyles}
      onClick={selectTemplateCrop}
      $selected={isSelected}
    >
      {videoId ? (
        <VideoContentDiv style={contentStyle}>
          <SyncedVideo
            id={templateCrop.title}
            controller={templateCrop.primary}
            videoId={videoId}
            primaryVideoId={primaryVideoId}
            playerOptions={playerOptions}
            qualityHeight={1080}
          />
        </VideoContentDiv>
      ) : null}
    </TemplateCropContainerDiv>
  )
}

const VIDEO_CONTAINER_SIZE = { width: 1280, height: 720 }
const VIDEO_AR = { width: 16, height: 9 }

export const useCropContainerData = (templateCrop: TemplateCrop) => {
  const { cropSettings, primaryVideoId, previewRect, selectedTemplateCrop } =
    useExporterState()
  const {
    setCropSettings,
    setPrimaryVideoId,
    setSelectedTemplateCrop,
    setCropRectSetting,
  } = useExporterHelper()

  const cropSettingItem: CropSettings | undefined = useMemo(() => {
    return cropSettings[templateCrop.title]
  }, [cropSettings, templateCrop.title])

  const containerDimensions = useMemo(() => {
    if (!previewRect) return { width: 1, height: 1 }
    return {
      width: previewRect.width * templateCrop.position.width,
      height: previewRect.height * templateCrop.position.height,
    }
  }, [previewRect, templateCrop.position.height, templateCrop.position.width])

  const containerStyles = useMemo(
    () => ({
      width: `${templateCrop.position.width * 100}%`,
      height: `${templateCrop.position.height * 100}%`,
      left: `${templateCrop.position.x * 100}%`,
      top: `${templateCrop.position.y * 100}%`,
    }),
    [templateCrop]
  )

  // where the container goes, and what size it is
  // how to scale the background and what position it should be in
  // IMAGE_SIZE.width
  // This works for locked A:R only needing to find one scale and it fits for w & h
  // const scale = useMemo(() => {
  //   if (!previewRect) return 1
  //   const widthAtImageDimensions = cropRect.width * IMAGE_SIZE.width
  //   const widthOfPreviewContainer =
  //     templateCrop.position.width * previewRect.width
  //   return widthOfPreviewContainer / widthAtImageDimensions
  // }, [templateCrop, previewRect.width, cropRect.width])

  const widthScale = useMemo(() => {
    if (!previewRect) return 1
    const widthAtImageDimensions = VIDEO_CONTAINER_SIZE.width
    const widthOfPreviewContainer = containerDimensions.width
    return widthOfPreviewContainer / widthAtImageDimensions
  }, [containerDimensions.width, previewRect])

  const heightScale = useMemo(() => {
    if (!previewRect) return 1
    const heightAtImageDimensions = VIDEO_CONTAINER_SIZE.height
    const heightOfPreviewContainer = containerDimensions.height
    return heightOfPreviewContainer / heightAtImageDimensions
  }, [containerDimensions.height, previewRect])

  const contentStyle = useMemo(() => {
    if (cropSettingItem === undefined) return {}
    const { rect } = cropSettingItem

    const widthAtImageDimensions = rect.width * VIDEO_CONTAINER_SIZE.width
    const widthOfPreviewContainer = containerDimensions.width
    const scale = widthOfPreviewContainer / widthAtImageDimensions
    const transform = `scale(${scale}) translate(${-(
      rect.x * VIDEO_CONTAINER_SIZE.width
    )}px, ${-(rect.y * VIDEO_CONTAINER_SIZE.height)}px)`

    return {
      ...VIDEO_CONTAINER_SIZE,
      transform,
    }
  }, [containerDimensions.width, cropSettingItem])

  const selectTemplateCrop = useCallback(() => {
    setSelectedTemplateCrop(templateCrop)
  }, [setSelectedTemplateCrop, templateCrop])

  const playerOptions = useMemo(() => {
    return { muted: templateCrop.primary === false }
  }, [templateCrop.primary])

  // keep rect up to date
  useEffect(() => {
    // full cover math for rect
    if (cropSettingItem?.type === CropSettingType.Full) {
      const heightBasedWidth = VIDEO_CONTAINER_SIZE.width * heightScale
      let nextRect = { x: 0, y: 0, width: 1, height: 1 }
      if (heightBasedWidth > containerDimensions.width) {
        const scale =
          (templateCrop.aspectRatio.height / VIDEO_AR.height) * VIDEO_AR.width
        const widthPct = templateCrop.aspectRatio.width / scale
        nextRect = {
          y: 0,
          x: (1 - widthPct) / 2,
          height: 1,
          width: widthPct,
        }
      } else {
        const scale =
          (templateCrop.aspectRatio.width / VIDEO_AR.width) * VIDEO_AR.height
        const heightPct = templateCrop.aspectRatio.height / scale
        nextRect = {
          y: (1 - heightPct) / 2,
          x: 0,
          height: heightPct,
          width: 1,
        }
      }

      setCropRectSetting(templateCrop.title, nextRect)
    }
  }, [
    containerDimensions.height,
    containerDimensions.width,
    cropSettingItem?.type,
    heightScale,
    setCropRectSetting,
    setCropSettings,
    templateCrop.aspectRatio.height,
    templateCrop.aspectRatio.width,
    templateCrop.title,
    widthScale,
  ])

  useEffect(() => {
    if (
      templateCrop.primary &&
      cropSettingItem?.videoId !== primaryVideoId &&
      cropSettingItem?.videoId !== '' &&
      cropSettingItem?.videoId !== undefined &&
      primaryVideoId !== ''
    ) {
      setPrimaryVideoId(cropSettingItem?.videoId ?? '')
    }
  }, [
    templateCrop.primary,
    cropSettingItem?.videoId,
    primaryVideoId,
    setPrimaryVideoId,
  ])

  useEffect(() => {
    if (cropSettingItem === undefined) {
      setCropSettings({
        title: templateCrop.title,
        type: CropSettingType.Full,
        videoId: primaryVideoId,
        rect: {
          x: 0,
          y: 0,
          width: 1,
          height: 1,
        },
      })
    }
  }, [cropSettingItem, primaryVideoId, setCropSettings, templateCrop.title])

  useEffect(() => {
    if (selectedTemplateCrop === undefined && templateCrop.primary) {
      setSelectedTemplateCrop(templateCrop)
    }
  }, [selectedTemplateCrop, setSelectedTemplateCrop, templateCrop])

  return useMemo(
    () => ({
      playerOptions,
      selectTemplateCrop,
      containerStyles,
      cropSettingItem,
      contentStyle,
      isSelected: templateCrop.title === selectedTemplateCrop?.title,
      videoId: cropSettingItem?.videoId ?? '',
    }),
    [
      playerOptions,
      selectTemplateCrop,
      containerStyles,
      cropSettingItem,
      contentStyle,
      templateCrop.title,
      selectedTemplateCrop?.title,
    ]
  )
}
