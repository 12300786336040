import React, { useEffect, useMemo, useRef } from 'react'
import videojs, { PlayerOptions, VideoJsPlayer } from 'video.js'

import { useDimensions } from 'utils/browser'

const defaultPlayerOptions: Partial<PlayerOptions & HTMLVideoElement> = {
  autoplay: false,
  controls: false,
  defaultMuted: true,
  muted: true,
}

interface MultiPlayerVideoProps {
  videoUrl: string
  seekTime: number
}

export const StillVideoPlayer: React.FC<MultiPlayerVideoProps> = ({
  videoUrl,
  seekTime,
}) => {
  const player = useRef<VideoJsPlayer>()
  const { containerRef, videoRef } = useStillVideoPlayer(
    videoUrl,
    seekTime,
    player
  )

  useEffect(() => {
    const videoElement = videoRef.current
    const videoJsPlayer = videojs(videoElement, {
      ...defaultPlayerOptions,
    })

    const src = { src: videoUrl ?? '', type: 'application/x-mpegURL' }
    videoJsPlayer.src([src])

    // set the ref, since we need to dispose it
    player.current = videoJsPlayer
  }, [videoRef, videoUrl])

  useEffect(() => {
    return () => {
      player.current?.dispose()
      player.current = null
    }
  }, [])

  const containerStyles = useMemo(() => ({ width: '100%', height: '100%' }), [])

  return (
    <div data-vjs-player style={containerStyles} ref={containerRef}>
      <video ref={videoRef} className="video-js" />
    </div>
  )
}

const useStillVideoPlayer = (
  videoUrl: string,
  seekTime: number,
  player: React.MutableRefObject<VideoJsPlayer>
) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  // not used yet, but will be helpful once we use facecam or custom content
  const [containerRef] = useDimensions()

  // useEffect(() => {
  //   console.log({ current: player.current, src })
  // }, [player, src])

  useEffect(() => {
    const loadStartEvent = () => {
      if (videoRef.current) {
        videoRef.current.currentTime = seekTime / 1000
      }
    }
    const vref = videoRef.current
    vref?.addEventListener('loadedmetadata', loadStartEvent)
    return () => vref?.removeEventListener('loadedmetadata', loadStartEvent)
  }, [player, seekTime, videoUrl])

  return useMemo(
    () => ({
      containerRef,
      videoRef,
    }),
    [containerRef]
  )
}
