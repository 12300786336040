import momentjs from 'moment'
import React, { useCallback, useMemo, useState } from 'react'
import { sortBy } from 'lodash'

import { useTimelineHelpers } from 'core/editor/timeline/hooks'
import { ChatMomentFragment } from '__generated__'

import {
  ChatMomentContentDiv,
  ChatMomentItemDiv,
  ChatMomentsContainerDiv,
} from './styled'

interface ChatSidebarProps {
  moments: ChatMomentFragment[]
}

export const ChatSidebar: React.FC<ChatSidebarProps> = ({ moments }) => {
  const { results } = useChatMomentSidebarData(moments)

  return (
    <ChatMomentsContainerDiv>
      <h2>Chat Moments</h2>
      <ChatMomentContentDiv>
        {results.map((moment) => (
          <ChatMomentItem key={moment.id} moment={moment} />
        ))}
      </ChatMomentContentDiv>
    </ChatMomentsContainerDiv>
  )
}

enum SortType {
  StartTime = 'StartTime',
  Score = 'Score',
}

const useChatMomentSidebarData = (moments: ChatMomentFragment[]) => {
  const [filter, setFilter] = useState<string | undefined>(undefined)
  const [sortType, setSortType] = useState<SortType>(SortType.Score)

  const filteredAndSorted = useMemo(() => {
    const filtered = filter ? moments.filter((m) => m.type === filter) : moments
    const sorted = sortBy(filtered, (m) => {
      return sortType === SortType.Score
        ? -m.metadata.score
        : momentjs(m.startsAt).valueOf()
    })
    return sorted
  }, [filter, moments, sortType])

  return useMemo(
    () => ({
      results: filteredAndSorted,
      filter,
      setFilter,
      sortType,
      setSortType,
    }),
    [filter, filteredAndSorted, sortType]
  )
}

interface ChatMomentItemProps {
  moment: ChatMomentFragment
}

export const ChatMomentItem: React.FC<ChatMomentItemProps> = ({ moment }) => {
  const { jumpToMoment, score, type } = useChatMomentData(moment)
  return (
    <ChatMomentItemDiv onClick={jumpToMoment}>
      {type} - {score}
    </ChatMomentItemDiv>
  )
}

const useChatMomentData = (moment: ChatMomentFragment) => {
  const { jumpToNewSelection } = useTimelineHelpers()

  const jumpToMoment = useCallback(() => {
    const startTime = momentjs(moment.startsAt).valueOf()
    const endTime = momentjs(moment.endsAt).valueOf()
    jumpToNewSelection(startTime, endTime, true)
  }, [jumpToNewSelection, moment.endsAt, moment.startsAt])

  const score = moment.metadata?.score ?? 0

  return useMemo(
    () => ({
      type: moment.type,
      score: score.toFixed(2),
      jumpToMoment,
    }),
    [jumpToMoment, moment.type, score]
  )
}
