import { groupBy, uniqBy } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import SimpleBar from 'simplebar-react'

import { Button } from 'components/core/button'
import { HStack } from 'components/core/layout'
import { Spinner } from 'components/core/spinner'
import { MomentEditFragment, useGetMomentDetailQuery } from '__generated__'

import { useSignalReviewState } from '../context'
import { MomentQuickActionsModal } from '../controls/actions-modal'
import {
  CreateButtonDiv,
  ExportViewContainerDiv,
  ExportViewLoadingContainerDiv,
  ExportViewOptionsDiv,
  ExportViewSidebarDiv,
} from '../styled'
import { CreateVideoContent, CreateVideoModal } from './create-video'
import { useCheckExportUpdates } from './hooks'
import { EditItemPreview } from './item'
import { VideoPreview } from './video-preview'

interface Props {
  momentId: string
}

export const ExportView = ({ momentId }: Props) => {
  const { edits, editsByFormat, refetch, moment } =
    useExportMomentData(momentId)
  const [selectedEditId, setSelectedEditId] = useState<string>()
  const [createVideoModalOpen, setCreateVideoModalOpen] = useState(false)
  const { showMomentActionsModal } = useSignalReviewState()
  useCheckExportUpdates(momentId)

  const selectedEdit = useMemo(() => {
    return selectedEditId
      ? edits?.find((edit) => edit.id === selectedEditId)
      : undefined
  }, [edits, selectedEditId])

  const recentExport = selectedEdit?.exports[0]
  // is it loading?
  const downloadUrl = recentExport?.output?.videos?.[0]?.url

  const simplebarStyles = useMemo(
    () => ({
      width: '100%',
      height: 'calc(100% - 50px)',
    }),
    []
  )

  useEffect(() => {
    if (selectedEditId === undefined && edits && edits[0]) {
      setSelectedEditId(edits[0].id)
    }
  }, [edits, selectedEditId])

  if (edits === undefined) {
    return (
      <ExportViewLoadingContainerDiv>
        <HStack
          fullHeight
          fullWidth={true}
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: 48 }}
        >
          <Spinner size={48} />
        </HStack>
      </ExportViewLoadingContainerDiv>
    )
  }

  if (edits.length === 0) {
    return (
      <ExportViewLoadingContainerDiv>
        <CreateVideoContent
          momentId={momentId}
          videoId={moment?.mainVideo.id ?? ''}
          start={moment?.startsAt}
          end={moment?.endsAt}
          close={() => {
            refetch()
          }}
        />
      </ExportViewLoadingContainerDiv>
    )
  }

  return (
    <ExportViewContainerDiv>
      <ExportViewSidebarDiv>
        <div>
          <CreateButtonDiv>
            <Button onClick={() => setCreateVideoModalOpen(true)}>
              Create Video
            </Button>
          </CreateButtonDiv>
          <SimpleBar style={simplebarStyles}>
            {editsByFormat.map(({ format, edits: formatEdits }) => {
              return (
                <React.Fragment key={format.id}>
                  {formatEdits.map((edit) => (
                    <EditItemPreview
                      key={edit.id}
                      selected={edit.id === selectedEditId}
                      momentEdit={edit}
                      selectEdit={setSelectedEditId}
                    />
                  ))}
                </React.Fragment>
              )
            })}
          </SimpleBar>
        </div>
      </ExportViewSidebarDiv>
      {selectedEdit && (
        <>
          <VideoPreview momentId={momentId} momentEdit={selectedEdit} />
          {recentExport && (
            <ExportViewOptionsDiv>
              <HStack gap={12}>
                <Link
                  to={{
                    pathname: `/m/${momentId}/editor/${selectedEditId}`,
                    state: { referrer: 'exports' },
                  }}
                >
                  <Button>Open in Editor</Button>
                </Link>
                <a href={downloadUrl}>
                  <Button>Download</Button>
                </a>
              </HStack>
            </ExportViewOptionsDiv>
          )}
        </>
      )}
      {showMomentActionsModal && (
        <MomentQuickActionsModal showEdits={false} onCreate={() => refetch()} />
      )}
      {createVideoModalOpen && (
        <CreateVideoModal
          momentId={momentId}
          videoId={moment?.mainVideo.id ?? ''}
          start={moment?.startsAt}
          end={moment?.endsAt}
          close={() => {
            setCreateVideoModalOpen(false)
            refetch()
          }}
        />
      )}
    </ExportViewContainerDiv>
  )
}

const useExportMomentData = (momentId: string) => {
  const { data, refetch } = useGetMomentDetailQuery({
    variables: { momentId },
    fetchPolicy: 'network-only',
  })

  const multiMoment = useMemo(() => {
    if (data?.moment?.__typename === 'MultiPerspectiveMoment') {
      return data.moment
    }
  }, [data?.moment])

  const edits: MomentEditFragment[] | undefined = useMemo(() => {
    if (data?.moment?.__typename === 'MultiPerspectiveMoment') {
      return data.moment.edits
    }
  }, [data?.moment])

  const editsByFormat = useMemo(() => {
    const uniqueFormats = uniqBy(
      (edits ?? []).map(({ format }) => format),
      ({ id }) => id
    )
    const editsByFormatId = groupBy(edits, ({ format }) => format.id)
    return uniqueFormats.map((format) => {
      return {
        format,
        edits: editsByFormatId[format.id],
      }
    })
  }, [edits])

  return useMemo(
    () => ({
      moment: multiMoment,
      editsByFormat,
      edits,
      refetch,
    }),
    [edits, editsByFormat, multiMoment, refetch]
  )
}
