import React, { useCallback, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import momentjs from 'moment'

import {
  MomentEditFragment,
  useSetMomentEditPublishedMutation,
} from '__generated__'
import { Button } from 'components/core/button'
import { Text } from 'components/core/text'
import { durationFormatMinutes } from 'utils/time'

import { useExportEdit } from '../exporter/hooks'
import {
  ExportContainerDiv,
  ExportContentDiv,
  MomentEditPreviewContainerDiv,
  MomentEditTitleDiv,
  SceneInfoDiv,
  ShowAllExportsButtonDiv,
} from '../styled'
import { MomentExportPreview } from './export-preview'
import { Spacing } from 'components/core/layout'

interface MomentEditPreviewProps {
  momentId: string
  momentEdit: MomentEditFragment
}

export const MomentEditPreview: React.FC<MomentEditPreviewProps> = ({
  momentId,
  momentEdit,
}) => {
  const exportEdit = useExportEdit(momentId, momentEdit.id)
  const [showAllExports, setShowAllExports] = useState(false)
  const [setEditPublish] = useSetMomentEditPublishedMutation()

  const totalTime = useMemo(() => {
    const timeInMs = momentEdit.scenes.reduce((prev, scene) => {
      return (
        prev + (momentjs(scene.end).valueOf() - momentjs(scene.start).valueOf())
      )
    }, 0)
    return durationFormatMinutes(timeInMs / 1000)
  }, [momentEdit.scenes])

  const togglePublishedStatus = useCallback(() => {
    setEditPublish({
      variables: {
        editId: momentEdit.id,
        nextPublishStatus: !momentEdit.published,
      },
    })
  }, [momentEdit.id, momentEdit.published, setEditPublish])

  return (
    <MomentEditPreviewContainerDiv>
      <MomentEditTitleDiv>
        <Link to={`/m/${momentId}/editor/${momentEdit.id}`}>
          <Text variant="text-1">{momentEdit.title}</Text>
        </Link>
        <SceneInfoDiv>
          <Text variant="text-3" as="p">
            {momentEdit.scenes.length} Scene
            {momentEdit.scenes.length > 1 ? 's' : null}-{totalTime}
          </Text>
          <Spacing top={4}>
            <Button
              size="small"
              variant={momentEdit.published ? 'secondary' : 'primary'}
              onClick={togglePublishedStatus}
            >
              {momentEdit.published ? 'Unpublish' : 'Publish'}
            </Button>
          </Spacing>
        </SceneInfoDiv>
      </MomentEditTitleDiv>
      <ExportContainerDiv>
        <Text variant="h6">Exports</Text>
        <ExportContentDiv>
          {!showAllExports && momentEdit.exports[0] && (
            <MomentExportPreview
              momentExport={momentEdit.exports[0]}
              formatAspectRatio={momentEdit.format.aspectRatio}
            />
          )}
          {showAllExports &&
            momentEdit.exports.map((videoExport) => (
              <MomentExportPreview
                key={videoExport.id}
                formatAspectRatio={momentEdit.format.aspectRatio}
                momentExport={videoExport}
              />
            ))}
          <ShowAllExportsButtonDiv>
            {!showAllExports && momentEdit.exports.length > 1 && (
              <Button variant="plain" onClick={() => setShowAllExports(true)}>
                Show All Exports
              </Button>
            )}
            {showAllExports && momentEdit.exports.length > 1 && (
              <Button variant="plain" onClick={() => setShowAllExports(false)}>
                Hide Old Exports
              </Button>
            )}
          </ShowAllExportsButtonDiv>
        </ExportContentDiv>
        <div>
          <Button variant="secondary" size="small" onClick={exportEdit}>
            {momentEdit.exports.length > 0
              ? 'Create Another Export'
              : 'Create Initial Export'}
          </Button>
        </div>
      </ExportContainerDiv>
    </MomentEditPreviewContainerDiv>
  )
}
