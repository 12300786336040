import { useEffect, useMemo } from 'react'
import {
  useGetLeaderBoardRankingsLazyQuery,
  useGetValorantMatchHighlightQuery,
} from '__generated__'

export type PlayerHighlightData = {
  accountId: string
  gameName: string
  displayName: string
  profileImageUrl: string
  competitiveTier: string
  kills: number
  deaths: number
  leaderboardRank?: number
  agent: string
}

export const useGetMatchHighlights = (
  matchId: string,
  accountIds: string[]
) => {
  const { data } = useGetValorantMatchHighlightQuery({
    variables: {
      matchId,
    },
  })
  const leaderboardRankById = useGetPlayersLeaderboardRanksByAccountId(
    accountIds,
    data?.valorantMatch?.endedAt
  )

  const highlightedPlayers = useMemo(
    () =>
      data?.valorantMatch?.players.filter((pl) =>
        accountIds.includes(pl.account?.id ?? '')
      ),
    [data?.valorantMatch?.players, accountIds]
  )

  const players: PlayerHighlightData[] | undefined = useMemo(() => {
    if (!highlightedPlayers && !leaderboardRankById) return []
    return highlightedPlayers?.map((player) => {
      const channelInfo = player.account?.channel
      return {
        accountId: player.account?.id ?? '',
        gameName: player.gameName ?? '',
        displayName: channelInfo?.displayName ?? '',
        profileImageUrl: channelInfo?.profileImageUrl ?? '',
        competitiveTier: player.competitiveTier ?? '',
        kills: player.stats.kills ?? 0,
        deaths: player.stats.deaths ?? 0,
        leaderboardRank: leaderboardRankById?.[player.account?.id ?? ''],
        agent: player.agent ?? '',
      }
    })
  }, [highlightedPlayers, leaderboardRankById])
  return players

  // return useMemo(() => {
  //   return {
  //     displayName,
  //     profileImageUrl,
  //     kills,
  //     deaths,
  //     assists,
  //     agent,
  //   }
  // }, [highlightedPlayers, leaderboardRank])
}

export const useGetPlayersLeaderboardRanksByAccountId = (
  accountIds?: string[],
  matchEnd?: string
) => {
  const [query, { data }] = useGetLeaderBoardRankingsLazyQuery()

  useEffect(() => {
    if (accountIds && matchEnd) {
      query({ variables: { accountIds, before: matchEnd } })
    }
  }, [accountIds, matchEnd, query])

  return useMemo(() => {
    return data?.valorantLeaderboard?.data
      .filter((item) => accountIds?.includes(item.account?.id ?? ''))
      .reduce((acc, item) => {
        acc[item.account?.id ?? ''] = item.leaderboardRank
        return acc
      }, {} as Record<string, number>)
  }, [accountIds, data?.valorantLeaderboard?.data])
}
