import { up } from 'styled-breakpoints'
import styled from 'styled-components/macro'

export const GridContainer = styled.div`
  display: grid;
  gap: 16px;

  grid-template-columns: repeat(2, 1fr);
  ${up('sm')} {
    grid-template-columns: repeat(3, 1fr);
  }
`

export const GridItemContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.neutrals.n8};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.neutrals.n6};
  box-shadow: ${({ theme }) => theme.elevation.depth2};

  cursor: pointer;
  transition: box-shadow 0.2s ease-in-out;

  &:hover {
    box-shadow: ${({ theme }) => theme.elevation.depth3};
  }
`

export const GridItemImageContainer = styled.div`
  position: relative;
  height: 0;
  padding-bottom: 56.25%;

  background-color: ${({ theme }) => theme.colors.neutrals.n6};
  border-radius: 8px 8px 0 0;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 1));
    opacity: 0.25;
  }
`

export const GridItemThumbnailImg = styled.img`
  position: absolute;
  max-width: 100%;
  top: 0;
  left: 0;
`

export const GridItemInfo = styled.div`
  padding: 8px 16px 16px;
`

export const SkeletonTitleText = styled.div`
  width: 75%;
  height: 14px;
  margin-top: 4px;

  background-color: ${({ theme }) => theme.colors.neutrals.n6};
`
export const SkeletonEditsText = styled.div`
  width: 25%;
  height: 12px;
  margin-top: 6px;

  background-color: ${({ theme }) => theme.colors.neutrals.n6};
`

export const GridItemChannel = styled.div`
  position: absolute;
  display: flex;
  right: 16px;
  bottom: 8px;
  justify-content: center;
  align-items: center;

  gap: 4px;

  z-index: 1;
`

export const GridItemChannelImg = styled.img`
  width: 16px;
  height: 16px;

  border-radius: 100%;
  border: 1px solid ${({ theme }) => theme.colors.neutrals.n5};
`
