import React, { useMemo } from 'react'

import { useComposerState } from 'core/composer/context'
import { useEditorState } from 'core/editor/context'
import { ValorantMatchPlayerPreviewFragment } from '__generated__'
import { Justifications } from 'core/composer/template-sidebar/addons/killfeed'

import { KillsByPlayer, KillsData, useKillFeed, useKillFeedData } from './hooks'
import { KillFeedContainer } from './styled'
import { STANDARD_FORMAT } from 'core/exporter/constants'
import { KillFeedStillDefault } from './types/default'
import { KillFeedStillAgentIcons } from './types/agent-icons'

export const KillFeedWithComposerContext = () => {
  const { selection, currentTime } = useEditorState()
  const { primaryVideoId, previewRect, killfeedOptions, selectedFormat } =
    useComposerState()
  const { width, height } = useMemo(() => {
    const width = selectedFormat?.id === STANDARD_FORMAT.id ? 1920 : 1080
    const height = selectedFormat?.id === STANDARD_FORMAT.id ? 1080 : 1920
    return { width, height }
  }, [selectedFormat])
  const scale = useMemo(() => {
    const height = selectedFormat?.id === STANDARD_FORMAT.id ? 1080 : 1920
    return (previewRect?.height ?? 0) / height
  }, [previewRect?.height, selectedFormat])
  const props = {
    y: killfeedOptions.y,
    justify: killfeedOptions.justify,
  }

  return selection?.startTime && primaryVideoId ? (
    <KillFeedContainer $scale={scale}>
      <KillFeed
        width={width}
        height={height}
        scale={scale}
        startTime={selection?.startTime ?? 0}
        endTime={selection?.endTime ?? 0}
        videoId={primaryVideoId}
        currentTime={currentTime}
        type={killfeedOptions.type ?? 'default'}
        {...props}
      />
    </KillFeedContainer>
  ) : (
    <React.Fragment />
  )
}

export type KillfeedTypes = 'default' | 'agent-icons'

interface Props {
  width: number
  height: number
  scale: number
  videoId: string
  startTime: number
  endTime: number
  currentTime: number | undefined
  justify: Justifications
  y: number
  type: KillfeedTypes
}

export const KillFeed = ({
  width,
  height,
  videoId,
  startTime,
  currentTime,
  type,
  scale,
  y,
  justify,
}: Props) => {
  const { accountId, playerKills, round, gameName, playerStats } =
    useKillFeedData(videoId, startTime)

  // useEffect(() => {
  //   console.log(
  //     `http://localhost:3000/addons/killfeed?videoId=${videoId}&startsAt=${startTime}&endsAt=${endTime}`
  //   )
  // }, [endTime, startTime, videoId])

  return (
    <KillFeedStill
      width={width}
      height={height}
      accountId={accountId}
      killsByPlayer={playerKills}
      currentTime={currentTime}
      gameName={gameName ?? ''}
      playerStats={playerStats}
      round={round}
      scale={scale}
      type={type}
      y={y}
      justify={justify}
    />
  )
}

export interface StillProps {
  accountId?: string
  playerStats?: { [accountId: string]: ValorantMatchPlayerPreviewFragment }
  killsByPlayer?: KillsByPlayer
  round?: number
  currentTime?: number
  gameName?: string
  scale: number
  y: number
  width: number
  height: number
  justify: Justifications
  type: KillfeedTypes
}

export type StillPropsTypes = StillProps & {
  kills: KillsData[]
}

export const KillFeedStill = (props: StillProps) => {
  const { accountId, round, currentTime, killsByPlayer, playerStats, type } =
    props
  const kills = useKillFeed(
    accountId ?? '',
    round ?? 0,
    currentTime,
    killsByPlayer ?? {}
  )

  if (!playerStats) {
    return null
  }

  return type === 'default' ? (
    <KillFeedStillDefault {...props} kills={kills} />
  ) : (
    <KillFeedStillAgentIcons {...props} kills={kills} />
  )
}
