import { useMemo } from 'react'
import momentjs from 'moment'

import { useVideoTimelineData } from '../helpers'
import { useTimelineState } from 'core/editor/timeline/context'
import { Text } from 'components/core/text'
import { TimelineContentsDiv, TimelineMomentSelectionDiv } from '../styled'
import { useEditorOffsets } from 'core/editor/hooks'

interface Props {
  videoId: string
}

export const SignalPlayerChatTimeline = ({ videoId }: Props) => {
  const { momentPcts, width, offset } = useSignalPlayerChatTimelineData(videoId)

  const containerStyle = useMemo(() => {
    return { width, left: !isNaN(offset) ? offset : 0 }
  }, [offset, width])

  const momentStyles = useMemo(() => {
    return momentPcts?.map((data) =>
      data
        ? {
            styles: {
              left: `${data.startPct * 100}%`,
              width: `${(data.endPct - data.startPct) * 100}%`,
            },
            type: data.type,
            score: data.meta.score,
            id: data.id,
          }
        : undefined
    )
  }, [momentPcts])

  return (
    <TimelineContentsDiv style={containerStyle}>
      {momentStyles?.map((data) =>
        data ? (
          <TimelineMomentSelectionDiv style={data.styles} key={data.id}>
            <Text variant="small">
              {data.type} - {(data.score * 100).toFixed(2)}%
            </Text>
          </TimelineMomentSelectionDiv>
        ) : null
      )}
    </TimelineContentsDiv>
  )
}

const useSignalPlayerChatTimelineData = (videoId: string) => {
  const { data } = useVideoTimelineData(videoId)
  const offsets = useEditorOffsets()
  const { startTime: timelineStart } = useTimelineState()
  const { containerRect, timelineLength } = useTimelineState()

  const { startedAt, videoLength } = useMemo(() => {
    const startedAt = momentjs(data?.video?.stream?.startedAt).valueOf()
    const endedAt = momentjs(data?.video?.stream?.endedAt).valueOf()
    const videoLength = endedAt - startedAt
    return { startedAt, endedAt, videoLength }
  }, [data?.video?.stream?.endedAt, data?.video?.stream?.startedAt])

  const { width, offset } = useMemo(() => {
    const pxPerMs = (containerRect?.width ?? 0) / timelineLength
    const offset =
      (startedAt - timelineStart + (offsets[videoId] ?? 0) * 1000) * pxPerMs
    return { width: videoLength * pxPerMs, offset }
  }, [
    containerRect?.width,
    offsets,
    startedAt,
    timelineLength,
    timelineStart,
    videoId,
    videoLength,
  ])

  const momentPcts = useMemo(() => {
    const momentData = data?.video?.moments.data.map((moment) => {
      if (moment.__typename === 'ChatMoment') {
        const momentStart = momentjs(moment.startsAt).valueOf()
        const momentEnd = momentjs(moment.endsAt).valueOf()
        const type = moment.type
        const meta = moment.metadata

        return {
          id: moment.id,
          startPct: (momentStart - startedAt) / videoLength,
          endPct: (momentEnd - startedAt) / videoLength,
          type,
          meta,
        }
      }
      return undefined
    })
    return momentData
  }, [data?.video?.moments.data, startedAt, videoLength])

  return useMemo(
    () => ({ momentPcts, width, offset }),
    [momentPcts, offset, width]
  )
}
