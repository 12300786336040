import { Button } from 'components/core/button'
import { IconExternalOpen } from 'components/core/icons'
import { Text } from 'components/core/text'
import { ReactNode } from 'react'
import { useTheme } from 'styled-components/macro'
import { SocialIcon } from './social-icon'
import {
  StyledIconWrapper,
  StyledSocialLink,
  StyledSocialLinkType,
} from './styled'

type Props = {
  type: string
  to?: string
  children: ReactNode
}
export const SocialLink = ({ type, children, to }: Props) => {
  const theme = useTheme()

  const Comp = to ? (
    <Button as="a" href={to} variant={'plain'} size={'small'} noPadding>
      {children}
    </Button>
  ) : (
    <Text
      variant={'text-3'}
      weight={'semibold'}
      color={theme.colors.neutrals.n4}
    >
      {children}
    </Text>
  )
  return (
    <StyledSocialLink>
      <StyledSocialLinkType>
        <SocialIcon type={type} />
      </StyledSocialLinkType>
      {Comp}
      {to && (
        <StyledIconWrapper>
          <IconExternalOpen fill={theme.colors.neutrals.n5} />
        </StyledIconWrapper>
      )}
    </StyledSocialLink>
  )
}
