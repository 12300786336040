import { HStack, Spacing } from 'components/core/layout'
import { Spinner } from 'components/core/spinner'
import { useFeedHelpers } from 'core/feed/helpers'
import { MultiFeed } from 'core/feed/multi-feed'
import { useEffect, useMemo } from 'react'
import styled from 'styled-components/macro'
import { useGetMatchQuery } from '__generated__'

interface Props {
  matchId: string
  channelIds: string[]
  selectable?: boolean
  selectedMap?: Record<string, true>
  toggleSelected?: (signalId: string) => void
}

export const MatchFeed = ({
  matchId,
  channelIds,
  selectable,
  selectedMap,
  toggleSelected,
}: Props) => {
  const { setChannelIds } = useFeedHelpers()
  const { data: matchData } = useGetMatchQuery({ variables: { matchId } })

  const additionalFilters = useMemo(() => {
    if (matchData?.valorantMatch) {
      return {
        startsAt: {
          from: matchData.valorantMatch.startedAt,
          to: matchData.valorantMatch.endedAt,
        },
      }
    }
    return undefined
  }, [matchData?.valorantMatch])

  useEffect(() => {
    setChannelIds(channelIds)
  }, [channelIds, setChannelIds])

  return (
    <FeedContainerDiv>
      {additionalFilters ? (
        <MultiFeed
          additionalFilters={additionalFilters}
          selectable={selectable}
          selectedMap={selectedMap}
          toggleSelected={toggleSelected}
        />
      ) : (
        <Spacing top={32}>
          <HStack justifyContent="center" fullWidth>
            <Spinner size={48} />
          </HStack>
        </Spacing>
      )}
    </FeedContainerDiv>
  )
}

const FeedContainerDiv = styled.div`
  min-width: 800px;
  padding: 0 16px;
`
