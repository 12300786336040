import styled, { css } from 'styled-components/macro'

import {
  MomentCollectionFragment,
  useGetMyMomentCollectionsQuery,
  useUpdateMomentsInCollectionMutation,
} from '__generated__'
import { Text } from 'components/core/text'
import { ReactComponent as CheckCircleIcon } from 'icons/svgs/solid/check-circle.svg'
import { ReactComponent as CheckCircleEmptyIcon } from 'icons/svgs/light/check-circle.svg'
import { useCallback, useMemo } from 'react'

const TEMP_IGNORE_LIST = [
  '062a2ef6-5703-4320-94f4-0906f8917d50',
  'f1dafd33-ada1-4c8c-aa14-a5c4b6578803',
  'ff418936-9e01-4b62-b208-adb3b123bac6',
  '720b1241-26d5-4fba-a150-de31db07391e',
  '18076d50-9a67-4bb5-82ae-d0abfb7f7c4a',
  '7d1fc2b6-4857-4d3a-bce0-d282f8b388be',
  '62d29038-fdd4-45fb-95ca-c9391ccd1597',
  '8dbe468e-a5d8-4ce3-b630-121022f75d03',
]

interface Props {
  position?: 'above' | 'below'
  momentId: string
}

export const AddToCollectionPopover = ({
  position = 'above',
  momentId,
}: Props) => {
  const { data } = useGetMyMomentCollectionsQuery()

  const momentCollections = useMemo(() => {
    return data?.momentCollections.data.filter(
      (mc) => TEMP_IGNORE_LIST.indexOf(mc.id) === -1
    )
  }, [data?.momentCollections.data])

  return data ? (
    <ContainerDiv $position={position}>
      <ListContainerDiv>
        {momentCollections?.map((collection, idx) => (
          <CollectionItem
            key={idx}
            momentId={momentId}
            collectionData={collection}
          />
        ))}
      </ListContainerDiv>
    </ContainerDiv>
  ) : null
}

interface ItemProps {
  collectionData: MomentCollectionFragment
  momentId: string
}

const CollectionItem = ({ collectionData, momentId }: ItemProps) => {
  const [updateMomentIds] = useUpdateMomentsInCollectionMutation()

  const momentIds = useMemo(() => {
    return collectionData.moments.data.map(({ id }) => id)
  }, [collectionData.moments.data])

  const inCollection = useMemo(() => {
    return momentIds.find((id) => id === momentId) !== undefined
  }, [momentId, momentIds])

  const addOrRemoveFromCollection = useCallback(() => {
    let nextMomentIds: string[] = []
    if (inCollection) {
      nextMomentIds = momentIds.filter((id) => id !== momentId)
    } else {
      nextMomentIds = [momentId, ...momentIds]
    }
    updateMomentIds({
      variables: {
        collectionId: collectionData.id,
        nextMomentIds,
      },
    })
  }, [collectionData.id, inCollection, momentId, momentIds, updateMomentIds])

  return (
    <ItemContainerDiv onClick={addOrRemoveFromCollection}>
      <ItemInCollectionIndicatorDiv $inCollection={inCollection}>
        {inCollection ? <CheckCircleIcon /> : <CheckCircleEmptyIcon />}
      </ItemInCollectionIndicatorDiv>
      <Text variant="text-3">{collectionData.title}</Text>
    </ItemContainerDiv>
  )
}

const ContainerDiv = styled.div<{ $position: 'above' | 'below' }>`
  position: absolute;

  width: 250px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.neutrals.n6};

  background-color: ${({ theme }) => theme.colors.neutrals.n8};
  box-shadow: ${({ theme }) => theme.elevation.depth2};
  padding: 24px;
  z-index: 2;

  ${({ $position }) =>
    $position === 'above'
      ? css`
          bottom: 100%;
          margin-bottom: 16px;
        `
      : css`
          top: 100%;
          margin-bottom: 16px;
        `}
`

const ListContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

const ItemInCollectionIndicatorDiv = styled.div<{ $inCollection: boolean }>`
  width: 16px;
  height: 16px;
  margin-right: 8px;

  svg {
    width: 100%;
    height: 100%;
    ${({ $inCollection, theme }) =>
      !$inCollection
        ? css`
            fill: ${theme.colors.neutrals.n6};
          `
        : css`
            fill: ${theme.colors.neutrals.n3};
          `}
  }
`

const ItemContainerDiv = styled.div`
  margin: 4px 0;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    color: ${({ theme }) => theme.colors.blue};

    & > ${ItemInCollectionIndicatorDiv} svg {
      fill: ${({ theme }) => theme.colors.blue};
    }
  }
`
