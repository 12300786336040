import React, { createContext, useContext, useReducer } from 'react'
import { VideoSyncType } from './constants'

interface VideoSyncState {
  momentId?: string
  primaryVideoId?: string
  secondaryVideoId?: string
  syncType: VideoSyncType
}

const INITIAL_STATE: VideoSyncState = {
  momentId: undefined,
  primaryVideoId: undefined,
  secondaryVideoId: undefined,
  syncType: VideoSyncType.Audio,
}

enum ACTION_TYPES {
  SET_MOMENT_ID = 'SET_MOMENT_ID',
  SET_PRIMARY_VIDEO_ID = 'SET_PRIMARY_VIDEO_ID',
  SET_SECONDARY_VIDEO_ID = 'SET_SECONDARY_VIDEO_ID',
  SET_SYNC_TYPE = 'SET_SYNC_TYPE',
}

interface SetMomentIdAction {
  type: ACTION_TYPES.SET_MOMENT_ID
  payload: {
    momentId?: string
  }
}

interface SetPrimaryVideoIdAction {
  type: ACTION_TYPES.SET_PRIMARY_VIDEO_ID
  payload: {
    primaryVideoId?: string
  }
}

interface SetSecondaryVideoIdAction {
  type: ACTION_TYPES.SET_SECONDARY_VIDEO_ID
  payload: {
    secondaryVideoId?: string
  }
}

interface SetSyncTypeAction {
  type: ACTION_TYPES.SET_SYNC_TYPE
  payload: {
    syncType: VideoSyncType
  }
}

type VideoSyncActions =
  | SetMomentIdAction
  | SetPrimaryVideoIdAction
  | SetSecondaryVideoIdAction
  | SetSyncTypeAction

type Dispatch = (action: VideoSyncActions) => void

const reducer = (
  state: VideoSyncState,
  action: VideoSyncActions
): VideoSyncState => {
  switch (action.type) {
    case ACTION_TYPES.SET_MOMENT_ID: {
      return {
        ...state,
        momentId: action.payload.momentId,
      }
    }

    case ACTION_TYPES.SET_PRIMARY_VIDEO_ID: {
      return {
        ...state,
        primaryVideoId: action.payload.primaryVideoId,
      }
    }

    case ACTION_TYPES.SET_SECONDARY_VIDEO_ID: {
      return {
        ...state,
        secondaryVideoId: action.payload.secondaryVideoId,
      }
    }

    case ACTION_TYPES.SET_SYNC_TYPE: {
      return {
        ...state,
        syncType: action.payload.syncType,
      }
    }

    default:
      return state
  }
}

const VideoSyncContext = createContext<VideoSyncState | undefined>(undefined)
const VideoUpdaterContext = createContext<Dispatch | undefined>(undefined)

export const VideoSyncProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE)

  return (
    <VideoSyncContext.Provider value={state}>
      <VideoUpdaterContext.Provider value={dispatch}>
        {children}
      </VideoUpdaterContext.Provider>
    </VideoSyncContext.Provider>
  )
}

export const useVideoSyncState = () => {
  const context = useContext(VideoSyncContext)
  if (context === undefined) {
    throw new Error('useVideoSyncState must be used within a VideoSyncProvider')
  }
  return context
}

export const useVideoSyncUpdater = () => {
  const context = useContext(VideoUpdaterContext)
  if (context === undefined) {
    throw new Error(
      'useVideoSyncUpdater must be used within a VideoSyncProvider'
    )
  }
  return context
}

useVideoSyncUpdater.ACTION_TYPES = ACTION_TYPES
