import { createGlobalStyle } from 'styled-components/macro'

const GlobalStyleSheet = createGlobalStyle`
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: ${({ theme }) => theme.colors.text.primary};
	min-height: 100vh;

  &.hidden {
    overflow: hidden;
  }
}

input, button, textarea, select {
  font: inherit;
}

a {
  color: ${({ theme }) => theme.colors.text.primary};
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*, *::before, *::after {
  box-sizing: border-box;
}

#root {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
  background-color: ${({ theme }) => theme.colors.background.secondary};
}

:root {
  --common-animation-duration: 0.2s;
  --common-ease-out: cubic-bezier(0.19, 0.65, 0.33, 1.01);
}

`

export { GlobalStyleSheet }
