import React, { useMemo } from 'react'

import {
  usePlayerMatchData,
  useValorantMatchRoundData,
  ValorantRoundStats,
} from 'core/shared/valorant/hooks'

import { useTimelineHelpers } from '../hooks'
import { ValorantRoundTimeline } from './round'
import { TimelineMatchContainerDiv } from './styled'
import { AgentSelectTimeline } from './agent-select'
import { PlayerInfo } from 'page/video/editor/sidebar/valorant/match-preview'

interface ValorantMatchTimelineDisplayProps {
  streamStart: number
  matchId: string
  accountIds: string[] // this is because one user can have alt accounts too
}

export const ValorantMatchTimelineDisplay: React.FC<
  ValorantMatchTimelineDisplayProps
> = ({ matchId, accountIds, streamStart }) => {
  const { playerDataByRound, matchStart, winningTeam } =
    useValorantMatchRoundData(matchId)
  const playerMatchData = usePlayerMatchData(matchId, accountIds)
  const { roundData, containerStyles, wonMatch } = useValorantMatchData(
    playerMatchData,
    playerDataByRound,
    accountIds,
    matchStart,
    streamStart,
    winningTeam ?? ''
  )

  return roundData ? (
    <TimelineMatchContainerDiv style={containerStyles}>
      <AgentSelectTimeline
        accountIds={accountIds}
        wonMatch={wonMatch}
        matchId={matchId}
        characterSelectLength={0} // this needs to be adjusted
      />
      {roundData.map((rd) => {
        return <ValorantRoundTimeline key={rd.roundNum} data={rd} />
      })}
    </TimelineMatchContainerDiv>
  ) : null
}

type MatchRoundDataByPlayer =
  | {
      [accountId: string]: ValorantRoundStats[]
    }
  | undefined

const useValorantMatchData = (
  playerMatchData: PlayerInfo | undefined,
  roundDataByPlayer: MatchRoundDataByPlayer,
  accountIds: string[],
  matchStart: number,
  streamStart: number,
  winningTeam: string
) => {
  const { getContentWidthFromTime } = useTimelineHelpers()

  // We only know the left, but the rest can be built out, round by round
  const containerStyles = useMemo(() => {
    if (matchStart === 0) return {}
    const offsetTime = matchStart - streamStart
    return {
      left: getContentWidthFromTime(offsetTime),
    }
  }, [getContentWidthFromTime, matchStart, streamStart])

  // find which account name matches
  const roundData = useMemo(() => {
    if (roundDataByPlayer === undefined) return undefined
    let currentAccount: ValorantRoundStats[] | undefined = undefined
    for (const accId of accountIds) {
      if (roundDataByPlayer[accId] !== undefined) {
        currentAccount = roundDataByPlayer[accId]
      }
    }
    return currentAccount
  }, [accountIds, roundDataByPlayer])

  const wonMatch =
    (playerMatchData?.team ?? '').toLocaleLowerCase() ===
    winningTeam.toLocaleLowerCase()

  return useMemo(
    () => ({ wonMatch, roundData, containerStyles }),
    [containerStyles, roundData, wonMatch]
  )
}
