import { useMemo } from 'react'

import { ReactComponent as KillIcon } from 'assets/valorant/icons/kill.svg'
import { ValorantRoundStats } from 'core/shared/valorant/hooks'
import { useTimelineHelpers } from 'core/editor/timeline/hooks'
import { useTimelineState } from 'core/editor/timeline/context'

import { useValorantMatchEditorState } from '../../context'
import {
  KillIndicatorDiv,
  PregamePaddingDiv,
  TimelineMatchContentsDiv,
  TimelineMomentSelectionDiv,
  TimelineRoundContainerDiv,
} from './styled'
import { useMatchStartRemote } from '../../hooks'

interface Props {
  roundStats: ValorantRoundStats[]
}

export const PlayerRoundTimelineDetail = ({ roundStats }: Props) => {
  const { selectedMomentStyles, pregamePaddingStyles } = usePlayerMatchDetails()

  return (
    <TimelineMatchContentsDiv>
      <PregamePaddingDiv style={pregamePaddingStyles} />
      <TimelineMomentSelectionDiv style={selectedMomentStyles} />
      {roundStats?.map((roundData) => (
        <ValorantRoundTimeline key={roundData.roundNum} data={roundData} />
      ))}
    </TimelineMatchContentsDiv>
  )
}

const usePlayerMatchDetails = () => {
  const { matchId, selectedMomentTimes, primaryVideoId } =
    useValorantMatchEditorState()
  const { getContentWidthFromTime } = useTimelineHelpers()
  const { startTime: timelineStartTime } = useTimelineState()
  const { matchStart } = useMatchStartRemote(matchId, primaryVideoId)

  const pregameTime = useMemo(() => {
    if (!matchStart) return 0
    return matchStart - timelineStartTime
  }, [matchStart, timelineStartTime])

  const pregamePaddingStyles = useMemo(
    () => ({
      width: getContentWidthFromTime(pregameTime),
    }),
    [getContentWidthFromTime, pregameTime]
  )

  const selectedMomentStyles = useMemo(() => {
    if (!selectedMomentTimes) return undefined
    return {
      left: getContentWidthFromTime(
        pregameTime + selectedMomentTimes.start - timelineStartTime
      ),
      width: getContentWidthFromTime(
        selectedMomentTimes.end - selectedMomentTimes.start
      ),
    }
  }, [
    getContentWidthFromTime,
    pregameTime,
    selectedMomentTimes,
    timelineStartTime,
  ])

  return useMemo(
    () => ({ selectedMomentStyles, pregamePaddingStyles }),
    [pregamePaddingStyles, selectedMomentStyles]
  )
}

interface ValorantRoundTimelineProps {
  data: ValorantRoundStats
}

export const ValorantRoundTimeline: React.FC<ValorantRoundTimelineProps> = ({
  data,
}) => {
  const { containerStyles, killNotchStyles } = useValorantRoundData(data)

  return (
    <TimelineRoundContainerDiv style={containerStyles}>
      {killNotchStyles.map((style, idx) => (
        <KillIndicatorDiv key={idx} style={style}>
          <KillIcon />
        </KillIndicatorDiv>
      ))}
    </TimelineRoundContainerDiv>
  )
}

const useValorantRoundData = (data: ValorantRoundStats) => {
  const { getContentWidthFromTime } = useTimelineHelpers()
  const containerStyles = useMemo(
    () => ({
      width: getContentWidthFromTime(data.roundLength),
    }),
    [data.roundLength, getContentWidthFromTime]
  )

  const killNotchStyles = useMemo(() => {
    const killOffset = data.stats.kills.map(({ timeSinceRoundStartMillis }) =>
      getContentWidthFromTime(timeSinceRoundStartMillis)
    )
    return killOffset.map((pxOffset) => ({ left: pxOffset }))
  }, [data.stats.kills, getContentWidthFromTime])

  return useMemo(
    () => ({ killNotchStyles, containerStyles }),
    [containerStyles, killNotchStyles]
  )
}
