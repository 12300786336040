import { Text } from 'components/core/text'
import { LinkingIconButton, LinkingPageDiv } from './styled'

export const TwitchAuthCallback = () => {
  // Create a new URLSearchParams object based on the current window's search string
  const searchParams = new URLSearchParams(window.location.search)
  const url = `mmnts://twitch?${searchParams.toString()}`

  // You could return null or some loading state here
  return (
    <LinkingPageDiv>
      <Text variant="text-2" color="white">
        Successfully Logged in
      </Text>
      <LinkingIconButton>
        <a href={url}>Finish Connecting</a>
      </LinkingIconButton>
    </LinkingPageDiv>
  )
}
