import { useValorantMatchRoundData } from 'core/shared/valorant/hooks'
import React, { useMemo } from 'react'

import { usePlayerList } from '../hooks'
import { PlayerRoundTimelineDetail } from './player-by-round'
import { RoundDisplay } from './rounds'
import { MainPerspectiveSpacerDiv, PlayerDataDisplayDiv } from './styled'

interface Props {
  matchId: string
  showAll: boolean
}

export const ValorantTimelineContents = ({ matchId, showAll }: Props) => {
  const { playerList, mainPerspectiveInfo } = usePlayerList()
  const { playerDataByRound } = useValorantMatchRoundData(matchId)

  const filteredPlayerList = useMemo(
    () =>
      showAll
        ? playerList
        : playerList.filter(
            (playerData) =>
              playerData.account.id === mainPerspectiveInfo?.accountId
          ),
    [playerList, mainPerspectiveInfo?.accountId, showAll]
  )

  return playerDataByRound ? (
    <>
      <RoundDisplay />
      <PlayerDataDisplayDiv>
        {filteredPlayerList.map((playerData) => (
          <React.Fragment key={playerData.account.id}>
            <PlayerRoundTimelineDetail
              roundStats={playerDataByRound[playerData.account.id]}
            />
            {mainPerspectiveInfo?.accountId === playerData.account.id &&
              filteredPlayerList.length > 1 && <MainPerspectiveSpacerDiv />}
          </React.Fragment>
        ))}
      </PlayerDataDisplayDiv>
    </>
  ) : null
}
