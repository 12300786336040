import React, { useEffect, useMemo, useRef, useState } from 'react'
import videojs, { PlayerOptions, VideoJsPlayer } from 'video.js'
import { useSyncedVideo } from './hooks'

const defaultPlayerOptions: Partial<PlayerOptions & HTMLVideoElement> = {
  autoplay: false,
  // controls: true,
  defaultMuted: true,
  muted: true,
}

interface MultiPlayerVideoProps {
  id: string
  videoId: string
  primaryVideoId: string
  controller?: boolean
  playerOptions?: Partial<PlayerOptions & HTMLVideoElement>
  qualityHeight?: number
  offset?: number // this is to override the api
}

export const SyncedVideo: React.FC<MultiPlayerVideoProps> = ({
  id,
  videoId,
  primaryVideoId,
  controller = false,
  playerOptions,
  qualityHeight,
  offset,
}) => {
  const player = useRef<VideoJsPlayer>()
  const [ready, setReady] = useState(false)
  const { containerRef, videoRef } = useSyncedVideo(
    id,
    videoId,
    primaryVideoId,
    player,
    controller,
    qualityHeight,
    offset,
    ready
  )

  useEffect(() => {
    if (!player.current) {
      const videoElement = videoRef.current
      const videoJsPlayer = videojs(
        videoElement,
        {
          ...defaultPlayerOptions,
          ...playerOptions,
        },
        () => {
          setReady(true)
        }
      )

      // set the ref, since we need to dispose it
      player.current = videoJsPlayer
      // this should be managed on vod issue (deleted/sub only)
      videoJsPlayer.on('error', function () {
        // console.log('error', videoJsPlayer.error())
      })
    } else {
      if (playerOptions?.muted !== undefined) {
        player.current.muted(playerOptions.muted)
      }
    }
  }, [playerOptions, videoRef])

  useEffect(() => {
    return () => {
      player.current?.dispose()
      player.current = null
    }
  }, [])

  const containerStyles = useMemo(() => ({ width: '100%', height: '100%' }), [])

  return (
    <div data-vjs-player style={containerStyles} ref={containerRef}>
      <video ref={videoRef} className="video-js" />
    </div>
  )
}
