import { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Route, RouteProps, useLocation } from 'react-router'
import jwtDecode, { JwtPayload } from 'jwt-decode'

export interface UserPayload extends JwtPayload {
  permissions?: string[]
}

type Props = {
  permissions?: string[]
} & RouteProps

export const ProtectedRoute = ({
  component: Component,
  children,
  path,
  permissions,
  ...rest
}: Props) => {
  const [authenticated, setAuthenticated] = useState<boolean | null>(null)
  const location = useLocation()
  const returnTo = location.pathname
  const {
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    getAccessTokenSilently,
  } = useAuth0()

  useEffect(() => {
    const fn = async () => {
      if (isLoading) {
        return
      }

      if (isAuthenticated) {
        if (permissions) {
          const token = await getAccessTokenSilently()
          const payload = jwtDecode<UserPayload>(token)
          if (!permissions.every((p) => payload.permissions?.includes(p))) {
            setAuthenticated(false)
            return
          }
        }
        setAuthenticated(true)
        return
      }

      await loginWithRedirect({
        appState: { returnTo },
      })
    }

    fn()
  }, [
    returnTo,
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    path,
    getAccessTokenSilently,
    permissions,
  ])

  const Unauthorized = () => <p>Unauthorized</p>

  type RenderFn = typeof rest.render
  const render: RenderFn =
    Component !== undefined
      ? (props) =>
          authenticated === true ? (
            <Component {...props} />
          ) : authenticated !== null ? (
            <Unauthorized />
          ) : undefined
      : undefined

  return (
    <Route
      path={path}
      render={render}
      children={
        authenticated === true ? (
          children
        ) : authenticated !== null ? (
          <Unauthorized />
        ) : undefined
      }
      {...rest}
    />
  )
}
