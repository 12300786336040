import { ChangeEvent, KeyboardEvent, useCallback, useMemo } from 'react'
import { useTheme } from 'styled-components/macro'
import { Rect } from '__generated__'
import { Spacing, VStack } from 'components/core/layout'
import { Text } from 'components/core/text'
import {
  SceneEditingModes,
  useComposerState,
  useComposerUpdater,
} from '../context'
import { useComposerHelper } from '../hooks'
import {
  CropRectContainer,
  CropRectContainerRow,
  IconButton,
  UnderlineInput,
  UnderlineInputLabel,
} from './styled'

import { ReactComponent as CropIcon } from 'icons/svgs/regular/crop-alt.svg'
import { ReactComponent as TrashIcon } from 'icons/svgs/regular/trash-alt.svg'
import { ReactComponent as VolumeIcon } from 'icons/svgs/solid/volume.svg'

export const TemplateSettingsDisplay = () => {
  const { selectedTemplateCrop } = useComposerState()
  const {
    editContent,
    removeLayer,
    setCropAsPrimary,
    xInput,
    yInput,
    widthInput,
    heightInput,
  } = useTemplateSettingsDisplay()

  const theme = useTheme()

  return (
    <div>
      <Text variant="text-2" weight="semibold">
        Layer Properties
      </Text>

      <Spacing top={16}>
        <CropRectContainer>
          <CropRectContainerRow>
            <UnderlineInputLabel>
              <span>X</span>
              <UnderlineInput {...xInput.bind} value={xInput.value} />
            </UnderlineInputLabel>
            <UnderlineInputLabel>
              <span>Y</span>
              <UnderlineInput {...yInput.bind} value={yInput.value} />
            </UnderlineInputLabel>
          </CropRectContainerRow>
          <CropRectContainerRow>
            <UnderlineInputLabel>
              <span>W</span>
              <UnderlineInput {...widthInput.bind} value={widthInput.value} />
            </UnderlineInputLabel>
            <UnderlineInputLabel>
              <span>H</span>
              <UnderlineInput {...heightInput.bind} value={heightInput.value} />
            </UnderlineInputLabel>
          </CropRectContainerRow>
        </CropRectContainer>
      </Spacing>

      <Spacing top={16}>
        <VStack gap={16}>
          <IconButton onClick={editContent}>
            <CropIcon fill={theme.colors.static.neutrals.n8} width={12} />
            <span>Edit Content</span>
          </IconButton>

          {!selectedTemplateCrop?.primary && (
            <IconButton
              size="small"
              variant="primary"
              onClick={setCropAsPrimary}
            >
              <VolumeIcon fill={theme.colors.static.neutrals.n8} height={12} />
              <span>Use Layer Audio</span>
            </IconButton>
          )}

          <IconButton onClick={removeLayer} color={theme.colors.rose}>
            <TrashIcon fill={theme.colors.static.neutrals.n8} height={12} />
            <span>Remove Layer</span>
          </IconButton>
        </VStack>
      </Spacing>
    </div>
  )
}

const useTemplateSettingsDisplay = () => {
  const { selectedTemplateCrop } = useComposerState()
  const { setSceneEditingMode, removeTemplateCrop, setPrimaryTemplateCrop } =
    useComposerHelper()

  const removeLayer = useCallback(() => {
    if (!selectedTemplateCrop) return
    removeTemplateCrop(selectedTemplateCrop?.title)
  }, [removeTemplateCrop, selectedTemplateCrop])

  const editContent = useCallback(() => {
    setSceneEditingMode(SceneEditingModes.Content)
  }, [setSceneEditingMode])

  const setCropAsPrimary = useCallback(() => {
    if (selectedTemplateCrop?.primary || !selectedTemplateCrop) return
    setPrimaryTemplateCrop(selectedTemplateCrop.title)
  }, [selectedTemplateCrop, setPrimaryTemplateCrop])

  const xInput = usePositionInput('x')
  const yInput = usePositionInput('y')
  const widthInput = usePositionInput('width')
  const heightInput = usePositionInput('height')

  return useMemo(
    () => ({
      editContent,
      removeLayer,
      setCropAsPrimary,
      xInput,
      yInput,
      widthInput,
      heightInput,
    }),
    [
      editContent,
      heightInput,
      removeLayer,
      setCropAsPrimary,
      widthInput,
      xInput,
      yInput,
    ]
  )
}

const usePositionInput = (prop: keyof Omit<Rect, '__typename'>) => {
  const { selectedTemplateCrop, selectedFormat, previewRect } =
    useComposerState()
  const composerDispatch = useComposerUpdater()
  const adjustsSize = useMemo(() => ['width', 'height'].includes(prop), [prop])

  // transforms aspect ratio to output resolution
  const resolution = useMemo(() => {
    if (!selectedFormat) {
      return { width: 0, height: 0 }
    }

    const { width, height } = selectedFormat.aspectRatio

    if (width === 16 && height === 9) {
      return { width: 1920, height: 1080 }
    } else if (width === 1 && height === 1) {
      return { width: 1080, height: 1080 }
    }
    return { width: 1080, height: 1920 }
  }, [selectedFormat])

  const valueTimesResolution = useCallback(
    (value: number) => {
      const accessor = ['x', 'width'].includes(prop) ? 'width' : 'height'
      return Math.round(value * resolution[accessor])
    },
    [prop, resolution]
  )

  const valueByResolution = useCallback(
    (value: number) => {
      const accessor = ['x', 'width'].includes(prop) ? 'width' : 'height'
      return value / resolution[accessor]
    },
    [prop, resolution]
  )

  // dispatches the value to the composer context
  const setValue = useCallback(
    (value: number) => {
      if (!selectedTemplateCrop || !previewRect) return
      const { title, position, aspectRatio } = selectedTemplateCrop

      let proportionalValue = {}
      // if there is no aspect ratio, don't adjust the other
      if (adjustsSize && aspectRatio.width !== 0) {
        const ratio = aspectRatio.width / aspectRatio.height
        switch (prop) {
          case 'width':
            proportionalValue['height'] =
              (value * previewRect.width) / ratio / previewRect.height
            break
          case 'height':
            proportionalValue['width'] =
              (value * previewRect.height * ratio) / previewRect.width
            break
        }
      }
      composerDispatch({
        type: useComposerUpdater.ACTION_TYPES.SET_TEMPLATE_CROP_RECT,
        payload: {
          title,
          rect: {
            ...position,
            ...proportionalValue,
            [prop]: value,
          },
        },
      })
    },
    [adjustsSize, composerDispatch, previewRect, prop, selectedTemplateCrop]
  )

  // formats the value from percentages to multiplied by output resolution
  const value = useMemo(
    () => valueTimesResolution(selectedTemplateCrop?.position[prop] || 0),
    [prop, selectedTemplateCrop?.position, valueTimesResolution]
  )

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (!selectedTemplateCrop) {
        return
      }
      const { value } = e.target
      const val = value === '' ? 0 : valueByResolution(parseInt(value, 10))
      setValue(val)
    },
    [selectedTemplateCrop, setValue, valueByResolution]
  )

  // Handle nudges by up and down arrow button. hold shift for larger jumps
  const onKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      const { key } = e
      if (!['ArrowUp', 'ArrowDown'].includes(key)) {
        return
      }
      e.preventDefault()

      let inc = 1
      if (e.shiftKey) {
        inc *= 10
      }

      if (key === 'ArrowDown') {
        inc *= -1
      }

      const next = value + inc

      setValue(valueByResolution(next))
    },
    [setValue, value, valueByResolution]
  )

  return useMemo(
    () => ({
      value,
      bind: {
        onChange,
        onKeyDown,
      },
    }),
    [onChange, onKeyDown, value]
  )
}
