import React, { forwardRef, useState } from 'react'
import styled from 'styled-components/macro'
import { fonts, fontWeights } from 'theme/typography'
import { randomUniqueId } from 'utils'

const StyledLabel = styled.label`
  ${fonts.text['text-3']}
  font-weight: ${fontWeights.semibold};
  display: block;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.neutrals.n5};
  margin-bottom: 8px;
`

const StyledTextField = styled.input`
  background-color: ${({ theme }) => theme.colors.background.primary};
  color: ${({ theme, disabled }) =>
    !disabled ? theme.colors.text.primary : theme.colors.text.tertiary};
  width: 100%;
  border: 2px solid ${({ theme }) => theme.colors.neutrals.n6};
  border-radius: 8px;
  padding: 8px 12px;
  outline: none;
  transition: 0.2s border-color ease-in-out;
  font-size: ${fonts.text['text-2']['font-size']};

  :focus {
    border-color: ${({ theme }) => theme.colors.blue};
  }
`

type Props = {
  placeholder?: string
  value?: string
  name?: string
  label?: string
  id?: string
  className?: string
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  onFocus?: React.FocusEventHandler<HTMLInputElement>
  onBlur?: React.FocusEventHandler<HTMLInputElement>
} & React.InputHTMLAttributes<HTMLInputElement>
const TextField = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const [id] = useState(props.id || `textfield-${randomUniqueId()}`)
  const { label, ...rest } = props
  return (
    <div>
      {label && <StyledLabel htmlFor={id}>{label}</StyledLabel>}
      <StyledTextField id={id} ref={ref} {...rest} />
    </div>
  )
})

export default TextField
