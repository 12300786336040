import { sortBy } from 'lodash'
import React, { useMemo, useState } from 'react'
import { useParams } from 'react-router'

import { MomentViewer } from 'core/moment-viewer'
import { MomentViewerProvider } from 'core/moment-viewer/context'
import {
  PreviewMomentFragment,
  useGetCampaignViewerMomentsQuery,
} from '__generated__'
import { CampaignSegments } from 'page/campaign/detail/segment-list'

import { Text } from 'components/core/text'
import {
  CampaignSegmentsDiv,
  CampaignPageDiv,
  CampaignTitleDiv,
  MomentViewerDiv,
} from 'page/campaign/styled'
import { GroupedSegment, useCampaignData } from 'page/campaign/hooks'
import { Container, Spacing } from 'components/core/layout'
import { Logo } from 'components/core/logo'
import { DragDropContext } from 'react-beautiful-dnd'

export const ClientCampaignPage = () => {
  const { campaignSlug } = useParams<{ campaignSlug }>()
  const { title } = useCampaignData(campaignSlug)
  const [selectedGroupedSegment, setSelectedGroupedSegment] =
    useState<GroupedSegment>()
  const { publishedMomentIds, loading } = useCampaignMoments(
    campaignSlug,
    selectedGroupedSegment
  )

  return (
    <Spacing top={16}>
      <Container alignItems="center" flex="1">
        <Logo />
      </Container>
      <CampaignPageDiv>
        <CampaignTitleDiv>
          <Text variant="h3">{title}</Text>
        </CampaignTitleDiv>
        <CampaignSegmentsDiv>
          <CampaignSegments
            setGroupedSegment={setSelectedGroupedSegment}
            selectedSegment={selectedGroupedSegment}
          />
        </CampaignSegmentsDiv>
        <MomentViewerDiv>
          <MomentViewerProvider>
            <DragDropContext onDragEnd={() => {}}>
              <MomentViewer
                loading={loading}
                momentIds={publishedMomentIds ?? []}
                includeUnapproved={false}
              />
            </DragDropContext>
          </MomentViewerProvider>
        </MomentViewerDiv>
      </CampaignPageDiv>
    </Spacing>
  )
}

const useCampaignMoments = (
  campaignSlug: string,
  groupedSegment?: GroupedSegment
) => {
  const timeframe = useMemo(() => {
    if (groupedSegment === undefined) return undefined
    const { from, to } = groupedSegment.segment
    return { from, to }
  }, [groupedSegment])

  const channelIds = useMemo(() => {
    return groupedSegment?.channels.map((c) => c.id)
  }, [groupedSegment?.channels])

  const { data, loading } = useGetCampaignViewerMomentsQuery({
    variables: { slug: campaignSlug, timeframe, channelIds },
    skip: groupedSegment === undefined,
  })

  const publishedMomentIds = useMemo(() => {
    const publishedMoments = (data?.campaign?.moments?.data
      .filter(
        (gm) =>
          gm.moment.published &&
          gm.moment.__typename === 'MultiPerspectiveMoment'
      )
      .map((gm) => gm.moment) ?? []) as PreviewMomentFragment[]
    const momentsWithApprovedEdits = publishedMoments.filter((m) => {
      return m.edits.some((edit) => edit.approved)
    })
    return sortBy(momentsWithApprovedEdits, (m) => new Date(m.startsAt)).map(
      (m) => m.id
    )
  }, [data?.campaign?.moments?.data])

  return useMemo(
    () => ({
      publishedMomentIds,
      loading,
    }),
    [loading, publishedMomentIds]
  )
}
