import React, { useEffect, useMemo } from 'react'
import { useParams } from 'react-router'

import { Collapse } from 'components/collapse'
import { Content, HStack, Spacing } from 'components/core/layout'
import { Spinner } from 'components/core/spinner'
import { Text } from 'components/core/text'
import { ReactionFeedFilters } from 'core/feed/components/filters/reaction-filters'
import { SectionToggleTitle } from 'core/feed/components/filters/section-toggle'
import { ValorantFeedFilters } from 'core/feed/components/filters/valorant-filters'
import { FeedProvider } from 'core/feed/context'
import { useFeedHelpers } from 'core/feed/helpers'
import { MultiFeed } from 'core/feed/multi-feed'
import { useQueryParamFilters } from 'utils/browser'
import { useGetVideoDetailQuery } from '__generated__'

import { useCurrentChannel } from '../hooks'
import { includeKey, useIncludeFilters } from '../matches/hooks'
import { ChannelNavigation } from '../navigation'
import { Sidebar } from '../sidebar'
import { ChannelContent, MainContent } from '../styled'
import { doubleMinKillOptions } from 'core/feed/components/filters/options'
import { VideoHeading } from './video-heading'
import { MomentFeedFilters } from 'core/feed/components/filters/moments-filters'

export const ChannelVideoDetailPage = () => {
  const { params, setParam } = useQueryParamFilters()
  const { hasValorantAccount } = useCurrentChannel()
  const { videoId } = useParams<{ videoId: string }>()
  const { data: videoData } = useGetVideoDetailQuery({ variables: { videoId } })
  const { valorant, toggleValorant, chat, toggleChat } = useIncludeFilters()

  const valorantFilterSettings = useMemo(
    () => ({
      minKills: { include: true, default: '3', options: doubleMinKillOptions },
    }),
    []
  )

  useEffect(() => {
    const currentParams = params[includeKey] as string[] | undefined
    if (!currentParams) {
      setParam(includeKey, ['valorant', 'chat'])
    }
  }, [params, setParam])

  return (
    <Content background={'secondary'}>
      <HStack gap={16}>
        <FeedProvider>
          <Sidebar>
            <MomentFeedFilters />
            {hasValorantAccount ? (
              <>
                <Spacing vertical={8}>
                  <SectionToggleTitle
                    title="Valorant"
                    checked={valorant}
                    onClick={toggleValorant}
                  />
                </Spacing>
                <Collapse open={valorant}>
                  <ValorantFeedFilters settings={valorantFilterSettings} />
                </Collapse>
                <Spacing top={24} bottom={8}>
                  <SectionToggleTitle
                    title="Chat"
                    checked={chat}
                    onClick={toggleChat}
                  />
                </Spacing>
                <Collapse open={chat}>
                  <ReactionFeedFilters />
                </Collapse>
              </>
            ) : (
              <ReactionFeedFilters />
            )}
          </Sidebar>
          <MainContent>
            <ChannelContent>
              <ChannelNavigation />
              <Spacing top={16}>
                {videoData && videoData.video && videoData.video.stream && (
                  <VideoHeading
                    title={videoData.video.title}
                    startedAt={videoData.video.stream.startedAt}
                    endedAt={videoData.video.stream.endedAt}
                    thumbnailUrl={videoData.video.thumbnailUrl || undefined}
                  />
                )}
              </Spacing>
              <Spacing top={32}>
                {videoData?.video?.stream && (
                  <VideoFeed
                    startedAt={videoData.video.stream.startedAt}
                    endedAt={videoData.video.stream.endedAt}
                  />
                )}
              </Spacing>
            </ChannelContent>
          </MainContent>
        </FeedProvider>
      </HStack>
    </Content>
  )
}

interface Props {
  startedAt: string
  endedAt: string
}

const VideoFeed = ({ startedAt, endedAt }: Props) => {
  const { data: channelData } = useCurrentChannel()
  const { setChannelIds } = useFeedHelpers()

  const additionalFilters = useMemo(() => {
    if (startedAt && endedAt) {
      return {
        startsAt: {
          from: startedAt,
          to: endedAt,
        },
      }
    }
    return undefined
  }, [endedAt, startedAt])

  useEffect(() => {
    if (!channelData?.channel?.id) {
      return
    }
    setChannelIds([channelData.channel.id])
  }, [channelData?.channel?.id, setChannelIds])

  return (
    <>
      <Spacing bottom={8}>
        <HStack alignItems="center" gap={8}>
          <Text variant="text-1" weight="bold">
            Feed
          </Text>
        </HStack>
      </Spacing>
      {additionalFilters ? (
        <MultiFeed additionalFilters={additionalFilters} hideChannel={true} />
      ) : (
        <Spacing top={32}>
          <HStack justifyContent="center" fullWidth>
            <Spinner size={48} />
          </HStack>
        </Spacing>
      )}
    </>
  )
}
