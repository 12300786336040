import { useEffect, useRef } from 'react'
import { useAppTheme } from 'theme/useDarkMode'

export const useLocalTheme = (type: 'dark' | 'light') => {
  const { setLocalTheme, theme } = useAppTheme()
  const initialTheme = useRef(theme)

  useEffect(() => {
    const theme = initialTheme.current
    setLocalTheme(type)
    return () => {
      theme && setLocalTheme(theme)
    }
  }, [setLocalTheme, type])
}
