import { useIncludeFilters } from 'page/channel/matches/hooks'
import { useEffect, useMemo } from 'react'

import { useQueryParamFilters } from 'utils/browser'
import { FeedPreviewFragment, useGetFeedSignalsLazyQuery } from '__generated__'

import { FeedType, useFeedState } from './context'
import { useFeed } from './controller'
import { bucketizeFeedByDay } from './helpers'

export const useMultiFeed = (additionalFilters?: {
  [key: string]: string | { [key: string]: string }
}) => {
  const filters = useMultiFeedParamsToVariables()
  const { channelIds } = useFeedState()
  const [fetchSignals, { data, loading }] = useGetFeedSignalsLazyQuery()

  // requery based on filters/channels
  useEffect(() => {
    if (channelIds && filters) {
      const queryVariables = {
        variables: {
          channelIds: channelIds,
          ...filters,
          ...additionalFilters,
          first: 14,
        },
      }
      fetchSignals(queryVariables)
    }
  }, [additionalFilters, channelIds, fetchSignals, filters])

  return useMemo(
    () => ({
      data: data?.moments?.data,
      hasNextPage: data?.moments?.pagination.hasNextPage,
      loading,
    }),
    [data?.moments?.data, data?.moments?.pagination.hasNextPage, loading]
  )
}

const useMultiFeedParamsToVariables = () => {
  const { valorant, chat } = useIncludeFilters()
  const { params } = useQueryParamFilters()

  return useMemo(() => {
    const results = {}

    const saved = params['saved']
    if (saved) {
      results['isUsed'] = saved === 'true'
    }

    const minKillsStr = params['minKills']
    if (minKillsStr && typeof minKillsStr === 'string') {
      results['minKills'] = parseInt(minKillsStr, 10)
    } else {
      results['minKills'] = 4
    }

    const roundCeremony = params['roundCeremony']
    if (roundCeremony) {
      results['roundCeremony'] = roundCeremony
    }

    const agents = params['agents']
    if (agents) {
      results['agents'] = agents
    }

    const maps = params['maps']
    if (maps) {
      results['maps'] = maps
    }

    const reactionTypes = params['reactionTypes']
    if (reactionTypes) {
      results['reactionTypes'] = reactionTypes
    }

    const tags = params['tags']
    if (tags) {
      results['tags'] = tags
    }

    const page = params['page']
    if (page && typeof page === 'string') {
      results['after'] = (parseInt(page, 10) * 14 - 1).toString()
    } else {
      results['after'] = undefined
    }

    results['includeValorant'] = valorant
    results['includeChat'] = chat

    return results
  }, [chat, params, valorant])
}

export const useBucketFeed = () => {
  const { type } = useFeedState()
  const { data } = useFeed()

  const filteredMoments = useMemo(() => {
    if (type === FeedType.ValorantSignal) {
      return data?.moments?.data.filter(
        (moment) =>
          moment.__typename === 'ValorantMoment' &&
          moment.video.stream?.endedAt !== null
      ) as FeedPreviewFragment[] | undefined
    } else {
      return data?.moments?.data
    }
  }, [data?.moments?.data, type])

  return useMemo(
    () =>
      filteredMoments
        ? bucketizeFeedByDay<FeedPreviewFragment>(filteredMoments)
        : [],
    [filteredMoments]
  )
}
