import styled, { css } from 'styled-components/macro'
import { transparentize } from 'polished'

export const SignalPlayerContainerDiv = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: 100%;
  grid-template-areas:
    'video-player'
    'timeline';
  width: 100%;
  height: 100%;
  background-color: #23262f;
  color: white;
`

export const VideoPlayerAreaDiv = styled.div`
  position: relative;
  grid-area: video-player;
`

export const TimelineAreaDiv = styled.div`
  position: relative;
  grid-area: timeline;
`

export const PlayerPerspectiveOptionsDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
`

export const StreamerThumbImg = styled.img<{ $selected: boolean }>`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 6px;

  border: 1px solid
    ${({ $selected }) => ($selected ? '#ffd166' : 'transparent')};
`

export const SignalPlayerHeadingDiv = styled.div<{
  $selected: boolean
  $availablePerspective: boolean
}>`
  position: relative;
  width: 140px;
  padding: 0 6px 0 32px;
  margin: 2px 0;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-right: 1px solid #353945;
  cursor: pointer;

  ${({ $selected }) =>
    $selected
      ? null
      : css`
          &:hover {
            ${StreamerThumbImg} {
              border-color: ${transparentize(0.5, '#ffd166')};
            }
          }
        `}
`

export const MainPerspectiveHeadingSpacerDiv = styled.div`
  margin: 8px 0;
  width: 100%;
  height: 1px;
  background-color: #353945;
`

export const StreamContainerDiv = styled.div`
  position: relative;
  height: 32px;
`

export const MomentContainerDiv = styled.div`
  position: absolute;
  height: 32px;
  padding: 4px;
  background-color: ${({ theme }) => theme.colors.gold};
`

export const TimelineContentsDiv = styled.div`
  position: relative;
  margin: 2px 0;
  height: 32px;
  display: flex;
  vertical-align: top;
  border-bottom: 1px solid #353945;
`

export const MainPerspectiveSpacerDiv = styled.div`
  margin: 8px 0;
  height: 1px;
  width: 100%;
  background-color: #353945;
`

export const TimelineMomentSelectionDiv = styled.div`
  position: absolute;
  height: 32px;
  background: #353945;
  border-radius: 4px 4px 0px 0px;
  text-transform: uppercase;
  padding-left: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  & > span {
    opacity: 0.75;
  }
`

export const TimelineRoundContainerDiv = styled.div`
  position: relative;
  height: 32px;
  flex-grow: 0;
  flex-shrink: 0;
`

export const SignalPlayerTimelineContentDiv = styled.div`
  padding: 21px 0;
`
