import { Route, Switch, useRouteMatch } from 'react-router'
import { AdminChannelCollectionsPage } from './channel-collections-page'
import { AdminCollectionDetailPage } from './detail-page'

export const ChannelCollectionPages = () => {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <Route exact path={`${path}`}>
        <AdminChannelCollectionsPage />
      </Route>
      <Route path={`${path}/:collectionSlug`}>
        <AdminCollectionDetailPage />
      </Route>
    </Switch>
  )
}
