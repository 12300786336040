import styled from 'styled-components/macro'

export const GridItemContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.neutrals.n8};
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.colors.neutrals.n6};
  box-shadow: ${({ theme }) => theme.elevation.depth2};

  cursor: pointer;
  transition: box-shadow 0.2s ease-in-out;

  &:hover {
    box-shadow: ${({ theme }) => theme.elevation.depth3};
  }
`

export const GridItemImageContainer = styled.div`
  position: relative;
  height: 0;
  padding-bottom: 56.25%;

  background-color: ${({ theme }) => theme.colors.neutrals.n6};
  border-radius: 16px;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 1));
    opacity: 0.25;
  }
`

export const GridItemThumbnailImg = styled.img`
  position: absolute;
  max-width: 100%;
  top: 0;
  left: 0;
`

export const GridItemInfo = styled.div`
  padding: 8px 16px;
`

export const GridItemChannel = styled.div`
  position: absolute;
  display: flex;
  right: 16px;
  bottom: 8px;
  justify-content: center;
  align-items: center;

  gap: 4px;

  z-index: 1;
`

export const GridItemChannelImg = styled.img`
  width: 32px;
  height: 32px;

  border-radius: 100%;
`

export const SyncedIndicatorDiv = styled.div`
  width: 12px;
  height: 12px;

  & > svg {
    width: 100%;
    height: 100%;
    fill: ${({ theme }) => theme.colors.rose};
  }
`

export const UnreadIndicatorDiv = styled.div`
  width: 5px;
  height: 5px;
  background-color: ${({ theme }) => theme.colors.blue};
  border-radius: 50%;
`

export const ConnectedMomentInfoDiv = styled.div`
  position: absolute;
  right: 8px;
  bottom: 8px;
`

export const IconBackgroundDiv = styled.div`
  /* background-color: ${({ theme }) => theme.colors.blue}; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;

  border-radius: 100%;
`

export const ChannelContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 45px;
`

export const RatingContainerDiv = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.neutrals.n1};
  border-radius: 4px;
  padding: 4px 8px;
  background-color: ${({ theme }) => theme.colors.neutrals.n6};

  span {
    color: ${({ theme }) => theme.colors.neutrals.n1};
  }

  svg {
    fill: ${({ theme }) => theme.colors.neutrals.n1};
  }
`

export const TitleContainerDiv = styled.div`
  position: relative;
  max-width: 180px;
  margin-right: 12px;
  overflow: hidden;

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
