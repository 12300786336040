import styled from 'styled-components/macro'
import { Switch } from 'components/core/switch'
import { Text } from 'components/core/text'
import React from 'react'

interface Props {
  title: string
  checked: boolean
  onClick: () => void
}

export const SectionToggleTitle = ({ title, checked, onClick }: Props) => {
  return (
    <ContainerDiv
      $selected={checked}
      onClick={(e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault()
        onClick()
      }}
    >
      <Text variant="h6" font-weight="bold">
        {title}
      </Text>
      <Switch checked={checked} onChange={() => {}} />
    </ContainerDiv>
  )
}

const ContainerDiv = styled.div<{ $selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${({ theme }) => theme.colors.neutrals.n6};
  border-width: 1px 0;
  padding: 16px 8px;

  cursor: pointer;
`
