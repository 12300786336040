import styled from 'styled-components/macro'
import { CropContents } from '../template/crop-contents'
import { PreviewContentsDiv } from '../template/styled'
import { useEffect, useMemo } from 'react'
import { useDimensions } from 'utils/browser'
import { SceneEditingModes, useComposerState } from '../context'
import { useComposerHelper } from '../hooks'
import { CropContainer } from '../template/crop-container'
import { containDimensions, findSameHeightFitAR } from 'utils/aspect-ratio'
import { KillFeedWithComposerContext } from 'core/video-addons/kill-feed'
import { RootOnly } from 'core/auth/components'
import { PlayerDisplayWithComposerContext } from 'core/video-addons/player-display'
import { KDAWithComposerContext } from 'core/video-addons/kda'
import { MatchIntroWithComposerContext } from 'core/video-addons/match-intro'
import { SubtitlesWithComposerContext } from 'core/video-addons/subtitles'

export const TemplatePreviewContent = () => {
  const {
    selectedTemplate,
    selectedFormat,
    previewContainerRect,
    layersLocked,
    killfeed,
    playerDisplayAddon,
    KDAAddon,
    matchIntroAddon,
    subtitlesAddon,
  } = useComposerState()
  const { setPreviewRect } = useComposerHelper()
  const [ref, previewRect] = useDimensions()
  const styles = useTemplatePreviewStyles()

  useEffect(() => {
    setPreviewRect(previewRect)
  }, [previewRect, setPreviewRect])

  return previewContainerRect && selectedTemplate && selectedFormat ? (
    <PreviewContentsDiv style={styles} ref={ref} $active={!layersLocked}>
      {previewRect &&
        selectedTemplate?.crops.map((crop) => {
          return (
            <CropContainer
              key={crop.title}
              containerRect={previewRect}
              templateCrop={crop}
            >
              <CropContents templateCrop={crop} />
            </CropContainer>
          )
        })}
      <RootOnly>{killfeed && <KillFeedWithComposerContext />}</RootOnly>
      <RootOnly>
        {playerDisplayAddon && <PlayerDisplayWithComposerContext />}
      </RootOnly>
      <RootOnly>{KDAAddon && <KDAWithComposerContext />}</RootOnly>
      <RootOnly>
        {matchIntroAddon && <MatchIntroWithComposerContext />}
      </RootOnly>
      <RootOnly>{subtitlesAddon && <SubtitlesWithComposerContext />}</RootOnly>
    </PreviewContentsDiv>
  ) : null
}

const GAP = 16

// if sceneEditingMode is content, we need to fit both content and preview
const useTemplatePreviewStyles = () => {
  const { selectedFormat, previewContainerRect, sceneEditingMode } =
    useComposerState()

  return useMemo(() => {
    if (selectedFormat?.aspectRatio && previewContainerRect) {
      if (sceneEditingMode === SceneEditingModes.Content) {
        const heightMatchingAR = findSameHeightFitAR(
          selectedFormat.aspectRatio,
          {
            width: 16,
            height: 9,
          }
        )
        // get dimensions with height fitting
        const containerDimensions = containDimensions(
          {
            width: previewContainerRect.width - GAP,
            height: previewContainerRect.height,
          },
          heightMatchingAR
        )
        const heightOffset =
          (previewContainerRect.height - containerDimensions.height) / 2
        const widthOffset =
          (previewContainerRect.width - GAP - containerDimensions.width) / 2
        // instead of using all the space in the preview
        // we make that fit into the height fitting container
        const { width, height } = containDimensions(
          containerDimensions,
          selectedFormat?.aspectRatio
        )

        return {
          left: widthOffset,
          top: heightOffset,
          width,
          height,
        }
      } else {
        const { width, height } = containDimensions(
          previewContainerRect,
          selectedFormat?.aspectRatio
        )
        // center it
        const left = (previewContainerRect.width - width) / 2
        const top = (previewContainerRect.height - height) / 2

        return {
          width,
          height,
          left,
          top,
        }
      }
    }
  }, [previewContainerRect, sceneEditingMode, selectedFormat?.aspectRatio])
}

export const OverlayPaddingDiv = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 35px;
  position: relative;
`
