import { NavLink } from 'react-router-dom'
import { useTheme } from 'styled-components/macro'
import { useAuth0 } from '@auth0/auth0-react'

import { useGetCurrentOrganizationQuery } from '__generated__'
import { Logo } from 'components/core/logo'
import { NavigationBar } from 'components/navigation-bar'

import { Login } from './login'
import { ProfileMenu } from './profile-menu/profile-menu'
import { RootOnly } from 'core/auth/components'

const Navigation = () => {
  const theme = useTheme()
  const { isAuthenticated, isLoading: isAuthLoading } = useAuth0()
  const { data } = useGetCurrentOrganizationQuery()

  return (
    <NavigationBar>
      <NavLink
        to={isAuthenticated ? `/o/${data?.viewer?.organization?.slug}` : '/'}
      >
        <Logo color={theme.colors.text.primary} />
      </NavLink>

      {data && (
        <NavigationBar.Menu>
          <RootOnly>
            <NavigationBar.MenuItem
              exact
              to={`/o/${data?.viewer?.organization?.slug}/feed`}
            >
              Feed
            </NavigationBar.MenuItem>
            <NavigationBar.MenuItem
              exact
              to={`/o/${data?.viewer?.organization?.slug}/content`}
            >
              Content
            </NavigationBar.MenuItem>
          </RootOnly>
          <NavigationBar.MenuItem
            exact
            to={`/o/${data?.viewer?.organization?.slug}`}
          >
            Campaigns
          </NavigationBar.MenuItem>
          <NavigationBar.MenuItem
            to={`/o/${data?.viewer?.organization?.slug}/collections`}
          >
            Collections
          </NavigationBar.MenuItem>
        </NavigationBar.Menu>
      )}

      <NavigationBar.Right>
        {!isAuthLoading && (!isAuthenticated ? <Login /> : <ProfileMenu />)}
      </NavigationBar.Right>
    </NavigationBar>
  )
}

export default Navigation
