import ASTRA from 'assets/valorant/agent-thumbs/ASTRA.png'
import BREACH from 'assets/valorant/agent-thumbs/BREACH.png'
import BRIMSTONE from 'assets/valorant/agent-thumbs/BRIMSTONE.png'
import CYPHER from 'assets/valorant/agent-thumbs/CYPHER.png'
import DEADLOCK from 'assets/valorant/agent-thumbs/DEADLOCK.png'
import JETT from 'assets/valorant/agent-thumbs/JETT.png'
import KAYO from 'assets/valorant/agent-thumbs/KAYO.png'
import KILLJOY from 'assets/valorant/agent-thumbs/KILLJOY.png'
import OMEN from 'assets/valorant/agent-thumbs/OMEN.png'
import PHOENIX from 'assets/valorant/agent-thumbs/PHOENIX.png'
import RAZE from 'assets/valorant/agent-thumbs/RAZE.png'
import REYNA from 'assets/valorant/agent-thumbs/REYNA.png'
import SAGE from 'assets/valorant/agent-thumbs/SAGE.png'
import SOVA from 'assets/valorant/agent-thumbs/SOVA.png'
import SKYE from 'assets/valorant/agent-thumbs/SKYE.png'
import VIPER from 'assets/valorant/agent-thumbs/VIPER.png'
import YORU from 'assets/valorant/agent-thumbs/YORU.png'
import CHAMBER from 'assets/valorant/agent-thumbs/CHAMBER.png'
import NEON from 'assets/valorant/agent-thumbs/NEON.png'
import FADE from 'assets/valorant/agent-thumbs/FADE.png'
import HARBOR from 'assets/valorant/agent-thumbs/HARBOR.png'
import GEKKO from 'assets/valorant/agent-thumbs/GEKKO.png'

export const AGENT_THUMB_IMAGES: { [key: string]: string } = {
  ASTRA: ASTRA,
  BREACH: BREACH,
  BRIMSTONE: BRIMSTONE,
  CYPHER: CYPHER,
  DEADLOCK: DEADLOCK,
  JETT: JETT,
  KAYO: KAYO,
  KILLJOY: KILLJOY,
  OMEN: OMEN,
  PHOENIX: PHOENIX,
  RAZE: RAZE,
  REYNA: REYNA,
  SAGE: SAGE,
  SOVA: SOVA,
  SKYE: SKYE,
  VIPER: VIPER,
  YORU: YORU,
  CHAMBER: CHAMBER,
  NEON: NEON,
  FADE: FADE,
  HARBOR: HARBOR,
  GEKKO: GEKKO,
  UNKNOWN: JETT,
}
