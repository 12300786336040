import { Container } from 'components/core/layout'
import styled from 'styled-components/macro'

export const PreviewModalRoot = styled.div`
  position: fixed;
  inset: 0px;
  z-index: 1000;
`

export const Backdrop = styled.div<{ $opacity?: number }>`
  background-color: rgba(
    0,
    0,
    0,
    ${({ $opacity }) => ($opacity ? $opacity : 0.25)}
  );
  position: fixed;
  inset: 0px;
  z-index: -1;
`

export const PreviewModalContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  height: 100%;

  z-index: 1;
`

export const PreviewModalContent = styled.div`
  position: relative;
  max-width: 848px;
  width: 100%;

  background-color: ${({ theme }) => theme.colors.background.primary};
  border-radius: 16px;
  box-shadow: ${({ theme }) => theme.elevation.depth3};

  /* overflow: hidden; */
`

export const VideoContainer = styled(Container)`
  position: relative;
  padding-bottom: 56.25%;
  border-radius: 16px;
  overflow: hidden;

  & > .video-js {
    position: absolute;
  }
`

export const ProfileImg = styled.img`
  width: 32px;
  height: 32px;
  border: 2px solid ${({ theme }) => theme.colors.neutrals.n1};
  border-radius: 16px;
`

export const CardContent = styled.div`
  padding: 16px;
`

export const UserInfoRow = styled.div<{ $bottomPadding?: number }>`
  display: flex;
  justify-content: space-between;
  padding-bottom: ${({ $bottomPadding }) =>
    $bottomPadding !== undefined ? $bottomPadding : 16}px;
`

export const MatchDataRow = styled.div`
  display: flex;
  padding-top: 16px;
`

export const HighlightDataContainer = styled.div`
  flex: 1;
  flex-grow: 1;
`
export const ScoreboardContainer = styled.div`
  flex: 1;
  flex-grow: 1;
`

export const CloseButton = styled.button`
  position: absolute;
  right: 16px;
  top: 16px;
  width: 32px;
  height: 32px;

  border: 0;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.static.neutrals.n2};
  z-index: 1;

  transition: background-color 0.15s ease-in-out, opacity 0.15s ease-in-out;

  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.static.neutrals.n3};
  }

  &:active {
    opacity: 0.75;
  }
`

export const TemplateSettingsContainerDiv = styled.div`
  position: absolute;
  top: 4px;
  left: 4px;
  z-index: 3;
`
