import { ReactNode } from 'react'

import { MenuWrapper } from './styled/menu-wrapper'

type Props = {
  children?: ReactNode
}
const Menu = ({ children }: Props) => {
  return <MenuWrapper>{children}</MenuWrapper>
}

export default Menu
