import styled from 'styled-components/macro'
import { formatUnit, Unit } from './unit'

type Props = {
  right?: Unit
  top?: Unit
  left?: Unit
  bottom?: Unit
  vertical?: Unit
  horizontal?: Unit
  all?: Unit
  inline?: boolean
}

export const Spacing = styled.div<Props>`
  ${({ right }) => right && `margin-right: ${formatUnit(right)};`}
  ${({ top }) => top && `margin-top: ${formatUnit(top)};`}
  ${({ left }) => left && `margin-left: ${formatUnit(left)};`}
  ${({ bottom }) => bottom && `margin-bottom: ${formatUnit(bottom)};`}
  ${({ vertical }) =>
    vertical &&
    `
  margin-top: ${formatUnit(vertical)};
  margin-bottom: ${formatUnit(vertical)};
  `};
  ${({ horizontal }) =>
    horizontal &&
    `
  margin-right: ${formatUnit(horizontal)};
  margin-left: ${formatUnit(horizontal)};
  `};
  ${({ all }) => all && `margin: ${formatUnit(all)};`}
  ${({ inline }) => inline && `display: inline-block;`}
`
