import styled from 'styled-components/macro'

export const ChatMomentItemDiv = styled.div`
  position: relative;
  width: 100%;
  border: 1px solid #666;
  padding: 8px;
  margin-bottom: 8px;
  cursor: pointer;
  color: #333;

  &:hover {
    color: black;
    border: 1px solid black;
  }
`

export const ChatMomentsContainerDiv = styled.div`
  padding: 12px;
`

export const ChatMomentContentDiv = styled.div`
  margin-top: 20px;
`

export const CreateMultiMomentContainerDiv = styled.div`
  margin-top: 12px;
  padding: 12px;
  border: 1px solid #ccc;

  & > div {
    margin-top: 4px;
  }

  & > input {
    margin-top: 4px;
  }

  & > button {
    margin-top: 4px;
  }
`
