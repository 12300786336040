import React, { useCallback, useEffect, useState } from 'react'

import { Spinner } from 'components/core/spinner'

import { GridContainer, GridSeparator } from './styled'
import { Container, Spacing } from 'components/core/layout'
import { Rating, Sort } from '__generated__'
import { FeedType, useFeedState } from 'core/feed/context'
import { useBucketFeed } from 'core/feed/hooks'
import { useFeedHelpers } from 'core/feed/helpers'
import { useEscKey } from 'hooks/use-esc-key'
import { MomentGridItem } from 'page/content/grid'
import { MomentModal } from 'page/content/components/moment-modal'

interface Props {
  channelIds: string[]
  startsAt: Date
  endsAt: Date
  sort: Sort
  rating?: Rating
}

export const ContentView = ({ channelIds }: Props) => {
  const [selectedMomentId, setSelectedMomentId] = useState<string | undefined>()
  const { setFeedType: setType, setChannelIds } = useFeedHelpers()
  const { loading } = useFeedState()
  const buckets = useBucketFeed()

  useEscKey(
    useCallback(() => {
      setSelectedMomentId(undefined)
    }, [])
  )

  useEffect(() => {
    setType(FeedType.Moment)
  }, [setType])

  useEffect(() => {
    setChannelIds(channelIds)
  }, [channelIds, setChannelIds])

  // useEffect(() => {
  //   setFilters({
  //     sort,
  //     startsAt: { from: startsAt, to: endsAt },
  //     rating,
  //   })
  // }, [endsAt, setFilters, startsAt, sort, rating])

  return (
    <Spacing bottom={32}>
      {loading && <Spinner />}
      {buckets.map((bucket) => (
        <React.Fragment key={bucket.label}>
          <GridSeparator>{bucket.label}</GridSeparator>
          <GridContainer>
            {bucket.data.map((moment) => (
              <MomentGridItem
                key={moment.id}
                momentId={moment.id}
                selectMomentId={setSelectedMomentId}
              />
            ))}
          </GridContainer>
        </React.Fragment>
      ))}

      <Container direction="row" justifyContent="center">
        {/* <Button
          onClick={loadNextPage}
          disabled={!hasNextPage || loading}
          isLoading={hasNextPage && loading}
        >
          Load more
        </Button> */}
      </Container>

      {selectedMomentId && (
        <MomentModal
          momentId={selectedMomentId}
          onClose={() => setSelectedMomentId(undefined)}
        />
      )}
    </Spacing>
  )
}
