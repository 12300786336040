import styled from 'styled-components/macro'

export const TimeContainerDiv = styled.div<{ $containerHeight: number }>`
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 20px;
  color: #333;

  & > div {
    width: 0px;
    font-size: 12px;

    &:before {
      content: '';
      position: absolute;
      // magic numbers lol...
      height: ${(p) => p.$containerHeight - 164}px;
      width: 1px;
      background-color: rgba(0, 0, 0, 0.1);
      bottom: 16px;
    }
  }
`

export const TimeTextDiv = styled.div`
  width: 70px;
  transform: translateX(-50%);
  white-space: nowrap;
  text-align: center;
  border-radius: 6px;
`

export const TimeMarkerContainerDiv = styled.div`
  position: absolute;
`
