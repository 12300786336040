import React, { useCallback, useEffect, useMemo } from 'react'
import momentjs from 'moment'
import { useParams } from 'react-router'

import { cloneWithoutTypename, randomUniqueId } from 'utils'
import {
  MultiPerspectiveMomentDetailFragment,
  SceneFragment,
  TemplateCropFragment,
  useGetMomentDetailQuery,
  useGetMomentEditLazyQuery,
} from '__generated__'
import { Editor } from 'core/editor'
import { useTimelineState } from 'core/editor/timeline/context'
import { Timeline } from 'core/editor/timeline'
import { ChatTimeline } from 'core/editor/timeline/moments/chat'
import { AspectRatioContainer } from 'components/aspect-ratio/container'
import { StreamerAvatarWithVideo } from 'components/streamer-avatar'
import { useTimelineHelpers } from 'core/editor/timeline/hooks'
import {
  ExportFormat,
  STANDARD_FORMAT,
  TemplateCropSettings,
} from 'core/exporter/constants'
import { TemplatePreviewContent, TemplateSelect } from 'core/exporter/template'
import { useEditor } from 'core/editor/hooks'
import { TemplateEditor } from 'core/template-editor'
import { DEFAULT_CONTENT_TYPES } from 'core/shared/templates/constants'

import { StreamerAvatarDiv, TimelineLegendDiv } from '../../video/editor/styled'
import { useExporterState } from './context'
import { useExporterHelper } from './hooks'
import { SelectedCrop } from './selected-crop'
import { SceneList } from './scenes/scene-list'
import {
  ExportPreviewContainerDiv,
  GridPlaceholder,
  MomentEditorPage,
  SceneDisplayDiv,
  TemplatePreviewContainerDiv,
  TemplatePreviewDiv,
  TemplateSelectDiv,
  TemplateSelectedContainerDiv,
  TimelineControlsDiv,
} from '../styled'
import { useTemplateEditorState } from 'core/template-editor/context'
import { useTemplateEditorHelpers } from 'core/template-editor/hooks'
import { Button } from 'components/core/button'

interface ExportEditorProps {
  momentId: string
}

export const ExportEditor: React.FC<ExportEditorProps> = ({ momentId }) => {
  const { editId } = useParams<{ editId: string }>()
  const { selectedFormat, ready } = useExportEditorData(momentId)
  // this will watch and continue to load in the edit as it changes
  useEditLoad()

  return ready && selectedFormat ? (
    <MomentEditor momentId={momentId} editId={editId} format={selectedFormat} />
  ) : null
}

const useEditLoad = () => {
  const { editId } = useParams<{ editId?: string }>()
  const [query, { data, called }] = useGetMomentEditLazyQuery()
  const { setFormat, addScene, setTitle, clearScenes } = useExporterHelper()

  useEffect(() => {
    if (editId && !called) {
      query({ variables: { editId } })
    }
  }, [called, editId, query])

  useEffect(() => {
    if (data?.momentEdit) {
      setFormat(data.momentEdit.format)

      // console.log(data.momentEdit.scenes.map((sceneFrag) => sceneFrag.id))
      clearScenes()
      data.momentEdit.scenes.forEach((sceneFragment: SceneFragment) => {
        const sceneData = cloneWithoutTypename(sceneFragment)
        const parsedTemplateCrops: TemplateCropSettings[] =
          sceneData.template.crops.map((tc: TemplateCropFragment, idx) => {
            return {
              ...tc,
              primary: idx === 0,
              localId: randomUniqueId(),
              defaultContent: DEFAULT_CONTENT_TYPES.CUSTOM,
            }
          })

        addScene({
          id: sceneData.id,
          start: momentjs(sceneData.start).valueOf(),
          end: momentjs(sceneData.end).valueOf(),
          template: { ...sceneData.template, crops: parsedTemplateCrops },
          cropData: sceneData.cropData,
          index: sceneData.index,
          overlays: [],
        })
      })

      setTitle(data.momentEdit.title ?? '')
    }
  }, [addScene, clearScenes, data, setFormat, setTitle])
}

const useExportEditorData = (momentId) => {
  const { data } = useGetMomentDetailQuery({ variables: { momentId } })
  const { setMomentId, setFormat, setPrimaryVideoId } = useExporterHelper()
  const { selectedFormat, primaryVideoId } = useExporterState()

  const moment = useMemo(() => {
    return data?.moment as MultiPerspectiveMomentDetailFragment | undefined
  }, [data?.moment])
  const mainVideoId = useMemo(() => moment?.mainVideo.id, [moment])

  useEffect(() => {
    if (primaryVideoId === '' && mainVideoId) {
      setPrimaryVideoId(mainVideoId)
    }
  }, [mainVideoId, primaryVideoId, setPrimaryVideoId])

  useEffect(() => {
    setMomentId(momentId)
  }, [momentId, setMomentId])

  return useMemo(
    () => ({
      ready: primaryVideoId !== '',
      selectedFormat,
      setFormat,
    }),
    [primaryVideoId, selectedFormat, setFormat]
  )
}

interface MomentEditorProps {
  momentId: string
  editId: string
  format: ExportFormat
}

export const MomentEditor: React.FC<MomentEditorProps> = ({
  momentId,
  format,
  editId,
}) => {
  const { open } = useTemplateEditorState()
  const { initialize } = useTemplateEditorHelpers()
  const { selectedTemplate, selectedTemplateCrop } = useExporterState()
  const { setTemplate } = useExporterHelper()
  const { videosData } = useGetMomentEditorData(momentId)

  const duplicateAndCreateTemplate = useCallback(() => {
    const templateCrops =
      selectedTemplate?.crops.map((crop) => {
        return {
          ...crop,
          localId: randomUniqueId(),
          defaultContent: DEFAULT_CONTENT_TYPES.CUSTOM,
        }
      }) ?? []

    initialize({
      currentlyEditing: { momentId, videosData },
      format,
      templateCrops,
    })
  }, [format, initialize, momentId, selectedTemplate?.crops, videosData])

  const aspectRatioContents = useMemo(() => {
    if (format.id === STANDARD_FORMAT.id) {
      return { width: 16 + 1 + 16, height: 9 }
    } else {
      return { width: 28.44 + 1 + 9, height: 16 }
    }
  }, [format])

  return (
    <Editor>
      <MomentEditorPage>
        <ExportPreviewContainerDiv>
          <TemplateSelectDiv>
            <TemplateSelect
              format={format}
              template={selectedTemplate}
              setTemplate={setTemplate}
            />
            <Button size="small" onClick={duplicateAndCreateTemplate}>
              New Template
            </Button>
          </TemplateSelectDiv>
          <AspectRatioContainer {...aspectRatioContents}>
            <TemplatePreviewDiv $formatId={format.id}>
              <TemplateSelectedContainerDiv>
                <AspectRatioContainer width={16} height={9}>
                  {selectedTemplateCrop && (
                    <SelectedCrop selectedTemplateCrop={selectedTemplateCrop} />
                  )}
                </AspectRatioContainer>
              </TemplateSelectedContainerDiv>
              <GridPlaceholder />
              <TemplatePreviewContainerDiv>
                {selectedTemplate && <TemplatePreviewContent />}
              </TemplatePreviewContainerDiv>
            </TemplatePreviewDiv>
          </AspectRatioContainer>
          <TimelineControlsDiv>
            <TimelineLegendDiv>
              {videosData.map(({ videoId }) => (
                <StreamerAvatarDiv key={videoId}>
                  <StreamerAvatarWithVideo
                    videoId={videoId}
                    width={50}
                    height={50}
                  />
                </StreamerAvatarDiv>
              ))}
            </TimelineLegendDiv>
            <Timeline>
              {videosData.map(({ videoId }) => (
                <ChatTimeline key={videoId} videoId={videoId} />
              ))}
            </Timeline>
          </TimelineControlsDiv>
          <SceneDisplayDiv>
            <SceneList editId={editId} />
          </SceneDisplayDiv>
        </ExportPreviewContainerDiv>
      </MomentEditorPage>
      {open ? <TemplateEditor /> : null}
    </Editor>
  )
}

const useGetMomentEditorData = (momentId: string) => {
  const { data } = useGetMomentDetailQuery({ variables: { momentId } })
  const { setOffsets } = useEditor()
  const { primaryVideoId } = useExporterState()
  const { setTimelineLength, setTimelineStartAndEnd } = useTimelineHelpers()
  const { timelineLength } = useTimelineState()

  const moment = useMemo(() => {
    return data?.moment as MultiPerspectiveMomentDetailFragment | undefined
  }, [data?.moment])
  const startTime = momentjs(moment?.startsAt).valueOf()
  const endTime = momentjs(moment?.endsAt).valueOf()

  const videosData = useMemo(() => {
    return (
      moment?.videos.map(({ id, user }) => ({
        videoId: id,
        userId: user?.id ?? '',
        displayName: user?.displayName ?? '',
      })) ?? []
    )
  }, [moment?.videos])

  useEffect(() => {
    const nextOffsets = (moment?.offsets ?? []).reduce((memo, offsetItem) => {
      const fromMap = memo[offsetItem.fromVideo.id]
      let nextFromMap = fromMap ? fromMap : {}
      nextFromMap[offsetItem.toVideo.id] = offsetItem.offsets.audioOffset
      return {
        ...memo,
        [offsetItem.fromVideo.id]: { ...nextFromMap },
      }
    }, {})
    setOffsets(nextOffsets)
  }, [moment?.offsets, setOffsets])

  // initialize size of timeline
  useEffect(() => {
    if (timelineLength !== endTime - startTime) {
      const paddedStart = startTime - 120 * 1000
      const paddedEnd = endTime + 120 * 1000
      setTimelineStartAndEnd(paddedStart, paddedEnd)
      setTimelineLength(paddedEnd - paddedStart)
    }
  }, [
    startTime,
    endTime,
    setTimelineLength,
    timelineLength,
    setTimelineStartAndEnd,
  ])

  return useMemo(
    () => ({
      videosData,
      primaryVideoId,
    }),
    [primaryVideoId, videosData]
  )
}
