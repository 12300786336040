import React, { useCallback, useEffect, useMemo } from 'react'

import { AspectRatioContainer } from 'components/aspect-ratio/container'
import { SAVE_LEVELS } from 'core/shared/templates/constants'
import { getTemplatesFromLocalStorage } from 'core/shared/templates/helpers'
import { useExporterState } from 'page/moment/exporter/context'
import { useDimensions } from 'utils/browser'

import { CropContainer } from './crop-container'
import { useExporterHelper } from 'page/moment/exporter/hooks'
import { ExportFormat, ExportTemplate, SAMPLE_TEMPLATES } from '../constants'
import { PreviewContentsDiv, TemplateEditorDiv } from './styled'

interface TemplateEditorProps {
  format: ExportFormat
}

export const TemplateEditor: React.FC<TemplateEditorProps> = ({ format }) => {
  return (
    <TemplateEditorDiv>
      <AspectRatioContainer {...format.aspectRatio} />
    </TemplateEditorDiv>
  )
}

interface TemplatePreviewProps {
  format: ExportFormat
  template?: ExportTemplate
  setTemplate: (nextTemplate: ExportTemplate | undefined) => void
}

export const TemplateSelect: React.FC<TemplatePreviewProps> = ({
  format,
  template,
  setTemplate,
}) => {
  const { momentId, primaryVideoId } = useExporterState()
  const templateOptions = useMemo(() => {
    const hardcoded = SAMPLE_TEMPLATES.filter(
      (temp) => temp.format.id === format.id
    )
    const momentLevel = getTemplatesFromLocalStorage(
      format.id,
      SAVE_LEVELS.MOMENT,
      momentId
    )
    const videoLevel = getTemplatesFromLocalStorage(
      format.id,
      SAVE_LEVELS.VIDEO,
      primaryVideoId
    )
    const globalLevel = getTemplatesFromLocalStorage(
      format.id,
      SAVE_LEVELS.GLOBAL
    )
    return [...hardcoded, ...momentLevel, ...videoLevel, ...globalLevel]
  }, [format.id, momentId, primaryVideoId])

  const onTemplateChange = useCallback(
    (evt: React.ChangeEvent<HTMLSelectElement>) => {
      const nextTemplate = templateOptions.find(
        ({ title }) => title === evt.currentTarget.value
      )
      setTemplate(nextTemplate)
    },
    [setTemplate, templateOptions]
  )

  useEffect(() => {
    if (template === undefined && templateOptions.length > 0) {
      setTemplate(templateOptions[0])
    }
  }, [setTemplate, template, templateOptions])

  return (
    <select value={template?.title} onChange={onTemplateChange}>
      {templateOptions.map((temp) => (
        <option key={temp.title} value={temp.title}>
          {temp.title}
        </option>
      ))}
    </select>
  )
}

export const TemplatePreviewContent: React.FC = () => {
  const { selectedTemplate, selectedFormat } = useExporterState()
  const { setPreviewRect } = useExporterHelper()
  const [ref, DOMRect] = useDimensions()

  useEffect(() => {
    setPreviewRect(DOMRect)
  }, [DOMRect, setPreviewRect])

  return selectedTemplate && selectedFormat ? (
    <AspectRatioContainer {...selectedFormat.aspectRatio}>
      <PreviewContentsDiv ref={ref}>
        {selectedTemplate.crops.map((crop) => {
          return <CropContainer key={crop.title} templateCrop={crop} />
        })}
      </PreviewContentsDiv>
    </AspectRatioContainer>
  ) : null
}
