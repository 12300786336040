import { Button } from 'components/core/button'
import styled from 'styled-components/macro'

export const TimelineAreaContentsDiv = styled.div`
  display: grid;
  grid-template-areas:
    'timeline-options timeline-options'
    'timeline-heading timeline-detail';
  /* '.                timeline-scroll'; */
  grid-template-columns: 140px 1fr;
  grid-template-rows: 40px 1fr;
  /* grid-template-rows: 40px 1fr 40px; */
  /* grid-template-rows: 1fr 40px; */
`

export const TimelineOptionsAreaDiv = styled.div`
  position: relative;
  grid-area: timeline-options;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const TogglePerspectivesButton = styled(Button).attrs({
  size: 'small',
})`
  position: absolute;
  top: 12px;
  left: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 0;
`

export const TimelineHeadingAreaDiv = styled.div`
  position: relative;
  grid-area: timeline-heading;
  margin-top: 30px;
  padding: 21px 0;
`
export const TimelineDetailAreaDiv = styled.div`
  position: relative;
  grid-area: timeline-detail;
  overflow: hidden;
`

export const TimelineScrollbarAreaDiv = styled.div`
  position: relative;
  grid-area: timeline-scroll;
`
