import { useMemo } from 'react'
import momentjs from 'moment'

import {
  useGetEditingVideoQuery,
  useGetValorantMatchesByVideoIdQuery,
  ValorantMatchType,
} from '__generated__'

import { useTimelineState } from '../context'
import { useTimelineHelpers } from '../hooks'
import { ValorantMatchTimelineDisplay } from './match'
import {
  ValorantTimelineContainerDiv,
  ValorantTimelineStreamDiv,
} from './styled'

interface ValorantGamesTimelineProps {
  videoId: string
}

export const ValorantGamesTimeline: React.FC<ValorantGamesTimelineProps> = ({
  videoId,
}) => {
  const { valorantMatches, accountIds, containerStyles, streamStart } =
    useValorantGamesTimelineData(videoId)

  return accountIds ? (
    <ValorantTimelineContainerDiv>
      <ValorantTimelineStreamDiv style={containerStyles}>
        {valorantMatches?.map((matchData) => {
          return (
            <ValorantMatchTimelineDisplay
              key={matchData.id}
              streamStart={streamStart}
              matchId={matchData.id}
              accountIds={accountIds}
            />
          )
        })}
      </ValorantTimelineStreamDiv>
    </ValorantTimelineContainerDiv>
  ) : null
}

const useValorantGamesTimelineData = (videoId) => {
  const { getContentWidthFromTime } = useTimelineHelpers()
  const { startTime } = useTimelineState()
  const { data: videoData } = useGetEditingVideoQuery({
    variables: { id: videoId },
  })
  const { data } = useGetValorantMatchesByVideoIdQuery({
    variables: { videoId },
    errorPolicy: 'all',
  })

  const streamStart = useMemo(
    () => momentjs(videoData?.video?.stream?.startedAt).valueOf(),
    [videoData?.video?.stream?.startedAt]
  )

  const containerStyles = useMemo(() => {
    if (videoData?.video?.stream?.startedAt === undefined) return {}
    const streamEnd = momentjs(videoData?.video?.stream?.endedAt).valueOf()
    const left = getContentWidthFromTime(streamStart - startTime)
    const width = getContentWidthFromTime(streamEnd - streamStart)

    return { left, width }
  }, [
    videoData?.video?.stream?.startedAt,
    videoData?.video?.stream?.endedAt,
    getContentWidthFromTime,
    streamStart,
    startTime,
  ])

  const competitiveValorantGames = useMemo(
    () =>
      (data?.video?.valorantMatches ?? []).filter(
        ({ type }) => type === ValorantMatchType.Competitive
      ),
    [data?.video?.valorantMatches]
  )

  return useMemo(
    () => ({
      containerStyles,
      streamStart,
      valorantMatches: competitiveValorantGames,
      accountIds: data?.video?.user?.linkedAccounts.map(({ puuid }) => puuid),
    }),
    [
      competitiveValorantGames,
      containerStyles,
      data?.video?.user?.linkedAccounts,
      streamStart,
    ]
  )
}
