import styled from 'styled-components/macro'
import { media } from 'theme/media'

export const MenuWrapper = styled.ul`
  ${media.phablet`
		position: absolute;
		width: 100%;
		z-index: 1;
	`}
`
