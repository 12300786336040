import momentjs from 'moment'
import {
  ScoreboardEntry,
  useScoreboard,
} from 'core/feed/components/scoreboard/hooks'
import styled, { useTheme } from 'styled-components/macro'
import { Text } from 'components/core/text'
import { HStack } from 'components/core/layout'
import { Spinner } from 'components/core/spinner'
import { PerspectiveStatus } from 'core/signal-review/components/scoreboard'
import { ValorantMatchTeam } from '__generated__'
import { useCallback, useMemo } from 'react'
import { sortBy } from 'lodash'
import { AGENT_THUMB_IMAGES } from 'components/valorant/agent'
import { lighten } from 'polished'

interface Props {
  matchId: string
  selectedChannelIds: string[]
  setSelectedChannelIds: (channelIds: string[]) => void
}

export const MatchDisplaySelection = ({
  matchId,
  selectedChannelIds,
  setSelectedChannelIds,
}: Props) => {
  const { teams, map, startedAt, loading, error } = useScoreboard(matchId)
  const theme = useTheme()
  const startTimeFormated = useMemo(
    () => momentjs(startedAt).format('MMM Do'),
    [startedAt]
  )

  const toggleSelectedChannelId = useCallback(
    (
      args:
        | {
            accountId: string
            channelId: string
            gameName: string
            tagLine?: string
          }
        | undefined
    ) => {
      if (args?.channelId === undefined) return
      const channelId = args.channelId
      if (selectedChannelIds.includes(channelId)) {
        setSelectedChannelIds(
          selectedChannelIds.filter((id) => id !== channelId)
        )
      } else {
        setSelectedChannelIds([...selectedChannelIds, channelId])
      }
    },
    [selectedChannelIds, setSelectedChannelIds]
  )

  return (
    <Container>
      {loading && (
        <HStack alignItems="baseline" gap={4}>
          <Text variant="text-3" color={theme.colors.text.secondary}>
            Loading scoreboard
          </Text>
          <Spinner color={theme.colors.text.secondary} size={12} />
        </HStack>
      )}
      {error && (
        <Text variant="text-3" color={theme.colors.text.secondary}>
          Error loading scoreboard
        </Text>
      )}
      {teams && map && (
        <Contents>
          <MapHeader>
            <Text variant="text-2" as="div">
              {startTimeFormated}
            </Text>
            <Text variant="text-3" as="div">
              {map}
            </Text>
          </MapHeader>
          <TeamContainer>
            <ScoreboardTeamSelector
              selectedChannelIds={selectedChannelIds}
              players={teams[ValorantMatchTeam.Blue]}
              onClick={toggleSelectedChannelId}
            />
          </TeamContainer>
          <VsContainer>
            <Text variant="text-2">vs</Text>
          </VsContainer>
          <TeamContainer>
            <ScoreboardTeamSelector
              selectedChannelIds={selectedChannelIds}
              players={teams[ValorantMatchTeam.Red]}
              onClick={toggleSelectedChannelId}
            />
          </TeamContainer>
        </Contents>
      )}
    </Container>
  )
}

type ScoreboardTeamProps = {
  selectedChannelIds: string[]
  players: ScoreboardEntry[]
  reverse?: boolean
  onClick?: (
    connectProps:
      | {
          accountId: string
          channelId: string
          gameName: string
          tagLine?: string
        }
      | undefined
  ) => void
}

export const ScoreboardTeamSelector = ({
  selectedChannelIds,
  players,
  reverse,
  onClick,
}: ScoreboardTeamProps) => {
  const playerByScore = sortBy(players, (player) => -player.stats.score)
  return (
    <>
      {playerByScore.map((player) => (
        <PlayerEntry
          $selectable={player.channelId !== undefined && player.hasPerspective}
          $selected={
            player.channelId !== undefined &&
            player.hasPerspective &&
            selectedChannelIds.includes(player.channelId)
          }
          key={player.name}
          reverse={reverse}
          onClick={() => {
            onClick &&
              player.channelId !== undefined &&
              player.hasPerspective &&
              onClick({
                accountId: player.accountId,
                gameName: player.name,
                tagLine: player.tagLine ?? '',
                channelId: player.channelId ?? '',
              })
          }}
        >
          <HStack alignItems="center">
            <AgentImage src={AGENT_THUMB_IMAGES[player.agent as string]} />
            <NameText>{player.name}</NameText>
            <PerspectiveStatus
              hasAccount={player.hasAccount}
              hasPerspective={player.hasPerspective}
              syncStatus={player.syncStatus}
            />
          </HStack>
          <Text variant="small" mono={true}>
            {player.stats.kills} - {player.stats.deaths}
          </Text>
        </PlayerEntry>
      ))}
    </>
  )
}

export const Container = styled.div`
  width: 272px;
`
export const Contents = styled.div`
  display: flex;
  flex-direction: column;
`

export const MapHeader = styled.div`
  padding: 12px;
`

export const TeamContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`

export const VsContainer = styled.div`
  padding: 8px 0 8px 12px;
`

export const PlayerEntry = styled(HStack).attrs({
  alignItems: 'center',
  justifyContent: 'space-between',
})<{ $selected: boolean; $selectable: boolean }>`
  gap: 24px;
  padding: 2px 6px;
  color: ${({ theme, $selectable }) =>
    $selectable ? theme.colors.neutrals.n2 : theme.colors.neutrals.n5};
  background-color: ${({ $selected, theme }) =>
    $selected ? theme.colors.neutrals.n7 : 'transparent'};
  border: 1px solid
    ${({ $selected, theme }) =>
      $selected ? theme.colors.neutrals.n6 : 'transparent'};
  border-radius: 4px;

  &:hover {
    background-color: ${({ $selectable, $selected, theme }) =>
      $selectable && $selected
        ? lighten(0.1)(theme.colors.neutrals.n6)
        : $selectable
        ? theme.colors.neutrals.n6
        : 'transparent'};
    cursor: ${({ $selectable }) => ($selectable ? 'pointer' : 'default')};
  }
`

export const AgentImage = styled.img<{ $size?: number }>`
  width: ${({ $size }) => ($size ? `${$size}px` : '16px')};
  height: ${({ $size }) => ($size ? `${$size}px` : '16px')};
  background-color: ${({ theme }) => theme.colors.static.neutrals.n2};
  border-radius: 4px;
`

export const NameText = styled(Text).attrs({ variant: 'text-3' })`
  margin: 0 4px;
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
