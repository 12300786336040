import React, { useEffect } from 'react'
import styled from 'styled-components/macro'

import { Container } from 'components/core/layout'
import { Logo } from 'components/core/logo'
import { useHistory } from 'react-router'
import { useAuth0 } from '@auth0/auth0-react'

export const MainPage: React.FC = () => {
  const { isAuthenticated } = useAuth0()
  const history = useHistory()
  useEffect(() => {
    if (isAuthenticated) {
      history.replace('/auth/login')
    }
  }, [isAuthenticated, history])
  return (
    <ContainerDiv alignItems="center" flex="1">
      <Logo />
    </ContainerDiv>
  )
}

const ContainerDiv = styled(Container)`
  width: 100%;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.static.neutrals.n1};
  justify-content: center;

  & > svg {
    fill: white;
  }
`
