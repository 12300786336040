import { useMemo } from 'react'

export const usePlayerLayout = (
  availableDimensions: {
    width: number
    height: number
  },
  numPerspectives: number
) => {
  const secondaryPlayerWidth = numPerspectives > 1 ? 340 : 0

  const secondaryContainerStyles = useMemo(() => {
    return {
      width: secondaryPlayerWidth,
      maxHeight: availableDimensions.height ?? 0,
    }
  }, [availableDimensions.height, secondaryPlayerWidth])

  const secondaryItemStyles = useMemo(() => {
    return {
      width: secondaryPlayerWidth - 32,
      height: ((secondaryPlayerWidth - 32) * 9) / 16,
    }
  }, [secondaryPlayerWidth])

  const mainPlayerStyles = useMemo(() => {
    return {
      width: availableDimensions.width - secondaryPlayerWidth,
      height: availableDimensions.height,
    }
  }, [
    availableDimensions.height,
    availableDimensions.width,
    secondaryPlayerWidth,
  ])

  return useMemo(
    () => ({
      secondaryContainerStyles,
      secondaryItemStyles,
      mainPlayerStyles,
    }),
    [mainPlayerStyles, secondaryContainerStyles, secondaryItemStyles]
  )
}
