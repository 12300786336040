import React, { createContext, useContext, useReducer } from 'react'

interface AvailableVideo {
  accountId: string
  videoId: string
  displayName: string
}

interface ValorantMatchState {
  matchId?: string
  timelineContainerRect?: DOMRect
  availableVideos?: AvailableVideo[]
}

const INITIAL_STATE: ValorantMatchState = {
  matchId: undefined,
  timelineContainerRect: undefined,
  availableVideos: undefined,
}

enum ACTION_TYPES {
  SET_MATCH_ID = 'SET_MATCH_ID',
  SET_TIMELINE_CONTAINER_RECT = 'SET_TIMELINE_CONTAINER_RECT',
  SET_AVAILABLE_VIDEOS = 'SET_AVAILABLE_VIDEOS',
}

interface SetMatchIdAction {
  type: ACTION_TYPES.SET_MATCH_ID
  payload: {
    matchId?: string
  }
}

interface SetMomentIdAction {
  type: ACTION_TYPES.SET_TIMELINE_CONTAINER_RECT
  payload: {
    timelineContainerRect?: DOMRect
  }
}

interface SetAvailableVideosAction {
  type: ACTION_TYPES.SET_AVAILABLE_VIDEOS
  payload: {
    availableVideos?: AvailableVideo[]
  }
}

type ValorantMatchActions =
  | SetMomentIdAction
  | SetMatchIdAction
  | SetAvailableVideosAction

type Dispatch = (action: ValorantMatchActions) => void

const reducer = (
  state: ValorantMatchState,
  action: ValorantMatchActions
): ValorantMatchState => {
  switch (action.type) {
    case ACTION_TYPES.SET_TIMELINE_CONTAINER_RECT: {
      return {
        ...state,
        timelineContainerRect: action.payload.timelineContainerRect,
      }
    }
    case ACTION_TYPES.SET_MATCH_ID: {
      return {
        ...state,
        matchId: action.payload.matchId,
      }
    }

    case ACTION_TYPES.SET_AVAILABLE_VIDEOS: {
      return {
        ...state,
        availableVideos: action.payload.availableVideos,
      }
    }

    default:
      return state
  }
}

const ValorantMatchContext = createContext<ValorantMatchState | undefined>(
  undefined
)
const ValorantMatchUpdaterContext = createContext<Dispatch | undefined>(
  undefined
)

export const ValorantMatchProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE)

  return (
    <ValorantMatchContext.Provider value={state}>
      <ValorantMatchUpdaterContext.Provider value={dispatch}>
        {children}
      </ValorantMatchUpdaterContext.Provider>
    </ValorantMatchContext.Provider>
  )
}

export const useValorantMatchState = () => {
  const context = useContext(ValorantMatchContext)
  if (context === undefined) {
    throw new Error(
      'useValorantMatchState must be used within a ValorantMatchProvider'
    )
  }
  return context
}

export const useValorantMatchUpdater = () => {
  const context = useContext(ValorantMatchUpdaterContext)
  if (context === undefined) {
    throw new Error(
      'useValorantMatchUpdater must be used within a ValorantMatchProvider'
    )
  }
  return context
}

useValorantMatchUpdater.ACTION_TYPES = ACTION_TYPES
