import React, { useMemo } from 'react'
import momentjs from 'moment'

import { PreviewMomentFragment, useGetVideoMomentsQuery } from '__generated__'

import { MomentViewer } from 'core/moment-viewer'
import { MomentViewerProvider } from 'core/moment-viewer/context'
import { sortBy } from 'lodash'
import { Content } from 'components/core/layout'
import { VideoMomentViewerDiv } from './styled'
import { DragDropContext } from 'react-beautiful-dnd'

interface VideoMomentsProps {
  videoId: string
}

export const VideoMoments: React.FC<VideoMomentsProps> = ({ videoId }) => {
  const { data, loading } = useGetVideoMomentsQuery({ variables: { videoId } })

  const publishedMoments = useMemo(() => {
    const multiPerspectiveMoments = data?.video?.moments.data.filter(
      (moment) => moment.__typename === 'MultiPerspectiveMoment'
    ) as PreviewMomentFragment[] | undefined
    const momentsWithExports =
      multiPerspectiveMoments?.filter(({ published, edits }) => {
        const hasExports = edits.some(({ exports }) => exports.length > 0)
        return published && hasExports
      }) ?? []
    return sortBy(momentsWithExports, (m) => momentjs(m.startsAt).valueOf())
  }, [data])

  return (
    <Content background="secondary">
      <VideoMomentViewerDiv>
        <MomentViewerProvider>
          <DragDropContext onDragEnd={() => {}}>
            <MomentViewer
              loading={loading}
              momentIds={publishedMoments.map(({ id }) => id)}
            />
          </DragDropContext>
        </MomentViewerProvider>
      </VideoMomentViewerDiv>
    </Content>
  )
}
