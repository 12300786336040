import { Button } from 'components/core/button'
import { HStack, Spacing } from 'components/core/layout'
import { Table } from 'components/core/table'
import { Text } from 'components/core/text'
import { ConnectRiotAccount } from 'core/feed/components/connect-account/riot'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Column } from 'react-table'
import {
  useGetChannelCollectionQuery,
  useGetValorantLeaderboardLazyQuery,
  useUpdateChannelCollectionChannelsPreviewMutation,
  ValorantLeaderboardPlayerFragment,
  ValorantRegion,
} from '__generated__'
import { PageItemDiv } from '../styled'

const RANKED_GODS_ID = '27cd268f-f318-42ee-96a9-a9454a3d3221'
const NA_RANKED_ID = 'c8fa0141-1dc4-4c82-83e2-970471b1b6b9'

export const LeaderboardPage = () => {
  const [selectedRiotAccount, setSelectedRiotAccount] = useState<
    | {
        accountId: string
        gameName: string
        tagLine?: string
      }
    | undefined
  >()
  const [page, setPage] = useState(1)
  const [fetchLeaderboard, { data, loading }] =
    useGetValorantLeaderboardLazyQuery()
  const { data: rankedGods } = useGetChannelCollectionQuery({
    variables: { id: RANKED_GODS_ID },
  })
  const { data: naRanked } = useGetChannelCollectionQuery({
    variables: { id: NA_RANKED_ID },
  })

  const [updateCollection] = useUpdateChannelCollectionChannelsPreviewMutation()

  const rankedGodsMap = useMemo(() => {
    return (
      rankedGods?.channelCollection?.channels.data.reduce((acc, channel) => {
        return {
          ...acc,
          [channel.id]: true,
        }
      }, {}) ?? {}
    )
  }, [rankedGods?.channelCollection?.channels.data])

  const naRankedMap = useMemo(() => {
    return (
      naRanked?.channelCollection?.channels.data.reduce((acc, channel) => {
        return {
          ...acc,
          [channel.id]: true,
        }
      }, {}) ?? {}
    )
  }, [naRanked?.channelCollection?.channels.data])

  const toggleRankedGods = useCallback(
    (channelId) => {
      let nextIds: string[] =
        rankedGods?.channelCollection?.channels.data.map(({ id }) => id) ?? []
      if (rankedGodsMap[channelId]) {
        nextIds = nextIds.filter((id) => id !== channelId)
      } else {
        nextIds = [...nextIds, channelId]
      }
      updateCollection({
        variables: { collectionId: RANKED_GODS_ID, channelIds: nextIds },
      })
    },
    [
      rankedGodsMap,
      rankedGods?.channelCollection?.channels.data,
      updateCollection,
    ]
  )

  const toggleNaRanked = useCallback(
    (channelId) => {
      let nextIds: string[] =
        naRanked?.channelCollection?.channels.data.map(({ id }) => id) ?? []
      if (naRankedMap[channelId]) {
        nextIds = nextIds.filter((id) => id !== channelId)
      } else {
        nextIds = [...nextIds, channelId]
      }
      updateCollection({
        variables: { collectionId: NA_RANKED_ID, channelIds: nextIds },
      })
    },
    [naRanked?.channelCollection?.channels.data, naRankedMap, updateCollection]
  )

  useEffect(() => {
    fetchLeaderboard({
      variables: {
        region: ValorantRegion.Na,
        first: 200,
        after: page === 1 ? undefined : `${(page - 1) * 200}`,
      },
    })
  }, [fetchLeaderboard, page])

  const columns: Column<ValorantLeaderboardPlayerFragment>[] = useMemo(
    () => [
      {
        accessor: 'leaderboardRank',
        Header: 'Rank',
      },
      {
        accessor: 'rankedRating',
        Header: 'Rating',
        align: 'left',
      },
      {
        Header: 'name',
        width: 300,
        Cell: ({ row }) => {
          return (
            <div>
              {row.original.gameName}#{row.original.tagLine}
            </div>
          )
        },
      },
      {
        accessor: 'numberOfWins',
        Header: 'Wins',
      },
      {
        Header: 'Account',
        key: 'id',
        Cell: ({ row }) => {
          if (row.original.account && row.original.account.channel) {
            return (
              <HStack gap={8}>
                <img
                  src={row.original.account.channel.profileImageUrl}
                  alt="thumbnail"
                  width={24}
                  height={24}
                />
                <Text variant="text-3">
                  {row.original.account.channel.displayName}
                </Text>
              </HStack>
            )
          } else {
            const onClick = () => {
              setSelectedRiotAccount({
                accountId: row.original.account.id,
                gameName: row.original.gameName,
                tagLine: row.original.tagLine,
              })
            }
            return (
              <div>
                <Button size="small" variant="secondary" onClick={onClick}>
                  Add
                </Button>
              </div>
            )
          }
        },
      },
      {
        Header: 'RG',
        Cell: ({ row }) => {
          if (
            row.original.account &&
            row.original.account.channel &&
            row.original.account.channel.id
          ) {
            const inCollection = rankedGodsMap[row.original.account.channel.id]
            const onClick = () => {
              toggleRankedGods(row.original.account.channel.id)
            }
            return (
              <div>
                <Button size="small" variant="secondary" onClick={onClick}>
                  {inCollection ? 'Remove' : 'Add'}
                </Button>
              </div>
            )
          } else {
            return <div></div>
          }
        },
      },
      {
        Header: 'NA',
        Cell: ({ row }) => {
          if (
            row.original.account &&
            row.original.account.channel &&
            row.original.account.channel.id
          ) {
            const inCollection = naRankedMap[row.original.account.channel.id]
            const onClick = () => {
              toggleNaRanked(row.original.account.channel.id)
            }
            return (
              <div>
                <Button size="small" variant="secondary" onClick={onClick}>
                  {inCollection ? 'Remove' : 'Add'}
                </Button>
              </div>
            )
          } else {
            return <div></div>
          }
        },
      },
    ],
    [naRankedMap, rankedGodsMap, toggleNaRanked, toggleRankedGods]
  )

  return (
    <>
      <div>
        {!loading && data?.valorantLeaderboard?.data && (
          <Table
            data={data?.valorantLeaderboard?.data ?? []}
            columns={columns}
          />
        )}
      </div>

      <div>
        <Spacing top={16}>
          <HStack gap={8} justifyContent="flex-end" alignItems="center">
            <Text variant="text-2">Pages:</Text>
            {Array.from({ length: 3 }).map((_, idx) => (
              <PageItemDiv
                $selected={idx + 1 === page}
                key={idx}
                onClick={() => setPage(idx + 1)}
              >
                <Text variant="text-2">{idx + 1}</Text>
              </PageItemDiv>
            ))}
          </HStack>
        </Spacing>
      </div>
      {selectedRiotAccount && (
        <ConnectRiotAccount
          {...selectedRiotAccount}
          close={() => setSelectedRiotAccount(undefined)}
        ></ConnectRiotAccount>
      )}
    </>
  )
}
