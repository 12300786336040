import { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import momentjs from 'moment'
import { sortBy } from 'lodash'

import { useEditorState } from 'core/editor/context'
import {
  ApiScene,
  DEFAULT_TEMPLATE_BY_FORMAT_ID,
  ExportFormat,
  ExportTemplate,
  IG_SQUARE_FORMAT,
  Scene,
  STANDARD_FORMAT,
  TemplateApi,
  TIKTOK_FORMAT,
} from 'core/exporter/constants'
import {
  useAddEditToMomentMutation,
  useAddSceneMutation,
  useGetMomentEditQuery,
  useRemoveSceneMutation,
  useUpdateSceneMutation,
} from '__generated__'

import { useExporterState } from '../context'

export const useCreateEdit = (momentId: string) => {
  const history = useHistory()
  const [createEditMutation] = useAddEditToMomentMutation()

  const createEdit = useCallback(
    async (formatId: string, videoId: string, start: string, end: string) => {
      const defaultInitialScene = getInitialSceneForFormatId(
        formatId,
        videoId,
        start,
        end
      )
      const initialScene = defaultInitialScene ? [defaultInitialScene] : []
      const results = await createEditMutation({
        variables: {
          momentId,
          editInput: {
            title: 'Untitled',
            formatId: formatId,
            published: true,
            scenes: initialScene,
            approved: false,
          },
        },
      })

      const editId = results.data?.addMomentEditToMoment?.id
      if (editId) {
        history.push({
          pathname: `/m/${momentId}/editor/${editId}`,
          state: { referrer: 'exports' },
        })
      }
    },
    [createEditMutation, history, momentId]
  )

  return createEdit
}

export const useEditScenes = (editId: string) => {
  const { selectedTemplate, cropSettings, scenes } = useExporterState()
  const { selection, offsets } = useEditorState()
  const { primaryVideoId } = useExporterState()
  const { refetch } = useGetMomentEditQuery({ variables: { editId } })
  const [removeSceneMutation] = useRemoveSceneMutation()
  const [addSceneMutation] = useAddSceneMutation()
  const [updateSceneMutation] = useUpdateSceneMutation()

  const currentScene = useMemo(() => {
    if (!selection || !selectedTemplate || !primaryVideoId) return
    const primaryCrop = selectedTemplate.crops.find((crop) => {
      return crop.primary
    })

    const cropData = Object.values(cropSettings).map((cs) => ({
      title: cs.title,
      videoId: cs.videoId ?? '',
      cropRect: cs.rect,
      muted: cs.title !== primaryCrop?.title,
      offset:
        primaryVideoId === cs.videoId
          ? 0
          : offsets[primaryVideoId]?.[cs.videoId ?? ''] ?? 0,
    }))

    const template = {
      title: selectedTemplate.title,
      crops: sortBy(selectedTemplate.crops, (c) => (c.primary ? 0 : 1)).map(
        (crop) => ({
          title: crop.title,
          aspectRatio: {
            width: crop.aspectRatio.width,
            height: crop.aspectRatio.height,
          },
          position: crop.position,
        })
      ),
    }

    const nextIndex =
      scenes.length > 0 ? scenes[scenes.length - 1].index + 1 : 0
    const scene: ApiScene = {
      start: momentjs(selection.startTime).toISOString(),
      end: momentjs(selection.endTime).toISOString(),
      index: nextIndex,
      cropData,
      template,
      overlays: [],
    }

    return scene
  }, [
    cropSettings,
    offsets,
    primaryVideoId,
    scenes,
    selectedTemplate,
    selection,
  ])

  const addScene = useCallback(async () => {
    if (currentScene) {
      await addSceneMutation({
        variables: {
          editId: editId,
          sceneInput: currentScene,
        },
      })
      refetch()
      // addSceneHelper(currentScene)
    }
  }, [addSceneMutation, currentScene, editId, refetch])

  const updateScene = useCallback(
    async (sceneId: string) => {
      if (!currentScene || scenes.length === 0) return
      const oldScene = scenes.find(({ id }) => id === sceneId) as Scene

      await updateSceneMutation({
        variables: {
          sceneId,
          sceneInput: {
            ...currentScene,
            index: oldScene.index,
          },
        },
      })
      refetch()
    },
    [scenes, currentScene, refetch, updateSceneMutation]
  )

  const removeScene = useCallback(
    async (sceneIdToRemove: string) => {
      await removeSceneMutation({ variables: { sceneId: sceneIdToRemove } })
      refetch()
    },
    [refetch, removeSceneMutation]
  )

  return useMemo(
    () => ({
      addScene,
      updateScene,
      removeScene,
    }),
    [addScene, removeScene, updateScene]
  )
}

const getInitialSceneForFormatId = (
  formatId: string,
  videoId: string,
  start: string,
  end: string
) => {
  const cropData = getCropDataByFormatId(formatId, videoId)
  if (!cropData) return
  const template = templateforApi(DEFAULT_TEMPLATE_BY_FORMAT_ID[formatId])
  const scene = {
    start,
    end,
    index: 0,
    cropData,
    template,
  }
  return scene
}

function templateforApi(
  template: ExportTemplate & { format: ExportFormat }
): TemplateApi {
  return {
    title: template.title,
    crops: template.crops.map(({ title, position, aspectRatio }) => ({
      title,
      position,
      aspectRatio,
    })),
  }
}

export const getCropDataByFormatId = (formatId: string, videoId: string) => {
  if (formatId === STANDARD_FORMAT.id) {
    return [
      {
        title: 'Gameplay',
        videoId,
        cropRect: {
          width: 1,
          height: 1,
          x: 0,
          y: 0,
        },
        muted: false,
        offset: 0,
      },
    ]
  } else if (formatId === TIKTOK_FORMAT.id) {
    return [
      {
        title: 'Gameplay',
        videoId,
        cropRect: {
          width: 0.316406,
          height: 1,
          x: 0.341797,
          y: 0,
        },
        muted: false,
        offset: 0,
      },
    ]
  } else if (formatId === IG_SQUARE_FORMAT.id) {
    return [
      {
        title: 'Gameplay',
        videoId,
        cropRect: {
          width: 0.5625,
          height: 1,
          x: 0.21875,
          y: 0,
        },
        muted: false,
        offset: 0,
      },
    ]
  }
}
