import { useCallback, useEffect, useState } from 'react'

import { Container, Content, HStack, Spacing } from 'components/core/layout'
import { Text } from 'components/core/text'
import { useEscKey } from 'hooks/use-esc-key'
import { FeedType } from 'core/feed/context'
import { useFeedHelpers } from 'core/feed/helpers'
import { FilterHeader, GridView, Sidebar } from 'page/feed/styles'
import { ChannelCollectionFilter } from 'page/feed/components/collection-select'

import { ContentFilters } from './components/content-filter'
import { ContentGrid } from './grid'
import { MomentModal } from './components/moment-modal'

export const ContentPage = () => {
  const { setFeedType } = useFeedHelpers()
  const [selectedMomentId, setSelectedMomentId] = useState<string | undefined>()

  const closePreviewModal = useCallback(() => {
    setSelectedMomentId(undefined)
  }, [])

  useEscKey(
    useCallback(() => {
      closePreviewModal()
    }, [closePreviewModal])
  )

  // intialize type and filters
  useEffect(() => {
    setFeedType(FeedType.Moment)
  }, [setFeedType])

  return (
    <>
      <Content background="secondary">
        <Container direction="row" gap={16}>
          <Sidebar>
            <Spacing left="16px" bottom="16px">
              <FilterHeader>Filter</FilterHeader>
            </Spacing>
            <ChannelCollectionFilter />
            <ContentFilters />
          </Sidebar>
          <GridView>
            <HStack alignItems="center" gap={8}>
              <Text variant="h4">Content</Text>
            </HStack>
            <ContentGrid selectMomentId={setSelectedMomentId} />
            {selectedMomentId && (
              <MomentModal
                momentId={selectedMomentId}
                onClose={closePreviewModal}
              />
            )}
          </GridView>
        </Container>
      </Content>
    </>
  )
}
