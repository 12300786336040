import { useEffect, useMemo } from 'react'

import { useGetChannelCollectionsQuery } from '__generated__'
import { FilterGroup } from 'core/feed/components/filter-group'
import { useFeedHelpers, useFilterForm } from 'core/feed/helpers'

import { COLLECTIONS } from '../constants'
import { AllChannelIds } from '../constants'

// This is a filter form that relies on the feed context
export const ChannelCollectionFilter = () => {
  const { setChannelIds } = useFeedHelpers()
  const { data } = useGetChannelCollectionsQuery({
    variables: { collectionIds: COLLECTIONS, first: 100 },
  })

  const channelsByCollection = useMemo(() => {
    return data?.channelCollections?.data.reduce((acc, item) => {
      return { ...acc, [item.id]: item.channels.data.map(({ id }) => id) }
    }, {})
  }, [data?.channelCollections?.data])

  const collectionsOptions = useMemo(() => {
    return (
      data?.channelCollections?.data.map(({ id, title }) => ({
        label: title,
        value: id,
      })) ?? []
    )
  }, [data?.channelCollections?.data])

  const {
    data: channelCollectionsData,
    bind: channelCollectionsBind,
    selected: channelCollectionSelected,
  } = useFilterForm(collectionsOptions, false)

  const channelIds = useMemo(() => {
    const selectedCollectionId = channelCollectionSelected[0]
    return selectedCollectionId &&
      channelsByCollection &&
      channelsByCollection[selectedCollectionId]
      ? channelsByCollection[channelCollectionSelected[0]]
      : AllChannelIds
  }, [channelCollectionSelected, channelsByCollection])

  // IMPORTANT! update the context when set
  useEffect(() => {
    setChannelIds(channelIds)
  }, [channelIds, setChannelIds])

  return data ? (
    <FilterGroup
      title="Collections"
      data={channelCollectionsData}
      {...channelCollectionsBind}
    />
  ) : null
}
