import { Content, HStack } from 'components/core/layout'
import { Text } from 'components/core/text'
import { Route, Switch } from 'react-router'
import { RankedGodsGenerator } from './generate'
import { LeaderboardMoves } from './leaderboards/leaderboard-moves'
import { MatchDiscovery } from './matches/discovery'
import { SoloMatchHighlightWithData } from './matches/solo'
import { RisingGodsWithParams } from './rising-god/rising-gods'

export const RankedGodsRouter = () => {
  return (
    <Switch>
      <Route
        path="/ranked-gods/leaderboards"
        exact
        component={LeaderboardMoves}
      />
      <Route path="/ranked-gods" exact component={RankedGodsPage} />
      <Route
        path="/ranked-gods/solo/:matchId/:accountId"
        exact
        component={SoloMatchHighlightWithData}
      />
      <Route path="/ranked-gods/rising" exact>
        <RisingGodsWithParams />
      </Route>
      <Route path="/ranked-gods/match/solo" exact component={MatchDiscovery} />
      <Route
        path="/ranked-gods/generator"
        exact
        component={RankedGodsGenerator}
      />
    </Switch>
  )
}

export const RankedGodsPage = () => {
  return (
    <Content>
      <HStack justifyContent={'center'}>
        <Text variant="h2">RANKED GODS</Text>
      </HStack>
    </Content>
  )
}
