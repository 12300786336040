import React from 'react'
import { Text } from 'components/core/text'
import { useComposerState } from 'core/composer/context'
import { AddonsHeadingDiv } from 'core/composer/styled'
import { KDAAddonSettings } from './kda'
import { KillfeedAddonSettings } from './killfeed'
import { MatchIntroAddonSettings } from './match-intro'
import { PlayerDisplayAddonSettings } from './player-display'
import { SubtitlesAddonSettings } from './subtitles'
import { Spacing } from 'components/core/layout'

export const SidebarAddons = () => {
  const { selectedSceneId } = useComposerState()
  return (
    <React.Fragment key={selectedSceneId}>
      <AddonsHeadingDiv>
        <Text variant="text-2" weight="semibold">
          Video
        </Text>
      </AddonsHeadingDiv>
      <SubtitlesAddonSettings />

      <Spacing top={16} />
      <AddonsHeadingDiv>
        <Text variant="text-2" weight="semibold">
          Valorant
        </Text>
      </AddonsHeadingDiv>
      <KillfeedAddonSettings />
      <PlayerDisplayAddonSettings />
      <KDAAddonSettings />
      <MatchIntroAddonSettings />
    </React.Fragment>
  )
}
