import momentjs from 'moment'

export const USERS = {
  Shroud: '37402112',
  Bnans: '110176631',
  Mizkif: '94753024',
  Emiru: '91067577',
  JakenBake: '11249217',
  LuluLuvely: '94875296',
  DoubleLift: '40017619',
  Lirik: '23161357',
  Keeoh: '151819490',
  PVPX: '80781002',
  Repullze: '118202507',
  Kruzadar: '90222378',
  Noko: '37175851',
  AlexiaRaye: '68313917',
  Jalon: '74363168',
  Slayeas: '584562589',
  Tommey: '25736477',
  Jericho: '10397006',
  Att: '646523547',
  DisguisedToast: '87204022',
  Tarik: '36340781',
  sgares: '29122976',
  Zombs: '47180661',
  Hiko: '26991127',
  QuarterJade: '173758090',
  Kyedae: '445529741',
}

export const EVENT_DATA_WEEK_1 = {
  title: 'AT&T WEEK 1 - APEX',
  channelCollectionId: 'b1772b50-2fc2-4606-b2bc-5fe3bccdd509',
  start: '2022-04-01T13:00:00-07:00',
  end: '2022-04-01T16:30:00-07:00',
  matches: [
    {
      start: '2022-04-01T13:00:00-07:00',
      end: '2022-04-01T14:45:00-07:00',
      teams: [
        {
          users: [USERS.PVPX, USERS.DoubleLift],
        },
      ],
    },
    {
      start: '2022-04-01T13:00:00-07:00',
      end: '2022-04-01T14:45:00-07:00',
      teams: [
        {
          users: [USERS.JakenBake, USERS.AlexiaRaye],
        },
      ],
    },
    {
      start: '2022-04-01T13:00:00-07:00',
      end: '2022-04-01T14:45:00-07:00',
      teams: [
        {
          users: [USERS.Mizkif, USERS.Emiru],
        },
      ],
    },
    {
      start: '2022-04-01T13:00:00-07:00',
      end: '2022-04-01T14:45:00-07:00',
      teams: [
        {
          users: [USERS.Jalon, USERS.Lirik],
        },
      ],
    },

    {
      start: '2022-04-01T13:00:00-07:00',
      end: '2022-04-01T14:45:00-07:00',
      teams: [
        {
          users: [USERS.Repullze, USERS.Kruzadar],
        },
      ],
    },

    {
      start: '2022-04-01T13:00:00-07:00',
      end: '2022-04-01T14:45:00-07:00',
      teams: [
        {
          users: [USERS.LuluLuvely, USERS.Tommey],
        },
      ],
    },
    {
      start: '2022-04-01T13:00:00-07:00',
      end: '2022-04-01T14:45:00-07:00',
      teams: [
        {
          users: [USERS.Slayeas, USERS.Jericho],
        },
      ],
    },
    {
      start: '2022-04-01T13:00:00-07:00',
      end: '2022-04-01T14:45:00-07:00',
      teams: [
        {
          users: [USERS.Shroud, USERS.Noko],
        },
      ],
    },
    {
      start: '2022-04-01T13:00:00-07:00',
      end: '2022-04-01T14:45:00-07:00',
      teams: [
        {
          users: [USERS.Bnans, USERS.Keeoh],
        },
      ],
    },

    {
      start: '2022-04-01T14:45:00-07:00',
      end: '2022-04-01T16:30:00-07:00',
      teams: [
        {
          users: [
            USERS.Shroud,
            USERS.Bnans,
            USERS.Mizkif,
            USERS.Emiru,
            USERS.LuluLuvely,
            USERS.JakenBake,
            USERS.DoubleLift,
            USERS.Keeoh,
            USERS.PVPX,
            USERS.Repullze,
            USERS.Kruzadar,
            USERS.Noko,
            USERS.AlexiaRaye,
            USERS.Jalon,
            USERS.Slayeas,
            USERS.Tommey,
            USERS.Jericho,
            USERS.Lirik,
            USERS.Att,
          ],
        },
      ],
    },
  ],
}

export const EVENT_DATA_WEEK_2 = {
  title: 'AT&T WEEK 2 - Dead By Daylight',
  channelCollectionId: 'b1772b50-2fc2-4606-b2bc-5fe3bccdd509',
  start: '2022-04-08T13:00:00-07:00',
  end: '2022-04-08T16:30:00-07:00',
  matches: [
    {
      start: '2022-04-08T13:00:00-07:00',
      end: '2022-04-08T116:30:00-07:00',
      teams: [
        {
          users: [
            USERS.Shroud,
            USERS.Bnans,
            USERS.Mizkif,
            USERS.Emiru,
            USERS.LuluLuvely,
            USERS.JakenBake,
            USERS.DoubleLift,
            USERS.Keeoh,
            USERS.PVPX,
            USERS.Repullze,
            USERS.Kruzadar,
            USERS.Noko,
            USERS.AlexiaRaye,
            USERS.Jalon,
            USERS.Slayeas,
            USERS.Tommey,
            USERS.Jericho,
            USERS.Lirik,
            USERS.Att,
          ],
        },
      ],
    },
  ],
}

export const EVENT_DATA_WEEK_5 = {
  title: 'AT&T WEEK 5 - CS:GO',
  channelCollectionId: 'b1772b50-2fc2-4606-b2bc-5fe3bccdd509',
  start: '2022-04-08T29:00:00-07:00',
  end: '2022-04-08T29:30:00-07:00',
  matches: [
    {
      start: '2022-04-29T13:00:00-07:00',
      end: '2022-04-29T116:30:00-07:00',
      teams: [
        {
          users: [
            USERS.Shroud,
            USERS.Bnans,
            USERS.Mizkif,
            USERS.Emiru,
            USERS.LuluLuvely,
            USERS.JakenBake,
            USERS.DoubleLift,
            USERS.Keeoh,
            USERS.PVPX,
            USERS.Repullze,
            USERS.Kruzadar,
            USERS.Noko,
            USERS.AlexiaRaye,
            USERS.Jalon,
            USERS.Slayeas,
            USERS.Tommey,
            USERS.Jericho,
            USERS.Att,
          ],
        },
      ],
    },
  ],
}

export const EVENT_DATA_CREATOR = {
  title: 'Tarik Champions',
  channelCollectionId: '9b61d125-55aa-4178-9048-0047c4e0eaa5',
  start: momentjs(1662646154227.4434).toISOString(),
  end: momentjs(1662661789773.4768).toISOString(),
  matches: [
    {
      start: momentjs(1662646154227.4434).toISOString(),
      end: momentjs(1662661789773.4768).toISOString(),
      teams: [
        {
          users: ['36340781', '37402112', '47180661', '128002336', '142728478'],
        },
      ],
    },
  ],
}

/*
26991127 - hiko
124304147 - averagejonas
128002336 - s0m
36340781 - tarik
37402112 - shroud
45302947 - iitztimmy
47180661 - zombs
142728478 - babybay
*/

export const MASTERS = {
  title: 'Masters',
  channelCollectionId: '9b61d125-55aa-4178-9048-0047c4e0eaa5',
  start: '2022-07-17T15:20:54.999Z',
  end: '2022-07-17T20:31:01.443Z',
  matches: [
    {
      start: '2022-07-17T15:20:54.999Z',
      end: '2022-07-17T20:31:01.443Z',
      teams: [
        {
          users: ['490592527', '26991127', '36340781', '124304147'],
        },
      ],
    },
  ],
}

const sapnaplive = '638077636'
// const richwcampbell = '127463427'
// const neeko = '169188075'
// const karljacobs = '124442278'
const karljacobsAlt = '638065882'
const schlatt = '98125665'

export const AMERICAS_CARDROOM = {
  title: `America's Cardroom 2`,
  channelCollectionId: 'e678bf8d-45d3-42f4-a132-41ecd9f51642',
  start: '2022-10-26T23:27:08.152Z',
  end: '2022-10-27T01:36:17.571Z',
  matches: [
    {
      start: '2022-10-26T23:27:08.152Z',
      end: '2022-10-27T01:36:17.571Z',
      teams: [
        {
          users: [sapnaplive, karljacobsAlt, schlatt],
        },
      ],
    },
  ],
}

// Disguised Toast - 87204022
// Tarik - 36340781
// Sean Gares - 29122976
// Zombs - 47180661
// Hiko - 26991127
// QuarterJade - 173758090
// Kyedae - 445529741

const costreamers = [
  {
    users: [
      USERS.DisguisedToast,
      USERS.Tarik,
      USERS.sgares,
      USERS.Zombs,
      USERS.Hiko,
      USERS.QuarterJade,
      USERS.Kyedae,
    ],
  },
]

export const VCT_BRAZIL = {
  title: 'VCT LOCK//IN',
  channelCollectionId: '10164f5c-85a9-4764-8dff-597fbd4099e0',
  start: momentjs(1676840371247).toISOString(),
  end: momentjs(1677973950000).toISOString(),
  matches: [
    {
      title: 'LOUD vs FNATIC - Finals',
      start: momentjs(1677954890000).toISOString(),
      end: momentjs(1677973950000).toISOString(),
      teams: costreamers,
    },
    {
      // semi finals NAVI vs FNATIC
      title: 'NAVI vs FNATIC - Semi Finals',
      start: momentjs(1677862891969).toISOString(),
      end: momentjs(1677874891002).toISOString(),
      teams: costreamers,
    },
    {
      // semi finals LOUD vs DRX
      title: 'LOUD vs DRX - Semi Finals',
      start: momentjs(1677776600000).toISOString(),
      end: momentjs(1677792579553).toISOString(),
      teams: costreamers,
    },
    // quarter finals LEV vs NAVI
    {
      title: 'LEV vs NAVI - Quarter Finals',
      start: momentjs(1677517301707).toISOString(),
      end: momentjs(1677523584647).toISOString(),
      teams: costreamers,
    },
    // quarter finals 100T vs FNATIC
    {
      title: '100T vs FNATIC - Quarter Finals',
      start: momentjs(1677525860000).toISOString(),
      end: momentjs(1677531758480).toISOString(),
      teams: costreamers,
    },
    // quarter finals NRG vs LOUD
    {
      title: 'NRG vs LOUD - Quarter Finals',
      start: '2023-02-19T17:05:00.000Z',
      end: momentjs(1676838569597).toISOString(),
      teams: costreamers,
    },
    // quarter finals DRX vs TALON
    {
      title: 'DRX vs TALON - Quarter Finals',
      start: momentjs(1676840371247).toISOString(),
      end: momentjs(1676850609369).toISOString(),
      teams: costreamers,
    },
  ],
}

export const EVENT_DATA = AMERICAS_CARDROOM
