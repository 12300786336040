import { ChangeEventHandler, useState } from 'react'
import { randomUniqueId } from 'utils'
import { StyledInput, SwitchLabel, SwitchSpan } from './styled'

type Props = {
  checked?: boolean
  onChange?: ChangeEventHandler<HTMLInputElement>
}
export const Switch = ({ checked, onChange }: Props) => {
  const [id] = useState(randomUniqueId())
  return (
    <>
      <StyledInput
        type="checkbox"
        checked={checked}
        onChange={onChange}
        id={id}
      />
      <SwitchLabel htmlFor={id}>
        <SwitchSpan />
      </SwitchLabel>
    </>
  )
}
