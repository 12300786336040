import { useMemo } from 'react'
import { flatten } from 'lodash'

import { HStack, Spacing } from 'components/core/layout'
import { Text } from 'components/core/text'
import { useGetRecentMomentsQuery } from '__generated__'
import { GridContainer } from 'page/feed/styles'

import { ChatSignalGridItem } from 'core/feed/multi-feed'
import { Link, useParams } from 'react-router-dom'
import { IconButton } from 'core/composer/template-sidebar/styled'
import { transparentize } from 'polished'
import { useTheme } from 'styled-components/macro'
import { ReactComponent as RightArrowIcon } from 'icons/svgs/solid/arrow-circle-right.svg'

interface Props {
  channelId?: string
}

export const RecentMoments = ({ channelId }: Props) => {
  const theme = useTheme()
  const { channelName } = useParams<{
    channelName: string
  }>()
  const { signalData } = useGetRecentMomentsData(channelId)

  const currentUrl = useMemo(() => {
    return `/roster/${channelName}`
  }, [channelName])

  return (
    <div>
      <Link to={`${currentUrl}/saved`}>
        <Text variant="h4">Recently Saved Moments</Text>
      </Link>
      <Spacing top={16} />
      {signalData?.length === 0 && (
        <HStack justifyContent="center" fullWidth>
          <Spacing top={16}>
            <Text variant="text-2">No saved moments yet</Text>
          </Spacing>
        </HStack>
      )}
      <GridContainer $paddingBottom={16}>
        {signalData?.slice(0, 4).map((sigData) => {
          if (sigData) {
            return (
              <Link
                key={sigData.signalId}
                to={{
                  pathname: `${currentUrl}/moment/${sigData.signalId}`,
                  state: { referrer: 'feed' },
                }}
              >
                <ChatSignalGridItem
                  key={sigData.signalId}
                  momentId={sigData.signalId}
                  hideChannel={true}
                />
              </Link>
            )
          }
          return null
        })}
      </GridContainer>
      {signalData?.length > 0 && (
        <HStack justifyContent="flex-end">
          <Link to={`${currentUrl}/saved`}>
            <IconButton
              color={transparentize(0.2, theme.colors.static.neutrals.n4)}
              onClick={() => {}}
            >
              <span>View All Moments</span>
              <RightArrowIcon
                fill={theme.colors.static.neutrals.n8}
                width={16}
              />
            </IconButton>
          </Link>
        </HStack>
      )}
    </div>
  )
}

const useGetRecentMomentsData = (channelId: string | undefined) => {
  const { data } = useGetRecentMomentsQuery({
    variables: { channelIds: [channelId ?? ''], first: 8 },
    skip: channelId === undefined,
  })

  const signalData = useMemo(() => {
    return flatten(
      data?.moments?.data
        .filter((item) => {
          return (
            item.__typename === 'MultiPerspectiveMoment' &&
            item.signals[0] &&
            item.signals[0].__typename === 'ChatMoment'
          )
        })
        .map((item) => {
          if (item.__typename === 'MultiPerspectiveMoment') {
            return { signalId: item.signals[0]?.id, momentId: item.id }
          }
          return undefined
        })
    )
  }, [data?.moments?.data])

  return useMemo(
    () => ({
      signalData,
    }),
    [signalData]
  )
}
