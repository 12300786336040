import { SyncedVideo } from 'core/editor/synced-video'
import { Rect, TemplateCrop } from 'core/exporter/constants'
import { CropOverlay } from 'core/exporter/template/crop-overlay'
import { TemplateSettingsContainerDiv } from 'core/exporter/template/styled'
import React, { useCallback, useMemo } from 'react'
import { useDimensions } from 'utils/browser'
import { useGetMomentDetailQuery } from '__generated__'
import { CropSettingType, CropSizeType, useExporterState } from './context'
import { useExporterHelper } from './hooks'

interface SelectedCropProps {
  selectedTemplateCrop: TemplateCrop
}

export const SelectedCrop: React.FC<SelectedCropProps> = ({
  selectedTemplateCrop,
}) => {
  const { primaryVideoId } = useExporterState()
  const {
    onVideoIdChange,
    videoId,
    videoOptions,
    onTypeChange,
    cropTypeOptions,
    cropSettingItem,
    setCropRect,
  } = useSelectedCropData(selectedTemplateCrop)
  const [ref, containerRect] = useDimensions()

  return (
    <div
      style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
      }}
      ref={ref}
    >
      <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
        {videoId && containerRect ? (
          <SyncedVideo
            id="preview"
            controller={false}
            videoId={videoId}
            primaryVideoId={primaryVideoId}
          ></SyncedVideo>
        ) : null}
        <CropOverlay
          containerRect={containerRect}
          editing={cropSettingItem.type === CropSettingType.Custom}
          crop={cropSettingItem.rect}
          setCropRect={setCropRect}
          templateCrop={selectedTemplateCrop}
        />
      </div>
      <TemplateSettingsContainerDiv>
        <select value={videoId} onChange={onVideoIdChange}>
          {videoOptions?.map(({ id, title }) => (
            <option key={id} value={id}>
              {title}
            </option>
          ))}
        </select>
        {videoId ? (
          <select value={cropSettingItem.type} onChange={onTypeChange}>
            {cropTypeOptions.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </select>
        ) : null}
        {/* {videoId ? (
          <select value={cropSettingItem.size} onChange={onSizeChange}>
            {sizeOptions.map((item) => (
              <option value={item}>{item}</option>
            ))}
          </select>
        ) : null} */}
      </TemplateSettingsContainerDiv>
    </div>
  )
}

export const useSelectedCropData = (templateCrop: TemplateCrop) => {
  const { momentId, cropSettings } = useExporterState()
  const { setCropSettings, setCropRectSetting } = useExporterHelper()
  const { data } = useGetMomentDetailQuery({
    variables: { momentId },
    skip: momentId === '',
  })

  const cropSettingItem = useMemo(() => {
    return (
      cropSettings[templateCrop.title] ?? {
        title: templateCrop.title,
        videoId: undefined,
        type: CropSettingType.Full,
      }
    )
  }, [cropSettings, templateCrop.title])

  const videoOptions = useMemo(() => {
    if (
      data === undefined ||
      data.moment?.__typename !== 'MultiPerspectiveMoment'
    )
      return undefined
    return data.moment.videos.map(({ id, user }) => ({
      id,
      title: user?.displayName,
    }))
  }, [data])

  const setVideoId = useCallback(
    (videoId?: string) => {
      setCropSettings({
        ...cropSettingItem,
        videoId,
      })
    },
    [cropSettingItem, setCropSettings]
  )

  const setCropType = useCallback(
    (nextCropType: CropSettingType) => {
      setCropSettings({
        ...cropSettingItem,
        type: nextCropType,
      })
    },
    [cropSettingItem, setCropSettings]
  )

  const setVideoSizeType = useCallback(
    (nextSizeType: CropSizeType) => {
      setCropSettings({
        ...cropSettingItem,
        size: nextSizeType,
      })
    },
    [cropSettingItem, setCropSettings]
  )

  const onSizeChange = useCallback(
    (evt: React.ChangeEvent<HTMLSelectElement>) => {
      return setVideoSizeType(evt.currentTarget.value as CropSizeType)
    },
    [setVideoSizeType]
  )

  const onTypeChange = useCallback(
    (evt: React.ChangeEvent<HTMLSelectElement>) => {
      return setCropType(evt.currentTarget.value as CropSettingType)
    },
    [setCropType]
  )

  const onVideoIdChange = useCallback(
    (evt: React.ChangeEvent<HTMLSelectElement>) => {
      const vidId = evt.currentTarget.value
      setVideoId(vidId === '' ? undefined : vidId)
    },
    [setVideoId]
  )

  // const sizeOptions = [CropSizeType.Contain, CropSizeType.Cover]
  const cropTypeOptions = useMemo(
    () => [
      CropSettingType.Full,
      CropSettingType.Facecam,
      CropSettingType.Custom,
    ],
    []
  )

  const setCropRect = useCallback(
    (nextRect: Rect) => {
      setCropRectSetting(templateCrop.title, nextRect)
    },
    [setCropRectSetting, templateCrop.title]
  )

  return useMemo(
    () => ({
      videoOptions,
      cropSettingItem,
      onSizeChange,
      onTypeChange,
      onVideoIdChange,
      cropTypeOptions,
      setCropRect,
      videoId: cropSettingItem.videoId,
    }),
    [
      videoOptions,
      cropSettingItem,
      onSizeChange,
      onTypeChange,
      onVideoIdChange,
      cropTypeOptions,
      setCropRect,
    ]
  )
}
