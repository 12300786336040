import {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useMemo,
  useState,
} from 'react'

export const usePositionInput = (initialVal: number) => {
  const [value, setValue] = useState(initialVal)

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target
      const val = value === '' ? 0 : parseInt(value, 10)
      setValue(val)
    },
    [setValue]
  )
  const onKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      const { key } = e
      if (!['ArrowUp', 'ArrowDown'].includes(key)) {
        return
      }
      e.preventDefault()

      let inc = 1
      if (e.shiftKey) {
        inc *= 10
      }

      if (key === 'ArrowDown') {
        inc *= -1
      }

      const next = value + inc

      setValue(next)
    },
    [setValue, value]
  )
  return useMemo(
    () => ({
      value,
      bind: {
        onChange,
        onKeyDown,
      },
    }),
    [onChange, onKeyDown, value]
  )
}
