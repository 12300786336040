import { css } from 'styled-components/macro'

export type TextVariants = 'text-1' | 'text-2' | 'text-3' | 'small'
export type HeaderVariants = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
export type FontWeight = 'regular' | 'semibold' | 'bold'

export const monoFontStyle = css`
  font-family: JetBrains Mono, ui-monospace, SFMono-Regular, SF Mono, Menlo,
    Consolas, Liberation Mono, monospace;
`

const fonts = {
  text: {
    'text-1': {
      'font-size': '18px',
      'line-height': '24px',
    },
    'text-2': {
      'font-size': '16px',
      'line-height': '22px',
    },
    'text-3': {
      'font-size': '14px',
      'line-height': '19px',
    },
    small: {
      'font-size': '12px',
      'line-height': '16px',
    },
  },
  headers: {
    h1: {
      'font-size': '48px',
      'line-height': '60px',
    },
    h2: {
      'font-size': '40px',
      'line-height': '52px',
    },
    h3: {
      'font-size': '32px',
      'line-height': '44px',
    },
    h4: {
      'font-size': '24px',
      'line-height': '32px',
    },
    h5: {
      'font-size': '20px',
      'line-height': '28px',
    },
    h6: {
      'font-size': '18px',
      'line-height': '24px',
    },
  },
}

const fontWeights = { regular: 400, semibold: 500, bold: 700 }

const getFontStyle = (
  variant: TextVariants | HeaderVariants,
  weight: FontWeight
) => {
  const isHeader = Object.keys(fonts.headers).includes(variant)
  const w = isHeader ? fontWeights[weight || 'bold'] : fontWeights[weight]
  return css`
    ${isHeader ? fonts.headers[variant] : fonts.text[variant]}
    font-weight: ${w};
  `
}

export { fonts, fontWeights, getFontStyle }
