import React, { useEffect } from 'react'

import { PreviewMomentPlayer } from './player'
import {
  MomentListDiv,
  MomentViewerModalDiv,
  ViewerContentsDiv,
} from './styled'
import { useMomentViewerState } from './context'
import { useMomentViewerHelpers } from './hooks'
import { MomentsPlaylist } from './playlist'
import { Text } from 'components/core/text'
import { HStack } from 'components/core/layout'

interface MomentViewerProps {
  loading: boolean
  momentIds: string[]
  includeUnapproved?: boolean
}

export const MomentViewer: React.FC<MomentViewerProps> = ({
  loading,
  momentIds,
  includeUnapproved = true,
}) => {
  const { selectedMomentId } = useMomentViewerState()
  const { setSelectedMomentId } = useMomentViewerHelpers()

  useEffect(() => {
    if (!momentIds.length) {
      return
    }
    const matches = window.location.hash.match(/^#m=(.+)$/)
    let momentId: string | undefined = undefined

    if (matches && matches.length >= 2) {
      const passedId = matches[1]
      momentId = momentIds.find((id) => id === passedId)
    }

    if (momentId) {
      setSelectedMomentId(momentId)
    } else if (selectedMomentId === '' && momentIds[0]) {
      setSelectedMomentId(momentIds[0])
    }

    const next = window.location.href.substring(
      0,
      window.location.href.indexOf('#')
    )
    window.history.replaceState({}, document.title, next)
  }, [momentIds, selectedMomentId, setSelectedMomentId])

  return (
    <MomentViewerModalDiv>
      <ViewerContentsDiv>
        <PreviewMomentPlayer includeUnapproved={includeUnapproved} />
        {loading ? (
          <MomentListDiv>
            <HStack justifyContent="center">
              <Text variant="text-2">Loading...</Text>
            </HStack>
          </MomentListDiv>
        ) : null}
        {!loading && momentIds.length === 0 ? (
          <MomentListDiv>
            <HStack justifyContent="center">
              <Text variant="text-2">No Moments</Text>
            </HStack>
          </MomentListDiv>
        ) : null}
        {!loading && momentIds.length > 0 ? (
          <MomentsPlaylist
            momentIds={momentIds}
            includeUnapproved={includeUnapproved}
          />
        ) : null}
      </ViewerContentsDiv>
    </MomentViewerModalDiv>
  )
}
