import styled from 'styled-components/macro'
import { formatUnit } from './unit'

type FlexDirection = 'row' | 'row-reverse' | 'column' | 'column-reverse'
type FlexWrap = 'nowrap' | 'wrap' | 'wrap-reverse'
type JustifyContent =
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'space-between'
  | 'space-around'
  | 'space-evenly'
  | 'start'
  | 'end'
  | 'left'
  | 'right'

type AlignItems =
  | 'stretch'
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'baseline'
  | 'first baseline'
  | 'last baseline'
  | 'start'
  | 'end'
  | 'self-start'
  | 'self-end'

type AlignContent =
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'space-between'
  | 'space-around'
  | 'space-evenly'
  | 'stretch'
  | 'start'
  | 'end'
  | 'baseline'
  | 'first baseline'
  | 'last baseline'

type AlignSelf =
  | 'auto'
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'baseline'
  | 'stretch'

type Props = {
  flex?: string
  direction?: FlexDirection
  wrap?: FlexWrap
  flow?: [FlexDirection, FlexWrap]
  justifyContent?: JustifyContent
  alignItems?: AlignItems
  alignContent?: AlignContent
  gap?: string | number
  columnGap?: string | number
  rowGap?: string | number

  order?: number
  grow?: number
  shrink?: number
  basis?: string
  alignSelf?: AlignSelf
}

export const Container = styled.div<Props>`
  display: flex;
  position: relative;
  min-width: 1px;
  max-width: 100%;

  ${({ flex }) => flex && `flex: ${flex};`}
  ${({ direction = 'column' }) => direction && `flex-direction: ${direction};`}
  ${({ wrap }) => wrap && `flex-wrap: ${wrap};`}
  ${({ flow }) => flow && `flex-flow: ${flow.join(' ')};`}
  ${({ justifyContent }) =>
    justifyContent && `justify-content: ${justifyContent};`}
  ${({ alignItems }) => alignItems && `align-items: ${alignItems};`}
  ${({ alignContent }) => alignContent && `align-content: ${alignContent};`}
  ${({ gap }) => gap && `gap: ${formatUnit(gap)};`}
  ${({ columnGap }) => columnGap && `column-gap: ${formatUnit(columnGap)};`}
  ${({ rowGap }) => rowGap && `row-gap: ${formatUnit(rowGap)};`}

  ${({ order }) => order && `order: ${order};`}
  ${({ grow }) => grow && `flex-grow: ${grow};`}
  ${({ shrink }) => shrink && `flex-shrink: ${shrink};`}
  ${({ basis }) => basis && `flex-basis: ${basis};`}
  ${({ alignSelf }) => alignSelf && `align-self: ${alignSelf};`}
`
