import { useTheme } from 'styled-components/macro'
import { ValorantMatchTeam } from '__generated__'

import { AGENT_THUMB_IMAGES } from 'components/valorant/agent'

import { ScoreboardEntry, useScoreboard } from './hooks'
import {
  AgentImage,
  Container,
  NameText,
  TeamContainer,
  TeamEntry,
} from './styled'

import { ReactComponent as PerspectiveIcon } from './perspective.svg'
import { Text } from 'components/core/text'
import { Spinner } from 'components/core/spinner'
import { HStack } from 'components/core/layout'
import { useMemo, useState } from 'react'
import { ConnectRiotAccount } from '../connect-account/riot'
import { useIsRootUser } from 'core/auth/hooks'

type Props = {
  matchId: string
}

export const MatchScoreboard = ({ matchId }: Props) => {
  const [selectedRiotAccount, setSelectedRiotAccount] = useState<
    | {
        accountId: string
        gameName: string
        tagLine?: string
      }
    | undefined
  >()
  const { teams, loading, error } = useScoreboard(matchId)
  const theme = useTheme()
  return (
    <>
      {loading && (
        <HStack alignItems="baseline" gap={4}>
          <Text variant="text-3" color={theme.colors.text.secondary}>
            Loading scoreboard
          </Text>
          <Spinner color={theme.colors.text.secondary} size={12} />
        </HStack>
      )}
      {error && (
        <Text variant="text-3" color={theme.colors.text.secondary}>
          Error loading scoreboard
        </Text>
      )}
      {teams && (
        <Container>
          <TeamContainer>
            <ScoreboardTeam
              players={teams[ValorantMatchTeam.Blue]}
              viewAccountDetails={setSelectedRiotAccount}
            />
          </TeamContainer>
          <TeamContainer>
            <ScoreboardTeam
              players={teams[ValorantMatchTeam.Red]}
              reverse
              viewAccountDetails={setSelectedRiotAccount}
            />
          </TeamContainer>
        </Container>
      )}
      {selectedRiotAccount && (
        <ConnectRiotAccount
          {...selectedRiotAccount}
          close={() => setSelectedRiotAccount(undefined)}
        ></ConnectRiotAccount>
      )}
    </>
  )
}

type ScoreboardTeamProps = {
  players: ScoreboardEntry[]
  reverse?: boolean
  viewAccountDetails?: (
    connectProps:
      | {
          accountId: string
          gameName: string
          tagLine?: string
        }
      | undefined
  ) => void
}
const ScoreboardTeam = ({
  players,
  reverse,
  viewAccountDetails,
}: ScoreboardTeamProps) => {
  const isRootUser = useIsRootUser()
  const theme = useTheme()

  const onClick = useMemo(() => {
    return isRootUser && viewAccountDetails
      ? (accountId: string, gameName: string, tagLine?: string) =>
          viewAccountDetails({ accountId, gameName, tagLine })
      : () => {}
  }, [isRootUser, viewAccountDetails])

  return (
    <>
      {players.map((player) => (
        <TeamEntry
          key={player.name}
          reverse={reverse}
          onClick={() => {
            onClick(player.accountId, player.name, player.tagLine)
          }}
        >
          <AgentImage src={AGENT_THUMB_IMAGES[player.agent as string]} />
          <NameText>{player.name}</NameText>
          {player.hasPerspective && player.hasAccount && (
            <PerspectiveIcon fill={theme.colors.gold} />
          )}
          {player.hasAccount && !player.hasPerspective && (
            <PerspectiveIcon fill={theme.colors.neutrals.n6} />
          )}
        </TeamEntry>
      ))}
    </>
  )
}
