import React, { useMemo } from 'react'

import { CurrentSelectionDisplayDiv } from './styled'
import { useTimelineState } from './context'

export const SelectionDisplay: React.FC = () => {
  const { selectionPct } = useTimelineState()

  const styles = useMemo(() => {
    if (selectionPct === undefined) {
      return {
        display: 'none',
      }
    }

    return {
      width: `${(selectionPct.endPct - selectionPct.startPct) * 100}%`,
      left: `${selectionPct.startPct * 100}%`,
    }
  }, [selectionPct])

  return (
    <CurrentSelectionDisplayDiv style={styles}></CurrentSelectionDisplayDiv>
  )
}
