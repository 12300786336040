import { AspectRatioContainer } from 'components/aspect-ratio/container'
import React, { useMemo } from 'react'
import { useDimensions } from 'utils/browser'
import { useTemplateEditorState } from './context'
import { PreviewCrop } from './preview-crop'
import { PreviewContentsDiv, TemplateEditorPreviewDiv } from './styled'

interface TemplateEditorPreviewProps {}

export const TemplateEditorPreview: React.FC<TemplateEditorPreviewProps> =
  () => {
    const [containerRef, containerRect] = useDimensions()
    const { format, templateCrops } = useTemplateEditorState()

    const aspectRatioContainerStyles = useMemo(
      () => ({
        alignItems: 'flex-start',
      }),
      []
    )

    return (
      <TemplateEditorPreviewDiv>
        <AspectRatioContainer
          containerStyles={aspectRatioContainerStyles}
          width={format.aspectRatio.width}
          height={format.aspectRatio.height}
        >
          <PreviewContentsDiv ref={containerRef}>
            {containerRect &&
              templateCrops.map((tc) => (
                <PreviewCrop
                  key={tc.title}
                  templateCrop={tc}
                  containerRect={containerRect}
                />
              ))}
          </PreviewContentsDiv>
        </AspectRatioContainer>
      </TemplateEditorPreviewDiv>
    )
  }
