import { useCallback, useState } from 'react'
import _ from 'lodash'

import { HStack, Spacing } from 'components/core/layout'

import { ChatEntryContainer, ChatEntryText, ChatEntryTimestamp } from './styled'
// import { ChatEntryDetail } from './chat-entry-detail'
import { RelativeChatMessage } from './types'

type Props = {
  message: RelativeChatMessage
}

export const ChatEntry = ({ message }: Props) => {
  const [detailVisible, setDetailVisible] = useState(false)
  const onClick = useCallback(() => {
    setDetailVisible(!detailVisible)
  }, [detailVisible, setDetailVisible])
  return (
    <>
      <Spacing horizontal={4}>
        <ChatEntryContainer
          expanded={detailVisible}
          showMatching={message.matching.length > 0}
        >
          <HStack onClick={onClick}>
            <ChatEntryTimestamp data-matching={message.matching.join('\n')}>
              {message.relativeTimestamp &&
                new Date(message.relativeTimestamp).toISOString().substr(11, 8)}
            </ChatEntryTimestamp>
            <ChatEntryText>{message.text}</ChatEntryText>
          </HStack>
          {/* {detailVisible && <ChatEntryDetail message={message} />} */}
        </ChatEntryContainer>
      </Spacing>
    </>
  )
}
