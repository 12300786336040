import { useParams } from 'react-router-dom'

import { ReactionPreviewModal } from 'core/feed/components/preview-modal/reaction-modal'
import { useBlockScroll } from 'utils/browser'
import { UnsavedSignal } from './unsaved'
import {
  ReactionDetailFragment,
  ReactionDetailFragmentDoc,
  useGetReactionSignalQuery,
  useSetMomentReadMutation,
} from '__generated__'
import { useCallback, useEffect, useMemo } from 'react'
import { SavedSignal } from './saved-signal'

interface QuickPrevieProps {
  onClose: () => void
}

export const QuickPreviewModal = ({ onClose }: QuickPrevieProps) => {
  const { signalId } = useParams<{ signalId: string }>()
  const { moment } = useQuickPreviewModalData(signalId)
  useBlockScroll()

  return (
    <ReactionPreviewModal
      signalId={signalId}
      onClose={onClose}
      useEditIfAvailable={true}
      // openReview={() => push(`/review/${signalId}`)}
    >
      {moment && moment.usedIn.length === 0 && <UnsavedSignal />}
      {moment && moment.usedIn.length > 0 && <SavedSignal />}
    </ReactionPreviewModal>
  )
}

const useQuickPreviewModalData = (signalId: string) => {
  const { data } = useGetReactionSignalQuery({ variables: { signalId } })
  const setMomentAsRead = useSetMomentToRead(signalId)

  const moment = useMemo(() => {
    if (!data || data?.moment?.__typename !== 'ChatMoment') {
      return
    }

    return data.moment
  }, [data])

  useEffect(() => {
    if (moment?.isUnread) {
      setMomentAsRead()
    }
  }, [moment, setMomentAsRead])

  return useMemo(
    () => ({
      moment,
    }),
    [moment]
  )
}

const useSetMomentToRead = (momentId: string) => {
  const [mutation] = useSetMomentReadMutation()

  return useCallback(() => {
    mutation({
      variables: { momentId },
      update: (cache, { errors }) => {
        if (errors) return
        const fragmentData: ReactionDetailFragment | null = cache.readFragment({
          id: `ChatMoment:${momentId}`,
          fragmentName: 'ReactionDetail',
          fragment: ReactionDetailFragmentDoc,
        })
        if (fragmentData) {
          cache.writeFragment({
            id: `ChatMoment:${momentId}`,
            fragmentName: 'ReactionDetail',
            fragment: ReactionDetailFragmentDoc,
            data: {
              ...fragmentData,
              isUnread: false,
            },
          })
        }
      },
    })
  }, [momentId, mutation])
}
