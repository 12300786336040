import { ReactNode, useMemo } from 'react'

import { useGetFeedValorantSignalQuery } from '__generated__'
import { HStack, VStack } from 'components/core/layout'
import { Text } from 'components/core/text'

import { PlaylistItemDiv } from './styled'

interface DisplayItemProps {
  children?: ReactNode
  signalId: string
  onClick?: () => void
  selected: boolean
}

export const ValorantMultiKillDisplayItem = ({
  children,
  signalId,
  onClick,
  selected,
}: DisplayItemProps) => {
  const { data } = useGetFeedValorantSignalQuery({
    variables: { signalId },
  })
  const moment = useMemo(() => {
    if (data?.moment?.__typename === 'ValorantMoment') {
      return data.moment
    }
  }, [data?.moment])

  const duration = useMemo(() => {
    if (moment)
      return `${Math.ceil(
        (new Date(moment.endsAt).getTime() -
          new Date(moment.startsAt).getTime()) /
          1000
      )}s`
  }, [moment])

  return moment ? (
    <PlaylistItemDiv onClick={onClick} $selected={selected}>
      <HStack alignItems="center" gap={16}>
        <img
          alt="profile"
          src={moment.channel.profileImageUrl ?? ''}
          width={30}
          height={30}
        />
        <VStack>
          <Text variant="text-2" weight="semibold">
            {moment.channel.name}
          </Text>
          <HStack gap={16}>
            <Text variant="small">
              {moment.kills.length} kill{moment.kills.length > 1 ? 's' : ''}{' '}
              over {duration}
            </Text>
            {/* <Text variant="small">{moment.combatScore}</Text> */}
          </HStack>
        </VStack>
      </HStack>
      {children}
    </PlaylistItemDiv>
  ) : null
}
