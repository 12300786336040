import React, { createContext, useContext, useReducer } from 'react'

export const TIMELINE_TIMES = {
  SHORT: 5 * 60 * 1000,
  MEDIUM: 10 * 60 * 1000,
  LONG: 20 * 60 * 1000,
  OVERVIEW: 60 * 60 * 1000,
}

export const TIMELINE_ZOOMS = [
  TIMELINE_TIMES.SHORT,
  TIMELINE_TIMES.MEDIUM,
  TIMELINE_TIMES.LONG,
  TIMELINE_TIMES.OVERVIEW,
]

interface TimelineState {
  startTime: number
  endTime: number
  selectionPct?: { startPct: number; endPct: number }
  offsetPct: number
  timelineLength: number // this indicates how much time is shown in the viewport
  zoomOptions?: number[]
  containerRect?: DOMRect
}

const INITIAL_STATE: TimelineState = {
  startTime: 0,
  endTime: 0,
  selectionPct: undefined,
  offsetPct: 0,
  timelineLength: TIMELINE_TIMES.MEDIUM,
  zoomOptions: TIMELINE_ZOOMS,
  containerRect: undefined,
}

enum ACTION_TYPES {
  SET_TIMES = 'SET_TIMES',
  SET_OFFSET_PCT = 'SET_OFFSET_PCT',
  SET_TIMELINE_LENGTH = 'SET_TIMELINE_LENGTH',
  SET_SELECTION_PCT = 'SET_SELECTION_PCT',
  SET_CONTENT_RECT = 'SET_CONTENT_RECT',
}

interface SetTimesAction {
  type: ACTION_TYPES.SET_TIMES
  payload: {
    startTime: number
    endTime: number
  }
}

interface SetOffsetPctAction {
  type: ACTION_TYPES.SET_OFFSET_PCT
  payload: {
    offsetPct: number
  }
}

interface SetTimelineLengthAction {
  type: ACTION_TYPES.SET_TIMELINE_LENGTH
  payload: {
    timelineLength: number
  }
}

interface SetSelectionPctAction {
  type: ACTION_TYPES.SET_SELECTION_PCT
  payload: {
    selectionPct?: {
      startPct: number
      endPct: number
    }
  }
}

interface SetContainerRectAction {
  type: ACTION_TYPES.SET_CONTENT_RECT
  payload: {
    containerRect?: DOMRect
  }
}

type TimelineActions =
  | SetTimesAction
  | SetOffsetPctAction
  | SetTimelineLengthAction
  | SetSelectionPctAction
  | SetContainerRectAction

type Dispatch = (action: TimelineActions) => void

const reducer = (
  state: TimelineState,
  action: TimelineActions
): TimelineState => {
  switch (action.type) {
    case ACTION_TYPES.SET_TIMES: {
      const { startTime, endTime } = action.payload
      return {
        ...state,
        startTime,
        endTime,
      }
    }

    case ACTION_TYPES.SET_OFFSET_PCT: {
      const { offsetPct } = action.payload
      return {
        ...state,
        offsetPct,
      }
    }

    case ACTION_TYPES.SET_TIMELINE_LENGTH: {
      const { timelineLength } = action.payload
      return {
        ...state,
        timelineLength,
      }
    }

    case ACTION_TYPES.SET_SELECTION_PCT: {
      const { selectionPct } = action.payload
      return {
        ...state,
        selectionPct,
      }
    }

    case ACTION_TYPES.SET_CONTENT_RECT: {
      const { containerRect } = action.payload
      return {
        ...state,
        containerRect,
      }
    }

    default:
      return state
  }
}

const TimelineContext = createContext<TimelineState | undefined>(undefined)
const TimelineUpdaterContext = createContext<Dispatch | undefined>(undefined)

export const TimelineProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE)

  return (
    <TimelineContext.Provider value={state}>
      <TimelineUpdaterContext.Provider value={dispatch}>
        {children}
      </TimelineUpdaterContext.Provider>
    </TimelineContext.Provider>
  )
}

export const useTimelineState = () => {
  const context = useContext(TimelineContext)
  if (context === undefined) {
    throw new Error('useTimelineState must be used within a TimelineProvider')
  }
  return context
}

export const useTimelineUpdater = () => {
  const context = useContext(TimelineUpdaterContext)
  if (context === undefined) {
    throw new Error('useTimelineUpdater must be used within a TimelineProvider')
  }
  return context
}

useTimelineUpdater.ACTION_TYPES = ACTION_TYPES
