import { useMemo } from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import {
  RecordingStatus,
  useGetMomentCollectionDetailQuery,
} from '__generated__'
import { GridItem } from './grid-item'
import { GridItemSkeleton } from './grid-item-skeleton'
import { GridContainer } from './styled'

type Props = {
  collectionId: string
}

export const CollectionGrid = ({ collectionId }: Props) => {
  const { loading, moments } = useCollectionGrid(collectionId)
  const { url } = useRouteMatch()

  return (
    <GridContainer>
      {loading ? (
        <>
          <GridItemSkeleton />
          <GridItemSkeleton />
          <GridItemSkeleton />
        </>
      ) : (
        <>
          {moments.map((item) => (
            <Link to={`${url}/viewer#m=${item.id}`} key={item.id}>
              <GridItem {...item} />
            </Link>
          ))}
        </>
      )}
    </GridContainer>
  )
}

type MomentGridItem = {
  id: string
  title?: string
  numberOfEdits: number
  channel: {
    id: string
    name: string
    profileImageUrl?: string
  }
  thumbnail?: string
  inProgress: boolean
}

const useCollectionGrid = (id: string) => {
  const { data, loading } = useGetMomentCollectionDetailQuery({
    variables: { id, first: 100 },
  })

  const moments: MomentGridItem[] = useMemo(
    () =>
      (data?.momentCollection?.moments.data || []).map((moment) => ({
        id: moment.id,
        title: moment.title || undefined,
        numberOfEdits: moment.edits.length,
        channel: {
          id: moment.channel.id,
          name: moment.channel.name,
          profileImageUrl: moment.channel.profileImageUrl || undefined,
        },
        thumbnail: moment.edits
          .find((v) => v)
          ?.exports.find((v) => v)
          ?.output?.thumbnails?.find((v) => v)?.url,
        inProgress: moment.edits.some((edit) =>
          edit.exports.some((v) => v.status === RecordingStatus.Inprogress)
        ),
      })),
    [data]
  )

  return {
    moments,
    loading,
  }
}
