import { useMemo } from 'react'
import momentjs from 'moment'

import {
  TimeContainerDiv,
  TimeTextDiv,
} from 'core/shared/timeline/detail/time-display/styled'
import { TIMELINE_TIMES, useTimelineState } from 'core/editor/timeline/context'
import { Text } from 'components/core/text'
import { durationFormat } from 'utils/time'

export const ValorantTimelineTimeDisplay = () => {
  const { timelineLength, startTime, endTime } = useTimelineState()

  const timeInterval = useMemo(() => {
    switch (timelineLength) {
      case TIMELINE_TIMES.SHORT:
        return 2 * 60 * 1000
      case TIMELINE_TIMES.MEDIUM:
        return 5 * 60 * 1000
      case TIMELINE_TIMES.LONG:
        return 10 * 60 * 1000
      default:
        if (timelineLength < TIMELINE_TIMES.SHORT) {
          return 10 * 1000
        }
        return 20 * 60 * 1000
    }
  }, [timelineLength])

  const timeMarks = useMemo(() => {
    const totalTime = endTime - startTime
    // const startingPoint = momentjs(startTime).startOf('hour').valueOf()
    const results: { text: string; pct: number }[] = []
    let currentTime = momentjs(startTime).valueOf()

    function calcPositionPct(time: number) {
      return (time - startTime) / totalTime
    }

    while (currentTime < endTime) {
      // const momentItem = momentjs(currentTime)
      results.push({
        // text: momentItem.format('LT'),
        text: durationFormat((currentTime - startTime) / 1000),
        pct: calcPositionPct(currentTime),
      })

      currentTime += timeInterval
    }
    return results
  }, [endTime, startTime, timeInterval])

  return (
    <TimeContainerDiv>
      {timeMarks.map(({ text, pct }) => (
        <div key={text} style={{ position: 'absolute', left: `${pct * 100}%` }}>
          <TimeTextDiv>
            <Text variant="text-3" color="#777E90" mono={true}>
              {text}
            </Text>
          </TimeTextDiv>
        </div>
      ))}
    </TimeContainerDiv>
  )
}
