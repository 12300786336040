import styled from 'styled-components/macro'
import { Column } from 'react-table'
import { ChangeEvent, useCallback, useMemo } from 'react'

import { Button } from 'components/core/button'
import { Card, Container, Spacing } from 'components/core/layout'
import { Table } from 'components/core/table'
import { TextField } from 'components/core/textfield'
import { AdminSearchResultFragment } from '__generated__'

import { SearchUserCard } from '../channels/components/search-user-card'
import { useSearch } from '../channels/hooks'

interface Props {
  addToCollection: (channelId: string) => void
  collectionUserIds: string[]
}

export const AddChannel = ({ collectionUserIds, addToCollection }: Props) => {
  const {
    search,
    setSearch,
    results,
    nextPage,
    prevPage,
    hasNextPage,
    hasPrevPage,
    directHit,
  } = useSearch()

  const collectionIdMapping = useMemo(() => {
    return collectionUserIds.reduce((acc, id) => {
      return {
        ...acc,
        [id]: true,
      }
    }, {}) as { [id: string]: true }
  }, [collectionUserIds])

  const columns: Column<AdminSearchResultFragment>[] = useMemo(
    () => [
      {
        accessor: 'name',
        Header: 'name',
        width: 300,
      },

      {
        accessor: 'id',
        Header: 'add',
        Cell: ({ value }) => {
          const inList = collectionIdMapping[value] !== undefined
          const onClick = inList ? () => {} : () => addToCollection(value)
          return (
            <Button onClick={onClick} disabled={inList}>
              {inList ? 'Added' : 'Add'}
            </Button>
          )
        },
      },
    ],
    [addToCollection, collectionIdMapping]
  )

  const data = useMemo(() => results || [], [results])

  const onChange = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      setSearch(evt.target.value)
    },
    [setSearch]
  )

  return (
    <Wrapper>
      <Spacing bottom={32}>
        <Card neutralOffset={2} body>
          <TextField
            placeholder="Streamer name"
            label="filter"
            value={search}
            onChange={onChange}
          />
        </Card>
      </Spacing>
      <Table data={data} columns={columns} />
      {search.length > 0 && (data.length === 0 || !directHit) && (
        <Spacing vertical={16}>
          <SearchUserCard name={search} />
        </Spacing>
      )}

      {(hasNextPage || hasPrevPage) && (
        <Spacing top={16}>
          <Container direction="row" justifyContent="space-evenly">
            <Button onClick={prevPage} disabled={!hasPrevPage}>
              Prev
            </Button>
            <Button onClick={nextPage} disabled={!hasNextPage}>
              Next
            </Button>
          </Container>
        </Spacing>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  a {
    text-decoration: underline;
  }
`
