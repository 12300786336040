import { HStack, VStack } from 'components/core/layout'
import { Text } from 'components/core/text'
import { AGENT_THUMB_IMAGES } from 'components/valorant/agent'
import { RANK_ICONS } from 'components/valorant/rank-icon'
import {
  ScoreboardEntry,
  useScoreboard,
} from 'core/feed/components/scoreboard/hooks'
import { ScoreboardTeam } from 'core/signal-review/components/scoreboard'
import { useMemo } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { ValorantMatchTeam } from '__generated__'
import { PlayerHighlightData, useGetMatchHighlights } from './hooks'
import { Container, TeamContainer } from './styled'

export const SoloMatchHighlightWithData = () => {
  const { matchId, accountId } = useParams<{
    matchId: string
    accountId: string
  }>()
  const accountIds = useMemo(() => [accountId], [accountId])
  const players = useGetMatchHighlights(matchId, accountIds)
  const { teams } = useScoreboard(matchId)
  return players && teams ? (
    <SoloMatchHighlight players={players} teams={teams} matchId={matchId} />
  ) : null
}

interface SoloMatchHighlightProps {
  players: PlayerHighlightData[]
  teams: Record<string, ScoreboardEntry[]>
  matchId: string
}

const SoloMatchHighlight = ({
  players,
  teams,
  matchId,
}: SoloMatchHighlightProps) => {
  return (
    <>
      <Text variant="h2">Solo Match Highlight</Text>
      {players?.map((player) => (
        <VStack key={player.gameName} gap={12}>
          <HStack gap={12}>
            <Link
              to={`/channel/${player.displayName.toLocaleLowerCase()}/matches/${matchId}`}
            >
              <img
                src={player.profileImageUrl ?? ''}
                width={50}
                height={50}
                style={{ borderRadius: '50%' }}
                alt=""
              />
            </Link>
            <VStack>
              <Text variant="text-1">{player.gameName}</Text>
              <HStack alignItems="center">
                <Text variant="text-2">
                  <img
                    src={RANK_ICONS[player.competitiveTier]}
                    width={30}
                    height={30}
                    style={{ borderRadius: '50%' }}
                    alt=""
                  />
                </Text>
                {player.leaderboardRank && (
                  <Text variant="text-2">#{player.leaderboardRank}</Text>
                )}
              </HStack>
            </VStack>
          </HStack>
          <HStack alignItems="center" gap={12}>
            <img
              src={AGENT_THUMB_IMAGES[player.agent]}
              width={50}
              height={50}
              alt=""
            />
            <Text variant="text-2">
              {player.kills}K - {player.deaths}D
            </Text>
          </HStack>
        </VStack>
      ))}
      {teams && (
        <Container>
          <TeamContainer>
            <ScoreboardTeam players={teams[ValorantMatchTeam.Blue]} />
          </TeamContainer>
          <TeamContainer>
            <ScoreboardTeam players={teams[ValorantMatchTeam.Red]} />
          </TeamContainer>
        </Container>
      )}
    </>
  )
}

// 76697c5d-97cc-4e52-bc57-d86451c58f82
// dCjQTaPJ31upUx5njWLsoyjCXpcGXFANz-U45caNm70nHstpfOxzunrl1FS_WoBUBXocalvL0HXmsQ
