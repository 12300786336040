import styled from 'styled-components/macro'
import { fontWeights } from 'theme/typography'

export const StyledTable = styled.table`
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;

  thead th {
    background: ${({ theme }) => theme.colors.neutrals.n7};
    border-top: 1px solid ${({ theme }) => theme.colors.neutrals.n6};
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutrals.n6};

    &:first-child {
      border-radius: 8px 0 0 8px;
      border-left: 1px solid ${({ theme }) => theme.colors.neutrals.n6};
    }

    &:last-child {
      border-radius: 0 8px 8px 0;
      border-right: 1px solid ${({ theme }) => theme.colors.neutrals.n6};
    }
  }

  th {
    font-weight: ${fontWeights['semibold']};
    text-transform: uppercase;
  }

  tr > td,
  tr > th {
    padding: 0 8px;
  }

  th > div,
  td > div {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
  }

  th > div {
    min-height: 40px;
  }

  td > div {
    min-height: 48px;
  }

  tr > td {
    border-bottom: 1px solid ${({ theme }) => theme.colors.background.tertiary};
  }
`
