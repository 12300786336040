import { Container, Content, Spacing, VStack } from 'components/core/layout'
import { Text } from 'components/core/text'
import { CampaignPreviewItem } from 'core/campaign/preview/preview-item'
import { useParams } from 'react-router'
import { useGetOrganizationCampaignsQuery } from '__generated__'

export const CampaignsPage = () => {
  const { organization: slug } = useParams<{ organization: string }>()
  const { data } = useGetOrganizationCampaignsQuery({
    variables: { slug },
  })
  return (
    <Content background="secondary">
      <VStack>
        <Spacing top={32}>
          <Text variant="h4" weight="semibold">
            Campaigns
          </Text>
        </Spacing>
        <Spacing top={8}>
          <Container direction="row" gap="16px" wrap="wrap">
            {data?.organization?.campaigns.map(({ id }) => (
              <CampaignPreviewItem key={id} campaignId={id} />
            ))}
          </Container>
        </Spacing>
      </VStack>
    </Content>
  )
}
