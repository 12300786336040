import { ReactNode } from 'react'
import styled from 'styled-components/macro'
import { useDimensions } from 'utils/browser'

type Props = {
  open?: boolean
  children?: ReactNode
  className?: string
}

export const Collapse = ({ open = true, className, children }: Props) => {
  const [contentsRef, contentsRect] = useDimensions()

  return (
    <CollapseContainer
      heightProp={open ? contentsRect?.height ?? 0 : 0}
      className={className}
    >
      <div ref={contentsRef}>{children}</div>
    </CollapseContainer>
  )
}

const CollapseContainer = styled.div<{ heightProp: number }>`
  overflow: hidden;
  height: ${({ heightProp }) => heightProp}px;
  transition: height 0.2s ease-in-out;
`
