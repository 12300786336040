import { useTheme } from 'styled-components/macro'

type Props = {
  up?: boolean
}

export const Chevron = ({ up }: Props) => {
  const theme = useTheme()
  return (
    <svg
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      transform={`rotate(${up ? 180 : 0})`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.00004 4.2929L1.35359 0.646454L0.646484 1.35356L5.00004 5.70711L9.35359 1.35356L8.64648 0.646454L5.00004 4.2929Z"
        fill={theme.colors.neutrals.n3}
      />
    </svg>
  )
}
