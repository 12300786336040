import React from 'react'
import { Route, Switch, useRouteMatch, useParams } from 'react-router'

import { EditorProvider } from 'core/editor/context'
import { TimelineProvider } from 'core/editor/timeline/context'

import { VideoEditor } from './editor'
import { VideoExplorerProvider } from './context'
import { VideoMoments } from './moments'
import { Navigation } from 'core/navigation'

export const VideoPage: React.FC = () => {
  const { path } = useRouteMatch()
  const { videoId } = useParams<{ videoId: string }>()

  return (
    <Switch>
      <Route path={`${path}/editor`}>
        <VideoExplorerProvider>
          <EditorProvider>
            <TimelineProvider>
              <VideoEditor videoId={videoId} />
            </TimelineProvider>
          </EditorProvider>
        </VideoExplorerProvider>
      </Route>
      <Route path={path}>
        <Navigation />
        <VideoMoments videoId={videoId} />
      </Route>
    </Switch>
  )
}
