import React, { useCallback, useMemo } from 'react'

import { StreamerAvatarWithVideo } from 'components/streamer-avatar'
import { useEditor } from 'core/editor/hooks'
import { useTimelineHelpers } from 'core/editor/timeline/hooks'
import { Scene } from 'core/exporter/constants'
import { durationFormatMinutes } from 'utils/time'
import { ReactComponent as SaveIcon } from 'icons/svgs/regular/save.svg'
import { ReactComponent as TrashIcon } from 'icons/svgs/regular/trash.svg'

import { CropSettingType } from '../context'
import { useExporterHelper } from '../hooks'
import { SceneActionsDiv, SceneDisplayContainerDiv } from './styled'
import { useEditScenes } from './hooks'

interface SceneDisplayCrops {
  idx: number
  scene: Scene
  editId: string
}

export const SceneDisplay: React.FC<SceneDisplayCrops> = ({
  scene,
  editId,
}) => {
  const { loadSceneData } = useSceneDisplay(scene)
  const { updateScene, removeScene } = useEditScenes(editId)
  // kind of complicated, but finding primary video
  const primaryVideoId = useMemo(() => {
    const primaryCrop = scene.template.crops.find(({ primary }) => primary)
    if (!primaryCrop) return undefined
    return scene.cropData.find((cd) => cd.title === primaryCrop.title)?.videoId
  }, [scene])

  const length = durationFormatMinutes((scene.end - scene.start) / 1000)

  const saveCurrentScene = useCallback(
    (evt: React.MouseEvent<SVGElement>) => {
      evt.stopPropagation()
      updateScene(scene.id ?? '')
    },
    [scene.id, updateScene]
  )

  const removeThisScene = useCallback(
    (evt: React.MouseEvent<SVGElement>) => {
      evt.stopPropagation()
      removeScene(scene.id ?? '')
    },
    [removeScene, scene.id]
  )

  return (
    <SceneDisplayContainerDiv onClick={loadSceneData}>
      <div>{length}</div>
      {primaryVideoId ? (
        <StreamerAvatarWithVideo
          videoId={primaryVideoId}
          width={40}
          height={40}
        ></StreamerAvatarWithVideo>
      ) : null}
      <SceneActionsDiv>
        <SaveIcon onClick={saveCurrentScene} />
        <TrashIcon onClick={removeThisScene} />
      </SceneActionsDiv>
    </SceneDisplayContainerDiv>
  )
}

const useSceneDisplay = (scene: Scene) => {
  const { seekTo } = useEditor()
  const { setSelectionToTime } = useTimelineHelpers()
  const { setTemplate, setCropSettings, setPrimaryVideoId } =
    useExporterHelper()

  const loadSceneData = useCallback(() => {
    const primaryCropTitle = scene.template.crops.find(
      (crop) => crop.primary === true
    )?.title

    setTemplate(scene.template)
    let primaryVideoId = ''
    scene.cropData.forEach((cropData) => {
      if (primaryCropTitle === cropData.title) {
        primaryVideoId = cropData.videoId
      }
      setCropSettings({
        title: cropData.title,
        rect: cropData.cropRect,
        type: CropSettingType.Custom,
        videoId: cropData.videoId,
      })
    })

    setPrimaryVideoId(primaryVideoId)
    setSelectionToTime(scene.start, scene.end)
    seekTo(scene.start)
  }, [
    scene.cropData,
    scene.end,
    scene.start,
    scene.template,
    seekTo,
    setCropSettings,
    setPrimaryVideoId,
    setSelectionToTime,
    setTemplate,
  ])

  return useMemo(
    () => ({
      loadSceneData,
    }),
    [loadSceneData]
  )
}
