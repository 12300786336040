import React, { useCallback, useMemo } from 'react'
import { SCENE_TIMELINE_SIZE } from '.'
import { SceneContentDiv, SceneEditOptionsDiv, SceneItemDiv } from '../styled'
import { Scene } from 'core/exporter/constants'
import { StreamerAvatarWithVideo } from 'components/streamer-avatar'
import { Text } from 'components/core/text'
import { useEditor } from 'core/editor/hooks'
import { useTimelineHelpers } from 'core/editor/timeline/hooks'
import { useComposerHelper } from '../hooks'
import { CropSettingType, useComposerState } from '../context'
import { Button } from 'components/core/button'
import { HStack } from 'components/core/layout'
import { useParams } from 'react-router'
import { useEditScenes } from './hooks'
import { LAYER_COLORS } from '../template-sidebar/option'
import { DraggableProvided } from 'react-beautiful-dnd'
import { OverlayType } from '__generated__'

interface Props {
  containerRect: DOMRect
  scene: Scene
  draggableProvided: DraggableProvided // used for drag & drop
}

export const SceneItem = ({
  scene,
  containerRect,
  draggableProvided,
}: Props) => {
  const { primaryVideoId, length, selected, updateScene, removeScene } =
    useSceneItemData(scene, containerRect)
  const { scenes } = useComposerState()
  const { loadSceneData } = useSceneDisplay()
  const loadThisScene = useCallback(() => {
    loadSceneData(scene)
  }, [loadSceneData, scene])
  const isLastScene = useMemo(() => {
    return scenes.length === 1
  }, [scenes.length])

  return (
    <SceneItemDiv
      $selected={selected}
      onClick={loadThisScene}
      ref={draggableProvided.innerRef}
      {...draggableProvided.draggableProps}
      {...draggableProvided.dragHandleProps}
    >
      <SceneContentDiv>
        {primaryVideoId ? (
          <StreamerAvatarWithVideo
            videoId={primaryVideoId}
            width={32}
            height={32}
          ></StreamerAvatarWithVideo>
        ) : null}
        <Text variant="small" as="div">
          {(length / 1000).toFixed(2)}s
        </Text>
      </SceneContentDiv>
      {selected && (
        <SceneEditOptionsDiv>
          <HStack>
            <Button
              size="small"
              variant="plain"
              onClick={(evt: React.MouseEvent<HTMLButtonElement>) => {
                evt.stopPropagation()
                updateScene()
              }}
            >
              Save
            </Button>
            {!isLastScene && (
              <Button
                size="small"
                variant="plain"
                onClick={(evt: React.MouseEvent<HTMLButtonElement>) => {
                  evt.stopPropagation()
                  removeScene()
                }}
              >
                Delete
              </Button>
            )}
          </HStack>
        </SceneEditOptionsDiv>
      )}
    </SceneItemDiv>
  )
}

const useSceneItemData = (scene: Scene, containerRect: DOMRect) => {
  const { editId } = useParams<{ momentId: string; editId: string }>()
  const { updateScene, removeScene } = useEditScenes(editId)
  const { selectedSceneId } = useComposerState()
  const length = scene.end - scene.start
  const sceneItemStyles = useMemo(() => {
    const widthPct = length / SCENE_TIMELINE_SIZE
    return { width: `${widthPct * containerRect.width}px` }
  }, [containerRect.width, length])

  const primaryVideoId = useMemo(() => {
    const primaryCrop = scene.template.crops.find(({ primary }) => primary)
    if (!primaryCrop) return undefined
    return scene.cropData.find((cd) => cd.title === primaryCrop.title)?.videoId
  }, [scene])

  const updateThisScene = useCallback(() => {
    if (!scene.id) return
    updateScene(scene.id)
  }, [scene.id, updateScene])

  const removeThisScene = useCallback(() => {
    if (!scene.id) return
    removeScene(scene.id)
  }, [removeScene, scene.id])

  return useMemo(
    () => ({
      selected: selectedSceneId === scene.id,
      styles: sceneItemStyles,
      primaryVideoId,
      length,
      updateScene: updateThisScene,
      removeScene: removeThisScene,
    }),
    [
      length,
      primaryVideoId,
      removeThisScene,
      scene.id,
      sceneItemStyles,
      selectedSceneId,
      updateThisScene,
    ]
  )
}

export const useSceneDisplay = () => {
  const { seekTo } = useEditor()
  const { setSelectionToTime } = useTimelineHelpers()
  const {
    setTemplate,
    setCropSettings,
    setPrimaryVideoId,
    setSelectedSceneId,
    setSelectedTemplateCrop,
    setKillfeed,
    setKillfeedOptions,
    setPlayerDisplay,
    setPlayerDisplayOptions,
    setKDA,
    setKDAOptions,
    setMatchIntro,
    setMatchIntroOptions,
    setSubtitles,
    setSubtitlesOptions,
  } = useComposerHelper()

  const loadSceneData = useCallback(
    (scene: Scene) => {
      const primaryCropTitle = scene.template.crops.find(
        (crop) => crop.primary === true
      )?.title

      setTemplate(scene.template)
      let primaryVideoId = ''
      scene.cropData.forEach((cropData, idx) => {
        if (primaryCropTitle === cropData.title) {
          primaryVideoId = cropData.videoId
        }
        setCropSettings({
          title: cropData.title,
          rect: cropData.cropRect,
          type: CropSettingType.Custom,
          videoId: cropData.videoId,
          color: LAYER_COLORS[idx],
        })
      })
      setSelectedSceneId(scene.id)
      setPrimaryVideoId(primaryVideoId)
      setSelectionToTime(scene.start, scene.end)

      if (
        scene.overlays[0] &&
        scene.overlays[0].overlayType === OverlayType.Killfeed
      ) {
        const overlayData = scene.overlays[0].data
        if (overlayData.killfeed) {
          setKillfeed(true)
          setKillfeedOptions({
            y: overlayData.killfeed.y,
            justify: overlayData.killfeed.just,
            type: overlayData.killfeed.type,
          })
        } else {
          setKillfeed(false)
        }
        if (overlayData.playerInfo) {
          setPlayerDisplay(true)
          setPlayerDisplayOptions({
            y: overlayData.playerInfo.y,
            justify: overlayData.playerInfo.just,
          })
        } else {
          setPlayerDisplay(false)
        }
        if (overlayData.kda) {
          setKDA(true)
          setKDAOptions({
            x: overlayData.kda.x,
            y: overlayData.kda.y,
          })
        } else {
          setKDA(false)
        }
        if (overlayData.matchIntro) {
          setMatchIntro(true)
          setMatchIntroOptions({
            y: overlayData.matchIntro.y,
          })
        } else {
          setMatchIntro(false)
        }
        if (overlayData.subtitles) {
          setSubtitles(true)
          setSubtitlesOptions({
            y: overlayData.subtitles.y,
          })
        } else {
          setMatchIntro(false)
        }
      } else {
        setKillfeed(false)
        setPlayerDisplay(false)
        setKDA(false)
        setMatchIntro(false)
        setSubtitles(false)
      }

      if (scene.template.crops.length > 0) {
        setSelectedTemplateCrop(scene.template.crops[0])
      }

      seekTo(scene.start)
    },
    [
      seekTo,
      setCropSettings,
      setKDA,
      setKDAOptions,
      setKillfeed,
      setKillfeedOptions,
      setMatchIntro,
      setMatchIntroOptions,
      setPlayerDisplay,
      setPlayerDisplayOptions,
      setPrimaryVideoId,
      setSelectedSceneId,
      setSelectedTemplateCrop,
      setSelectionToTime,
      setSubtitles,
      setSubtitlesOptions,
      setTemplate,
    ]
  )

  return useMemo(
    () => ({
      loadSceneData,
    }),
    [loadSceneData]
  )
}
