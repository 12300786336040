import { Rect } from 'core/exporter/constants'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { cropRectInPx } from './helpers'

export const useCropDrag = (
  crop: Rect,
  containerRect: DOMRect | undefined,
  updateCropRect: (nextCrop: Rect) => void,
  active: boolean
) => {
  const ref = useRef<HTMLDivElement>(null)
  const [dragging, setDragging] = useState(false)
  const startingOffsetPos = useRef<{ x: number; y: number }>({ x: 0, y: 0 })
  const startingClientPos = useRef<{ x: number; y: number }>({ x: 0, y: 0 })

  const cropInPx = useMemo(
    () => (crop ? cropRectInPx(containerRect, crop) : undefined),
    [crop, containerRect]
  )

  const onMouseMove = useCallback(
    (event: MouseEvent) => {
      event.preventDefault()
      if (!containerRect || !cropInPx) return
      const diff = {
        x: startingClientPos.current.x - event.clientX,
        y: startingClientPos.current.y - event.clientY,
      }
      let nextOffsetPos = {
        x: startingOffsetPos.current.x - diff.x,
        y: startingOffsetPos.current.y - diff.y,
      }

      // min check
      if (nextOffsetPos.x < 0) {
        nextOffsetPos.x = 0
      }
      if (nextOffsetPos.y < 0) {
        nextOffsetPos.y = 0
      }

      // max check
      if (nextOffsetPos.x + cropInPx.width > containerRect.width) {
        nextOffsetPos.x = containerRect.width - cropInPx.width
      }
      if (nextOffsetPos.y + cropInPx.height > containerRect.height) {
        nextOffsetPos.y = containerRect.height - cropInPx.height
      }

      const nextPctCropRect = {
        ...crop,
        x: nextOffsetPos.x / containerRect.width,
        y: nextOffsetPos.y / containerRect.height,
      }
      updateCropRect(nextPctCropRect)
    },
    [cropInPx, crop, containerRect, updateCropRect]
  )

  const onMouseUp = useCallback(() => {
    window.removeEventListener('mousemove', onMouseMove)
    window.removeEventListener('mouseup', onMouseUp)
    startingOffsetPos.current = { x: 0, y: 0 }
    startingClientPos.current = { x: 0, y: 0 }
    setDragging(false)
  }, [setDragging, onMouseMove])

  const onMouseDown = useCallback(
    (event: MouseEvent) => {
      event.preventDefault()
      if (!containerRect) return
      window.addEventListener('mousemove', onMouseMove)
      window.addEventListener('mouseup', onMouseUp)

      startingOffsetPos.current = {
        x: crop.x * containerRect.width,
        y: crop.y * containerRect.height,
      }
      startingClientPos.current = {
        x: event.clientX,
        y: event.clientY,
      }

      setDragging(true)
    },
    [onMouseMove, onMouseUp, containerRect, crop]
  )

  //setup
  useEffect(() => {
    // console.log({ref: ref.current, crop, contianerRect, active})
    if (ref.current && containerRect && active) {
      const element = ref.current
      element.addEventListener('mousedown', onMouseDown)

      return () => {
        element.removeEventListener('mousedown', onMouseDown)
      }
    }
  }, [onMouseDown, containerRect, ref, active])

  return useMemo(
    () => ({
      dragging,
      ref,
    }),
    [dragging, ref]
  )
}

export interface CropRestrictions {
  aspectRatio?: {
    width: number
    height: number
  }
}

export const useCropResize = (
  crop: Rect,
  containerRect: DOMRect | undefined,
  updateCropRect: (nextCrop: Rect) => void,
  restrictions: CropRestrictions,
  active: boolean
) => {
  const ref = useRef<HTMLDivElement>(null)
  const [dragging, setDragging] = useState(false)
  const startingRectSize = useRef<{ width: number; height: number }>({
    width: 0,
    height: 0,
  })
  const startingClientPos = useRef<{ x: number; y: number }>({ x: 0, y: 0 })

  const cropInPx = useMemo(
    () => (crop ? cropRectInPx(containerRect, crop) : undefined),
    [crop, containerRect]
  )

  const onMouseMove = useCallback(
    (event: MouseEvent) => {
      event.preventDefault()
      if (!containerRect || !cropInPx) return
      const diff = {
        x: startingClientPos.current.x - event.clientX,
        y: startingClientPos.current.y - event.clientY,
      }
      let nextRectSize = {
        width: startingRectSize.current.width - diff.x,
        height: startingRectSize.current.height - diff.y,
      }

      // check that it would fit into parent
      if (cropInPx.x + nextRectSize.width > containerRect.width) {
        nextRectSize.width = containerRect.width - cropInPx.x
      }

      if (cropInPx.y + nextRectSize.height > containerRect.height) {
        nextRectSize.height = containerRect.height - cropInPx.y
      }

      if (restrictions.aspectRatio) {
        // Now we need to enforce aspect ratio, so we grab ideal width/height
        // and we calculate them separately, limit them to parent container
        // then we use the one with the smallest area
        let widthFocusedAspectRatio = {
          width: nextRectSize.width,
          height:
            (nextRectSize.width * restrictions.aspectRatio.height) /
            restrictions.aspectRatio.width,
        }

        // in this ideal case, we need to find out if it fits, if it doesn't recalc both
        if (
          cropInPx.y + widthFocusedAspectRatio.height >
          containerRect.height
        ) {
          const heightFits = containerRect.height - cropInPx.y
          widthFocusedAspectRatio = {
            height: heightFits,
            width:
              (heightFits * restrictions.aspectRatio.width) /
              restrictions.aspectRatio.height,
          }
        }

        let heightFocusedAspectRatio = {
          width:
            (nextRectSize.height * restrictions.aspectRatio.width) /
            restrictions.aspectRatio.height,
          height: nextRectSize.height,
        }

        if (cropInPx.x + heightFocusedAspectRatio.width > containerRect.width) {
          const widthFits = containerRect.width - cropInPx.x
          heightFocusedAspectRatio = {
            width: widthFits,
            height:
              (widthFits * restrictions.aspectRatio.height) /
              restrictions.aspectRatio.width,
          }
        }

        // smaller area is the one we want
        if (
          widthFocusedAspectRatio.width * widthFocusedAspectRatio.height >
          heightFocusedAspectRatio.width * heightFocusedAspectRatio.height
        ) {
          nextRectSize = widthFocusedAspectRatio
        } else {
          nextRectSize = heightFocusedAspectRatio
        }
      }

      const nextPctCropRect = {
        ...crop,
        width: nextRectSize.width / containerRect.width,
        height: nextRectSize.height / containerRect.height,
      }

      updateCropRect(nextPctCropRect)
    },
    [containerRect, cropInPx, crop, updateCropRect, restrictions]
  )

  const onMouseUp = useCallback(() => {
    window.removeEventListener('mousemove', onMouseMove)
    window.removeEventListener('mouseup', onMouseUp)
    startingRectSize.current = { width: 0, height: 0 }
    startingClientPos.current = { x: 0, y: 0 }

    // reset styled cursor
    document?.querySelector('body')?.style.setProperty('cursor', 'auto')
    setDragging(false)
  }, [setDragging, onMouseMove])

  const onMouseDown = useCallback(
    (event: MouseEvent) => {
      event.preventDefault()
      if (!containerRect) return
      window.addEventListener('mousemove', onMouseMove)
      window.addEventListener('mouseup', onMouseUp)

      startingRectSize.current = {
        width: crop.width * containerRect.width,
        height: crop.height * containerRect.height,
      }
      startingClientPos.current = {
        x: event.clientX,
        y: event.clientY,
      }

      // set cursor styling globally, super hacky...
      document
        ?.querySelector('body')
        ?.style.setProperty('cursor', 'nwse-resize', 'important')

      setDragging(true)
      // This could probably trigger a move action if we don't stopPropagation
      event.stopPropagation()
    },
    [onMouseMove, onMouseUp, containerRect, crop]
  )

  //setup
  useEffect(() => {
    if (ref.current && containerRect && active) {
      const element = ref.current
      element.addEventListener('mousedown', onMouseDown)

      return () => {
        element.removeEventListener('mousedown', onMouseDown)
      }
    }
  }, [onMouseDown, containerRect, ref, active])

  return useMemo(
    () => ({
      dragging,
      ref,
    }),
    [dragging, ref]
  )
}
