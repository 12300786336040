export const funny = [
  'c379d2fe-7198-49d9-84b4-e2177fd2ecbf',
  '9a9832e3-ce8d-408b-b42b-db5098f32a23',
  'f2f1223b-d6c3-40b0-b37d-58c3c08028f4',
  '6ce2fa6a-bdc5-42e5-a71e-8e1923bb9628',
  'beb6a1ba-696e-4a3d-84ca-d03cfe7f8a3e',
  '051fe9be-10ae-4d2f-8812-5abb63552d31',
  'd66183ab-ddf0-41d6-beda-9b457fd709fa',
  'e05ed2c1-b434-46f9-8f8a-b0562b11ce89',
]

export const characterBuilding = [
  'e686e801-3379-4abb-bf00-4da7e9b8fb77',
  '26b12495-353a-4409-8afd-d189c7daab59',
  'e8a5a046-4357-4cb5-9f81-b3de93928edc',
  '425ed2cd-26c0-4dd3-a5b7-4c5b6102ac5f',
  'cad61e5f-1d54-49b8-be8c-320ddd1cd028',
  'c3068b51-2cbe-47e3-a322-4fc51acb7f75',
  '95df1000-824d-4e2b-9609-32d8c8e0b11e',
  '0887def5-12b5-49fa-9382-ec5d59694ae3',
]

export const sponsorReads = [
  'df2e07ed-bd0a-4114-9c06-bf25b6671cb5',
  '3aec0926-3829-4693-9761-2c0c3208b9f9',
  '9ff4ea75-01b3-4ea9-96b3-d2f5cd206c56',
  'e2cd087c-0d81-4fc5-a888-84a75e1875e8',
  'df8c624d-200d-427f-9c9d-6eea39abe717',
  '7b3a66cc-e75a-48d0-932c-d37b6d2102dc',
  'a5568649-4f35-4533-9d85-e161b80b1435',
  'f7165dcd-1322-44c3-9222-dd49974b6ed8',
  'd3c26063-ac6f-409f-ac1e-46601fc2cbc7',
  'a748809b-b575-4e77-a827-35cb0a2bab43',
  'cf2ac22a-5f62-4ac4-bf3f-5494ad3521b4',
  'e13a56f4-ac9e-45ce-a5cf-8bfde15b1e96',
  'e8bd0992-ec74-4290-9e41-1b1fe44c52a6',
  '15153a3f-716e-4579-8cb1-413ad0fdbac8',
  '5582b865-8ebb-4fde-b033-54018bdd1a80',
  '7700c130-e637-409a-972b-d3f6b29ab5a3',
]

export const fallingOffTheMap = [
  'd93e7704-b23c-4da0-94a9-5ee6cb628f68',
  '8090b00b-5db0-41e2-89ee-4256cbb82bbb',
  'bd182460-712b-446f-aa83-e6916096991b',
  '3ef9c768-49b3-49c2-80df-38d177276fa6',
]

export const daemonhost = [
  'fb9816de-0957-4d14-b548-7abf3cbfc017',
  'bd0e757e-2242-4c59-a3d0-3aa4a0f4d088',
  '43a87643-d0c1-49d0-bc2b-59c15ed6c7f9',
]

export const giveaways = [
  'ed7a69b7-c475-4662-b53f-82adbcd6864e',
  'b58506ed-a770-426c-aae5-df3299cdd615',
  '73f95534-2553-4692-9aa9-4a68d5c91b2a',
  '6e676684-1bc3-487f-a2cd-b59e726a1bc5',
  'a082761f-1e44-4fa2-b29c-b06d05682973',
  '9e8b69f2-f74c-45a1-9279-0041797acc61',
]

export const mobile = [
  'f0336679-e9c1-41f0-8032-007414310481',
  '644fb9ef-078f-4eb9-b55e-24a75b986829',
  '8fc05aaa-ffd3-44ef-ac02-c0acc53a9b34',
  'fd7fbd33-def3-4ecb-9072-7c1c11945a43',
  'd5e01ef9-7782-4dc1-83ba-40af46b29f6e',
  '6b54f778-8dea-49b5-9550-45060fc21b95',
]

export const SECTIONS_WITH_TITLES = [
  {
    title: 'Funny Moments',
    edits: funny,
  },
  {
    title: 'Character Building',
    edits: characterBuilding,
  },
  {
    title: 'Mobile',
    edits: mobile,
  },
  {
    title: 'Daemonhost Boss Deaths',
    edits: daemonhost,
  },
  {
    title: 'Falling off the Map',
    edits: fallingOffTheMap,
  },
  {
    title: 'Sponsor Reads',
    edits: sponsorReads,
  },
  {
    title: 'Key Giveaways',
    edits: giveaways,
  },
]
