import React, { useMemo } from 'react'
import Simplebar from 'simplebar-react'

import { ChatMomentFragment } from '__generated__'
import { ReactComponent as ChatIcon } from 'icons/svgs/regular/comment-alt-lines.svg'
import { ReactComponent as MultiIcon } from 'icons/svgs/regular/layer-group.svg'
import { ReactComponent as ValorantIcon } from 'icons/svgs/brands/valorant.svg'

import { MomentTypeSelectDiv, MomentTypeSelectItemDiv } from '../styled'
import { ChatSidebar } from './chat'
import { MultiMomentsSidebar } from './multi'
import { ValorantSidebar } from './valorant'
import { BrowsingMode, useVideoExplorerState } from 'page/video/context'
import { useVideoExplorerHelpers } from 'page/video/hooks'

interface VideoSidebarProps {
  videoId: string
  chatMoments: ChatMomentFragment[]
}

export const VideoSidebar: React.FC<VideoSidebarProps> = ({
  videoId,
  chatMoments,
}) => {
  const { browsingMode } = useVideoExplorerState()
  const { setBrowsingMode } = useVideoExplorerHelpers()

  const containerStyles = useMemo(
    () => ({ height: '100vh', width: '100%' }),
    []
  )

  const simplebarStyles = useMemo(() => {
    return {
      maxHeight: 'calc(100% - 40px)',
      width: '100%',
    }
  }, [])

  return (
    <div style={containerStyles}>
      <MomentTypeSelectDiv>
        <MomentTypeSelectItemDiv
          onClick={() => setBrowsingMode(BrowsingMode.CHAT)}
          $selected={browsingMode === BrowsingMode.CHAT}
        >
          <ChatIcon />
        </MomentTypeSelectItemDiv>
        <MomentTypeSelectItemDiv
          onClick={() => setBrowsingMode(BrowsingMode.VALORANT)}
          $selected={browsingMode === BrowsingMode.VALORANT}
        >
          <ValorantIcon />
        </MomentTypeSelectItemDiv>
        <MomentTypeSelectItemDiv
          onClick={() => setBrowsingMode(BrowsingMode.MULTI)}
          $selected={browsingMode === BrowsingMode.MULTI}
        >
          <MultiIcon />
        </MomentTypeSelectItemDiv>
      </MomentTypeSelectDiv>
      <Simplebar style={simplebarStyles}>
        {browsingMode === BrowsingMode.CHAT ? (
          <ChatSidebar moments={chatMoments} />
        ) : null}
        {browsingMode === BrowsingMode.VALORANT ? (
          <ValorantSidebar videoId={videoId} />
        ) : null}
        {browsingMode === BrowsingMode.MULTI ? (
          <MultiMomentsSidebar videoId={videoId} />
        ) : null}
      </Simplebar>
    </div>
  )
}
