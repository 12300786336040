import { useCallback, useEffect, useMemo } from 'react'

import { useGetEditingVideoQuery } from '__generated__'
import { SyncStatus, useEditorState, useEditorUpdater } from './context'

export const useEditor = () => {
  const { playing, status, selection } = useEditorState()
  const dispatch = useEditorUpdater()

  const seekTo = useCallback(
    (targetTime: number, playWhenReady = false) => {
      dispatch({
        type: useEditorUpdater.ACTION_TYPES.SEEK_TO,
        payload: {
          targetTime,
        },
      })
      if (playWhenReady) {
        dispatch({
          type: useEditorUpdater.ACTION_TYPES.SET_PLAY_WHEN_READY,
          payload: {
            playWhenReady,
          },
        })
      }
    },
    [dispatch]
  )

  // Uses diff time from current time in ms
  const relativeSeekTo = useCallback(
    (timeDiff: number, playWhenReady = false) => {
      dispatch({
        type: useEditorUpdater.ACTION_TYPES.SEEK_RELATIVE,
        payload: {
          timeDiff,
        },
      })
      if (playWhenReady) {
        dispatch({
          type: useEditorUpdater.ACTION_TYPES.SET_PLAY_WHEN_READY,
          payload: {
            playWhenReady,
          },
        })
      }
    },
    [dispatch]
  )

  const play = useCallback(() => {
    dispatch({
      type: useEditorUpdater.ACTION_TYPES.SET_PLAYING,
      payload: { playing: true },
    })
  }, [dispatch])

  const pause = useCallback(() => {
    dispatch({
      type: useEditorUpdater.ACTION_TYPES.SET_PLAYING,
      payload: { playing: false },
    })
  }, [dispatch])

  const ready = useMemo(() => {
    return status === SyncStatus.Synced
  }, [status])

  const setSelection = useCallback(
    (selection: { startTime: number; endTime: number }) => {
      dispatch({
        type: useEditorUpdater.ACTION_TYPES.SET_SELECTION,
        payload: {
          selection,
        },
      })
    },
    [dispatch]
  )

  const restartSelection = useCallback(() => {
    if (selection) {
      seekTo(selection.startTime)
    }
  }, [seekTo, selection])

  const setPlayWhenReady = useCallback(
    (nextPlayWhenReady: boolean) => {
      dispatch({
        type: useEditorUpdater.ACTION_TYPES.SET_PLAY_WHEN_READY,
        payload: {
          playWhenReady: nextPlayWhenReady,
        },
      })
    },
    [dispatch]
  )

  const seekToCurrentTime = useCallback(
    (key: string) => {
      dispatch({
        type: useEditorUpdater.ACTION_TYPES.SEEK_TO_CURRENT_TIME,
        payload: {
          videoKey: key,
        },
      })
    },
    [dispatch]
  )

  const setOffsets = useCallback(
    (nextOffsets: { [relatedVideoId: string]: number }) => {
      dispatch({
        type: useEditorUpdater.ACTION_TYPES.SET_OFFSETS,
        payload: {
          offsets: nextOffsets,
        },
      })
    },
    [dispatch]
  )

  // a helper that assigns any elements passed
  const assignOffsets = useCallback(
    (nextOffsets: { [relatedVideoId: string]: number }) => {
      dispatch({
        type: useEditorUpdater.ACTION_TYPES.ASSIGN_OFFSETS,
        payload: {
          offsets: nextOffsets,
        },
      })
    },
    [dispatch]
  )

  return useMemo(
    () => ({
      seekTo,
      restartSelection,
      setOffsets,
      assignOffsets,
      play,
      pause,
      playing,
      ready,
      setSelection,
      setPlayWhenReady,
      seekToCurrentTime,
      relativeSeekTo,
    }),
    [
      seekTo,
      restartSelection,
      setOffsets,
      assignOffsets,
      play,
      pause,
      playing,
      ready,
      setSelection,
      setPlayWhenReady,
      seekToCurrentTime,
      relativeSeekTo,
    ]
  )
}

// The main idea here is cascading offsets
// 1. related video offset
// 2. editor's context offsets (custom setting)
export const useEditorOffsets = () => {
  const { primaryVideoId, offsets } = useEditorState()
  const { data } = useGetEditingVideoQuery({
    variables: { id: primaryVideoId ?? '' },
    skip: primaryVideoId === undefined,
    errorPolicy: 'all',
  })

  const offsetsById = useMemo(() => {
    const nextOffsetById =
      data?.video?.relatedVideos.reduce((memo, { offset, video: { id } }) => {
        memo[id] = offset
        return memo
      }, {}) ?? {}
    return nextOffsetById
  }, [data?.video?.relatedVideos]) as { [videoId: string]: number }

  return useMemo(
    () => ({
      ...offsetsById,
      ...offsets,
    }),
    [offsets, offsetsById]
  ) as { [videoId: string]: number }
}

export const useEditorController = () => {
  const { videoStatuses, playWhenReady } = useEditorState()
  const { play, ready, setPlayWhenReady } = useEditor()
  const dispatch = useEditorUpdater()

  // Manage overall state for any type of play button needed
  useEffect(() => {
    const synced = Object.values(videoStatuses).every(
      (vidStatus) => vidStatus.syncStatus === SyncStatus.Synced
    )
    if (synced) {
      dispatch({
        type: useEditorUpdater.ACTION_TYPES.SET_SYNC_STATUS,
        payload: {
          status: SyncStatus.Synced,
        },
      })
    }
  }, [videoStatuses, dispatch])

  useEffect(() => {
    if (playWhenReady && ready) {
      // setTimeout(() => {
      play()
      setPlayWhenReady(false)
      // }, 100)
    }
  }, [playWhenReady, play, ready, setPlayWhenReady])
}
