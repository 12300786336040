import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  AdminTwitchUserSourceFragment,
  useGetTwitchUserSourceLazyQuery,
} from '__generated__'

import { Button } from 'components/core/button'
import { Card, Spacing } from 'components/core/layout'
import { Text } from 'components/core/text'

import { TwitchUserCard } from './twitch-user-card'

type Props = {
  name: string
}

export const SearchUserCard = ({ name }: Props) => {
  const { loading, result, execute, called } = useGetTwitchUser(name)

  return (
    <Card body>
      <Spacing bottom={16}>
        <Text variant="text-3">
          No channel found for "{name}". Do you want to search twitch for this
          user?
        </Text>
      </Spacing>
      <Button
        variant="secondary"
        isLoading={loading}
        disabled={called}
        onClick={execute}
      >
        Search on twitch
      </Button>
      {result && (
        <Spacing top={16}>
          <TwitchUserCard user={result} />
        </Spacing>
      )}
    </Card>
  )
}

const useGetTwitchUser = (name: string) => {
  const [result, setResult] = useState<AdminTwitchUserSourceFragment | null>(
    null
  )
  const [loading, setLoading] = useState(false)
  const [called, setCalled] = useState(false)

  const [fetch, { data, called: queryCalled, loading: queryLoading }] =
    useGetTwitchUserSourceLazyQuery({ fetchPolicy: 'no-cache' })

  useEffect(() => {
    setLoading(false)
    setCalled(false)
    setResult(null)
  }, [name])

  useEffect(() => {
    setResult(data?.twitchUserSourceByName ?? null)
  }, [data?.twitchUserSourceByName])

  const execute = useCallback(() => {
    fetch({ variables: { name } })
  }, [fetch, name])

  useEffect(() => {
    setLoading(queryLoading)
  }, [queryLoading])

  useEffect(() => {
    setCalled(queryCalled)
  }, [queryCalled])

  return useMemo(
    () => ({
      called,
      loading,
      execute,
      result,
    }),
    [result, execute, loading, called]
  )
}
