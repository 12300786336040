import { useTheme } from 'styled-components/macro'
import momentjs from 'moment'

import { Container, HStack, Spacing, VStack } from 'components/core/layout'
import { Text } from 'components/core/text'
import { ReactComponent as StarFullIcon } from 'icons/svgs/solid/star.svg'
import { ReactComponent as ErrorIcon } from 'icons/svgs/light/sync-alt.svg'

import {
  ConnectedMomentInfoDiv,
  GridItemChannelImg,
  GridItemContainer,
  GridItemImageContainer,
  GridItemInfo,
  GridItemThumbnailImg,
  SyncedIndicatorDiv,
  UnreadIndicatorDiv,
} from './styled'
import { uppercaseFirstLetter } from 'utils/strings'
import { AGENT_THUMB_IMAGES } from 'components/valorant/agent'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'

type Props = {
  thumbnail?: string
  channel: {
    id: string
    name: string
    profileImageUrl?: string
  }
  map: string
  agent: string
  combatScore: number
  kills: number
  synced: boolean
  startsAt: string
  matchUrl?: string
  rating?: number
  editAspectRatios?: string[]
  unread?: boolean
  onClick?: () => void
  hideChannel?: boolean // used for channel pages
}

export const GridItem = ({
  thumbnail,
  channel,
  agent,
  kills,
  map,
  synced,
  onClick,
  unread,
  editAspectRatios,
  rating,
  startsAt,
  matchUrl,
  hideChannel = false,
}: Props) => {
  const theme = useTheme()

  const readableTime = useMemo(() => {
    return new Date(startsAt).toLocaleString()
  }, [startsAt])
  return (
    <GridItemContainer onClick={onClick}>
      <GridItemImageContainer>
        <GridItemThumbnailImg src={thumbnail} alt="" />
        <ConnectedMomentInfoDiv>
          <VStack alignItems="flex-end" gap={8}>
            {editAspectRatios && (
              <HStack alignItems="center" gap={4}>
                {editAspectRatios?.map((ratio) => (
                  <div
                    key={ratio}
                    style={{
                      border: '1px solid white',
                      borderRadius: 4,
                      padding: '2px 4px',
                      backgroundColor: '#666',
                    }}
                  >
                    <Text variant="small" color="white">
                      {ratio}
                    </Text>
                  </div>
                ))}
              </HStack>
            )}
            {rating && (
              <div
                style={{
                  border: '1px solid white',
                  borderRadius: 4,
                  padding: '4px 8px',
                  backgroundColor: '#666',
                }}
              >
                <HStack alignItems="center" gap={4}>
                  <Text variant="text-3" mono color="white">
                    {rating}
                  </Text>
                  <StarFullIcon width={12} height={12} fill="white" />
                </HStack>
              </div>
            )}
          </VStack>
        </ConnectedMomentInfoDiv>
      </GridItemImageContainer>
      {hideChannel ? (
        <GridItemInfo>
          <VStack>
            <Spacing bottom="4px">
              <HStack justifyContent="space-between" alignItems="center">
                <Container direction="row" alignItems="center">
                  <GridItemChannelImg src={AGENT_THUMB_IMAGES[agent]} />
                  <Spacing left="8px">
                    <VStack>
                      <HStack alignItems="center" gap={8}>
                        <Text
                          variant="text-2"
                          weight="semibold"
                          color={theme.colors.text.primary}
                        >
                          {kills} kill{kills > 1 && 's'}
                        </Text>
                        {unread ? <UnreadIndicatorDiv /> : null}
                      </HStack>
                      <Text
                        variant="text-3"
                        color={theme.colors.text.secondary}
                      >
                        {uppercaseFirstLetter(map)}
                      </Text>
                    </VStack>
                  </Spacing>
                </Container>
                <Container alignItems="flex-end">
                  <HStack alignItems="center" gap={8}>
                    {!synced ? (
                      <Link to={matchUrl ?? '#'}>
                        <SyncedIndicatorDiv title="Match Sync Issue">
                          <ErrorIcon />
                        </SyncedIndicatorDiv>
                      </Link>
                    ) : null}
                    <time dateTime={startsAt} title={readableTime}>
                      <Text variant="text-3" mono>
                        {momentjs(startsAt).format('LT')}
                      </Text>
                    </time>
                  </HStack>
                </Container>
              </HStack>
            </Spacing>
          </VStack>
        </GridItemInfo>
      ) : (
        <GridItemInfo>
          <VStack>
            <Spacing bottom="4px">
              <HStack justifyContent="space-between" alignItems="center">
                <Container direction="row" alignItems="center">
                  <GridItemChannelImg src={channel.profileImageUrl} />
                  <Spacing left="8px">
                    <VStack>
                      <HStack alignItems="center" gap={8}>
                        <Text
                          variant="text-2"
                          weight="semibold"
                          color={theme.colors.text.primary}
                        >
                          {channel.name}
                        </Text>
                        {unread ? <UnreadIndicatorDiv /> : null}
                      </HStack>
                      <Text
                        variant="text-3"
                        color={theme.colors.text.secondary}
                      >
                        {uppercaseFirstLetter(agent)}
                      </Text>
                    </VStack>
                  </Spacing>
                </Container>
                <HStack alignItems="center" gap={8}>
                  {!synced ? (
                    <Link to={matchUrl ?? '#'}>
                      <SyncedIndicatorDiv title="Match Sync Issue">
                        <ErrorIcon />
                      </SyncedIndicatorDiv>
                    </Link>
                  ) : null}
                  <time dateTime={startsAt} title={readableTime}>
                    <Text variant="text-3" mono>
                      {momentjs(startsAt).format('LT')}
                    </Text>
                  </time>
                </HStack>
              </HStack>
            </Spacing>

            <HStack justifyContent="space-between">
              <Text variant="text-2">{uppercaseFirstLetter(map)}</Text>
              <Text variant="text-2">{kills} kills</Text>
            </HStack>
          </VStack>
        </GridItemInfo>
      )}
    </GridItemContainer>
  )
}
