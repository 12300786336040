import { useEffect, useRef } from 'react'
import { isPlainObject, isArray } from 'lodash'

export function usePrevious<T>(value: T) {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export function cloneWithoutTypename<T>(obj: T): Omit<T, '__typename'> {
  if (!isPlainObject(obj)) {
    return obj
  }
  let result: any = {}
  for (let key in obj) {
    if (key === '__typename') {
      continue
    }
    let value = (obj as any)[key]
    if (isPlainObject(value)) {
      result[key] = cloneWithoutTypename(value)
    } else if (isArray(value)) {
      result[key] = value.map(cloneWithoutTypename)
    } else {
      result[key] = value
    }
  }
  return result
}

export function randomUniqueId() {
  return (Math.random() + 1).toString(36).substring(2)
}
