import styled from 'styled-components/macro'

export const DarkModeListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ProfileSubMenu = styled.ul`
  position: absolute;
  top: 0;
  right: 0;
  min-width: 230px;
  padding: 16px;
  margin-right: -16px;
  margin-top: -16px;

  background-color: ${({ theme }) => theme.colors.background.primary};
  box-shadow: ${({ theme }) => theme.elevation.depth3},
    0 0 0 1px ${({ theme }) => theme.colors.neutrals.n6};
  border-radius: 16px;
  opacity: 0;
  visibility: hidden;
  z-index: 2;

  transition: opacity var(--common-animation-duration);

  & > li {
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 8px;
    }
  }
`

export const ProfileMenuContainer = styled.div`
  position: relative;

  &:hover ${ProfileSubMenu} {
    visibility: visible;
    opacity: 1;
  }
`

export const ProfileSubMenuSeparator = styled.li`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.neutrals.n6};
`

export const RegionListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`
