import styled from 'styled-components/macro'

export const VideoMomentViewerDiv = styled.div`
  width: 100%;
  height: calc(100vh - 118px - 32px);
`

export const MomentListContainerDiv = styled.div`
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, 256px);
  grid-template-rows: repeat(auto-fill, 256px);
  grid-row-gap: 20px;
  grid-column-gap: 20px;
`

export const ContainerDiv = styled.div`
  position: relative;
  width: 256px;
  height: 256px;
`

export const MomentPreviewImageDiv = styled.div<{
  $backgroundImageUrl: string
}>`
  width: 100%;
  height: 100%;
  transition: transform 1s var(--common-ease-out);
  transform: scale(1.000001);
  background-image: url(${({ $backgroundImageUrl }) => $backgroundImageUrl});
  background-size: cover;
`

export const MomentPreviewContainerDiv = styled.div`
  position: relative;
  width: 256px;
  height: ${(256 * 9) / 16}px;
  border: 1px solid black;
  overflow: hidden;
  border-radius: 16px;
  cursor: pointer;

  &:hover > ${MomentPreviewImageDiv} {
    transform: scale(1.1);
  }
`

export const MomentTitleWrapper = styled.div`
  margin-top: 8px;

  & > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
