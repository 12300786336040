import { useMemo } from 'react'

import { useEditorState } from 'core/editor/context'
import { QuickCreateAndPublishButton } from 'core/shared/moments/create-and-publish/button'

interface Props {
  title: string
}

export const CreateMultiMomentHighlight = ({ title }: Props) => {
  const { videoIds } = useEditorState()

  const videoId = useMemo(() => {
    return videoIds[0] ?? undefined
  }, [videoIds])

  return videoId ? (
    <QuickCreateAndPublishButton videoId={videoId} title={title} />
  ) : null
}
