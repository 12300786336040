import React, { useEffect, useMemo } from 'react'
import { sum, uniq } from 'lodash'
import { Link } from 'react-router-dom'

import {
  FeedPreviewFragment,
  useGetFeedValorantSignalQuery,
} from '__generated__'
import { Spinner } from 'components/core/spinner'
import { GridItem } from 'core/feed/components/grid/grid-item'
import { Button } from 'components/core/button'
import { Container, HStack, Spacing } from 'components/core/layout'
import { FeedType, useFeedState } from 'core/feed/context'
import { bucketizeFeedByDay, useFeedHelpers } from 'core/feed/helpers'
import { useFeed } from 'core/feed/controller'
import { translateRatingEnum } from 'core/signal-review/helpers'
import { useQueryParamFilters } from 'utils/browser'

import { GridContainer, GridSeparator } from './styles'
import {
  MomentThumbnailProvider,
  MomentThumbnailConsumer,
} from './components/moment-thumbnail-provider'

interface Props {
  additionalFilters?: { [key: string]: string | { [key: string]: string } }
  openReview?: (signalId: string) => void
  hideChannel?: boolean
}

export const ValorantFeed = ({
  additionalFilters,
  hideChannel = false,
}: Props) => {
  const { type } = useFeedState()
  const { data, loading } = useFeed(additionalFilters)
  const { params, setParam } = useQueryParamFilters()
  const buckets = useBucketFeed(data?.moments?.data, type)

  const currentPage = useMemo(() => {
    const pageStr = params['page']
    return pageStr && typeof pageStr === 'string' ? parseInt(pageStr, 10) : 0
  }, [params])

  return (
    <Spacing bottom={32}>
      {loading && (
        <Spacing vertical={32}>
          <HStack justifyContent="center" fullWidth>
            <Spinner size={48} />
          </HStack>
        </Spacing>
      )}
      {buckets &&
        buckets.map((bucket) => {
          return (
            <React.Fragment key={bucket.label}>
              <GridSeparator>{bucket.label}</GridSeparator>
              <GridContainer>
                {bucket.data.map((moment) => (
                  <Link
                    key={moment.id}
                    to={{
                      pathname: `/review/${moment.id}`,
                      state: { referrer: 'feed' },
                    }}
                  >
                    <MomentGridItem
                      momentId={moment.id}
                      hideChannel={hideChannel}
                      // onClick={() => openReview(moment.id)}
                    />
                  </Link>
                ))}
              </GridContainer>
            </React.Fragment>
          )
        })}
      <Container direction="row" justifyContent="center" gap={8}>
        <Button
          onClick={() => {
            const nextPage = currentPage - 1
            setParam('page', nextPage === 0 ? undefined : nextPage.toString())
          }}
          variant={currentPage === 0 || loading ? 'secondary' : 'primary'}
          disabled={currentPage === 0 || loading}
          isLoading={loading}
        >
          Prev Page
        </Button>
        <Button
          onClick={() => setParam('page', (currentPage + 1).toString())}
          disabled={!data?.moments?.pagination.hasNextPage || loading}
          variant={
            !data?.moments?.pagination.hasNextPage || loading
              ? 'secondary'
              : 'primary'
          }
          isLoading={loading}
        >
          Next Page
        </Button>
      </Container>
      {/* <RootOnly>
        <SyncPlaylist />
      </RootOnly> */}
    </Spacing>
  )
}

export const useBucketFeed = (
  data: FeedPreviewFragment[] | undefined,
  type: FeedType
) => {
  const filteredMoments = useMemo(() => {
    if (type === FeedType.ValorantSignal) {
      return data?.filter(
        (moment) =>
          moment.__typename === 'ValorantMoment' &&
          moment.video.stream?.endedAt !== null
      ) as FeedPreviewFragment[] | undefined
    } else {
      return data
    }
  }, [data, type])

  return useMemo(
    () =>
      filteredMoments
        ? bucketizeFeedByDay<FeedPreviewFragment>(filteredMoments)
        : [],
    [filteredMoments]
  )
}

interface MomentGridItemProps {
  momentId: string
  onClick?: () => void
  hideChannel?: boolean
}

export const MomentGridItem = ({
  momentId,
  onClick,
  hideChannel = false,
}: MomentGridItemProps) => {
  const { setMatchVideoSync } = useFeedHelpers()
  const { data } = useGetFeedValorantSignalQuery({
    variables: { signalId: momentId },
  })
  const moment = useMemo(() => {
    if (data?.moment && data.moment.__typename === 'ValorantMoment') {
      return data?.moment
    }
  }, [data?.moment])

  const accurateKillCount = useMemo(
    () =>
      moment?.kills.filter((kill) => {
        return kill.killer?.account.id !== kill.victim?.account.id
      }),
    [moment?.kills]
  )

  const ratings = useMemo(() => {
    if (data?.moment && data.moment.__typename === 'ValorantMoment') {
      const connectedMoment = data.moment.usedIn[0]
      if (!connectedMoment) return undefined
      const allRatings = connectedMoment.ratings.map(({ rating }) =>
        translateRatingEnum(rating)
      )
      return sum(allRatings) / allRatings.length
    }
  }, [data?.moment])

  const editsAspectRatios = useMemo(() => {
    if (data?.moment && data.moment.__typename === 'ValorantMoment') {
      const connectedMoment = data.moment.usedIn[0]
      if (!connectedMoment) return undefined
      const allFormats = connectedMoment.edits.map(
        ({ format }) =>
          `${format.aspectRatio.width}x${format.aspectRatio.height}`
      )
      return uniq(allFormats)
    }
  }, [data?.moment])

  const matchUrl = useMemo(() => {
    return `/channel/${moment?.channel.name}/matches/${moment?.match.id}`
  }, [moment?.channel.name, moment?.match.id])

  useEffect(() => {
    if (moment) {
      const matchId = moment.match.id
      const videoId = moment.video.id
      // some of this stuff is just needed to load up match player
      const matchStart = moment.match.startedAt
      const userId = moment.channel.id
      const synced = moment.match.videoSyncData.find(
        ({ video }) => video.id === videoId
      )
      setMatchVideoSync({
        matchId,
        videoId,
        matchStart,
        userId,
        synced: synced !== undefined,
      })
    }
  }, [moment, setMatchVideoSync])

  return moment ? (
    <MomentThumbnailProvider id={moment.id} key={moment.id}>
      <MomentThumbnailConsumer>
        {({ thumbnail }) => (
          <GridItem
            onClick={onClick}
            channel={{
              id: moment.channel.id,
              name: moment.channel.name,
              profileImageUrl: moment.channel.profileImageUrl || '',
            }}
            agent={moment.agent}
            kills={accurateKillCount?.length ?? 0}
            matchUrl={matchUrl}
            combatScore={moment.combatScore}
            map={moment.match.map || ''}
            thumbnail={thumbnail}
            synced={
              moment.match.videoSyncData.findIndex(
                (sync) => sync.video.id === moment.video.id
              ) >= 0
            }
            startsAt={moment.startsAt}
            rating={ratings}
            editAspectRatios={editsAspectRatios}
            unread={moment.isUnread}
            hideChannel={hideChannel}
          />
        )}
      </MomentThumbnailConsumer>
    </MomentThumbnailProvider>
  ) : null
}
