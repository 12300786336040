import React, { useCallback, useMemo, useState } from 'react'
import styled, { useTheme } from 'styled-components/macro'
import { AlertIcon } from '@primer/octicons-react'

import { Button } from 'components/core/button'
import { Card, Container } from 'components/core/layout'
import { Text } from 'components/core/text'
import { useCollectionContext } from 'core/collections/collections-context-provider'
import { TextField } from 'components/core/textfield'
import { useUpdatePartialMomentsInCollectionMutation } from '__generated__'
import { useRouteMatch } from 'react-router'

export const TitleSettings = () => {
  const theme = useTheme()

  const { title, url, onChange, onSubmit, loading, disabled } =
    useTitleSettings()

  return (
    <Card>
      <Card.Body>
        <Card.Title>Title</Card.Title>
        <Card.Text>
          <Container gap={16}>
            <StyledTextField onChange={onChange} value={title} />
            <StyledTextField onChange={onChange} disabled value={url} />
          </Container>
        </Card.Text>
      </Card.Body>

      <Card.Footer>
        <Container direction="row" justifyContent="space-between">
          <Container direction="row" gap={8} alignItems="center">
            <AlertIcon fill={theme.colors.neutrals.n4} />
            <Text variant="text-3" color={theme.colors.neutrals.n4}>
              Updating the title will break the public link
            </Text>
          </Container>
          <div>
            <Button
              size="small"
              onClick={onSubmit}
              disabled={disabled}
              isLoading={loading}
            >
              Save
            </Button>
          </div>
        </Container>
      </Card.Footer>
    </Card>
  )
}

const slugify = (value: string) => {
  return value
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .replace(/[^a-z0-9\\-_]+/gi, '-')
    .replace(/(\\-+$|^\\-)/, '')
}

const useTitleSettings = () => {
  const { selected: collection } = useCollectionContext()
  const { params } = useRouteMatch<{ organization: string }>()

  const [mutation, { loading }] = useUpdatePartialMomentsInCollectionMutation()

  const [title, setTitle] = useState(collection?.title || '')
  const [slug, setSlug] = useState(collection?.slug || '')

  const onChange = useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
    const title = evt.target.value
    setTitle(title)
    setSlug(slugify(title))
  }, [])

  const url = `https://mmnts.live/p/collection/${params.organization}/${slug}`

  const onSubmit = useCallback(() => {
    if (!collection) {
      return
    }
    mutation({ variables: { title, slug, collectionId: collection.id } })
  }, [title, slug, mutation, collection])

  return useMemo(
    () => ({
      title,
      slug,
      url,
      onChange,
      onSubmit,
      loading,
      disabled:
        loading || (title === collection?.title && slug === collection.slug),
    }),
    [title, url, onChange, onSubmit, slug, loading, collection]
  )
}

const StyledTextField = styled(TextField)`
  width: 60%;
`
