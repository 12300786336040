import { Content, HStack, VStack } from 'components/core/layout'
import { Text } from 'components/core/text'
import { useMemo, useState } from 'react'
import {
  LeaderboardPlayerFragment,
  useGetValorantLeaderboardSnapshotQuery,
} from '__generated__'
import channelIds from '../channel-ids.json'
import { sortBy } from 'lodash'
import { LeaderboardItem } from './leaderboard-item'
import { TimeRange, TimeRangePicker } from './timerange-picker'
import { Select } from 'components/core/select'

type SortTypes = 'startRank' | 'endRank' | 'rankChange' | 'numWins'

export const LeaderboardMoves = () => {
  const [sort, setSort] = useState<SortTypes>('rankChange')
  const [timeRange, setTimeRange] = useState<TimeRange>({
    startDate: new Date('2022-11-01T00:00:00.000Z'),
    endDate: new Date('2022-12-01T00:00:00.000Z'),
  })

  const data = useGetValorantBiggestRankGains(
    timeRange.startDate.toISOString(),
    timeRange.endDate.toISOString(),
    sort
  )
  return (
    <Content>
      <HStack justifyContent={'center'}>
        <VStack gap={12}>
          <Text variant="h4">Ascending Gods</Text>
          <TimeRangePicker
            initialStart={timeRange.startDate}
            initialEnd={timeRange.endDate}
            onChange={(timeRange) => setTimeRange(timeRange)}
          />
          <Select onChange={(e) => setSort(e.target.value as SortTypes)}>
            <option value="startRank">Start Rank</option>
            <option value="endRank">End Rank</option>
            <option value="rankChange">Rank Change</option>
            <option value="numWins">Wins</option>
          </Select>

          <VStack gap={24}>
            {data.map((player) => (
              <LeaderboardItem
                player={player}
                start={timeRange.startDate.toISOString()}
                end={timeRange.endDate.toISOString()}
              />
            ))}
          </VStack>
        </VStack>
      </HStack>
    </Content>
  )
}

const useGetValorantBiggestRankGains = (
  start: string,
  end: string,
  sortType: SortTypes
) => {
  const { data: startData } = useGetValorantLeaderboardSnapshotQuery({
    variables: { snapshotTime: start, twitchUserIds: channelIds },
  })

  const { data: endData } = useGetValorantLeaderboardSnapshotQuery({
    variables: { snapshotTime: end, twitchUserIds: channelIds },
  })

  const startMapByAccountId = useMemo(() => {
    return startData?.valorantLeaderboard?.data.reduce((acc, cur) => {
      acc[cur.account?.id ?? ''] = cur
      return acc
    }, {} as Record<string, LeaderboardPlayerFragment>)
  }, [startData])

  const endMapByAccountId = useMemo(() => {
    return endData?.valorantLeaderboard?.data.reduce((acc, cur) => {
      acc[cur.account?.id ?? ''] = cur
      return acc
    }, {} as Record<string, LeaderboardPlayerFragment>)
  }, [endData])

  return useMemo(() => {
    if (!startMapByAccountId || !endMapByAccountId) return []
    const results = Object.values(startMapByAccountId ?? {}).reduce(
      (acc, cur) => {
        const endRank =
          endMapByAccountId?.[cur.account?.id ?? '']?.leaderboardRank
        const rankChange = cur.leaderboardRank - endRank
        if (endRank !== undefined && rankChange > 0)
          acc.push({
            start: cur,
            rankChange,
            end: endMapByAccountId?.[cur.account?.id ?? ''],
            wins:
              endMapByAccountId?.[cur.account?.id ?? '']?.numberOfWins -
              startMapByAccountId?.[cur.account?.id ?? '']?.numberOfWins,
          })
        return acc
      },
      [] as {
        start: LeaderboardPlayerFragment
        end: LeaderboardPlayerFragment
        rankChange: number
        wins: number
      }[]
    )
    return sortBy(results, (item) => {
      switch (sortType) {
        case 'startRank':
          return item.start.leaderboardRank
        case 'endRank':
          return item.end?.leaderboardRank ?? 0
        case 'rankChange':
          return -item.rankChange
        case 'numWins':
          return -item.wins
        default:
          return item.rankChange
      }
    })
  }, [startMapByAccountId, endMapByAccountId, sortType])
}
