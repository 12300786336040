import React from 'react'

import { AGENT_THUMB_IMAGES } from 'components/valorant/agent'

import { PlayerInfo } from './match-preview'
import {
  AgentIconContainerDiv,
  MainPlayerContainerDiv,
  MainPlayerStatsDiv,
} from './styled'

interface PreviewPlayerProps {
  playerInfo: PlayerInfo
}

export const PreviewPlayer: React.FC<PreviewPlayerProps> = ({ playerInfo }) => {
  return (
    <MainPlayerContainerDiv $team={playerInfo.team}>
      <AgentIconContainerDiv>
        <img alt="agent thumbnail" src={AGENT_THUMB_IMAGES[playerInfo.agent]} />
      </AgentIconContainerDiv>
      <MainPlayerStatsDiv>
        <h3>{playerInfo.gameName}</h3>
        <div>
          {playerInfo.stats.kills} / {playerInfo.stats.deaths} /{' '}
          {playerInfo.stats.assists}
        </div>
      </MainPlayerStatsDiv>
    </MainPlayerContainerDiv>
  )
}
