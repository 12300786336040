import { ProtectedRoute } from 'core/auth/protected-route'
import { FeedProvider } from 'core/feed/context'
import { CampaignsPage } from 'page/campaign'
import { CampaignPage } from 'page/campaign/detail'
import { CollectionSettingsPage, CollectionsPage } from 'page/collections'
import { FeedPage } from 'page/feed'
import { MomentCollectionPage } from 'page/moment-collection'
import { Switch, Route, useRouteMatch, Redirect } from 'react-router'
import { Unauthorized } from './unauthorized'
import { useAuthorizedOrganization } from './useOrganization'
import { ContentPage } from 'page/content'

export const OrganizationPage = () => {
  const { authorized, loading } = useAuthorizedOrganization()
  const { path, url } = useRouteMatch()
  if (loading) {
    return <></>
  }

  if (!authorized) {
    return <Unauthorized />
  }

  return (
    <Switch>
      <Route exact path={`${path}/campaigns`}>
        <Redirect to={url} />
      </Route>
      <Route path={`${path}/campaigns/:campaignSlug`}>
        <CampaignPage />
      </Route>

      <Route
        path={[
          `${path}/collections`,
          `${path}/collections/:momentCollectionId`,
        ]}
        exact
      >
        <CollectionsPage />
      </Route>

      <Route path={`${path}/collections/:momentCollectionId/settings`} exact>
        <CollectionSettingsPage />
      </Route>
      <Route path={`${path}/collections/:momentCollectionId/viewer`}>
        <MomentCollectionPage />
      </Route>

      <ProtectedRoute path={`${path}/feed`}>
        <FeedProvider>
          <FeedPage />
        </FeedProvider>
      </ProtectedRoute>
      <ProtectedRoute path={`${path}/content`}>
        <FeedProvider>
          {/* <FeedController /> */}
          <ContentPage />
        </FeedProvider>
      </ProtectedRoute>

      <Route exact path={`${path}/`}>
        <CampaignsPage />
      </Route>
    </Switch>
  )
}
