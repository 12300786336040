import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { up } from 'styled-breakpoints'
import { Helmet } from 'react-helmet'
import { useBreakpoint } from 'styled-breakpoints/react-styled'

import { PostBuyEditFragment } from '__generated__'
import { Text } from 'components/core/text'
import { HStack, Spacing } from 'components/core/layout'
import { StreamerAvatar } from 'components/streamer-avatar'
import { useQuery } from 'utils/browser'
import { useAppTheme } from 'theme/useDarkMode'

import LoadedImg from './loaded.png'
import AmericasCardroomImg from './americas-cardroom.png'
import MetaImage from './meta-image.png'
import { PostBuyModal } from './modal/modal'
import { EditModalContent } from './modal/edit-modal-content'
import {
  ActivationHeadingDiv,
  HorizontalCollectionContainerDiv,
  MomentPreviewHorizontalDiv,
  MomentPreviewVerticalDiv,
  PostBuyPageDiv,
  StreamerAvatarWrapperDiv,
  StreamerCreatorListDiv,
  VerticalCollectionContainerDiv,
} from './styled'
import {
  SECTIONS_WITH_TITLES,
  STREAMERS_IDS,
  STREAMER_ICONS,
} from './moment-edits-data'
import STATIC_DATA from './data.json'
import { EditDisplay } from './item'

export const AmericasCardroomPostBuyPage = () => {
  const { push, location } = useHistory()
  const [muted, setMuted] = useState(true)
  const [hoveredVideo, setHoveredVideo] = useState<{
    section: string
    editId: string
  }>()
  const { setTheme, theme } = useAppTheme()
  const initialTheme = useRef(theme)
  const search = useQuery()
  const selectedEdit = search.get('v')

  const isLarge = useBreakpoint(up('md'))

  useEffect(() => {
    const initTheme = initialTheme.current
    setTheme('dark')
    return () => {
      initTheme && setTheme(initTheme)
    }
  }, [setTheme])

  return (
    <>
      <Helmet>
        <title>MMNTS - Americas Cardroom Event</title>
        <meta property="og:title" content="MMNTS - Americas Cardroom Event" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://mmnts.live/events/americas-cardroom"
        />
        <meta property="og:image" content={MetaImage} />
      </Helmet>
      <PostBuyPageDiv>
        <ActivationHeadingDiv>
          <img
            src={AmericasCardroomImg}
            alt=""
            style={{ height: isLarge ? 100 : 60 }}
          ></img>
          <Text variant={isLarge ? 'h2' : 'h4'}>x</Text>
          <img
            src={LoadedImg}
            alt=""
            style={{ height: isLarge ? 100 : 60 }}
          ></img>
        </ActivationHeadingDiv>
        <StreamerCreatorListDiv>
          {STREAMERS_IDS.map((streamerId) =>
            STREAMER_ICONS[streamerId] ? (
              <StreamerAvatar
                key={streamerId}
                height={60}
                width={60}
                profileImageUrl={STREAMER_ICONS[streamerId]}
              />
            ) : null
          )}
        </StreamerCreatorListDiv>

        {SECTIONS_WITH_TITLES.map(({ title, edits, icon }) => {
          const editData = edits.map(
            (editId) => STATIC_DATA[editId]
          ) as PostBuyEditFragment[]
          const AspectRatioDiv =
            editData[0].format.aspectRatio.width === 16
              ? MomentPreviewHorizontalDiv
              : MomentPreviewVerticalDiv
          const AspectRatioContainerDiv =
            editData[0].format.aspectRatio.width === 16
              ? HorizontalCollectionContainerDiv
              : VerticalCollectionContainerDiv
          return (
            <React.Fragment key={title}>
              <Spacing top={64} bottom={12}>
                <HStack alignItems="center" gap={8}>
                  {icon && (
                    <StreamerAvatarWrapperDiv>
                      <StreamerAvatar
                        width={30}
                        height={30}
                        profileImageUrl={icon}
                      />
                    </StreamerAvatarWrapperDiv>
                  )}
                  <Text variant="h4">{title}</Text>
                </HStack>
              </Spacing>
              <AspectRatioContainerDiv>
                {editData.map((editFrag, i) => (
                  <AspectRatioDiv
                    key={`${editFrag.id}-${title.toLowerCase()}-${i}`}
                    onMouseEnter={() =>
                      isLarge &&
                      setHoveredVideo({ section: title, editId: editFrag.id })
                    }
                    onMouseLeave={() => setHoveredVideo(undefined)}
                    onClick={() => {
                      push(`${location.pathname}?v=${editFrag.id}`)
                    }}
                  >
                    <EditDisplay
                      hovered={
                        hoveredVideo?.editId === editFrag.id &&
                        hoveredVideo.section === title
                      }
                      editFrag={editFrag}
                      muted={muted}
                      setMuted={setMuted}
                    />
                  </AspectRatioDiv>
                ))}
              </AspectRatioContainerDiv>
            </React.Fragment>
          )
        })}

        {selectedEdit && (
          <PostBuyModal>
            <EditModalContent
              editMap={STATIC_DATA as { [key: string]: any }}
              editId={selectedEdit}
            />
          </PostBuyModal>
        )}
      </PostBuyPageDiv>
    </>
  )
}
