import { useState } from 'react'
import styled from 'styled-components/macro'

import { Button } from 'components/core/button'
import { AddToCollectionPopover } from './popover'
import { ButtonSize, ButtonVariant } from 'components/core/button/styled'

interface Props {
  momentId: string
  variant?: ButtonVariant
  size?: ButtonSize
  position?: 'above' | 'below'
}

export const AddToCollectionButton = ({
  momentId,
  variant,
  size,
  position,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <AddToCollectionContainer>
      {isOpen ? (
        <AddToCollectionPopover momentId={momentId} position={position} />
      ) : null}
      <Button
        variant={variant ?? 'primary'}
        onClick={() => setIsOpen(!isOpen)}
        size={size}
      >
        Add To Collection
      </Button>
    </AddToCollectionContainer>
  )
}

const AddToCollectionContainer = styled.div`
  position: relative;
`
