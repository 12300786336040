import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useAppTheme } from 'theme/useDarkMode'
import { CloseButton } from 'core/feed/components/preview-modal/styled'
import { CloseIcon } from 'core/feed/components/preview-modal/close-icon'

import { SignalReviewProvider, SignalTypes } from './context'
import { ReactionSignalReviewPlaylist } from './playlist/reactions'
import { CloseDiv, SignalReviewContainerDiv } from './styled'
import { ReactionSignalReview } from './reaction'
import { ValorantSignalReviewPlaylist } from './playlist/valorant'
import { ValorantMultiKillSignalReview } from './valorant-multi'
import { FeedProvider } from 'core/feed/context'
import { useHistory, useParams } from 'react-router'
import { useGetSignalTypeQuery } from '__generated__'

export type SignalData = { signalType: SignalTypes; signalId: string }

export const SignalReviewRoutePage = () => {
  const { signalId } = useParams<{ signalId: string }>()
  const { data } = useGetSignalTypeQuery({ variables: { signalId } })

  return (
    <FeedProvider>
      {data?.moment && (
        <SignalReviewPage
          initialSignalData={{
            signalId,
            signalType: data.moment.__typename as
              | 'ChatMoment'
              | 'ValorantMoment'
              | 'ManualSignalMoment',
          }}
        />
      )}
    </FeedProvider>
  )
}

type Props = {
  initialSignalData?: SignalData
}

export const SignalReviewPage = ({ initialSignalData }: Props) => {
  const { location, goBack } = useHistory()
  const [signalData, setSignalData] = useState<SignalData | undefined>()
  const { setLocalTheme, theme } = useAppTheme()
  const initialTheme = useRef(theme)

  const hasHistory = useMemo(() => {
    const state = location?.state as { referrer: string } | undefined
    const referrer = state?.referrer as any
    return referrer !== undefined
  }, [location?.state])

  const close = useCallback(() => {
    if (hasHistory) {
      goBack()
    }
  }, [goBack, hasHistory])

  useEffect(() => {
    document.body.classList.add('hidden')
    return () => document.body.classList.remove('hidden')
  }, [])

  // initialize if it was passed in with data
  useEffect(() => {
    setSignalData(initialSignalData)
  }, [initialSignalData])

  useEffect(() => {
    const theme = initialTheme.current
    setLocalTheme('dark')
    return () => {
      theme && setLocalTheme(theme)
    }
  }, [setLocalTheme])

  return (
    <SignalReviewContainerDiv>
      {(initialSignalData?.signalType === 'ChatMoment' ||
        initialSignalData?.signalType === 'ManualSignalMoment') && (
        <ReactionSignalReviewPlaylist
          signalData={signalData}
          setSignalData={setSignalData}
        />
      )}
      {initialSignalData?.signalType === 'ValorantMoment' && (
        <ValorantSignalReviewPlaylist
          signalData={signalData}
          setSignalData={setSignalData}
        />
      )}
      {hasHistory && (
        <CloseDiv>
          <CloseButton onClick={close}>
            <CloseIcon />
          </CloseButton>
        </CloseDiv>
      )}
      {signalData && (
        <SignalReviewProvider key={signalData.signalId} {...signalData}>
          {(signalData.signalType === 'ChatMoment' ||
            signalData.signalType === 'ManualSignalMoment') && (
            <ReactionSignalReview />
          )}
          {signalData.signalType === 'ValorantMoment' && (
            <ValorantMultiKillSignalReview />
          )}
        </SignalReviewProvider>
      )}
    </SignalReviewContainerDiv>
  )
}
