import styled from 'styled-components/macro'

export const MatchContainerDiv = styled.div`
  position: relative;
  border: 1px solid #666;
  border-width: 1px 1px 0 1px;
  margin-top: 24px;
  background-color: #f3f3f3;
  cursor: pointer;

  &:hover {
    border-color: black;
    background-color: #fff;
  }
`

export const MainPlayerContainerDiv = styled.div<{ $team: string }>`
  position: relative;
  width: 100%;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #666;
`

export const AgentIconContainerDiv = styled.div`
  position: relative;
  width: 50px;
  height: 50px;
  flex: 0 0 50px;

  img {
    border: 1px solid #666;
    width: 100%;
    height: 100%;
  }
`

export const MainPlayerStatsDiv = styled.div`
  margin-left: 20px;
  flex: 1;

  h3 {
    margin-bottom: 8px;
    font-size: 14px;
  }

  div {
    font-size: 14px;
    font-weight: 700;
  }
`

export const VsContainerDiv = styled.div`
  text-align: center;
  border-bottom: 1px solid #666;
  font-weight: 700;
  font-size: 14px;
  padding: 8px 0;
`

export const MatchOffsetDiv = styled.div`
  border: 1px solid #666;
  background-color: #f3f3f3;
  margin-top: 2px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    font-size: 12px;
  }

  button {
    cursor: pointer;
    font-size: 12px;
  }
`
