import React, { ReactNode, useCallback, useMemo } from 'react'

import { Rect, TemplateCropSettings } from 'core/exporter/constants'
import { ContentCoverDiv, TemplateEditorPreviewCropDiv } from './styled'
import {
  CropRestrictions,
  useCropDrag,
  useCropResize,
} from 'core/shared/cropping/hooks'
import { CropResizeSquareDiv } from 'core/exporter/template/styled'
import { useComposerHelper } from '../hooks'
import { useComposerState } from '../context'
import { LAYER_COLORS } from '../template-sidebar/option'

interface PreviewCropProps {
  children?: ReactNode
  templateCrop: TemplateCropSettings
  containerRect: DOMRect
}

export const CropContainer = ({
  children,
  templateCrop,
  containerRect,
}: PreviewCropProps) => {
  const { cropSettings } = useComposerState()
  const {
    selected,
    styles,
    selectThisTemplateCrop,
    resizeRef,
    dragRef,
    dragging,
  } = usePreviewCrop(templateCrop, containerRect)

  const selectTemplateCrop = useCallback(
    (evt: React.MouseEvent<HTMLDivElement>) => {
      if (!selected) {
        selectThisTemplateCrop(evt)
      }
    },
    [selectThisTemplateCrop, selected]
  )

  const layerColor = useMemo(
    () => cropSettings[templateCrop.title]?.color ?? LAYER_COLORS[0],
    [cropSettings, templateCrop.title]
  )

  return (
    <TemplateEditorPreviewCropDiv
      ref={dragRef}
      style={styles}
      onClick={selectTemplateCrop}
      $selected={selected}
      $dragging={dragging}
    >
      {children}
      <ContentCoverDiv $selected={selected} $color={layerColor} />
      {selected ? (
        <CropResizeSquareDiv ref={resizeRef} $editing={selected} />
      ) : null}
    </TemplateEditorPreviewCropDiv>
  )
}

const usePreviewCrop = (
  templateCrop: TemplateCropSettings,
  containerRect: DOMRect
) => {
  const { selectedTemplateCrop } = useComposerState()
  const { setTemplateCropRect, setSelectedTemplateCrop } = useComposerHelper()

  const selected = useMemo(
    () => selectedTemplateCrop?.title === templateCrop.title,
    [selectedTemplateCrop?.title, templateCrop.title]
  )

  // const setNextCropRect = useMemo(() => {
  //   return (nextRect: Rect) =>
  //     setCropRectTemplateChange(templateCrop.title, nextRect)
  // }, [setCropRectTemplateChange, templateCrop.title])

  const selectThisTemplateCrop = useCallback(
    (evt: React.MouseEvent<HTMLDivElement>) => {
      evt.preventDefault()
      setSelectedTemplateCrop(templateCrop)
    },
    [setSelectedTemplateCrop, templateCrop]
  )

  // this watches for unsaved changes and current settings
  // const currentPosition = useMemo(() => {
  //   const changedPos = templateCropChanges[templateCrop.title]
  //   return changedPos ? changedPos : templateCrop.position
  // }, [templateCrop.position, templateCrop.title, templateCropChanges])

  const setNextCropRect = useCallback(
    (nextRect: Rect) => {
      setTemplateCropRect(templateCrop.title, nextRect)
    },
    [setTemplateCropRect, templateCrop.title]
  )

  const styles = useMemo(() => {
    const { x, y, width, height } = templateCrop.position
    return {
      width: `${width * 100}%`,
      height: `${height * 100}%`,
      left: `${x * 100}%`,
      top: `${y * 100}%`,
    }
  }, [templateCrop.position])

  const restrictions: CropRestrictions = useMemo(() => {
    if (
      templateCrop.aspectRatio.width === 0 &&
      templateCrop.aspectRatio.height === 0
    ) {
      return {}
    }
    return {
      aspectRatio: templateCrop.aspectRatio,
    }
  }, [templateCrop.aspectRatio])

  const { ref: dragRef, dragging } = useCropDrag(
    templateCrop.position,
    containerRect,
    setNextCropRect,
    selected
  )
  const { ref: resizeRef } = useCropResize(
    templateCrop.position,
    containerRect,
    setNextCropRect,
    restrictions,
    selected
  )

  return useMemo(
    () => ({
      selectThisTemplateCrop,
      selected,
      styles,
      dragRef,
      resizeRef,
      dragging,
    }),
    [dragRef, dragging, resizeRef, selectThisTemplateCrop, selected, styles]
  )
}
