import React, { createContext, ReactNode, useContext, useReducer } from 'react'

export enum FeedType {
  'ValorantSignal' = 'ValorantSignal',
  'ChatSignal' = 'ChatSignal',
  'Moment' = 'Moment',
}

interface FeedState {
  type: FeedType
  channelIds?: string[]
  // filters?: any
  cursor?: string
  // page: number
  moments?: any[]
  hasNextPage: boolean
  loading: boolean
  valorantMatchSync: {
    [matchId: string]: {
      [videoId: string]: { synced: boolean; matchStart: string; userId: string }
    }
  }
}

const INITAL_STATE: FeedState = {
  type: FeedType.ValorantSignal,
  channelIds: undefined,
  // filters: undefined,
  cursor: undefined,
  // page: 0,
  moments: undefined,
  hasNextPage: false,
  loading: false,
  valorantMatchSync: {},
}

enum ACTION_TYPES {
  SET_CHANNEL_IDS = 'SET_CHANNEL_IDS',
  // SET_FILTERS = 'SET_FILTERS',
  SET_TYPE = 'SET_TYPE',
  // SET_PAGE = 'SET_PAGE',
  SET_INITIAL_RESULTS = 'SET_INITIAL_RESULTS',
  APPEND_RESULTS = 'APPEND_RESULTS',
  CLEAR_RESULTS = 'CLEAR_RESULTS',
  SET_LOADING = 'SET_LOADING',
  SET_VALORANT_MATCH_SYNC = 'SET_VALORANT_MATCH_SYNC',
}

interface setChannelIdsAction {
  type: ACTION_TYPES.SET_CHANNEL_IDS
  payload: {
    channelIds: string[]
  }
}

// interface setPageAction {
//   type: ACTION_TYPES.SET_PAGE
//   payload: {
//     page: number
//   }
// }
// interface setFiltersAction {
//   type: ACTION_TYPES.SET_FILTERS
//   payload: {
//     filters: any
//   }
// }
interface setInitialResultsAction {
  type: ACTION_TYPES.SET_INITIAL_RESULTS
  payload: {
    moments: any[]
    cursor: string
    hasNextPage: boolean
  }
}

interface appendResultsAction {
  type: ACTION_TYPES.APPEND_RESULTS
  payload: {
    moments: any[]
    cursor: string
    hasNextPage: boolean
  }
}

interface setLoadingAction {
  type: ACTION_TYPES.SET_LOADING
  payload: {
    loading: boolean
  }
}

interface setTypeAction {
  type: ACTION_TYPES.SET_TYPE
  payload: {
    type: FeedType
  }
}

interface clearResultsAction {
  type: ACTION_TYPES.CLEAR_RESULTS
}

interface setValorantMatchSyncsAction {
  type: ACTION_TYPES.SET_VALORANT_MATCH_SYNC
  payload: {
    matchId: string
    videoId: string
    matchStart: string
    userId: string
    synced: boolean
  }
}

type FeedActions =
  | setChannelIdsAction
  // | setFiltersAction
  | setInitialResultsAction
  | appendResultsAction
  | setLoadingAction
  | clearResultsAction
  | setTypeAction
  | setValorantMatchSyncsAction
// | setPageAction

type Dispatch = (action: FeedActions) => void

const reducer = (state: FeedState, action: FeedActions): FeedState => {
  switch (action.type) {
    // case ACTION_TYPES.SET_FILTERS: {
    //   return {
    //     ...state,
    //     filters: action.payload.filters,
    //   }
    // }

    case ACTION_TYPES.SET_CHANNEL_IDS: {
      return {
        ...state,
        channelIds: action.payload.channelIds,
      }
    }

    case ACTION_TYPES.SET_INITIAL_RESULTS: {
      const { cursor, hasNextPage, moments } = action.payload
      return {
        ...state,
        valorantMatchSync: {},
        moments: [...moments],
        cursor,
        hasNextPage,
        loading: false,
      }
    }

    case ACTION_TYPES.APPEND_RESULTS: {
      const { cursor, hasNextPage, moments } = action.payload
      if (!state.moments) return { ...state }
      return {
        ...state,
        moments: [...state.moments, ...moments],
        cursor,
        hasNextPage,
        loading: false,
      }
    }

    case ACTION_TYPES.SET_LOADING: {
      return {
        ...state,
        loading: action.payload.loading,
      }
    }

    case ACTION_TYPES.CLEAR_RESULTS: {
      return {
        ...state,
        moments: [],
        cursor: '0',
        hasNextPage: false,
      }
    }

    case ACTION_TYPES.SET_TYPE: {
      return {
        ...state,
        type: action.payload.type,
      }
    }

    // case ACTION_TYPES.SET_PAGE: {
    //   return {
    //     ...state,
    //     page: action.payload.page,
    //   }
    // }

    case ACTION_TYPES.SET_VALORANT_MATCH_SYNC: {
      const { matchId, videoId, synced, matchStart, userId } = action.payload
      //check for an exisiting syncs for this match
      const existing = state.valorantMatchSync[matchId] ?? {}

      return {
        ...state,
        valorantMatchSync: {
          ...state.valorantMatchSync,
          [matchId]: {
            ...existing,
            [videoId]: { synced, matchStart, userId },
          },
        },
      }
    }

    default:
      return state
  }
}

const FeedContext = createContext<FeedState | undefined>(undefined)
const FeedUpdaterContext = createContext<Dispatch | undefined>(undefined)

interface Props {
  children: ReactNode
}

export const FeedProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(reducer, INITAL_STATE)

  return (
    <FeedContext.Provider value={state}>
      <FeedUpdaterContext.Provider value={dispatch}>
        {children}
      </FeedUpdaterContext.Provider>
    </FeedContext.Provider>
  )
}

export const useFeedState = () => {
  const context = useContext(FeedContext)
  if (context === undefined) {
    throw new Error('useFeedState must be used within a FeedProvider')
  }
  return context
}

export const useFeedUpdater = () => {
  const context = useContext(FeedUpdaterContext)
  if (context === undefined) {
    throw new Error('useFeedUpdater must be used within a FeedProvider')
  }
  return context
}

useFeedUpdater.ACTION_TYPES = ACTION_TYPES
