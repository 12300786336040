import { useEffect } from 'react'
import { useTheme } from 'styled-components/macro'
import { transparentize } from 'polished'

import { HStack, Spacing } from 'components/core/layout'
import { Switch } from 'components/core/switch'

import { useComposerState } from '../../context'
import { useComposerHelper } from '../../hooks'
import {
  CropRectContainer,
  CropRectContainerRow,
  ToggleAddonButton,
  UnderlineInput,
  UnderlineInputLabel,
} from '../styled'
import { usePositionInput } from './hooks'

export const MatchIntroAddonSettings = () => {
  const { matchIntroAddon, matchIntroOptions } = useComposerState()
  const { setMatchIntro, setMatchIntroOptions } = useComposerHelper()

  const y = usePositionInput(matchIntroOptions.y ?? 1550)

  useEffect(() => {
    setMatchIntroOptions({
      y: y.value,
    })
  }, [y, setMatchIntroOptions])

  const theme = useTheme()
  return (
    <>
      <Spacing top={8}>
        <ToggleAddonButton
          color={transparentize(0.5, theme.colors.static.neutrals.n4)}
          onClick={(e) => {
            e.preventDefault()
            setMatchIntro(!matchIntroAddon)
          }}
        >
          <HStack alignItems="center" gap={8}>
            <Switch checked={matchIntroAddon} onChange={() => {}} />
            <Spacing right={8}>
              <span>Match Intro</span>
            </Spacing>
          </HStack>
        </ToggleAddonButton>
      </Spacing>

      <Spacing top={8}>
        {matchIntroAddon && (
          <>
            <CropRectContainer>
              <CropRectContainerRow>
                <UnderlineInputLabel>
                  <span>Y</span>
                  <UnderlineInput {...y.bind} value={y.value} />
                </UnderlineInputLabel>
              </CropRectContainerRow>
            </CropRectContainer>
          </>
        )}
      </Spacing>
    </>
  )
}
