import { MouseEvent, useCallback, useMemo } from 'react'
import styled from 'styled-components/macro'
import { transparentize } from 'polished'

import { ReactComponent as PlayButton } from 'icons/svgs/solid/play.svg'
import { ReactComponent as PauseButton } from 'icons/svgs/solid/pause.svg'

import { useTimeRangePlayerState, useTimeRangePlayerUpdater } from './context'
import { useTimeRangePlayerHelpers } from './helpers'
import { PlaybackRateController } from './playback-rate-controller'

export const SimpleTimeline = () => {
  const { play, pause } = useTimeRangePlayerHelpers()
  const { playing, currentTime, startTime, endTime } = useTimeRangePlayerState()
  const dispatch = useTimeRangePlayerUpdater()
  const fillStyle = useMemo(() => {
    const length = endTime - startTime
    const fillPct = (currentTime - startTime) / length
    return {
      width: `${fillPct * 100}%`,
    }
  }, [currentTime, endTime, startTime])

  const onTimelineClick = useCallback(
    (evt: MouseEvent<HTMLDivElement>) => {
      const bounds = evt.currentTarget.getBoundingClientRect()
      const x = evt.clientX - bounds.left
      const progress = x / bounds.width
      const duration = endTime - startTime
      const targetTime = startTime + duration * progress
      dispatch({
        type: useTimeRangePlayerUpdater.ACTION_TYPES.SEEK_TO,
        payload: {
          targetTime,
          playing,
        },
      })
    },
    [dispatch, endTime, playing, startTime]
  )

  return (
    <TimelineContainerDiv>
      {playing ? (
        <PauseButton onClick={pause} />
      ) : (
        <PlayButton onClick={play} />
      )}
      <TimelineProgressDiv onClick={onTimelineClick}>
        <TimelineProgressFillDiv style={fillStyle} />
      </TimelineProgressDiv>
      <PlaybackRateController />
    </TimelineContainerDiv>
  )
}

const TimelineContainerDiv = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 36px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${transparentize(0.6, 'black')};

  & > svg {
    fill: ${({ theme }) => theme.colors.static.neutrals.n6};
    width: 12px;
    height: 12px;
    margin-right: 12px;
    cursor: pointer;
  }
`

const TimelineProgressDiv = styled.div`
  position: relative;
  width: calc(100% - 12px - 16px - 32px);
  height: 4px;
  background-color: ${({ theme }) =>
    transparentize(0.4, theme.colors.static.neutrals.n5)};
  border-radius: 2px;
  overflow: hidden;
  cursor: pointer;
`

const TimelineProgressFillDiv = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.static.neutrals.n6};
`
