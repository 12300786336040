import styled from 'styled-components/macro'

import { ReactComponent as StarFullIcon } from 'icons/svgs/solid/star.svg'
import { HStack, Spacing } from 'components/core/layout'

interface Props {
  rating: number | undefined
  setRating: (nextRating: number | undefined) => void
  avgRating?: number
  starSize?: number
}

export const RatingSystem = ({ rating, setRating, starSize }: Props) => {
  return (
    <div>
      <Spacing all={8}>
        {/* <Spacing top={8} /> */}
        <HStack alignItems="center">
          <PreviewStarContainerDiv>
            {Array(5)
              .fill('')
              .map((_, idx) => (
                <PreviewStarDiv
                  key={idx}
                  $size={starSize}
                  $selected={rating !== undefined && idx + 1 <= rating}
                  onClick={() => setRating(idx + 1)}
                >
                  <StarFullIcon />
                </PreviewStarDiv>
              ))}
          </PreviewStarContainerDiv>
          {/* <Spacing left={8}>
            {avgRating && <Text variant="text-3">{avgRating.toFixed(1)}</Text>}
          </Spacing> */}
        </HStack>
      </Spacing>
    </div>
  )
}

export const PreviewStarContainerDiv = styled.div`
  position: relative;
  display: flex;
  border-radius: 8px;
`

export const PreviewStarDiv = styled.div<{
  $selected: boolean
  $size?: number
}>`
  width: ${({ $size }) => ($size ? $size : 16)}px;
  height: ${({ $size }) => ($size ? $size : 16)}px;
  margin: 0 2px;
  cursor: pointer;

  svg {
    &:hover {
      opacity: 1;
    }
    opacity: 0.9;
    fill: ${({ $selected, theme }) =>
      $selected ? theme.colors.neutrals.n3 : theme.colors.neutrals.n6};
  }
`
