export enum DEFAULT_CONTENT_TYPES {
  GAMEPLAY = 'GAMEPLAY',
  CAMERA = 'CAMERA',
  CUSTOM = 'CUSTOM',
}

export const AVAILABLE_DEFAULT_CONTENT_TYPES = [
  DEFAULT_CONTENT_TYPES.GAMEPLAY,
  DEFAULT_CONTENT_TYPES.CAMERA,
  DEFAULT_CONTENT_TYPES.CUSTOM,
]

export enum SAVE_LEVELS {
  MOMENT = 'MOMENT',
  VIDEO = 'VIDEO',
  STREAMER = 'STREAMER',
  GLOBAL = 'GLOBAL',
}

export const SAVE_LEVEL_OPTIONS = [
  SAVE_LEVELS.MOMENT,
  SAVE_LEVELS.VIDEO,
  SAVE_LEVELS.STREAMER,
  SAVE_LEVELS.GLOBAL,
]
