import React, { useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import { CONFIRM_NODE_ID } from './config'

export const Portal: React.FC = ({ children }) => {
  const mountNodeRef = useRef<HTMLElement | null>(
    document.getElementById(CONFIRM_NODE_ID)
  )
  const portalNode = useRef<HTMLElement>(document.createElement('div'))

  useEffect(() => {
    if (!mountNodeRef.current) {
      const mountNode = document.createElement('div')
      mountNode.setAttribute('id', CONFIRM_NODE_ID)
      document.body.appendChild(mountNode)

      mountNodeRef.current = mountNode
    }
    mountNodeRef.current.appendChild(portalNode.current)

    const portalCopy = portalNode.current

    return () => {
      mountNodeRef.current?.removeChild(portalCopy)
    }
  }, [mountNodeRef, portalNode])

  return createPortal(children, portalNode.current)
}
