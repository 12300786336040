import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { SyncedVideo } from 'core/editor/synced-video'
import { Timeline } from 'core/editor/timeline'
import { ChatTimeline } from 'core/editor/timeline/moments/chat'
// import { useOffsetEditing } from './hooks'

import { useEditor } from 'core/editor/hooks'
import { Editor } from 'core/editor'
import {
  SyncVideoCompareDiv,
  SyncVideoControlsDiv,
  SyncVideoPageDiv,
  SyncVideoTimelineControlsDiv,
} from 'core/sync/styled'
import { Button } from 'components/core/button'
import { useEditorState } from 'core/editor/context'
import { useTimelineHelpers } from 'core/editor/timeline/hooks'
import { Container } from 'components/core/layout'
import { Text } from 'components/core/text'

interface VideoSyncEditorProps {
  primaryVideoId: string
  secondaryVideoId: string
  initialOffset: number
  save: (nextOffset: number) => void
}

export const RelatedVideoSyncEditor: React.FC<VideoSyncEditorProps> = ({
  primaryVideoId,
  secondaryVideoId,
  initialOffset,
  save,
}) => {
  const { setSelectionToTime } = useTimelineHelpers()
  const { selection } = useEditorState()
  const { seekTo, restartSelection } = useEditor()
  const [offset, setOffset] = useState(initialOffset)
  const [hotkeyType, setHotkeyType] = useState<
    'selection' | 'offset' | undefined
  >()

  const adjustSelection = useCallback(
    (adjustment: number) => {
      if (!selection) return
      setSelectionToTime(
        selection.startTime + adjustment,
        selection.endTime + adjustment
      )
      seekTo(selection.startTime + adjustment)
    },
    [seekTo, selection, setSelectionToTime]
  )

  const adjustOffset = useCallback(
    (adjustment: number) => {
      setOffset((currentOffset) =>
        parseFloat((currentOffset - adjustment / 1000).toFixed(5))
      )
      // setPlayWhenReady(true)
      restartSelection()
    },
    [restartSelection]
  )

  const hotkeyAction = useMemo(() => {
    if (hotkeyType === 'selection') {
      return adjustSelection
    } else if (hotkeyType === 'offset') {
      return adjustOffset
    }
  }, [adjustOffset, adjustSelection, hotkeyType])

  // const initialGuess = useCallback(() => {
  //   // const start = 1648850019902
  //   // const start = 1648851824271
  //   // const start = 1648850081057
  //   const start = 1648850142771.337
  //   setSelectionToTime(start, start + 2 * 60 * 1000)
  // }, [setSelectionToTime])

  useKeyDownAction(hotkeyAction)
  const playerOptions = useMemo(() => {
    return {
      muted: false,
    }
  }, [])

  const saveOffset = useCallback(() => {
    save(offset)
  }, [offset, save])

  // useEffect(() => {
  //   setOffset(currentOffsets.audioOffset)
  // }, [currentOffsets, setOffset])

  return (
    <Editor>
      <SyncVideoPageDiv>
        <SyncVideoCompareDiv>
          <SyncedVideo
            id={primaryVideoId}
            primaryVideoId={primaryVideoId}
            videoId={primaryVideoId}
            controller={true}
            playerOptions={playerOptions}
          />
          <SyncedVideo
            id={secondaryVideoId}
            primaryVideoId={primaryVideoId}
            videoId={secondaryVideoId}
            playerOptions={playerOptions}
            offset={offset}
          />
        </SyncVideoCompareDiv>
        <SyncVideoControlsDiv>
          <Container
            gap={8}
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            {/* <Button variant="plain" onClick={initialGuess}>
              Initialize
            </Button> */}
            <Button
              variant={hotkeyType === 'selection' ? 'primary' : 'secondary'}
              onClick={() => setHotkeyType('selection')}
            >
              Adjust Time
            </Button>
            <Button
              variant={hotkeyType === 'offset' ? 'primary' : 'secondary'}
              onClick={() => setHotkeyType('offset')}
            >
              Adjust Offset
            </Button>
            <Text variant="text-2">{offset}</Text>
            <Button variant="primary" onClick={saveOffset}>
              Save
            </Button>
          </Container>
        </SyncVideoControlsDiv>
        <SyncVideoTimelineControlsDiv>
          <Timeline>
            <ChatTimeline videoId={primaryVideoId} />
            <ChatTimeline videoId={secondaryVideoId} />
          </Timeline>
        </SyncVideoTimelineControlsDiv>
      </SyncVideoPageDiv>
    </Editor>
  )
}

const useKeyDownAction = (action?: (amount: number) => void) => {
  const onKeyDown = useCallback(
    (evt: KeyboardEvent) => {
      if (!action) return
      switch (evt.key) {
        case '1':
          action(-10 * 1000)
          break
        case '2':
          action(10 * 1000)
          break
        case 'q':
          action(-5 * 1000)
          break
        case 'w':
          action(5 * 1000)
          break
        case 'a':
          action(-1 * 1000)
          break
        case 's':
          action(1 * 1000)
          break
        case 'z':
          action(-1000 / 30)
          break
        case 'x':
          action(1000 / 30)
          break
        case 'c':
          action(-1000 / 60)
          break
        case 'v':
          action(1000 / 60)
          break

        default:
          break
      }
    },
    [action]
  )

  useEffect(() => {
    if (action) {
      document.addEventListener('keydown', onKeyDown, false)
      return () => document.removeEventListener('keydown', onKeyDown, false)
    }
  }, [action, onKeyDown])
}
