import { SelectHTMLAttributes } from 'react'
import styled from 'styled-components/macro'
import { fontWeights } from 'theme/typography'

type SelectProps = SelectHTMLAttributes<HTMLSelectElement>

const Wrapper = styled.label`
  position: relative;
  display: inline-block;

  svg {
    position: absolute;
    right: 12px;
    top: calc(50% - 3px);
    width: 10px;
    height: 6px;
    stroke-width: 2px;
    stroke: ${({ theme }) => theme.colors.static.neutrals.n5};
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    pointer-events: none;
    transition: stroke 150ms ease-in-out;
  }

  select {
    appearance: none;
    padding: 8px 26px 8px 12px;
    width: 100%;
    color: ${({ theme }) => theme.colors.static.neutrals.n3};
    background-color: ${({ theme }) => theme.colors.static.neutrals.n8};
    border: 2px solid ${({ theme }) => theme.colors.static.neutrals.n5};
    border-radius: 8px;
    cursor: pointer;
    outline: none;
    transition: all 150ms ease-in-out;
    font-size: 14px;
    font-weight: ${fontWeights.semibold};

    &:focus {
      outline: none;
      border-color: ${({ theme }) => theme.colors.blue};
    }

    &:hover + svg {
      stroke: ${({ theme }) => theme.colors.blue};
    }
  }
`

export const Select = ({ className, ...props }: SelectProps) => {
  return (
    <Wrapper className={className}>
      <select {...props} />
      <Arrow />
    </Wrapper>
  )
}

const Arrow = () => (
  <svg width={10} height={6} viewBox="0 0 10 6">
    <polyline points="1 1 5 5 9 1"></polyline>
  </svg>
)
