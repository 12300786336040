import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { up } from 'styled-breakpoints'
import { Helmet } from 'react-helmet'
import { useBreakpoint } from 'styled-breakpoints/react-styled'
import styled from 'styled-components/macro'

import { PostBuyEditFragment } from '__generated__'
import { Text } from 'components/core/text'
import { HStack, Spacing } from 'components/core/layout'
import { useQuery } from 'utils/browser'
import { useAppTheme } from 'theme/useDarkMode'

import MetaImage from './meta-image.png'
import { PostBuyModal } from './modal/modal'
import { EditModalContent } from './modal/edit-modal-content'
import {
  HorizontalCollectionContainerDiv,
  MomentPreviewHorizontalDiv,
  MomentPreviewVerticalDiv,
  PostBuyPageDiv,
  StreamerCreatorListDiv,
  VerticalCollectionContainerDiv,
} from './styled'
import { SECTIONS_WITH_TITLES } from './darktide-edits-data'
import STATIC_DATA from './darktide-data.json'
import { EditDisplay } from './item'
import DarktideLogo from './darktide.png'
import { ReactComponent as Gamesight } from './gamesight.svg'

export const DarkTidePostBuyPage = () => {
  const { push, location } = useHistory()
  const [muted, setMuted] = useState(true)
  const [hoveredVideo, setHoveredVideo] = useState<{
    section: string
    editId: string
  }>()
  const { setTheme, theme } = useAppTheme()
  const initialTheme = useRef(theme)
  const search = useQuery()
  const selectedEdit = search.get('v')

  const isLarge = useBreakpoint(up('md'))

  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.background =
        'linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(39,49,38,1) 15%, rgba(39,49,38,1) 100%)'
      return () => {
        document.body.style.removeProperty('background')
      }
    }
  })

  useEffect(() => {
    const initTheme = initialTheme.current
    setTheme('dark')
    return () => {
      initTheme && setTheme(initTheme)
    }
  }, [setTheme])

  return (
    <>
      <Helmet>
        <title>MMNTS - Warhammer 40k: Darktide</title>
        <meta property="og:title" content="MMNTS - Warhammer 40k: Darktide" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://mmnts.live/events/darktide" />
        <meta property="og:image" content={MetaImage} />
      </Helmet>
      <PostBuyPageDiv>
        <ActivationHeadingDiv>
          <img
            src={DarktideLogo}
            alt=""
            style={{ height: isLarge ? 100 : 60 }}
          ></img>
          <Text variant="h6">+</Text>
          <GamesightContainerDiv>
            <Gamesight />
          </GamesightContainerDiv>
        </ActivationHeadingDiv>
        <StreamerCreatorListDiv>
          {/* {STREAMERS_IDS.map((streamerId) => (
            <StreamerAvatar
              key={streamerId}
              height={60}
              width={60}
              profileImageUrl={STREAMER_ICONS[streamerId]}
            />
          ))} */}
        </StreamerCreatorListDiv>

        {SECTIONS_WITH_TITLES.map(({ title, edits }) => {
          const editData = edits.map(
            (editId) => STATIC_DATA[editId]
          ) as PostBuyEditFragment[]
          const AspectRatioDiv =
            editData[0].format.aspectRatio.width === 16
              ? MomentPreviewHorizontalDiv
              : MomentPreviewVerticalDiv
          const AspectRatioContainerDiv =
            editData[0].format.aspectRatio.width === 16
              ? HorizontalCollectionContainerDiv
              : VerticalCollectionContainerDiv
          return (
            <React.Fragment key={title}>
              <Spacing top={64} bottom={12}>
                <HStack alignItems="center" gap={8}>
                  {/* {icon && (
                    <StreamerAvatarWrapperDiv>
                      <StreamerAvatar
                        width={30}
                        height={30}
                        profileImageUrl={icon}
                      />
                    </StreamerAvatarWrapperDiv>
                  )} */}
                  <Text variant="h4">{title}</Text>
                </HStack>
              </Spacing>
              <AspectRatioContainerDiv>
                {editData.map((editFrag, i) => (
                  <AspectRatioDiv
                    key={`${editFrag.id}-${title.toLowerCase()}-${i}`}
                    onMouseEnter={() =>
                      isLarge &&
                      setHoveredVideo({ section: title, editId: editFrag.id })
                    }
                    onMouseLeave={() => setHoveredVideo(undefined)}
                    onClick={() => {
                      push(`${location.pathname}?v=${editFrag.id}`)
                    }}
                  >
                    <EditDisplay
                      hovered={
                        hoveredVideo?.editId === editFrag.id &&
                        hoveredVideo.section === title
                      }
                      editFrag={editFrag}
                      muted={muted}
                      setMuted={setMuted}
                    />
                  </AspectRatioDiv>
                ))}
              </AspectRatioContainerDiv>
            </React.Fragment>
          )
        })}

        {selectedEdit && (
          <PostBuyModal>
            <EditModalContent
              editMap={STATIC_DATA as { [key: string]: PostBuyEditFragment }}
              editId={selectedEdit}
            />
          </PostBuyModal>
        )}
      </PostBuyPageDiv>
    </>
  )
}

export const ActivationHeadingDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  margin: 50px 0 10px 0;
  gap: 8px;

  ${up('md')} {
    gap: 16px;
  }

  & > img {
    /* filter: grayscale(100%) invert(100%) brightness(1.25); */
  }
`

export const GamesightContainerDiv = styled.div`
  position: relative;
  width: 90px;
  height: 36px;

  ${up('md')} {
    width: 160px;
  }

  & > svg {
    width: 100%;
    height: 100%;
  }
`
