import { useCallback, useMemo, useState } from 'react'
import moment from 'moment'

import { FeedType, useFeedUpdater } from './context'
import { OnChangeFunction } from './components/filter-group'

// All helpers that don't rerender on state change
export const useFeedHelpers = () => {
  const dispatch = useFeedUpdater()

  const setChannelIds = useCallback(
    (channelIds: string[]) => {
      dispatch({
        type: useFeedUpdater.ACTION_TYPES.SET_CHANNEL_IDS,
        payload: { channelIds },
      })
    },
    [dispatch]
  )

  const setFeedType = useCallback(
    (type: FeedType) => {
      dispatch({
        type: useFeedUpdater.ACTION_TYPES.SET_TYPE,
        payload: { type },
      })
    },
    [dispatch]
  )

  const setMatchVideoSync = useCallback(
    (payload: {
      matchId: string
      videoId: string
      matchStart: string
      userId: string
      synced: boolean
    }) => {
      dispatch({
        type: useFeedUpdater.ACTION_TYPES.SET_VALORANT_MATCH_SYNC,
        payload,
      })
    },
    [dispatch]
  )

  return useMemo(
    () => ({
      setChannelIds,
      setFeedType,
      setMatchVideoSync,
    }),
    [setChannelIds, setFeedType, setMatchVideoSync]
  )
}

type FeedByDayBucket<T> = {
  label: string
  date: Date
  data: T[]
}

export const bucketizeFeedByDay = <T extends { startsAt: string }>(data: T[]) =>
  Object.values(
    data.reduce<{
      [key: string]: FeedByDayBucket<T>
    }>((acc, m) => {
      const date = moment(m.startsAt).startOf('day')
      const key = date.toString()
      const bucket = acc[key] || {
        date: date.toDate(),
        label: bucketLabelForDate(date),
        data: new Array<T>(),
      }

      acc[key] = {
        ...bucket,
        data: [...bucket.data, m],
      }

      return acc
    }, {})
  ).sort((a, b) => b.date.getTime() - a.date.getTime())

const bucketLabelForDate = (d: moment.Moment) => {
  const diff = moment().diff(d, 'day')
  switch (diff) {
    case 0:
      return 'Today'
    case 1:
      return 'Yesterday'
    default:
      return d.format('MMM Do, YYYY')
  }
}

export type FilterOptions<T> = { label: string; value: T }[]

export const useFilterForm = <T>(
  options: FilterOptions<T>,
  isMultiSelect = true,
  selectedValues: T[] = []
) => {
  const data = options.map((opt) => opt.label)
  const [selected, setSelected] = useState<T[]>(selectedValues)

  const onChange: OnChangeFunction = (values) => {
    const res = Object.entries(values)
      .filter(([_, v]) => v)
      .map(([k]) => options.find((opt) => opt.label === k)?.value) as T[]
    setSelected(res)
  }

  return {
    bind: {
      onChange,
      isMultiSelect,
      selected: selectedValues.map((value) =>
        options.findIndex((opt) => opt.value === value)
      ),
    },
    data,
    selected,
  }
}
