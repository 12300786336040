import { sortBy } from 'lodash'
import React, { useMemo, useState } from 'react'
import { Route, Switch, useParams, useRouteMatch } from 'react-router'

import { MomentViewer } from 'core/moment-viewer'
import { MomentViewerProvider } from 'core/moment-viewer/context'
import {
  PreviewMomentFragment,
  useGetCampaignViewerMomentsQuery,
} from '__generated__'

import { GroupedSegment, useCampaignData } from '../hooks'
import { CampaignSegments } from './segment-list'
import { Text } from 'components/core/text'
import {
  CampaignPageDiv,
  CampaignSegmentsDiv,
  CampaignTitleDiv,
  MomentViewerDiv,
} from '../styled'
import { Content } from 'components/core/layout'
import { DragDropContext } from 'react-beautiful-dnd'
import { CampaignFeedPage } from '../feed/feed'
import { CampaignContentPage } from '../content/content'

export const CampaignPage = () => {
  const { path } = useRouteMatch()
  const { campaignSlug } = useParams<{ campaignSlug: string }>()
  const { title } = useCampaignData(campaignSlug)
  const [selectedGroupedSegment, setSelectedGroupedSegment] =
    useState<GroupedSegment>()
  const { publishedMomentIds, loading } = useCampaignMoments(
    campaignSlug,
    selectedGroupedSegment
  )

  return (
    <Switch>
      <Route path={`${path}`} exact>
        <Content background="secondary">
          <CampaignPageDiv>
            <CampaignTitleDiv>
              <Text variant="h3">{title}</Text>
            </CampaignTitleDiv>
            <CampaignSegmentsDiv>
              <CampaignSegments
                setGroupedSegment={setSelectedGroupedSegment}
                selectedSegment={selectedGroupedSegment}
              />
            </CampaignSegmentsDiv>
            <MomentViewerDiv>
              <MomentViewerProvider>
                <DragDropContext onDragEnd={() => {}}>
                  <MomentViewer
                    loading={loading}
                    momentIds={publishedMomentIds ?? []}
                  />
                </DragDropContext>
              </MomentViewerProvider>
            </MomentViewerDiv>
          </CampaignPageDiv>
        </Content>
      </Route>
      <Route path={`${path}/feed`}>
        <CampaignFeedPage campaignSlug={campaignSlug} />
      </Route>
      <Route path={`${path}/content`}>
        <CampaignContentPage campaignSlug={campaignSlug} />
      </Route>
    </Switch>
  )
}

const useCampaignMoments = (
  campaignSlug: string,
  groupedSegment?: GroupedSegment
) => {
  const timeframe = useMemo(() => {
    if (groupedSegment === undefined) return undefined
    const { from, to } = groupedSegment.segment
    return { from, to }
  }, [groupedSegment])

  const channelIds = useMemo(() => {
    return groupedSegment?.channels.map((c) => c.id)
  }, [groupedSegment?.channels])

  const { data, loading } = useGetCampaignViewerMomentsQuery({
    variables: { slug: campaignSlug, timeframe, channelIds },
    skip: groupedSegment === undefined,
  })

  const publishedMomentIds = useMemo(() => {
    const publishedMoments = (data?.campaign?.moments?.data
      .filter(
        (gm) =>
          gm.moment.published &&
          gm.moment.__typename === 'MultiPerspectiveMoment'
      )
      .map((gm) => gm.moment) ?? []) as PreviewMomentFragment[]
    const momentsWithApprovedEdits = publishedMoments.filter((m) => {
      return m.edits.some((edit) => edit.published)
    })
    return sortBy(momentsWithApprovedEdits, (m) => new Date(m.startsAt)).map(
      (m) => m.id
    )
  }, [data?.campaign?.moments?.data])

  return useMemo(
    () => ({
      publishedMomentIds,
      loading,
    }),
    [loading, publishedMomentIds]
  )
}
