import React, { useEffect, useMemo } from 'react'
import { useDimensions } from 'utils/browser'
import { useTimelineUpdater } from './context'

import { TimelineDetail } from './detail'
import { TimelineScrollbar } from './scrollbar'
import { TimelineContainerDiv } from './styled'

export const Timeline: React.FC = ({ children }) => {
  const { ref: containerRef } = useTimeline()

  return (
    <TimelineContainerDiv ref={containerRef}>
      <TimelineDetail>{children}</TimelineDetail>
      <TimelineScrollbar />
    </TimelineContainerDiv>
  )
}

export const useTimeline = () => {
  const [ref, containerRect] = useDimensions()
  const dispatch = useTimelineUpdater()

  useEffect(() => {
    dispatch({
      type: useTimelineUpdater.ACTION_TYPES.SET_CONTENT_RECT,
      payload: {
        containerRect,
      },
    })
  }, [containerRect, dispatch])

  return useMemo(() => ({ ref }), [ref])
}
