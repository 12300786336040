import { useCallback, useMemo } from 'react'
import {
  FeedValorantMomentMatchFragment,
  FeedValorantMomentMatchFragmentDoc,
  useDeleteValorantMatchSyncMutation,
  useGetValorantMatchSyncQuery,
  useSetValorantMatchSyncMutation,
} from '__generated__'

export const useRemoteMatchStartTime = (matchId: string) => {
  const { data: matchSyncData, loading } = useGetValorantMatchSyncQuery({
    variables: { matchId },
  })

  const data = useMemo(
    () =>
      (matchSyncData?.valorantMatch?.videoSyncData || []).reduce(
        (acc, v) => ({
          ...acc,
          [v.video.id]: new Date(v.matchStartTime).getTime(),
        }),
        {} as { [videoId: string]: number }
      ),
    [matchSyncData]
  )
  return {
    data,
    loading,
  }
}

export const useMatchStartRemote = (matchId: string, videoId: string) => {
  const { data: matchStartTimes, loading } = useRemoteMatchStartTime(matchId)

  return {
    matchStart:
      matchStartTimes && matchStartTimes[videoId]
        ? matchStartTimes[videoId]
        : undefined,
    loading,
  }
}

export const useGetMatchOffset = (matchId: string) => {
  const { data: matchStartTimes } = useRemoteMatchStartTime(matchId)

  return useCallback(
    (primaryVideoId: string, videoId: string) => {
      if (matchStartTimes[primaryVideoId] && matchStartTimes[videoId]) {
        return matchStartTimes[primaryVideoId] - matchStartTimes[videoId]
      }
    },
    [matchStartTimes]
  )
}

export const useRemoteMatchSync = (
  matchId: string,
  videoId: string,
  primaryVideoId: string
) => {
  const { data: matchStartTimes, loading } = useRemoteMatchStartTime(matchId)

  const [setMatchSyncMutation] = useSetValorantMatchSyncMutation()
  const [clearMatchSyncMutation] = useDeleteValorantMatchSyncMutation()

  const setMatchStartTime = useCallback(
    (nextStartTime: number) => {
      const matchStartTime = new Date(nextStartTime).toISOString()
      setMatchSyncMutation({
        variables: { matchId, videoId, matchStartTime },
        update: (cache, { errors }) => {
          if (errors) return
          const fragmentData: FeedValorantMomentMatchFragment | null =
            cache.readFragment({
              id: `ValorantMatch:${matchId}`,
              fragmentName: 'FeedValorantMomentMatch',
              fragment: FeedValorantMomentMatchFragmentDoc,
            })

          if (fragmentData) {
            let videoSyncData = [...(fragmentData.videoSyncData || [])]
            const index = videoSyncData.findIndex(
              (sync) => sync.video.id === videoId
            )

            if (index === -1) {
              videoSyncData = [
                ...videoSyncData,
                {
                  matchStartTime,
                  __typename: 'ValorantMatchVideoSync',
                  video: { id: videoId, __typename: 'TwitchVideo' },
                  match: { id: matchId, __typename: 'ValorantMatch' },
                },
              ]
            } else {
              videoSyncData[index] = {
                ...videoSyncData[index],
                matchStartTime,
              }
            }

            cache.writeFragment({
              id: `ValorantMatch:${matchId}`,
              fragmentName: 'FeedValorantMomentMatch',
              fragment: FeedValorantMomentMatchFragmentDoc,
              data: {
                ...fragmentData,
                videoSyncData,
              },
            })
          }
        },
      })
    },
    [matchId, setMatchSyncMutation, videoId]
  )

  // this system works off using match start from a primary video id and
  // then creates offsets for others videos
  const { matchStart, offset } = useMemo(() => {
    if (
      !matchStartTimes ||
      !matchStartTimes[primaryVideoId] ||
      !matchStartTimes[videoId]
    ) {
      return { matchStart: undefined, offset: undefined }
    }

    const videoOffset = matchStartTimes[videoId]
    const primaryOffset = matchStartTimes[primaryVideoId]
    const matchStart = new Date(primaryOffset).getTime()

    let offset = 0
    if (primaryVideoId !== videoId) {
      offset = matchStart - new Date(videoOffset).getTime()
    }

    return { matchStart, offset }
  }, [matchStartTimes, videoId, primaryVideoId])

  const clearMatchData = useCallback(() => {
    if (!matchStartTimes) {
      return
    }
    const clearData = async (videoId: string) => {
      await clearMatchSyncMutation({ variables: { matchId, videoId } })
    }
    Promise.all(
      Object.keys(matchStartTimes).map((videoId) => clearData(videoId))
    )
  }, [matchId, clearMatchSyncMutation, matchStartTimes])

  return useMemo(
    () => ({
      loading,
      matchStart,
      offset,
      setMatchStartTime,
      clearMatchData,
    }),
    [loading, matchStart, offset, setMatchStartTime, clearMatchData]
  )
}
