import { useScoreboard } from 'core/feed/components/scoreboard/hooks'
import {
  PlayerHighlightData,
  useGetPlayersLeaderboardRanksByAccountId,
} from 'page/ranked-gods/matches/hooks'
import { useEffect, useMemo } from 'react'

import {
  useGetLeaderBoardRankingsLazyQuery,
  useGetValorantMatchesForVideoWithUserInfoQuery,
  useGetValorantMatchHighlightQuery,
} from '__generated__'

export const useMatchIntroData = (videoId: string, startsAt: number) => {
  const { data: videoMatchesData } =
    useGetValorantMatchesForVideoWithUserInfoQuery({
      variables: { videoId },
    })

  const userData = useMemo(() => {
    return {
      displayName: videoMatchesData?.video?.user?.displayName,
      profileImageUrl: videoMatchesData?.video?.user?.profileImageUrl,
    }
  }, [
    videoMatchesData?.video?.user?.displayName,
    videoMatchesData?.video?.user?.profileImageUrl,
  ])

  const riotAccountIds = useMemo(
    () => videoMatchesData?.video?.user?.linkedAccounts.map((acc) => acc.puuid),
    [videoMatchesData?.video?.user?.linkedAccounts]
  )

  const matchId = useMemo(() => {
    const matches = videoMatchesData?.video?.valorantMatches
    if (!matches) return undefined
    return matches.find(
      (m) =>
        startsAt >= new Date(m.startedAt).valueOf() &&
        startsAt <= new Date(m.endedAt).valueOf() + 120 * 1000 // add some padding
    )?.id
  }, [startsAt, videoMatchesData?.video?.valorantMatches])

  const { teams } = useScoreboard(matchId ?? '')
  const { data: matchDetailsData } = useGetValorantMatchHighlightQuery({
    variables: { matchId: matchId ?? '' },
    skip: matchId === undefined,
  })

  const accountIds = useMemo(
    () =>
      matchDetailsData?.valorantMatch?.players
        .filter((pl) => riotAccountIds?.includes(pl.account?.id ?? ''))
        .map((pl) => pl.account?.id ?? ''),
    [matchDetailsData?.valorantMatch?.players, riotAccountIds]
  )

  const leaderboardRankById = useGetPlayersLeaderboardRanksByAccountId(
    accountIds,
    matchDetailsData?.valorantMatch?.endedAt
  )

  const highlightedPlayers = useMemo(
    () =>
      matchDetailsData?.valorantMatch?.players.filter((pl) =>
        accountIds?.includes(pl.account?.id ?? '')
      ),
    [matchDetailsData?.valorantMatch?.players, accountIds]
  )
  const players: PlayerHighlightData[] | undefined = useMemo(() => {
    if (!highlightedPlayers && !leaderboardRankById) return undefined
    return highlightedPlayers?.map((player) => {
      const channelInfo = player.account?.channel
      return {
        accountId: player.account?.id ?? '',
        gameName: player.gameName ?? '',
        displayName: channelInfo?.displayName ?? '',
        profileImageUrl: channelInfo?.profileImageUrl ?? '',
        competitiveTier: player.competitiveTier ?? '',
        kills: player.stats.kills ?? 0,
        deaths: player.stats.deaths ?? 0,
        leaderboardRank: leaderboardRankById?.[player.account?.id ?? ''],
        agent: player.agent ?? '',
      }
    })
  }, [highlightedPlayers, leaderboardRankById])

  return useMemo(
    () => ({
      displayName: userData.displayName ?? undefined,
      profileImageUrl: userData.profileImageUrl ?? undefined,
      players,
      teams,
      matchStart: matchDetailsData?.valorantMatch?.startedAt,
      matchEnd: matchDetailsData?.valorantMatch?.endedAt,
    }),
    [
      userData.displayName,
      userData.profileImageUrl,
      players,
      teams,
      matchDetailsData?.valorantMatch?.startedAt,
      matchDetailsData?.valorantMatch?.endedAt,
    ]
  )
}

export type KillsData = {
  victim: { id: string; name: string }
  time: number
  round: number
  weapon?: string
  type?: string
}

export type DeathsData = {
  killer: { id: string; name: string }
  time: number
  weapon?: string
  type?: string
}

export type KillsByPlayer = {
  [gameName: string]: KillsData[]
}
export type DeathsByPlayer = {
  [gameName: string]: DeathsData[]
}

export const useGetPlayerLeaderboardRank = (
  accountId?: string,
  matchEnd?: string
) => {
  const [query, { data, loading, called }] =
    useGetLeaderBoardRankingsLazyQuery()

  useEffect(() => {
    if (accountId && matchEnd) {
      query({ variables: { accountIds: [accountId], before: matchEnd } })
    }
  }, [accountId, matchEnd, query])

  const leaderboardRank = useMemo(() => {
    return data?.valorantLeaderboard?.data.find(
      (item) => item.account?.id === accountId
    )?.leaderboardRank
  }, [accountId, data?.valorantLeaderboard?.data])

  return useMemo(() => {
    return {
      ready: called && !loading && data,
      leaderboardRank: leaderboardRank,
    }
  }, [called, data, leaderboardRank, loading])
}
