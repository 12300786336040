import { useTheme } from 'styled-components/macro'

import { VStack } from 'components/core/layout'
import { Text } from 'components/core/text'

import {
  GridItemChannel,
  GridItemChannelImg,
  GridItemContainer,
  GridItemImageContainer,
  GridItemInfo,
  GridItemThumbnailImg,
} from './styled'

type Props = {
  title?: string
  numberOfEdits: number
  thumbnail?: string
  channel: {
    name: string
    profileImageUrl?: string
  }
  inProgress: boolean
}

export const GridItem = ({
  title,
  numberOfEdits,
  thumbnail,
  channel,
  inProgress,
}: Props) => {
  const theme = useTheme()
  return (
    <GridItemContainer>
      <GridItemImageContainer>
        <GridItemThumbnailImg src={thumbnail} alt="" />
        <GridItemChannel>
          <Text variant="text-3" color={theme.colors.white}>
            {channel.name}
          </Text>
          {channel.profileImageUrl && (
            <GridItemChannelImg src={channel.profileImageUrl} />
          )}
        </GridItemChannel>
      </GridItemImageContainer>
      <GridItemInfo>
        <VStack>
          <Text variant="text-2" weight="semibold">
            {title}
          </Text>
          <Text variant="text-3" color={theme.colors.neutrals.n4}>
            {numberOfEdits} Edits {inProgress ? '- in progress' : ''}
          </Text>
        </VStack>
      </GridItemInfo>
    </GridItemContainer>
  )
}
