import { useCallback, useEffect, useMemo, useState } from 'react'
import { transparentize } from 'polished'
import { useTheme } from 'styled-components/macro'

import { Editor } from 'core/editor'
import { useEditorState, useEditorUpdater } from 'core/editor/context'
import { useTimelineHelpers } from 'core/editor/timeline/hooks'
import { ReactComponent as LockIcon } from 'icons/svgs/solid/lock.svg'
import { ReactComponent as LockOpenIcon } from 'icons/svgs/solid/lock-open.svg'
import { useDimensions } from 'utils/browser'
import { IconButton } from 'core/composer/template-sidebar/styled'
import { RootOnly } from 'core/auth/components'
import { useEditor } from 'core/editor/hooks'

import { useSignalPlayerUpdater } from './context'
import {
  SignalPlayerContainerDiv,
  TimelineAreaDiv,
  VideoPlayerAreaDiv,
} from './styled'
import { SignalVideoPlayer } from './player'
import { SignalPlayerTimeline } from './timeline'

interface Props {
  videoId: string
  signalStart: number
  signalEnd: number
  relatedVideoData: { id: string; offset?: number }[]
  selectedRelatedVideoIds: string[]
  updateSelectedRelatedVideoIds?: (videoIds: string[]) => void
}

export const SignalPlayer = ({
  videoId,
  signalStart,
  signalEnd,
  selectedRelatedVideoIds,
}: Props) => {
  const [screenRef, screenRect] = useDimensions()
  const [timelineRef, timelineRect] = useDimensions()
  const editorDispatch = useEditorUpdater()
  const availablePlayerDimensions = useMemo(() => {
    if (!timelineRect || !screenRect) return undefined
    return {
      width: screenRect.width,
      height: screenRect.height - timelineRect.height,
    }
  }, [screenRect, timelineRect])
  const dispatch = useSignalPlayerUpdater()
  const { jumpToNewSelection } = useTimelineHelpers()
  useHotkeys(true)

  useEffect(() => {
    jumpToNewSelection(signalStart, signalEnd)
  }, [jumpToNewSelection, signalEnd, signalStart])

  // only show first 4 related videos
  useEffect(() => {
    dispatch({
      type: useSignalPlayerUpdater.ACTION_TYPES.SET_VISIBLE_RELATED_VIDEOS,
      payload: {
        relatedVideoIds: selectedRelatedVideoIds,
      },
    })
  }, [dispatch, selectedRelatedVideoIds])

  // this is used since it checks and fills in offsets for related videos
  useEffect(() => {
    editorDispatch({
      type: useEditorUpdater.ACTION_TYPES.SET_PRIMARY_VIDEO,
      payload: { primaryVideoId: videoId },
    })
  }, [editorDispatch, videoId])

  return (
    <Editor>
      <SignalPlayerContainerDiv ref={screenRef}>
        {availablePlayerDimensions ? (
          <VideoPlayerAreaDiv>
            {/* <Hotkeys /> */}
            <SignalVideoPlayer
              videoId={videoId}
              availableDimensions={availablePlayerDimensions}
            />
          </VideoPlayerAreaDiv>
        ) : null}
        <TimelineAreaDiv ref={timelineRef}>
          <SignalPlayerTimeline />
        </TimelineAreaDiv>
      </SignalPlayerContainerDiv>
    </Editor>
  )
}

export const Hotkeys = () => {
  const theme = useTheme()
  const [hotkeysActive, setHotkeysActive] = useState(true)
  useHotkeys(hotkeysActive)

  return (
    <RootOnly>
      <IconButton
        color={transparentize(0.5, theme.colors.static.neutrals.n4)}
        size="small"
        style={{ padding: '6px 6px' }}
        onClick={() => setHotkeysActive((currentState) => !currentState)}
      >
        {hotkeysActive ? (
          <LockOpenIcon width={12} height={12} fill="white" />
        ) : (
          <LockIcon width={12} height={12} fill="white" />
        )}
      </IconButton>
    </RootOnly>
  )
}

const useHotkeys = (hotkeysActive: boolean) => {
  const { relativeSeekTo, pause, play, playing } = useEditor()
  const { currentTime, selection } = useEditorState()
  const { setSelectionToTime } = useTimelineHelpers()

  const onKeyDown = useCallback(
    (evt: KeyboardEvent) => {
      switch (evt.key) {
        case '1':
          relativeSeekTo(-10 * 1000)
          break
        case '2':
          relativeSeekTo(10 * 1000)
          break
        case 'q':
          relativeSeekTo(-5 * 1000)
          break
        case 'w':
          relativeSeekTo(5 * 1000)
          break
        case 'a':
          relativeSeekTo(-1 * 1000)
          break
        case 's':
          relativeSeekTo(1 * 1000)
          break
        case 'z':
          relativeSeekTo(-1000 / 30)
          break
        case 'x':
          relativeSeekTo(1000 / 30)
          break
        case 'c':
          relativeSeekTo(-1000 / 60)
          break
        case 'v':
          relativeSeekTo(1000 / 60)
          break
        case '[':
          if (selection?.endTime)
            setSelectionToTime(currentTime, selection.endTime)
          break
        case ']':
          if (selection?.startTime)
            setSelectionToTime(selection.startTime, currentTime)
          break
        case ' ':
          if (playing) {
            pause()
          } else {
            play()
          }
          break

        default:
          break
      }
    },
    [
      currentTime,
      pause,
      play,
      playing,
      relativeSeekTo,
      selection?.endTime,
      selection?.startTime,
      setSelectionToTime,
    ]
  )

  useEffect(() => {
    if (hotkeysActive) {
      document.addEventListener('keydown', onKeyDown, false)
      return () => document.removeEventListener('keydown', onKeyDown, false)
    }
  }, [onKeyDown, hotkeysActive])
}
