import { useTheme } from 'styled-components/macro'
import momentjs from 'moment'

import { Container, HStack, Spacing, VStack } from 'components/core/layout'
import { Text } from 'components/core/text'

import {
  ChannelContentDiv,
  ConnectedMomentInfoDiv,
  GridItemChannelImg,
  GridItemContainer,
  GridItemImageContainer,
  GridItemInfo,
  GridItemThumbnailImg,
  IconBackgroundDiv,
  RatingContainerDiv,
  SyncedIndicatorDiv,
  TitleContainerDiv,
  UnreadIndicatorDiv,
} from './styled'
import { uppercaseFirstLetter } from 'utils/strings'
import { ReactComponent as FireEmoji } from 'icons/svgs/regular/fire.svg'
import { ReactComponent as FunnyEmoji } from 'icons/svgs/regular/grin-tears.svg'
import { ReactComponent as StarFullIcon } from 'icons/svgs/solid/star.svg'

type Props = {
  thumbnail?: string
  channel: {
    id: string
    name: string
    profileImageUrl?: string
  }
  at: string
  metadata: any
  score: number
  type: string
  synced: boolean
  rating?: number
  editAspectRatios?: string[]
  unread?: boolean
  usedIn?: number
  onClick?: () => void
  hideChannel?: boolean
  savedTitle?: string
}

export const ReactionGridItem = ({
  thumbnail,
  channel,
  synced,
  type,
  onClick,
  at,
  editAspectRatios,
  rating,
  usedIn = 0,
  hideChannel = false,
  unread,
  savedTitle,
  metadata,
}: Props) => {
  const theme = useTheme()

  return (
    <GridItemContainer onClick={onClick}>
      <GridItemImageContainer>
        <GridItemThumbnailImg src={thumbnail} alt="" />
        {synced ? <SyncedIndicatorDiv /> : null}
        <ConnectedMomentInfoDiv>
          <VStack alignItems="flex-end" gap={8}>
            {editAspectRatios && (
              <HStack alignItems="center" gap={4}>
                {editAspectRatios?.map((ratio) => (
                  <RatingContainerDiv key={ratio}>
                    <Text variant="small">{ratio}</Text>
                  </RatingContainerDiv>
                ))}
              </HStack>
            )}
            {rating && (
              <RatingContainerDiv>
                <HStack alignItems="center" gap={4}>
                  <Text variant="text-3" mono>
                    {rating}
                  </Text>
                  <StarFullIcon width={12} height={12} />
                </HStack>
              </RatingContainerDiv>
            )}
          </VStack>
        </ConnectedMomentInfoDiv>
      </GridItemImageContainer>
      {hideChannel ? (
        <GridItemInfo>
          <ChannelContentDiv>
            <Spacing bottom="4px">
              <HStack justifyContent="space-between" alignItems="center">
                <Container direction="row" alignItems="center">
                  <IconBackgroundDiv>
                    {type === 'funny' ? (
                      <FunnyEmoji
                        width={24}
                        height={24}
                        fill={theme.colors.neutrals.n4}
                      />
                    ) : (
                      <FireEmoji
                        width={18}
                        height={18}
                        fill={theme.colors.neutrals.n4}
                      />
                    )}
                  </IconBackgroundDiv>
                  <Spacing left="8px">
                    <VStack>
                      <HStack alignItems="center" gap={4}>
                        <TitleContainerDiv>
                          <HStack alignItems="center" gap={8}>
                            <Text
                              variant="text-2"
                              weight="semibold"
                              color={theme.colors.text.primary}
                              as="p"
                            >
                              {savedTitle !== undefined && savedTitle !== ''
                                ? savedTitle
                                : uppercaseFirstLetter(type)}
                            </Text>
                            <Text variant="text-3" weight="semibold">
                              {(metadata.score * 100).toFixed(0)}%
                            </Text>
                          </HStack>
                        </TitleContainerDiv>
                        {unread ? <UnreadIndicatorDiv /> : null}
                      </HStack>
                    </VStack>
                  </Spacing>
                </Container>
                <Container alignItems="flex-end">
                  {/* <time dateTime={at} title={readableTime}> */}
                  <Text variant="text-3" mono>
                    {momentjs(at).format('LT')}
                  </Text>
                  {/* </time> */}
                  <Text variant="text-3">
                    {usedIn > 0 &&
                      `in ${usedIn} moment${usedIn > 1 ? 's' : ''}`}
                  </Text>
                </Container>
              </HStack>
            </Spacing>
          </ChannelContentDiv>
        </GridItemInfo>
      ) : (
        <GridItemInfo>
          <VStack>
            <Spacing bottom="4px">
              <HStack justifyContent="space-between">
                <Container direction="row" alignItems="center">
                  <GridItemChannelImg src={channel.profileImageUrl} />
                  <Spacing left="8px">
                    <VStack>
                      <Text
                        variant="text-2"
                        weight="semibold"
                        color={theme.colors.text.primary}
                      >
                        {channel.name}
                      </Text>
                      <Text
                        variant="text-3"
                        color={theme.colors.text.secondary}
                      >
                        {uppercaseFirstLetter(type)}
                      </Text>
                    </VStack>
                  </Spacing>
                </Container>
                <Container alignItems="flex-end">
                  <Text variant="text-3" mono>
                    {momentjs(at).format('LT')}
                  </Text>
                  <Text variant="text-3">
                    {usedIn > 0 &&
                      `in ${usedIn} moment${usedIn > 1 ? 's' : ''}`}
                  </Text>
                </Container>
              </HStack>
            </Spacing>
          </VStack>
        </GridItemInfo>
      )}
    </GridItemContainer>
  )
}
