import Iron1 from 'assets/valorant/ranks/Iron_1_Rank.png'
import Iron2 from 'assets/valorant/ranks/Iron_2_Rank.png'
import Iron3 from 'assets/valorant/ranks/Iron_3_Rank.png'
import Bronze1 from 'assets/valorant/ranks/Bronze_1_Rank.png'
import Bronze2 from 'assets/valorant/ranks/Bronze_2_Rank.png'
import Bronze3 from 'assets/valorant/ranks/Bronze_3_Rank.png'
import Silver1 from 'assets/valorant/ranks/Silver_1_Rank.png'
import Silver2 from 'assets/valorant/ranks/Silver_2_Rank.png'
import Silver3 from 'assets/valorant/ranks/Silver_3_Rank.png'
import Gold1 from 'assets/valorant/ranks/Gold_1_Rank.png'
import Gold2 from 'assets/valorant/ranks/Gold_2_Rank.png'
import Gold3 from 'assets/valorant/ranks/Gold_3_Rank.png'
import Platinum1 from 'assets/valorant/ranks/Platinum_1_Rank.png'
import Platinum2 from 'assets/valorant/ranks/Platinum_2_Rank.png'
import Platinum3 from 'assets/valorant/ranks/Platinum_3_Rank.png'
import Diamond1 from 'assets/valorant/ranks/Diamond_1_Rank.png'
import Diamond2 from 'assets/valorant/ranks/Diamond_2_Rank.png'
import Diamond3 from 'assets/valorant/ranks/Diamond_3_Rank.png'
import Ascendant1 from 'assets/valorant/ranks/Ascendant_1_Rank.png'
import Ascendant2 from 'assets/valorant/ranks/Ascendant_2_Rank.png'
import Ascendant3 from 'assets/valorant/ranks/Ascendant_3_Rank.png'
import Immortal1 from 'assets/valorant/ranks/Immortal_1_Rank.png'
import Immortal2 from 'assets/valorant/ranks/Immortal_2_Rank.png'
import Immortal3 from 'assets/valorant/ranks/Immortal_3_Rank.png'
import Radiant from 'assets/valorant/ranks/Radiant_Rank.png'

export const RANK_ICONS: { [key: string]: string } = {
  IRON_1: Iron1,
  IRON_2: Iron2,
  IRON3: Iron3,
  BRONZE_1: Bronze1,
  BRONZE_2: Bronze2,
  BRONZE_3: Bronze3,
  SILVER_1: Silver1,
  SILVER_2: Silver2,
  SILVER_3: Silver3,
  GOLD_1: Gold1,
  GOLD_2: Gold2,
  GOLD_3: Gold3,
  PLATINUM_1: Platinum1,
  PLATINUM_2: Platinum2,
  PLATINUM_3: Platinum3,
  DIAMOND_1: Diamond1,
  DIAMOND_2: Diamond2,
  DIAMOND_3: Diamond3,
  ASCENDANT_1: Ascendant1,
  ASCENDANT_2: Ascendant2,
  ASCENDANT_3: Ascendant3,
  IMMORTAL_1: Immortal1,
  IMMORTAL_2: Immortal2,
  IMMORTAL_3: Immortal3,
  RADIANT: Radiant,
}
