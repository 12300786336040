import styled from 'styled-components/macro'

export const AddonContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
`

export const PlayerDisplayScaledContainer = styled.div<{
  $width?: number
  $height?: number
  $scale: number
  $top?: number
  $left: number
}>`
  position: absolute;
  width: ${({ $width }) => ($width ? `${$width}px` : '1080px')};
  height: ${({ $height }) => ($height ? `${$height}px` : '1920px')};
  display: flex;
  flex-direction: column;

  padding: 10px;
  transform-origin: top left;
  transform: scale(${({ $scale }) => $scale});
  pointer-events: none;
  align-items: flex-start;
`

export const PositioningContainerDiv = styled.div<{
  $top: number
  $left: number
}>`
  position: absolute;
  top: ${({ $top }) => $top}px;
  left: ${({ $left }) => $left}px;
  overflow: visible;
`

export const ContentsDiv = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  gap: 24px;
  border-radius: 20px;
  padding-right: 24px;
  background: rgba(0, 0, 0, 0.85);
  transform: translateX(-50%);
`

export const AgentImg = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 20px;
`
