import { HStack, VStack } from 'components/core/layout'
import { Text } from 'components/core/text'
import { AGENT_THUMB_IMAGES } from 'components/valorant/agent'
import { groupBy, sortBy } from 'lodash'
import { useMemo } from 'react'
import styled from 'styled-components/macro'

import { useGetValorantMatchPreviewQuery, ValorantAgent } from '__generated__'

interface Props {
  matchId: string
}

export const ValorantMatchPreview = ({ matchId }: Props) => {
  const { data } = useGetValorantMatchPreviewQuery({
    variables: { matchId },
  })

  const groupedPlayers = useMemo(() => {
    const sortedByScore = sortBy(
      data?.valorantMatch?.players ?? [],
      (pl) => -pl.stats?.score
    )
    return groupBy(sortedByScore, ({ team }) => team)
  }, [data?.valorantMatch?.players])

  return (
    <MatchContainerDiv>
      <HStack>
        {Object.entries(groupedPlayers).map(([team, players]) => (
          <VStack style={{ margin: '0 16px' }}>
            <div>{team}</div>
            {players.map(({ agent, gameName, stats, account }) => (
              <HStack
                alignItems="center"
                justifyContent="flex-start"
                data-id={account.id} // just to help with ids
              >
                <AgentThumbImg
                  src={AGENT_THUMB_IMAGES[agent as ValorantAgent]}
                  alt="agent thumb"
                />
                <PlayerNameDiv>
                  <Text variant="text-1">{gameName}</Text>
                </PlayerNameDiv>
                <PlayerNameDiv>
                  <Text variant="text-1">
                    {stats?.kills}/{stats?.deaths}/{stats?.assists}
                  </Text>
                </PlayerNameDiv>
                <div>{account.channel?.id ? 'X' : null}</div>
              </HStack>
            ))}
          </VStack>
        ))}
      </HStack>
    </MatchContainerDiv>
  )
}

const MatchContainerDiv = styled.div`
  min-height: 200px;
  border: 1px solid ${({ theme }) => theme.colors.neutrals.n5};
`

const AgentThumbImg = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 20px;
`

const PlayerNameDiv = styled.div`
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
