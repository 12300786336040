import { useEffect, useState } from 'react'
import { useTheme } from 'styled-components/macro'
import { transparentize } from 'polished'

import { HStack, Spacing } from 'components/core/layout'
import { Switch } from 'components/core/switch'
// import { ReactComponent as AlignLeft } from 'icons/svgs/solid/align-left.svg'
// import { ReactComponent as AlignCenter } from 'icons/svgs/solid/align-center.svg'
// import { ReactComponent as AlignRight } from 'icons/svgs/solid/align-right.svg'

import { useComposerState } from '../../context'
import { useComposerHelper } from '../../hooks'
import {
  // CropRectContainer,
  // CropRectContainerRow,
  // IconButton,
  ToggleAddonButton,
  // UnderlineInput,
  // UnderlineInputLabel,
} from '../styled'
import { usePositionInput } from './hooks'

export enum Justifications {
  left = 'left',
  center = 'center',
  right = 'right',
}

// const JustificationIcons = {
//   [Justifications.left]: AlignLeft,
//   [Justifications.center]: AlignCenter,
//   [Justifications.right]: AlignRight,
// }

export const PlayerDisplayAddonSettings = () => {
  const { playerDisplayAddon, playerDisplayOptions } = useComposerState()
  const { setPlayerDisplay, setPlayerDisplayOptions } = useComposerHelper()

  const y = usePositionInput(playerDisplayOptions.y ?? 1550)
  const [justification] = useState<Justifications>(
    playerDisplayOptions.justify ?? Justifications.left
  )

  useEffect(() => {
    setPlayerDisplayOptions({
      y: y.value,
      justify: justification,
    })
  }, [y, justification, setPlayerDisplayOptions])

  const theme = useTheme()
  return (
    <>
      <Spacing top={8}>
        <ToggleAddonButton
          color={transparentize(0.5, theme.colors.static.neutrals.n4)}
          onClick={(e) => {
            e.preventDefault()
            setPlayerDisplay(!playerDisplayAddon)
          }}
        >
          <HStack alignItems="center" gap={8}>
            <Switch checked={playerDisplayAddon} onChange={() => {}} />
            <Spacing right={8}>
              <span>PlayerDisplay</span>
            </Spacing>
          </HStack>
        </ToggleAddonButton>
      </Spacing>

      <Spacing top={8}>
        {/* {playerDisplayAddon && (
          <>
            <HStack gap={4}>
              {[
                Justifications.left,
                Justifications.center,
                Justifications.right,
              ].map((just) => {
                const Icon = JustificationIcons[just]
                return (
                  <IconButton
                    key={just}
                    color={
                      just === justification
                        ? transparentize(0.5, theme.colors.static.neutrals.n6)
                        : transparentize(0.5, theme.colors.static.neutrals.n4)
                    }
                    size="small"
                    style={{ padding: '6px 6px' }}
                    onClick={() => setJustification(just)}
                  >
                    <Icon width={12} height={12} fill="white" />
                  </IconButton>
                )
              })}
            </HStack>
            <Spacing top={16}>
              <CropRectContainer>
                <CropRectContainerRow>
                  <UnderlineInputLabel>
                    <span>Y</span>
                    <UnderlineInput {...y.bind} value={y.value} />
                  </UnderlineInputLabel>
                </CropRectContainerRow>
              </CropRectContainer>
            </Spacing>
          </>
        )} */}
      </Spacing>
    </>
  )
}
