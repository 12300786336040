import { Text } from 'components/core/text'
import { HStack, VStack } from 'components/core/layout'
import { AGENT_THUMB_IMAGES } from 'components/valorant/agent'
import { WinLossGridDiv } from '../styled'
import { useQuery } from 'utils/browser'
import {
  AgentStats,
  ChannelInfo,
  hashRisingGod,
  MatchPlayerData,
  Stats,
  useGetValorantRankedStats,
} from './hooks'
import { useCallback, useEffect, useState } from 'react'
import { MatchDetailDisplay } from './match-detail'

interface SharedProps {
  saveData?: (key: string, data: RisingGodsProps) => void
}

export const RisingGodsWithParams = ({ saveData }: SharedProps) => {
  const searchQuery = useQuery()
  const channelId = searchQuery.get('channelId') ?? ''
  const start = searchQuery.get('startTime') ?? ''
  const end = searchQuery.get('endTime') ?? ''

  return (
    <RisingGodsWithData
      channelId={channelId}
      start={start}
      end={end}
      saveData={saveData}
    />
  )
}

interface Props extends SharedProps {
  channelId: string
  start: string
  end: string
}

export const RisingGodsWithData = ({
  channelId,
  start,
  end,
  saveData,
}: Props) => {
  const [rankAfterMatch, setRankAfterMatch] = useState<Record<string, number>>(
    {}
  )
  const { channelInfo, stats, agentStats, matchPlayerData, finishedFetching } =
    useGetValorantRankedStats(channelId, start, end, rankAfterMatch)

  const setRankAfterMatchCallback = useCallback(
    (matchId: string, rank: number) => {
      setRankAfterMatch((prev) => ({ ...prev, [matchId]: rank }))
    },
    []
  )

  useEffect(() => {
    if (
      saveData &&
      channelInfo &&
      stats &&
      agentStats &&
      matchPlayerData &&
      finishedFetching
    ) {
      const id = hashRisingGod(channelId, start, end)
      saveData(id, {
        channelInfo,
        stats,
        agentStats,
        matchPlayerData,
      })
    }
  }, [
    agentStats,
    channelId,
    channelInfo,
    end,
    finishedFetching,
    matchPlayerData,
    saveData,
    start,
    stats,
  ])

  return stats && channelInfo && agentStats && matchPlayerData ? (
    <RisingGods
      channelInfo={channelInfo}
      stats={stats}
      agentStats={agentStats}
      matchPlayerData={matchPlayerData}
      setRank={setRankAfterMatchCallback}
    />
  ) : null
}

export interface RisingGodsProps {
  channelInfo: ChannelInfo
  stats: Stats
  agentStats: AgentStats[]
  matchPlayerData: MatchPlayerData[]
}

interface SetRankProps {
  setRank: (matchId: string, rank: number) => void
}

export const RisingGods = ({
  channelInfo,
  stats,
  agentStats,
  matchPlayerData,
  setRank,
}: RisingGodsProps & SetRankProps) => {
  return (
    <div>
      <VStack gap={24}>
        <VStack>
          <HStack>
            <img
              src={channelInfo?.profileImageUrl ?? ''}
              width={50}
              height={50}
              style={{ borderRadius: '50%' }}
              alt=""
            />
            <Text variant="text-1">{channelInfo?.name}</Text>
          </HStack>
          <Text variant="text-2">Rank Change:{stats?.rankChange}</Text>
          <HStack>
            <Text variant="text-2">Start:{stats?.startRank}</Text>
            <Text variant="text-2">{'->'}</Text>
            <Text variant="text-2">End:{stats?.endRank}</Text>
          </HStack>
          <Text variant="text-2">Games Played:{stats?.numGames}</Text>
          <Text variant="text-2">Win Pct:{stats?.winPercent ?? 0}%</Text>
          <Text variant="text-2">Wins:{stats?.numWins}</Text>
        </VStack>
        <HStack gap={8}>
          {agentStats?.slice(0, 3).map((agent) => (
            <VStack>
              <img
                src={AGENT_THUMB_IMAGES[agent.agent]}
                width={50}
                height={50}
                alt=""
              />
              <Text variant="small" weight="semibold">
                {agent.numWins}W - {agent.numLosses}L
              </Text>
              <Text variant="small" weight="semibold">
                {(agent.kills / agent.deaths).toFixed(2)} K/D
              </Text>
            </VStack>
          ))}
        </HStack>
        <WinLossGridDiv>
          {matchPlayerData?.map((match) => (
            <MatchDetailDisplay
              setRank={setRank}
              match={match}
              accountId={channelInfo.accountId}
            />
          ))}
        </WinLossGridDiv>
      </VStack>
    </div>
  )
}
