import { SVGProps } from 'react'

const IconExternalOpen = ({
  width = '13',
  height = '13',
  color,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 13 13"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.6036 0H12.75C12.8881 0 13 0.11193 13 0.25V4.39645C13 4.61917 12.7307 4.73072 12.5732 4.57322L11.0303 3.03033L7.28033 6.78033C6.98744 7.07322 6.51256 7.07322 6.21967 6.78033C5.92678 6.48744 5.92678 6.01256 6.21967 5.71967L9.9697 1.96967L8.4268 0.42678C8.2693 0.26929 8.3808 0 8.6036 0ZM1.75 1C0.7835 1 0 1.7835 0 2.75V11.25C0 12.2165 0.7835 13 1.75 13H10.25C11.2165 13 12 12.2165 12 11.25V7.75C12 7.33579 11.6642 7 11.25 7C10.8358 7 10.5 7.33579 10.5 7.75V11.25C10.5 11.3881 10.3881 11.5 10.25 11.5H1.75C1.61193 11.5 1.5 11.3881 1.5 11.25V2.75C1.5 2.61193 1.61193 2.5 1.75 2.5H5.25C5.66421 2.5 6 2.16421 6 1.75C6 1.33579 5.66421 1 5.25 1H1.75Z"
    />
  </svg>
)

export { IconExternalOpen }
export { TwitchIcon } from './twitch-icon'
