import { useCallback, useMemo } from 'react'

import { useValorantMatchRoundData } from 'core/shared/valorant/hooks'
import {
  useGetValorantMatchesForVideoWithUserInfoQuery,
  useGetValorantMatchPageDetailQuery,
  ValorantMatchDetailFragment,
  ValorantMatchPlayerPreviewFragment,
} from '__generated__'
// import matchData from './match-data.json'

// fixTime is used to adjust the time based off what riotApi tells us
// vs what actually happened. The fixTime prop is used since we have some places
// that have already taken it into account, but others haven't calculated it yet
export const useKillFeedData = (
  videoId: string,
  startsAt: number,
  fixTime?: boolean
) => {
  const { data: videoMatchesData } =
    useGetValorantMatchesForVideoWithUserInfoQuery({
      variables: { videoId },
    })

  const riotAccountIds = useMemo(
    () => videoMatchesData?.video?.user?.linkedAccounts.map((acc) => acc.puuid),
    [videoMatchesData?.video?.user?.linkedAccounts]
  )

  const matchId = useMemo(() => {
    const matches = videoMatchesData?.video?.valorantMatches
    if (!matches) return undefined
    return matches.find(
      (m) =>
        startsAt >= new Date(m.startedAt).valueOf() &&
        startsAt <= new Date(m.endedAt).valueOf() + 120 * 1000 // add some padding
    )?.id
  }, [startsAt, videoMatchesData?.video?.valorantMatches])

  const { data: matchDetailsData } = useGetValorantMatchPageDetailQuery({
    variables: { matchId: matchId ?? '' },
    skip: matchId === undefined,
  })
  const { expectedFirstRoundStart, getRoundTime } = useGetRoundFromTime(
    videoId,
    matchId,
    matchDetailsData?.valorantMatch,
    fixTime
  )
  const playerInfo = useMemo(() => {
    return matchDetailsData?.valorantMatch?.players.find((pl) =>
      riotAccountIds?.includes(pl.account.id)
    )
  }, [matchDetailsData?.valorantMatch?.players, riotAccountIds])
  const accountId = useMemo(
    () => playerInfo?.account.id,
    [playerInfo?.account.id]
  )
  const gameName = useMemo(() => playerInfo?.gameName, [playerInfo?.gameName])
  const roundResults = useMemo(
    () => matchDetailsData?.valorantMatch?.roundResults,
    [matchDetailsData]
  )
  const playerStats = matchDetailsData?.valorantMatch?.players.reduce(
    (acc, pl) => {
      return {
        ...acc,
        [pl.account.id]: pl,
      }
    },
    {} as { [accountId: string]: ValorantMatchPlayerPreviewFragment }
  )
  const playerKills = useMatchKillsByPlayer(
    videoId,
    matchDetailsData?.valorantMatch,
    expectedFirstRoundStart ?? 0
  )

  const roundByTime = useMemo(() => {
    return getRoundTime(startsAt)
  }, [getRoundTime, startsAt])

  return useMemo(
    () => ({
      accountId,
      playerKills,
      roundResults,
      round: roundByTime,
      gameName,
      playerStats,
    }),
    [accountId, gameName, playerKills, playerStats, roundByTime, roundResults]
  )
}

export type KillsData = {
  victim: { id: string; name: string }
  time: number
  round: number
  weapon?: string
  type?: string
}

export type KillsByPlayer = {
  [gameName: string]: KillsData[]
}

// We should rework this for useMemo
export const useKillFeed = (
  accountId: string,
  round: number,
  currentTime: number | undefined,
  killsByPlayer: KillsByPlayer
) => {
  let kills: KillsData[] = []
  const playerKills = killsByPlayer[accountId]
  if (currentTime && playerKills) {
    kills = playerKills.filter(
      (k) => k.round === round && currentTime >= k.time
    )
  }

  return kills
}

export const useFindAllKillsSlideshow = (
  killsByPlayer: KillsByPlayer,
  accountId: string,
  startsAt: number,
  endsAt: number
) => {
  const includedData = useMemo(
    () =>
      killsByPlayer[accountId]?.filter((k) => {
        return k.time >= startsAt && k.time <= endsAt
      }),
    [accountId, endsAt, killsByPlayer, startsAt]
  )

  return useMemo(() => {
    if (!includedData || includedData.length === 0) return []
    return includedData.map(({ time }) => time)
  }, [includedData])
}

// We should rework this for useMemo
export const useMatchKillsByPlayer = (
  videoId: string,
  matchData: ValorantMatchDetailFragment | undefined | null,
  expectedFirstRoundStart: number
) => {
  const syncedStart = matchData?.videoSyncData.find(
    (vsync) => vsync.video.id === videoId
  )?.matchStartTime

  const syncedStartMillis = new Date(syncedStart).valueOf()
  const matchStart = new Date(matchData?.startedAt).valueOf()
  const syncedMatchStart =
    matchStart + (syncedStartMillis - matchStart - expectedFirstRoundStart)

  return useMemo(() => {
    const killsByPlayer: KillsByPlayer = {}
    for (const round of matchData?.roundResults ?? []) {
      for (const playerRoundStats of round.playerStats ?? []) {
        const killerId = playerRoundStats?.player?.account.id
        for (const kill of playerRoundStats?.kills ?? []) {
          if (!killerId || !kill) continue // this shouldn't happen, ty ts
          if (!killsByPlayer[killerId]) killsByPlayer[killerId] = []
          killsByPlayer[killerId]?.push({
            victim: {
              id: kill.victim?.account.id ?? '',
              name: kill.victim?.gameName ?? '',
            },
            time: syncedMatchStart + (kill?.timeSinceGameStartMillis ?? 0),
            round: (round?.roundNum ?? 0) + 1,
            weapon: kill.finishingDamage?.damageItem ?? undefined,
            type: kill.finishingDamage?.damageType ?? undefined,
          })
        }
      }
    }
    return killsByPlayer
  }, [matchData?.roundResults, syncedMatchStart])
}

const useGetRoundFromTime = (
  videoId: string,
  matchId: string | undefined,
  matchData: ValorantMatchDetailFragment | undefined | null,
  fixTime: boolean | undefined
) => {
  const { roundDataWithLength, expectedFirstRoundStart } =
    useValorantMatchRoundData(matchId)
  const syncedStart = matchData?.videoSyncData.find(
    (vsync) => vsync.video.id === videoId
  )?.matchStartTime

  const syncedStartMillis = new Date(syncedStart).valueOf()
  const syncedMatchStart = syncedStartMillis
  const matchStart = new Date(matchData?.startedAt).valueOf()

  const getRoundTime = useCallback(
    (time: number) => {
      // time needs to be adjusted to match the synced time
      const realTime = fixTime
        ? time +
          (syncedMatchStart - matchStart - (expectedFirstRoundStart ?? 0))
        : time
      const round = roundDataWithLength?.find((rd) => {
        const roundStartTime = syncedMatchStart + rd.roundStart
        const roundEndTime = roundStartTime + rd.roundLength
        return realTime >= roundStartTime && realTime <= roundEndTime
      })?.roundNum

      return round !== undefined ? round + 1 : undefined
    },
    [
      expectedFirstRoundStart,
      fixTime,
      matchStart,
      roundDataWithLength,
      syncedMatchStart,
    ]
  )

  return useMemo(
    () => ({
      getRoundTime,
      expectedFirstRoundStart,
    }),
    [expectedFirstRoundStart, getRoundTime]
  )
}
