import { useMemo } from 'react'
import styled from 'styled-components/macro'
import momentjs from 'moment'

import { useGetCampaignPreviewQuery } from '__generated__'
import { Text } from 'components/core/text'
import { Spacing } from 'components/core/layout'
import { useRouteMatch } from 'react-router'
import { Link } from 'react-router-dom'

interface Props {
  campaignId: string
}

export const CampaignPreviewItem = ({ campaignId }: Props) => {
  const { data } = useGetCampaignPreviewQuery({ variables: { campaignId } })
  const { url } = useRouteMatch()
  // const theme = useTheme()

  // const { numMoments, numEdits } = useMemo(() => {
  //   const numMoments = data?.campaign?.moments?.data.filter(
  //     (m) => m.moment.published
  //   )
  //   // these are always published ones.
  //   const numEdits = data?.campaign?.moments?.data.flatMap(
  //     (m) => m.moment.edits
  //   ).length
  //   return { numMoments, numEdits }
  // }, [])

  const { startDisplay, endDisplay } = useMemo(() => {
    return {
      startDisplay: data?.campaign?.start
        ? momentjs(data?.campaign?.start).format('L')
        : undefined,
      endDisplay: data?.campaign?.end
        ? momentjs(data?.campaign?.end).format('L')
        : undefined,
    }
  }, [data?.campaign?.end, data?.campaign?.start])

  return (
    <StyledLink to={`${url}/campaigns/${data?.campaign?.slug}/feed`}>
      <ContainerDiv>
        <Text variant="text-1" weight="semibold" as="p">
          {data?.campaign?.title}
        </Text>
        {/* <Text variant="text-3" color={theme.colors.neutrals.n4} as="p">
          Owner: Loaded
        </Text> */}

        <Spacing top={16} bottom={32}>
          <Text variant="text-3">
            {[startDisplay, endDisplay].filter(Boolean).join(' - ')}
          </Text>
        </Spacing>

        <PreviewChannelsDiv>
          {data?.campaign?.channels?.channels.data
            .slice(0, 17)
            .map(({ id, profileImageUrl }) => (
              <PreviewChannelImg
                key={id}
                width={24}
                height={24}
                src={profileImageUrl ?? ''}
              />
            ))}
        </PreviewChannelsDiv>
      </ContainerDiv>
    </StyledLink>
  )
}

const ContainerDiv = styled.div`
  display: flex;
  flex-direction: column;

  width: 312px;
  height: 200px;
  border-radius: 8px;
  padding: 24px;

  background-color: ${({ theme }) => theme.colors.neutrals.n8};
  box-shadow: ${({ theme }) => theme.elevation.depth1};

  transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

  &:hover {
    box-shadow: ${({ theme }) => theme.elevation.depth2};
  }
`

const PreviewChannelImg = styled.img`
  width: 24px;
  height: 24px;
  margin-left: -8px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.neutrals.n8};
`

const PreviewChannelsDiv = styled.div`
  position: relative;
  display: flex;
  overflow: hidden;

  margin-top: auto;

  & > ${PreviewChannelImg}:first-child {
    margin-left: 0;
  }

  &::before {
    content: '';
    top: 0px;
    right: 0px;
    position: absolute;
    width: 32px;
    height: 100%;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1)
    );
  }
`

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.text.primary};
  text-decoration: none;
`
