import {
  Rating,
  // ValorantAgent,
  // ValorantMap,
  // ValorantRoundCeremony,
} from '__generated__'
// import { FilterOptions } from 'core/feed/helpers'

export const RatingOptions = [
  { label: '★', value: Rating.One },
  { label: '★★', value: Rating.Two },
  { label: '★★★', value: Rating.Three },
  { label: '★★★★', value: Rating.Four },
  { label: '★★★★★', value: Rating.Five },
]

// const matchTypeOptions = Object.entries(ValorantMatchType).map(([k, v]) => ({
//   label: k,
//   value: v,
// }))

// export const roundCeremonyOptions = Object.keys(ValorantRoundCeremony)
//   .filter((map) => map !== 'Default')
//   .sort()
//   .reduce<FilterOptions<ValorantRoundCeremony>>(
//     (acc, key) => [...acc, { label: key, value: ValorantRoundCeremony[key] }],
//     []
//   )

// export const mapOptions = Object.keys(ValorantMap)
//   .filter((map) => map !== 'Range')
//   .sort()
//   .reduce<FilterOptions<ValorantMap>>(
//     (acc, key) => [...acc, { label: key, value: ValorantMap[key] }],
//     []
//   )

// export const agentOptions = Object.keys(ValorantAgent)
//   .filter((map) => map !== 'Unknown')
//   .sort()
//   .reduce<FilterOptions<ValorantAgent>>(
//     (acc, key) => [...acc, { label: key, value: ValorantAgent[key] }],
//     []
//   )

// export const reactionOptions = [
//   { label: 'Funny', value: 'funny' },
//   { label: 'Exciting', value: 'hype' },
// ]
