import { Button } from 'components/core/button'
import { Text } from 'components/core/text'
import { sortBy } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import {
  useGetLinkedAccountsFromChannelCollectionQuery,
  useGetValorantMatchesQuery,
  ValorantMatchPreviewFragment,
} from '__generated__'
import { TimeRange, TimeRangePicker } from '../leaderboards/timerange-picker'
import { Link } from 'react-router-dom'
import { Select } from 'components/core/select'
import {
  KDADiv,
  KillsDiv,
  LinkOutDiv,
  MatchesTable,
  NumTrackedTopFragsDiv,
  TopFragNameDiv,
} from './styled'

export const MatchDiscovery = () => {
  const [sort, setSort] = useState<
    'kills' | 'kd' | 'tracked-topfrags' | 'h2h' | 'duo'
  >('kills')
  const [cursor, setCursor] = useState<string>()
  const { allAccountIds, channelInfoByAccountId } = useGetLinkedAccounts()
  const [timeRange, setTimeRange] = useState<TimeRange>({
    startDate: new Date('2022-11-21T00:00:00.000Z'),
    endDate: new Date('2023-01-08T00:00:00.000Z'),
  })
  const [allResults, setAllResults] = useState<ValorantMatchPreviewFragment[]>(
    []
  )
  const { data: matchesData } = useGetValorantMatchesQuery({
    variables: {
      playerAccountIds: allAccountIds ?? [],
      start: timeRange.startDate,
      end: timeRange.endDate,
      first: 100,
      after: cursor ? cursor : undefined,
    },
  })

  useEffect(() => {
    if (matchesData?.valorantMatches?.data) {
      setAllResults((prev) => [
        ...prev,
        ...(matchesData.valorantMatches
          ?.data as ValorantMatchPreviewFragment[]),
      ])
    }
    if (matchesData?.valorantMatches?.pagination?.hasNextPage) {
      setCursor(matchesData.valorantMatches.pagination.endCursor)
    }
  }, [matchesData])

  // if time range changes, reset cursor and results
  useEffect(() => {
    setAllResults([])
    setCursor(undefined)
  }, [timeRange])

  const scoredMatches = useMemo(() => {
    return sortBy(
      allResults.map((match) => {
        return { match, score: scoreMatch(match, channelInfoByAccountId) }
      }),
      (scoredMatch) => {
        switch (sort) {
          case 'kills':
            return -scoredMatch.score.topFrag.kills ?? 0
          case 'kd':
            return -scoredMatch.score.topFrag.kd
          case 'tracked-topfrags':
            return -scoredMatch.score.topTrackedPlayers
          case 'h2h':
            return -scoredMatch.score.h2hScore
          case 'duo':
            return -scoredMatch.score.duoScore
          default:
            return -scoredMatch.score.topFrag.kills
        }
      }
    )
  }, [allResults, channelInfoByAccountId, sort])

  const lastMatch = useMemo(() => {
    return sortBy(scoredMatches, (m) => -new Date(m.match.endedAt).getTime())[0]
  }, [scoredMatches])

  return (
    <>
      <Text variant="h5">Match Discovery</Text>
      <TimeRangePicker
        initialStart={timeRange.startDate}
        initialEnd={timeRange.endDate}
        onChange={(timeRange) => setTimeRange(timeRange)}
      />
      {/* Select sort type */}
      <Select onChange={(e) => setSort(e.target.value as 'kills' | 'kd')}>
        <option value="kills">Most Kills</option>
        <option value="kd">Highest K/D</option>
        <option value="tracked-topfrags">Tracked Top-Frags</option>
        <option value="h2h">H2H Score</option>
        <option value="duo">Duo Score</option>
      </Select>
      <Text variant="h6">
        Results up to: {lastMatch && lastMatch.match.startedAt}
      </Text>
      <MatchesTable>
        <TopFragNameDiv>
          <Text variant="text-2">Top Frag</Text>
        </TopFragNameDiv>
        <KillsDiv>
          <Text variant="text-2">Kills</Text>
        </KillsDiv>
        <KDADiv>
          <Text variant="text-2">K/D</Text>
        </KDADiv>
        <NumTrackedTopFragsDiv>
          <Text variant="text-2">Tracked Top Frags</Text>
        </NumTrackedTopFragsDiv>
        <NumTrackedTopFragsDiv>
          <Text variant="text-2">H2H Score</Text>
        </NumTrackedTopFragsDiv>
        <NumTrackedTopFragsDiv>
          <Text variant="text-2">Duo Score</Text>
        </NumTrackedTopFragsDiv>
        <LinkOutDiv>
          <Text variant="text-2">Link</Text>
        </LinkOutDiv>
        {scoredMatches.map(({ match, score }) => (
          <React.Fragment key={match.id}>
            <TopFragNameDiv>
              <Text variant="text-2">{score.topFrag.gameName}</Text>
            </TopFragNameDiv>
            <KillsDiv>
              <Text variant="text-2">{score.topFrag.kills}</Text>
            </KillsDiv>
            <KDADiv>
              <Text variant="text-2">{score.topFrag.kd.toFixed(2)}</Text>
            </KDADiv>
            <NumTrackedTopFragsDiv>
              <Text variant="text-2">{score.topTrackedPlayers}</Text>
            </NumTrackedTopFragsDiv>
            <NumTrackedTopFragsDiv>
              <Text variant="text-2">{score.h2hScore}</Text>
            </NumTrackedTopFragsDiv>
            <NumTrackedTopFragsDiv>
              <Text variant="text-2">{score.duoScore}</Text>
            </NumTrackedTopFragsDiv>
            <LinkOutDiv>
              <Link
                to={`/ranked-gods/solo/${match.id}/${score.topFrag.accountId}`}
                target="_blank"
              >
                <Text variant="text-2">-&gt;</Text>
              </Link>
            </LinkOutDiv>
          </React.Fragment>
        ))}
      </MatchesTable>
      {matchesData?.valorantMatches?.pagination?.hasNextPage && (
        <Button
          onClick={() =>
            setCursor(matchesData?.valorantMatches?.pagination?.endCursor ?? '')
          }
        >
          Next
        </Button>
      )}
    </>
  )
}

// function zScore(numbers: number[]): number[] {
//   // Calculate the mean of the numbers
//   const mean = numbers.reduce((sum, number) => sum + number, 0) / numbers.length

//   // Calculate the standard deviation of the numbers
//   const variance =
//     numbers.reduce((sum, number) => sum + Math.pow(number - mean, 2), 0) /
//     numbers.length
//   const stdDev = Math.sqrt(variance)

//   // Calculate the z-score for each number
//   return numbers.map((number) => (number - mean) / stdDev)
// }

type ChannelInfo = {
  id: string
  displayName: string
  viewCount: number
  accountIds: string[]
}

const useGetLinkedAccounts = () => {
  const { data } = useGetLinkedAccountsFromChannelCollectionQuery({
    variables: { slug: 'ranked-gods' },
  })

  const channelsByViewcount: ChannelInfo[] | undefined = useMemo(
    () =>
      data?.channelCollection?.channels.data
        .map((channel) => {
          return {
            id: channel.id,
            displayName: channel.displayName ?? '',
            viewCount: channel.viewCount ?? 0,
            accountIds: channel.linkedAccounts.map((acc) => acc.id),
          }
        })
        .sort((a, b) => b.viewCount - a.viewCount),
    [data?.channelCollection?.channels.data]
  )

  const channelInfoByAccountId = useMemo(() => {
    const channelInfoByAccountId: Record<string, any> = {}
    channelsByViewcount?.forEach((channel) => {
      channel.accountIds.forEach((accountId) => {
        channelInfoByAccountId[accountId] = {
          displayName: channel.displayName,
          viewCount: channel.viewCount,
        }
      })
    })
    return channelInfoByAccountId
  }, [channelsByViewcount])

  const allAccountIds = useMemo(() => {
    return channelsByViewcount?.flatMap((channel) => channel.accountIds)
  }, [channelsByViewcount])

  return useMemo(
    () => ({
      channelInfoByAccountId,
      channelsByViewcount,
      allAccountIds,
    }),
    [allAccountIds, channelInfoByAccountId, channelsByViewcount]
  )
}

const scoreMatch = (
  match: ValorantMatchPreviewFragment,
  channelInfoByAccountId: Record<string, ChannelInfo>
) => {
  const numTrackedPlayers = match.players.reduce((acc, player) => {
    if (channelInfoByAccountId[player.account.id]) {
      return acc + 1
    }
    return acc
  }, 0)

  const sortedPlayers = sortBy(match.players, (player) => -player.stats.kills)
  let topFrag = {
    kills: 0,
    kd: 0,
    gameName: '',
    accountId: '',
  }
  if (sortedPlayers && channelInfoByAccountId[sortedPlayers[0]?.account.id]) {
    topFrag = {
      kills: sortedPlayers[0].stats.kills,
      kd: sortedPlayers[0].stats.kills / sortedPlayers[0].stats.deaths,
      gameName: sortedPlayers[0].gameName ?? '',
      accountId: sortedPlayers[0].account.id,
    }
  }

  const overallScore = topFrag.kills

  return {
    topTrackedPlayers: getNumConsecutiveTrackedPlayers(
      match,
      channelInfoByAccountId
    ),
    h2hScore: getHeadToHeadScore(match, channelInfoByAccountId),
    duoScore: getDuoMatchScore(match, channelInfoByAccountId),
    numTrackedPlayers,
    overallScore,
    topFrag,
  }
}

const getNumConsecutiveTrackedPlayers = (
  match: ValorantMatchPreviewFragment,
  channelInfoByAccountId: Record<string, ChannelInfo>
) => {
  const sortedPlayers = sortBy(match.players, (player) => -player.stats.score)
  let consecutiveTrackedPlayers = 0

  for (const player of sortedPlayers) {
    if (channelInfoByAccountId[player.account.id]) {
      consecutiveTrackedPlayers++
    } else {
      break
    }
  }
  return consecutiveTrackedPlayers
}

const getHeadToHeadScore = (
  match: ValorantMatchPreviewFragment,
  importantPlayers: Record<string, ChannelInfo>
) => {
  const redTeam = sortBy(
    match.players.filter((player) => player.team === 'RED'),
    (player) => -player.stats.score
  )
  const blueTeam = sortBy(
    match.players.filter((player) => player.team === 'BLUE'),
    (player) => -player.stats.score
  )

  if (
    redTeam[0].account.id in importantPlayers &&
    blueTeam[0].account.id in importantPlayers
  ) {
    const topRedPlayer = redTeam[0]
    const topBluePlayer = blueTeam[0]
    return topRedPlayer.stats.kills + topBluePlayer.stats.kills
  }
  return 0
}

const getDuoMatchScore = (
  match: ValorantMatchPreviewFragment,
  importantPlayers: Record<string, ChannelInfo>
) => {
  const topPlayers = sortBy(match.players, (player) => -player.stats.score)
  const topPlayer = topPlayers[0]
  const secondPlayer = topPlayers[1]

  if (
    topPlayer.account.id in importantPlayers &&
    secondPlayer.account.id in importantPlayers &&
    topPlayer.team === secondPlayer.team
  ) {
    return topPlayer.stats.kills + secondPlayer.stats.kills
  }
  return 0
}
