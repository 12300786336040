import React, { useMemo } from 'react'

import { useValorantMatchRoundData } from 'core/shared/valorant/hooks'

import { usePlayerList } from '../hooks'
import { ValorantMatchPlayerHeading } from './player-heading'
import {
  MainPerspectiveHeadingSpacerDiv,
  ValorantTeamDiv,
} from 'core/shared/timeline/valorant/heading/styled'

interface Props {
  matchId: string
  showAll: boolean
}

export const ValorantMatchTimelineHeading = ({ matchId, showAll }: Props) => {
  const { playerList, mainPerspectiveInfo } = usePlayerList()
  const { winningTeam } = useValorantMatchRoundData(matchId)

  const filteredPlayerList = useMemo(
    () =>
      showAll
        ? playerList
        : playerList.filter(
            (playerData) =>
              playerData.account.id === mainPerspectiveInfo?.accountId
          ),
    [playerList, mainPerspectiveInfo?.accountId, showAll]
  )

  return (
    <ValorantTeamDiv>
      {filteredPlayerList.map((playerData) => (
        <React.Fragment key={playerData.account.id}>
          <ValorantMatchPlayerHeading
            playerData={playerData}
            winningTeam={winningTeam === playerData.team}
          />
          {mainPerspectiveInfo?.accountId === playerData.account.id &&
            filteredPlayerList.length > 1 && (
              <MainPerspectiveHeadingSpacerDiv />
            )}
        </React.Fragment>
      ))}
    </ValorantTeamDiv>
  )
}
