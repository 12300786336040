import { useEffect, useMemo } from 'react'

import { FilterGroup } from 'core/feed/components/filter-group'
import { useQueryParamFilters } from 'utils/browser'
import { useFilterForm } from 'core/feed/helpers'

import { reactionOptions } from './options'
import { usePrevious } from 'utils'
import { isEqual } from 'lodash'

export const ReactionFeedFilters = () => {
  const { params, setParam } = useQueryParamFilters()

  const reactionsSelectedParams = useMemo(() => {
    const reactionParams = params['reactionTypes']
    if (!reactionParams) return []
    if (Array.isArray(reactionParams)) {
      return reactionParams
    } else {
      return [reactionParams]
    }
  }, [params])
  const {
    data: reactionData,
    bind: reactionBind,
    selected: reactionsSelected,
  } = useFilterForm(
    reactionOptions,
    true,
    reactionsSelectedParams.length === 0
      ? ['funny', 'hype']
      : reactionsSelectedParams
  )

  const reactionFilters = useMemo(() => {
    return {
      reactionTypes: reactionsSelected,
    }
  }, [reactionsSelected])

  const prevFilters = usePrevious(reactionFilters)

  useEffect(() => {
    for (const [key, value] of Object.entries(reactionFilters)) {
      if (
        value === undefined ||
        (typeof value === 'object' && value.length === 0)
      ) {
        setParam(key, undefined)
        continue
      }
      setParam(key, value)
    }
  }, [reactionFilters, setParam])

  useEffect(() => {
    if (!isEqual(prevFilters, reactionFilters) && prevFilters !== undefined) {
      setParam('page', undefined)
    }
  }, [prevFilters, reactionFilters, setParam])

  return (
    <>
      <FilterGroup
        title="Reaction Type"
        data={reactionData}
        {...reactionBind}
      />
    </>
  )
}
