import React, { useCallback, useMemo } from 'react'

import { ValorantAgent, ValorantMatchTeam } from '__generated__'
import {
  useVideoExplorerState,
  useVideoExplorerUpdater,
} from 'page/video/context'
import { useEditorState } from 'core/editor/context'
import { durationFormatMinutes } from 'utils/time'
import { useValorantPlayerMatchData } from 'core/shared/valorant/hooks'
import { Text } from 'components/core/text'

import { RelatedVideoResult, useValorantMatchDataForVideo } from './hooks'
import { PreviewPlayer } from './preview-player'
import { MatchContainerDiv, MatchOffsetDiv, VsContainerDiv } from './styled'

interface ValorantMatchPreviewProps {
  videoId: string
  matchId: string
}

export const ValorantMatchPreview: React.FC<ValorantMatchPreviewProps> = ({
  videoId,
  matchId,
}) => {
  const { primaryPlayerIds, relatedByMatchId } =
    useValorantMatchDataForVideo(videoId)
  const {
    primaryPlayerInfo,
    relatedPlayersInfo,
    jumpToMatch,
    pregameLength,
    setPregameLength,
    canSetPregameLength,
  } = useGetMatchPreviewDetails(matchId, primaryPlayerIds, relatedByMatchId)

  return primaryPlayerInfo ? (
    <div>
      <MatchContainerDiv onClick={jumpToMatch}>
        <PreviewPlayer playerInfo={primaryPlayerInfo} />
        {relatedPlayersInfo.teammates.map((relatedPlayer) => (
          <PreviewPlayer key={relatedPlayer.puuid} playerInfo={relatedPlayer} />
        ))}
        {relatedPlayersInfo.opponents.length > 0 ? (
          <VsContainerDiv>VS</VsContainerDiv>
        ) : null}
        {relatedPlayersInfo.opponents.map((relatedPlayer) => (
          <PreviewPlayer key={relatedPlayer.puuid} playerInfo={relatedPlayer} />
        ))}
      </MatchContainerDiv>
      <MatchOffsetDiv>
        <Text variant="text-3">
          Offset: {pregameLength ? ` ${pregameLength}` : 'unset'}
        </Text>
        {canSetPregameLength ? (
          <button onClick={setPregameLength}>set</button>
        ) : null}
      </MatchOffsetDiv>
    </div>
  ) : null
}

export interface PlayerStats {
  kills: number
  score: number
  deaths: number
  assists: number
  'rounds-played': number
  'playtime-millis': number
}

export interface PlayerInfo {
  puuid: string
  agent: ValorantAgent
  gameName: string
  team: ValorantMatchTeam
  stats: PlayerStats
}

const useGetMatchPreviewDetails = (
  matchId: string,
  primaryPlayerIds: string[],
  relatedPlayersByMatchId: { [puuid: string]: RelatedVideoResult[] }
) => {
  const { jumpToMatch, findPlayerInfoById } =
    useValorantPlayerMatchData(matchId)
  const { selection } = useEditorState()
  const { pregameLengthByMatch } = useVideoExplorerState()
  const videoExplorerDispatch = useVideoExplorerUpdater()

  const primaryPlayerInfo = useMemo(() => {
    return findPlayerInfoById(primaryPlayerIds)
  }, [findPlayerInfoById, primaryPlayerIds])

  const relatedPlayersInfo = useMemo(() => {
    if (!primaryPlayerInfo) return { teammates: [], opponents: [] }
    const allRelatedPlayers = relatedPlayersByMatchId[matchId].map(
      ({ puuids }) => {
        return findPlayerInfoById(puuids)
      }
    ) as PlayerInfo[]

    const teammates = allRelatedPlayers.filter(
      (info) => info.team === primaryPlayerInfo.team
    )
    const opponents = allRelatedPlayers.filter(
      (info) => info.team !== primaryPlayerInfo.team
    )

    return {
      teammates,
      opponents,
    }
  }, [findPlayerInfoById, matchId, relatedPlayersByMatchId, primaryPlayerInfo])

  const setPregameLength = useCallback(() => {
    if (selection === undefined) return

    const nextLength = selection.endTime - selection.startTime
    videoExplorerDispatch({
      type: useVideoExplorerUpdater.ACTION_TYPES.SET_MATCH_PREGAME_LENGTH,
      payload: {
        matchId: matchId,
        length: nextLength,
      },
    })
  }, [matchId, selection, videoExplorerDispatch])

  const pregameLength: string | undefined = pregameLengthByMatch[matchId]
    ? durationFormatMinutes(pregameLengthByMatch[matchId] / 1000)
    : undefined
  const canSetPregameLength = selection !== undefined

  return useMemo(
    () => ({
      jumpToMatch,
      primaryPlayerInfo,
      relatedPlayersInfo,
      pregameLength,
      setPregameLength,
      canSetPregameLength,
    }),
    [
      canSetPregameLength,
      jumpToMatch,
      pregameLength,
      primaryPlayerInfo,
      relatedPlayersInfo,
      setPregameLength,
    ]
  )
}
