import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Spinner } from 'components/core/spinner'

import { Spacing } from 'components/core/layout'
import { Sort } from '__generated__'
import { SignalData, SignalReviewPage } from 'core/signal-review'
import { FeedType, useFeedState } from 'core/feed/context'
import { useFeedHelpers } from 'core/feed/helpers'
import { useEscKey } from 'hooks/use-esc-key'
import { ReactionPreviewModal } from 'core/feed/components/preview-modal/reaction-modal'
import { Portal } from 'components/dialog/portal'
import { FixedSignalReviewDiv } from 'page/feed/styles'
import { MultiFeed } from 'core/feed/multi-feed'
import { useQueryParamFilters } from 'utils/browser'

interface Props {
  channelIds: string[]
  startsAt: string
  endsAt: string
  sort: Sort
}

export const FeedView = ({ channelIds, startsAt, endsAt }: Props) => {
  const [signalReviewOpen, setSignalReviewOpen] = useState(false)
  const [signalReviewInitData, setSignalReviewInitData] = useState<
    SignalData | undefined
  >()
  const [selectedReactionMomentId, setSelectedReactionMomentId] = useState<
    string | undefined
  >()
  const { setParam } = useQueryParamFilters()

  const { setFeedType: setType, setChannelIds } = useFeedHelpers()
  const { loading } = useFeedState()
  // const loadNextPage = useFeedFetchMore()

  useEscKey(
    useCallback(() => {
      setSelectedReactionMomentId(undefined)
    }, [])
  )

  const closePreviewModal = useCallback(() => {
    setSelectedReactionMomentId(undefined)
  }, [setSelectedReactionMomentId])

  const openReactionReview = useCallback(() => {
    if (!selectedReactionMomentId) return
    setSignalReviewOpen(true)
    setSignalReviewInitData({
      signalId: selectedReactionMomentId,
      signalType: 'ChatMoment',
    })
    closePreviewModal()
  }, [closePreviewModal, selectedReactionMomentId])

  useEffect(() => {
    setType(FeedType.ChatSignal)
  }, [setType])

  useEffect(() => {
    setChannelIds(channelIds)
  }, [channelIds, setChannelIds])

  //if startsAt and endsAt change, we need to reset the page
  useEffect(() => {
    if (startsAt && endsAt) {
      setParam('page', undefined)
    }
  }, [startsAt, endsAt, setParam])

  const multiFeedFilters = useMemo(() => {
    return {
      startsAt: { from: startsAt, to: endsAt },
    }
  }, [endsAt, startsAt])

  // useEffect(() => {
  //   setFilters({
  //     sort,
  //     startsAt: { from: startsAt, to: endsAt },
  //   })
  // }, [endsAt, setFilters, startsAt, sort])

  return (
    <Spacing bottom={32}>
      {loading && <Spinner />}
      <MultiFeed additionalFilters={multiFeedFilters} hideChannel={false} />

      {selectedReactionMomentId && (
        <ReactionPreviewModal
          openReview={openReactionReview}
          signalId={selectedReactionMomentId}
          onClose={() => setSelectedReactionMomentId(undefined)}
        />
      )}

      {signalReviewOpen && (
        <Portal>
          <FixedSignalReviewDiv>
            <SignalReviewPage initialSignalData={signalReviewInitData} />
          </FixedSignalReviewDiv>
        </Portal>
      )}
    </Spacing>
  )
}

// const useFeed = (
//   channelIds: string[],
//   startsAt: Date,
//   endsAt: Date,
//   sort: Sort
// ) => {
//   const [fetchReactionFeed, { data, loading, fetchMore }] =
//     useGetReactionFeedLazyQuery({
//       notifyOnNetworkStatusChange: true,
//     })

//   const loadNextPage = useCallback(() => {
//     const after = data?.moments?.pagination.endCursor
//     const hasNextPage = data?.moments?.pagination.hasNextPage || false
//     if (!hasNextPage || !after || !fetchMore) {
//       return
//     }
//     fetchMore({ variables: { after } })
//   }, [
//     data?.moments?.pagination.endCursor,
//     data?.moments?.pagination.hasNextPage,
//     fetchMore,
//   ])

//   useEffect(() => {
//     if (!channelIds) {
//       return
//     }

//     fetchReactionFeed({
//       variables: {
//         sort,
//         channelIds,
//         first: 10,
//         startsAt: { from: startsAt, to: endsAt },
//       },
//     })
//   }, [channelIds, fetchReactionFeed, startsAt, endsAt, sort])

//   const hasNextPage = useMemo(
//     () => data?.moments?.pagination.hasNextPage || false,
//     [data?.moments?.pagination.hasNextPage]
//   )

//   const reactionFeedItems: = (
//     (data?.moments?.data || []) as ReactionFeedDetailFragment[]
//   ).map((moment) => ({
//     id: moment.id,
//     matchId: 'match.id',
//     channel: {
//       id: moment.channel.id,
//       name: moment.channel.name,
//       profileImageUrl: moment.channel.profileImageUrl ?? '',
//     },
//     videoId: moment.video.id,
//     startsAt: moment.startsAt,
//     endsAt: moment.endsAt,
//     metadata: moment.metadata,
//     type: moment.type,
//     score: parseFloat(moment.metadata?.score ?? 0) * 100,
//     usedIn: moment.usedIn.length,
//   }))

//   return {
//     data: reactionFeedItems,
//     loading,
//     hasNextPage,
//     loadNextPage,
//   }
// }
