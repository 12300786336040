import styled from 'styled-components/macro'

export const TimeContainerDiv = styled.div`
  position: relative;
  pointer-events: none;
  width: 100%;
  height: 30px;
  color: #333;
  border-bottom: 1px solid #777e90;

  & > div {
    height: 100%;
    width: 0px;
    font-size: 12px;

    &:before {
      content: '';
      position: absolute;
      height: 7px;
      width: 1px;
      background-color: #777e90;
      bottom: 0;
    }
  }
`

export const TimeTextDiv = styled.div`
  position: absolute;
  width: 70px;
  top: 0;
  transform: translateX(-50%);
  white-space: nowrap;
  text-align: center;
  border-radius: 6px;

  span {
    font-size: 9px;
  }
`
