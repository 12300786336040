import momentjs from 'moment'
import { Text } from 'components/core/text'
import { useMemo } from 'react'
import { useGetVideosWithGameSectionsQuery } from '__generated__'
import { durationFormatHours } from 'utils/time'
import { Link } from 'react-router-dom'

interface Props {
  channelId: string
  gameId: string
  startTime: string
  endTime: string
}
export const VideoSegments = ({
  channelId,
  gameId,
  startTime,
  endTime,
}: Props) => {
  const { channelName, streamSegments } = useGetGameSegments(
    channelId,
    gameId,
    startTime,
    endTime
  )
  return (
    <div>
      <Text variant="h5">{channelName}</Text>
      <div>
        {streamSegments?.map((segment) => {
          return (
            <Link
              to={{
                pathname: '/moments/subsection',
                search: new URLSearchParams({
                  channelId: channelId,
                  startTime: momentjs(segment.startTime)
                    .subtract(5, 'minutes')
                    .toISOString(),
                  endTime: segment.endTime,
                }).toString(),
              }}
            >
              <div>
                <Text variant="text-2" as="div">
                  {momentjs(segment.startTime).format('MMM Do YY, h:mm:ss a')}
                </Text>
                <Text variant="text-2" as="div">
                  {durationFormatHours(
                    (momentjs(segment.endTime).valueOf() -
                      momentjs(segment.startTime).valueOf()) /
                      1000
                  )}
                </Text>
              </div>
            </Link>
          )
        })}
      </div>
    </div>
  )
}

const useGetGameSegments = (
  channelId: string,
  gameId: string,
  startTime: string,
  endTime: string
) => {
  const { data } = useGetVideosWithGameSectionsQuery({
    variables: {
      channelId,
      startTime,
      endTime,
    },
  })

  const streamSegments = useMemo(() => {
    if (!data?.channel?.streams.data) {
      return []
    }

    const results: { videoId: string; startTime: string; endTime: string }[] =
      []
    for (const stream of data.channel.streams.data) {
      const videoId = stream.video?.id ?? ''
      let idx = 0
      const gameSegments: {
        gameId: string
        startTime: string
        endTime: string
      }[] = []
      for (const segment of stream.gamesPlayed) {
        const seg = {
          gameId: segment.game.id,
          startTime: segment.startedAt,
          endTime: stream.gamesPlayed[idx + 1]?.startedAt ?? stream.endedAt,
        }
        gameSegments.push(seg)
        if (seg.gameId === gameId) {
          results.push({
            videoId,
            startTime: seg.startTime,
            endTime: seg.endTime,
          })
        }
        idx++
      }
    }
    return results

    // return .filter((stream) => {
    //   stream.gamesPlayed.filter(() => {})
    // })
  }, [data?.channel?.streams.data, gameId])

  return { channelName: data?.channel?.name ?? '', streamSegments }
}
