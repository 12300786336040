import { MenuItemWrapper } from './styled/menu-item-wrapper'
import NavLink from './styled/nav-link'

type Props = {
  to: string
  exact?: boolean
  children: string
}
const MenuItem = ({ children, to, exact }: Props) => {
  return (
    <MenuItemWrapper>
      <NavLink exact={exact} to={to} activeClassName="active" text={children}>
        {children}
      </NavLink>
    </MenuItemWrapper>
  )
}

export default MenuItem
