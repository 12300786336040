import { ImagePreview } from 'components/core/image-preview'
import styled from 'styled-components/macro'
import { media } from 'theme/media'

export const SidebarContainer = styled.div`
  margin-top: 64px;
  width: 272px;
  position: fixed;
  height: calc(100vh - 124px);

  ${media.phablet`
    width: 100%;
    margin-top: 32px;
  `}
`

export const SidebarContent = styled.div`
  box-shadow: ${({ theme }) => theme.elevation.depth3};
  border-radius: 16px;
  padding: 32px;
  background-color: ${({ theme }) => theme.colors.background.primary};
`

export const StyledSocialLink = styled.li`
  display: flex;
  margin: 8px 0;
  align-items: center;
`
export const StyledSocialLinkType = styled.span`
  width: 16px;
  height: 16px;
  margin-right: 8px;
`

export const StyledIconWrapper = styled.span`
  margin-left: 4px;
`

export const RecentlyPlayedContainer = styled.div`
  margin-top: 32px;
`

export const RecentlyPlayedGamesWrapper = styled.div`
  margin-top: 8px;
  display: flex;
  // justify-content: space-between;
`
export const StyledImagePreview = styled(ImagePreview)`
  border-radius: 8px;
  margin-right: 16px;
  flex-shrink: 0;

  &:last-child {
    margin-right: 0;
  }
`

export const Hr = styled.hr`
  border: 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutrals.n6};
  margin: 16px 0 24px;
`

export const PlayerListDiv = styled.div`
  position: relative;
  max-height: 200px;
  overflow: hidden;
`
