import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useTheme } from 'styled-components/macro'
import {
  GetOrganizationCollectionsListDocument,
  GetOrganizationCollectionsListQuery,
  useCreateEmptyMomentcollectionMutation,
} from '__generated__'

import { Button } from 'components/core/button'
import { Container, Spacing } from 'components/core/layout'
import { TextField } from 'components/core/textfield'
import { Dialog } from 'components/dialog'
import { Text } from 'components/core/text'
import { useRouteMatch } from 'react-router'
import { useOrganization } from 'page/organization/useOrganization'

type Props = {
  onClose?: () => void
}

export const CreateCollectionDialog = ({ onClose }: Props) => {
  const theme = useTheme()
  const { value, onChange, submitEnabled, onSubmit, loading, inputError } =
    useCreateCollectionDialog(onClose)

  return (
    <Dialog backdrop>
      <Dialog.Title>Create collection</Dialog.Title>
      <Dialog.Body>
        <TextField value={value} onChange={onChange} label="Name" />
        {inputError && (
          <Spacing top={8}>
            <Text variant="small" color={theme.colors.rose}>
              {inputError}
            </Text>
          </Spacing>
        )}
      </Dialog.Body>
      <Dialog.Footer inset>
        <Container direction="row" gap={16} justifyContent="flex-end">
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            onClick={onSubmit}
            disabled={!submitEnabled}
            isLoading={loading}
          >
            Create
          </Button>
        </Container>
      </Dialog.Footer>
    </Dialog>
  )
}

const useCreateCollectionDialog = (onClose?: () => void) => {
  const [mutation, { loading, error }] =
    useCreateEmptyMomentcollectionMutation()
  const { params } = useRouteMatch<{ organization: string }>()
  const { organization } = useOrganization(params.organization)

  const [inputError, setInputError] = useState<string | null>(null)
  const [submitEnabled, setSubmitEnabled] = useState(false)
  const [value, setValue] = useState('')
  const onChange = useCallback((evt: ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target
    setValue(value)
    setSubmitEnabled(value.length >= 3)
    setInputError(null)
  }, [])

  const onPressEsc = useCallback(
    (evt: KeyboardEvent) => {
      if (evt.code === 'Escape') {
        onClose && onClose()
      }
    },
    [onClose]
  )

  useEffect(() => {
    document.addEventListener('keydown', onPressEsc)

    return () => {
      document.removeEventListener('keydown', onPressEsc)
    }
  }, [onPressEsc])

  const onSubmit = useCallback(() => {
    if (!organization) {
      return
    }

    const fn = async () => {
      try {
        await mutation({
          variables: { title: value, organizationId: organization.id },
          update(cache, { data }) {
            if (!data?.createMomentCollection) {
              return
            }
            const res = cache.readQuery<GetOrganizationCollectionsListQuery>({
              query: GetOrganizationCollectionsListDocument,
              variables: { slug: organization.slug },
            })

            const collections = res?.organization?.collections.data || []
            const newCollections = [data.createMomentCollection, ...collections]

            cache.writeQuery({
              query: GetOrganizationCollectionsListDocument,
              data: {
                ...res,
                organization: {
                  ...res?.organization,
                  collections: {
                    ...res?.organization?.collections,
                    data: newCollections,
                  },
                },
              },
              variables: { slug: organization.slug },
            })
          },
        })
        onClose && onClose()
      } catch (err) {}
    }
    fn()
  }, [value, mutation, onClose, organization])

  useEffect(() => {
    if (!error) {
      return
    }

    if (error.message === 'duplicate entry') {
      setInputError('A collection with that name already exists')
    }
  }, [error])

  return useMemo(
    () => ({
      loading,
      value,
      onChange,
      onSubmit,
      submitEnabled,
      inputError,
    }),
    [value, onChange, loading, onSubmit, submitEnabled, inputError]
  )
}
