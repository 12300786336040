import React, { createContext, useContext, useReducer } from 'react'

import {
  ExportFormat,
  ExportTemplate,
  Rect,
  Scene,
  TemplateCrop,
  TemplateCropSettings,
} from 'core/exporter/constants'
import { Justifications } from './template-sidebar/addons/killfeed'
import { KillfeedTypes } from 'core/video-addons/kill-feed'

export enum CropSettingType {
  Full = 'Full',
  Facecam = 'Facecam',
  Custom = 'Custom',
}

export enum CropSizeType {
  Contain = 'Contain',
  Cover = 'Cover',
}

export interface CropSettings {
  title: string
  videoId?: string
  type: CropSettingType
  size?: CropSizeType
  color?: string
  rect: Rect
}

export enum ComposerModes {
  Preview = 'Preview',
  EditScene = 'EditScene',
}

export enum SceneEditingModes {
  Locked = 'Locked',
  Template = 'Template',
  Content = 'Content',
}

export interface MatchIntroOptions {
  y: number
}

export interface KillfeedOptions {
  y: number
  justify: Justifications
  type: KillfeedTypes
}

export interface PlayerDisplayOptions {
  y: number
  justify: Justifications
}

export interface KDAOptions {
  x: number
  y: number
}

export interface SubtitlesOptions {
  y: number
}

interface ComposerState {
  momentId: string
  primaryVideoId: string
  editId: string
  selectedTemplate?: ExportTemplate
  selectedFormat?: ExportFormat
  cropSettings: { [key: string]: CropSettings }
  scenes: Scene[]
  previewRect?: DOMRect
  previewContainerRect?: DOMRect
  selectedTemplateCrop?: TemplateCrop
  title: string
  mode: ComposerModes
  sceneEditingMode: SceneEditingModes
  selectedSceneId?: string
  valorantMatchId?: string
  videosData: {
    videoId: string
    userId: string
  }[]
  layersLocked: boolean
  killfeed: boolean
  killfeedOptions: KillfeedOptions
  playerDisplayAddon: boolean
  playerDisplayOptions: PlayerDisplayOptions
  KDAAddon: boolean
  KDAOptions: KDAOptions
  matchIntroAddon: boolean
  matchIntroOptions: MatchIntroOptions
  subtitlesAddon: boolean
  subtitlesOptions: SubtitlesOptions
}

const INITIAL_STATE: ComposerState = {
  momentId: '',
  primaryVideoId: '',
  editId: '',
  selectedTemplate: undefined,
  selectedFormat: undefined,
  cropSettings: {},
  scenes: [],
  previewRect: undefined,
  previewContainerRect: undefined,
  selectedTemplateCrop: undefined,
  title: '',
  mode: ComposerModes.EditScene,
  sceneEditingMode: SceneEditingModes.Locked,
  selectedSceneId: undefined,
  valorantMatchId: undefined,
  videosData: [],
  layersLocked: false,
  killfeed: false,
  killfeedOptions: {
    justify: Justifications.right,
    y: 630,
    type: 'default',
  },
  playerDisplayAddon: false,
  playerDisplayOptions: {
    justify: Justifications.left,
    y: 1550,
  },
  KDAAddon: false,
  KDAOptions: {
    x: 540,
    y: 545,
  },
  matchIntroAddon: false,
  matchIntroOptions: {
    y: 550,
  },
  subtitlesAddon: false,
  subtitlesOptions: {
    y: 550,
  },
}

enum ACTION_TYPES {
  SET_TEMPLATE = 'SET_TEMPLATE',
  SET_FORMAT = 'SET_FORMAT',
  SET_MOMENT_ID = 'SET_MOMENT_ID',
  SET_VIDEOS_DATA = 'SET_VIDEOS_DATA',
  SET_EDIT_ID = 'SET_EDIT_ID',
  SET_CROP_SETTINGS = 'SET_CROP_SETTINGS',
  SET_PRIMARY_VIDEO_ID = 'SET_PRIMARY_VIDEO_ID',
  ADD_SCENE = 'ADD_SCENE',
  EDIT_SCENE = 'EDIT_SCENE',
  DELETE_SCENE = 'DELETE_SCENE',
  CLEAR_SCENES = 'CLEAR_SCENES',
  SET_PREVIEW_RECT = 'SET_PREVIEW_RECT',
  SET_PREVIEW_CONTAINER_RECT = 'SET_PREVIEW_CONTAINER_RECT',
  SET_SELECTED_TEMPLATE_CROP = 'SET_SELECTED_TEMPLATE_CROP',
  SET_TEMPLATE_CROP_RECT = 'SET_TEMPLATE_CROP_RECT',
  SET_CROP_RECT_DATA = 'SET_CROP_RECT_DATA',
  SET_TITLE = 'SET_TITLE',
  SET_SCENE_EDITING_MODE = 'SET_SCENE_EDITING_MODE',
  SET_SELECTED_SCENE_ID = 'SET_SELECTED_SCENE_ID',
  ADD_TEMPLATE_CROP = 'ADD_TEMPLATE_CROP',
  REMOVE_TEMPLATE_CROP = 'REMOVE_TEMPLATE_CROP',
  ORDER_TEMPLATE_CROPS = 'ORDER_TEMPLATE_CROPS',
  SET_PRIMARY_CROP = 'SET_PRIMARY_CROP',
  SET_SCENE_ORDER = 'SET_SCENE_ORDER',
  SET_VALORANT_MATCH_ID = 'SET_VALORANT_MATCH_ID',
  SET_KILLFEED = 'SET_KILLFEED',
  SET_KILLFEED_OPTIONS = 'SET_KILLFEED_OPTIONS',
  SET_PLAYER_DISPLAY = 'SET_PLAYER_DISPLAY',
  SET_PLAYER_DISPLAY_OPTIONS = 'SET_PLAYER_DISPLAY_OPTIONS',
  SET_KDA = 'SET_KDA',
  SET_KDA_OPTIONS = 'SET_KDA_OPTIONS',
  SET_MATCH_INTRO = 'SET_MATCH_INTRO',
  SET_MATCH_INTRO_OPTIONS = 'SET_MATCH_INTRO_OPTIONS',
  SET_SUBTITLES = 'SET_SUBTITLES',
  SET_SUBTITLES_OPTIONS = 'SET_SUBTITLES_OPTIONS',
  SET_LAYERS_LOCKED = 'SET_LAYERS_LOCKED',
}

interface SetTitleAction {
  type: ACTION_TYPES.SET_TITLE
  payload: {
    title: string
  }
}

interface SetTemplateAction {
  type: ACTION_TYPES.SET_TEMPLATE
  payload: {
    selectedTemplate?: ExportTemplate
  }
}

interface SetFormatAction {
  type: ACTION_TYPES.SET_FORMAT
  payload: {
    selectedFormat?: ExportFormat
  }
}

interface SetMomentIdAction {
  type: ACTION_TYPES.SET_MOMENT_ID
  payload: {
    momentId: string
  }
}

interface SetEditIdAction {
  type: ACTION_TYPES.SET_EDIT_ID
  payload: {
    editId: string
  }
}

interface SetCropSettingsAction {
  type: ACTION_TYPES.SET_CROP_SETTINGS
  payload: {
    cropSettings: CropSettings
  }
}

interface SetPrimaryVideoIdAction {
  type: ACTION_TYPES.SET_PRIMARY_VIDEO_ID
  payload: {
    primaryVideoId: string
  }
}

interface AddSceneAction {
  type: ACTION_TYPES.ADD_SCENE
  payload: {
    scene: Scene
  }
}

interface EditSceneAction {
  type: ACTION_TYPES.EDIT_SCENE
  payload: {
    idx: number
    scene: Scene
  }
}

interface SetVideosDataAction {
  type: ACTION_TYPES.SET_VIDEOS_DATA
  payload: {
    videosData: { videoId: string; userId: string }[]
  }
}

interface DeleteSceneAction {
  type: ACTION_TYPES.DELETE_SCENE
  payload: {
    idx: number
  }
}

interface ClearScenesAction {
  type: ACTION_TYPES.CLEAR_SCENES
}

interface SetSceneOrderAction {
  type: ACTION_TYPES.SET_SCENE_ORDER
  payload: {
    sceneIds: string[]
  }
}

interface SetPreviewRectAction {
  type: ACTION_TYPES.SET_PREVIEW_RECT
  payload: {
    previewRect?: DOMRect
  }
}

interface SetPreviewContainerRectAction {
  type: ACTION_TYPES.SET_PREVIEW_CONTAINER_RECT
  payload: {
    previewContainerRect?: DOMRect
  }
}

interface SetSelectedTemplateCropAction {
  type: ACTION_TYPES.SET_SELECTED_TEMPLATE_CROP
  payload: {
    selectedTemplateCrop?: TemplateCrop
  }
}

interface SetCropRectDataAction {
  type: ACTION_TYPES.SET_CROP_RECT_DATA
  payload: {
    title: string
    rect: Rect
  }
}

// This one is focused on the container rect
interface SetTemplateCropRectAction {
  type: ACTION_TYPES.SET_TEMPLATE_CROP_RECT
  payload: {
    title: string
    rect: Rect
  }
}

interface SetSceneEditingModeAction {
  type: ACTION_TYPES.SET_SCENE_EDITING_MODE
  payload: {
    sceneEditingMode: SceneEditingModes
  }
}

interface SetSelectedSceneIdAction {
  type: ACTION_TYPES.SET_SELECTED_SCENE_ID
  payload: {
    selectedSceneId?: string
  }
}

interface AddTemplateCropAction {
  type: ACTION_TYPES.ADD_TEMPLATE_CROP
  payload: {
    templateCrop: TemplateCropSettings
  }
}

interface RemoveTemplateCropAction {
  type: ACTION_TYPES.REMOVE_TEMPLATE_CROP
  payload: {
    title: string
  }
}

interface OrderTemplateCropsAction {
  type: ACTION_TYPES.ORDER_TEMPLATE_CROPS
  payload: {
    nextIdOrder: string[]
  }
}

interface SetPrimaryCropAction {
  type: ACTION_TYPES.SET_PRIMARY_CROP
  payload: {
    title: string
  }
}

interface SetValorantMatchIdAction {
  type: ACTION_TYPES.SET_VALORANT_MATCH_ID
  payload: {
    valorantMatchId: string | undefined
  }
}

interface SetKillfeedAction {
  type: ACTION_TYPES.SET_KILLFEED
  payload: {
    killfeed: boolean
  }
}

interface SetKillfeedOptionsAction {
  type: ACTION_TYPES.SET_KILLFEED_OPTIONS
  payload: {
    options: Partial<KillfeedOptions>
  }
}

interface SetPlayerDisplayAction {
  type: ACTION_TYPES.SET_PLAYER_DISPLAY
  payload: {
    playerDisplay: boolean
  }
}

interface SetPlayerDisplayOptionsAction {
  type: ACTION_TYPES.SET_PLAYER_DISPLAY_OPTIONS
  payload: {
    options: Partial<PlayerDisplayOptions>
  }
}

interface SetKDAAction {
  type: ACTION_TYPES.SET_KDA
  payload: {
    KDA: boolean
  }
}

interface SetKDAOptionsAction {
  type: ACTION_TYPES.SET_KDA_OPTIONS
  payload: {
    options: Partial<KDAOptions>
  }
}

interface SetMatchIntroAction {
  type: ACTION_TYPES.SET_MATCH_INTRO
  payload: {
    matchIntro: boolean
  }
}

interface SetMatchIntroOptionsAction {
  type: ACTION_TYPES.SET_MATCH_INTRO_OPTIONS
  payload: {
    options: Partial<MatchIntroOptions>
  }
}

interface SetSubtitlesAction {
  type: ACTION_TYPES.SET_SUBTITLES
  payload: {
    subtitles: boolean
  }
}

interface SetSubtitlesOptionsAction {
  type: ACTION_TYPES.SET_SUBTITLES_OPTIONS
  payload: {
    options: Partial<SubtitlesOptions>
  }
}

interface SetLayersLockedAction {
  type: ACTION_TYPES.SET_LAYERS_LOCKED
  payload: {
    layersLocked: boolean
  }
}

type ComposerActions =
  | SetTemplateAction
  | SetFormatAction
  | SetMomentIdAction
  | SetCropSettingsAction
  | SetPrimaryVideoIdAction
  | AddSceneAction
  | EditSceneAction
  | DeleteSceneAction
  | ClearScenesAction
  | SetPreviewRectAction
  | SetPreviewContainerRectAction
  | SetSelectedTemplateCropAction
  | SetTemplateCropRectAction
  | SetCropRectDataAction
  | SetTitleAction
  | SetSceneEditingModeAction
  | SetSelectedSceneIdAction
  | AddTemplateCropAction
  | RemoveTemplateCropAction
  | OrderTemplateCropsAction
  | SetPrimaryCropAction
  | SetSceneOrderAction
  | SetValorantMatchIdAction
  | SetEditIdAction
  | SetVideosDataAction
  | SetKillfeedAction
  | SetKillfeedOptionsAction
  | SetPlayerDisplayAction
  | SetPlayerDisplayOptionsAction
  | SetKDAAction
  | SetKDAOptionsAction
  | SetMatchIntroAction
  | SetMatchIntroOptionsAction
  | SetSubtitlesAction
  | SetSubtitlesOptionsAction
  | SetLayersLockedAction

type Dispatch = (action: ComposerActions) => void

const reducer = (
  state: ComposerState,
  action: ComposerActions
): ComposerState => {
  switch (action.type) {
    case ACTION_TYPES.SET_TITLE: {
      return {
        ...state,
        title: action.payload.title,
      }
    }

    case ACTION_TYPES.SET_TEMPLATE: {
      return {
        ...state,
        selectedTemplate: action.payload.selectedTemplate,
        cropSettings: {},
      }
    }

    case ACTION_TYPES.SET_FORMAT: {
      return {
        ...state,
        selectedFormat: action.payload.selectedFormat,
      }
    }

    case ACTION_TYPES.SET_MOMENT_ID: {
      return {
        ...state,
        momentId: action.payload.momentId,
      }
    }

    case ACTION_TYPES.SET_EDIT_ID: {
      return {
        ...state,
        editId: action.payload.editId,
      }
    }

    case ACTION_TYPES.SET_CROP_SETTINGS: {
      const data = action.payload.cropSettings
      const nextCropSettings = { ...state.cropSettings, [data.title]: data }

      return {
        ...state,
        cropSettings: nextCropSettings,
      }
    }

    case ACTION_TYPES.SET_PRIMARY_CROP: {
      if (!state.selectedTemplate || !state.selectedTemplateCrop) return state
      const nextCrops = state.selectedTemplate.crops.map((crop) => ({
        ...crop,
        primary: crop.title === action.payload.title,
      }))

      // another spot that this should be derived...
      const selectedTemplateCrop = nextCrops.find(
        ({ title }) => title === action.payload.title
      ) as TemplateCropSettings

      const nextState = {
        ...state,
        selectedTemplate: {
          ...state.selectedTemplate,
          crops: nextCrops,
        },
        selectedTemplateCrop: { ...selectedTemplateCrop },
      }

      return nextState
    }

    case ACTION_TYPES.SET_PRIMARY_VIDEO_ID: {
      return {
        ...state,
        primaryVideoId: action.payload.primaryVideoId,
      }
    }

    case ACTION_TYPES.ORDER_TEMPLATE_CROPS: {
      if (!state.selectedTemplate) return state

      const nextTemplateCrops = action.payload.nextIdOrder.map((id) =>
        state.selectedTemplate?.crops.find(({ localId }) => localId === id)
      ) as TemplateCropSettings[]
      return {
        ...state,
        selectedTemplate: {
          ...state.selectedTemplate,
          crops: nextTemplateCrops,
        },
      }
    }

    case ACTION_TYPES.SET_TEMPLATE_CROP_RECT: {
      if (!state.selectedTemplate) return { ...state }
      const { title, rect } = action.payload

      const nextTemplateCrops = state.selectedTemplate.crops.map((tc) => {
        const nextPosition = {
          position: tc.title === title ? rect : tc.position,
        }

        return {
          ...tc,
          ...nextPosition,
        }
      })

      // this should actually just be derived...
      const nextSelectedTemplateCrop = nextTemplateCrops.find((tc) => {
        return tc.title === state.selectedTemplateCrop?.title
      })

      return {
        ...state,
        selectedTemplateCrop: nextSelectedTemplateCrop,
        selectedTemplate: {
          ...state.selectedTemplate,
          crops: nextTemplateCrops,
        },
      }
    }

    case ACTION_TYPES.ADD_SCENE: {
      const nextScenes = [...state.scenes, action.payload.scene]

      return {
        ...state,
        scenes: nextScenes,
      }
    }

    case ACTION_TYPES.EDIT_SCENE: {
      const { idx } = action.payload
      const nextScenes = state.scenes.map((item, i) => {
        return idx === i ? action.payload.scene : item
      })

      return {
        ...state,
        scenes: nextScenes,
      }
    }

    case ACTION_TYPES.DELETE_SCENE: {
      const { idx } = action.payload
      const nextScenes = state.scenes.filter((_, i) => {
        return idx !== i
      })

      return {
        ...state,
        scenes: nextScenes,
      }
    }

    case ACTION_TYPES.CLEAR_SCENES: {
      return {
        ...state,
        scenes: [],
      }
    }

    case ACTION_TYPES.SET_SCENE_ORDER: {
      const { sceneIds } = action.payload
      const nextScenes = sceneIds.map((orderId, idx) => {
        return {
          ...state.scenes.find(({ id }) => orderId === id),
          index: idx,
        }
      }) as Scene[]

      return {
        ...state,
        scenes: nextScenes,
      }
    }

    case ACTION_TYPES.SET_PREVIEW_RECT: {
      return {
        ...state,
        previewRect: action.payload.previewRect,
      }
    }

    case ACTION_TYPES.SET_PREVIEW_CONTAINER_RECT: {
      return {
        ...state,
        previewContainerRect: action.payload.previewContainerRect,
      }
    }

    case ACTION_TYPES.SET_SELECTED_TEMPLATE_CROP: {
      return {
        ...state,
        selectedTemplateCrop: action.payload.selectedTemplateCrop,
      }
    }

    case ACTION_TYPES.SET_CROP_RECT_DATA: {
      const { title, rect } = action.payload
      const nextCropSettings = Object.values(state.cropSettings).reduce(
        (memo, item) => {
          const nextSetting =
            item.title === title
              ? { ...state.cropSettings[item.title], rect }
              : { ...state.cropSettings[item.title] }
          return {
            ...memo,
            [item.title]: nextSetting,
          }
        },
        {}
      )

      return {
        ...state,
        cropSettings: nextCropSettings,
      }
    }

    case ACTION_TYPES.SET_SCENE_EDITING_MODE: {
      const { sceneEditingMode } = action.payload

      return {
        ...state,
        sceneEditingMode,
      }
    }

    case ACTION_TYPES.SET_SELECTED_SCENE_ID: {
      return {
        ...state,
        selectedSceneId: action.payload.selectedSceneId,
      }
    }

    case ACTION_TYPES.ADD_TEMPLATE_CROP: {
      const { templateCrop } = action.payload
      if (!state.selectedTemplate) return { ...state }

      return {
        ...state,
        selectedTemplate: {
          ...state.selectedTemplate,
          crops: [...state.selectedTemplate.crops, templateCrop],
        },
      }
    }

    case ACTION_TYPES.REMOVE_TEMPLATE_CROP: {
      if (!state.selectedTemplate) return state
      const { title } = action.payload
      const nextCrops = state.selectedTemplate.crops.filter(
        (crop) => crop.title !== title
      )
      const nextCropSettings = { ...state.cropSettings }
      delete nextCropSettings[title]

      return {
        ...state,
        selectedTemplate: {
          ...state.selectedTemplate,
          crops: nextCrops,
        },
        cropSettings: nextCropSettings,
      }
    }

    case ACTION_TYPES.SET_VALORANT_MATCH_ID: {
      return {
        ...state,
        valorantMatchId: action.payload.valorantMatchId,
      }
    }

    case ACTION_TYPES.SET_VIDEOS_DATA: {
      return {
        ...state,
        videosData: action.payload.videosData,
      }
    }

    case ACTION_TYPES.SET_KILLFEED: {
      return {
        ...state,
        killfeed: action.payload.killfeed,
      }
    }

    case ACTION_TYPES.SET_KILLFEED_OPTIONS: {
      return {
        ...state,
        killfeedOptions: {
          ...state.killfeedOptions,
          ...action.payload.options,
        },
      }
    }

    case ACTION_TYPES.SET_PLAYER_DISPLAY: {
      return {
        ...state,
        playerDisplayAddon: action.payload.playerDisplay,
      }
    }

    case ACTION_TYPES.SET_PLAYER_DISPLAY_OPTIONS: {
      return {
        ...state,
        playerDisplayOptions: {
          ...state.playerDisplayOptions,
          ...action.payload.options,
        },
      }
    }

    case ACTION_TYPES.SET_KDA: {
      return {
        ...state,
        KDAAddon: action.payload.KDA,
      }
    }

    case ACTION_TYPES.SET_KDA_OPTIONS: {
      return {
        ...state,
        KDAOptions: {
          ...state.KDAOptions,
          ...action.payload.options,
        },
      }
    }

    case ACTION_TYPES.SET_MATCH_INTRO: {
      return {
        ...state,
        matchIntroAddon: action.payload.matchIntro,
      }
    }

    case ACTION_TYPES.SET_MATCH_INTRO_OPTIONS: {
      return {
        ...state,
        matchIntroOptions: {
          ...state.matchIntroOptions,
          ...action.payload.options,
        },
      }
    }

    case ACTION_TYPES.SET_SUBTITLES: {
      return {
        ...state,
        subtitlesAddon: action.payload.subtitles,
      }
    }

    case ACTION_TYPES.SET_SUBTITLES_OPTIONS: {
      return {
        ...state,
        subtitlesOptions: {
          ...state.subtitlesOptions,
          ...action.payload.options,
        },
      }
    }

    case ACTION_TYPES.SET_LAYERS_LOCKED: {
      return {
        ...state,
        layersLocked: action.payload.layersLocked,
      }
    }

    default:
      return state
  }
}

const ComposerContext = createContext<ComposerState | undefined>(undefined)
const ComposerUpdaterContext = createContext<Dispatch | undefined>(undefined)

export const ComposerProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE)

  return (
    <ComposerContext.Provider value={state}>
      <ComposerUpdaterContext.Provider value={dispatch}>
        {children}
      </ComposerUpdaterContext.Provider>
    </ComposerContext.Provider>
  )
}

export const useComposerState = () => {
  const context = useContext(ComposerContext)
  if (context === undefined) {
    throw new Error('useComposerState must be used within a ComposerProvider')
  }
  return context
}

export const useComposerUpdater = () => {
  const context = useContext(ComposerUpdaterContext)
  if (context === undefined) {
    throw new Error('useComposerUpdater must be used within a ComposerProvider')
  }
  return context
}

useComposerUpdater.ACTION_TYPES = ACTION_TYPES
