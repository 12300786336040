import { useMemo } from 'react'

import { HStack, Spacing } from 'components/core/layout'
import { Spinner } from 'components/core/spinner'
import { Text } from 'components/core/text'
import { VideoGrid } from 'page/channel/videos/styled'
import { VideoCard } from 'page/channel/videos/video-card'
import { StreamingPlatform, useGetChannelVideosQuery } from '__generated__'
import { useRosterData } from './hooks'
import { Link, useParams } from 'react-router-dom'
import { IconButton } from 'core/composer/template-sidebar/styled'
import { transparentize } from 'polished'
import { useTheme } from 'styled-components/macro'
import { ReactComponent as RightArrowIcon } from 'icons/svgs/solid/arrow-circle-right.svg'

interface Props {
  channelId?: string
}

export const RosterVideoList = ({ channelId }: Props) => {
  const theme = useTheme()
  const { channelName } = useParams<{ channelName: string }>()
  const { roster } = useRosterData()
  const { data: videoData } = useGetChannelVideosQuery({
    variables: {
      channelId: channelId ?? '',
      platform: StreamingPlatform.Twitch,
      first: 9,
    },
    skip: channelId === undefined,
  })

  const selectedUserChannelUrl = useMemo(() => {
    const selectedUser = roster?.find((user) => user.id === channelId)
    if (selectedUser?.login) {
      return `/roster/${selectedUser.login}`
    }
    return undefined
  }, [channelId, roster])

  const videos = useMemo(
    () =>
      (videoData?.channel?.videos?.data || []).filter(
        (v) => v.stream?.endedAt !== null
      ),
    [videoData]
  )

  return channelId ? (
    <div>
      <Link to={`/roster/${channelName}/videos`}>
        <Text variant="h4">Recent Videos</Text>
      </Link>

      {videos?.length === 0 && (
        <HStack justifyContent="center" fullWidth>
          <Spacing top={16}>
            <Text variant="text-2">No Videos Available</Text>
          </Spacing>
        </HStack>
      )}
      <VideoGrid>
        {videos.slice(0, 4).map((video) => (
          <VideoCard
            key={video.id}
            id={video.id}
            title={video.title}
            publishedAt={video.publishedAt}
            thumbnailUrl={video.thumbnailUrl || undefined}
            url={
              selectedUserChannelUrl
                ? `${selectedUserChannelUrl}/videos/${video.id}`
                : ''
            }
          />
        ))}
      </VideoGrid>
      {videos?.length > 0 && (
        <Spacing top={16}>
          <HStack justifyContent="flex-end">
            <Link to={`/roster/${channelName}/videos`}>
              <IconButton
                color={transparentize(0.2, theme.colors.static.neutrals.n4)}
                onClick={() => {}}
              >
                <span>View All Videos</span>
                <RightArrowIcon
                  fill={theme.colors.static.neutrals.n8}
                  width={16}
                />
              </IconButton>
            </Link>
          </HStack>
        </Spacing>
      )}
    </div>
  ) : (
    <HStack fullHeight alignItems="center" justifyContent="center">
      <Spinner size={32}></Spinner>
    </HStack>
  )
}
