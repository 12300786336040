import { useTheme } from 'styled-components/macro'
import { useAuth0 } from '@auth0/auth0-react'
import { darken } from 'polished'

import { LOGOUT_PATH } from 'core/auth/constants'
import { Button } from 'components/core/button'

import { ProfileItem } from './profile-item'
import { DarkModeItem } from './dark-mode-list-item'
import {
  ProfileMenuContainer,
  ProfileSubMenu,
  ProfileSubMenuSeparator,
} from './styled'
import { useGetCurrentOrganizationQuery } from '__generated__'
import { RegionItem } from './region-item'

export const ProfileMenu = () => {
  const { user } = useAuth0()
  const theme = useTheme()
  const { data } = useGetCurrentOrganizationQuery()
  return (
    <ProfileMenuContainer>
      <ProfileItem
        name={user?.name || ''}
        organization={data?.viewer?.organization || undefined}
        imageUrl={user?.picture || ''}
      />
      <ProfileSubMenu>
        <li>
          <ProfileItem
            name={user?.name || ''}
            organization={data?.viewer?.organization || undefined}
            imageUrl={user?.picture || ''}
          />
        </li>
        <ProfileSubMenuSeparator />
        <DarkModeItem />
        <RegionItem />
        <ProfileSubMenuSeparator />
        <li>
          <Button
            noPadding
            regular
            as={'a'}
            variant={'plain'}
            color={theme.colors.rose}
            hoverColor={darken(0.2, theme.colors.rose)}
            href={LOGOUT_PATH}
          >
            Log out
          </Button>
        </li>
      </ProfileSubMenu>
    </ProfileMenuContainer>
  )
}
