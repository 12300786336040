import { Link } from 'react-router-dom'
import SimpleBar from 'simplebar-react'

import { HStack, VStack } from 'components/core/layout'
import { Text } from 'components/core/text'
import { ValorantFeedFilters } from 'core/feed/components/filters/valorant-filters'

import { useChannelCollectionData } from '..'
import { Hr, PlayerListDiv, SidebarContainer, SidebarContent } from './styled'

interface OrganizationSidebarProps {
  collectionId: string
}

export const OrganizationSidebar = ({
  collectionId,
}: OrganizationSidebarProps) => {
  const { title, channels } = useChannelCollectionData(collectionId)

  return (
    <section>
      <SidebarContainer>
        <SidebarContent>
          {/* <ChannelAvatar src={profileImageUrl || undefined} /> */}
          <Text variant="h3" as={'h3'} textAlign={'center'}>
            {title}
          </Text>
          <Hr />
          <PlayerListDiv>
            <SimpleBar style={{ maxHeight: 200, width: `100%` }}>
              <VStack gap={4}>
                {channels?.map(({ login, displayName, profileImageUrl }) => (
                  <Link to={`/channel/${login}`}>
                    <HStack gap={8}>
                      <img
                        alt="roster icon"
                        src={profileImageUrl ?? ''}
                        width={20}
                        height={20}
                        style={{ borderRadius: '50%' }}
                      />
                      <Text variant="text-3" weight="semibold">
                        {displayName}
                      </Text>
                    </HStack>
                  </Link>
                ))}
              </VStack>
            </SimpleBar>
          </PlayerListDiv>
        </SidebarContent>
        <SidebarContent
          style={{
            position: 'relative',
            maxHeight: 'calc(100vh - 600px)',
            marginTop: 24,
            overflow: 'hidden',
          }}
        >
          <ValorantFeedFilters />
        </SidebarContent>
      </SidebarContainer>
    </section>
  )
}
