import { useCallback, useMemo } from 'react'
import { useTheme } from 'styled-components/macro'
import {
  AdminTwitchUserSourceFragment,
  useAddTwitchUserMutation,
} from '__generated__'

import { Button } from 'components/core/button'
import { ImagePreview } from 'components/core/image-preview'
import { Card, Container, HStack, Spacing } from 'components/core/layout'
import { Text } from 'components/core/text'

type Props = {
  user: AdminTwitchUserSourceFragment
}

export const TwitchUserCard = ({ user }: Props) => {
  const theme = useTheme()
  const { name, views, profilePictureUrl, execute, loading, completed } =
    useTwitchUser(user)
  return (
    <Card neutralOffset={2} body>
      <HStack alignItems="center">
        <Container>
          <ImagePreview width={64} src={profilePictureUrl || ''} />
        </Container>
        <Container flex="1">
          <Spacing left={16}>
            <Text as="div" variant="text-2">
              {name}
            </Text>
            <Text color={theme.colors.neutrals.n4} as="div" variant="text-3">
              {'Views: '}
              <Text variant="text-3" mono>
                {views}
              </Text>
            </Text>
          </Spacing>
        </Container>
        <Container>
          <Button
            onClick={execute}
            isLoading={loading}
            disabled={loading || completed}
            variant={!completed ? 'primary' : 'secondary'}
          >
            {!completed ? 'Add' : 'Added'}
          </Button>
        </Container>
      </HStack>
    </Card>
  )
}

const useTwitchUser = (user: AdminTwitchUserSourceFragment) => {
  const { id, name, views, profilePictureUrl } = user

  const [mutate, { loading, called, data }] = useAddTwitchUserMutation()

  const execute = useCallback(() => {
    mutate({
      variables: { id, name },
      refetchQueries: 'active',
      update(cache) {
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'channels' })
      },
    })
  }, [id, name, mutate])

  return useMemo(
    () => ({
      name,
      views: (views || 0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 '),
      profilePictureUrl,
      execute,
      loading,
      completed: called && data !== null && data !== undefined,
    }),
    [execute, name, views, profilePictureUrl, loading, data, called]
  )
}
