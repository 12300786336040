import { useMemo } from 'react'

import { SyncedVideo } from 'core/editor/synced-video'

import { useValorantMatchPreviewData, ValorantVideoInfo } from '.'
import { useValorantMatchEditorState } from './context'
import { ValorantMatchPreviewOption } from './player-option'
import {
  EditorPlayerContainerDiv,
  MainPlayerDiv,
  MainVideoWrapperDiv,
  SecondaryPlayerContainerDiv,
} from 'core/shared/video-player/styled'
import { AspectRatioContainer } from 'components/aspect-ratio/container'
import { usePlayerList } from './timeline/hooks'
import { ValorantVideoSyncControls } from './sync-controls'
import { useEditor } from 'core/editor/hooks'
import { useRemoteMatchSync } from './hooks'
import SimpleBar from 'simplebar-react'
import { usePlayerLayout } from 'core/shared/video-player/hooks'

interface Props {
  matchId: string
  userId: string
  availableDimensions: { width: number; height: number }
}

export const ValorantMatchEditorPlayer = ({
  matchId,
  availableDimensions,
}: Props) => {
  const { playing } = useEditor()
  const { videoData } = useValorantMatchPreviewData(matchId)
  const { playerList } = usePlayerList()
  const { selectedVideoInfo, mainPerspectiveInfo, primaryVideoId } =
    useValorantMatchEditorState()
  const { offset } = useRemoteMatchSync(
    matchId,
    selectedVideoInfo?.videoId ?? '',
    primaryVideoId
  )

  const orderedVideoData = useMemo(() => {
    if (!videoData) return undefined
    return playerList
      .map(({ account }) => {
        return videoData?.find(({ accountId }) => account.id === accountId)
      })
      .filter((vd) => vd !== undefined) as ValorantVideoInfo[]
  }, [playerList, videoData])

  const { mainPlayerStyles, secondaryContainerStyles, secondaryItemStyles } =
    usePlayerLayout(availableDimensions, playerList.length)

  // to keep it from rerendering
  const playerSettings = useMemo(
    () => ({
      muted: false,
    }),
    []
  )

  return selectedVideoInfo && mainPerspectiveInfo ? (
    <EditorPlayerContainerDiv>
      <MainPlayerDiv style={mainPlayerStyles}>
        <AspectRatioContainer width={16} height={9}>
          <MainVideoWrapperDiv>
            <SyncedVideo
              id={`main_${selectedVideoInfo.videoId}`}
              primaryVideoId={mainPerspectiveInfo.videoId}
              videoId={selectedVideoInfo.videoId}
              offset={offset ? offset / 1000 : undefined}
              playerOptions={playerSettings}
              // if it is the only video, it should be the controller
              controller={playerList.length === 1}
              // qualityHeight={480}
            />
            {!playing && (
              <ValorantVideoSyncControls videoId={selectedVideoInfo.videoId} />
            )}
          </MainVideoWrapperDiv>
        </AspectRatioContainer>
      </MainPlayerDiv>
      {playerList.length > 1 && (
        <SecondaryPlayerContainerDiv style={secondaryContainerStyles}>
          <SimpleBar style={secondaryContainerStyles}>
            {orderedVideoData?.map((vd) => (
              <ValorantMatchPreviewOption
                key={vd.videoId}
                videoData={vd}
                style={secondaryItemStyles}
              />
            ))}
          </SimpleBar>
        </SecondaryPlayerContainerDiv>
      )}
    </EditorPlayerContainerDiv>
  ) : null
}
