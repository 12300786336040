import { useTheme } from 'styled-components/macro'
import momentjs from 'moment'

import { Container, HStack, Spacing, VStack } from 'components/core/layout'
import { Text } from 'components/core/text'

import {
  GridItemChannelImg,
  GridItemContainer,
  GridItemImageContainer,
  GridItemInfo,
  GridItemThumbnailImg,
} from './styled'
import { useMemo } from 'react'
import { Rating } from '__generated__'

type Props = {
  thumbnail?: string
  channel: {
    id: string
    name: string
    profileImageUrl?: string
  }
  at: string
  editCount: number
  ratings: Rating[]
  title?: string
  onClick?: () => void
}

export const GridItemMoment = ({
  thumbnail,
  channel,
  onClick,
  editCount,
  title,
  at,
}: Props) => {
  const theme = useTheme()

  const readableTime = useMemo(() => {
    return new Date(at).toLocaleString()
  }, [at])

  return (
    <GridItemContainer onClick={onClick}>
      <GridItemImageContainer>
        <GridItemThumbnailImg src={thumbnail} alt="" />
        {/* {synced ? <SyncedIndicatorDiv /> : null} */}
      </GridItemImageContainer>
      <GridItemInfo>
        <VStack>
          <Spacing bottom="12px">
            <Text variant="text-2" weight="semibold">
              {title}
            </Text>
          </Spacing>
          <Spacing bottom="4px">
            <HStack justifyContent="space-between">
              <Container direction="row" alignItems="center">
                <GridItemChannelImg src={channel.profileImageUrl} />
                <Spacing left="8px">
                  <VStack>
                    <Text
                      variant="text-2"
                      weight="semibold"
                      color={theme.colors.text.primary}
                    >
                      {channel.name}
                    </Text>
                    <Text
                      variant="text-3"
                      color={theme.colors.text.secondary}
                    ></Text>
                  </VStack>
                </Spacing>
              </Container>
              <Container alignItems="flex-end">
                <time dateTime={at} title={readableTime}>
                  <Text variant="text-3" mono>
                    {momentjs(at).format('LT')}
                  </Text>
                </time>
                <Text variant="text-3">{editCount} Edits</Text>
              </Container>
            </HStack>
          </Spacing>
        </VStack>
      </GridItemInfo>
    </GridItemContainer>
  )
}
