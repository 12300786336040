import React, { useCallback, useEffect, useMemo, useState } from 'react'
import momentjs from 'moment'

import {
  useCreateMultiPerspectiveMomentMutation,
  useGetEditingVideoQuery,
} from '__generated__'
import { useEditorState } from 'core/editor/context'
import { durationFormat } from 'utils/time'
import { CreateMultiMomentContainerDiv } from '../styled'

interface CreateMultiMomentProps {
  videoId: string
}

export const CreateMultiMoment: React.FC<CreateMultiMomentProps> = ({
  videoId,
}) => {
  const {
    selection,
    startText,
    lengthText,
    overlapCount,
    createNewMoment,
    title,
    onTitleChange,
  } = useCreateMultiMoment(videoId)
  return selection ? (
    <CreateMultiMomentContainerDiv>
      <h2>Create New Moment</h2>
      <input
        type="text"
        placeholder="Title"
        value={title}
        onChange={onTitleChange}
      />
      <div>
        {startText} - {lengthText}
      </div>

      <div>{overlapCount} guests</div>
      <button onClick={createNewMoment}>Create Moment</button>
    </CreateMultiMomentContainerDiv>
  ) : null
}

const useCreateMultiMoment = (videoId: string) => {
  const { selection } = useEditorState()
  const { data, refetch } = useGetEditingVideoQuery({
    variables: { id: videoId },
  })
  const [mutation, { called, data: mutationData }] =
    useCreateMultiPerspectiveMomentMutation()
  const [title, setTitle] = useState('')

  const onTitleChange = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      setTitle(evt.currentTarget.value)
    },
    [setTitle]
  )

  const { overlapCount, relatedVideoIds } = useMemo(() => {
    if (!selection) return { overlapCount: 0, relatedVideoIds: [] }
    const vids = data?.video?.relatedVideos ?? []
    const overlaps = vids.filter((item) => {
      return item.overlaps.some(({ from, to }) => {
        const overlapStart = momentjs(from).valueOf()
        const overlapEnd = momentjs(to).valueOf()
        return (
          selection.startTime > overlapStart && selection.endTime < overlapEnd
        )
      })
    })
    return {
      overlapCount: overlaps.length,
      relatedVideoIds: overlaps.map((item) => item.video.id),
    }
  }, [data?.video?.relatedVideos, selection])

  const startTime = selection?.startTime ?? 0
  const endTime = selection?.endTime ?? 0
  const startText = momentjs(startTime).format('LT')
  const lengthText = durationFormat((endTime - startTime) / 1000)

  const createNewMoment = useCallback(() => {
    const input = {
      title,
      startsAt: momentjs(startTime).toISOString(),
      endsAt: momentjs(endTime).toISOString(),
      published: false,
      videoIds: [videoId, ...relatedVideoIds],
      mainVideoId: videoId,
      edits: [],
      offsets: [],
      tags: [],
      signalIds: [],
    }
    mutation({ variables: { createMultiPerspectiveMomentInput: input } })
  }, [endTime, mutation, relatedVideoIds, startTime, title, videoId])

  useEffect(() => {
    if (called && mutationData?.createMultiPerspectiveMoment?.id) {
      refetch()
    }
  }, [called, mutationData?.createMultiPerspectiveMoment?.id, refetch])

  return useMemo(
    () => ({
      createNewMoment,
      selection,
      startText,
      lengthText,
      overlapCount,
      title,
      onTitleChange,
    }),
    [
      createNewMoment,
      lengthText,
      onTitleChange,
      overlapCount,
      selection,
      startText,
      title,
    ]
  )
}
