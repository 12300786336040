import { useEffect, useMemo, useRef, useState } from 'react'
import SimpleBar from 'simplebar-react'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import { useBreakpoint } from 'styled-breakpoints/react-styled'

import { MomentItem } from './moment-item'

import { DroppableContainer, MomentListDiv } from './styled'
import { useIsPublicView } from './hooks'
import { up } from 'styled-breakpoints'

interface Props {
  momentIds: string[]
  includeUnapproved: boolean
}

export const MomentsPlaylist = ({ momentIds, includeUnapproved }: Props) => {
  const isPublic = useIsPublicView()
  const [offsetTop, setOffsetTop] = useState(0)
  const simplebarStyles = useMemo(
    () => ({
      width: '100%',
      height: '100%',
    }),
    []
  )

  const ref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (!ref.current) {
      return
    }

    const root = document.documentElement || document.body
    const offsetTop = ref.current.getBoundingClientRect().top + root.scrollTop
    setOffsetTop(offsetTop)
  }, [ref])
  const isAboveMobile = useBreakpoint(up('sm'))

  const desktopStyling = useMemo(
    () =>
      isAboveMobile ? { height: `calc(100vh - ${offsetTop}px` } : undefined,
    [isAboveMobile, offsetTop]
  )

  return (
    <MomentListDiv ref={ref} style={desktopStyling}>
      <SimpleBar style={simplebarStyles}>
        {isPublic ? (
          momentIds.map((momentId) => (
            <MomentItem
              key={momentId}
              momentId={momentId}
              includeUnapproved={includeUnapproved}
            />
          ))
        ) : (
          <Droppable droppableId="main">
            {(droppableProvided) => (
              <DroppableContainer
                ref={droppableProvided.innerRef}
                {...droppableProvided.droppableProps}
              >
                {momentIds.map((momentId, index) => (
                  <Draggable
                    key={momentId}
                    draggableId={momentId}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <MomentItem
                          key={momentId}
                          momentId={momentId}
                          includeUnapproved={includeUnapproved}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {droppableProvided.placeholder}
              </DroppableContainer>
            )}
          </Droppable>
        )}
      </SimpleBar>
    </MomentListDiv>
  )
}
