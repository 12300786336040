import { Button } from 'components/core/button'
import { Container } from 'components/core/layout'
import { useMomentViewerData } from 'core/moment-viewer/hooks'
import { get, sortBy } from 'lodash'
import { Link } from 'react-router-dom'
import { PreviewVideoEditContainerDiv } from './styled'

interface Props {
  momentId: string
  editId: string
}

// const SIZE_PER_FORMAT = {
//   '16x9':    { height: '45vh'
//   width: '80vh'
//   }
// }

export const PreviewEditVideo = ({ momentId, editId }: Props) => {
  const { edits } = useMomentViewerData(momentId, editId, true)
  const selectedEdit = edits?.find(({ id }) => id === editId)
  const recentExport = sortBy(selectedEdit?.exports, ({ createdAt }) => {
    return -new Date(createdAt).getTime()
  })

  const videoUrl = get(recentExport, [0, 'output', 'videos', 0, 'url']) as
    | string
    | undefined
  const thumbnailUrl = get(recentExport, [
    0,
    'output',
    'thumbnails',
    0,
    'url',
  ]) as string | undefined

  return videoUrl && selectedEdit ? (
    <div style={{ position: 'relative' }}>
      <PreviewVideoEditContainerDiv
        $format={`${selectedEdit.format.aspectRatio.width}x${selectedEdit.format.aspectRatio.height}`}
      >
        <video
          style={{
            maxHeight: '100%',
            maxWidth: '100%',
            borderRadius: 8,
            overflow: 'hidden',
            aspectRatio: `${selectedEdit.format.aspectRatio.width}/${selectedEdit.format.aspectRatio.height}`,
          }}
          src={videoUrl}
          poster={thumbnailUrl}
          controls={true}
        />
      </PreviewVideoEditContainerDiv>
      <Container
        gap={8}
        direction="row"
        style={{ position: 'absolute', right: 0, bottom: -52 }}
      >
        <Link to={`/m/${momentId}/editor/${editId}`}>
          <Button>Edit</Button>
        </Link>
        <Button>Delete</Button>
      </Container>
    </div>
  ) : null
}
