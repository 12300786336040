import React from 'react'

import { EditorProvider } from 'core/editor/context'
import { TimelineProvider } from 'core/editor/timeline/context'
import { VideoSyncController } from './controller'
import { CloseButton } from 'core/feed/components/preview-modal/styled'
import { CloseIcon } from 'core/feed/components/preview-modal/close-icon'

export interface Props {
  primaryVideoId: string
  secondaryVideoId: string
  start: string
  end: string
  offset: number
  save: (nextOffset: number) => void
  cancel: () => void
}

export const RelatedSync = ({
  primaryVideoId,
  secondaryVideoId,
  start,
  end,
  offset,
  save,
  cancel,
}: Props) => {
  return (
    <EditorProvider>
      <TimelineProvider>
        <VideoSyncController
          primaryVideoId={primaryVideoId}
          secondaryVideoId={secondaryVideoId}
          start={start}
          end={end}
          save={save}
          initialOffset={offset}
        >
          <CloseButton onClick={cancel}>
            <CloseIcon />
          </CloseButton>
        </VideoSyncController>
      </TimelineProvider>
    </EditorProvider>
  )
}
