import React, { createContext, useContext, useReducer } from 'react'

export enum SyncStatus {
  Ready = 'Ready',
  Seeking = 'Seeking',
  Loading = 'Loading',
}

interface TimeRangePlayerState {
  videoStartTime: number // mainly for convenience
  startTime: number
  endTime: number
  videoId: string
  status: SyncStatus
  targetTime?: number
  playing: boolean
  currentTime: number
  muted: boolean
  playbackRate: number
  autoPlay: boolean
}

const INITIAL_STATE = {
  status: SyncStatus.Loading,
  targetTime: undefined,
  playing: false,
  currentTime: 0,
  videoStartTime: 0,
  muted: true,
  playbackRate: 1,
  autoPlay: false,
}

enum ACTION_TYPES {
  SET_VIDEO_START = 'SET_VIDEO_START',
  SET_PLAYING = 'SET_PLAYING',
  SEEK_TO = 'SEEK_TO',
  SET_STATUS = 'SET_STATUS',
  SET_CURRENT_TIME = 'SET_CURRENT_TIME',
  SEEK_TO_CURRENT_TIME = 'SEEK_TO_CURRENT_TIME',
  SET_PLAYBACK_RATE = 'SET_PLAYBACK_RATE',
}

interface SetPlayingAction {
  type: ACTION_TYPES.SET_PLAYING
  payload: {
    playing: boolean
  }
}

interface SeekToAction {
  type: ACTION_TYPES.SEEK_TO
  payload: {
    targetTime: number
    playing?: boolean
  }
}

interface SetSyncStatusAction {
  type: ACTION_TYPES.SET_STATUS
  payload: {
    status: SyncStatus
  }
}

interface SetCurrentTimeAction {
  type: ACTION_TYPES.SET_CURRENT_TIME
  payload: {
    currentTime: number
  }
}

interface SeekToCurrentTimeAction {
  type: ACTION_TYPES.SEEK_TO_CURRENT_TIME
  payload: {
    videoKey: string
  }
}

interface SetVideoStartAction {
  type: ACTION_TYPES.SET_VIDEO_START
  payload: {
    videoStartTime: number
  }
}

interface SetPlaybackRateAction {
  type: ACTION_TYPES.SET_PLAYBACK_RATE
  payload: {
    playbackRate: number
  }
}

type TimeRangePlayerActions =
  | SetPlayingAction
  | SeekToAction
  | SetSyncStatusAction
  | SetCurrentTimeAction
  | SeekToCurrentTimeAction
  | SetVideoStartAction
  | SetPlaybackRateAction

type Dispatch = (action: TimeRangePlayerActions) => void

const reducer = (
  state: TimeRangePlayerState,
  action: TimeRangePlayerActions
): TimeRangePlayerState => {
  switch (action.type) {
    case ACTION_TYPES.SET_PLAYING: {
      return {
        ...state,
        playing: action.payload.playing,
      }
    }

    case ACTION_TYPES.SEEK_TO: {
      return {
        ...state,
        playing: action.payload.playing ?? false,
        targetTime: action.payload.targetTime,
        status: SyncStatus.Seeking,
        currentTime: action.payload.targetTime,
      }
    }

    case ACTION_TYPES.SET_STATUS: {
      const { status } = action.payload

      return {
        ...state,
        status,
        targetTime: status === SyncStatus.Ready ? undefined : state.targetTime,
      }
    }

    case ACTION_TYPES.SET_CURRENT_TIME: {
      return {
        ...state,
        currentTime: action.payload.currentTime,
      }
    }

    case ACTION_TYPES.SET_VIDEO_START: {
      return {
        ...state,
        videoStartTime: action.payload.videoStartTime,
      }
    }

    case ACTION_TYPES.SET_PLAYBACK_RATE: {
      return {
        ...state,
        playbackRate: Math.max(action.payload.playbackRate, 0),
      }
    }

    default:
      return state
  }
}

const TimeRangePlayerContext = createContext<TimeRangePlayerState | undefined>(
  undefined
)
const TimeRangePlayerUpdaterContext = createContext<Dispatch | undefined>(
  undefined
)

interface InitProps {
  startTime: number
  endTime: number
  videoId: string
  autoPlay?: boolean
}

export const TimeRangePlayerProvider: React.FC<InitProps> = ({
  children,
  startTime,
  endTime,
  videoId,
  autoPlay,
}) => {
  const [state, dispatch] = useReducer(reducer, {
    ...INITIAL_STATE,
    startTime,
    endTime,
    videoId,
    autoPlay: autoPlay ?? false,
  })

  return (
    <TimeRangePlayerContext.Provider value={state}>
      <TimeRangePlayerUpdaterContext.Provider value={dispatch}>
        {children}
      </TimeRangePlayerUpdaterContext.Provider>
    </TimeRangePlayerContext.Provider>
  )
}

export const useTimeRangePlayerState = () => {
  const context = useContext(TimeRangePlayerContext)
  if (context === undefined) {
    throw new Error(
      'useTimeRangePlayerState must be used within a TimeRangePlayerProvider'
    )
  }
  return context
}

export const useTimeRangePlayerUpdater = () => {
  const context = useContext(TimeRangePlayerUpdaterContext)
  if (context === undefined) {
    throw new Error(
      'useTimeRangePlayerUpdater must be used within a TimeRangePlayerProvider'
    )
  }
  return context
}

useTimeRangePlayerUpdater.ACTION_TYPES = ACTION_TYPES
