import styled from 'styled-components/macro'

import { media } from 'theme/media'

export const MainContent = styled.div`
  margin-left: 272px;
  width: 100%;
`

export const ChannelContent = styled.div`
  width: 100%;
  margin-top: 4px;
  padding: 16px;

  ${media.phablet`
  padding: 0;
  margin-top: 32px;
`}
`
