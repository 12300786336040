import { useEffect, useMemo } from 'react'

import { Editor } from 'core/editor'
import {
  useGetValorantMatchPageDetailQuery,
  useGetValorantMatchVideosLazyQuery,
} from '__generated__'

import { ValorantMatchTimeline } from './timeline'
import { ValorantMatchEditorPlayer } from './player'
import { useValorantMatchEditorHelpers } from './helpers'
import {
  EditorContainerDiv,
  TimelineAreaDiv,
  VideoPlayerAreaDiv,
} from './styled'
import { useDimensions } from 'utils/browser'
import { useTimelineHelpers } from 'core/editor/timeline/hooks'
import { useTimelineState } from 'core/editor/timeline/context'
import { ValorantMomentExportingModal } from './exporting-modal'
import { useValorantMatchEditorState } from './context'

interface Props {
  matchId: string
  userId: string // initial perspective to start with maybe optional?
  start?: number // a way to jump to a selection
  end?: number
  options?: {
    onExport?: (momentId: string) => void
  }
}

export const ValorantMatchEditor = ({
  matchId,
  userId,
  start,
  end,
  options,
}: Props) => {
  const [screenRef, screenRect] = useDimensions()
  const [timelineRef, timelineRect] = useDimensions()
  const { videoData } = useValorantMatchPreviewData(matchId)
  const { startTime: timelineStartTime } = useTimelineState()
  const { jumpToNewSelection } = useTimelineHelpers()
  const { exportingOpen } = useValorantMatchEditorState()
  const {
    setMatchId,
    setPrimaryVideoId,
    setSelectedVideoInfo,
    setSelectedMomentTimes,
    setMainPerspectiveInfo,
  } = useValorantMatchEditorHelpers()

  const availablePlayerDimensions = useMemo(() => {
    if (!timelineRect || !screenRect) return undefined
    return {
      width: screenRect.width,
      height: screenRect.height - timelineRect.height,
    }
  }, [screenRect, timelineRect])

  useEffect(() => {
    setMatchId(matchId)
  }, [matchId, setMatchId])

  // initializes first selected video, and primary video
  // which will be used for syncing (for now)
  useEffect(() => {
    if (videoData) {
      const nextSelectedVideoInfo = videoData.find(
        (info) => info.userId === userId
      )
      if (nextSelectedVideoInfo) {
        setSelectedVideoInfo(nextSelectedVideoInfo)
        setPrimaryVideoId(nextSelectedVideoInfo.videoId)
      }
    }
  }, [setPrimaryVideoId, setSelectedVideoInfo, userId, videoData])

  useEffect(() => {
    if (videoData && userId) {
      const mainPerspectiveInfo = videoData.find(
        (info) => info.userId === userId
      )
      if (mainPerspectiveInfo) {
        setMainPerspectiveInfo(mainPerspectiveInfo)
      }
    }
  }, [setMainPerspectiveInfo, userId, videoData])

  // initialize the selection
  useEffect(() => {
    // make sure they are both set, and timelinedata is ready
    if (start && end && timelineStartTime) {
      jumpToNewSelection(start, end)
      setSelectedMomentTimes({ start, end })
    }
  }, [
    start,
    end,
    timelineStartTime,
    jumpToNewSelection,
    setSelectedMomentTimes,
  ])

  return (
    <Editor>
      <EditorContainerDiv $hasGuests={true} ref={screenRef}>
        {availablePlayerDimensions ? (
          <VideoPlayerAreaDiv>
            <ValorantMatchEditorPlayer
              matchId={matchId}
              userId={userId}
              availableDimensions={availablePlayerDimensions}
            />
          </VideoPlayerAreaDiv>
        ) : null}
        <TimelineAreaDiv ref={timelineRef}>
          <ValorantMatchTimeline matchId={matchId} options={options} />
        </TimelineAreaDiv>
      </EditorContainerDiv>
      {exportingOpen ? (
        <ValorantMomentExportingModal options={options} />
      ) : null}
    </Editor>
  )
}

export interface ValorantVideoInfo {
  accountId: string
  videoId: string
  userId: string
  videoStart: string
  displayName: string
  profileThumb: string
}

export const useValorantMatchPreviewData = (matchId: string) => {
  const { data: previewData } = useGetValorantMatchPageDetailQuery({
    variables: { matchId },
    skip: matchId === '',
  })
  const [fetchVideoData, { data: matchVideosData }] =
    useGetValorantMatchVideosLazyQuery()
  // const dispatch = useValorantMatchEditorUpdater()

  // since we don't know match start and end until we get preview data, we need to send this later
  useEffect(() => {
    if (
      previewData?.valorantMatch?.startedAt &&
      previewData?.valorantMatch.endedAt
    ) {
      fetchVideoData({
        variables: {
          matchId,
          matchStart: previewData.valorantMatch.startedAt,
          matchEnd: previewData.valorantMatch.endedAt,
        },
      })
    }
  }, [fetchVideoData, matchId, previewData])

  const videoData = useMemo(() => {
    const valorantMatch = previewData?.valorantMatch
    if (!matchVideosData || !previewData || !valorantMatch) return
    const matchStartedAt = new Date(valorantMatch.startedAt).getTime()
    const matchEndedAt = new Date(valorantMatch.endedAt).getTime()
    return matchVideosData.valorantMatch?.players
      .map((pl) => {
        if (pl.account.channel) {
          const stream = pl.account.channel.streams.data[0]
          if (!stream || !stream.video) return undefined

          // map muted segments to segments with absolute times and an ends at instead of duration
          const mutedSegments = (stream.video.mutedSegments || []).map(
            (segment) => {
              const startsAt =
                new Date(stream.startedAt).getTime() + segment.startsAt
              return {
                startsAt: startsAt,
                endsAt: startsAt + segment.duration * 1000,
              }
            }
          )

          // check if there's any overlap with muted segments
          const hasMutedSegments = mutedSegments.some(
            (segment) =>
              segment.startsAt > matchStartedAt && segment.endsAt < matchEndedAt
          )

          if (hasMutedSegments) {
            return undefined
          }

          return {
            accountId: pl.account.id,
            videoId: stream.video.id,
            videoStart: stream.startedAt,
            userId: pl.account.channel.id,
            displayName: pl.account.channel.displayName,
            profileThumb: pl.account.channel.profileImageUrl,
          }
        }
        return undefined
      })
      .filter((item) => item !== undefined) as ValorantVideoInfo[]
  }, [matchVideosData, previewData])

  return useMemo(
    () => ({
      videoData,
      previewData,
    }),
    [videoData, previewData]
  )
}
