import { flatMap, sortBy, uniqBy } from 'lodash'
import { useMemo } from 'react'
import momentjs from 'moment'

import {
  useGetValorantMatchDetailsByChannelCollectionQuery,
  useGetValorantMatchDetailsByVideoQuery,
  ValorantMatchDetailFragment,
} from '__generated__'

export enum TimeframeOptions {
  'Day' = 'Day',
  'Week' = 'Week',
  'Month' = 'Month',
}

const getTimeframeFromLength = (timeframeLength: TimeframeOptions) => {
  let from
  switch (timeframeLength) {
    case TimeframeOptions.Day:
      from = momentjs().subtract(24, 'hours').startOf('day').toISOString()
      break
    case TimeframeOptions.Week:
      from = momentjs()
        .subtract(24, 'hours')
        .startOf('day')
        .subtract(2, 'days')
        .toISOString()
      break
    case TimeframeOptions.Month:
      from = momentjs()
        .subtract(24, 'hours')
        .startOf('day')
        .subtract(1, 'month')
        .toISOString()
      break
    default:
      from = momentjs().subtract(24, 'hours').startOf('day').toISOString()
  }

  return {
    from,
    to: momentjs().toISOString(),
  }
}

export const useFindValorantMoments = (
  collectionId: string,
  timeframeLength: TimeframeOptions
) => {
  const timeframe = useMemo(() => {
    return getTimeframeFromLength(timeframeLength)
  }, [timeframeLength])

  const { data } = useGetValorantMatchDetailsByChannelCollectionQuery({
    variables: { collectionId, timeframe },
    errorPolicy: 'all',
  })
  // const { data } = useGetValorantMatchDetailsByChannelQuery({
  //   variables: { channelId, numVideos: 4 },
  //   errorPolicy: 'all',
  // })

  // made a loop over a ton of reduce/maps
  const gameNamesHash = useMemo(() => {
    let validGameNames: { [gameName: string]: true } = {}
    for (const channel of data?.channelCollection?.channels.data ?? []) {
      for (const valorantAccount of channel.linkedAccounts) {
        validGameNames[valorantAccount.valorantUserInfo?.gameName ?? ''] = true
      }
    }
    return validGameNames
  }, [data?.channelCollection?.channels.data])

  const allVideos = flatMap(
    data?.channelCollection?.channels.data,
    (ch) => ch.videos.data
  )
  const allMatches = uniqBy(
    flatMap(allVideos, ({ valorantMatches }) => valorantMatches),
    ({ id }) => id
  )
  const highlights = flatMap(allMatches, (matchData) =>
    findHighlights(matchData).filter(
      (highlight) => gameNamesHash[highlight.name ?? '']
    )
  )

  return sortBy(highlights, ({ score }) => -score)
}

export const useFindValorantMomentsForVideo = (videoId: string) => {
  const { data } = useGetValorantMatchDetailsByVideoQuery({
    variables: { videoId },
    errorPolicy: 'all',
  })

  // made a loop over a ton of reduce/maps
  const gameNamesHash = useMemo(() => {
    let validGameNames: { [gameName: string]: true } = {}
    for (const valorantAccount of data?.video?.user?.linkedAccounts ?? []) {
      validGameNames[valorantAccount.valorantUserInfo?.gameName ?? ''] = true
    }
    return validGameNames
  }, [data?.video?.user?.linkedAccounts])

  const highlights = useMemo(
    () =>
      flatMap(data?.video?.valorantMatches, (matchData) =>
        findHighlights(matchData).filter(
          (highlight) => gameNamesHash[highlight.name ?? '']
        )
      ),
    [data?.video?.valorantMatches, gameNamesHash]
  )

  return sortBy(highlights, ({ score }) => -score)
}

export interface ValorantMomentHighlight {
  matchId: string
  round: number
  name?: string
  accountId: string
  startsAt: number
  endsAt: number
  score: number
  scoreBreakdown?: any
}

export enum HighlightTypes {
  'Kills' = 'Kills',
  'Clutch' = 'Clutch',
}

const findHighlights = (matchData: ValorantMatchDetailFragment) => {
  const results: ValorantMomentHighlight[] = []
  results.push(...getKillHighlights(matchData))

  // const clutch = useMemo(() => {
  //   for (const roundResult of data?.valorantMatch?.roundResults ?? []) {
  //     if (roundResult.roundCeremony === ValorantRoundCeremony.Clutch) {
  //       console.log({ roundResult, clutchRound: roundResult.playerStats })
  //     }
  //   }
  // }, [data?.valorantMatch?.roundResults])

  // return results.filter(({ name }) => name === 'tarik')
  return results
}

const getKillHighlights = (matchData: ValorantMatchDetailFragment) => {
  let results: ValorantMomentHighlight[] = []
  for (const roundResult of matchData.roundResults ?? []) {
    for (const playerStats of roundResult.playerStats ?? []) {
      if (playerStats?.kills && playerStats.kills.length >= 3) {
        const startsAt = Math.min(
          ...playerStats.kills.map(
            (k) => k?.timeSinceRoundStartMillis ?? Number.MAX_VALUE
          )
        )
        const endsAt = Math.max(
          ...playerStats.kills.map((k) => k?.timeSinceRoundStartMillis ?? 0)
        )

        results.push({
          matchId: matchData.id,
          accountId: playerStats.player?.account.id ?? '',
          name: playerStats.player?.gameName ?? '',
          round: roundResult.roundNum ?? 0,
          score: playerStats.score ?? 0,
          scoreBreakdown: {
            type: HighlightTypes.Kills,
            kills: playerStats.kills.length,
          },
          startsAt,
          endsAt,
        })
      }
    }
  }

  return results
}
