import { HStack, Spacing } from 'components/core/layout'
import { Text } from 'components/core/text'
import { RootOnly } from 'core/auth/components'
import { useMomentViewerData } from 'core/moment-viewer/hooks'
import { ReactComponent as StarFullIcon } from 'icons/svgs/solid/star.svg'
import {
  MomentItemDiv,
  MomentPreviewImageDiv,
  MomentTextDiv,
  PreviewStarContainerDiv,
  PreviewStarDiv,
} from 'core/moment-viewer/styled'
import { EditSelector } from './edit-selector'
import { useCallback, useContext } from 'react'
import { MomentModalContext } from './moment-modal'

interface ContentProps {
  momentId: string
}

export const MomentEdits = ({ momentId }: ContentProps) => {
  const { selectedEditId, setSelectedEditId } = useContext(MomentModalContext)
  const { momentData, edits, rating, mainChannelData } = useMomentViewerData(
    momentId,
    selectedEditId,
    true
  )

  const resetSelectedId = useCallback(() => {
    setSelectedEditId(undefined)
  }, [setSelectedEditId])

  return (
    <MomentItemDiv $selected={true}>
      <Spacing all={16}>
        <HStack onClick={resetSelectedId}>
          <MomentPreviewImageDiv
            $backgroundUrl={mainChannelData?.profileImageUrl ?? ''}
          />
          <MomentTextDiv>
            <Text variant="text-2" as="p" weight="semibold">
              {momentData?.title}
            </Text>
            {edits && (
              <Text variant="text-3" as="p">
                {edits.length} Edit{edits.length > 1 ? 's' : null}
              </Text>
            )}
            <RootOnly>
              {rating ? (
                <PreviewStarContainerDiv>
                  {Array(5)
                    .fill('')
                    .map((_, idx) => (
                      <PreviewStarDiv key={idx} $selected={idx + 1 <= rating}>
                        <StarFullIcon />
                      </PreviewStarDiv>
                    ))}
                </PreviewStarContainerDiv>
              ) : null}
            </RootOnly>
          </MomentTextDiv>
        </HStack>
      </Spacing>
      <EditSelector includeUnapproved={true} />
    </MomentItemDiv>
  )
}
