import React, { useMemo } from 'react'
import styled from 'styled-components/macro'
import { useDimensions } from 'utils/browser'

interface AspectRatioContainerProps {
  containerStyles?: React.CSSProperties
  width: number
  height: number
  forceFullWidth?: boolean
}

export const AspectRatioContainer: React.FC<AspectRatioContainerProps> = ({
  width,
  height,
  containerStyles,
  forceFullWidth,
  children,
}) => {
  const [ref, parentDOMRect] = useDimensions()

  const aspectRatioStyles = useMemo(() => {
    if (parentDOMRect === undefined) return {}
    const fullHeight = {
      height: parentDOMRect.height,
      width: (parentDOMRect.height * width) / height,
    }

    const fullWidth = {
      width: parentDOMRect.width,
      height: (parentDOMRect.width * height) / width,
    }

    if (forceFullWidth) {
      return fullWidth
    }

    if (fullHeight.width <= parentDOMRect.width) {
      return fullHeight
    } else {
      return fullWidth
    }
  }, [forceFullWidth, height, parentDOMRect, width])

  return (
    <ContainerDiv ref={ref} style={containerStyles}>
      <AspectRatioContainerDiv style={aspectRatioStyles}>
        {children}
      </AspectRatioContainerDiv>
    </ContainerDiv>
  )
}

const AspectRatioContainerDiv = styled.div`
  position: relative;
`

const ContainerDiv = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
