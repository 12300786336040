import React, { useCallback, useEffect, useMemo } from 'react'

import { SyncedVideo } from 'core/editor/synced-video'
import { CropSettings, CropSettingType, useComposerState } from '../context'

import { VideoContentDiv } from './styled'
import { TemplateCrop } from 'core/exporter/constants'
import { useComposerHelper } from '../hooks'
import { LAYER_COLORS } from '../template-sidebar/option'
import { useFacecamRect } from './facecam/hooks'
// import { usePrevious } from 'utils'

interface CropContainerProps {
  templateCrop: TemplateCrop
}

export const CropContents: React.FC<CropContainerProps> = ({
  templateCrop,
}) => {
  const { primaryVideoId } = useComposerState()
  const {
    videoId,
    contentStyle,
    // selectTemplateCrop,
    // isSelected,
    playerOptions,
  } = useCropContainerData(templateCrop)

  return (
    <>
      {videoId ? (
        <VideoContentDiv style={contentStyle}>
          <SyncedVideo
            // key={}
            id={`${templateCrop.title}-${videoId}`}
            controller={templateCrop.primary}
            videoId={videoId}
            primaryVideoId={primaryVideoId}
            playerOptions={playerOptions}
            qualityHeight={1080}
          />
        </VideoContentDiv>
      ) : null}
    </>
  )
}

const VIDEO_CONTAINER_SIZE = { width: 1920, height: 1080 }
const VIDEO_AR = { width: 16, height: 9 }

export const useCropContainerData = (templateCrop: TemplateCrop) => {
  const {
    cropSettings,
    primaryVideoId,
    previewRect,
    selectedTemplateCrop,
    selectedFormat,
    selectedTemplate,
  } = useComposerState()
  const {
    setCropSettings,
    setPrimaryVideoId,
    setSelectedTemplateCrop,
    setCropRectSetting,
  } = useComposerHelper()
  // const prevTemplateCropHeight = usePrevious(templateCrop.position.height)
  // const prevTemplateCropWidth = usePrevious(templateCrop.position.width)

  const cropSettingItem: CropSettings | undefined = useMemo(() => {
    return cropSettings[templateCrop.title]
  }, [cropSettings, templateCrop.title])

  const containerDimensions = useMemo(() => {
    if (!previewRect) return { width: 1, height: 1 }
    // const changedPosition = templateCropChanges[templateCrop.title]
    const { width, height } = templateCrop.position

    return {
      width: previewRect.width * width,
      height: previewRect.height * height,
    }
  }, [previewRect, templateCrop.position])

  const facecamRect = useFacecamRect(
    cropSettingItem?.videoId ?? '',
    containerDimensions
  )

  const widthScale = useMemo(() => {
    if (!previewRect) return 1
    const widthAtImageDimensions = VIDEO_CONTAINER_SIZE.width
    const widthOfPreviewContainer = containerDimensions.width
    return widthOfPreviewContainer / widthAtImageDimensions
  }, [containerDimensions.width, previewRect])

  const heightScale = useMemo(() => {
    if (!previewRect) return 1
    const heightAtImageDimensions = VIDEO_CONTAINER_SIZE.height
    const heightOfPreviewContainer = containerDimensions.height
    return heightOfPreviewContainer / heightAtImageDimensions
  }, [containerDimensions.height, previewRect])

  const contentStyle = useMemo(() => {
    if (cropSettingItem === undefined) return {}
    const { rect } = cropSettingItem

    const widthAtImageDimensions = rect.width * VIDEO_CONTAINER_SIZE.width
    const widthOfPreviewContainer = containerDimensions.width
    const scale = widthOfPreviewContainer / widthAtImageDimensions
    const transform = `scale(${scale}) translate(${-(
      rect.x * VIDEO_CONTAINER_SIZE.width
    )}px, ${-(rect.y * VIDEO_CONTAINER_SIZE.height)}px)`

    return {
      ...VIDEO_CONTAINER_SIZE,
      transform,
    }
  }, [containerDimensions.width, cropSettingItem])

  const selectTemplateCrop = useCallback(() => {
    setSelectedTemplateCrop(templateCrop)
  }, [setSelectedTemplateCrop, templateCrop])

  const playerOptions = useMemo(() => {
    return { muted: templateCrop.primary === false }
  }, [templateCrop.primary])

  // If the setting is "Full" then it uses a cover algorithm
  useEffect(() => {
    const cropAR =
      templateCrop.aspectRatio.height === 0 &&
      templateCrop.aspectRatio.width === 0
        ? {
            width:
              templateCrop.position.width *
              (selectedFormat?.aspectRatio.width ?? 1),
            height:
              templateCrop.position.height *
              (selectedFormat?.aspectRatio.height ?? 1),
          }
        : templateCrop.aspectRatio

    // full cover math for rect
    if (cropSettingItem?.type === CropSettingType.Full) {
      const heightBasedWidth = VIDEO_CONTAINER_SIZE.width * heightScale
      let nextRect = { x: 0, y: 0, width: 1, height: 1 }
      if (heightBasedWidth > containerDimensions.width) {
        const scale = (cropAR.height / VIDEO_AR.height) * VIDEO_AR.width
        const widthPct = cropAR.width / scale
        nextRect = {
          y: 0,
          x: (1 - widthPct) / 2,
          height: 1,
          width: widthPct,
        }
      } else {
        const scale = (cropAR.width / VIDEO_AR.width) * VIDEO_AR.height
        const heightPct = cropAR.height / scale
        nextRect = {
          y: (1 - heightPct) / 2,
          x: 0,
          height: heightPct,
          width: 1,
        }
      }

      setCropRectSetting(templateCrop.title, nextRect)
    } else if (cropSettingItem?.type === CropSettingType.Facecam) {
      setCropRectSetting(templateCrop.title, facecamRect)
    }
  }, [
    containerDimensions.height,
    containerDimensions.width,
    cropSettingItem?.type,
    facecamRect,
    heightScale,
    selectedFormat?.aspectRatio.height,
    selectedFormat?.aspectRatio.width,
    setCropRectSetting,
    setCropSettings,
    templateCrop.aspectRatio,
    templateCrop.aspectRatio.height,
    templateCrop.aspectRatio.width,
    templateCrop.position.height,
    templateCrop.position.width,
    templateCrop.title,
    widthScale,
  ])

  useEffect(() => {
    if (
      templateCrop.primary &&
      cropSettingItem?.videoId !== primaryVideoId &&
      cropSettingItem?.videoId !== '' &&
      cropSettingItem?.videoId !== undefined &&
      primaryVideoId !== ''
    ) {
      setPrimaryVideoId(cropSettingItem?.videoId ?? '')
    }
  }, [
    templateCrop.primary,
    cropSettingItem?.videoId,
    primaryVideoId,
    setPrimaryVideoId,
  ])

  useEffect(() => {
    if (cropSettingItem === undefined) {
      // find an unused color for it
      const usedColors = Object.values(cropSettings).reduce(
        (acc, { color }) => {
          if (color) acc[color] = true
          return acc
        },
        {} as { [color: string]: boolean }
      )
      const idx = selectedTemplate?.crops.findIndex(
        (crop) => crop.title === templateCrop.title
      )

      let plannedColor = LAYER_COLORS[idx ?? 0]
      let loopIdx = 0
      while (usedColors[plannedColor] === true) {
        plannedColor = LAYER_COLORS[loopIdx]
        loopIdx++
        if (plannedColor === undefined) {
          plannedColor = LAYER_COLORS[0]
          break
        }
      }

      setCropSettings({
        title: templateCrop.title,
        type: CropSettingType.Full,
        videoId: primaryVideoId,
        color: plannedColor,
        rect: {
          x: 0,
          y: 0,
          width: 1,
          height: 1,
        },
      })
    }
  }, [
    cropSettingItem,
    cropSettings,
    primaryVideoId,
    selectedTemplate,
    selectedTemplate?.crops,
    setCropSettings,
    templateCrop.title,
  ])

  useEffect(() => {
    if (selectedTemplateCrop === undefined && templateCrop.primary) {
      setSelectedTemplateCrop(templateCrop)
    }
  }, [selectedTemplateCrop, setSelectedTemplateCrop, templateCrop])

  // TODO: This should be implemented here, but for now, using Facecam
  // or Gameplay content setting, it will do its best to fill.
  // If it has no aspect ratio lock, and width || height changed
  // lets make a best guess for how content should fit into it now
  // that the AR has changed.
  // useEffect(() => {
  //   if (
  //     selectedTemplateCrop?.aspectRatio.height === 0 &&
  //     selectedTemplateCrop.aspectRatio.width === 0 &&
  //     (prevTemplateCropHeight !== templateCrop.position.height ||
  //       prevTemplateCropWidth !== templateCrop.position.width) &&
  //     cropSettingItem.type === 'Custom'
  //   ) {

  //   }
  // }, [
  //   cropSettingItem,
  //   prevTemplateCropHeight,
  //   prevTemplateCropWidth,
  //   selectedTemplateCrop?.aspectRatio.height,
  //   selectedTemplateCrop?.aspectRatio.width,
  //   templateCrop.position.height,
  //   templateCrop.position.width,
  // ])

  return useMemo(
    () => ({
      playerOptions,
      selectTemplateCrop,
      cropSettingItem,
      contentStyle,
      isSelected: templateCrop.title === selectedTemplateCrop?.title,
      videoId: cropSettingItem?.videoId ?? '',
    }),
    [
      playerOptions,
      selectTemplateCrop,
      cropSettingItem,
      contentStyle,
      templateCrop.title,
      selectedTemplateCrop?.title,
    ]
  )
}
