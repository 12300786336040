import { useComposerState } from 'core/composer/context'
import { Rect } from 'core/exporter/constants'
import { isEqual } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'

export function useStoreLocalStorage<T>(
  localStorageKey: string,
  objectKey: string | undefined,
  value: T,
  set: (next: T) => void,
  defaultValue: T
) {
  useEffect(() => {
    if (objectKey && !isEqual(value, defaultValue)) {
      const currentRect = JSON.parse(
        localStorage.getItem(localStorageKey) ?? '{}'
      )
      const nextData = {
        ...currentRect,
        [objectKey]: value,
      }
      localStorage.setItem(localStorageKey, JSON.stringify(nextData))
      window.dispatchEvent(new Event('storage'))
    }
  }, [defaultValue, localStorageKey, objectKey, value])

  // load in data from localstorage
  useEffect(() => {
    const currentRect = JSON.parse(
      localStorage.getItem(localStorageKey) ?? '{}'
    )
    if (objectKey && currentRect[objectKey]) {
      set(currentRect[objectKey])
    }
  }, [localStorageKey, objectKey, set])
}

// This works for a mapping with key/objectKey
export function useWatchLocalStorageValue<T>(key: string, objectKey: string) {
  // this will hold the current value of localstorage key
  let initialValue =
    localStorage.getItem(key) !== null &&
    JSON.parse(localStorage.getItem(key) ?? '{}')[objectKey]
      ? JSON.parse(localStorage.getItem(key) ?? '{}')[objectKey]
      : undefined
  const [value, setValue] = useState<T | undefined>(initialValue)
  const onStorageChange = useCallback(() => {
    const storedItem = localStorage.getItem(key)
    if (storedItem === null) {
      setValue(undefined)
      return
    }
    const itemMap = JSON.parse(storedItem) as { [key: string]: T }
    setValue(itemMap[objectKey])
  }, [key, objectKey])

  useEffect(() => {
    window.addEventListener('storage', onStorageChange)
    return () => {
      window.removeEventListener('storage', onStorageChange)
    }
  }, [onStorageChange])

  useEffect(() => {
    onStorageChange()
  }, [onStorageChange])

  return useMemo(() => value, [value])
}

const backupRect = {
  x: 0,
  y: 0,
  width: 1,
  height: 1,
}

export const useLoadFacecamData = (
  momentId: string,
  videoId: string,
  userId: string
) => {
  const momentCameraRect = useWatchLocalStorageValue<Rect>(
    'facecamContainerRect',
    `m_${momentId}_v_${videoId}`
  )
  const momentFaceLocationRect = useWatchLocalStorageValue<Rect>(
    'faceRect',
    `m_${momentId}_v_${videoId}`
  )

  const videoCameraRect = useWatchLocalStorageValue<Rect>(
    'facecamContainerRect',
    `v_${videoId}`
  )
  const videoFaceLocationRect = useWatchLocalStorageValue<Rect>(
    'faceRect',
    `v_${videoId}`
  )

  const userCameraRect = useWatchLocalStorageValue<Rect>(
    'facecamContainerRect',
    `u_${userId}`
  )
  const userFaceLocationRect = useWatchLocalStorageValue<Rect>(
    'faceRect',
    `u_${userId}`
  )

  return useMemo(
    () => ({
      cameraRect:
        momentCameraRect ?? videoCameraRect ?? userCameraRect ?? backupRect,
      faceLocationRect:
        momentFaceLocationRect ??
        videoFaceLocationRect ??
        userFaceLocationRect ??
        backupRect,
    }),
    [
      momentCameraRect,
      momentFaceLocationRect,
      userCameraRect,
      userFaceLocationRect,
      videoCameraRect,
      videoFaceLocationRect,
    ]
  )
}

const VIDEO_CONTAINER_SIZE = { width: 1920, height: 1080 }

export function useFacecamRect(
  videoId: string,
  containerDimensions: { width: number; height: number }
) {
  const { momentId, videosData } = useComposerState()
  // use videosData to load which userSettings exist
  const userId = videosData.find((vid) => videoId === vid.videoId)?.userId ?? ''
  const { faceLocationRect, cameraRect } = useLoadFacecamData(
    momentId,
    videoId,
    userId
  )
  const faceRect = faceLocationRect ?? backupRect
  const containerRect = cameraRect ?? backupRect

  // transforms facecam container rect from a percentage into a pixel value
  const trimmedRect = useMemo(
    () => ({
      width: containerRect.width * VIDEO_CONTAINER_SIZE.width,
      height: containerRect.height * VIDEO_CONTAINER_SIZE.height,
      x: containerRect.x * VIDEO_CONTAINER_SIZE.width,
      y: containerRect.y * VIDEO_CONTAINER_SIZE.height,
    }),
    [
      containerRect.height,
      containerRect.width,
      containerRect.x,
      containerRect.y,
    ]
  )

  //
  const heightScale = useMemo(() => {
    const heightAtImageDimensions = trimmedRect.height
    const heightOfPreviewContainer = containerDimensions.height
    return heightOfPreviewContainer / heightAtImageDimensions
  }, [containerDimensions.height, trimmedRect.height])

  const faceRectCenter = useMemo(
    () => ({
      x: faceRect.width / 2 + faceRect.x,
      y: faceRect.height / 2 + faceRect.y,
    }),
    [faceRect.height, faceRect.width, faceRect.x, faceRect.y]
  )

  const cropAR = useMemo(
    () => ({
      width: containerDimensions.width,
      height: containerDimensions.height,
    }),
    [containerDimensions.height, containerDimensions.width]
  )

  const trimmedAR = useMemo(
    () => ({
      height: trimmedRect.height,
      width: trimmedRect.width,
    }),
    [trimmedRect.height, trimmedRect.width]
  )

  // full cover math for rect
  const heightBasedWidth = trimmedRect.width * heightScale
  let nextRect = { x: 0, y: 0, width: 1, height: 1 }
  if (heightBasedWidth > (containerDimensions.width ?? 1)) {
    const scale = (cropAR.height / trimmedAR.height) * trimmedAR.width
    const widthPct = cropAR.width / scale
    // lets try to center on facecam
    const half = widthPct / 2
    const idealX = faceRectCenter.x - half
    let nextX = idealX
    // does ideal center fit?
    if (idealX + widthPct > 1) {
      nextX = 1 - widthPct
    } else if (idealX < 0) {
      nextX = 0
    }
    nextRect = {
      y: 0,
      x: nextX,
      height: 1,
      width: widthPct,
    }
  } else {
    const scale = (cropAR.width / trimmedAR.width) * trimmedAR.height
    const heightPct = cropAR.height / scale

    const half = heightPct / 2
    const idealY = faceRectCenter.y - half
    let nextY = idealY
    if (idealY + heightPct > 1) {
      nextY = 1 - heightPct
    } else if (idealY < 0) {
      nextY = 0
    }
    nextRect = {
      y: nextY,
      x: 0,
      height: heightPct,
      width: 1,
    }
  }

  return useMemo(
    () => ({
      x: containerRect.width * nextRect.x + containerRect.x,
      y: containerRect.height * nextRect.y + containerRect.y,
      width: containerRect.width * nextRect.width,
      height: containerRect.height * nextRect.height,
    }),
    [
      containerRect.height,
      containerRect.width,
      containerRect.x,
      containerRect.y,
      nextRect.height,
      nextRect.width,
      nextRect.x,
      nextRect.y,
    ]
  )
}
