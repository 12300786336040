import { ImagePreviewWrapper } from './styled'

type Props = React.ImgHTMLAttributes<HTMLImageElement>
const ImagePreview = ({ width, height, className, ...props }: Props) => (
  <ImagePreviewWrapper
    widthProp={width}
    heightProp={height}
    className={className}
  >
    {/* eslint-disable-next-line jsx-a11y/alt-text */}
    <img {...props} />
  </ImagePreviewWrapper>
)

export { ImagePreview }
