import React from 'react'
import styled from 'styled-components/macro'

import { HStack, Spacing } from 'components/core/layout'
import { Text } from 'components/core/text'

interface UserPreviewProps {
  displayName: string
  avatarUrl: string
}

export const UserPreview: React.FC<UserPreviewProps> = ({
  displayName,
  avatarUrl,
}) => {
  return (
    <UserPreviewContainerDiv>
      <HStack alignItems="center">
        <img width={32} height={32} src={avatarUrl} alt="avatar" />
        <div>
          <Spacing left={8}>
            <Text variant="text-3">{displayName}</Text>
          </Spacing>
        </div>
      </HStack>
    </UserPreviewContainerDiv>
  )
}

export const UserPreviewContainerDiv = styled.div`
  border-radius: 8px;
  padding: 8px;
  border: 1px solid ${({ theme }) => theme.colors.neutrals.n6};
  background-color: ${({ theme }) => theme.colors.neutrals.n8};
  margin-right: 8px;

  img {
    border-radius: 8px;
  }
`
