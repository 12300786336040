import styled from 'styled-components/macro'

import { HighlightTypes, ValorantMomentHighlight } from '../hooks'
import { Text } from 'components/core/text'
import { isEqual } from 'lodash'
import { HStack, Spacing } from 'components/core/layout'
import { useMemo } from 'react'
import { CreateMultiMomentHighlight } from './create-button'

interface Props {
  highlight: ValorantMomentHighlight
  selectedHighlight?: ValorantMomentHighlight
  setSelectedHighlight: (nextHighlight: ValorantMomentHighlight) => void
}

export const ValorantPlaylistItem = ({
  highlight,
  selectedHighlight,
  setSelectedHighlight,
}: Props) => {
  const isSelected = isEqual(selectedHighlight, highlight)
  const title = useMemo(
    () => `${highlight.scoreBreakdown?.kills}K - ${highlight.score}`,
    [highlight.score, highlight.scoreBreakdown?.kills]
  )

  return (
    <ContainerDiv $selected={isSelected}>
      <div onClick={() => setSelectedHighlight(highlight)}>
        <Spacing all={8}>
          <HStack alignItems="center" justifyContent="space-between">
            <NameContainerDiv>
              <Text variant="text-2" weight="bold">
                {highlight.name}
              </Text>
            </NameContainerDiv>
            <Text variant="text-2" weight="bold">
              {highlight.score}
            </Text>
          </HStack>
        </Spacing>
        <Spacing all={8}>
          {highlight.scoreBreakdown?.type === HighlightTypes.Kills ? (
            <HStack alignItems="center" justifyContent="center">
              <Text variant="text-3" weight="bold">
                {highlight.scoreBreakdown?.kills} Kills
              </Text>
              <Text variant="text-3" weight="semibold">
                &nbsp;over&nbsp;
              </Text>
              <Text variant="text-3" weight="bold">
                {(highlight.endsAt - highlight.startsAt) / 1000}s
              </Text>
            </HStack>
          ) : null}
        </Spacing>
      </div>
      {isSelected ? (
        <Spacing all={8}>
          <CreateMultiMomentHighlight title={title} />
        </Spacing>
      ) : null}
      <a href={`/valorant/match/${highlight.matchId}?user=45302947`}>
        ViewInEditor
      </a>
    </ContainerDiv>
  )
}

const ContainerDiv = styled.div<{ $selected: boolean }>`
  position: relative;
  border-radius: 8px;
  margin-bottom: 16px;

  border: 1px solid ${({ theme }) => theme.colors.neutrals.n6};
  cursor: pointer;

  transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

  ${({ $selected, theme }) =>
    $selected
      ? `
    background-color: ${theme.colors.neutrals.n8};
    box-shadow: ${theme.elevation.depth2};
  `
      : ``}

  &:hover {
    background-color: ${({ theme }) => theme.colors.neutrals.n8};
    box-shadow: ${({ theme }) => theme.elevation.depth2};
  }
`

const NameContainerDiv = styled.div`
  max-width: 75%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
