import { isEqual } from 'lodash'
import momentjs from 'moment'
import { useCallback, useEffect, useMemo } from 'react'
import { useParams } from 'react-router'

import { Spacing } from 'components/core/layout'
import { Text } from 'components/core/text'

import { GroupedSegment, useCampaignData } from '../hooks'
import {
  CampaignUserPreviewImg,
  SegmentListDiv,
  SegmentOptionDiv,
} from '../styled'

interface Props {
  setGroupedSegment: (gs: GroupedSegment) => void
  selectedSegment: GroupedSegment | undefined
}

export const CampaignSegments = ({
  setGroupedSegment,
  selectedSegment,
}: Props) => {
  const { campaignSlug } = useParams<{ campaignSlug }>()
  const { groupedSegments } = useCampaignData(campaignSlug)

  useEffect(() => {
    if (selectedSegment === undefined && groupedSegments[0]) {
      setGroupedSegment(groupedSegments[0])
    }
  }, [groupedSegments, selectedSegment, setGroupedSegment])

  return (
    <SegmentListDiv>
      {groupedSegments.map((gs, idx) => {
        return (
          <SegmentOption
            key={idx}
            segment={gs}
            setGroupedSegment={setGroupedSegment}
            selectedSegment={selectedSegment}
          />
        )
      })}
    </SegmentListDiv>
  )
}

interface SegementOptionProps {
  selectedSegment: GroupedSegment | undefined
  segment: GroupedSegment
  setGroupedSegment: (gs: GroupedSegment) => void
}

const SegmentOption = ({
  setGroupedSegment,
  segment,
  selectedSegment,
}: SegementOptionProps) => {
  const startDate = momentjs(segment.segment.from).format('ll')
  const startTime = momentjs(segment.segment.from).format('LT')

  const setSegment = useCallback(() => {
    setGroupedSegment(segment)
  }, [segment, setGroupedSegment])

  const isSelected = useMemo(() => {
    return isEqual(segment, selectedSegment)
  }, [segment, selectedSegment])

  return (
    <SegmentOptionDiv $selected={isSelected} onClick={setSegment}>
      <Text variant="text-3" weight="semibold" as="p">
        {startDate}
      </Text>
      <Text variant="text-3" as="p">
        {startTime}
      </Text>
      <Spacing top={8}>
        {segment.channels.map((ch, idx) => {
          return (
            <CampaignUserPreviewImg key={idx} src={ch.profileImageUrl ?? ''} />
          )
        })}
      </Spacing>
    </SegmentOptionDiv>
  )
}
