import styled from 'styled-components/macro'
import { fontWeights } from 'theme/typography'

export const SearchWrapper = styled.div<{ expanded: boolean }>`
  transition: var(--common-animation-duration) flex-grow ease-in-out;
  flex-grow: ${({ expanded }) => (expanded ? '1' : '0')};

  position: relative;

  & > input {
    width: 100%;
  }
`

export const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const ProfileImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 20px;
`

export const SearchResultWrapper = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  padding: 0 16px;
  z-index: 1;
`

export const SearchResultList = styled.ul`
  background-color: ${({ theme }) => theme.colors.background.primary};
  box-shadow: ${({ theme }) => theme.elevation.depth3};
  border-radius: 8px;
  padding: 8px 0;
`
export const StyledSearchResultListItem = styled.li`
  padding: 16px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.background.secondary};
  }
`

export const HighlightedText = styled.span`
  font-weight: ${fontWeights.semibold};
`
