import styled, { css } from 'styled-components/macro'
import { transparentize } from 'polished'

export const TimelineContainerDiv = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 100%;
    left: 0;
    top: 0;
    background: linear-gradient(90deg, #23262f 0%, rgba(35, 38, 47, 0) 100%);
    pointer-events: none;
  }
`

export const TimelineContentDiv = styled.div`
  position: relative;
  user-select: none;
`

export const TimelineMainContentDiv = styled.div`
  position: relative;
`

export const PlayerDataDisplayDiv = styled.div`
  padding: 21px 0;
`

export const CurrentTimeDiv = styled.div`
  position: absolute;
  top: 21px;
  height: 100%;
  width: 1px;
  background-color: ${({ theme }) => theme.colors.blue};

  & > svg {
    position: absolute;
    top: 0;
    left: -3px;
    width: 7px;
    height: 9px;
    color: ${({ theme }) => theme.colors.blue};
  }
`

export const SceneDragDiv = styled.div<{ $type: 'left' | 'right' }>`
  pointer-events: all;
  position: absolute;
  top: calc(50% - 5px);
  width: 6px;
  height: 10px;

  border-radius: 2px;
  background-color: ${({ theme }) => theme.colors.gold};
  cursor: col-resize;

  ${(p) =>
    p.$type === 'left'
      ? css`
          left: -4px;
        `
      : css`
          right: -4px;
        `}
`

export const SceneContainerDiv = styled.div`
  position: absolute;
  user-select: none;
  pointer-events: none;
  top: 30px;
  height: calc(100% - 30px);
  background-color: ${({ theme }) => transparentize(0.75)(theme.colors.gold)};
  border: 2px solid ${({ theme }) => theme.colors.gold};
  border-width: 0 1px;
`
