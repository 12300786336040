import styled, { css } from 'styled-components/macro'
import { rgba, transparentize } from 'polished'
import { colors } from 'utils/colors'

export const TemplateEditorDiv = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

export const TemplateCropContainerDiv = styled.div<{ $selected: boolean }>`
  position: absolute;
  border: 1px solid ${(p) => (p.$selected ? 'red' : 'black')};
  overflow: hidden;
  cursor: pointer;
  pointer-events: none;
`

export const TemplateSettingsContainerDiv = styled.div`
  position: absolute;
  top: 4px;
  left: 4px;
  z-index: 3;
`

export const PreviewContentsDiv = styled.div<{ $active: boolean }>`
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: black;
  pointer-events: ${({ $active }) => ($active ? 'auto' : 'none')};
`

export const VideoContentDiv = styled.div`
  position: absolute;
  transform-origin: top left;
`

export const CropSelectionContainerDiv = styled.div<{
  $editing: boolean
  $dragging: boolean
}>`
  position: absolute;
  border: 1px solid white;

  ${(p) =>
    p.$editing
      ? css`
          cursor: grab;
          z-index: 2;
          border: 1px dashed ${colors.green.base};
        `
      : ``}

  ${(p) =>
    p.$dragging
      ? css`
          cursor: grabbing;
          border: 1px dashed ${colors.green.base};
          background-color: ${rgba(colors.green.light, 0.25)};
        `
      : ``}
`

export const CropTemplateSelectionContainerDiv = styled.div<{
  $editing: boolean
  $dragging: boolean
}>`
  position: absolute;
  border: 1px solid white;

  ${(p) =>
    p.$editing
      ? css`
          cursor: grab;
          border: 1px dashed ${colors.green.base};
        `
      : ``}

  ${(p) =>
    p.$dragging
      ? css`
          cursor: grabbing;
          border: 1px dashed ${colors.green.base};
          background-color: ${rgba(colors.green.light, 0.25)};
        `
      : ``}
`

export const CropResizeSquareDiv = styled.div<{ $editing: boolean }>`
  position: absolute;
  right: -5px;
  bottom: -5px;
  width: 10px;
  height: 10px;
  background-color: ${colors.blue.light};
  border: 1px solid ${colors.blue.dark};
  cursor: nwse-resize;

  ${(p) =>
    p.$editing
      ? ``
      : css`
          display: none;
        `}
`

export const CropDisabledDiv = styled.div`
  position: absolute;
  border: 1px solid ${colors.green.light};
  background-color: ${rgba(colors.green.lightest, 0.2)};
`

export const CropBackgroundDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
`

export const TemplateEditorPreviewCropDiv = styled.div<{
  $selected: boolean
  $dragging: boolean
}>`
  position: absolute;
  overflow: hidden;

  ${(p) =>
    p.$selected
      ? css`
          cursor: grab;
        `
      : css`
          cursor: pointer;
          &:hover {
            border-color: #777;
          }
        `}

  ${(p) =>
    p.$dragging
      ? css`
          cursor: grabbing;
        `
      : ``}
`

export const ContentCoverDiv = styled.div<{
  $selected: boolean
  $color: string
}>`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  pointer-events: none;
  ${({ $color, $selected }) =>
    `border: ${$selected ? 4 : 1}px solid ${
      $selected ? $color : transparentize(0.5)($color)
    }`}
`

export const CloseButton = styled.button`
  position: absolute;
  right: 16px;
  top: 16px;
  width: 32px;
  height: 32px;

  border: 0;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.static.neutrals.n2};
  z-index: 1;

  transition: background-color 0.15s ease-in-out, opacity 0.15s ease-in-out;

  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.static.neutrals.n3};
  }

  &:active {
    opacity: 0.75;
  }
`

export const EditCropContentsDiv = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

export const FacecamSettingOptionsDiv = styled.div`
  position: absolute;
  top: 8px;
  left: 8px;
  display: flex;
  align-items: center;
  gap: 16px;
  z-index: 2;
`

export const FacecamContainerDiv = styled.div`
  position: absolute;
`
