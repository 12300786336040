import { AspectRatioContainer } from 'components/aspect-ratio/container'
import { SyncedVideo } from 'core/editor/synced-video'
import {
  EditorPlayerContainerDiv,
  MainPlayerDiv,
  MainVideoWrapperDiv,
  SecondaryPlayerContainerDiv,
} from 'core/shared/video-player/styled'
import { useMemo } from 'react'
import SimpleBar from 'simplebar-react'
import { usePlayerLayout } from '../shared/video-player/hooks'
import { useSignalPlayerState } from './context'
import { PreviewPlayerOption } from './player-option'

interface Props {
  videoId: string
  availableDimensions: { width: number; height: number }
}

export const SignalVideoPlayer = ({ videoId, availableDimensions }: Props) => {
  const { visibleRelatedVideoIds, selectedVideoId } = useSignalPlayerState()
  // + 1 since the main video is not counted in visibleRelatedVideos
  const { mainPlayerStyles, secondaryContainerStyles, secondaryItemStyles } =
    usePlayerLayout(availableDimensions, visibleRelatedVideoIds.length + 1)

  const filteredVideos = useMemo(() => {
    return [videoId, ...visibleRelatedVideoIds]
  }, [videoId, visibleRelatedVideoIds])

  const playerSettings = useMemo(() => ({ muted: false }), [])

  return (
    <EditorPlayerContainerDiv>
      <MainPlayerDiv style={mainPlayerStyles}>
        <AspectRatioContainer width={16} height={9}>
          <MainVideoWrapperDiv>
            <SyncedVideo
              id={`main_${videoId}`}
              primaryVideoId={videoId}
              videoId={selectedVideoId}
              playerOptions={playerSettings}
              // if it is the only video, it should be the controller
              // otherwise it should be the preview version
              controller={
                visibleRelatedVideoIds.length === 0 &&
                selectedVideoId === videoId
              }
            />
          </MainVideoWrapperDiv>
        </AspectRatioContainer>
      </MainPlayerDiv>
      {visibleRelatedVideoIds.length > 0 && (
        <SecondaryPlayerContainerDiv style={secondaryContainerStyles}>
          <SimpleBar style={secondaryContainerStyles}>
            {filteredVideos.map((vidId) => (
              <PreviewPlayerOption
                key={vidId}
                videoId={vidId}
                style={secondaryItemStyles}
              />
            ))}
          </SimpleBar>
        </SecondaryPlayerContainerDiv>
      )}
    </EditorPlayerContainerDiv>
  )
}
