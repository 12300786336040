import styled, { css } from 'styled-components/macro'

export const PlayerButtonDiv = styled.div<{
  $disabled?: boolean
  $active?: boolean
}>`
  position: relative;
  padding: 8px;
  height: 24px;
  width: 24px;
  /* background-color: #eee; */
  border-radius: 4px;
  /* box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1); */
  background: #353945;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  }

  & > svg {
    fill: #b1b5c3;
    ${(p) =>
      p.$disabled
        ? css`
            fill: #efefef;
          `
        : p.$active
        ? css`
            fill: #7bff7b;
          `
        : null}
    width: 100%;
    height: 100%;
  }
`

export const PlayerControlsContainerDiv = styled.div`
  height: 40px;
  padding-left: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
`
