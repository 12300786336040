import styled from 'styled-components/macro'

import { Button } from 'components/core/button'
import { useState } from 'react'

import { QuickSaveModal } from './quick-save'

export const UnsavedSignal = () => {
  const [showSaveModal, setShowSaveModal] = useState(false)

  return (
    <SaveDiv>
      <Button
        size="small"
        onClick={() => setShowSaveModal((current) => !current)}
      >
        Quick Save
      </Button>
      {showSaveModal && (
        <QuickSaveModal onClose={() => setShowSaveModal(false)} />
      )}
    </SaveDiv>
  )
}

const SaveDiv = styled.div`
  position: relative;
`
