import styled, { css } from 'styled-components/macro'
import { rgba } from 'polished'
import { colors } from 'utils/colors'

export const TemplateEditorDiv = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

export const TemplateCropContainerDiv = styled.div<{ $selected: boolean }>`
  position: absolute;
  border: 1px solid ${(p) => (p.$selected ? 'red' : 'black')};
  overflow: hidden;
  cursor: pointer;
`

export const TemplateSettingsContainerDiv = styled.div`
  position: absolute;
  top: 4px;
  left: 4px;
  z-index: 3;
`

export const PreviewContentsDiv = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
`

export const VideoContentDiv = styled.div`
  position: absolute;
  transform-origin: top left;
`

export const CropSelectionContainerDiv = styled.div<{
  $editing: boolean
  $dragging: boolean
}>`
  position: absolute;
  border: 1px solid white;

  ${(p) =>
    p.$editing
      ? css`
          cursor: grab;
          z-index: 2;
          border: 1px dashed ${colors.green.base};
        `
      : ``}

  ${(p) =>
    p.$dragging
      ? css`
          cursor: grabbing;
          border: 1px dashed ${colors.green.base};
          background-color: ${rgba(colors.green.light, 0.25)};
        `
      : ``}
`

export const CropTemplateSelectionContainerDiv = styled.div<{
  $editing: boolean
  $dragging: boolean
}>`
  position: absolute;
  border: 1px solid white;

  ${(p) =>
    p.$editing
      ? css`
          cursor: grab;
          border: 1px dashed ${colors.green.base};
        `
      : ``}

  ${(p) =>
    p.$dragging
      ? css`
          cursor: grabbing;
          border: 1px dashed ${colors.green.base};
          background-color: ${rgba(colors.green.light, 0.25)};
        `
      : ``}
`

export const CropResizeSquareDiv = styled.div<{ $editing: boolean }>`
  position: absolute;
  right: -5px;
  bottom: -5px;
  width: 10px;
  height: 10px;
  background-color: ${colors.blue.light};
  border: 1px solid ${colors.blue.dark};
  cursor: nwse-resize;

  ${(p) =>
    p.$editing
      ? ``
      : css`
          display: none;
        `}
`

export const CropDisabledDiv = styled.div`
  position: absolute;
  border: 1px solid ${colors.green.light};
  background-color: ${rgba(colors.green.lightest, 0.2)};
`

export const CropBackgroundDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
`
