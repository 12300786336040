import React, { useEffect, useMemo } from 'react'
import momentjs from 'moment'

import { useVideoSyncState } from './context'
import { VideoSyncModalContainerDiv } from './styled'
import { useVideoSyncHelpers } from './hooks'
import { VideoSyncSelect } from './video-select'
import { VideoSyncEditor } from './editor'
import {
  MultiPerspectiveMomentDetailFragment,
  useGetMomentDetailQuery,
} from '__generated__'
import { useTimelineState } from 'core/editor/timeline/context'
import { useTimelineHelpers } from 'core/editor/timeline/hooks'

interface VideoSyncControllerProps {
  momentId: string
}

export const VideoSyncController: React.FC<VideoSyncControllerProps> = ({
  momentId,
}) => {
  const { primaryVideoId, secondaryVideoId } = useVideoSyncState()
  useSyncEditor(momentId)
  const { closeVideoSync } = useVideoSyncHelpers()

  return momentId ? (
    <VideoSyncModalContainerDiv>
      {momentId}
      {primaryVideoId && secondaryVideoId ? (
        <VideoSyncEditor
          momentId={momentId}
          primaryVideoId={primaryVideoId}
          secondaryVideoId={secondaryVideoId}
        />
      ) : (
        <VideoSyncSelect momentId={momentId} />
      )}
      <button onClick={closeVideoSync}>Close</button>
    </VideoSyncModalContainerDiv>
  ) : null
}

const useSyncEditor = (momentId: string) => {
  const { data } = useGetMomentDetailQuery({ variables: { momentId } })
  const { setTimelineStartAndEnd, setTimelineLength } = useTimelineHelpers()
  const { timelineLength } = useTimelineState()

  const moment = useMemo(() => {
    return data?.moment as MultiPerspectiveMomentDetailFragment | undefined
  }, [data?.moment])
  const startTime = momentjs(moment?.startsAt).valueOf()
  const endTime = momentjs(moment?.endsAt).valueOf()

  const videoIds = useMemo(() => {
    return moment?.videos.map(({ id }) => id) ?? []
  }, [moment?.videos])

  const offsets = useMemo(() => {
    return moment?.offsets
  }, [moment?.offsets])

  // initialize size of timeline
  useEffect(() => {
    if (timelineLength !== endTime - startTime) {
      setTimelineStartAndEnd(startTime, endTime)
      setTimelineLength(endTime - startTime)
    }
  }, [
    startTime,
    endTime,
    setTimelineLength,
    timelineLength,
    setTimelineStartAndEnd,
  ])

  return useMemo(() => ({ videoIds, offsets }), [offsets, videoIds])
}
