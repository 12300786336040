import { useCallback, useMemo } from 'react'
import { useQueryParamFilters } from 'utils/browser'
import { useSyncMatchVideoMutation } from '__generated__'

export const includeKey = 'include'
export const useIncludeFilters = () => {
  const { params, setParam } = useQueryParamFilters()

  const { valorant, chat } = useMemo(() => {
    const currentParams = params[includeKey] as string[] | undefined
    if (!currentParams) return { valorant: true, chat: true }
    return {
      valorant: currentParams.includes('valorant'),
      chat: currentParams.includes('chat'),
    }
  }, [params])

  const toggleValorant = useCallback(() => {
    const nextState: string[] = []
    if (chat) {
      nextState.push('chat')
    }
    if (!valorant) {
      nextState.push('valorant')
    }
    setParam(includeKey, nextState)
  }, [chat, setParam, valorant])

  const toggleChat = useCallback(() => {
    const nextState: string[] = []
    if (!chat) {
      nextState.push('chat')
    }
    if (valorant) {
      nextState.push('valorant')
    }
    setParam(includeKey, nextState)
  }, [chat, setParam, valorant])

  return useMemo(
    () => ({
      valorant,
      chat,
      toggleValorant,
      toggleChat,
    }),
    [chat, toggleChat, toggleValorant, valorant]
  )
}

export const useSyncMatchPerspectives = () => {
  const [syncVideoMatch] = useSyncMatchVideoMutation()

  return useCallback(
    async (matchId: string, videoIds: string[]) => {
      try {
        return await Promise.all(
          videoIds.map((videoId) =>
            syncVideoMatch({ variables: { matchId, videoId } })
          )
        )
      } catch (error) {}
    },
    [syncVideoMatch]
  )
}
