import React, { useMemo } from 'react'
import momentjs from 'moment'

import { useGetVideoSrcUrlsQuery } from '__generated__'
import { StillVideoPlayer } from './player'

interface VideoStillProps {
  videoId: string
  timeInMs: number
}

export const VideoStill: React.FC<VideoStillProps> = ({
  videoId,
  timeInMs,
}) => {
  const { srcUrl, seekTime } = useVideoStillData(videoId, timeInMs)
  return seekTime && srcUrl ? (
    <StillVideoPlayer seekTime={seekTime} videoUrl={srcUrl} />
  ) : null
}

const useVideoStillData = (videoId: string, timeInMs: number) => {
  const { data } = useGetVideoSrcUrlsQuery({ variables: { videoId } })

  const bestSrcUrl = data?.video?.vod.find(
    ({ resolution }) => resolution === 'best'
  )?.url

  const startTime = data?.video?.stream?.startedAt
    ? momentjs(data?.video?.stream?.startedAt).valueOf()
    : undefined

  const seekTime = startTime ? timeInMs - startTime : undefined

  return useMemo(
    () => ({
      srcUrl: bestSrcUrl,
      seekTime,
    }),
    [bestSrcUrl, seekTime]
  )
}
