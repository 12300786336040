import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { useGetOrganizationCollectionsListQuery } from '__generated__'

import {
  CollectionAction,
  useCollectionDispatch,
} from '../collections-context-provider'

import { ListSkeleton } from './list-skeleton'
import {
  BottomListItem,
  Heading,
  ListContainer,
  ListItem,
  UnorderedList,
} from './styled'
import { CreateCollectionDialog } from './create-collection-dialog'

type Props = {
  organizationSlug: string
}

export const CollectionsMenu = ({ organizationSlug }: Props) => {
  const { collections, loading, isSelected, onSelect } =
    useCollectionsList(organizationSlug)
  const { isOpen, closeDialog, showDialog } = useCreateDialog()

  return (
    <>
      <ListContainer>
        <Heading>COLLECTIONS</Heading>
        {loading ? (
          <ListSkeleton />
        ) : (
          <UnorderedList>
            {collections.map(({ title }, i) => (
              <ListItem selected={isSelected(i)} key={`${i}`}>
                <button onClick={onSelect(i)}>{title}</button>
              </ListItem>
            ))}
            <BottomListItem>
              <button onClick={showDialog}>Create new collection</button>
            </BottomListItem>
          </UnorderedList>
        )}
      </ListContainer>
      {isOpen && <CreateCollectionDialog onClose={closeDialog} />}
    </>
  )
}

const useCollectionsList = (slug: string) => {
  const { data, loading } = useGetOrganizationCollectionsListQuery({
    variables: { slug },
  })
  const { replace } = useHistory()
  const { params } =
    useRouteMatch<{ organization: string; momentCollectionId?: string }>()
  const dispatch = useCollectionDispatch()
  const [selectedId, setSelectedId] = useState<string | undefined>(
    params.momentCollectionId
  )

  const collections = useMemo(
    () => data?.organization?.collections.data || [],
    [data?.organization?.collections.data]
  )

  const isSelected = useCallback(
    (idx: number) => selectedId === collections[idx].id,
    [selectedId, collections]
  )

  const onSelect = useCallback(
    (idx: number) => {
      return () => {
        const collection = collections[idx]
        setSelectedId(collection.id)
      }
    },
    [collections]
  )

  useEffect(() => {
    if (selectedId === undefined && collections.length === 0) {
      return
    }

    if (selectedId === undefined) {
      setSelectedId(collections[0].id)
    }

    const collection = collections.find(({ id }) => id === selectedId)
    if (!collection) {
      return
    }

    dispatch({
      type: CollectionAction.SetSelectedCollection,
      payload: { value: collection },
    })

    if (collection && params.momentCollectionId !== collection.id) {
      replace(`/o/${params.organization}/collections/${collection.id}`)
    }
  }, [dispatch, selectedId, collections, replace, params])

  return {
    loading,
    isSelected,
    onSelect,
    collections,
  }
}

const useCreateDialog = () => {
  const [isOpen, setOpen] = useState(false)

  const close = () => {
    setOpen(false)
  }

  const open = () => {
    setOpen(true)
  }

  return {
    isOpen,
    showDialog: open,
    closeDialog: close,
  }
}
