import styled, { css } from 'styled-components/macro'

export const TemplateEditorDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding-top: 5vh;
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 3;
`

export const CropSettingsListDiv = styled.div`
  position: relative;
  width: 340px;
  margin-right: 40px;
  /* border: 1px solid #ccc; */
`

export const CropSettingsDiv = styled.div<{ $selected: boolean }>`
  position: relative;
  width: 100%;
  border: 1px solid ${(p) => (p.$selected ? 'red' : '#ccc')};
  margin: 8px 0;
  padding: 12px;
  cursor: pointer;

  &:hover {
    border: 1px solid ${(p) => (p.$selected ? 'red' : '#777')};
  }
`

export const CropSettingsActionsDiv = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  select {
    height: 32px;
  }
`

export const TemplateEditorPreviewDiv = styled.div`
  position: relative;
  height: 90vh;
  width: 800px;
`
export const PreviewContentsDiv = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
  overflow: hidden;
`

export const TemplateEditorPreviewCropDiv = styled.div<{
  $selected: boolean
  $dragging: boolean
}>`
  position: absolute;
  border: 1px solid ${(p) => (p.$selected ? 'red' : '#ccc')};

  ${(p) =>
    p.$selected
      ? css`
          cursor: grab;
        `
      : css`
          cursor: pointer;
          &:hover {
            border-color: #777;
          }
        `}

  ${(p) =>
    p.$dragging
      ? css`
          cursor: grabbing;
        `
      : ``}
`

export const PrimaryCropSelectDiv = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    width: 16px;
    height: 16px;
  }
`
export const RemoveCropDiv = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    width: 16px;
    height: 16px;
  }
`

export const AspectRatioCustomDiv = styled.div`
  margin-top: 4px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  input {
    width: 40px;
    border: 1px solid #333;
    font-size: 12px;
    padding: 2px 4px;
  }
`

export const SaveTemplateDiv = styled.div`
  margin-top: 12px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;

  select {
    height: 32px;
    margin-right: 12px;
  }
`

export const CloseButtonDiv = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
  width: 32px;
  height: 32px;
  cursor: pointer;

  svg {
    width: 100%;
    height: 100%;
  }
`
