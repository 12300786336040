import { DEFAULT_CONTENT_TYPES } from 'core/shared/templates/constants'
import { randomUniqueId } from 'utils'
import { OverlayInput } from '__generated__'

export interface Rect {
  x: number
  y: number
  width: number
  height: number
}

export interface Scene {
  id?: string
  start: number
  end: number
  template: ExportTemplate
  index: number
  cropData: {
    title: string
    videoId: string
    cropRect: Rect
  }[]
  overlays: OverlayInput[]
}

export interface ApiScene {
  start: string
  end: string
  template: TemplateApi
  index: number
  cropData: {
    title: string
    videoId: string
    muted: boolean
    cropRect: Rect
    offset: number
  }[]
  overlays: OverlayInput[]
}

// This is for container resolution/platform
export interface ExportFormat {
  id: string
  title: string
  aspectRatio: {
    width: number
    height: number
  }
}

export const STANDARD_FORMAT: ExportFormat = {
  id: 'effef67b-2722-461d-8dc2-99b06badc376',
  title: 'Horizontal',
  aspectRatio: {
    width: 16,
    height: 9,
  },
}

export const TIKTOK_FORMAT: ExportFormat = {
  id: '8da60737-1a47-4b2d-bef6-3b7f3ac3e672',
  title: 'Vertical',
  aspectRatio: {
    width: 9,
    height: 16,
  },
}

export const IG_SQUARE_FORMAT: ExportFormat = {
  id: '6b17f413-5f16-4ca9-9454-68f387376c3d',
  title: 'Square',
  aspectRatio: {
    width: 1,
    height: 1,
  },
}
export const IG_FORMAT: ExportFormat = {
  id: '567915af-cfc7-4cd6-855c-461adb4df89f',
  title: 'Instagram Post 4:5',
  aspectRatio: {
    width: 4,
    height: 5,
  },
}

export const FORMATS: ExportFormat[] = [
  STANDARD_FORMAT,
  TIKTOK_FORMAT,
  IG_SQUARE_FORMAT,
]

export interface TemplateCrop {
  title: string
  position: Rect
  primary: boolean
  aspectRatio: {
    width: number
    height: number
  }
}

export type DefaultTemplateSetting = {
  id?: number
  title: string
  format: ExportFormat
  crops: TemplateCropSettings[]
}

export type TemplateCropSettings = {
  localId: string
  defaultContent: DEFAULT_CONTENT_TYPES
} & TemplateCrop

export type TemplateCropApi = Omit<TemplateCrop, 'primary'>

export interface TemplateApi {
  title: string
  crops: TemplateCropApi[]
}

export interface ExportTemplate {
  title: string
  crops: TemplateCropSettings[]
}

export interface ExportCropData {
  title: string
  videoId: string
  rect: Rect
}

export const DEFAULT_16_9: DefaultTemplateSetting = {
  title: 'Fullscreen',
  format: STANDARD_FORMAT,
  crops: [
    {
      localId: randomUniqueId(),
      title: 'Gameplay',
      defaultContent: DEFAULT_CONTENT_TYPES.GAMEPLAY,
      primary: true,
      position: {
        x: 0,
        y: 0,
        width: 1,
        height: 1,
      },
      aspectRatio: {
        width: 16,
        height: 9,
      },
    },
  ],
}

const DEFAULT_1_1 = {
  title: 'Only Gameplay',
  format: IG_SQUARE_FORMAT,
  crops: [
    {
      localId: randomUniqueId(),
      title: 'Gameplay',
      defaultContent: DEFAULT_CONTENT_TYPES.GAMEPLAY,
      primary: true,
      position: {
        x: 0,
        y: 0,
        width: 1,
        height: 1,
      },
      aspectRatio: {
        width: 1,
        height: 1,
      },
    },
  ],
}

export const DEFAULT_9_16: DefaultTemplateSetting = {
  id: 6,
  title: 'Only Gameplay',
  format: TIKTOK_FORMAT,
  crops: [
    {
      localId: randomUniqueId(),
      title: 'Gameplay',
      defaultContent: DEFAULT_CONTENT_TYPES.GAMEPLAY,
      primary: true,
      position: {
        x: 0,
        y: 0,
        width: 1,
        height: 1,
      },
      aspectRatio: {
        width: 9,
        height: 16,
      },
    },
  ],
}

export const TIKTOK_FACE_DEFAULT: DefaultTemplateSetting = {
  id: 4,
  title: 'Top',
  format: TIKTOK_FORMAT,
  crops: [
    {
      localId: randomUniqueId(),
      title: 'Gameplay',
      defaultContent: DEFAULT_CONTENT_TYPES.GAMEPLAY,
      primary: true,
      aspectRatio: {
        width: 0,
        height: 0,
      },
      position: {
        height: 0.7291666666666666,
        width: 1,
        x: 0,
        y: 0.2708333333333333,
      },
    },
    {
      localId: randomUniqueId(),
      title: 'Main Camera',
      defaultContent: DEFAULT_CONTENT_TYPES.CAMERA,
      primary: false,
      aspectRatio: {
        width: 0,
        height: 0,
      },
      position: {
        height: 0.26670092497430625,
        width: 1,
        x: 0,
        y: 0,
      },
    },
  ],
}

export const SAMPLE_TEMPLATES = [
  DEFAULT_16_9,
  DEFAULT_1_1,
  DEFAULT_9_16,
  TIKTOK_FACE_DEFAULT,
  {
    id: 5,
    title: 'Top',
    format: TIKTOK_FORMAT,
    crops: [
      {
        localId: randomUniqueId(),
        title: 'Gameplay',
        defaultContent: DEFAULT_CONTENT_TYPES.GAMEPLAY,
        primary: true,
        aspectRatio: {
          width: 0,
          height: 0,
        },
        position: {
          height: 0.7291666666666666,
          width: 1,
          x: 0,
          y: 0.2708333333333333,
        },
      },
      {
        localId: randomUniqueId(),
        title: 'Main Camera',
        defaultContent: DEFAULT_CONTENT_TYPES.CAMERA,
        primary: false,
        aspectRatio: {
          width: 0,
          height: 0,
        },
        position: {
          height: 0.26670092497430625,
          width: 0.6481481481481481,
          x: 0,
          y: 0,
        },
      },
      {
        localId: randomUniqueId(),
        title: 'Guest Camera',
        defaultContent: DEFAULT_CONTENT_TYPES.CAMERA,
        primary: false,
        aspectRatio: {
          width: 0,
          height: 0,
        },
        position: {
          height: 0.26666666666666666,
          width: 0.3472222222222222,
          x: 0.6527777777777778,
          y: 0,
        },
      },
    ],
  },
  {
    id: 6,
    title: 'Guest',
    format: TIKTOK_FORMAT,
    crops: [
      {
        localId: randomUniqueId(),
        title: 'Main Camera',
        defaultContent: DEFAULT_CONTENT_TYPES.CAMERA,
        primary: false,
        aspectRatio: {
          width: 0,
          height: 0,
        },
        position: {
          height: 0.2760416666666667,
          width: 1,
          x: 0,
          y: 0,
        },
      },
      {
        localId: randomUniqueId(),
        title: 'Guest Camera',
        defaultContent: DEFAULT_CONTENT_TYPES.CAMERA,
        primary: false,
        aspectRatio: {
          width: 0,
          height: 0,
        },
        position: {
          height: 0.2760416666666667,
          width: 1,
          x: 0,
          y: 0.2786458333333333,
        },
      },
      {
        localId: randomUniqueId(),
        title: 'Gameplay',
        defaultContent: DEFAULT_CONTENT_TYPES.GAMEPLAY,
        primary: true,
        aspectRatio: {
          width: 0,
          height: 0,
        },
        position: {
          height: 0.4433551198257081,
          width: 1,
          x: 0,
          y: 0.5566448801742919,
        },
      },
    ],
  },
  {
    id: '8',
    title: 'FullScreen',
    format: IG_FORMAT,
    crops: [
      {
        localId: randomUniqueId(),
        title: 'GAMEPLAY',
        defaultContent: DEFAULT_CONTENT_TYPES.GAMEPLAY,
        primary: true,
        position: {
          x: 0,
          y: 0,
          width: 1,
          height: 1,
        },
        aspectRatio: {
          width: 4,
          height: 5,
        },
      },
    ],
  },
]

export const DEFAULT_TEMPLATE_BY_FORMAT_ID = {
  [STANDARD_FORMAT.id]: DEFAULT_16_9,
  [TIKTOK_FORMAT.id]: DEFAULT_9_16,
  [IG_SQUARE_FORMAT.id]: DEFAULT_1_1,
}
