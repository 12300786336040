import { useCallback, useMemo } from 'react'

import { SyncedVideo } from 'core/editor/synced-video'
import {
  PreviewVideoLabelDiv,
  SecondaryPlayerDiv,
  VideoGradientDiv,
} from 'core/shared/video-player/styled'

import { useSignalPlayerState } from './context'
import { useSignalPlayerHelpers, useVideoTimelineData } from './helpers'
import { StreamerThumbImg } from './styled'
import { Text } from 'components/core/text'

interface Props {
  videoId: string
  style: React.CSSProperties
}

export const PreviewPlayerOption = ({ videoId, style }: Props) => {
  const { data } = useVideoTimelineData(videoId)
  const { selectedVideoId, primaryVideoId } = useSignalPlayerState()
  const { setSelectedVideo } = useSignalPlayerHelpers()

  const setSelected = useCallback(() => {
    setSelectedVideo(videoId)
  }, [setSelectedVideo, videoId])

  const isSelected = useMemo(() => {
    return selectedVideoId === videoId
  }, [selectedVideoId, videoId])

  return (
    <SecondaryPlayerDiv
      style={style}
      onClick={setSelected}
      $selected={isSelected}
    >
      <SyncedVideo
        id={`preview_${videoId}`}
        primaryVideoId={primaryVideoId}
        videoId={videoId}
        controller={videoId === primaryVideoId}
      />
      <VideoGradientDiv />
      <PreviewVideoLabelDiv>
        {data && data?.video?.user?.profileImageUrl && (
          <StreamerThumbImg
            $selected={false}
            alt="streamer thumbnail"
            src={data.video.user.profileImageUrl}
          />
        )}
        <Text variant="text-3">{data?.video?.user?.displayName}</Text>
      </PreviewVideoLabelDiv>
    </SecondaryPlayerDiv>
  )
}
