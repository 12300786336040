import React, { useMemo } from 'react'
import momentjs from 'moment'

import { TIMELINE_TIMES, useTimelineState } from '../context'
import { TimeContainerDiv, TimeMarkerContainerDiv, TimeTextDiv } from './styled'

export const TimelineTimeDisplay = () => {
  const { timelineLength, startTime, endTime, containerRect } =
    useTimelineState()

  const timeInterval = useMemo(() => {
    switch (timelineLength) {
      case TIMELINE_TIMES.SHORT:
        return 2 * 60 * 1000
      case TIMELINE_TIMES.MEDIUM:
        return 5 * 60 * 1000
      case TIMELINE_TIMES.LONG:
        return 10 * 60 * 1000
      default:
        if (timelineLength < TIMELINE_TIMES.SHORT) {
          return 1 * 60 * 1000
        }
        return 20 * 60 * 1000
    }
  }, [timelineLength])

  const containerHeight = useMemo(() => {
    return containerRect?.height ?? 1
  }, [containerRect])

  const timeMarks = useMemo(() => {
    const totalTime = endTime - startTime
    const startingPoint = momentjs(startTime).startOf('hour').valueOf()
    const results: { text: string; pct: number }[] = []
    let currentTime = startingPoint

    function calcPositionPct(time: number) {
      return (time - startTime) / totalTime
    }

    while (currentTime < endTime) {
      const momentItem = momentjs(currentTime)
      results.push({
        text: momentItem.format('LT'),
        pct: calcPositionPct(currentTime),
      })

      currentTime += timeInterval
    }
    return results
  }, [endTime, startTime, timeInterval])

  return (
    <TimeContainerDiv $containerHeight={containerHeight}>
      {timeMarks.map(({ text, pct }) => (
        <TimeMarker key={pct} text={text} pct={pct} />
      ))}
    </TimeContainerDiv>
  )
}

interface Props {
  text: string
  pct: number
}

const TimeMarker = React.memo(({ text, pct }: Props) => {
  const style = useMemo(() => ({ left: `${pct * 100}%` }), [pct])

  return (
    <TimeMarkerContainerDiv style={style}>
      <TimeTextDiv>{text}</TimeTextDiv>
    </TimeMarkerContainerDiv>
  )
})
