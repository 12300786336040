import { useCallback, useEffect, useMemo } from 'react'
import momentjs from 'moment'

import {
  useCreateMultiPerspectiveMomentFromReactionSignalMutation,
  useGetMomentDetailLazyQuery,
  useSetMomentRatingMutation,
  useUpdateMultiPerspectiveMomentFromReactionSignalMutation,
  VideoWithOffsetsInput,
  useGetConnectedMomentLazyQuery,
} from '__generated__'
import { EditorProvider, useEditorState } from 'core/editor/context'
import { TimelineProvider } from 'core/editor/timeline/context'
import { ValorantMatchEditorProvider } from 'core/valorant-match/editor/context'
import { ValorantMatchEditor } from 'core/valorant-match/editor'
import { useTimelineHelpers } from 'core/editor/timeline/hooks'
import { SignalPlayerProvider } from 'core/signal-player/context'
import { SignalPlayer } from 'core/signal-player'
import { useEditor, useEditorOffsets } from 'core/editor/hooks'

import { RelatedVideoSelectorModal } from './controls/related-modal'
// import { MomentQuickActionsModal } from './controls/actions-modal'
import { useSignalReviewState } from './context'
import {
  ControlsDiv,
  MetadataSidebarDiv,
  PlayerDiv,
  StyledMomentChatDisplay,
  ValorantMatchOverviewDiv,
} from './styled'
import { Sidebar } from './sidebar'
import { MetaScoreboard } from './components/scoreboard'
import {
  translateRatingEnum,
  translateRatingInt,
  useSignalData,
  useSignalReviewHelpers,
} from './helpers'
import { Controls } from './controls'
// import { ReactionRelatedVideoControls } from './controls/reaction-related-videos'
import { Route, Switch, useRouteMatch } from 'react-router'
import { VideoSyncProvider } from 'core/sync/context'
import { ExportView } from './exports'
// import { ChatMetaInfo } from './components/chat-meta'
// import { RootOnly } from 'core/auth/components'
// import { AdminReviewTools } from './admin-tools'

export const ReactionSignalReview = () => {
  const { path } = useRouteMatch()
  const { signalId, relatedVideoIds, showRelatedVideoModal } =
    useSignalReviewState()
  const { setSelectedRelatedVideoIds } = useSignalReviewHelpers()
  const signalData = useSignalData(signalId)
  const connectedMoment = useMemo(
    () => signalData?.data.connectedMoments[0],
    [signalData]
  )

  useEffect(() => {
    setSelectedRelatedVideoIds(
      signalData?.data.relatedVideoData.map(({ id }) => id) ?? []
    )
  }, [setSelectedRelatedVideoIds, signalData?.data.relatedVideoData])

  return signalData &&
    (signalData.type === 'ChatMoment' ||
      signalData.type === 'ManualSignalMoment') ? (
    <EditorProvider>
      <TimelineProvider>
        <ControlsDiv>
          <Controls />
        </ControlsDiv>
        <Switch>
          <Route path={`${path}`} exact>
            <ValorantMatchOverviewDiv>
              <PlayerDiv>
                {signalData.data.valorantMatchId ? (
                  <ValorantMatchEditorProvider>
                    <ValorantMatchEditor
                      userId={signalData.data.userId}
                      matchId={signalData.data.valorantMatchId}
                      start={signalData.data.signalStartTime}
                      end={signalData.data.signalEndTime}
                    ></ValorantMatchEditor>
                  </ValorantMatchEditorProvider>
                ) : (
                  <SignalPlayerProvider
                    videoId={signalData.data.videoId}
                    relatedVideoData={signalData.data.relatedVideoData}
                  >
                    <SignalPlayer
                      videoId={signalData.data.videoId}
                      relatedVideoData={signalData.data.relatedVideoData}
                      signalStart={signalData.data.signalStartTime}
                      signalEnd={signalData.data.signalEndTime}
                      selectedRelatedVideoIds={relatedVideoIds}
                    />
                  </SignalPlayerProvider>
                )}
              </PlayerDiv>
              <MetadataSidebarDiv>
                <Sidebar>
                  {/* <RootOnly>
                    <AdminReviewTools />
                  </RootOnly> */}
                  {signalData.data.valorantMatchId && (
                    <MetaScoreboard
                      key={signalData.data.valorantMatchId}
                      matchId={signalData.data.valorantMatchId}
                    />
                  )}
                  {/* {signalData.data.valorantMatchId ? null : ( // <ValorantRelatedVideoControls /> */}
                  {/* <ReactionRelatedVideoControls /> */}
                  {/* )} */}
                  {/* <ChatMetaInfo metadata={signalData.data.metadata} /> */}
                  <StyledMomentChatDisplay videoId={signalData.data.videoId} />
                </Sidebar>
              </MetadataSidebarDiv>
            </ValorantMatchOverviewDiv>
          </Route>
          <Route path={`${path}/exports`} exact>
            {connectedMoment && (
              <VideoSyncProvider>
                <ExportView momentId={connectedMoment.id} />
              </VideoSyncProvider>
            )}
          </Route>
        </Switch>
        {showRelatedVideoModal && <RelatedVideoSelectorModal />}
        {/* {showMomentActionsModal && <MomentQuickActionsModal />} */}
      </TimelineProvider>
    </EditorProvider>
  ) : null
}

// Local offset should be responsible for providing draft
// offset changes to the custom edtior offsets
export const useManageSync = () => {
  const { assignOffsets } = useEditor()
  const { localOffsets } = useSignalReviewState()

  useEffect(() => {
    assignOffsets(localOffsets)
  }, [assignOffsets, localOffsets])
}

export const useLoadConnectedMoment = () => {
  const { setOffsets } = useEditor()
  const { signalId, momentId } = useSignalReviewState()
  const [getMoment, { data: momentData }] = useGetMomentDetailLazyQuery()
  const signalData = useSignalData(signalId)
  const { jumpToNewSelection } = useTimelineHelpers()
  const {
    setMomentId,
    setRating,
    setTags,
    setTitle,
    setSelectedRelatedVideoIds,
  } = useSignalReviewHelpers()

  // fetch moment when it changes
  useEffect(() => {
    if (momentId) {
      getMoment({ variables: { momentId } })
    }
  }, [getMoment, momentId])

  // Now when the momentId changes, we will also update the offsets
  // which will be stored in the editor context
  useEffect(() => {
    if (
      momentId &&
      momentData &&
      momentData.moment?.__typename === 'MultiPerspectiveMoment'
    ) {
      const offsets = momentData.moment.offsets.reduce((acc, item) => {
        if (item.fromVideo.id === signalData?.data.videoId) {
          return {
            ...acc,
            [item.toVideo.id]: item.offsets.videoOffset,
          }
        }
        return acc
      }, {}) as { [videoId: string]: number }

      setOffsets(offsets)
      return () => {
        setOffsets({})
      }
    }
  }, [momentData, momentId, setOffsets, signalData?.data.videoId])

  // Sets and Resets when creating or editing a moment, plus holds
  // local state for pending changes
  return useCallback(
    (connectedMomentId: string | undefined) => {
      if (!signalData) return
      if (connectedMomentId === undefined) {
        setRating(undefined)
        setTitle('')
        setTags([])
        setMomentId(undefined)
        jumpToNewSelection(
          signalData.data.signalStartTime,
          signalData.data.signalEndTime
        )
      } else {
        const connectedData = signalData.data.connectedMoments.find(
          ({ id }) => id === connectedMomentId
        )
        if (!connectedData) return

        const numRating = connectedData.ratings[0]?.rating
          ? translateRatingEnum(connectedData.ratings[0].rating)
          : undefined
        setRating(numRating)
        setTags(connectedData.tags)
        setTitle(connectedData.title ?? '')
        setMomentId(connectedMomentId)
        setSelectedRelatedVideoIds(
          connectedData.videos
            .filter((vid) => vid.id !== signalData.data.videoId)
            .map(({ id }) => id)
        )
        jumpToNewSelection(
          new Date(connectedData.startsAt).getTime(),
          new Date(connectedData.endsAt).getTime()
        )
      }
    },
    [
      jumpToNewSelection,
      setMomentId,
      setRating,
      setSelectedRelatedVideoIds,
      setTags,
      setTitle,
      signalData,
    ]
  )
}

export const useCreateMultiPerspectiveMoment = () => {
  const { title, signalId, rating, tags, relatedVideoIds } =
    useSignalReviewState()
  const offsetMapping = useEditorOffsets()
  const { selection } = useEditorState()
  const signalData = useSignalData(signalId)
  const [getConnectedMoment] = useGetConnectedMomentLazyQuery({
    fetchPolicy: 'network-only',
  })

  const [createMomentMutation] =
    useCreateMultiPerspectiveMomentFromReactionSignalMutation({})
  const [setRating] = useSetMomentRatingMutation()

  return useCallback(async () => {
    if (!signalData || !selection) return

    let offsets: VideoWithOffsetsInput[] = []
    if (
      signalData.type === 'ValorantMoment' ||
      (signalData.type === 'ChatMoment' && signalData.data.valorantMatchId)
    ) {
      offsets = getValorantOffsets(
        signalData.data.relatedVideoData,
        signalData.data.videoId
      )
    } else if (signalData.type === 'ChatMoment') {
      offsets = getChatMomentOffsets(
        relatedVideoIds,
        offsetMapping,
        signalData.data.videoId
      )
    }

    const input = {
      title,
      startsAt: momentjs(selection.startTime).toISOString(),
      endsAt: momentjs(selection.endTime).toISOString(),
      published: true,
      videoIds: [signalData.data.videoId, ...relatedVideoIds],
      mainVideoId: signalData.data.videoId,
      edits: [],
      offsets: offsets,
      tags: tags,
      signalIds: [signalId],
    }

    const { data } = await createMomentMutation({
      variables: { createMultiPerspectiveMomentInput: input },
    })
    const createdMomentId = data?.createMultiPerspectiveMoment?.id
    if (createdMomentId && rating) {
      await setRating({
        variables: {
          momentId: createdMomentId,
          rating: translateRatingInt(rating),
        },
      })
      // this could be temporary if we do more cache tricks or have setRating
      // resolve a moment
      await getConnectedMoment({ variables: { momentId: createdMomentId } })
    }
  }, [
    createMomentMutation,
    getConnectedMoment,
    offsetMapping,
    rating,
    relatedVideoIds,
    selection,
    setRating,
    signalData,
    signalId,
    tags,
    title,
  ])
}

export const useUpdateMultiPerspectiveMoment = () => {
  const { title, signalId, rating, tags, momentId, relatedVideoIds } =
    useSignalReviewState()
  const { selection } = useEditorState()
  const signalData = useSignalData(signalId)
  const offsetMapping = useEditorOffsets()
  const [getConnectedMoment] = useGetConnectedMomentLazyQuery({
    fetchPolicy: 'network-only',
  })

  const [updateMomentMutation] =
    useUpdateMultiPerspectiveMomentFromReactionSignalMutation()
  const [setRating] = useSetMomentRatingMutation()

  return useCallback(async () => {
    if (!signalData || !selection || !momentId) return

    // TODO: Turn this into something that works well for both valorant/reaction
    let offsets: VideoWithOffsetsInput[] = []
    if (
      signalData.type === 'ValorantMoment' ||
      (signalData.type === 'ChatMoment' && signalData.data.valorantMatchId)
    ) {
      offsets = getValorantOffsets(
        signalData.data.relatedVideoData,
        signalData.data.videoId
      )
    } else if (signalData.type === 'ChatMoment') {
      offsets = getChatMomentOffsets(
        relatedVideoIds,
        offsetMapping,
        signalData.data.videoId
      )
    }

    const input = {
      title,
      startsAt: momentjs(selection.startTime).toISOString(),
      endsAt: momentjs(selection.endTime).toISOString(),
      tags: tags,
      offsets,
      videoIds: [signalData.data.videoId, ...relatedVideoIds],
    }

    const { data } = await updateMomentMutation({
      variables: { momentId, updateMultiPerspectiveMomentInput: input },
    })
    const createdMomentId = data?.updateMultiPerspectiveMoment?.id
    if (createdMomentId) {
      await setRating({
        variables: {
          momentId: createdMomentId,
          rating: translateRatingInt(rating),
        },
      })

      // this could be temporary if we do more cache tricks or have setRating
      // resolve a moment
      await getConnectedMoment({ variables: { momentId: createdMomentId } })
    }
  }, [
    getConnectedMoment,
    momentId,
    offsetMapping,
    rating,
    relatedVideoIds,
    selection,
    setRating,
    signalData,
    tags,
    title,
    updateMomentMutation,
  ])
}

export const getValorantOffsets = (
  relatedVideoData: { id: string; offset?: number }[],
  primaryVideoId: string
) => {
  let offsets: VideoWithOffsetsInput[] = []
  for (const { id, offset } of relatedVideoData) {
    offsets.push({
      fromVideoId: primaryVideoId,
      toVideoId: id,
      offsets: {
        videoOffset: (offset ?? 0) / 1000,
        audioOffset: (offset ?? 0) / 1000,
      },
    })
    offsets.push({
      fromVideoId: id,
      toVideoId: primaryVideoId,
      offsets: {
        videoOffset: -(offset ?? 0) / 1000,
        audioOffset: -(offset ?? 0) / 1000,
      },
    })
  }
  // This is for all relatedVideos
  for (const { id: primaryId, offset: primaryOffset } of relatedVideoData) {
    for (const {
      id: secondaryId,
      offset: secondaryOffset,
    } of relatedVideoData) {
      // offsets aren't needed between same video
      if (primaryId === secondaryId) continue
      offsets.push({
        fromVideoId: primaryId,
        toVideoId: secondaryId,
        offsets: {
          videoOffset: ((secondaryOffset ?? 0) - (primaryOffset ?? 0)) / 1000,
          audioOffset: ((secondaryOffset ?? 0) - (primaryOffset ?? 0)) / 1000,
        },
      })
      offsets.push({
        fromVideoId: secondaryId,
        toVideoId: primaryId,
        offsets: {
          videoOffset: ((primaryOffset ?? 0) - (secondaryOffset ?? 0)) / 1000,
          audioOffset: ((primaryOffset ?? 0) - (secondaryOffset ?? 0)) / 1000,
        },
      })
    }
  }
  return offsets
}

const getChatMomentOffsets = (
  relatedVideoIds: string[],
  offsetMapping: {
    [videoId: string]: number
  },
  primaryVideoId: string
) => {
  let offsets: VideoWithOffsetsInput[] = []
  for (const relatedId of relatedVideoIds) {
    if (offsetMapping[relatedId]) {
      offsets.push({
        fromVideoId: primaryVideoId,
        toVideoId: relatedId,
        offsets: {
          audioOffset: offsetMapping[relatedId],
          videoOffset: offsetMapping[relatedId],
        },
      })
      offsets.push({
        fromVideoId: relatedId,
        toVideoId: primaryVideoId,
        offsets: {
          audioOffset: -offsetMapping[relatedId],
          videoOffset: -offsetMapping[relatedId],
        },
      })
    }
  }

  // if we have data from the primary video, we can imply offsets
  // between each other

  for (const firstId of relatedVideoIds) {
    for (const secondId of relatedVideoIds) {
      if (firstId === secondId) continue
      if (offsetMapping[firstId] && offsetMapping[secondId]) {
        offsets.push({
          fromVideoId: firstId,
          toVideoId: secondId,
          offsets: {
            audioOffset: offsetMapping[secondId] - offsetMapping[firstId],
            videoOffset: offsetMapping[secondId] - offsetMapping[firstId],
          },
        })
        offsets.push({
          fromVideoId: secondId,
          toVideoId: firstId,
          offsets: {
            audioOffset: offsetMapping[firstId] - offsetMapping[secondId],
            videoOffset: offsetMapping[firstId] - offsetMapping[secondId],
          },
        })
      }
    }
  }

  return offsets
}
