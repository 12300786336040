import { Justifications } from 'core/composer/template-sidebar/addons/killfeed'
import styled from 'styled-components/macro'

export const AddonContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
`

export const PlayerDisplayScaledContainer = styled.div<{
  $scale: number
  $top?: number
  $justify: Justifications
  $width?: number
  $height?: number
}>`
  position: absolute;
  width: ${({ $width }) => ($width ? `${$width}px` : '1080px')};
  height: ${({ $height }) => ($height ? `${$height}px` : '1920px')};
  display: flex;
  flex-direction: column;

  padding: 10px;
  transform-origin: top left;
  transform: scale(${({ $scale }) => $scale});
  pointer-events: none;
  padding: 0 ${({ $scale }) => $scale * 80}px; // padding for safe area
  padding-top: ${({ $top }) => ($top ? `${$top}px` : 0)};

  align-items: ${({ $justify }) =>
    $justify === Justifications.left
      ? 'flex-start'
      : $justify === Justifications.center
      ? 'center'
      : 'flex-end'};
`

export const ContentsDiv = styled.div<{ $justify: Justifications }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: ${({ $justify }) =>
    $justify === Justifications.left
      ? 'flex-start'
      : $justify === Justifications.center
      ? 'center'
      : 'flex-end'};
`

export const ProfileImg = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 20px;
  border: 2px solid rgba(255, 255, 255, 0.65);
`
export const RankImg = styled.img`
  width: 60px;
  height: 60px;
`
export const PlayerInfoContainerDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  height: 100px;
  border-radius: 20px;
  padding-right: 48px;
  background-color: rgba(0, 0, 0, 0.65);
`

export const RankingContainerDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px 36px 12px 12px;
  background-color: rgba(0, 0, 0, 0.65);
  border-radius: 20px;
`
