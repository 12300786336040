import React from 'react'
import { ChatMomentContentDiv, ChatMomentsContainerDiv } from '../styled'
import { CreateMultiMoment } from './create'
import { MultiMomentsSidebarList } from './list'

interface MultiMomentsSidebarProps {
  videoId: string
}

export const MultiMomentsSidebar: React.FC<MultiMomentsSidebarProps> = ({
  videoId,
}) => {
  return (
    <ChatMomentsContainerDiv>
      <h2>Multi Perspective Moments</h2>
      <ChatMomentContentDiv>
        <MultiMomentsSidebarList videoId={videoId} />
        <CreateMultiMoment videoId={videoId} />
      </ChatMomentContentDiv>
    </ChatMomentsContainerDiv>
  )
}
