import { useAuth0 } from '@auth0/auth0-react'
import jwtDecode from 'jwt-decode'
import { useEffect, useState } from 'react'
import { UserPayload } from '../protected-route'

export const useIsRootUser = () => {
  const { getAccessTokenSilently } = useAuth0()
  const [isRootUser, setIsRootUser] = useState<boolean | null>(null)

  useEffect(() => {
    const fn = async () => {
      try {
        const token = await getAccessTokenSilently()
        const payload = jwtDecode<UserPayload>(token)
        setIsRootUser(payload.permissions?.includes('root') || false)
      } catch (error) {
        setIsRootUser(false)
      }
    }
    fn()
  }, [getAccessTokenSilently])

  return isRootUser
}
