import { useCallback, useMemo } from 'react'

import {
  ExportFormat,
  Rect,
  TemplateCropSettings,
} from 'core/exporter/constants'

import { CurrentlyEditingContext, useTemplateEditorUpdater } from './context'

interface InitializationProps {
  currentlyEditing: CurrentlyEditingContext
  format: ExportFormat
  templateId?: string
  templateCrops?: TemplateCropSettings[]
}

export const useTemplateEditorHelpers = () => {
  const dispatch = useTemplateEditorUpdater()

  const initialize = useCallback(
    (data: InitializationProps) => {
      dispatch({
        type: useTemplateEditorUpdater.ACTION_TYPES.LOAD_TEMPLATE_EDITOR,
        payload: {
          ...data,
        },
      })
    },
    [dispatch]
  )

  const close = useCallback(() => {
    dispatch({
      type: useTemplateEditorUpdater.ACTION_TYPES.CLOSE,
    })
  }, [dispatch])

  const selectTemplateCrop = useCallback(
    (templateCrop: TemplateCropSettings) => {
      dispatch({
        type: useTemplateEditorUpdater.ACTION_TYPES.SET_SELECTED_TEMPLATE_CROP,
        payload: {
          templateCrop,
        },
      })
    },
    [dispatch]
  )

  const setCropRect = useCallback(
    (title: string, nextRect: Rect) => {
      dispatch({
        type: useTemplateEditorUpdater.ACTION_TYPES.SET_TEMPLATE_CROP_RECT,
        payload: {
          title,
          rect: nextRect,
        },
      })
    },
    [dispatch]
  )

  const addNewTemplateCrop = useCallback(
    (templateCrop: TemplateCropSettings) => {
      dispatch({
        type: useTemplateEditorUpdater.ACTION_TYPES.ADD_TEMPLATE_CROP,
        payload: {
          templateCrop,
        },
      })
    },
    [dispatch]
  )

  const setPrimaryCrop = useCallback(
    (id: string) => {
      dispatch({
        type: useTemplateEditorUpdater.ACTION_TYPES.SET_PRIMARY_CROP,
        payload: {
          templateCropId: id,
        },
      })
    },
    [dispatch]
  )

  const setTemplateTitle = useCallback(
    (title: string) => {
      dispatch({
        type: useTemplateEditorUpdater.ACTION_TYPES.SET_TITLE,
        payload: {
          title,
        },
      })
    },
    [dispatch]
  )

  const removeTemplateCrop = useCallback(
    (templateCropId: string) => {
      dispatch({
        type: useTemplateEditorUpdater.ACTION_TYPES.REMOVE_CROP,
        payload: {
          templateCropId,
        },
      })
    },
    [dispatch]
  )

  const setPreviewContent = useCallback(
    (title: string, videoId: string | undefined) => {
      dispatch({
        type: useTemplateEditorUpdater.ACTION_TYPES.SET_PREVIEW_CONTENT,
        payload: {
          title,
          videoId,
        },
      })
    },
    [dispatch]
  )

  return useMemo(
    () => ({
      initialize,
      setPreviewContent,
      setTemplateTitle,
      selectTemplateCrop,
      setCropRect,
      addNewTemplateCrop,
      setPrimaryCrop,
      removeTemplateCrop,
      close,
    }),
    [
      addNewTemplateCrop,
      close,
      initialize,
      removeTemplateCrop,
      selectTemplateCrop,
      setCropRect,
      setPreviewContent,
      setPrimaryCrop,
      setTemplateTitle,
    ]
  )
}
