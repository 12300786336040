import { Switch, Route, useRouteMatch } from 'react-router'

import { EditorProvider } from 'core/editor/context'
import { TimelineProvider } from 'core/editor/timeline/context'
import { VideoValorantMomentPlayer } from './channel/video-moments'
import { ValorantMatchEditorPage } from './moment-player/page'
import { ValorantMatchEditorProvider } from 'core/valorant-match/editor/context'

import { ValorantMatchesByChannelPage } from './channel'
import { SearchValorantChannelsPage } from './search'
import { ValorantMomentPlayer } from './moment-player'

export const ValorantPage = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${path}`} exact>
        <SearchValorantChannelsPage />
      </Route>
      <Route path={`${path}/match/:matchId`}>
        <EditorProvider>
          <TimelineProvider>
            <ValorantMatchEditorProvider>
              <ValorantMatchEditorPage />
            </ValorantMatchEditorProvider>
          </TimelineProvider>
        </EditorProvider>
      </Route>
      <Route path={`${path}/channel/:channelId`}>
        <ValorantMatchesByChannelPage />
      </Route>
      <Route path={`${path}/video/:videoId`}>
        <EditorProvider>
          <TimelineProvider>
            <VideoValorantMomentPlayer />
          </TimelineProvider>
        </EditorProvider>
      </Route>
      <Route path={`${path}/moments`}>
        <EditorProvider>
          <TimelineProvider>
            <ValorantMomentPlayer />
          </TimelineProvider>
        </EditorProvider>
      </Route>
    </Switch>
  )
}
