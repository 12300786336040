import { useEffect } from 'react'
import momentjs from 'moment'

import { useTimelineHelpers } from 'core/editor/timeline/hooks'
import { useGetEditingVideoQuery } from '__generated__'
import {
  TimelineAreaContentsDiv,
  TimelineDetailAreaDiv,
  TimelineHeadingAreaDiv,
  TimelineOptionsAreaDiv,
} from 'core/shared/timeline/styled'
import { TimelinePlayerControls } from 'core/shared/timeline/controls'

import { TimelineDetail } from './timeline-detail'
import { useSignalPlayerState } from '../context'
import { SignalPlayerVideoTimelineHeading } from './video-heading'
import { SignalPlayerChatTimeline } from './chat-timeline'
import { SignalPlayerTimelineContentDiv } from '../styled'

export const SignalPlayerTimeline = () => {
  const { primaryVideoId, visibleRelatedVideoIds } = useSignalPlayerState()
  const { data } = useGetEditingVideoQuery({
    variables: { id: primaryVideoId },
  })
  const { setTimelineStartAndEnd } = useTimelineHelpers()

  useEffect(() => {
    // Sets start and end of timeline
    setTimelineStartAndEnd(
      momentjs(data?.video?.stream?.startedAt).valueOf(),
      momentjs(data?.video?.stream?.endedAt).valueOf()
    )
  }, [
    data?.video?.stream?.endedAt,
    data?.video?.stream?.startedAt,
    setTimelineStartAndEnd,
  ])

  return (
    <TimelineAreaContentsDiv>
      <TimelineOptionsAreaDiv>
        <TimelinePlayerControls></TimelinePlayerControls>
      </TimelineOptionsAreaDiv>
      <TimelineHeadingAreaDiv>
        <SignalPlayerVideoTimelineHeading videoId={primaryVideoId} />
        {visibleRelatedVideoIds.map((videoId) => (
          <SignalPlayerVideoTimelineHeading key={videoId} videoId={videoId} />
        ))}
      </TimelineHeadingAreaDiv>
      <TimelineDetailAreaDiv>
        <TimelineDetail>
          <SignalPlayerTimelineContentDiv>
            <SignalPlayerChatTimeline videoId={primaryVideoId} />
            {visibleRelatedVideoIds.map((videoId) => (
              <SignalPlayerChatTimeline key={videoId} videoId={videoId} />
            ))}
          </SignalPlayerTimelineContentDiv>
        </TimelineDetail>
      </TimelineDetailAreaDiv>
    </TimelineAreaContentsDiv>
  )
}
