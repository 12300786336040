import { useCallback, useEffect, useState } from 'react'

import { Text } from 'components/core/text'
import {
  Region,
  useGetRegionQuery,
  useUpdateRegionMutation,
} from '__generated__'

import { RegionListItem } from './styled'

export const RegionItem = () => {
  const { data: regionData } = useGetRegionQuery()
  const [region, setRegion] = useState<Region>()
  const [updateRegion] = useUpdateRegionMutation()

  useEffect(() => {
    if (regionData?.viewer?.settings?.region) {
      setRegion(regionData.viewer.settings.region.value)
    }
  }, [regionData?.viewer?.settings?.region])

  const toggleRegion = useCallback(async () => {
    if (!regionData?.viewer?.settings?.region) return
    const results = await updateRegion({
      variables: {
        region: region === Region.Eu ? Region.Us : Region.Eu,
      },
    })
    setRegion(results.data?.updateUserSettings?.region.value)
  }, [region, regionData?.viewer?.settings?.region, updateRegion])

  return (
    <RegionListItem onClick={toggleRegion}>
      <Text variant={'text-2'}>Region</Text>
      <Text variant={'text-2'} weight="semibold">
        {region}
      </Text>
    </RegionListItem>
  )
}
