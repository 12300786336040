import { useCallback, useMemo } from 'react'
import {
  MultiPerspectiveMomentDetailFragment,
  useGetMomentDetailQuery,
  useSetMultiPerspectiveMomentVideoOffsetMutation,
} from '__generated__'
import { useVideoSyncUpdater } from './context'

export const useVideoSyncHelpers = () => {
  const syncDispatch = useVideoSyncUpdater()

  const setMomentId = useCallback(
    (momentId: string | undefined) => {
      syncDispatch({
        type: useVideoSyncUpdater.ACTION_TYPES.SET_MOMENT_ID,
        payload: {
          momentId,
        },
      })
    },
    [syncDispatch]
  )

  const setPrimaryVideoId = useCallback(
    (primaryVideoId: string | undefined) => {
      syncDispatch({
        type: useVideoSyncUpdater.ACTION_TYPES.SET_PRIMARY_VIDEO_ID,
        payload: {
          primaryVideoId,
        },
      })
    },
    [syncDispatch]
  )
  const setSecondaryVideoId = useCallback(
    (secondaryVideoId: string | undefined) => {
      syncDispatch({
        type: useVideoSyncUpdater.ACTION_TYPES.SET_SECONDARY_VIDEO_ID,
        payload: {
          secondaryVideoId,
        },
      })
    },
    [syncDispatch]
  )

  type VideoSyncParams = {
    momentId: string
    primaryVideoId?: string
    secondaryVideoId?: string
  }
  const syncVideos: (params: VideoSyncParams) => void = useCallback(
    function ({ momentId, primaryVideoId, secondaryVideoId }) {
      setMomentId(momentId)

      if (primaryVideoId) {
        setPrimaryVideoId(primaryVideoId)
      }

      if (secondaryVideoId) {
        setSecondaryVideoId(secondaryVideoId)
      }
    },
    [setMomentId, setPrimaryVideoId, setSecondaryVideoId]
  )

  const closeVideoSync = useCallback(() => {
    setMomentId(undefined)
    setPrimaryVideoId(undefined)
    setSecondaryVideoId(undefined)
  }, [setMomentId, setPrimaryVideoId, setSecondaryVideoId])

  return useMemo(
    () => ({
      setMomentId,
      setPrimaryVideoId,
      setSecondaryVideoId,
      syncVideos,
      closeVideoSync,
    }),
    [
      closeVideoSync,
      setMomentId,
      setPrimaryVideoId,
      setSecondaryVideoId,
      syncVideos,
    ]
  )
}

export const useOffsetEditing = (
  momentId: string,
  primaryVideoId?: string,
  secondaryVideoId?: string
) => {
  const { data, refetch } = useGetMomentDetailQuery({ variables: { momentId } })
  const { closeVideoSync } = useVideoSyncHelpers()
  const [mutation] = useSetMultiPerspectiveMomentVideoOffsetMutation()

  const moment = useMemo(() => {
    return data?.moment as MultiPerspectiveMomentDetailFragment | undefined
  }, [data?.moment])

  const offsetMap = useMemo(() => {
    return (moment?.offsets ?? []).reduce((memo, offsetItem) => {
      const fromMap = memo[offsetItem.fromVideo.id]
      let nextFromMap = fromMap ? fromMap : {}
      nextFromMap[offsetItem.toVideo.id] = offsetItem.offsets
      return {
        ...memo,
        [offsetItem.fromVideo.id]: { ...nextFromMap },
      }
    }, {})
  }, [moment?.offsets])

  const videoIds = useMemo(() => {
    return moment?.videos.map(({ id }) => id) ?? []
  }, [moment?.videos])

  const currentOffsets: { audioOffset: number; videoOffset: number } =
    useMemo(() => {
      const defaultOffsets = {
        audioOffset: 0,
        videoOffset: 0,
      }
      if (!primaryVideoId || !secondaryVideoId) return defaultOffsets
      return offsetMap[primaryVideoId]?.[secondaryVideoId] ?? defaultOffsets
    }, [offsetMap, primaryVideoId, secondaryVideoId])

  const saveOffsets = useCallback(
    async (nextAudioOffset: number) => {
      if (!primaryVideoId || !secondaryVideoId) return
      await mutation({
        variables: {
          fromVideoId: primaryVideoId,
          toVideoId: secondaryVideoId,
          momentId,
          offsetsInput: {
            audioOffset: nextAudioOffset,
            videoOffset: 0,
          },
        },
      })
      refetch()
      closeVideoSync()
    },
    [
      closeVideoSync,
      momentId,
      mutation,
      primaryVideoId,
      refetch,
      secondaryVideoId,
    ]
  )

  return useMemo(
    () => ({
      offsetMap,
      videoIds,
      saveOffsets,
      currentOffsets,
    }),
    [currentOffsets, offsetMap, saveOffsets, videoIds]
  )
}
