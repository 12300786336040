import React, { useEffect } from 'react'
import momentjs from 'moment'

import { useTimelineState } from 'core/editor/timeline/context'
import { useTimelineHelpers } from 'core/editor/timeline/hooks'
import { VideoSyncModalContainerDiv } from 'core/sync/styled'
import { RelatedVideoSyncEditor } from './editor'

interface VideoSyncControllerProps {
  primaryVideoId: string
  secondaryVideoId: string
  start: string
  end: string
  save: (nextOffset: number) => void
  initialOffset: number
}

export const VideoSyncController: React.FC<VideoSyncControllerProps> = ({
  children,
  primaryVideoId,
  secondaryVideoId,
  start,
  end,
  save,
  initialOffset,
}) => {
  useSyncEditor(start, end)
  // const { closeVideoSync } = useVideoSyncHelpers()

  return (
    <VideoSyncModalContainerDiv>
      <RelatedVideoSyncEditor
        primaryVideoId={primaryVideoId}
        secondaryVideoId={secondaryVideoId}
        initialOffset={initialOffset}
        save={save}
      />
      {children}
    </VideoSyncModalContainerDiv>
  )
}

const useSyncEditor = (start: string, end: string) => {
  const { setTimelineStartAndEnd, setTimelineLength } = useTimelineHelpers()
  const { timelineLength } = useTimelineState()

  const startTime = momentjs(start).valueOf()
  const endTime = momentjs(end).valueOf()

  // initialize size of timeline
  useEffect(() => {
    if (timelineLength !== endTime - startTime) {
      setTimelineStartAndEnd(startTime, endTime)
      setTimelineLength(endTime - startTime)
    }
  }, [
    startTime,
    endTime,
    setTimelineLength,
    timelineLength,
    setTimelineStartAndEnd,
  ])
}
