import { Select } from 'components/core/select'
import { Text } from 'components/core/text'
import { AspectRatioCustomDiv } from 'core/template-editor/styled'
import { useCallback, useState } from 'react'
import {
  ASPECT_RATIO_PRESETS,
  ASPECT_RATIO_TYPES,
  TitledAspectRatio,
} from './constants'

interface AspectRatioDropdownProps {
  aspectRatio?: TitledAspectRatio
  setAspectRatio: (aspectRatio: TitledAspectRatio) => void
}

export const AspectRatioDropdown: React.FC<AspectRatioDropdownProps> = ({
  aspectRatio,
  setAspectRatio,
}) => {
  const [customWidth, setCustomWidth] = useState<number>(1)
  const [customHeight, setCustomHeight] = useState<number>(1)

  const handleSelectChange = useCallback(
    (evt: React.ChangeEvent<HTMLSelectElement>) => {
      const title = evt.target.value

      const nextAspectRatio = [
        ASPECT_RATIO_TYPES.OFF,
        ...ASPECT_RATIO_PRESETS,
        ASPECT_RATIO_TYPES.CUSTOM,
      ].find((item) => item.title === title)
      setAspectRatio(nextAspectRatio ?? ASPECT_RATIO_TYPES.OFF)
    },
    [setAspectRatio]
  )

  const handleWidthChange = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const width = parseInt(evt.target.value, 10)
      setCustomWidth(width)
      setAspectRatio({
        title: 'custom',
        width,
        height: customHeight,
      })
    },
    [customHeight, setAspectRatio]
  )

  const handleHeightChange = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const height = parseInt(evt.target.value, 10)
      setCustomHeight(height)
      setAspectRatio({
        title: 'custom',
        height: parseInt(evt.target.value, 10),
        width: customWidth,
      })
    },
    [customWidth, setAspectRatio]
  )

  return (
    <div>
      <Select value={aspectRatio?.title} onChange={handleSelectChange}>
        <option value="off">No AR</option>
        {ASPECT_RATIO_PRESETS.map((ar) => (
          <option value={ar.title} key={ar.title}>
            {ar.width}x{ar.height}
          </option>
        ))}
        <option value="custom">Custom</option>
      </Select>
      {aspectRatio?.title === ASPECT_RATIO_TYPES.CUSTOM.title ? (
        <>
          <AspectRatioCustomDiv>
            <Text variant="text-2">W:</Text>
            <input type="text" onChange={handleWidthChange} />
          </AspectRatioCustomDiv>
          <AspectRatioCustomDiv>
            <Text variant="text-2">H:</Text>
            <input type="text" onChange={handleHeightChange} />
          </AspectRatioCustomDiv>
        </>
      ) : null}
    </div>
  )
}
