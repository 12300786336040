import { useMemo } from 'react'
import { Link } from 'react-router-dom'

import {
  Container,
  Content,
  HStack,
  Spacing,
  VStack,
} from 'components/core/layout'
import { Text } from 'components/core/text'
import { useGetMatchesQuery } from '__generated__'

import { Sidebar } from '../sidebar'
import { useCurrentChannel } from '../hooks'
import { ChannelNavigation } from '../navigation'
import { ChannelContent, MainContent } from '../styled'
import { MatchCard } from './match-card'
import { Spinner } from 'components/core/spinner'
import { Button } from 'components/core/button'
import { useQueryParamFilters } from 'utils/browser'

export const ChannelMatches = () => {
  return (
    <Content background={'secondary'}>
      <HStack gap={16}>
        <Sidebar />
        <MainContent>
          <ChannelContent>
            <ChannelNavigation />
            <Spacing top={16}>
              <MatchFeed />
            </Spacing>
          </ChannelContent>
        </MainContent>
      </HStack>
    </Content>
  )
}

const MatchFeed = () => {
  const { data: channelData, urls } = useCurrentChannel()
  const { params, setParam } = useQueryParamFilters()

  const currentPage = useMemo(() => {
    const pageStr = params['page']
    return pageStr && typeof pageStr === 'string' ? parseInt(pageStr, 10) : 0
  }, [params])

  const accountIds = useMemo(() => {
    return channelData?.channel?.linkedAccounts.map(({ id }) => id)
  }, [channelData?.channel?.linkedAccounts])

  const { data: matchesData, loading } = useGetMatchesQuery({
    variables: {
      accountIds: accountIds ?? [],
      first: 14,
      after: currentPage === 0 ? undefined : (currentPage * 14 - 1).toString(),
    },
  })

  return (
    <>
      <HStack alignItems="center" gap={8}>
        <Text variant="h4">Matches</Text>
      </HStack>
      <Spacing top={16}>
        {loading || !matchesData ? (
          <Spacing top={32}>
            <HStack justifyContent="center" fullWidth>
              <Spinner size={48} />
            </HStack>
          </Spacing>
        ) : (
          <>
            <VStack gap={12}>
              {matchesData?.valorantMatches?.data.map(({ id }) => (
                <Link key={id} to={`${urls.matches}/${id}`}>
                  <MatchCard matchId={id} accountIds={accountIds ?? []} />
                </Link>
              ))}
            </VStack>
            <Spacing top={32}>
              <Container direction="row" justifyContent="center" gap={8}>
                <Button
                  onClick={() => {
                    const nextPage = currentPage - 1
                    setParam(
                      'page',
                      nextPage === 0 ? undefined : nextPage.toString()
                    )
                  }}
                  variant={
                    currentPage === 0 || loading ? 'secondary' : 'primary'
                  }
                  disabled={currentPage === 0 || loading}
                >
                  Prev Page
                </Button>
                <Button
                  onClick={() => setParam('page', (currentPage + 1).toString())}
                  variant={
                    !matchesData?.valorantMatches?.pagination.hasNextPage ||
                    loading
                      ? 'secondary'
                      : 'primary'
                  }
                  disabled={
                    !matchesData?.valorantMatches?.pagination.hasNextPage ||
                    loading
                  }
                >
                  Next Page
                </Button>
              </Container>
            </Spacing>
          </>
        )}
      </Spacing>
    </>
  )
}
