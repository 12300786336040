import { Button } from 'components/core/button'
import { Text } from 'components/core/text'
import { useVideoSyncHelpers } from 'core/sync/hooks'
import { groupBy, uniqBy } from 'lodash'
import React, { useCallback, useMemo } from 'react'

import {
  MomentEditFragment,
  useGetMomentDetailQuery,
  useSetMomentPublishedMutation,
} from '__generated__'
import { MomentHomePageDiv } from '../styled'
import { CreateEdit } from './create-edit'
import { MomentEditPreview } from './edit-preview'

interface MomentHomeProps {
  momentId: string
}

export const MomentHome: React.FC<MomentHomeProps> = ({ momentId }) => {
  const { moment, togglePublish, editsByFormat } = useMomentHomeData(momentId)
  const { syncVideos } = useVideoSyncHelpers()

  const syncModal = useCallback(() => {
    syncVideos({ momentId })
  }, [momentId, syncVideos])

  return moment ? (
    <MomentHomePageDiv>
      <Text variant="h2">{moment.title}</Text>
      <div>
        <Button size="small" onClick={togglePublish}>
          {moment.published ? 'Unpublish' : 'Publish'}
        </Button>
      </div>
      <br />
      <div>
        <Button size="small" onClick={syncModal}>
          Edit Video Sync
        </Button>
      </div>
      <br />
      <br />
      <br />
      <Text variant="h3">Edits:</Text>
      {editsByFormat.map(({ format, edits: formatEdits }) => {
        return (
          <div key={format.id}>
            <Text variant="h4">{format.title}</Text>
            {formatEdits.map((edit) => (
              <MomentEditPreview
                key={edit.id}
                momentId={momentId}
                momentEdit={edit}
              />
            ))}
          </div>
        )
      })}

      <CreateEdit momentId={momentId} />
    </MomentHomePageDiv>
  ) : (
    <>Loading...</>
  )
}

const useMomentHomeData = (momentId: string) => {
  const { data } = useGetMomentDetailQuery({ variables: { momentId } })
  const [publishMutation] = useSetMomentPublishedMutation()

  const togglePublish = useCallback(() => {
    if (data?.moment?.__typename === 'MultiPerspectiveMoment' && data.moment)
      publishMutation({
        variables: { momentId, nextPublishStatus: !data.moment.published },
      })
  }, [data?.moment, momentId, publishMutation])

  const multiMoment = useMemo(() => {
    if (data?.moment?.__typename === 'MultiPerspectiveMoment') {
      return data.moment
    }
  }, [data?.moment])

  const edits: MomentEditFragment[] | undefined = useMemo(() => {
    if (data?.moment?.__typename === 'MultiPerspectiveMoment') {
      return data.moment.edits
    }
  }, [data?.moment])

  const editsByFormat = useMemo(() => {
    const uniqueFormats = uniqBy(
      (edits ?? []).map(({ format }) => format),
      ({ id }) => id
    )
    const editsByFormatId = groupBy(edits, ({ format }) => format.id)
    return uniqueFormats.map((format) => {
      return {
        format,
        edits: editsByFormatId[format.id],
      }
    })
  }, [edits])

  return useMemo(
    () => ({
      moment: multiMoment,
      editsByFormat,
      edits,
      togglePublish,
    }),
    [edits, editsByFormat, multiMoment, togglePublish]
  )
}
