import React, { useMemo } from 'react'

import { useDimensions } from 'utils/browser'
import { useTimelineState } from './context'

import {
  useGetTimeToContentsWidth,
  useThumbDrag,
  useTimelineHelpers,
} from './hooks'
import { ScrollableThumbContainerDiv, ScrollableThumbDiv } from './styled'

export const TimelineScrollbar: React.FC = () => {
  const { containerRef, thumbRef, thumbStyles, dragging, disabled } =
    useTimelineScrollbarData()

  return disabled ? null : (
    <ScrollableThumbContainerDiv ref={containerRef}>
      <ScrollableThumbDiv
        style={thumbStyles}
        ref={thumbRef}
        $dragging={dragging}
      />
    </ScrollableThumbContainerDiv>
  )
}

const useTimelineScrollbarData = () => {
  const { setOffsetPct } = useTimelineHelpers()
  const { offsetPct, startTime, endTime, timelineLength } = useTimelineState()

  const [containerRef, parentSize] = useDimensions()
  const { ref: thumbRef, dragging } = useThumbDrag(
    parentSize?.width ?? 1,
    offsetPct,
    setOffsetPct,
    { start: startTime, end: endTime },
    timelineLength
  )

  const getTimeToWidth = useGetTimeToContentsWidth(
    parentSize?.width ?? 1,
    endTime - startTime
  )

  const thumbStyles = useMemo(() => {
    // this is because if it hasn't been set they will be the same current time
    // since momentjs(undefined).toValue() === momentjs(undefined).toValue()
    // ghetto, but works
    if (endTime === startTime) return {}
    const left = getTimeToWidth(
      offsetPct * (endTime - startTime - timelineLength)
    )
    const width = getTimeToWidth(timelineLength)
    return {
      width,
      transform: `translateX(${left}px)`,
    }
  }, [endTime, getTimeToWidth, offsetPct, startTime, timelineLength])

  const disabled = useMemo(() => {
    return endTime - startTime === timelineLength
  }, [endTime, startTime, timelineLength])

  return useMemo(
    () => ({
      thumbStyles,
      thumbRef,
      containerRef,
      dragging,
      disabled,
    }),
    [thumbStyles, thumbRef, containerRef, dragging, disabled]
  )
}
