import { createContext, useMemo } from 'react'
import { useGetMomentThumbnailQuery } from '__generated__'

type Props = {
  id: string
}

type MomentThumbnailState = {
  thumbnail: string | undefined
}

const MomentThumbnailContext = createContext<MomentThumbnailState>({
  thumbnail: undefined,
})

export const MomentThumbnailProvider: React.FC<Props> = ({ id, children }) => {
  const { data } = useGetMomentThumbnailQuery({ variables: { id } })
  const thumbnail = useMemo(
    () => data?.moment?.thumbnail?.toString(),
    [data?.moment?.thumbnail]
  )
  return (
    <MomentThumbnailContext.Provider value={{ thumbnail }}>
      {children}
    </MomentThumbnailContext.Provider>
  )
}
export const MomentThumbnailConsumer = MomentThumbnailContext.Consumer
