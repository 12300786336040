import { useCallback, useEffect, useMemo } from 'react'
import {
  useGetValorantMatchPreviewQuery,
  useGetValorantMatchScoreboardLazyQuery,
  ValorantAgent,
  ValorantMatchVideoSyncStatus,
} from '__generated__'

export type PlayerStats = {
  assists: number
  deaths: number
  kills: number
  'playtime-millis': number
  'rounds-played': number
  score: number
}

export type ScoreboardEntry = {
  name: string
  agent: ValorantAgent
  stats: PlayerStats
  hasAccount: boolean
  hasPerspective: boolean
  videoId?: string
  syncStatus: SyncStatus
  accountId: string
  tagLine?: string
  channelId?: string
}

export enum SyncStatus {
  NO_VIDEO = 'NO_VIDEO',
  ERROR = 'ERROR',
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
}

export const useScoreboard = (matchId: string) => {
  // this should be cached if using feed
  const { data: previewData, loading: previewLoading } =
    useGetValorantMatchPreviewQuery({
      variables: { matchId },
      fetchPolicy: 'cache-first',
    })

  const [queryMatchWithStreams, { data, loading, error }] =
    useGetValorantMatchScoreboardLazyQuery({ fetchPolicy: 'cache-first' })

  useEffect(() => {
    if (previewData?.valorantMatch) {
      queryMatchWithStreams({
        variables: {
          matchId: previewData.valorantMatch.id,
          matchStart: previewData.valorantMatch.startedAt,
          matchEnd: previewData.valorantMatch.endedAt,
        },
      })
    }
  }, [previewData?.valorantMatch, queryMatchWithStreams])

  const matchSyncMap = useMemo(() => {
    if (!previewData?.valorantMatch) return {}
    return previewData.valorantMatch.videoSyncData.reduce((acc, item) => {
      const { status, error, matchStartTime } = item
      return {
        ...acc,
        [item.video.id]: { status, error, matchStartTime },
      }
    }, {}) as { [key: string]: any }
  }, [previewData])

  const getSyncStatus = useCallback(
    (videoId: string | undefined) => {
      if (!videoId) return SyncStatus.NO_VIDEO
      const syncData = matchSyncMap[videoId]
      if (!syncData) return SyncStatus.NO_VIDEO
      switch (syncData.status) {
        case ValorantMatchVideoSyncStatus.Failed:
          return SyncStatus.ERROR
        case ValorantMatchVideoSyncStatus.Inprogress:
          return SyncStatus.IN_PROGRESS
        case ValorantMatchVideoSyncStatus.Completed:
          return SyncStatus.SUCCESS
        default:
          return SyncStatus.ERROR
      }
    },
    [matchSyncMap]
  )

  const teams = useMemo(
    () =>
      data?.valorantMatch?.players.reduce<{
        [key: string]: ScoreboardEntry[]
      }>((acc, player) => {
        const { team, gameName, agent, stats, account, tagLine } = player

        const key = team ?? ''
        const hasPerspective =
          account.channel?.streams.data[0]?.video?.id !== undefined
        const videoId = account.channel?.streams.data[0]?.video?.id
        const syncStatus = getSyncStatus(videoId)

        return {
          ...acc,
          [key]: [
            ...(acc[key] || []),
            {
              name: gameName ?? '',
              agent: agent ?? ValorantAgent.Unknown,
              stats: stats,
              hasAccount: account.channel !== null,
              hasPerspective,
              accountId: account.id,
              videoId,
              syncStatus,
              channelId: account.channel?.id,
              tagLine: tagLine ?? undefined,
            },
          ],
        }
      }, {}),
    [data?.valorantMatch?.players, getSyncStatus]
  )

  return {
    startedAt: previewData?.valorantMatch?.startedAt,
    map: previewData?.valorantMatch?.map,
    teams,
    winningTeam: previewData?.valorantMatch?.winningTeam,
    loading: loading || previewLoading,
    error,
  }
}
