import React from 'react'
import { CurrentTimeIndicator } from './current-time'

import { useTimelineDetailData } from './hooks'
import { TimelineSelectionArea } from './selection-area'
import { SelectionDisplay } from './selection-display'
import { SelectionPlayer } from './selection-player'
import { TimelineDetailContainerDiv, TimelineDetailContentsDiv } from './styled'
import { TimelineTimeDisplay } from './time-display'

export const TimelineDetail: React.FC = ({ children }) => {
  const { containerRef, containerStyles, containerRect, seekToClick } =
    useTimelineDetailData()

  return (
    <TimelineDetailContainerDiv ref={containerRef}>
      <TimelineDetailContentsDiv style={containerStyles}>
        <SelectionPlayer />
        <TimelineSelectionArea parentRect={containerRect} />
        <div onClick={seekToClick}>{children}</div>
        <SelectionDisplay />
        <CurrentTimeIndicator />
        <TimelineTimeDisplay />
        {/* {videoIds.map((videoId) => (
            <VideoTimelineDetail
              key={videoId}
              videoId={videoId}
              contentsWidth={parentSize?.width ?? 1}
              timelineInfo={timelineInfo}
              timelineLength={timelineLength}
            />
          ))} 
         <TimelineActionLayerDiv ref={contentsRef} />
        <TimelineSelection
          selection={localSelection}
          setSelection={updateLocalSelection}
          contentWidth={contentWidth}
          onUpdateDone={setGlobalSelection}
        />
        {overlaps?.map(({ from, to }) => (
          <GuestOverlapDisplay
            key={`${from}_${to}`}
            from={from}
            to={to}
            streamStart={timelineInfo.start}
            streamEnd={timelineInfo.end}
          />
        ))} */}
      </TimelineDetailContentsDiv>
    </TimelineDetailContainerDiv>
  )
}
