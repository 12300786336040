import React, { ReactNode } from 'react'

import { ReactComponent as PlayButton } from 'icons/svgs/solid/play.svg'
import { ReactComponent as PauseButton } from 'icons/svgs/solid/pause.svg'
import { ReactComponent as StepBackwardButton } from 'icons/svgs/solid/step-backward.svg'
import { ReactComponent as RepeatButton } from 'icons/svgs/solid/repeat.svg'
import { useEditor } from 'core/editor/hooks'
import { PlayerButtonDiv, PlayerControlsContainerDiv } from './styled'

interface Props {
  children?: ReactNode
}

export const TimelinePlayerControls = ({ children }: Props) => {
  const { play, pause, playing, ready, restartSelection } = useEditor()

  return (
    <PlayerControlsContainerDiv>
      {playing ? (
        <PlayerButtonDiv onClick={pause}>
          <PauseButton />
        </PlayerButtonDiv>
      ) : (
        <PlayerButtonDiv onClick={play} $disabled={ready === false}>
          <PlayButton />
        </PlayerButtonDiv>
      )}
      <PlayerButtonDiv onClick={restartSelection}>
        <StepBackwardButton />
      </PlayerButtonDiv>
      <PlayerButtonDiv onClick={() => {}}>
        <RepeatButton />
      </PlayerButtonDiv>
      {children}
    </PlayerControlsContainerDiv>
  )
}
