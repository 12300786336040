import styled from 'styled-components/macro'

export const EditorPlayerContainerDiv = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: black;
`

export const MainVideoWrapperDiv = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  overflow: hidden;
`

export const MainPlayerDiv = styled.div`
  position: relative;
  padding: 16px;
`

export const SecondaryPlayerContainerDiv = styled.div`
  position: relative;
  overflow: hidden;
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  & > div {
    position: relative;
  }
`

export const IgnoreActionDiv = styled.div`
  position: absolute;
  right: 4px;
  top: 4px;
  width: 12px;
  height: 12px;
  align-items: center;
  justify-content: center;

  border: 0;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.static.neutrals.n2};
  z-index: 1;

  transition: background-color 0.15s ease-in-out, opacity 0.15s ease-in-out;

  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.static.neutrals.n3};
  }

  &:active {
    opacity: 0.75;
  }

  svg {
    width: 50%;
    height: 50%;
  }

  display: none;
`

export const SecondaryPlayerDiv = styled.div<{ $selected: boolean }>`
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 8px 0;
  border-radius: 8px;
  overflow: hidden;

  border: 1px solid
    ${({ $selected, theme }) =>
      $selected ? theme.colors.gold : theme.colors.static.neutrals.n1};

  cursor: pointer;

  &:hover {
    & > ${IgnoreActionDiv} {
      display: flex;
    }
  }
`

export const VideoGradientDiv = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 50%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  opacity: 0.75;
`

export const PreviewVideoLabelDiv = styled.div`
  position: absolute;
  bottom: 8px;
  left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > img {
    width: 16px;
    height: 16px;
    margin-right: 4px;
    border-radius: 50%;
  }

  & > span {
    font-size: 12px;
  }
`
