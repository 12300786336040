import React from 'react'

import { useEditorController } from './hooks'

export const Editor: React.FC = ({ children }) => {
  return (
    <>
      <EditorController />
      {children}
    </>
  )
}

export const EditorController: React.FC = () => {
  useEditorController()
  return <div />
}
