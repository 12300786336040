import { ReactNode, useCallback, useMemo } from 'react'
import styled from 'styled-components/macro'

import { colors } from 'utils/colors'
import { Text } from 'components/core/text'
import { Portal } from 'components/dialog/portal'
import { Backdrop } from 'core/feed/components/preview-modal/styled'
import { HStack, VStack } from 'components/core/layout'
import {
  ExportFormat,
  STANDARD_FORMAT,
  TIKTOK_FORMAT,
} from 'core/exporter/constants'
import { QuickExports } from './quick-exports'
import { CloseModalButton } from 'components/close-modal/close-button'
import { Button } from 'components/core/button'
import { useCreateEdit } from 'page/moment/exporter/scenes/hooks'
import { AutoCut } from './auto-cut'
import { RootOnly } from 'core/auth/components'

interface Props {
  momentId: string
  videoId: string
  start: string
  end: string
  close?: () => void
}

export const CreateVideoModal = ({
  close,
  momentId,
  videoId,
  start,
  end,
}: Props) => {
  return (
    <Portal>
      <ModalDiv>
        <Backdrop $opacity={0.75} onClick={close} />
        <CreateVideoContent
          momentId={momentId}
          videoId={videoId}
          start={start}
          end={end}
          close={close}
        >
          <CloseModalButton onClick={close ? close : () => {}} />
        </CreateVideoContent>
      </ModalDiv>
    </Portal>
  )
}

interface CreateVideoProps {
  momentId: string
  videoId: string
  start: string
  end: string

  close?: () => void
  children?: ReactNode
}

export const CreateVideoContent = ({
  close,
  children,
  momentId,
  videoId,
  start,
  end,
}: CreateVideoProps) => {
  const createEdit = useCreateEdit(momentId ?? '')

  const createEditWithFormat = useCallback(
    async (formatId: string) => {
      await createEdit(formatId, videoId, start, end)
    },
    [createEdit, end, start, videoId]
  )
  return (
    <ModalContainerDiv>
      <HStack gap={48} alignItems="stretch" justifyContent="stretch">
        <VStack alignItems="center">
          <Text variant="h4">Create Video Edit</Text>
          <FormatOptions createEditWithFormat={createEditWithFormat} />
        </VStack>
        <DividerDiv />
        <VStack alignItems="center">
          <Text variant="h4">Full Export</Text>
          <QuickExports onCreate={close} />
        </VStack>
      </HStack>
      <RootOnly>
        <AutoCut
          momentId={momentId}
          videoId={videoId}
          startsAt={start}
          onCreate={close}
        ></AutoCut>
      </RootOnly>
      {children}
    </ModalContainerDiv>
  )
}

interface FormatOptionsProps {
  createEditWithFormat: (formatId: string) => void
}

const FormatOptions = ({ createEditWithFormat }: FormatOptionsProps) => {
  return (
    <HStack>
      {[STANDARD_FORMAT, TIKTOK_FORMAT].map((format) => {
        return (
          <FormatOption
            key={format.id}
            format={format}
            onClick={() => createEditWithFormat(format.id)}
          />
        )
      })}
    </HStack>
  )
}

interface FormatOptionProp {
  format: ExportFormat
  onClick: () => void
}

const FormatOption = ({ format, onClick }: FormatOptionProp) => {
  const containerSize = useMemo(() => {
    const HEIGHT = 200
    return {
      height: HEIGHT,
      width: (format.aspectRatio.width / format.aspectRatio.height) * HEIGHT,
    }
  }, [format])

  return (
    <TemplateOptionContainerDiv onClick={onClick}>
      <ContentPreviewDiv style={containerSize}>
        <Button variant="primary" size="small">
          Create
        </Button>
      </ContentPreviewDiv>
      <div>{format.title}</div>
    </TemplateOptionContainerDiv>
  )
}

const ModalDiv = styled.div`
  position: fixed;
  inset: 0px;
  z-index: 1000;
`

const ModalContainerDiv = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  background-color: #353945;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  padding: 32px;
  padding-top: 48px;
`

const ContentPreviewDiv = styled.div`
  height: 200px;
  background-color: ${colors.neutral.light};
  border: 1px solid ${colors.neutral.base};
  border-radius: 8px;
  margin: 12px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const TemplateOptionContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  padding: 12px;
  cursor: pointer;

  & button {
    transition: opacity 200ms ease-in-out;
    opacity: 0;
    pointer-events: none;
  }

  &:hover {
    & button {
      opacity: 0.75;
    }
    & > ${ContentPreviewDiv} {
      border-color: ${colors.blue.base};
      background-color: ${colors.blue.light};
    }
  }
`

const DividerDiv = styled.div`
  height: 300px;
  width: 1px;
  border-left: 1px dashed ${({ theme }) => theme.colors.neutrals.n5};
`
