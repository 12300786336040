import { Text } from 'components/core/text'
import React from 'react'
import styled from 'styled-components/macro'
import { fonts, fontWeights } from 'theme/typography'
import { Portal } from './portal'

interface DialogCompound {
  Title: typeof Title
  Footer: typeof Footer
  Body: typeof Body
  Text: typeof DialogText
}

const DialogContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  height: 100%;

  z-index: 1;
`

const DialogCard = styled.div<{ border?: boolean }>`
  max-width: 380px;
  width: 100%;

  background-color: ${({ theme }) => theme.colors.background.primary};
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.elevation.depth3};
  border: ${({ theme, border = false }) =>
    border && `1px solid ${theme.colors.neutrals.n6}`};

  overflow: hidden;
`

const Title = styled.div<{ textAlign?: 'left' | 'right' | 'center' }>`
  padding: 16px 16px 0;

  ${fonts.text['text-1']}
  font-weight: ${fontWeights.semibold};
  text-align: ${({ textAlign = 'left' }) => textAlign};
`

const Body = styled.div`
  padding: 16px;
`

const DialogText = styled(Text)``

const Footer = styled.div<{ inset?: boolean }>`
  border-top: 1px solid ${({ theme }) => theme.colors.neutrals.n6};
  ${({ inset = false }) => inset && `padding: 16px;`}
`

const DialogRoot = styled.div`
  position: fixed;
  inset: 0px;
  z-index: 1000;
`

const Backdrop = styled.div`
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  inset: 0px;
  z-index: -1;
`

export type DialogProps = {
  backdrop?: boolean
  border?: boolean
}

export const Dialog: React.FC<DialogProps> & DialogCompound = ({
  children,
  backdrop = false,
  border = false,
}) => {
  return (
    <Portal>
      <DialogRoot>
        {backdrop && <Backdrop />}
        <DialogContainer>
          <DialogCard border={border}>{children}</DialogCard>
        </DialogContainer>
      </DialogRoot>
    </Portal>
  )
}

Dialog.Title = Title
Dialog.Body = Body
Dialog.Text = DialogText
Dialog.Footer = Footer
