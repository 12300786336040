/* eslint-disable react-hooks/exhaustive-deps */
import {
  ExportFormat,
  ExportTemplate,
  Scene,
  TemplateCrop,
} from 'core/exporter/constants'
import { useCallback, useMemo } from 'react'
import {
  Rect,
  useExportEditMutation,
  useGetMomentDetailQuery,
} from '__generated__'
import { CropSettings, useExporterUpdater } from './context'

export const useExporterHelper = () => {
  const dispatch = useExporterUpdater()

  const setTitle = useCallback(
    (nextTitle: string) => {
      dispatch({
        type: useExporterUpdater.ACTION_TYPES.SET_TITLE,
        payload: {
          title: nextTitle,
        },
      })
    },
    [dispatch]
  )

  const setTemplate = useCallback(
    (nextTemplate: ExportTemplate | undefined) => {
      dispatch({
        type: useExporterUpdater.ACTION_TYPES.SET_TEMPLATE,
        payload: {
          selectedTemplate: nextTemplate,
        },
      })
    },
    [dispatch]
  )

  const setMomentId = useCallback(
    (nextMomentId: string) => {
      dispatch({
        type: useExporterUpdater.ACTION_TYPES.SET_MOMENT_ID,
        payload: {
          momentId: nextMomentId,
        },
      })
    },
    [dispatch]
  )

  const setFormat = useCallback(
    (nextFormat: ExportFormat) => {
      dispatch({
        type: useExporterUpdater.ACTION_TYPES.SET_FORMAT,
        payload: {
          selectedFormat: nextFormat,
        },
      })
    },
    [dispatch]
  )

  const setCropSettings = useCallback(
    (nextCropSettings: CropSettings) => {
      dispatch({
        type: useExporterUpdater.ACTION_TYPES.SET_CROP_SETTINGS,
        payload: {
          cropSettings: nextCropSettings,
        },
      })
    },
    [dispatch]
  )

  const setPrimaryVideoId = useCallback(
    (nextPrimaryVideoId: string) => {
      dispatch({
        type: useExporterUpdater.ACTION_TYPES.SET_PRIMARY_VIDEO_ID,
        payload: {
          primaryVideoId: nextPrimaryVideoId,
        },
      })
    },
    [dispatch]
  )

  const setPreviewRect = useCallback(
    (nextPreviewRect: DOMRect | undefined) => {
      dispatch({
        type: useExporterUpdater.ACTION_TYPES.SET_PREVIEW_RECT,
        payload: {
          previewRect: nextPreviewRect,
        },
      })
    },
    [dispatch]
  )

  const setSelectedTemplateCrop = useCallback(
    (nextSelectedTemplateCrop: TemplateCrop | undefined) => {
      dispatch({
        type: useExporterUpdater.ACTION_TYPES.SET_SELECTED_TEMPLATE_CROP,
        payload: {
          selectedTemplateCrop: nextSelectedTemplateCrop,
        },
      })
    },
    [dispatch]
  )

  const setCropRectSetting = useCallback(
    (title: string, rect: Rect) => {
      dispatch({
        type: useExporterUpdater.ACTION_TYPES.SET_CROP_RECT_DATA,
        payload: {
          title,
          rect,
        },
      })
    },
    [dispatch]
  )

  const addScene = useCallback(
    (scene: Scene) => {
      dispatch({
        type: useExporterUpdater.ACTION_TYPES.ADD_SCENE,
        payload: {
          scene,
        },
      })
    },
    [dispatch]
  )

  const deleteScene = useCallback(
    (idx: number) => {
      dispatch({
        type: useExporterUpdater.ACTION_TYPES.DELETE_SCENE,
        payload: {
          idx,
        },
      })
    },
    [dispatch]
  )

  const editScene = useCallback(
    (idx: number) => {
      dispatch({
        type: useExporterUpdater.ACTION_TYPES.DELETE_SCENE,
        payload: {
          idx,
        },
      })
    },
    [dispatch]
  )

  const clearScenes = useCallback(() => {
    dispatch({
      type: useExporterUpdater.ACTION_TYPES.CLEAR_SCENES,
    })
  }, [dispatch])

  return useMemo(
    () => ({
      setTitle,
      setCropRectSetting,
      setTemplate,
      setMomentId,
      setFormat,
      setCropSettings,
      setPrimaryVideoId,
      setPreviewRect,
      setSelectedTemplateCrop,
      addScene,
      deleteScene,
      editScene,
      clearScenes,
    }),
    [
      setTitle,
      addScene,
      deleteScene,
      editScene,
      setCropRectSetting,
      setCropSettings,
      setFormat,
      setMomentId,
      setPreviewRect,
      setPrimaryVideoId,
      setSelectedTemplateCrop,
      setTemplate,
      clearScenes,
    ]
  )
}

export const useExportEdit = (momentId: string, momentEditId: string) => {
  const { refetch } = useGetMomentDetailQuery({ variables: { momentId } })
  const [mutation] = useExportEditMutation()

  return useCallback(async () => {
    await mutation({
      variables: {
        editId: momentEditId,
      },
    })
    refetch()
  }, [momentEditId, momentId, mutation, refetch])
}
