import styled from 'styled-components/macro'
import { HStack } from 'components/core/layout'
import { Text } from 'components/core/text'

export const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
`

export const TeamContainer = styled.div`
  &:first-child {
    border-right: 1px solid ${({ theme }) => theme.colors.neutrals.n6};
  }
`

export const TeamEntry = styled(HStack).attrs({ alignItems: 'center' })`
  padding: 2px 0;
  color: ${({ theme }) => theme.colors.text.secondary};
`

export const AgentImage = styled.img`
  width: 16px;
  height: 16px;
  background-color: ${({ theme }) => theme.colors.static.neutrals.n2};
  border-radius: 4px;
`

export const NameText = styled(Text).attrs({ variant: 'text-3' })`
  margin: 0 4px;
`
