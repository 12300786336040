import { ExportTemplate } from 'core/exporter/constants'

import { SAVE_LEVELS } from './constants'

export const templateLocalStorageKey = (
  formatId: string,
  saveLevel: SAVE_LEVELS,
  id?: string
) => {
  if (saveLevel === SAVE_LEVELS.GLOBAL) {
    return `TEMPLATE_F_${formatId}_${saveLevel}`
  }
  return `TEMPLATES_F_${formatId}_${saveLevel}_${id}`
}

export const getTemplatesFromLocalStorage = (
  formatId: string,
  saveLevel: SAVE_LEVELS,
  id?: string
) => {
  return JSON.parse(
    localStorage.getItem(templateLocalStorageKey(formatId, saveLevel, id)) ??
      '[]'
  ) as ExportTemplate[]
}
