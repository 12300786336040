import { useEffect, useMemo } from 'react'
import { isEqual } from 'lodash'

import { usePrevious } from 'utils'
import { FilterGroup } from 'core/feed/components/filter-group'
import { useQueryParamFilters } from 'utils/browser'
import { useFilterForm } from 'core/feed/helpers'

import {
  agentOptions,
  killOptions,
  mapOptions,
  roundCeremonyOptions,
  tagsOptions,
} from './options'
import { RootOnly } from 'core/auth/components'

const defaultSettings = {
  tags: { include: true },
  minKills: { include: true, default: '4', options: killOptions },
  agents: { include: true },
  maps: { include: true },
  roundCeremony: { include: false },
  gameType: { include: false },
}

interface Props {
  settings?: Partial<typeof defaultSettings>
}

export const ValorantFeedFilters = ({ settings }: Props) => {
  const filterSettings = useMemo(
    () => ({
      ...defaultSettings,
      ...settings,
    }),
    [settings]
  )
  const { params, setParam } = useQueryParamFilters()

  const paramTags = useMemo(() => {
    const tagParams = params['tags']
    if (!tagParams) return []
    if (Array.isArray(tagParams)) {
      return tagParams
    } else {
      return [tagParams]
    }
  }, [params])
  const {
    data: tagsData,
    bind: tagsBind,
    selected: tagsSelected,
  } = useFilterForm(tagsOptions, true, paramTags)

  const minKills = params['minKills']
  const {
    data: killData,
    bind: killBind,
    selected: killSelected,
  } = useFilterForm(filterSettings.minKills.options, false, [
    parseInt(
      typeof minKills === 'string' ? minKills : filterSettings.minKills.default,
      10
    ),
  ])

  const {
    data: roundCeremonyData,
    bind: roundCeremonyBind,
    selected: roundCeremonySelected,
  } = useFilterForm(roundCeremonyOptions, false)

  const mapsSelected = useMemo(() => {
    const mapParams = params['maps']
    if (!mapParams) return []
    if (Array.isArray(mapParams)) {
      return mapParams
    } else {
      return [mapParams]
    }
  }, [params])
  const {
    data: mapData,
    bind: mapBind,
    selected: mapSelected,
  } = useFilterForm(mapOptions, true, mapsSelected)

  const agentsSelected = useMemo(() => {
    const agentsParams = params['agents']
    if (!agentsParams) return []
    if (Array.isArray(agentsParams)) {
      return agentsParams
    } else {
      return [agentsParams]
    }
  }, [params])
  const {
    data: agentData,
    bind: agentBind,
    selected: agentSelected,
  } = useFilterForm(agentOptions, true, agentsSelected)

  // TODO: Add Valorant only reaction moments
  // 516575

  // const {
  //   data: matchTypeData,
  //   bind: matchTypeBind,
  //   selected: matchTypeSelected,
  // } = useFilterForm(matchTypeOptions, false)

  const valorantFilters = useMemo(() => {
    return {
      tags: tagsSelected,
      agents: agentSelected,
      maps: mapSelected,
      minKills: killSelected[0] ?? 4,
      roundCeremony: roundCeremonySelected[0],
      // matchType: ValorantMatchType.Competitive,
    }
  }, [
    agentSelected,
    killSelected,
    mapSelected,
    roundCeremonySelected,
    tagsSelected,
  ])

  const prevFilters = usePrevious(valorantFilters)

  useEffect(() => {
    for (const [key, value] of Object.entries(valorantFilters)) {
      if (
        value === undefined ||
        (typeof value === 'object' && value.length === 0)
      ) {
        setParam(key, undefined)
        continue
      }
      if (typeof value === 'number') {
        setParam(key, value.toString())
        continue
      }
      setParam(key, value)
    }
  }, [setParam, valorantFilters])

  useEffect(() => {
    if (!isEqual(prevFilters, valorantFilters) && prevFilters !== undefined) {
      setParam('page', undefined)
    }
  }, [prevFilters, setParam, valorantFilters])

  return (
    <>
      <RootOnly>
        {filterSettings.tags.include && (
          <FilterGroup
            title="Types"
            data={tagsData}
            {...tagsBind}
            collapsed={false}
          />
        )}
      </RootOnly>
      {filterSettings.minKills.include && (
        <FilterGroup
          title="Min Kills"
          data={killData}
          {...killBind}
          collapsed={false}
        />
      )}
      {/* <FilterGroup
  title="Match type"
  data={matchTypeData}
  {...matchTypeBind}
/> */}
      {filterSettings.roundCeremony.include && (
        <FilterGroup
          title="Round Ceremony"
          data={roundCeremonyData}
          {...roundCeremonyBind}
        />
      )}
      {filterSettings.maps.include && (
        <FilterGroup title="Maps" data={mapData} {...mapBind} />
      )}
      {filterSettings.agents.include && (
        <FilterGroup title="Agents" data={agentData} {...agentBind} />
      )}
    </>
  )
}
