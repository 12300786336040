import { useEffect, useMemo } from 'react'

import { useGetValorantMatchVideosLazyQuery } from '__generated__'
import { useValorantMatchRoundData } from 'core/shared/valorant/hooks'
import { useTimelineHelpers } from 'core/editor/timeline/hooks'
import { Editor } from 'core/editor'
import { Timeline } from 'core/editor/timeline'
import { SyncedVideo } from 'core/editor/synced-video'

import { MAX_ROUND_TIME } from '../constants'
import { useValorantMatchState, useValorantMatchUpdater } from './context'
import { ValorantMatchTimelineDiv, ValorantMatchVideoDiv } from '../styled'
import { ValorantMomentHighlight } from '../hooks'

interface Props {
  matchId: string
  highlight?: ValorantMomentHighlight
}

export const ValorantMatchPage = ({ matchId, highlight }: Props) => {
  const { availableVideos } = useValorantMatchState()
  const dispatch = useValorantMatchUpdater()
  const { matchStart, gameLength, roundDataWithLength } =
    useValorantMatchRoundData(matchId)
  const [query, { data }] = useGetValorantMatchVideosLazyQuery()
  const { setTimelineLength, setTimelineStartAndEnd, jumpToNewSelection } =
    useTimelineHelpers()

  // set Available Videos (what to show on timeline/perspectives to offer)
  useEffect(() => {
    const streamingPlayers = data?.valorantMatch?.players.filter(
      (pl) => pl.account.channel?.streams.data[0] !== undefined
    )

    const nextAvailableVideos = streamingPlayers?.map((pl) => {
      const videoId = pl.account.channel?.streams.data[0].video?.id ?? ''
      return {
        accountId: pl.account.id,
        videoId,
        displayName: pl.account.channel?.displayName ?? '',
      }
    })

    dispatch({
      type: useValorantMatchUpdater.ACTION_TYPES.SET_AVAILABLE_VIDEOS,
      payload: {
        availableVideos: nextAvailableVideos,
      },
    })
  }, [data, dispatch])

  // Query for available streams in this match
  useEffect(() => {
    if (gameLength) {
      query({
        variables: {
          matchId,
          matchStart: new Date(matchStart).toISOString(),
          matchEnd: new Date(matchStart + gameLength).toISOString(),
        },
      })
    }
  }, [gameLength, matchId, matchStart, query])

  useEffect(() => {
    dispatch({
      type: useValorantMatchUpdater.ACTION_TYPES.SET_MATCH_ID,
      payload: { matchId },
    })
  }, [dispatch, matchId])

  //cleanup could use some work, but should help ui react a bit
  useEffect(() => {
    return () => {
      dispatch({
        type: useValorantMatchUpdater.ACTION_TYPES.SET_AVAILABLE_VIDEOS,
        payload: {
          availableVideos: undefined,
        },
      })
    }
  }, [matchId, dispatch])

  // Timeline setup for match
  useEffect(() => {
    if (gameLength) {
      setTimelineLength(MAX_ROUND_TIME * 1000)
      setTimelineStartAndEnd(
        matchStart,
        // adds just a bit of padding until we have RIOT API fix
        matchStart + gameLength + 120 * 1000
      )
    }
  }, [
    gameLength,
    highlight,
    matchStart,
    setTimelineLength,
    setTimelineStartAndEnd,
  ])

  useEffect(() => {
    if (highlight) {
      let roundOffset = 0
      for (const roundData of roundDataWithLength ?? []) {
        if (roundData.roundNum === highlight.round) break
        roundOffset += roundData.roundLength
      }
      const pregame = 125 * 1000
      const start = matchStart + pregame + roundOffset + highlight.startsAt
      const end = matchStart + pregame + roundOffset + highlight.endsAt

      jumpToNewSelection(start, end, true)
    }
  }, [highlight, jumpToNewSelection, matchStart, roundDataWithLength])

  const mainVideoId = useMemo(() => {
    return availableVideos?.find(
      (availableVid) => availableVid.accountId === highlight?.accountId
    )?.videoId
  }, [availableVideos, highlight?.accountId])

  const playerOptions = useMemo(() => ({ muted: false }), [])

  return (
    <Editor>
      <ValorantMatchVideoDiv>
        {mainVideoId ? (
          <SyncedVideo
            key={`primary_${mainVideoId}`}
            id={`primary_${mainVideoId}`}
            primaryVideoId={mainVideoId}
            controller={true}
            videoId={mainVideoId}
            playerOptions={playerOptions}
          />
        ) : null}
      </ValorantMatchVideoDiv>
      <ValorantMatchTimelineDiv>
        <Timeline>
          <div style={{ width: '100%', height: 100 }} />
          {/* <ValorantMatchTimelineDetail /> */}
          {/* <ValorantMatchTimeline /> */}
        </Timeline>
      </ValorantMatchTimelineDiv>
    </Editor>
  )
}
