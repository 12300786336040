import { useCallback, useEffect, useMemo, useState } from 'react'
import { flatten, sortBy } from 'lodash'
import momentjs from 'moment'
import {
  RecordingStatus,
  useAddEditToMomentMutation,
  useExportEditMutation,
  useGetMomentDetailQuery,
  useGetVideosInfoQuery,
} from '__generated__'
import { getEditInput, getEditMultiSceneInput } from 'core/shared/moments/hooks'
import { useEditorState } from 'core/editor/context'

import { useSignalReviewState } from '../context'
import { useSignalData, useSignalReviewHelpers } from '../helpers'
import { useEditorOffsets } from 'core/editor/hooks'
import { useKillFeedData } from 'core/video-addons/kill-feed/hooks'
import {
  DEFAULT_16_9,
  DEFAULT_9_16,
  TIKTOK_FACE_DEFAULT,
} from 'core/exporter/constants'

export const useCheckExportUpdates = (momentId: string) => {
  const { data, refetch } = useGetMomentDetailQuery({
    variables: { momentId },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (data?.moment?.__typename === 'MultiPerspectiveMoment') {
      const allEdits = data.moment.edits
      const allExportStatus = flatten(
        allEdits.map((edit) => {
          return edit.exports.map((exp) => exp.status)
        })
      )
      if (
        allExportStatus.some(
          (status) =>
            status === RecordingStatus.Inprogress ||
            status === RecordingStatus.Created
        )
      ) {
        const interval = setInterval(() => {
          refetch()
        }, 10 * 1000)

        return () => {
          clearInterval(interval)
        }
      }
    }
  }, [data, refetch])
}

export const useQuickActions = () => {
  const [quickExportVideos, setQuickExportVideos] = useState<string[]>([])
  const [createEditMutation] = useAddEditToMomentMutation()
  const [exportEditMutation] = useExportEditMutation()
  const offsetById = useEditorOffsets()
  const { momentId, relatedVideoIds, signalId } = useSignalReviewState()
  const { setShowMomentActionsModal } = useSignalReviewHelpers()
  const { selection } = useEditorState()
  const signalData = useSignalData(signalId)
  const { data, loading } = useGetMomentDetailQuery({
    variables: { momentId: momentId ?? '' },
    skip: !momentId,
  })

  const allVideoIds = useMemo(() => {
    if (signalData) {
      return [signalData?.data.videoId, ...relatedVideoIds]
    }
    return []
  }, [relatedVideoIds, signalData])

  const { data: relatedVideoData, loading: relatedVideoLoading } =
    useGetVideosInfoQuery({
      variables: { videoIds: allVideoIds },
      skip: allVideoIds.length === 0,
    })

  const edits = useMemo(() => {
    if (data?.moment?.__typename === 'MultiPerspectiveMoment') {
      return data.moment.edits.filter((edit) => edit.published)
    }
  }, [data?.moment])

  const closeModal = useCallback(() => {
    setShowMomentActionsModal(false)
  }, [setShowMomentActionsModal])

  const videoExports = useMemo(() => {
    const videoData = relatedVideoData?.videos?.data.map(({ id, user }) => ({
      videoId: id,
      userId: user?.id,
      displayName: user?.displayName,
      profileImageUrl: user?.profileImageUrl,
      selected: quickExportVideos.includes(id),
      offset: offsetById[id],
    }))
    if (videoData) {
      return sortBy(videoData, ({ videoId }) =>
        videoId === signalData?.data.videoId ? -1 : 1
      )
    }
  }, [
    offsetById,
    quickExportVideos,
    relatedVideoData?.videos?.data,
    signalData?.data.videoId,
  ])

  const toggleQuickExportVideo = useCallback((videoId: string) => {
    setQuickExportVideos((currentExportVideos) => {
      if (currentExportVideos.includes(videoId)) {
        return currentExportVideos.filter((vidId) => vidId !== videoId)
      }
      return [...currentExportVideos, videoId]
    })
  }, [])

  const createEditsAndExports = useCallback(async () => {
    if (!momentId || !videoExports || !selection) return
    const builtEdits = await Promise.all(
      videoExports
        .filter(({ videoId }) => quickExportVideos.includes(videoId))
        .map(({ videoId, displayName, offset }) => {
          return createEditMutation(
            getEditInput({
              title: `${displayName} Perspective`,
              videoId,
              startsAt: momentjs(selection.startTime).toISOString(),
              endsAt: momentjs(selection.endTime).toISOString(),
              momentId,
              offset: offset ?? 0,
            })
          )
        })
    )
    await Promise.all(
      builtEdits.map(({ data }) => {
        const editId = data?.addMomentEditToMoment?.id
        if (!editId) return Promise.reject()
        return exportEditMutation({
          variables: {
            editId,
          },
        })
      })
    )
  }, [
    createEditMutation,
    exportEditMutation,
    momentId,
    quickExportVideos,
    selection,
    videoExports,
  ])

  return useMemo(
    () => ({
      loading: loading || relatedVideoLoading,
      videoExports,
      toggleQuickExportVideo,
      edits,
      closeModal,
      createEditsAndExports,
    }),
    [
      closeModal,
      createEditsAndExports,
      edits,
      loading,
      relatedVideoLoading,
      toggleQuickExportVideo,
      videoExports,
    ]
  )
}

const KILL_PADDING = 1000
const CONTINUE_SCENE_PADDING = 4000

const endPaddingByKills = (numKills: number, dies: boolean) => {
  if (numKills < 4) return 1500
  if (numKills === 4 && dies) return 1500
  if (numKills === 5 || (numKills === 4 && !dies)) return 3000
  return 1500
}

export const useAutoCut = (
  videoId: string,
  startsAt: number,
  fixTime?: boolean
) => {
  const { playerKills, round, accountId } = useKillFeedData(
    videoId,
    startsAt,
    fixTime === true
  )

  const diesThisRound = useMemo(() => {
    const roundKills = flatten(
      Object.entries(playerKills).map(([accId, kills]) => {
        return kills
          .filter((kill) => kill.round === round)
          .map((kill) => ({
            ...kill,
            accountId: accId,
          }))
      })
    )
    return roundKills.some((kill) => kill.victim.id === accountId)
  }, [accountId, playerKills, round])

  const scenes = useMemo(() => {
    if (accountId && playerKills[accountId]) {
      const times = playerKills[accountId]
        .filter((kill) => {
          return kill.round === round
        })
        .map((kill) => kill.time)
        .sort((a, b) => a - b)
      const sceneTimes: { start: number; end: number }[] = []

      let i = 0
      while (i < times.length) {
        const start = times[i] - KILL_PADDING
        let end = times[i] + KILL_PADDING
        // how many kills should we include in this scene?
        while (
          times[i + 1] &&
          times[i + 1] - times[i] < CONTINUE_SCENE_PADDING
        ) {
          end = times[i + 1] + KILL_PADDING
          i++
        }
        if (i === times.length - 1) {
          end += endPaddingByKills(times.length, diesThisRound)
        }
        sceneTimes.push({ start, end })

        i++
      }

      return sceneTimes
    }
  }, [accountId, diesThisRound, playerKills, round])

  return useMemo(
    () => ({
      scenes,
    }),
    [scenes]
  )
}

export type AutoCutTypes = 'mobile' | 'mobile-no-cam' | 'desktop'

export const useCreateMultiCutEdit = (
  videoId: string,
  momentId: string,
  startsAt: string,
  type: AutoCutTypes
) => {
  const startsAtInMs = useMemo(() => momentjs(startsAt).valueOf(), [startsAt])
  const [createEditMutation] = useAddEditToMomentMutation()
  const [exportEditMutation] = useExportEditMutation()
  const { scenes } = useAutoCut(videoId, startsAtInMs)

  const template = useMemo(() => {
    if (type === 'mobile') return TIKTOK_FACE_DEFAULT
    if (type === 'mobile-no-cam') return DEFAULT_9_16
    if (type === 'desktop') return DEFAULT_16_9
    return DEFAULT_16_9
  }, [type])

  const createEdit = useCallback(async () => {
    if (!scenes) return
    const builtEdit = await createEditMutation(
      getEditMultiSceneInput(
        {
          title: `Auto-Cut`,
          videoId,
          momentId,
          offset: 0,
          sceneTimes: scenes.map(({ start, end }) => ({
            start: momentjs(start).toISOString(),
            end: momentjs(end).toISOString(),
          })),
          template,
        },
        type
      )
    )
    if (builtEdit.data?.addMomentEditToMoment?.id) {
      const editId = builtEdit.data.addMomentEditToMoment.id
      await exportEditMutation({
        variables: {
          editId,
        },
      })
      return editId
    }
  }, [
    createEditMutation,
    exportEditMutation,
    momentId,
    scenes,
    template,
    type,
    videoId,
  ])

  return useMemo(() => createEdit, [createEdit])
}
