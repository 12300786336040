import styled from 'styled-components/macro'
import { colors } from 'utils/colors'
// polished
import { lighten } from 'polished'

export const SceneActionsDiv = styled.div`
  position: absolute;
  right: -26px;
  bottom: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background-color: ${colors.neutral.light};
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  border: 1px solid #ccc;
  transition: right 200ms ease-in-out;

  & > svg {
    margin: 4px;
    width: 16px;
    height: 16px;
    cursor: pointer;
    fill: #666;

    &:hover {
      fill: #333;
    }
  }
`

export const SceneDisplayContainerDiv = styled.div`
  position: relative;
  width: 120px;
  height: 80px;
  border-top: 1px solid #333;
  border-bottom: 1px solid #333;
  border-left: 1px dashed #333;
  border-right: 1px dashed #333;
  background-color: ${colors.green.light};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 4px;
  cursor: pointer;
  overflow: hidden;

  &:hover {
    background-color: ${lighten(0.05, colors.green.light)};

    & > ${SceneActionsDiv} {
      right: -1px;
    }
  }

  & > div {
    margin: 4px 0;
  }
`

export const SceneListContainerDiv = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  & > ${SceneDisplayContainerDiv}:first-child {
    border-left: 1px solid #333;
  }

  & > ${SceneDisplayContainerDiv}:nth-last-child(2) {
    border-right: 1px solid #333;
  }
`

export const AddSceneDisplayContainerDiv = styled.div`
  position: relative;
  width: 180px;
  height: 80px;
  border-top: 1px solid #333;
  border-bottom: 1px solid #333;
  border-left: 1px dashed #333;
  border-right: 1px solid #333;
  background-color: ${colors.green.lightest};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 4px;

  div {
    margin: 4px 0;
  }

  button {
    margin-left: 20px;
  }
`
