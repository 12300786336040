import { HStack } from 'components/core/layout'
import { Text } from 'components/core/text'
import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

export type TimeRange = {
  startDate: Date
  endDate: Date
}

type TimeRangePickerProps = {
  initialStart: Date
  initialEnd: Date
  onChange: (timeRange: TimeRange) => void
}

export const TimeRangePicker = ({
  initialStart,
  initialEnd,
  onChange,
}: TimeRangePickerProps) => {
  const [startDate, setStartDate] = useState(initialStart)
  const [endDate, setEndDate] = useState(initialEnd)

  const onChangeStartDate = (date: Date) => {
    // Ensure that the end date is always later than the start date
    if (date > endDate) {
      setEndDate(date)
    }
    setStartDate(date)

    // Call the onChange prop to update the parent component's state
    if (onChange) {
      onChange({ startDate: date, endDate })
    }
  }

  const onChangeEndDate = (date: Date) => {
    // Ensure that the end date is always later than the start date
    if (date < startDate) {
      setStartDate(date)
    }
    setEndDate(date)

    // Call the onChange prop to update the parent component's state
    if (onChange) {
      onChange({ startDate, endDate: date })
    }
  }

  return (
    <HStack alignItems={'center'}>
      <DatePicker
        selected={startDate}
        onChange={onChangeStartDate}
        dateFormat="MMMM d, yyyy"
      />
      <Text variant="h4">-</Text>
      <DatePicker
        selected={endDate}
        onChange={onChangeEndDate}
        dateFormat="MMMM d, yyyy"
      />
    </HStack>
  )
}
