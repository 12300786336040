import { transparentize } from 'polished'
import { useCallback, useMemo, useState } from 'react'
import { useTheme } from 'styled-components/macro'

import { HStack } from 'components/core/layout'
import { Select } from 'components/core/select'
import { Portal } from 'components/dialog/portal'
import { SyncedVideo } from 'core/editor/synced-video'
import { TemplateCrop } from 'core/exporter/constants'
import { ReactComponent as CloseIcon } from 'icons/svgs/solid/times.svg'
import { containDimensions, findSameHeightFitAR } from 'utils/aspect-ratio'
import { useDimensions } from 'utils/browser'
import { Rect, useGetMomentDetailQuery } from '__generated__'
import {
  CropSettingType,
  CropSizeType,
  SceneEditingModes,
  useComposerState,
} from '../context'
import { useComposerHelper } from '../hooks'
import { IconButton } from '../template-sidebar/styled'

import { CropOverlay } from './crop-overlay'
import { EditFacecamSettings } from './facecam/edit-facecam'
import {
  CloseButton,
  EditCropContentsDiv,
  TemplateSettingsContainerDiv,
} from './styled'

interface ModalProps {
  close: () => void
}

export const ContentModal = ({ close }: ModalProps) => {
  const { selectedTemplateCrop, sceneEditingMode, selectedFormat } =
    useComposerState()
  const position = useContentPosition()

  return selectedTemplateCrop &&
    selectedFormat &&
    sceneEditingMode === SceneEditingModes.Content ? (
    <Portal>
      <div style={{ position: 'fixed', ...position }}>
        <EditCropContents selectedTemplateCrop={selectedTemplateCrop} />
        <CloseButton onClick={close} style={{ zIndex: 3 }}>
          <CloseIcon fill="#efefef" />
        </CloseButton>
      </div>
    </Portal>
  ) : null
}

interface EditCropContentsProps {
  selectedTemplateCrop: TemplateCrop
}

export const EditCropContents = ({
  selectedTemplateCrop,
}: EditCropContentsProps) => {
  const { primaryVideoId } = useComposerState()
  const [editingFacecamSettings, setEditingFacecamSettings] = useState(false)
  const {
    onVideoIdChange,
    videoId,
    videoOptions,
    onTypeChange,
    cropTypeOptions,
    cropSettingItem,
    setCropRect,
    format,
  } = useSelectedCropData(selectedTemplateCrop)
  const [ref, containerRect] = useDimensions()
  const theme = useTheme()

  return (
    <EditCropContentsDiv ref={ref}>
      <EditCropContentsDiv>
        {videoId && containerRect ? (
          <SyncedVideo
            id="content-edit"
            controller={false}
            videoId={videoId}
            primaryVideoId={primaryVideoId}
            qualityHeight={1080}
          ></SyncedVideo>
        ) : null}
        {!editingFacecamSettings && format && (
          <CropOverlay
            format={format}
            containerRect={containerRect}
            editing={cropSettingItem.type === CropSettingType.Custom}
            crop={cropSettingItem.rect}
            setCropRect={setCropRect}
            templateCrop={selectedTemplateCrop}
          />
        )}
        {editingFacecamSettings && containerRect && (
          <EditFacecamSettings
            containerRect={containerRect}
            close={() => setEditingFacecamSettings(false)}
          />
        )}
      </EditCropContentsDiv>
      {!editingFacecamSettings && (
        <TemplateSettingsContainerDiv>
          <HStack gap={8}>
            <Select value={videoId} onChange={onVideoIdChange}>
              {videoOptions?.map(({ id, title }) => (
                <option key={id} value={id}>
                  {title}
                </option>
              ))}
            </Select>
            {videoId ? (
              <Select value={cropSettingItem.type} onChange={onTypeChange}>
                {cropTypeOptions.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </Select>
            ) : null}
            {cropSettingItem.type === CropSettingType.Facecam && (
              <IconButton
                style={{ height: 32 }}
                color={transparentize(0.05, theme.colors.static.neutrals.n4)}
                onClick={() =>
                  setEditingFacecamSettings((currentOpen) => !currentOpen)
                }
              >
                <span>Edit Settings</span>
              </IconButton>
            )}
          </HStack>
        </TemplateSettingsContainerDiv>
      )}
    </EditCropContentsDiv>
  )
}

export const useSelectedCropData = (templateCrop: TemplateCrop) => {
  const { momentId, cropSettings, selectedFormat } = useComposerState()
  const { setCropSettings, setCropRectSetting } = useComposerHelper()
  const { data } = useGetMomentDetailQuery({
    variables: { momentId },
    skip: momentId === '',
  })

  const cropSettingItem = useMemo(() => {
    return (
      cropSettings[templateCrop.title] ?? {
        title: templateCrop.title,
        videoId: undefined,
        type: CropSettingType.Full,
      }
    )
  }, [cropSettings, templateCrop.title])

  const videoOptions = useMemo(() => {
    if (
      data === undefined ||
      data.moment?.__typename !== 'MultiPerspectiveMoment'
    )
      return undefined
    return data.moment.videos.map(({ id, user }) => ({
      id,
      title: user?.displayName,
    }))
  }, [data])

  const setVideoId = useCallback(
    (videoId?: string) => {
      setCropSettings({
        ...cropSettingItem,
        videoId,
      })
    },
    [cropSettingItem, setCropSettings]
  )

  const setCropType = useCallback(
    (nextCropType: CropSettingType) => {
      setCropSettings({
        ...cropSettingItem,
        type: nextCropType,
      })
    },
    [cropSettingItem, setCropSettings]
  )

  const setVideoSizeType = useCallback(
    (nextSizeType: CropSizeType) => {
      setCropSettings({
        ...cropSettingItem,
        size: nextSizeType,
      })
    },
    [cropSettingItem, setCropSettings]
  )

  const onSizeChange = useCallback(
    (evt: React.ChangeEvent<HTMLSelectElement>) => {
      return setVideoSizeType(evt.currentTarget.value as CropSizeType)
    },
    [setVideoSizeType]
  )

  const onTypeChange = useCallback(
    (evt: React.ChangeEvent<HTMLSelectElement>) => {
      return setCropType(evt.currentTarget.value as CropSettingType)
    },
    [setCropType]
  )

  const onVideoIdChange = useCallback(
    (evt: React.ChangeEvent<HTMLSelectElement>) => {
      const vidId = evt.currentTarget.value
      setVideoId(vidId === '' ? undefined : vidId)
    },
    [setVideoId]
  )

  // const sizeOptions = [CropSizeType.Contain, CropSizeType.Cover]
  const cropTypeOptions = useMemo(
    () => [
      CropSettingType.Full,
      CropSettingType.Facecam,
      CropSettingType.Custom,
    ],
    []
  )

  const setCropRect = useCallback(
    (nextRect: Rect) => {
      setCropRectSetting(templateCrop.title, nextRect)
    },
    [setCropRectSetting, templateCrop.title]
  )

  return useMemo(
    () => ({
      videoOptions,
      cropSettingItem,
      onSizeChange,
      onTypeChange,
      onVideoIdChange,
      cropTypeOptions,
      setCropRect,
      videoId: cropSettingItem.videoId,
      format: selectedFormat,
    }),
    [
      videoOptions,
      cropSettingItem,
      onSizeChange,
      onTypeChange,
      onVideoIdChange,
      cropTypeOptions,
      setCropRect,
      selectedFormat,
    ]
  )
}

const GAP = 16

const useContentPosition = () => {
  const { selectedFormat, previewContainerRect } = useComposerState()

  if (selectedFormat && previewContainerRect) {
    const heightMatchingAR = findSameHeightFitAR(selectedFormat.aspectRatio, {
      width: 16,
      height: 9,
    })
    // get dimensions with height fitting
    const containerDimensions = containDimensions(
      {
        width: previewContainerRect.width - GAP,
        height: previewContainerRect.height,
      },
      heightMatchingAR
    )

    const heightOffset =
      (previewContainerRect.height - containerDimensions.height) / 2
    const widthOffset =
      (previewContainerRect.width - GAP - containerDimensions.width) / 2

    const { width: previewWidth } = containDimensions(
      containerDimensions,
      selectedFormat.aspectRatio
    )

    const { width, height } = containDimensions(containerDimensions, {
      width: 16,
      height: 9,
    })

    return {
      width,
      height,
      left: widthOffset + previewWidth + 32 + GAP,
      top: heightOffset + 32,
    }
  } else {
    return {}
  }
}
