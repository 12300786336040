import { UserPreviewContainerDiv } from 'components/user-preview'
import { up } from 'styled-breakpoints'
import styled, { css } from 'styled-components/macro'

// I think this styling might break, it might need to be set to fixed or
// something since the user might have scrolled down the page
export const MomentViewerModalDiv = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

export const ViewerContentsDiv = styled.div`
  /* background: ${({ theme }) => theme.colors.neutrals.n8}; */

  display: flex;
  flex-direction: column;
  ${up('sm')} {
    display: grid;
    grid-column-gap: 24px;
    grid-template-columns: 1fr 280px;
    grid-template-areas: 'preview-moment moment-list';
  }
`

export const PreviewWrapperDiv = styled.div`
  grid-area: preview-moment;
`

export const PreviewDiv = styled.div`
  position: relative;
  max-height: 450px;
  height: 100%;
  box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.neutrals.n6};
  border-radius: 16px;

  video {
    width: 100%;
    height: 100%;
    border-radius: 16px;
  }
`

export const DownloadIconContainerDiv = styled.div`
  margin-right: 12px;
  svg {
    width: 24px;
    height: 24px;
    fill: ${({ theme }) => theme.colors.neutrals.n3};
    opacity: 0.25;

    &:hover {
      opacity: 0.75;
    }
  }
`

export const MomentListDiv = styled.div`
  position: relative;
  grid-area: moment-list;
  width: 100%;
  height: 100%;
  padding-bottom: 16px;
  overflow: hidden;
`

export const CloseButtonDiv = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
  width: 32px;
  height: 32px;
  cursor: pointer;

  svg {
    width: 100%;
    height: 100%;
  }
`

export const MomentItemDiv = styled.div<{ $selected: boolean }>`
  position: relative;
  border-radius: 8px;
  margin-bottom: 16px;
  border: 1px solid ${({ theme }) => theme.colors.neutrals.n6};
  cursor: pointer;

  transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

  ${({ $selected, theme }) =>
    $selected
      ? `
    background-color: ${theme.colors.neutrals.n8};
    box-shadow: ${theme.elevation.depth2};
  `
      : ``}

  &:hover {
    background-color: ${({ theme }) => theme.colors.neutrals.n8};
    box-shadow: ${({ theme }) => theme.elevation.depth2};
  }
`

export const MomentTextDiv = styled.div`
  position: relative;
  overflow: hidden;

  & > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const MomentPreviewImageDiv = styled.div<{ $backgroundUrl: string }>`
  position: relative;
  display: flex;
  flex: 0 0 64px;
  width: 64px;
  height: 64px;
  margin-right: 16px;
  border-radius: 8px;
  background-image: url(${(p) => p.$backgroundUrl});
  background-position: center center;
  background-size: cover;
`

export const FormatSelectorDiv = styled.div`
  padding: 0 32px;
  border-bottom: 2px solid ${({ theme }) => theme.colors.neutrals.n6};
`

export const FormatOptionDiv = styled.div<{ $selected: boolean }>`
  position: relative;
  color: ${({ theme }) => theme.colors.neutrals.n4};
  padding: 0 8px 8px;

  &:hover {
    &:after {
      position: absolute;
      content: '';
      left: 0;
      width: 100%;
      height: 2px;
      bottom: -2px;
      background-color: ${({ theme }) => theme.colors.neutrals.n5};
    }
  }

  ${(p) =>
    p.$selected
      ? css`
          &:after {
            position: absolute;
            content: '';
            left: 0;
            width: 100%;
            height: 2px;
            bottom: -2px;
            background-color: ${p.theme.colors.neutrals.n4};
          }
        `
      : ``}
`

export const EditOptionContainerDiv = styled.div<{ $selected: boolean }>`
  display: grid;
  grid-column-gap: 8px;
  grid-template-rows: 40px;
  grid-template-columns: 16px 132px 60px 16px;
  grid-template-areas: 'play-icon edit-title edit-time approval-icon';
  align-items: center;
  padding: 0 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutrals.n6};

  ${({ $selected, theme }) =>
    $selected ? `background-color: ${theme.colors.neutrals.n7};` : ``}

  &:hover {
    background-color: ${({ theme }) => theme.colors.neutrals.n7};
  }
`

export const EditOptionPlayIconDiv = styled.div`
  grid-area: play-icon;
  height: 16px;
  fill: ${({ theme }) => theme.colors.neutrals.n3};

  svg {
    width: 100%;
    height: 100%;
  }
`

export const EditOptionTitleDiv = styled.div`
  grid-area: edit-title;
  overflow: hidden;

  & > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const EditOptionTimeDiv = styled.div`
  grid-area: edit-time;
`

export const EditApprovalDiv = styled.div<{ $approved: boolean }>`
  grid-area: approval-icon;
  height: 16px;
  fill: ${({ theme }) => theme.colors.neutrals.n3};
  opacity: ${({ $approved }) => ($approved ? 1 : 0.25)};

  &:hover {
    opacity: ${({ $approved }) => ($approved ? 0.75 : 0.5)};
  }

  svg {
    width: 100%;
    height: 100%;
  }
`

export const DownloadEditDiv = styled.div<{ $downloaded: boolean }>`
  grid-area: approval-icon;
  height: 16px;
  fill: ${({ theme }) => theme.colors.neutrals.n3};
  opacity: ${({ $downloaded }) => ($downloaded ? 1 : 0.25)};

  &:hover {
    opacity: ${({ $downloaded }) => ($downloaded ? 0.75 : 0.5)};
  }

  svg {
    width: 100%;
    height: 100%;
  }
`

export const EditSelectorDiv = styled.div`
  ${EditOptionContainerDiv}:last-child {
    border-bottom: 0;
  }
`

export const IsUnreadContainerDiv = styled.div`
  position: absolute;
  width: 6px;
  height: 6px;
  left: 4px;
  top: 45px;

  & > svg {
    position: absolute;
    top: 0;
    left: 0;
    fill: ${({ theme }) => theme.colors.blue};
    width: 100%;
    height: 100%;
  }
`

export const UserListContainerDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 8px;

  & ${UserPreviewContainerDiv} {
    margin-right: 0;
  }
`

export const DroppableContainer = styled.div`
  position: relative;
`

export const PreviewStarContainerDiv = styled.div`
  position: relative;
  display: flex;
  margin-top: 4px;
  border-radius: 8px;
`

export const PreviewStarDiv = styled.div<{ $selected: boolean }>`
  width: 12px;
  height: 12px;
  margin: 0 2px;
  cursor: pointer;

  svg {
    &:hover {
      opacity: 1;
    }
    opacity: 0.9;
    fill: ${({ $selected, theme }) =>
      $selected ? theme.colors.neutrals.n3 : theme.colors.neutrals.n6};
  }
`
