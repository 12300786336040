import styled, { css } from 'styled-components/macro'
import { transparentize } from 'polished'

export const AgentThumbImg = styled.img<{ $selected: boolean }>`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 6px;

  border: 1px solid
    ${({ $selected }) => ($selected ? '#ffd166' : 'transparent')};
`

export const ValorantHeadingDiv = styled.div<{
  $selected: boolean
  $availablePerspective: boolean
}>`
  position: relative;
  width: 140px;
  padding: 0 6px 0 32px;
  margin: 2px 0;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-right: 1px solid #353945;
  cursor: pointer;

  ${({ $selected }) =>
    $selected
      ? null
      : css`
          &:hover {
            ${AgentThumbImg} {
              border-color: ${transparentize(0.5, '#ffd166')};
            }
          }
        `}
`

export const MainPerspectiveHeadingSpacerDiv = styled.div`
  margin: 8px 0;
  width: 100%;
  height: 1px;
  background-color: #353945;
`

export const ValorantTeamDiv = styled.div`
  width: 140px;
`
export const TeamIndicatorDiv = styled.div<{ $winner: boolean }>`
  position: relative;
  height: 100%;
  width: 2px;
  margin-right: 4px;

  background-color: ${({ $winner }) => ($winner ? '#53CFC2' : '#EF466F')};
`

export const NameDiv = styled.div`
  width: 74px;
  overflow: hidden;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  span {
    font-size: 12px;
  }
`

export const KDADiv = styled.div`
  margin-left: 12px;
`
