import React, { useEffect, useRef } from 'react'

import { useMomentChatDisplayData } from './hooks'
import { ChatEntry } from './chat/chat-entry'
import { VideoReviewChatWrapper } from './styled'
import { ChatDisplayProvider } from './context'

interface MomentChatDisplayProps {
  videoId: string
  className?: string
}

export const MomentChatDisplay: React.FC<MomentChatDisplayProps> = ({
  videoId,
  className,
}) => {
  const { messages, allowedChatMomentTypes } = useMomentChatDisplayData(videoId)
  const chatWrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!chatWrapperRef.current) {
      return
    }
    chatWrapperRef.current.scrollTop = chatWrapperRef.current.scrollHeight
  }, [messages.length, chatWrapperRef])

  return (
    <ChatDisplayProvider value={{ allowedChatMomentTypes }}>
      <VideoReviewChatWrapper className={className} ref={chatWrapperRef}>
        {messages.map((message, i) => (
          <ChatEntry key={i.toString()} message={message} />
        ))}
      </VideoReviewChatWrapper>
    </ChatDisplayProvider>
  )
}
