import { Switch, Route, useRouteMatch, Redirect } from 'react-router'

import { Text } from 'components/core/text'
import { Content, Container } from 'components/core/layout'

import { Header } from './styled'
import { Sidebar } from './sidebar'
import { ChannelsPage } from './channels'
import { AdminCampaignPage } from './campaign/campaign-page'
import { ChannelCollectionPages } from './channel-collections'
import { LeaderboardPage } from './leaderboard'

export const AdminPage = () => {
  const { path } = useRouteMatch()
  return (
    <>
      <Header>
        <Content>
          <Container>
            <Text variant="h3" weight="semibold">
              Channels
            </Text>
          </Container>
        </Content>
      </Header>
      <Content>
        <Container direction="row" columnGap={32} flex="1">
          <Container>
            <Sidebar />
          </Container>
          <Container flex="1" alignItems="stretch">
            <Switch>
              <Route path={`${path}`} exact>
                <Redirect to={`${path}/channels`} />
              </Route>
              <Route path={`${path}/channels`}>
                <ChannelsPage />
              </Route>
              <Route path={`${path}/campaigns`}>
                <AdminCampaignPage />
              </Route>
              <Route path={`${path}/channel-collections`}>
                <ChannelCollectionPages />
              </Route>
              <Route path={`${path}/leaderboard`}>
                <LeaderboardPage />
              </Route>
            </Switch>
          </Container>
        </Container>
      </Content>
    </>
  )
}
