import React, { useCallback, useMemo } from 'react'
import momentjs from 'moment'

import {
  useAddEditToMomentMutation,
  useCreateMultiPerspectiveMomentMutation,
  useExportEditMutation,
  useGetEditingVideoQuery,
} from '__generated__'
import { useEditorState } from 'core/editor/context'
import { Button } from 'components/core/button'
import { STANDARD_FORMAT } from 'core/exporter/constants'

interface Props {
  videoId: string
  title: string
}

// This requires you to be inside an Editor State, so that it can use the selection
export const QuickCreateAndPublishButton = ({ videoId, title }: Props) => {
  const { createdMoment, overlapCount, createNewMoment } =
    useCreateAndPublishMultiMoment(videoId, title)
  return (
    <Button size="small" onClick={createNewMoment} disabled={createdMoment}>
      {createdMoment ? 'Created' : `Create Moment (${overlapCount})`}
    </Button>
  )
}

const useCreateAndPublishMultiMoment = (videoId: string, title: string) => {
  const { selection } = useEditorState()
  const { data, refetch } = useGetEditingVideoQuery({
    variables: { id: videoId },
  })

  // check to see if there is a multi-perspective moment for this
  const createdMoment = useMemo(() => {
    if (!selection) return false
    // since we don't know exact times, we
    const threshold = 60 * 1000
    const matchingMoment = data?.video?.moments.data.find((m) => {
      const hasOverlap =
        selection.endTime > momentjs(m.startsAt).valueOf() - threshold &&
        selection.startTime < momentjs(m.endsAt).valueOf() + threshold
      return m.__typename === 'MultiPerspectiveMoment' && hasOverlap
    })
    return matchingMoment !== undefined
  }, [data?.video?.moments.data, selection])
  // const [status, setStatus] = useState<Status>(Status.Uncalled)
  const [createMomentMutation] = useCreateMultiPerspectiveMomentMutation()
  const [createEditMutation] = useAddEditToMomentMutation()
  const [exportEditMutation] = useExportEditMutation()

  const { overlapCount, relatedVideoIds } = useMemo(() => {
    if (!selection) return { overlapCount: 0, relatedVideoIds: [] }
    const vids = data?.video?.relatedVideos ?? []
    const overlaps = vids.filter((item) => {
      return item.overlaps.some(({ from, to }) => {
        const overlapStart = momentjs(from).valueOf()
        const overlapEnd = momentjs(to).valueOf()
        return (
          selection.startTime > overlapStart && selection.endTime < overlapEnd
        )
      })
    })
    return {
      overlapCount: overlaps.length,
      relatedVideoIds: overlaps.map((item) => item.video.id),
    }
  }, [data?.video?.relatedVideos, selection])

  const startTime = selection?.startTime ?? 0
  const endTime = selection?.endTime ?? 0

  const createEdit = useCallback(
    async (momentId: string) => {
      return createEditMutation({
        variables: {
          momentId,
          editInput: {
            title,
            formatId: STANDARD_FORMAT.id,
            published: true,
            scenes: [
              {
                start: momentjs(selection?.startTime).toISOString(),
                end: momentjs(selection?.endTime).toISOString(),
                index: 0,
                cropData: [
                  {
                    cropRect: { height: 1, width: 1, x: 0, y: 0 },
                    offset: 0,
                    title: 'main',
                    videoId,
                    muted: false,
                  },
                ],
                template: {
                  title: 'default',
                  crops: [
                    {
                      title: 'main',
                      aspectRatio: STANDARD_FORMAT.aspectRatio,
                      position: { x: 0, y: 0, width: 1, height: 1 },
                    },
                  ],
                },
              },
            ],
            approved: false,
          },
        },
      })
    },
    [
      createEditMutation,
      selection?.endTime,
      selection?.startTime,
      title,
      videoId,
    ]
  )

  const createNewMoment = useCallback(async () => {
    const input = {
      title,
      startsAt: momentjs(startTime).toISOString(),
      endsAt: momentjs(endTime).toISOString(),
      published: true,
      videoIds: [videoId, ...relatedVideoIds],
      mainVideoId: videoId,
      edits: [],
      offsets: [],
      tags: [],
      signalIds: [],
    }
    // setStatus(Status.CreatingMoment)
    const momentResult = await createMomentMutation({
      variables: { createMultiPerspectiveMomentInput: input },
    })
    const momentId = momentResult.data?.createMultiPerspectiveMoment?.id
    if (!momentId) {
      return
    }

    const editResult = await createEdit(momentId)
    const editId = editResult.data?.addMomentEditToMoment?.id
    if (!editId) {
      return
    }

    await exportEditMutation({
      variables: {
        editId,
      },
    })
    refetch()
  }, [
    createEdit,
    createMomentMutation,
    endTime,
    exportEditMutation,
    refetch,
    relatedVideoIds,
    startTime,
    title,
    videoId,
  ])

  // useEffect(() => {
  //   if (
  //     createMomentCalled &&
  //     createMomentData?.createMultiPerspectiveMoment?.id
  //   ) {
  //     setStatus(Status.DoneCreatingMoment)
  //     refetch()
  //   }
  // }, [
  //   createMomentCalled,
  //   createMomentData?.createMultiPerspectiveMoment?.id,
  //   refetch,
  // ])

  return useMemo(
    () => ({
      createNewMoment,
      selection,
      overlapCount,
      title,
      createdMoment,
    }),
    [createNewMoment, createdMoment, overlapCount, selection, title]
  )
}
