import React, { useMemo } from 'react'

import { Text } from 'components/core/text'
import { useComposerState } from 'core/composer/context'
import { useEditorState } from 'core/editor/context'

import { TextBackgroundDiv } from './styled'
import { AddonContainer, ContentsDiv, SubtitlesScaledContainer } from './styled'
import { HStack } from 'components/core/layout'
import { STANDARD_FORMAT } from 'core/exporter/constants'
import { SubtitleData, useGetSubtitleFrame, useSubtitles } from './hooks'

export const SubtitlesWithComposerContext = () => {
  const { selection, currentTime } = useEditorState()
  const { primaryVideoId, previewRect, subtitlesOptions, selectedFormat } =
    useComposerState()
  const { width, height } = useMemo(() => {
    const width = selectedFormat?.id === STANDARD_FORMAT.id ? 1920 : 1080
    const height = selectedFormat?.id === STANDARD_FORMAT.id ? 1080 : 1920
    return { width, height }
  }, [selectedFormat])

  const scale = useMemo(() => {
    const height = selectedFormat?.id === STANDARD_FORMAT.id ? 1080 : 1920
    return (previewRect?.height ?? 0) / height
  }, [previewRect?.height, selectedFormat])
  const props = {
    y: subtitlesOptions.y,
  }

  return selection?.startTime && primaryVideoId ? (
    <AddonContainer>
      <Subtitles
        width={width}
        height={height}
        scale={scale}
        currentTime={currentTime}
        startTime={selection?.startTime ?? 0}
        endTime={selection?.endTime ?? 0}
        videoId={primaryVideoId}
        {...props}
      />
    </AddonContainer>
  ) : (
    <React.Fragment />
  )
}

interface Props {
  width: number
  height: number
  scale: number
  videoId: string
  startTime: number
  endTime: number
  currentTime: number
  y: number
}

export const Subtitles = ({
  width,
  height,
  videoId,
  startTime,
  endTime,
  currentTime,
  scale,
  y,
}: Props) => {
  const { subtitleData } = useSubtitles(videoId, startTime, endTime)

  return (
    <SubtitleStill
      width={width}
      height={height}
      subtitleData={subtitleData}
      currentTime={currentTime}
      scale={scale}
      y={y}
    />
  )
}

interface StillProps {
  width: number
  height: number
  subtitleData?: SubtitleData[]
  currentTime?: number
  scale: number
  y: number
}

export const SubtitleStill = ({
  width,
  height,
  subtitleData,
  currentTime,
  scale,
  y,
}: StillProps) => {
  const { currentSubtitle } = useGetSubtitleFrame(subtitleData, currentTime)

  //uppercase text
  const text = useMemo(() => {
    return currentSubtitle?.text.toUpperCase()
  }, [currentSubtitle?.text])

  return subtitleData ? (
    <SubtitlesScaledContainer
      $scale={scale}
      $top={y}
      $width={width}
      $height={height}
    >
      <ContentsDiv>
        {text ? (
          <HStack fullWidth justifyContent="center">
            <TextBackgroundDiv>
              <Text variant="h1" color="white">
                {text}
              </Text>
            </TextBackgroundDiv>
          </HStack>
        ) : null}
      </ContentsDiv>
    </SubtitlesScaledContainer>
  ) : (
    <div />
  )
}
