import { createLocalStorageStateHook } from 'use-local-storage-state'

import randomThumbs from './thumbnails.json'

// I am just doing something super quick here, keeping it separate
// since this should all be reworked with api integration
const hashCode = function (input: string) {
  let hash = 0,
    i,
    chr
  if (input.length === 0) return hash
  for (i = 0; i < input.length; i++) {
    chr = input.charCodeAt(i)
    hash = (hash << 5) - hash + chr
    hash |= 0 // Convert to 32bit integer
  }
  return hash
}

export const valorantMomentIdHash = (
  userId: string,
  start: string,
  end: string
) => {
  return hashCode(`${userId}-${start}-${end}`)
}

// stores the multiperspective moments from a valorant moment
export const useValorantMultiPerspectiveMoments = createLocalStorageStateHook<{
  [valorantMomentHashId: string]: string[]
}>('ValorantMultiPerspectiveMoments', {})

export const getRandomThumb = (n: number) => {
  const idx = Math.round(n) % randomThumbs.length
  return randomThumbs[idx]
}
