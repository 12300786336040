import { useMemo } from 'react'
import { flatten, sortBy } from 'lodash'
import momentjs from 'moment'

import {
  useGetValorantMatchesByVideoIdQuery,
  ValorantMatchType,
} from '__generated__'

export interface RelatedVideoResult {
  displayName: string
  videoId: string
  puuids: string[]
}

export const useValorantMatchDataForVideo = (videoId: string) => {
  const { data, loading } = useGetValorantMatchesByVideoIdQuery({
    variables: { videoId },
    errorPolicy: 'all',
  })

  const matchData = useMemo(() => {
    const competitiveMatches =
      data?.video?.valorantMatches
        .filter(({ type }) => type === ValorantMatchType.Competitive)
        .map(({ id, startedAt, endedAt }) => ({
          id,
          startedAt,
          endedAt,
        })) ?? []
    return sortBy(competitiveMatches, (md) => momentjs(md.startedAt).valueOf)
  }, [data?.video?.valorantMatches])

  const primaryPlayerIds = useMemo(
    () => data?.video?.user?.linkedAccounts.map(({ puuid }) => puuid) ?? [],
    [data?.video?.user?.linkedAccounts]
  )

  const relatedByMatchId = useMemo(() => {
    let mapping = matchData.reduce((memo, { id }) => {
      return {
        ...memo,
        [id]: [],
      }
    }, {})
    data?.video?.relatedVideos.forEach((rv) => {
      const displayName = rv.video.user?.displayName ?? ''
      const videoId = rv.video.id
      const puuids =
        rv.video.user?.linkedAccounts.map(({ puuid }) => puuid) ?? []
      const matchIds = flatten(
        rv.reasons.map((reason) => {
          if (reason.__typename === 'PlayedInValorantMatches') {
            return reason.valorantMatches.map((item) => {
              return item.id
            })
          }
          return []
        })
      )
      matchIds.forEach((matchId) => {
        mapping[matchId].push({ displayName, videoId, puuids })
      })
    })
    return mapping
  }, [data?.video?.relatedVideos, matchData])

  return useMemo(
    () => ({ loading, matchData, primaryPlayerIds, relatedByMatchId }),
    [loading, matchData, primaryPlayerIds, relatedByMatchId]
  )
}
