import { useEffect } from 'react'
import { useTheme } from 'styled-components/macro'
import { transparentize } from 'polished'

import { HStack, Spacing } from 'components/core/layout'
import { Switch } from 'components/core/switch'

import { useComposerState } from '../../context'
import { useComposerHelper } from '../../hooks'
import {
  CropRectContainer,
  CropRectContainerRow,
  ToggleAddonButton,
  UnderlineInput,
  UnderlineInputLabel,
} from '../styled'
import { usePositionInput } from './hooks'

export const SubtitlesAddonSettings = () => {
  const { subtitlesAddon, subtitlesOptions } = useComposerState()
  const { setSubtitles, setSubtitlesOptions } = useComposerHelper()

  const y = usePositionInput(subtitlesOptions.y ?? 550)

  useEffect(() => {
    setSubtitlesOptions({
      y: y.value,
    })
  }, [y, setSubtitlesOptions])

  const theme = useTheme()
  return (
    <>
      <Spacing top={8}>
        <ToggleAddonButton
          color={transparentize(0.5, theme.colors.static.neutrals.n4)}
          onClick={(e) => {
            e.preventDefault()
            setSubtitles(!subtitlesAddon)
          }}
        >
          <HStack alignItems="center" gap={8}>
            <Switch checked={subtitlesAddon} onChange={() => {}} />
            <Spacing right={8}>
              <span>Subtitles</span>
            </Spacing>
          </HStack>
        </ToggleAddonButton>
      </Spacing>

      <Spacing top={8}>
        {subtitlesAddon && (
          <>
            <CropRectContainer>
              <CropRectContainerRow>
                <UnderlineInputLabel>
                  <span>Y</span>
                  <UnderlineInput {...y.bind} value={y.value} />
                </UnderlineInputLabel>
              </CropRectContainerRow>
            </CropRectContainer>
          </>
        )}
      </Spacing>
    </>
  )
}
