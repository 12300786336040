import React, { ChangeEvent, useCallback, useState } from 'react'

import { Button } from 'components/core/button'
import { TextField } from 'components/core/textfield'
import { TemplateCropSettings } from 'core/exporter/constants'

import { useTemplateEditorHelpers } from './hooks'
import { CropSettingsActionsDiv, CropSettingsDiv } from './styled'
import { ASPECT_RATIO_TYPES } from 'components/aspect-ratio/constants'
import { AspectRatioDropdown } from 'components/aspect-ratio/select'
import {
  AVAILABLE_DEFAULT_CONTENT_TYPES,
  DEFAULT_CONTENT_TYPES,
} from 'core/shared/templates/constants'
import { randomUniqueId } from 'utils'

interface CreateTemplateCropProps {}

export const CreateTemplateCrop: React.FC<CreateTemplateCropProps> = () => {
  const [title, setTitle] = useState('')
  const [defaultContent, setDefaultContent] = useState(
    DEFAULT_CONTENT_TYPES.CUSTOM
  )
  const [aspectRatio, setAspectRatio] = useState(ASPECT_RATIO_TYPES.AR16_9)
  const { addNewTemplateCrop } = useTemplateEditorHelpers()

  const onDefaultContentChange = useCallback(
    (evt: ChangeEvent<HTMLSelectElement>) => {
      setDefaultContent(evt.currentTarget.value as DEFAULT_CONTENT_TYPES)
    },
    []
  )

  const createTemplateCrop = useCallback(() => {
    let initialDimension = {
      width: 0.5,
      // better math here lol
      height: (0.5 * aspectRatio.height) / aspectRatio.width,
    }
    if (aspectRatio.title === ASPECT_RATIO_TYPES.OFF.title) {
      initialDimension = {
        width: 0.5,
        height: 0.5,
      }
    }

    const newTemplateCrop: TemplateCropSettings = {
      localId: randomUniqueId(),
      title,
      aspectRatio,
      position: {
        x: 0,
        y: 0,
        ...initialDimension,
      },
      defaultContent,
      primary: false,
    }

    addNewTemplateCrop(newTemplateCrop)
    setTitle('')
    setAspectRatio(ASPECT_RATIO_TYPES.AR16_9)
    setDefaultContent(DEFAULT_CONTENT_TYPES.CUSTOM)
  }, [addNewTemplateCrop, aspectRatio, defaultContent, title])

  return (
    <CropSettingsDiv $selected={false}>
      <TextField
        value={title}
        onChange={(evt) => setTitle(evt.currentTarget.value)}
        placeholder="Crop Title"
      />
      <CropSettingsActionsDiv>
        <AspectRatioDropdown
          aspectRatio={aspectRatio}
          setAspectRatio={setAspectRatio}
        />
        <select value={defaultContent} onChange={onDefaultContentChange}>
          {AVAILABLE_DEFAULT_CONTENT_TYPES.map((dc) => (
            <option key={dc} value={dc}>
              {dc}
            </option>
          ))}
        </select>
        <Button size="small" onClick={createTemplateCrop}>
          Add Crop
        </Button>
      </CropSettingsActionsDiv>
    </CropSettingsDiv>
  )
}
