import styled from 'styled-components/macro'
import { Text } from 'components/core/text'
import { AGENT_THUMB_IMAGES } from 'components/valorant/agent'

import { FeedItemContainer, KillFeedAgentScaledContainer } from '../styled'
import { StillPropsTypes } from '..'
import { VStack } from 'components/core/layout'
import { KILL_FEED_WEAPONS } from 'components/valorant/killfeed-weapon'

export const KillFeedStillAgentIcons = ({
  width,
  height,
  playerStats,
  scale,
  y,
  justify,
  kills,
}: StillPropsTypes) => {
  return playerStats ? (
    <KillFeedAgentScaledContainer
      $scale={scale}
      $top={y}
      $justify={justify}
      $width={width}
      $height={height}
    >
      {kills?.map((kf, idx) => (
        <FeedItemContainer key={idx}>
          <VStack gap={10} alignItems="center">
            <AgentIconContainerDiv>
              <AgentImg
                src={
                  AGENT_THUMB_IMAGES[
                    playerStats
                      ? playerStats[kf.victim.id].agent ?? 'CHAMBER'
                      : 'CHAMBER'
                  ]
                }
              />
            </AgentIconContainerDiv>
            <WeaponImg src={KILL_FEED_WEAPONS[kf.weapon ?? ''] ?? ''} alt="" />
            <Text variant="h6" weight="bold"></Text>
          </VStack>
        </FeedItemContainer>
      ))}
    </KillFeedAgentScaledContainer>
  ) : (
    <div />
  )
}

const AgentIconContainerDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  background-color: black;
  border: 2px solid #ece681;
`

export const AgentImg = styled.img`
  height: 100%;
  width: 100%;
  filter: drop-shadow(0px 0px 17px rgba(244, 236, 131, 1));
`

export const WeaponImg = styled.img`
  height: 20px;
  transform: scaleX(-1);
  filter: drop-shadow(0px 0px 17px rgba(244, 236, 131, 1));
`
