import momentjs from 'moment'
import { Button } from 'components/core/button'
import { HStack, VStack } from 'components/core/layout'
import { Text } from 'components/core/text'
import {
  useAutoCut,
  useCreateMultiCutEdit,
} from 'core/signal-review/exports/hooks'
import { lighten } from 'polished'
import { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { MatchMomentFragment, ValorantMomentTag } from '__generated__'
import { useTransformSignalToMoment } from './hooks'

interface Props {
  matchId: string
  moments?: MatchMomentFragment[]
  hasCollection: boolean
  initializeCollection: () => void
}

export const MomentCollectionSidebar = ({
  moments,
  hasCollection,
  initializeCollection,
}: Props) => {
  return (
    <SidebarContentDiv>
      {hasCollection ? (
        <Text variant="h6">Moments</Text>
      ) : (
        <Button onClick={initializeCollection}>Create</Button>
      )}
      <VStack gap={4}>
        {moments?.map((moment) => (
          <MomentPreview key={moment.id} moment={moment} />
        ))}
      </VStack>

      <VStack gap={4}>
        <HStack gap={4} alignItems="center" justifyContent="space-between">
          <Text variant="text-3" weight="bold">
            Format:
          </Text>
          <Text variant="text-3" weight="semibold">
            TikTok
          </Text>
        </HStack>
        <HStack gap={4} alignItems="flex-start" justifyContent="space-between">
          <Text variant="text-3" weight="bold">
            Add-ons:
          </Text>
          <VStack alignItems="flex-end">
            <Text variant="text-3" weight="semibold">
              Kill Feed
            </Text>
            <Text variant="text-3" weight="semibold">
              Player Display
            </Text>
            <Text variant="text-3" weight="semibold">
              K/D/A
            </Text>
          </VStack>
        </HStack>
        <HStack gap={4} alignItems="center" justifyContent="space-between">
          <Text variant="text-3" weight="bold">
            Elapsed Time:
          </Text>
          <Text variant="text-3" weight="semibold">
            1m 45s
          </Text>
        </HStack>
      </VStack>

      <Button variant="secondary" size="small">
        Create Video
      </Button>
    </SidebarContentDiv>
  )
}

const MomentPreview = ({ moment }: { moment: MatchMomentFragment }) => {
  const [createdMomentId, setCreatedMomentId] = useState<string>()
  const createMoment = useTransformSignalToMoment(moment.id)
  const createMobile = useCreateMultiCutEdit(
    moment.video.id,
    createdMomentId ?? '',
    moment.startsAt,
    'mobile'
  )
  const startsAtInMs = useMemo(
    () => momentjs(moment.startsAt).valueOf(),
    [moment.startsAt]
  )
  const { scenes } = useAutoCut(moment.video.id, startsAtInMs, true)
  const videoLength = useMemo(
    () => scenes?.reduce((acc, sc) => acc + (sc.end - sc.start), 0) ?? 0,
    [scenes]
  )

  const onCreate = useCallback(async () => {
    const id = await createMoment()
    setCreatedMomentId(id)
  }, [createMoment])

  useEffect(() => {
    if (createdMomentId) {
      createMobile()
    }
  }, [createdMomentId, createMobile])

  return (
    <MomentPreviewDiv onClick={onCreate}>
      <HStack gap={8} alignItems="center">
        <img alt="" src={moment.channel.profileImageUrl ?? ''} />
        <Text variant="text-3">{moment.channel.displayName}</Text>
      </HStack>
      <HStack gap={6}>
        {moment.__typename === 'ValorantMoment' && (
          <Text variant="small" weight="bold">
            {moment.kills.length}K
          </Text>
        )}
        {moment.__typename === 'ValorantMoment' &&
          moment.valorantTags.includes(ValorantMomentTag.Clutch) && (
            <Text variant="small" weight="bold">
              CL
            </Text>
          )}
        {moment.__typename === 'ChatMoment' && (
          <Text variant="small" weight="bold">
            {moment.type.toUpperCase()}
          </Text>
        )}
        <Text variant="small" weight="bold">
          {(videoLength / 1000).toFixed(1)}s
        </Text>
      </HStack>
    </MomentPreviewDiv>
  )
}

const SidebarContentDiv = styled.div`
  width: 100%;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const MomentPreviewDiv = styled(HStack).attrs({
  alignItems: 'center',
  justifyContent: 'space-between',
})`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  padding: 3px 6px;
  color: ${({ theme }) => theme.colors.neutrals.n2};
  background-color: ${({ theme }) => theme.colors.neutrals.n7};
  border: 1px solid ${({ theme }) => theme.colors.neutrals.n6};
  border-radius: 4px;

  &:hover {
    background-color: ${({ theme }) => lighten(0.1)(theme.colors.neutrals.n6)};
    cursor: pointer;
  }

  img {
    width: 20px;
    height: 20px;
    border-radius: 8px;
  }
`
