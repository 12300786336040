import { useEffect, useMemo } from 'react'

import { HStack } from 'components/core/layout'
import { Spinner } from 'components/core/spinner'

import { SignalTypes } from '../context'
import { PlaylistContainerDiv } from './styled'
import { ReactionMomentDisplayItem } from './reaction-item'
import { useBucketFeed } from 'core/feed/hooks'
import { useFeedState } from 'core/feed/context'

interface Props {
  setSignalData: (
    nextSignal: { signalType: SignalTypes; signalId: string } | undefined
  ) => void
  signalData: { signalType: SignalTypes; signalId: string } | undefined
}

export const ReactionSignalReviewPlaylist = ({
  setSignalData,
  signalData,
}: Props) => {
  // const [playlistOpen, setPlaylistOpen] = useState(false)
  const { loading } = useFeedState()
  const buckets = useBucketFeed()
  const feedData = useMemo(() => {
    return buckets.map((bucket) => bucket.data).flat()
  }, [buckets])
  // const simplebarStyles = useMemo(() => ({ height: 400, width: '100%' }), [])

  // const selectReactionMoment = useCallback(
  //   (id: string) => {
  //     setSignalData({ signalType: 'ChatMoment', signalId: id })
  //     setPlaylistOpen(false)
  //   },
  //   [setSignalData]
  // )

  useEffect(() => {
    if (feedData && feedData[0]) {
      setSignalData({
        signalType: signalData?.signalType ?? 'ChatMoment',
        signalId: feedData[0].id,
      })
    }
  }, [feedData, setSignalData, signalData?.signalType])

  // const reactionMoment = useMemo(() => {
  //   if (signalData) {
  //     return feedData.find((feedItem) => feedItem.id === signalData.signalId)
  //   }
  // }, [feedData, signalData])

  return (
    <PlaylistContainerDiv $open={false}>
      {loading && (
        <HStack fullWidth={true} justifyContent="center" alignItems="center">
          <Spinner color="white" size={24} />
        </HStack>
      )}
      {signalData?.signalId ? (
        <ReactionMomentDisplayItem
          selected={false}
          signalId={signalData.signalId}
          // onClick={() => setPlaylistOpen((currentlyOpen) => !currentlyOpen)}
        >
          {/* <Icon>
            <Chevron up={playlistOpen} />
          </Icon> */}
        </ReactionMomentDisplayItem>
      ) : null}
      {/* <PlaylistContentDiv $open={playlistOpen}>
        <SimpleBar style={simplebarStyles}>
          {feedData.map((moment) => (
            <ReactionMomentDisplayItem
              key={moment.id}
              selected={moment.id === reactionMoment?.id}
              signalId={moment.id}
              onClick={() => selectReactionMoment(moment.id)}
            />
          ))}
        </SimpleBar>
      </PlaylistContentDiv> */}
      {/* <RootOnly>{syncData && <SyncPlaylist matchesInfo={syncData} />}</RootOnly> */}
    </PlaylistContainerDiv>
  )
}
