import { useCallback } from 'react'
import { useParams } from 'react-router'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

import { Button } from 'components/core/button'
import { HStack, Spacing } from 'components/core/layout'
import { useDimensions } from 'utils/browser'

import { useComposerState } from '../context'
import { SceneListDiv, SceneTimelineDiv } from '../styled'
import { useEditScenes } from './hooks'
import { SceneItem } from './item'

export const SCENE_TIMELINE_SIZE = 2 * 60 * 1000

export const SceneTimeline = () => {
  const { editId } = useParams<{ momentId: string; editId: string }>()
  const { addScene, reorderScenes } = useEditScenes(editId)
  const { scenes } = useComposerState()
  const [containerRef, containerRect] = useDimensions()

  const onOrderEnd = useCallback(
    (result) => {
      if (result.destination === null) return
      reorderScenes(result.source.index, result.destination.index)
    },
    [reorderScenes]
  )

  return (
    <SceneTimelineDiv ref={containerRef}>
      <HStack justifyContent="center" alignItems="center">
        <DragDropContext onDragEnd={onOrderEnd}>
          <Droppable droppableId="main" direction="horizontal">
            {(droppableProvided) => (
              <SceneListDiv
                ref={droppableProvided.innerRef}
                {...droppableProvided.droppableProps}
              >
                {containerRect &&
                  scenes.map((scene, idx) => (
                    <Draggable
                      key={scene.id}
                      draggableId={scene.id ?? idx.toString()}
                      index={scene.index}
                    >
                      {(draggableProvided) => (
                        <SceneItem
                          key={scene.index}
                          containerRect={containerRect}
                          scene={scene}
                          draggableProvided={draggableProvided}
                        />
                      )}
                    </Draggable>
                  ))}
                {droppableProvided.placeholder}
              </SceneListDiv>
            )}
          </Droppable>
        </DragDropContext>
        <Spacing left={12}></Spacing>
        <div>
          <Button size="small" onClick={addScene}>
            Add Scene
          </Button>
        </div>
      </HStack>
    </SceneTimelineDiv>
  )
}
