import React, { useMemo } from 'react'

import { useEditorState } from 'core/editor/context'
import { useTimelineState } from 'core/editor/timeline/context'
import { ReactComponent as TimelineMarker } from 'assets/timeline/timeline-marker.svg'
import { CurrentTimeDiv } from './styled'

export const CurrentTimeIndicator: React.FC = () => {
  const { currentTime } = useEditorState()
  const { startTime, endTime } = useTimelineState()

  const style = useMemo(
    () => ({
      left: `${((currentTime - startTime) / (endTime - startTime)) * 100}%`,
    }),
    [currentTime, endTime, startTime]
  )

  return (
    <CurrentTimeDiv style={style}>
      <TimelineMarker />
    </CurrentTimeDiv>
  )
}
