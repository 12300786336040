import React, { useCallback, useEffect, useMemo } from 'react'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components/macro'

import { Button } from 'components/core/button'
import { HStack, Spacing } from 'components/core/layout'
import { TextField } from 'components/core/textfield'

import { RatingSystem } from './rating'
import { MomentViews, useSignalReviewState } from '../context'
import { useSignalData, useSignalReviewHelpers } from '../helpers'
import {
  useCreateMultiPerspectiveMoment,
  useLoadConnectedMoment,
  useManageSync,
  useUpdateMultiPerspectiveMoment,
} from '../reaction'
import { AddToCollectionButton } from 'core/moment-collections/add-button'
import { Text } from 'components/core/text'
import { useSetMomentToRead } from 'core/moment-viewer/hooks'

export const Controls = () => {
  const { location } = useHistory()
  const loadConnectedMoment = useLoadConnectedMoment()
  const { title, rating, momentId, signalId } = useSignalReviewState()
  const signalData = useSignalData(signalId)
  const { setRating, setTitle } = useSignalReviewHelpers()
  const createMoment = useCreateMultiPerspectiveMoment()
  const updateMoment = useUpdateMultiPerspectiveMoment()
  useManageSync()
  const connectedMomentId = useMemo(
    () => signalData?.data.connectedMoments[0]?.id,
    [signalData?.data.connectedMoments]
  )
  const setRead = useSetMomentToRead(signalId)

  const isUnread = useMemo(() => {
    if (
      signalData?.type === 'ValorantMoment' ||
      signalData?.type === 'ChatMoment'
    ) {
      return signalData.data.isUnread
    }
    return false
  }, [signalData?.data.isUnread, signalData?.type])

  useEffect(() => {
    if (isUnread) {
      setRead()
    }
  }, [isUnread, setRead])

  const onTitleChange = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      setTitle(evt.target.value)
    },
    [setTitle]
  )

  // load first one if there is one
  useEffect(() => {
    if (connectedMomentId) {
      loadConnectedMoment(connectedMomentId)
    }
  }, [connectedMomentId, loadConnectedMoment])

  const baseUrl = `/review/${signalId}`
  const exportUrl = `/review/${signalId}/exports`
  const currentView = useMemo(() => {
    return exportUrl === location.pathname
      ? MomentViews.EXPORTS
      : MomentViews.OVERVIEW
  }, [exportUrl, location.pathname])

  return (
    <HStack alignItems="center" style={{ height: `100%` }} gap={18}>
      <div>
        <RatingSystem rating={rating} setRating={setRating} />
        <SaveButtonContainer>
          <Button size="small" onClick={momentId ? updateMoment : createMoment}>
            {momentId ? 'Update' : 'Save'}
          </Button>
        </SaveButtonContainer>
      </div>
      {momentId && (
        <>
          <div>
            <TextField
              value={title}
              onChange={onTitleChange}
              placeholder="Title"
            />
            {/* <Tags tags={tags} setTags={setTags} /> */}
          </div>
          <Spacing left={24}></Spacing>
          <HStack
            alignItems="flex-end"
            justifyContent="center"
            fullHeight
            gap={8}
          >
            <Link to={baseUrl} replace>
              <TabDiv $active={currentView === MomentViews.OVERVIEW}>
                <Text variant="text-2" weight="semibold">
                  Overview
                </Text>
              </TabDiv>
            </Link>
            <Link to={exportUrl} replace>
              <TabDiv $active={currentView === MomentViews.EXPORTS}>
                <Text variant="text-2" weight="semibold">
                  Exports
                </Text>
              </TabDiv>
            </Link>

            <Spacing left={12} bottom={6}>
              <AddToCollectionButton
                momentId={momentId}
                position="below"
                size="small"
              />
            </Spacing>
          </HStack>
        </>
      )}
    </HStack>
  )
}

const SaveButtonContainer = styled.div`
  margin: 4px 12px 0;

  button {
    width: 100%;
    /* background-color: ${({ theme }) => theme.colors.neutrals.n6};
    color: white; */
  }
`

const TabDiv = styled.div<{ $active: boolean }>`
  height: 40px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: ${({ theme, $active }) =>
    $active ? theme.colors.blue : theme.colors.neutrals.n6};
  border: 1px solid ${({ theme }) => theme.colors.neutrals.n5};
  border-bottom: 0;
  padding: 0 20px;
  display: flex;
  align-items: center;
`
