import styled, { css } from 'styled-components/macro'
import { useMemo, useState } from 'react'
import momentjs from 'moment'

import { HStack, VStack } from 'components/core/layout'
import {
  useScoreboard,
  ScoreboardEntry,
} from 'core/feed/components/scoreboard/hooks'
import {
  AgentImage,
  ScoreboardTeam,
} from 'core/signal-review/components/scoreboard'
import { ValorantMatchTeam } from '__generated__'
import { AGENT_THUMB_IMAGES } from 'components/valorant/agent'
import { Text } from 'components/core/text'
import { useSyncMatchPerspectives } from './hooks'
import { ReactComponent as SyncIcon } from 'icons/svgs/solid/sync.svg'
import { RootOnly } from 'core/auth/components'
import { ConnectRiotAccount } from 'core/feed/components/connect-account/riot'

interface Props {
  accountIds: string[]
  matchId: string
  disabled?: boolean
}

export const MatchCard = ({ accountIds, matchId, disabled = false }: Props) => {
  const { teams, startedAt, map, winningTeam } = useScoreboard(matchId)
  const syncMatchPerspective = useSyncMatchPerspectives()
  const [selectedRiotAccount, setSelectedRiotAccount] = useState<
    | {
        accountId: string
        gameName: string
        tagLine?: string
      }
    | undefined
  >()

  const allVideoPerspectives = useMemo(() => {
    if (
      !teams ||
      !teams[ValorantMatchTeam.Red] ||
      !teams[ValorantMatchTeam.Blue]
    )
      return undefined
    const allVideoIds = [
      ...teams[ValorantMatchTeam.Red],
      ...teams[ValorantMatchTeam.Blue],
    ]
      .filter(({ videoId }) => videoId !== undefined)
      .map(({ videoId }) => videoId) as string[]
    return allVideoIds
  }, [teams])

  const userInfo = useMemo(() => {
    if (!teams) return undefined
    let mainPlayer: ScoreboardEntry | undefined = undefined
    let mainTeam: ValorantMatchTeam | undefined = undefined
    let enemyTeam: ValorantMatchTeam | undefined = undefined
    if (teams[ValorantMatchTeam.Blue] === undefined) return undefined
    mainPlayer = teams[ValorantMatchTeam.Blue].find((pl) =>
      accountIds.includes(pl.accountId)
    )
    if (mainPlayer === undefined && teams[ValorantMatchTeam.Red]) {
      mainPlayer = teams[ValorantMatchTeam.Red].find((pl) =>
        accountIds.includes(pl.accountId)
      )
      mainTeam = ValorantMatchTeam.Red
      enemyTeam = ValorantMatchTeam.Blue
    } else {
      mainTeam = ValorantMatchTeam.Blue
      enemyTeam = ValorantMatchTeam.Red
    }
    if (!mainPlayer) return undefined
    return {
      player: mainPlayer,
      mainTeam,
      enemyTeam,
    }
  }, [accountIds, teams])

  return teams &&
    teams[ValorantMatchTeam.Red] &&
    teams[ValorantMatchTeam.Blue] &&
    userInfo ? (
    <>
      <ContainerDiv $disabled={disabled}>
        <WinOrLossDiv $win={winningTeam === userInfo.mainTeam} />
        <HStack justifyContent="flex-end" gap={16}>
          <VStack flex={1} gap={8}>
            <HStack alignItems="center" justifyContent={'flex-start'} gap={8}>
              <Text variant="text-1" weight="bold">
                {momentjs(startedAt).format('MMMM Do')}
              </Text>

              <Text variant="text-3">-</Text>
              <Text variant="text-3">
                {momentjs(startedAt).format('h:mm:ss a')}
              </Text>
            </HStack>
            <RootOnly>
              <SyncMatchButtonDiv
                onClick={(e) => {
                  e.preventDefault()
                  if (allVideoPerspectives && allVideoPerspectives.length > 0) {
                    syncMatchPerspective(matchId, allVideoPerspectives)
                  }
                }}
              >
                <SyncIcon />
              </SyncMatchButtonDiv>
            </RootOnly>
            <Text variant="text-3" weight="semibold">
              {map}
            </Text>
            <HStack gap={18} alignItems="center">
              <AgentImage
                $size={48}
                src={AGENT_THUMB_IMAGES[userInfo.player.agent as string]}
              />
              <Text variant="h5">
                {userInfo.player.stats.kills} - {userInfo.player.stats.deaths}
              </Text>
            </HStack>
          </VStack>
          <div style={{ width: 210 }}>
            <ScoreboardTeam
              players={teams[userInfo.mainTeam]}
              viewAccountDetails={setSelectedRiotAccount}
            />
          </div>
          <div style={{ width: 210, margin: '0 12px' }}>
            <ScoreboardTeam
              players={teams[userInfo.enemyTeam]}
              viewAccountDetails={setSelectedRiotAccount}
            />
          </div>
        </HStack>
      </ContainerDiv>
      {selectedRiotAccount && (
        <ConnectRiotAccount
          {...selectedRiotAccount}
          close={() => setSelectedRiotAccount(undefined)}
        ></ConnectRiotAccount>
      )}
    </>
  ) : null
}

// brought over from other cards
const ContainerDiv = styled.div<{ $disabled: boolean }>`
  position: relative;
  background-color: ${({ theme }) => theme.colors.neutrals.n8};
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.colors.neutrals.n6};
  box-shadow: ${({ theme }) => theme.elevation.depth2};
  padding: 16px;
  padding-left: 22px;

  transition: box-shadow 0.2s ease-in-out;
  overflow: hidden;

  ${({ $disabled }) =>
    !$disabled &&
    css`
      cursor: pointer;
      &:hover {
        box-shadow: ${({ theme }) => theme.elevation.depth3};
      }
    `};
`

const SyncMatchButtonDiv = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  width: 12px;
  height: 12px;
  cursor: pointer;

  & > svg {
    width: 100%;
    height: 100%;
    fill: white;
  }
`

const WinOrLossDiv = styled.div<{ $win: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 6px;
  background-color: ${({ $win }) => ($win ? '#53CFC2' : '#EF466F')};
`
