import { useCallback, useMemo, useState } from 'react'
import { useTheme } from 'styled-components/macro'
import momentjs from 'moment'
import { sortBy } from 'lodash'

import { Button } from 'components/core/button'
import { Container, HStack } from 'components/core/layout'
import { Text } from 'components/core/text'
import { Dialog } from 'components/dialog'
import { Portal } from 'components/dialog/portal'
import { CustomCheckbox } from 'core/valorant-match/editor/exporting-modal'
import {
  useAddEditToMomentMutation,
  useExportEditMutation,
  useGetMomentDetailQuery,
  useGetVideosInfoQuery,
} from '__generated__'
import { getEditInput } from 'core/shared/moments/hooks'
import { useEditorState } from 'core/editor/context'
import { AspectRatioLabelDiv } from 'core/valorant-match/editor/styled'

import { useSignalReviewState } from '../context'
import { useSignalData, useSignalReviewHelpers } from '../helpers'
import { useEditorOffsets } from 'core/editor/hooks'

interface Props {
  showEdits?: boolean
  onCreate?: () => void
}
export const MomentQuickActionsModal = ({ showEdits, onCreate }: Props) => {
  const theme = useTheme()
  const {
    edits,
    closeModal,
    videoExports,
    toggleQuickExportVideo,
    createEditsAndExports,
  } = useQuickActions()

  const handleCreate = useCallback(async () => {
    await createEditsAndExports()
    if (onCreate) {
      onCreate()
    }
    closeModal()
  }, [closeModal, createEditsAndExports, onCreate])

  return (
    <Portal>
      <Dialog backdrop>
        <Dialog.Title>Quick Export</Dialog.Title>
        <>
          <Dialog.Body>
            <Container gap={16} direction="column">
              {showEdits === true && (
                <>
                  <Text variant="text-3">Edits:</Text>
                  {edits?.map(({ id, title, format }) => (
                    <HStack key={id} alignItems="center">
                      <AspectRatioLabelDiv>
                        <Text variant="small" weight="bold">
                          {format.aspectRatio.width}x{format.aspectRatio.height}
                        </Text>
                      </AspectRatioLabelDiv>
                      <Text variant="text-3">{title}</Text>
                    </HStack>
                  ))}
                </>
              )}
              {videoExports?.map(
                ({ selected, videoId, displayName, offset }) => (
                  <HStack
                    key={videoId}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <CustomCheckbox
                      onClick={() => toggleQuickExportVideo(videoId)}
                      checked={selected}
                    >
                      <Text variant="text-3">{displayName}</Text>
                    </CustomCheckbox>
                    <Text variant="small" color={theme.colors.green}>
                      {offset?.toFixed(2)}
                    </Text>
                  </HStack>
                )
              )}
              <Button size="small" variant="secondary" onClick={handleCreate}>
                Create Edits
              </Button>
            </Container>
          </Dialog.Body>
          <Dialog.Footer inset>
            <Container direction="row" gap={16} justifyContent="flex-end">
              <Button variant="secondary" onClick={closeModal}>
                Cancel
              </Button>
            </Container>
          </Dialog.Footer>
        </>
      </Dialog>
    </Portal>
  )
}

const useQuickActions = () => {
  const [quickExportVideos, setQuickExportVideos] = useState<string[]>([])
  const [createEditMutation] = useAddEditToMomentMutation()
  const [exportEditMutation] = useExportEditMutation()
  const offsetById = useEditorOffsets()
  const { momentId, relatedVideoIds, signalId } = useSignalReviewState()
  const { setShowMomentActionsModal } = useSignalReviewHelpers()
  const { selection } = useEditorState()
  const signalData = useSignalData(signalId)
  const { data, loading } = useGetMomentDetailQuery({
    variables: { momentId: momentId ?? '' },
    skip: !momentId,
  })

  const allVideoIds = useMemo(() => {
    if (signalData) {
      return [signalData?.data.videoId, ...relatedVideoIds]
    }
    return []
  }, [relatedVideoIds, signalData])

  const { data: relatedVideoData, loading: relatedVideoLoading } =
    useGetVideosInfoQuery({
      variables: { videoIds: allVideoIds },
      skip: allVideoIds.length === 0,
    })

  const edits = useMemo(() => {
    if (data?.moment?.__typename === 'MultiPerspectiveMoment') {
      return data.moment.edits.filter((edit) => edit.published)
    }
  }, [data?.moment])

  const closeModal = useCallback(() => {
    setShowMomentActionsModal(false)
  }, [setShowMomentActionsModal])

  const videoExports = useMemo(() => {
    const videoData = relatedVideoData?.videos?.data.map(({ id, user }) => ({
      videoId: id,
      userId: user?.id,
      displayName: user?.displayName,
      profileImageUrl: user?.profileImageUrl,
      selected: quickExportVideos.includes(id),
      offset: offsetById[id],
    }))
    if (videoData) {
      return sortBy(videoData, ({ videoId }) =>
        videoId === signalData?.data.videoId ? -1 : 1
      )
    }
  }, [
    offsetById,
    quickExportVideos,
    relatedVideoData?.videos?.data,
    signalData?.data.videoId,
  ])

  const toggleQuickExportVideo = useCallback((videoId: string) => {
    setQuickExportVideos((currentExportVideos) => {
      if (currentExportVideos.includes(videoId)) {
        return currentExportVideos.filter((vidId) => vidId !== videoId)
      }
      return [...currentExportVideos, videoId]
    })
  }, [])

  const createEditsAndExports = useCallback(async () => {
    if (!momentId || !videoExports || !selection) return
    const builtEdits = await Promise.all(
      videoExports
        .filter(({ videoId }) => quickExportVideos.includes(videoId))
        .map(({ videoId, displayName, offset }) => {
          return createEditMutation(
            getEditInput({
              title: `${displayName} Perspective`,
              videoId,
              startsAt: momentjs(selection.startTime).toISOString(),
              endsAt: momentjs(selection.endTime).toISOString(),
              momentId,
              offset: offset ?? 0,
            })
          )
        })
    )
    await Promise.all(
      builtEdits.map(({ data }) => {
        const editId = data?.addMomentEditToMoment?.id
        if (!editId) return Promise.reject()
        return exportEditMutation({
          variables: {
            editId,
          },
        })
      })
    )
  }, [
    createEditMutation,
    exportEditMutation,
    momentId,
    quickExportVideos,
    selection,
    videoExports,
  ])

  return useMemo(
    () => ({
      loading: loading || relatedVideoLoading,
      videoExports,
      toggleQuickExportVideo,
      edits,
      closeModal,
      createEditsAndExports,
    }),
    [
      closeModal,
      createEditsAndExports,
      edits,
      loading,
      relatedVideoLoading,
      toggleQuickExportVideo,
      videoExports,
    ]
  )
}
