import React, { useMemo } from 'react'

import { TemplateCropSettings } from 'core/exporter/constants'
import { VideoStill } from 'components/video-still'
import { useTimelineState } from 'core/editor/timeline/context'
import { useTemplateEditorState } from './context'

interface PreviewCropProps {
  templateCrop: TemplateCropSettings
  containerRect: DOMRect
}

export const ContentPreview: React.FC<PreviewCropProps> = ({
  templateCrop,
  containerRect,
}) => {
  const { videoId, momentStartTimeInMs } = useContentPreviewData(
    templateCrop,
    containerRect
  )
  // templateCrop.defaultContent

  return videoId ? (
    <VideoStill videoId={videoId} timeInMs={momentStartTimeInMs} />
  ) : null
}

const useContentPreviewData = (
  templateCrop: TemplateCropSettings,
  _: DOMRect
) => {
  const { startTime } = useTimelineState()
  const { previewContent } = useTemplateEditorState()

  const videoId = previewContent[templateCrop.title]

  return useMemo(
    () => ({ videoId, momentStartTimeInMs: startTime }),
    [startTime, videoId]
  )
}
