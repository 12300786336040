import {
  ChangeEvent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useHistory } from 'react-router'

import {
  useGetMomentCollectionIdsQuery,
  useUpdateMomentsInCollectionMutation,
  VideoWithOffsetsInput,
} from '__generated__'
import { Container, Spacing } from 'components/core/layout'
import { Text } from 'components/core/text'
import { TextField } from 'components/core/textfield'
import { Dialog } from 'components/dialog'
import { Button } from 'components/core/button'
import { useCreateMultiPerspectiveMoment } from 'core/shared/moments/hooks'
import { useEditorState } from 'core/editor/context'

import { usePlayerList } from './timeline/hooks'
import {
  CustomCheckboxContainerDiv,
  CustomCheckboxDiv,
  PlayerPerspectiveOptionsDiv,
} from './styled'
import { useValorantMatchEditorHelpers } from './helpers'
import { useValorantMatchEditorState } from './context'
import { useValorantMatchPreviewData } from '.'
import { useGetMatchOffset } from './hooks'
import { Portal } from 'components/dialog/portal'

interface Options {
  onExport?: (momentId: string) => void
}

interface Props {
  options?: Options
}

export const ValorantMomentExportingModal = ({ options }: Props) => {
  const history = useHistory()
  const { mainPerspectiveInfo } = useValorantMatchEditorState()
  const { setExportingOpen } = useValorantMatchEditorHelpers()
  const closeModal = useCallback(() => {
    setExportingOpen(false)
  }, [setExportingOpen])
  const {
    playerList,
    value,
    onChange,
    onSubmit,
    submitEnabled,
    selectedPerspectives,
    toggleSelectedPerspectives,
    status,
    lastMoment,
  } = useExportMultiplePerspectives(closeModal, options)

  const toFeed = useCallback(() => {
    history.push(`/o/mmnts/feed`)
  }, [history])

  const toMoment = useCallback(() => {
    history.push(`/moment/${lastMoment}`)
  }, [history, lastMoment])

  return (
    <Portal>
      <Dialog backdrop>
        <Dialog.Title>Export Moment</Dialog.Title>
        {status === 'pending' ? (
          <>
            <Dialog.Body>
              <Spacing bottom={8}>
                <Text variant="text-3">Name your export</Text>
              </Spacing>
              <TextField value={value} onChange={onChange} />
              <Container gap={16} direction="column">
                <Spacing top={16}>
                  <Text variant="text-3">Select Perspectives to Export</Text>
                  <Spacing top={8}>
                    <PlayerPerspectiveOptionsDiv>
                      {playerList.map(({ account }) => (
                        <CustomCheckbox
                          key={account.id}
                          checked={selectedPerspectives[account.id] ?? false}
                          onClick={() =>
                            mainPerspectiveInfo?.accountId === account.id
                              ? () => {}
                              : toggleSelectedPerspectives(account.id)
                          }
                        >
                          <Text variant="text-2">{account.channel?.name}</Text>
                        </CustomCheckbox>
                      ))}
                    </PlayerPerspectiveOptionsDiv>
                  </Spacing>
                </Spacing>
              </Container>
            </Dialog.Body>
            <Dialog.Footer inset>
              <Container direction="row" gap={16} justifyContent="flex-end">
                <Button variant="secondary" onClick={closeModal}>
                  Cancel
                </Button>
                <Button onClick={onSubmit} disabled={!submitEnabled}>
                  Create
                </Button>
              </Container>
            </Dialog.Footer>
          </>
        ) : null}
        {status === 'success' ? (
          <>
            <Dialog.Body>
              <Spacing bottom={16}>
                <Text variant="text-1">Successfully Exported Moment</Text>
              </Spacing>
            </Dialog.Body>

            <Dialog.Footer inset>
              <Container direction="row" gap={16} justifyContent="flex-end">
                <Button variant="secondary" onClick={closeModal}>
                  Close
                </Button>
                <Button onClick={toFeed}>Back To Feed</Button>
                <Button onClick={toMoment}>View Moment</Button>
              </Container>
            </Dialog.Footer>
          </>
        ) : null}
      </Dialog>
    </Portal>
  )
}

interface CustomCheckboxProps {
  checked: boolean
  onClick?: () => void
  children?: ReactNode
}

export const CustomCheckbox = ({
  onClick,
  checked,
  children,
}: CustomCheckboxProps) => {
  return (
    <CustomCheckboxContainerDiv onClick={onClick}>
      <CustomCheckboxDiv $checked={checked} />
      {children}
    </CustomCheckboxContainerDiv>
  )
}

const useExportMultiplePerspectives = (
  onClose?: () => void,
  options?: Options
) => {
  const [selectedPerspectives, setSelectedPerspectives] = useState<{
    [accountId: string]: boolean
  }>({})
  const { mainPerspectiveInfo, matchId } = useValorantMatchEditorState()

  const { videoData } = useValorantMatchPreviewData(matchId)
  const { playerList } = usePlayerList()
  const { selection } = useEditorState()
  const createMomentEditsAndExports = useCreateMultiPerspectiveMoment()
  const getVideoOffset = useGetMatchOffset(matchId)
  const [inputError, setInputError] = useState<string | null>(null)
  const [submitEnabled, setSubmitEnabled] = useState(false)
  const [value, setValue] = useState('')
  const [status, setStatus] = useState<'success' | 'error' | 'pending'>(
    'pending'
  )
  const [lastMoment, setLastMoment] = useState<string>()
  const [updateMomentIds] = useUpdateMomentsInCollectionMutation()
  const { data: collectionIdsData, refetch } = useGetMomentCollectionIdsQuery({
    variables: {
      id: localStorage.getItem('working-collection') ?? '',
      first: 200,
    },
    skip: localStorage.getItem('working-collection') === null,
  })

  const onChange = useCallback((evt: ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target
    setValue(value)
    setSubmitEnabled(value.length >= 1)
    setInputError(null)
  }, [])

  const onPressEsc = useCallback(
    (evt: KeyboardEvent) => {
      if (evt.code === 'Escape') {
        onClose && onClose()
      }
    },
    [onClose]
  )

  useEffect(() => {
    if (playerList && playerList[0])
      setSelectedPerspectives({
        [playerList[0].account.id]: true,
      })
  }, [playerList])

  useEffect(() => {
    document.addEventListener('keydown', onPressEsc)

    return () => {
      document.removeEventListener('keydown', onPressEsc)
    }
  }, [onPressEsc])

  const toggleSelectedPerspectives = useCallback((accountId: string) => {
    setSelectedPerspectives((currentSelected) => {
      return {
        ...currentSelected,
        [accountId]: currentSelected[accountId]
          ? !currentSelected[accountId]
          : true,
      }
    })
  }, [])

  const exportVideoInfo = useMemo(() => {
    return (
      videoData
        ?.filter(({ accountId }) => selectedPerspectives[accountId] === true)
        .map(({ videoId, userId, displayName }) => ({
          videoId,
          userId,
          name: displayName,
        })) ?? []
    )
  }, [selectedPerspectives, videoData])

  const onSubmit = useCallback(async () => {
    if (!mainPerspectiveInfo || !videoData || !selection) return

    let offsets: VideoWithOffsetsInput[] = []
    for (const primaryVid of videoData) {
      for (const video of videoData) {
        if (primaryVid.videoId === video.videoId) continue
        const offset = getVideoOffset(primaryVid.videoId, video.videoId)
        if (!offset) continue
        offsets.push({
          fromVideoId: primaryVid.videoId,
          toVideoId: video.videoId,
          offsets: {
            audioOffset: offset / 1000,
            videoOffset: offset / 1000,
          },
        })
      }
    }

    const momentId = await createMomentEditsAndExports({
      title: value,
      startTime: selection.startTime,
      endTime: selection.endTime,
      mainVideoId: mainPerspectiveInfo.videoId,
      offsets: offsets,
      relatedVideoIds: videoData.map(({ videoId }) => videoId),
      exportVideoInfo: exportVideoInfo,
    })

    setLastMoment(momentId)
    setStatus('success')
    // Temp stuff
    // add to a collection if found in local storage
    const workingCollectionId = localStorage.getItem('working-collection')
    if (workingCollectionId && momentId && collectionIdsData) {
      const currentMomentIds =
        collectionIdsData.momentCollection?.moments.data.map(({ id }) => id) ??
        []
      await updateMomentIds({
        variables: {
          collectionId: workingCollectionId,
          nextMomentIds: [...currentMomentIds, momentId],
        },
      })
      await refetch()
    }
    // passing through momentId back after export
    if (options?.onExport && momentId) options.onExport(momentId)
  }, [
    collectionIdsData,
    createMomentEditsAndExports,
    exportVideoInfo,
    getVideoOffset,
    mainPerspectiveInfo,
    options,
    refetch,
    selection,
    updateMomentIds,
    value,
    videoData,
  ])

  return useMemo(
    () => ({
      // loading,
      playerList,
      selectedPerspectives,
      toggleSelectedPerspectives,
      value,
      onChange,
      onSubmit,
      submitEnabled,
      inputError,
      status,
      lastMoment,
    }),
    [
      playerList,
      selectedPerspectives,
      toggleSelectedPerspectives,
      value,
      onChange,
      onSubmit,
      submitEnabled,
      inputError,
      status,
      lastMoment,
    ]
  )
}
