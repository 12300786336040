import styled from 'styled-components/macro'

import { Text } from 'components/core/text'
import { FeedType } from 'core/feed/context'

interface Props {
  selectedType: FeedType
  setFeedType: (feedType: FeedType) => void
}

export const FeedTypesTabs = ({ selectedType, setFeedType }: Props) => {
  return (
    <TabOptionContainerDiv>
      <TabOptionDiv
        $selected={selectedType === FeedType.ValorantSignal}
        onClick={() => setFeedType(FeedType.ValorantSignal)}
      >
        <Text variant="text-2" weight="semibold">
          Valorant
        </Text>
      </TabOptionDiv>
      <TabOptionDiv
        $selected={selectedType === FeedType.ChatSignal}
        onClick={() => setFeedType(FeedType.ChatSignal)}
      >
        <Text variant="text-2" weight="semibold">
          Reactions
        </Text>
      </TabOptionDiv>
    </TabOptionContainerDiv>
  )
}

const TabOptionDiv = styled.div<{ $selected: boolean }>`
  padding: 0 4px;
  cursor: pointer;
  border-bottom: 2px solid
    ${({ $selected, theme }) => theme.colors.neutrals[$selected ? 'n3' : 'n5']};
  color: ${({ $selected, theme }) =>
    theme.colors.neutrals[$selected ? 'n3' : 'n4']};

  transition: color 0.2s ease-in-out, border-bottom 0.2s ease-in-out;

  &:hover {
    border-bottom: 2px solid ${({ theme }) => theme.colors.neutrals.n3};
    color: ${({ theme }) => theme.colors.neutrals.n3};
  }
`

const TabOptionContainerDiv = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
`
