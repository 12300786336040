import { ElementType, ReactNode } from 'react'
import styled, { css } from 'styled-components/macro'
import {
  FontWeight,
  getFontStyle,
  TextVariants,
  HeaderVariants,
  monoFontStyle,
} from 'theme/typography'

const Span = styled.span<{
  variant: TextVariants | HeaderVariants
  weight: FontWeight
  color: string
  mono: boolean
  textAlign?: 'left' | 'center' | 'right'
}>`
  ${({ variant, weight }) => getFontStyle(variant, weight)}
  ${({ mono }) => mono && monoFontStyle}
  color: ${({ color }) => color};
  ${({ textAlign }) =>
    textAlign &&
    css`
      display: block;
      text-align: ${textAlign};
    `}
`

type Props = {
  as?: ElementType
  color?: string
  children?: ReactNode
  mono?: boolean
  textAlign?: 'left' | 'center' | 'right'
  className?: string
}

type TextStyleProps =
  | {
      variant: TextVariants
      weight?: FontWeight
    }
  | {
      variant: HeaderVariants
      weight?: 'bold' | 'semibold'
    }
const Text = ({
  variant,
  weight,
  children,
  color,
  textAlign,
  mono = false,
  className,
  as,
}: Props & TextStyleProps) => {
  const Component = as || 'span'
  return (
    <Span
      as={Component}
      className={className}
      mono={mono}
      variant={variant}
      weight={weight}
      color={color}
      textAlign={textAlign}
    >
      {children}
    </Span>
  )
}

export { Text }
