import { Button } from 'components/core/button'
import { Container, HStack, Spacing } from 'components/core/layout'
import { Text } from 'components/core/text'
import { ChatSignalGridItem } from 'core/feed/multi-feed'
import { flatten } from 'lodash'
import { GridContainer } from 'page/feed/styles'
import { useMemo } from 'react'
import { Route, useHistory, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { useQueryParamFilters } from 'utils/browser'
import { useGetRecentMomentsQuery } from '__generated__'
import { QuickPreviewModal } from '../preview-modal'

interface Props {
  channelId: string
}

export const SavedMoments = ({ channelId }: Props) => {
  const { channelName } = useParams<{
    channelName: string
  }>()
  const { params, setParam } = useQueryParamFilters()
  const { location, goBack, replace } = useHistory()

  const hasHistory = useMemo(() => {
    const state = location?.state as { referrer: string } | undefined
    const referrer = state?.referrer as any
    return referrer !== undefined
  }, [location?.state])

  const currentPage = useMemo(() => {
    const pageStr = params['page']
    return pageStr && typeof pageStr === 'string' ? parseInt(pageStr, 10) : 0
  }, [params])

  const { data: momentData, loading } = useGetRecentMomentsQuery({
    variables: {
      channelIds: [channelId],
      first: 14,
      after: currentPage === 0 ? undefined : (currentPage * 14 - 1).toString(),
    },
  })

  const signalData = useMemo(() => {
    return flatten(
      momentData?.moments?.data
        .filter((item) => {
          return (
            item.__typename === 'MultiPerspectiveMoment' &&
            item.signals[0] &&
            item.signals[0].__typename === 'ChatMoment'
          )
        })
        .map((item) => {
          if (item.__typename === 'MultiPerspectiveMoment') {
            return { signalId: item.signals[0]?.id, momentId: item.id }
          }
          return undefined
        })
    )
  }, [momentData?.moments?.data])

  const hasNextPage = useMemo(() => {
    return momentData?.moments?.pagination.hasNextPage
  }, [momentData?.moments?.pagination.hasNextPage])

  const currentUrl = useMemo(() => {
    return `/roster/${channelName}/saved`
  }, [channelName])

  return (
    <div>
      <Text variant="h4">Saved Moments</Text>
      <Spacing top={16} />
      {signalData?.length === 0 && !loading && (
        <HStack justifyContent="center" fullWidth>
          <Spacing top={16}>
            <Text variant="text-2">No saved moments yet</Text>
          </Spacing>
        </HStack>
      )}
      <GridContainer>
        {signalData?.map((sigData) => {
          if (sigData) {
            return (
              <Link
                key={sigData.signalId}
                to={{
                  pathname: `${currentUrl}/moment/${sigData.signalId}`,
                  search:
                    currentPage === 0 ? undefined : `?page=${currentPage}`,
                  state: { referrer: 'feed' },
                }}
              >
                <ChatSignalGridItem
                  key={sigData.signalId}
                  momentId={sigData.signalId}
                />
              </Link>
            )
          }
          return null
        })}
      </GridContainer>
      <Container direction="row" justifyContent="center" gap={8}>
        <Button
          onClick={() => {
            const nextPage = currentPage - 1
            setParam('page', nextPage === 0 ? undefined : nextPage.toString())
          }}
          variant={currentPage === 0 || loading ? 'secondary' : 'primary'}
          disabled={currentPage === 0 || loading}
          isLoading={loading}
        >
          Prev Page
        </Button>
        <Button
          onClick={() => setParam('page', (currentPage + 1).toString())}
          variant={!hasNextPage || loading || loading ? 'secondary' : 'primary'}
          disabled={!hasNextPage || loading}
          isLoading={loading}
        >
          Next Page
        </Button>
      </Container>
      <Route path="/roster/:channelName/saved/moment/:signalId">
        <QuickPreviewModal
          onClose={() =>
            hasHistory
              ? goBack()
              : replace({
                  pathname: `/roster/${channelName}`,
                  search:
                    currentPage === 0 ? undefined : `?page=${currentPage}`,
                })
          }
        />
      </Route>
    </div>
  )
}
