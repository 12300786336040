import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components/macro'

import { Button } from 'components/core/button'
import { HStack, VStack } from 'components/core/layout'
import { TextField } from 'components/core/textfield'
import { RatingSystem } from 'core/signal-review/controls/rating'
import { useQuickSave } from '../hooks'
import { useParams } from 'react-router'

interface Props {
  saveButtonRect?: DOMRect
  onClose: () => void
}

export const QuickSaveModal = ({ onClose }: Props) => {
  const { signalId } = useParams<{ signalId: string }>()
  const [title, setTitle] = useState('')
  const [rating, setRating] = useState<number>()
  const titleRef = useRef<HTMLInputElement>(null)
  const quickSave = useQuickSave(signalId, title, rating)

  useEffect(() => {
    if (titleRef.current) {
      titleRef.current.focus()
    }
  }, [])

  return (
    <ModalAnchorPointDiv>
      <SaveModalDiv>
        <VStack>
          <SaveButtonContentDiv>
            <VStack gap={8}>
              <TextField
                ref={titleRef}
                placeholder="Moment Title"
                onChange={(e) => setTitle(e.target.value)}
                value={title}
              />
              <RatingSystem rating={rating} setRating={setRating} />
            </VStack>
          </SaveButtonContentDiv>
          <HStack justifyContent="flex-end" gap={8}>
            <Button size="small" variant="plain" onClick={onClose}>
              Cancel
            </Button>
            <Button size="small" onClick={quickSave}>
              Save
            </Button>
          </HStack>
        </VStack>
      </SaveModalDiv>
    </ModalAnchorPointDiv>
  )
}

const ModalAnchorPointDiv = styled.div`
  position: absolute;
  // setup offsets to match button (padding + border)
  bottom: -13px;
  right: -13px;
`

const SaveModalDiv = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.neutrals.n5};
  background-color: ${({ theme }) => theme.colors.neutrals.n8};
  border-radius: 4px;
  padding: 12px;
  min-width: 230px;
`

const SaveButtonContentDiv = styled.div`
  display: flex;
`
