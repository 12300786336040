import { NavLink } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

import { useGetCurrentOrganizationQuery } from '__generated__'
import { NavigationBar } from 'components/navigation-bar'

import { Login } from '../navigation/login'
import { ProfileMenu } from '../navigation/profile-menu/profile-menu'
import { useMemo } from 'react'
import { useAppTheme } from 'theme/useDarkMode'
import blackLogo from 'assets/sliver-logo.png'
import styled from 'styled-components/macro'

const AppNavigation = () => {
  const { theme: themeMode } = useAppTheme()
  const { isAuthenticated, isLoading: isAuthLoading } = useAuth0()
  const { data } = useGetCurrentOrganizationQuery()

  const primaryUrl = useMemo(() => {
    const rosterData = data?.viewer?.organization?.roster?.data
    if (rosterData === undefined || !isAuthenticated) {
      return { url: '/', title: 'Content' }
    }
    if (rosterData.length === 1) {
      return { url: `/channel/${rosterData[0]?.name}`, title: 'Content' }
    } else if (rosterData.length > 1) {
      return { url: '/roster', title: 'Roster' }
    }
    return { url: '/', title: 'Content' }
  }, [data?.viewer?.organization?.roster?.data, isAuthenticated])

  return (
    <NavigationBar>
      <NavLink to={primaryUrl.url}>
        <LogoImg $dark={themeMode === 'dark'} src={blackLogo} alt="logo" />
      </NavLink>

      {data && (
        <NavigationBar.Menu>
          <NavigationBar.MenuItem to={primaryUrl.url}>
            {primaryUrl.title}
          </NavigationBar.MenuItem>
          <NavigationBar.MenuItem
            to={`/o/${data?.viewer?.organization?.slug}/collections`}
          >
            Collections
          </NavigationBar.MenuItem>
        </NavigationBar.Menu>
      )}

      <NavigationBar.Right>
        {!isAuthLoading && (!isAuthenticated ? <Login /> : <ProfileMenu />)}
      </NavigationBar.Right>
    </NavigationBar>
  )
}

export default AppNavigation

const LogoImg = styled.img<{ $dark: boolean }>`
  height: 24px;
  filter: invert(${({ $dark }) => ($dark ? 1 : 0)});
`
