import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useTimelineState } from 'core/editor/timeline/context'
import { useTimelineHelpers } from 'core/editor/timeline/hooks'
import { useValorantMatchPreviewData } from 'core/valorant-match/editor'
import {
  ValorantMatchPlayerPreviewFragment,
  ValorantMatchVideoSyncStatus,
} from '__generated__'
import { sortBy } from 'lodash'

export const BLOCKLIST_CHANNEL_IDS = {
  '40965449': 'Subroza',
  '66243419': 'BabyJ',
  // '44558619': 'ethos',
  '103262684': 'jasonr',
  // '100182904': 'wardell',
}

export const useMatchPlayerList = (matchId: string, primaryUserId: string) => {
  const [ignoredVideos, setIgnoredVideos] = useState<string[]>([])
  const { videoData, previewData } = useValorantMatchPreviewData(matchId)

  const mainPerspectiveInfo = useMemo(() => {
    return videoData?.find((info) => info.userId === primaryUserId)
  }, [primaryUserId, videoData])

  const accountIdsWithVideos = useMemo(() => {
    return videoData?.map((vd) => vd.accountId) ?? []
  }, [videoData])

  const mainPerspectivePlayerInfo = useMemo(() => {
    if (!previewData?.valorantMatch || !primaryUserId) return undefined
    return previewData.valorantMatch.players.find(
      (pl) => pl.account.channel?.id === primaryUserId
    )
  }, [previewData?.valorantMatch, primaryUserId])

  const playerList: ValorantMatchPlayerPreviewFragment[] = useMemo(() => {
    if (
      !previewData?.valorantMatch ||
      primaryUserId === '' ||
      !mainPerspectivePlayerInfo
    )
      return []
    const syncedVideoIds = previewData.valorantMatch.videoSyncData
      .filter(({ status }) => status === ValorantMatchVideoSyncStatus.Completed)
      .map(({ video }) => video.id)
    const filteredList = previewData.valorantMatch.players.filter((pl) => {
      let ignoreVideo = false
      const videoInfo = videoData?.find(
        ({ accountId }) => accountId === pl.account.id
      )
      if (ignoredVideos !== null && videoInfo !== undefined) {
        ignoreVideo = ignoredVideos.includes(videoInfo.videoId)
      }

      if (
        videoInfo !== undefined &&
        !syncedVideoIds.includes(videoInfo.videoId)
      ) {
        ignoreVideo = true
      }

      return (
        accountIdsWithVideos.includes(pl.account.id) &&
        pl.account.channel &&
        BLOCKLIST_CHANNEL_IDS[pl.account.channel.id] === undefined &&
        !ignoreVideo
      )
    })

    const firstTeam = mainPerspectivePlayerInfo.team

    const byScore = sortBy(filteredList, (pl) => -pl.stats.score)
    const byTeam = sortBy(byScore, ({ team }) => (team === firstTeam ? 0 : 1))
    return sortBy(byTeam, ({ account }) =>
      account.channel?.id === primaryUserId ? 0 : 1
    )
  }, [
    accountIdsWithVideos,
    ignoredVideos,
    mainPerspectivePlayerInfo,
    previewData?.valorantMatch,
    primaryUserId,
    videoData,
  ])

  // ignored videos
  const onStorageChange = useCallback(() => {
    const storedItem = localStorage.getItem('ignoredVideos')
    if (storedItem === null) {
      setIgnoredVideos([])
      return
    }
    const storedVideosArr = JSON.parse(storedItem) as string[]
    setIgnoredVideos(storedVideosArr)
  }, [])

  useEffect(() => {
    window.addEventListener('storage', onStorageChange)
    return () => {
      window.removeEventListener('storage', onStorageChange)
    }
  }, [onStorageChange])

  useEffect(() => {
    onStorageChange()
  }, [onStorageChange])

  return useMemo(
    () => ({ mainPerspectiveInfo, playerList }),
    [mainPerspectiveInfo, playerList]
  )
}

export const useDragTimeline = (seekToPageX: (pageX: number) => void) => {
  const { containerRect, offsetPct } = useTimelineState()
  const { setOffsetPct } = useTimelineHelpers()
  const contentRef = useRef<HTMLDivElement>(null)
  const startingMousePos = useRef(0)
  const startingOffsetPos = useRef(0)
  const lastSeenOffsetPct = useRef(0)
  const mouseDownPageX = useRef<number | undefined>(undefined)
  const drag = useRef(false)

  const getContentsRect = useCallback(() => {
    return contentRef.current?.getBoundingClientRect()
  }, [contentRef])

  const onMouseMove = useCallback(
    (event: MouseEvent) => {
      const contentsRect = getContentsRect()
      if (!contentsRect || !containerRect) return
      drag.current = true
      const diff = startingMousePos.current - event.clientX
      let nextOffset = startingOffsetPos.current + diff

      const maxOffset = contentsRect.width - containerRect.width
      // control max/min setup
      if (nextOffset < 0) {
        nextOffset = 0
      } else if (nextOffset > maxOffset) {
        nextOffset = maxOffset
      }

      // we could set min or max times
      const nextOffsetPct = nextOffset / maxOffset
      setOffsetPct(nextOffsetPct)
    },
    [getContentsRect, containerRect, setOffsetPct]
  )

  const onMouseUp = useCallback(() => {
    window.removeEventListener('mouseup', onMouseUp)
    window.removeEventListener('mousemove', onMouseMove)
    if (drag.current === false && mouseDownPageX.current) {
      seekToPageX(mouseDownPageX.current)
    }
    drag.current = false
    // onUpdateDone()
  }, [onMouseMove, seekToPageX])

  const onMouseDown = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation()
      const contentsRect = getContentsRect()
      mouseDownPageX.current = event.pageX
      if (!contentsRect || !containerRect) return
      startingMousePos.current = event.clientX
      startingOffsetPos.current =
        (contentsRect.width - containerRect.width) * lastSeenOffsetPct.current
      // console.log(startingOffsetPos.current)

      window.addEventListener('mousemove', onMouseMove)
      window.addEventListener('mouseup', onMouseUp)
    },
    [getContentsRect, containerRect, onMouseMove, onMouseUp]
  )

  const onWheel = useCallback(
    (event: WheelEvent) => {
      event.preventDefault()
      const contentsRect = getContentsRect()
      if (!contentsRect || !containerRect) return
      const maxOffset = contentsRect.width - containerRect.width
      let nextOffset = startingOffsetPos.current - event.deltaY
      if (nextOffset < 0) {
        nextOffset = 0
      } else if (nextOffset > maxOffset) {
        nextOffset = maxOffset
      }
      const nextOffsetPct = nextOffset / maxOffset
      setOffsetPct(nextOffsetPct)
      startingOffsetPos.current = nextOffset
    },
    [containerRect, getContentsRect, setOffsetPct]
  )

  useEffect(() => {
    if (contentRef.current) {
      const elem = contentRef.current
      elem.addEventListener('wheel', onWheel)
      elem.addEventListener('mousedown', onMouseDown)
      return () => {
        elem.removeEventListener('wheel', onWheel)
        elem.removeEventListener('mousedown', onMouseDown)
      }
    }
  }, [onMouseDown, onWheel])

  useEffect(() => {
    lastSeenOffsetPct.current = offsetPct
  }, [offsetPct])

  return useMemo(
    () => ({
      contentRef,
    }),
    []
  )
}
