import React, { useEffect, useMemo } from 'react'
import { sum, uniq } from 'lodash'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'

import {
  FeedPreviewFragment,
  useGetFeedValorantSignalQuery,
  useGetReactionSignalQuery,
  ValorantMatchVideoSyncStatus,
} from '__generated__'
import { Spinner } from 'components/core/spinner'
import { GridItem } from 'core/feed/components/grid/grid-item'
import { Button } from 'components/core/button'
import { Container, Spacing } from 'components/core/layout'
import { useFeedHelpers } from 'core/feed/helpers'
import { translateRatingEnum } from 'core/signal-review/helpers'
import { useQueryParamFilters } from 'utils/browser'

import { GridContainer } from '../../page/feed/styles'
import {
  MomentThumbnailProvider,
  MomentThumbnailConsumer,
} from '../../page/feed/components/moment-thumbnail-provider'
import { useMultiFeed } from './hooks'
import { ReactionGridItem } from './components/grid/grid-item-reaction'
import { SelectableItem } from './selectable-item'

interface Props {
  additionalFilters?: { [key: string]: string | { [key: string]: string } }
  hideChannel?: boolean
  selectable?: boolean
  selectedMap?: Record<string, true>
  toggleSelected?: (signalId: string) => void
}

export const MultiFeed = ({
  additionalFilters,
  hideChannel,
  selectable,
  selectedMap,
  toggleSelected,
}: Props) => {
  const { data, hasNextPage, loading } = useMultiFeed(additionalFilters)
  const { params, setParam } = useQueryParamFilters()

  const currentPage = useMemo(() => {
    const pageStr = params['page']
    return pageStr && typeof pageStr === 'string' ? parseInt(pageStr, 10) : 0
  }, [params])

  return (
    <Spacing bottom={32}>
      {loading && <Spinner />}
      <GridContainer>
        {data?.map((moment) => (
          <GridListItem
            moment={moment}
            hideChannel={hideChannel}
            selectable={selectable ?? false}
            selected={selectedMap && selectedMap[moment.id]}
            toggleSelected={() =>
              toggleSelected ? toggleSelected(moment.id) : () => {}
            }
          />
        ))}
      </GridContainer>
      <Container direction="row" justifyContent="center" gap={8}>
        <Button
          onClick={() => {
            const nextPage = currentPage - 1
            setParam('page', nextPage === 0 ? undefined : nextPage.toString())
          }}
          variant={currentPage === 0 || loading ? 'secondary' : 'primary'}
          disabled={currentPage === 0 || loading}
          isLoading={loading}
        >
          Prev Page
        </Button>
        <Button
          onClick={() => setParam('page', (currentPage + 1).toString())}
          variant={!hasNextPage || loading || loading ? 'secondary' : 'primary'}
          disabled={!hasNextPage || loading}
          isLoading={loading}
        >
          Next Page
        </Button>
      </Container>
    </Spacing>
  )
}

const GridListItem = ({
  moment,
  hideChannel,
  selectable,
  selected,
  toggleSelected,
}: {
  moment: FeedPreviewFragment
  hideChannel?: boolean
  selectable?: boolean
  selected?: boolean
  toggleSelected: (id: string) => void
}) => {
  return (
    <GridListItemContainerDiv>
      <Link
        key={moment.id}
        to={{
          pathname: `/review/${moment.id}`,
          state: { referrer: 'feed' },
        }}
      >
        {moment.__typename === 'ValorantMoment' ? (
          <ValorantSignalGridItem
            momentId={moment.id}
            hideChannel={hideChannel}
          />
        ) : (
          <ChatSignalGridItem momentId={moment.id} hideChannel={hideChannel} />
        )}
      </Link>
      {selectable ? (
        <SelectableItem
          momentId={moment.id}
          selected={selected ?? false}
          toggleSelected={toggleSelected}
        />
      ) : null}
    </GridListItemContainerDiv>
  )
}

const GridListItemContainerDiv = styled.div`
  position: relative;
`

interface GridItemProps {
  momentId: string
  hideChannel?: boolean
  onClick?: () => void
}

export const ValorantSignalGridItem = ({
  momentId,
  onClick,
  hideChannel,
}: GridItemProps) => {
  const { setMatchVideoSync } = useFeedHelpers()
  const { data } = useGetFeedValorantSignalQuery({
    variables: { signalId: momentId },
  })
  const moment = useMemo(() => {
    if (data?.moment && data.moment.__typename === 'ValorantMoment') {
      return data?.moment
    }
  }, [data?.moment])

  const accurateKillCount = useMemo(
    () =>
      moment?.kills.filter((kill) => {
        return kill.killer?.account.id !== kill.victim?.account.id
      }),
    [moment?.kills]
  )

  const ratings = useMemo(() => {
    if (data?.moment && data.moment.__typename === 'ValorantMoment') {
      const connectedMoment = data.moment.usedIn[0]
      if (!connectedMoment) return undefined
      const allRatings = connectedMoment.ratings.map(({ rating }) =>
        translateRatingEnum(rating)
      )
      if (allRatings.length === 0) return undefined
      return sum(allRatings) / allRatings.length
    }
  }, [data?.moment])

  const editsAspectRatios = useMemo(() => {
    if (data?.moment && data.moment.__typename === 'ValorantMoment') {
      const connectedMoment = data.moment.usedIn[0]
      if (!connectedMoment) return undefined
      const allFormats = connectedMoment.edits.map(
        ({ format }) =>
          `${format.aspectRatio.width}x${format.aspectRatio.height}`
      )
      return uniq(allFormats)
    }
  }, [data?.moment])

  const matchUrl = useMemo(() => {
    return `/channel/${moment?.channel.name}/matches/${moment?.match.id}`
  }, [moment?.channel.name, moment?.match.id])

  useEffect(() => {
    if (moment) {
      const matchId = moment.match.id
      const videoId = moment.video.id
      // some of this stuff is just needed to load up match player
      const matchStart = moment.match.startedAt
      const userId = moment.channel.id
      const synced = moment.match.videoSyncData.find(
        ({ video }) => video.id === videoId
      )
      setMatchVideoSync({
        matchId,
        videoId,
        matchStart,
        userId,
        synced: synced !== undefined,
      })
    }
  }, [moment, setMatchVideoSync])

  return moment ? (
    <MomentThumbnailProvider id={moment.id} key={moment.id}>
      <MomentThumbnailConsumer>
        {({ thumbnail }) => (
          <GridItem
            onClick={onClick}
            channel={{
              id: moment.channel.id,
              name: moment.channel.name,
              profileImageUrl: moment.channel.profileImageUrl || '',
            }}
            agent={moment.agent}
            kills={accurateKillCount?.length ?? 0}
            matchUrl={matchUrl}
            combatScore={moment.combatScore}
            map={moment.match.map || ''}
            thumbnail={thumbnail}
            synced={
              moment.match.videoSyncData.find(
                (sync) => sync.video.id === moment.video.id
              )?.status === ValorantMatchVideoSyncStatus.Completed
            }
            startsAt={moment.startsAt}
            rating={ratings}
            editAspectRatios={editsAspectRatios}
            unread={moment.isUnread}
            hideChannel={hideChannel ?? false}
          />
        )}
      </MomentThumbnailConsumer>
    </MomentThumbnailProvider>
  ) : null
}

export const ChatSignalGridItem = ({
  momentId,
  onClick,
  hideChannel,
}: GridItemProps) => {
  const { moment, ratings, editsAspectRatios, savedTitle } =
    useChatSignalGridData(momentId)

  return moment ? (
    <MomentThumbnailProvider id={moment.id} key={moment.id}>
      <MomentThumbnailConsumer>
        {({ thumbnail }) => (
          <ReactionGridItem
            onClick={onClick}
            channel={{
              id: moment.channel.id,
              name: moment.channel.name,
              profileImageUrl: moment.channel.profileImageUrl || '',
            }}
            at={moment.startsAt}
            metadata={moment.metadata}
            rating={ratings}
            editAspectRatios={editsAspectRatios}
            score={parseFloat(moment.metadata?.score ?? 0) * 100}
            thumbnail={thumbnail}
            type={moment.type}
            synced={false}
            hideChannel={hideChannel ?? false}
            unread={moment.isUnread}
            savedTitle={savedTitle}
          />
        )}
      </MomentThumbnailConsumer>
    </MomentThumbnailProvider>
  ) : null
}

const useChatSignalGridData = (signalId: string) => {
  const { data } = useGetReactionSignalQuery({
    variables: { signalId: signalId },
  })

  const moment = useMemo(() => {
    if (
      data?.moment &&
      (data.moment.__typename === 'ChatMoment' ||
        data.moment.__typename === 'ManualSignalMoment')
    ) {
      return data?.moment
    }
  }, [data?.moment])

  const connectedMoment = useMemo(() => {
    if (moment) {
      return moment.usedIn[0]
    }
  }, [moment])

  const ratings = useMemo(() => {
    if (moment && connectedMoment) {
      const allRatings = connectedMoment.ratings.map(({ rating }) =>
        translateRatingEnum(rating)
      )
      if (allRatings.length === 0) return undefined
      return sum(allRatings) / allRatings.length
    }
  }, [connectedMoment, moment])

  const editsAspectRatios = useMemo(() => {
    if (moment && connectedMoment) {
      const allFormats = connectedMoment.edits.map(
        ({ format }) =>
          `${format.aspectRatio.width}x${format.aspectRatio.height}`
      )
      return uniq(allFormats)
    }
  }, [connectedMoment, moment])

  const savedTitle = useMemo(() => {
    if (moment && connectedMoment) {
      return connectedMoment.title ?? undefined
    }
  }, [connectedMoment, moment])

  return useMemo(
    () => ({
      moment,
      ratings,
      editsAspectRatios,
      savedTitle,
    }),
    [editsAspectRatios, moment, ratings, savedTitle]
  )
}
