import { useParams } from 'react-router'
import { Column } from 'react-table'
import { useCallback, useMemo, useState } from 'react'

import {
  ChannelRowPreviewFragment,
  useGetChannelCollectionChannelsQuery,
  useUpdateChannelCollectionChannelsMutation,
} from '__generated__'
import { Table } from 'components/core/table'
import { HStack, Spacing } from 'components/core/layout'
import { ReactComponent as TrashIcon } from 'icons/svgs/solid/trash.svg'
import { formatNumber } from 'utils/numbers'
import { Button } from 'components/core/button'
import { Text } from 'components/core/text'

import { AddChannel } from './add-channel'

export const AdminCollectionDetailPage = () => {
  const { collectionSlug } = useParams<{ collectionSlug: string }>()
  const { data } = useGetChannelCollectionChannelsQuery({
    variables: {
      slug: collectionSlug,
    },
  })
  const [updateChannels] = useUpdateChannelCollectionChannelsMutation()
  const [adding, setAdding] = useState(false)

  const allIds = useMemo(
    () => data?.channelCollection?.channels.data.map(({ id }) => id) ?? [],
    [data?.channelCollection?.channels.data]
  )

  const addUser = useCallback(
    (id: string) => {
      const collectionId = data?.channelCollection?.id
      // quick double check
      const notInList = allIds.indexOf(id) === -1

      if (collectionId && notInList) {
        updateChannels({
          variables: {
            collectionId,
            channelIds: [...allIds, id],
          },
        })
      }
    },
    [allIds, data?.channelCollection?.id, updateChannels]
  )

  const removeUser = useCallback(
    (id: string) => {
      const collectionId = data?.channelCollection?.id
      const nextIds = allIds.filter((currentId: string) => currentId !== id)

      if (collectionId) {
        updateChannels({
          variables: {
            collectionId,
            channelIds: nextIds,
          },
        })
      }
    },
    [allIds, data?.channelCollection?.id, updateChannels]
  )

  const columns: Column<ChannelRowPreviewFragment>[] = useMemo(
    () => [
      {
        accessor: 'displayName',
        Header: 'User',
        width: 300,
        Cell: ({ row, value }) => {
          return (
            <Spacing top={8}>
              <HStack gap={24} alignItems="center">
                <img
                  alt="preview thumb"
                  src={row.original.profileImageUrl ?? ''}
                  width={38}
                  height={38}
                  style={{ borderRadius: '50%' }}
                />
                <div>{value}</div>
              </HStack>
            </Spacing>
          )
        },
      },
      {
        accessor: 'viewCount',
        Header: 'View Count',
        Cell: ({ value }) => {
          return formatNumber(value ?? 0, 1)
        },
      },
      {
        align: 'right',
        accessor: 'id',
        Header: 'Remove',
        Cell: ({ value }) => {
          return (
            <TrashIcon
              height={18}
              width={18}
              fill="#666"
              cursor="pointer"
              onClick={() => removeUser(value)}
            />
          )
        },
      },
    ],
    [removeUser]
  )

  return (
    <>
      <Spacing bottom={24}>
        <HStack justifyContent="space-between" alignItems="center">
          <Text variant="h2">{data?.channelCollection?.title}</Text>
          <div>
            <Button
              size="small"
              onClick={() => setAdding((current) => !current)}
            >
              {adding ? 'Cancel' : 'Add User'}
            </Button>
          </div>
        </HStack>
      </Spacing>
      {adding ? (
        <AddChannel collectionUserIds={allIds} addToCollection={addUser} />
      ) : (
        <Table
          data={data?.channelCollection?.channels.data ?? []}
          columns={columns}
        />
      )}
    </>
  )
  // return (
  //   <div>
  //     <ul>
  //       {data?.channelCollection?.channels.data.map(
  //         ({ id, displayName, profileImageUrl }) => (
  //           <li>{displayName}</li>
  //         )
  //       )}
  //     </ul>
  //   </div>
  // )
}
