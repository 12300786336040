import { CLIENT_TOKEN_KEY } from 'core/auth/constants'
import {
  AuthorizationAction,
  useAuthorizationDispatch,
} from 'core/auth/context'
import { useEffect } from 'react'
import { useHistory } from 'react-router'

export const Logout = () => {
  const history = useHistory()
  const dispatch = useAuthorizationDispatch()

  useEffect(() => {
    localStorage.removeItem(CLIENT_TOKEN_KEY)
    dispatch({
      type: AuthorizationAction.SetAccessToken,
      payload: { accessToken: null, provider: null },
    })

    history.replace('/', null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}
