import { useCallback, useEffect } from 'react'

export const useEscKey = (callback: () => void) => {
  const onPressEsc = useCallback(
    (evt: KeyboardEvent) => {
      if (evt.code === 'Escape') {
        callback()
      }
    },
    [callback]
  )

  useEffect(() => {
    document.addEventListener('keydown', onPressEsc)

    return () => {
      document.removeEventListener('keydown', onPressEsc)
    }
  }, [onPressEsc])
}
