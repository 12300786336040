import { AGENT_THUMB_IMAGES } from 'components/valorant/agent'
import { usePlayerMatchData } from 'core/shared/valorant/hooks'
import { useVideoExplorerState } from 'page/video/context'
import React, { useMemo } from 'react'
import { useTimelineHelpers } from '../hooks'
import {
  AgentSelectContainerDiv,
  AgentSelectImg,
  MatchStatsBreakdownDiv,
} from './styled'

interface AgentSelectTimelineProps {
  wonMatch: boolean
  accountIds: string[]
  matchId: string
  characterSelectLength: number
}

export const AgentSelectTimeline: React.FC<AgentSelectTimelineProps> = ({
  matchId,
  accountIds,
  wonMatch,
}) => {
  const playerMatchData = usePlayerMatchData(matchId, accountIds)
  const { pregameLengthByMatch } = useVideoExplorerState()
  const { getContentWidthFromTime } = useTimelineHelpers()

  const containerStyles = useMemo(
    () => ({
      width: getContentWidthFromTime(
        pregameLengthByMatch[matchId]
          ? pregameLengthByMatch[matchId]
          : 145 * 1000
      ),
    }),
    [getContentWidthFromTime, matchId, pregameLengthByMatch]
  )

  const agentThumbUrl = useMemo(
    () =>
      playerMatchData?.agent ? AGENT_THUMB_IMAGES[playerMatchData?.agent] : '',
    [playerMatchData?.agent]
  )

  return (
    <AgentSelectContainerDiv style={containerStyles} $won={wonMatch}>
      <AgentSelectImg src={agentThumbUrl} alt="agent thumbnail" />
      <MatchStatsBreakdownDiv>
        <div>{playerMatchData?.agent}</div>
        <div>
          {playerMatchData?.stats.kills} - {playerMatchData?.stats.deaths}
        </div>
      </MatchStatsBreakdownDiv>
    </AgentSelectContainerDiv>
  )
}
