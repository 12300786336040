import { useEditorState } from 'core/editor/context'
import _, { sortBy } from 'lodash'
import { useEffect, useMemo, useRef, useState } from 'react'
import { usePrevious } from 'utils'

import {
  ChatMessageFragment,
  DebugChatMomentFragment,
  useGetChannelChatLazyQuery,
  useGetVideoChatMomentsQuery,
} from '__generated__'
import { RelativeChatMessage } from './chat/types'

const PRE_FETCH_CHAT_DURATION = 30000
const PRE_FETCH_NEXT_PAGE_DURATION = 5000

export const useVideoMomentsData = (videoId: string) => {
  const { data } = useGetVideoChatMomentsQuery({ variables: { videoId } })

  return useMemo(() => {
    if (data?.video?.__typename === 'TwitchVideo') {
      const channelId = data.video.user?.id
      const streamStart = data.video.stream?.startedAt
      const streamEnd = data.video.stream?.endedAt
      const allowedChatMomentTypes = data.video.stream?.allowedChatMomentTypes
      const chatMoments = sortBy(
        data.video.moments.data.filter(
          (moment) => moment.__typename === 'ChatMoment'
        ) as DebugChatMomentFragment[],
        (m) => -m.metadata.score
      )

      return {
        channelId,
        streamStart,
        streamEnd,
        allowedChatMomentTypes,
        chatMoments,
      }
    } else {
      return {
        channelId: undefined,
        streamStart: undefined,
        streamEnd: undefined,
        allowedChatMomentTypes: undefined,
        chatMoments: undefined,
      }
    }
  }, [data])
}

const useChatCursorByCurrentTime = (
  pagination?: { hasNextPage: boolean; endCursor: string },
  lastMessageAt?: string
) => {
  const prevPagination = useRef(pagination)
  const { currentTime } = useEditorState()
  const [value, setValue] = useState<string | null>(null)

  useEffect(() => {
    if (pagination === undefined && prevPagination !== undefined) {
      setValue(null)
      return
    }

    if (!lastMessageAt || !pagination) {
      return
    }

    const { endCursor, hasNextPage } = pagination
    const messageAt = new Date(lastMessageAt).getTime()
    const adjustedCurrentTime = currentTime - PRE_FETCH_NEXT_PAGE_DURATION
    if (hasNextPage && adjustedCurrentTime > messageAt && value !== endCursor) {
      setValue(endCursor)
    }
  }, [currentTime, value, pagination, lastMessageAt])

  return value
}

const useChatTimeRange = (videoId: string) => {
  const { streamStart, streamEnd } = useVideoMomentsData(videoId)
  const { currentTime } = useEditorState()
  const previousTime = usePrevious(currentTime) ?? 0
  const absDelta = Math.abs(currentTime - previousTime)

  const [start, setStart] = useState<Date | undefined>(undefined)
  const [end, setEnd] = useState<Date | undefined>(undefined)

  useEffect(() => {
    if (streamStart && start === undefined) {
      setStart(new Date(currentTime - PRE_FETCH_CHAT_DURATION))
    }
    if (streamEnd && end === undefined) {
      setEnd(new Date(streamEnd))
    }
  }, [streamStart, streamEnd, start, end, currentTime])

  useEffect(() => {
    if (absDelta > 1000) {
      setStart(new Date(currentTime - PRE_FETCH_CHAT_DURATION))
    }
  }, [absDelta, setStart, currentTime])

  return [start, end]
}

export const useMomentChatDisplayData = (videoId: string) => {
  const { streamStart, channelId, allowedChatMomentTypes } =
    useVideoMomentsData(videoId)
  const { currentTime } = useEditorState()

  const [fetchChat, { data: chatData, error }] = useGetChannelChatLazyQuery()
  const [chatMessageData, setChatMessageData] = useState<ChatMessageFragment[]>(
    []
  )
  const [pagination, setPagination] = useState<
    { endCursor: string; hasNextPage: boolean } | undefined
  >(undefined)

  const [chatStart, chatEnd] = useChatTimeRange(videoId)
  const prevFetchChatStart = useRef(chatStart)
  const lastChatMessage = chatMessageData[chatMessageData.length - 1]

  const cursor = useChatCursorByCurrentTime(
    pagination,
    lastChatMessage?.timestamp
  )

  useEffect(() => {
    if (!chatStart === undefined || !chatEnd === undefined) {
      return
    }
    if (channelId && !error) {
      const shouldResetChat = prevFetchChatStart.current !== chatStart
      if (shouldResetChat) {
        setChatMessageData([])
        setPagination(undefined)
      }
      const after = !shouldResetChat ? cursor : undefined
      fetchChat({
        variables: {
          providerId: channelId,
          from: chatStart,
          to: chatEnd,
          first: 50,
          after,
        },
      })

      prevFetchChatStart.current = chatStart
    }
  }, [channelId, fetchChat, chatEnd, chatStart, cursor, error])

  useEffect(() => {
    if (chatData) {
      setChatMessageData((state) => {
        let newState = [...state]
        const data = chatData.channel?.chatMessages.data ?? []
        data.forEach((message) => {
          const index = newState.findIndex(({ id }) => id === message.id)
          if (index !== -1) {
            newState[index] = message
          } else {
            newState.push(message)
          }
        })
        return newState
      })
      setPagination(chatData.channel?.chatMessages.pagination)
    }
  }, [chatData])

  return useMemo(() => {
    return {
      allowedChatMomentTypes,
      messages: chatMessageData
        .filter(
          (message): message is RelativeChatMessage =>
            new Date(message.timestamp).getTime() < currentTime
        )
        .map((message) => ({
          ...message,
          relativeTimestamp:
            new Date(message.timestamp).getTime() -
            new Date(streamStart).getTime(),
        })),
    }
  }, [chatMessageData, currentTime, streamStart, allowedChatMomentTypes])
}
