import momentjs from 'moment'
import { useMemo } from 'react'

import { HStack } from 'components/core/layout'
import { Text } from 'components/core/text'
import {
  StyledLink,
  VideoPreview,
  VideoPreviewImage,
  VideoTitleWrapper,
} from './styled'

type Props = {
  id: string
  title: string
  publishedAt: string
  thumbnailUrl?: string
  url?: string
}
export const VideoCard = ({ title, thumbnailUrl, publishedAt, url }: Props) => {
  const thumbnail = useMemo(() => {
    if (!thumbnailUrl) return undefined

    return thumbnailUrl.replace('%{width}', '512').replace('%{height}', '288')
  }, [thumbnailUrl])
  return (
    <div>
      <StyledLink to={url ?? ''}>
        <VideoPreview>
          <VideoPreviewImage backgroundImageUrl={thumbnail || ''} />
        </VideoPreview>
        <VideoTitleWrapper>
          <Text variant={'text-1'} weight={'semibold'} as="p">
            {title}
          </Text>
        </VideoTitleWrapper>
        <HStack gap={8} alignItems={'center'}>
          <Text variant={'text-3'}>
            {momentjs(publishedAt).format('MMMM Do, YYYY')}
          </Text>
          <Text variant="small">({momentjs(publishedAt || '').fromNow()})</Text>
        </HStack>
      </StyledLink>
    </div>
  )
}
