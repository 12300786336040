import { useTheme } from 'styled-components/macro'

import { Container, Content, HStack, Spacing } from 'components/core/layout'
import { Text } from 'components/core/text'
import {
  CollectionContextProvider,
  useCollectionContext,
} from 'core/collections/collections-context-provider'
import { SidebarView } from '../sidebar'
import { TitleSettings } from './title-settings'
import { PublicSettings } from './public-settings'
import { DeleteSettings } from './delete-settings'

export const CollectionSettingsPage = () => {
  return (
    <CollectionContextProvider>
      <Content background="primary">
        <Container direction="row" gap={16}>
          <SidebarView />
          <SettingsView />
        </Container>
      </Content>
    </CollectionContextProvider>
  )
}

const SettingsView = () => {
  const theme = useTheme()
  const { selected: collection } = useCollectionContext()
  return (
    <>
      {collection ? (
        <Container flex="1">
          <Spacing top={8} bottom={16}>
            <HStack alignItems="baseline" gap={8}>
              <Text
                variant="h4"
                weight="semibold"
                color={theme.colors.neutrals.n3}
              >
                {collection.title}
              </Text>
              <Text
                variant="h6"
                weight="semibold"
                color={theme.colors.neutrals.n4}
              >
                Settings
              </Text>
            </HStack>

            <Spacing top={32}>
              <Container gap={32}>
                <TitleSettings />
                <PublicSettings />
                <DeleteSettings />
              </Container>
            </Spacing>
          </Spacing>
        </Container>
      ) : null}
    </>
  )
}
