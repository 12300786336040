import styled, { css } from 'styled-components/macro'
import { colors } from 'utils/colors'

export const TimelineDetailContainerDiv = styled.div`
  position: relative;
  left: 0;
  top: 0;
  height: calc(100% - 40px);
  width: 100%;
  overflow: hidden;
  // this is for the player options
`

export const TimelineContainerDiv = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

export const TimelineDetailContentsDiv = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  user-select: none;
`

export const ScrollableThumbContainerDiv = styled.div`
  position: relative;
  grid-area: video-timeline;
  background-color: #eee;
  width: 100%;
  height: 40px;
`

export const ScrollableThumbDiv = styled.div<{ $dragging: boolean }>`
  position: absolute;
  left: 0;
  width: 5%;
  height: 100%;
  border: 1px solid #333;
  background-color: #ccc;
  border-radius: 4px;
  /* border-width: 0 1px; */

  ${(p) =>
    p.$dragging
      ? css`
          cursor: grabbing;
        `
      : css`
          cursor: grab;
        `}
`

export const SelectionAreaContainerDiv = styled.div`
  position: relative;
  margin-top: 60px;
  height: 38px;
  width: 100%;
  background-color: ${colors.neutral.light};
`

export const SceneIndicatorDiv = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  background-color: #ccc;
  border: 1px solid ${colors.neutral.dark};
  border-width: 0 1px;
`

export const SceneDragDiv = styled.div<{ $type: 'left' | 'right' }>`
  pointer-events: all;
  position: absolute;
  top: calc(50% - 8px);
  width: 10px;
  height: 16px;
  background-color: #6f6f6f;
  cursor: col-resize;

  ${(p) =>
    p.$type === 'left'
      ? css`
          left: -6px;
        `
      : css`
          right: -6px;
        `}
`

export const SelectionPlayerContainerDiv = styled.div`
  position: absolute;
  top: 16px;
  left: 50%;
  width: 114px;
  height: 40px;
  padding-left: 4px;
  background-color: #ccc;
  transform: translateX(-50%);
  display: flex;
  justify-content: left;
  align-items: center;
  border-radius: 4px;
`

export const PlayerButtonDiv = styled.div<{ $disabled?: boolean }>`
  position: relative;
  padding: 8px;
  height: 32px;
  width: 32px;
  background-color: #eee;
  border-radius: 4px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  margin-right: 8px;

  /* &:hover {
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);

  } */

  & > svg {
    ${(p) =>
      p.$disabled
        ? css`
            fill: #efefef;
          `
        : ``}
    fill: #333;
    width: 100%;
    height: 100%;
  }
`

export const SelectionPositioningDiv = styled.div`
  position: absolute;
  height: 60px;
`

export const CurrentTimeDiv = styled.div`
  position: absolute;
  top: 98px;
  height: calc(100% - 98px);
  width: 1px;
  background-color: red;
`

export const CurrentSelectionDisplayDiv = styled.div`
  position: absolute;
  top: 98px;
  height: calc(100% - 98px);
  background-color: rgba(0, 0, 0, 0.05);
  border: 1px dashed #333;
  border-width: 0 1px;
  pointer-events: none;
`
