import React, { useCallback } from 'react'

interface Props {
  collectionId: string
  setCollectionId: (nextCollectionId: string) => void
}

export const VALORANT_TEAM_COLLECTIONS = [
  { title: 'Loaded', id: 'f8167cd5-8bac-46e2-b414-877984b514c5' },
  { title: 'TSM', id: '9d5fbf1b-a6c3-4c28-9b4c-215a3507b7c2' },
  { title: 'Sentinels', id: 'd2a420b8-0ddb-4605-b56a-d39db8365819' },
  { title: 'Envy', id: 'fe6f9990-69a7-4f0c-960c-2983283cd4ec' },
  { title: '100T', id: '516db646-9091-410e-a0db-0abfde3dc54e' },
  { title: 'Cloud9', id: '6e530f11-cd0a-4457-ab81-3da88ccc5c6b' },
  { title: 'XSET', id: '94f23c32-2ae3-4c58-b2b4-ed4f06056b76' },
  { title: 'Rise', id: '06449208-d6c0-4e6b-be61-825429fb32fa' },
  { title: 'Version1', id: 'dae10ead-1aef-4a72-ae61-74dd73f38d6c' },
  { title: 'Gen.G', id: 'db232829-10df-4aed-9dc3-e9798b7b75b1' },
  { title: 'Luminosity', id: '2c36b82d-97a5-4ee8-8a7e-63c710b452c2' },
  { title: 'NRG', id: 'b33b38c6-631a-4e7b-9219-5c8b3ed3fd5a' },
  { title: 'T1', id: 'db00212e-8fc3-4381-a2d5-e42db9073fe9' },
  { title: 'Evil Geniuses', id: 'd2bf0ba3-22a0-4fc0-9d98-30efab102bb1' },
]

export const ValorantCollectionFilter = ({
  collectionId,
  setCollectionId,
}: Props) => {
  const onCollectionChange = useCallback(
    (evt: React.ChangeEvent<HTMLSelectElement>) => {
      setCollectionId(evt.currentTarget.value)
    },
    [setCollectionId]
  )

  return (
    <select value={collectionId} onChange={onCollectionChange}>
      {VALORANT_TEAM_COLLECTIONS.map(({ id, title }) => (
        <option key={id} value={id}>
          {title}
        </option>
      ))}
    </select>
  )
}
