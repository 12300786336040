import React from 'react'
import { useParams } from 'react-router'

import { VideoReview } from 'core/review'
import { EditorProvider } from 'core/editor/context'
import { TimelineProvider } from 'core/editor/timeline/context'

export const VideoReviewPage: React.FC = () => {
  const { videoId } = useParams<{ videoId: string }>()
  return (
    <EditorProvider>
      <TimelineProvider>
        <VideoReview videoId={videoId} />
      </TimelineProvider>
    </EditorProvider>
  )
}
