import { useCallback, useMemo } from 'react'
import { css, useTheme } from 'styled-components/macro'
import styled from 'styled-components/macro'

import { Button } from 'components/core/button'
import { Container, VStack } from 'components/core/layout'
import { Text } from 'components/core/text'
import { CustomCheckbox } from 'core/valorant-match/editor/exporting-modal'
import { useQuickActions } from './hooks'
import { transparentize } from 'polished'

interface Props {
  showEdits?: boolean
  onCreate?: () => void
}
export const QuickExports = ({ onCreate }: Props) => {
  const theme = useTheme()
  const {
    edits,
    closeModal,
    videoExports,
    toggleQuickExportVideo,
    createEditsAndExports,
  } = useQuickActions()

  const editTitles = useMemo(() => edits?.map(({ title }) => title), [edits])

  const handleCreate = useCallback(async () => {
    await createEditsAndExports()
    if (onCreate) {
      onCreate()
    }
    closeModal()
  }, [closeModal, createEditsAndExports, onCreate])

  return (
    <ContainerDiv>
      <Container
        gap={16}
        direction="column"
        justifyContent="space-between"
        grow={1}
      >
        <VStack>
          {videoExports?.map(({ selected, videoId, displayName, offset }) => {
            const created = (editTitles ?? []).includes(
              `${displayName} Perspective`
            )
            return (
              <OptionContainerDiv
                key={videoId}
                $created={created}
                onClick={() => !created && toggleQuickExportVideo(videoId)}
              >
                <CustomCheckbox checked={selected || created}>
                  <Text variant="text-3">{displayName}</Text>
                </CustomCheckbox>
                <Text variant="small" color={theme.colors.green}>
                  {offset?.toFixed(2)}
                </Text>
              </OptionContainerDiv>
            )
          })}
        </VStack>
        <Button size="small" onClick={handleCreate}>
          Create Exports
        </Button>
      </Container>
    </ContainerDiv>
  )
}

const ContainerDiv = styled.div`
  width: 340px;
  padding: 34px;
  height: 100%;
  border-width: 1px 1px 0;
`

const OptionContainerDiv = styled.div<{ $created: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid
    ${({ theme }) => transparentize(0.75, theme.colors.neutrals.n5)};
  border-top: 0;
  cursor: pointer;
  padding: 8px 8px;

  ${({ $created }) =>
    $created &&
    css`
      opacity: 0.6;
      pointer-events: none;
    `}

  &:first-child {
    border-top: 1px solid
      ${({ theme }) => transparentize(0.75, theme.colors.neutrals.n5)};
  }

  &:hover {
    background-color: ${({ theme }) =>
      transparentize(0.85, theme.colors.neutrals.n5)};
  }
`
