import React from 'react'

import { ChatMomentContentDiv, ChatMomentsContainerDiv } from '../styled'
import { useValorantMatchDataForVideo } from './hooks'
import { ValorantMatchPreview } from './match-preview'

interface ValorantSidebarProps {
  videoId: string
}

export const ValorantSidebar: React.FC<ValorantSidebarProps> = ({
  videoId,
}) => {
  const { matchData } = useValorantMatchDataForVideo(videoId)
  return (
    <ChatMomentsContainerDiv>
      <h2>Valorant Matches</h2>
      <ChatMomentContentDiv>
        {matchData.map((md) => (
          <ValorantMatchPreview key={md.id} matchId={md.id} videoId={videoId} />
        ))}
      </ChatMomentContentDiv>
    </ChatMomentsContainerDiv>
  )
}
