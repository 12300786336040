import React, { createContext, useContext, useEffect, useReducer } from 'react'
import { ThemeProvider } from 'styled-components/macro'
import { GlobalStyleSheet } from './global'
import { darkTheme, lightTheme } from './theme'

const THEME_LOCAL_STORAGE_KEY = 'theme_mode'

type Theme = 'dark' | 'light'

type AppThemeState = {
  theme: Theme | null
}

const INITIAL_STATE: AppThemeState = {
  theme: null,
}

enum ACTION_TYPES {
  SET_THEME = 'SET_THEME',
  SET_LOCAL_THEME = 'SET_LOCAL_THEME',
  TOGGLE_THEME = 'TOGGLE_THEME',
}

type SetThemeAction = {
  type: ACTION_TYPES.SET_THEME
  payload: {
    theme: Theme
  }
}

type SetLocalThemeAction = {
  type: ACTION_TYPES.SET_LOCAL_THEME
  payload: {
    theme: Theme
  }
}

type ToggleThemeAction = {
  type: ACTION_TYPES.TOGGLE_THEME
}

type AppThemeActions = SetThemeAction | ToggleThemeAction | SetLocalThemeAction

const storeTheme = (theme: Theme) => {
  window.localStorage.setItem(THEME_LOCAL_STORAGE_KEY, theme)
}

const reducer = (
  state: AppThemeState,
  action: AppThemeActions
): AppThemeState => {
  switch (action.type) {
    case ACTION_TYPES.SET_THEME: {
      const {
        payload: { theme },
      } = action
      storeTheme(theme)
      return {
        ...state,
        theme,
      }
    }
    case ACTION_TYPES.SET_LOCAL_THEME: {
      const {
        payload: { theme },
      } = action
      return {
        ...state,
        theme,
      }
    }
    case ACTION_TYPES.TOGGLE_THEME: {
      const theme = state.theme === 'light' ? 'dark' : 'light'
      storeTheme(theme)
      return {
        ...state,
        theme,
      }
    }
  }
}

type Dispatch = (action: AppThemeActions) => void
const AppThemeContext = createContext<
  (AppThemeState & { dispatch: Dispatch }) | undefined
>(undefined)

// eslint-disable-next-line react-hooks/exhaustive-deps
const useMountEffect = (fn: () => void) => useEffect(fn, [])

const AppThemeProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE)
  const { theme } = state

  useMountEffect(() => {
    if (!theme) {
      const localTheme = window.localStorage.getItem(THEME_LOCAL_STORAGE_KEY)
      if (localTheme as Theme) {
        dispatch({
          type: ACTION_TYPES.SET_THEME,
          payload: { theme: localTheme as Theme },
        })
      }
    }
  })

  return (
    <AppThemeContext.Provider value={{ ...state, dispatch }}>
      <ThemeProvider theme={theme !== 'dark' ? lightTheme : darkTheme}>
        <GlobalStyleSheet />
        {children}
      </ThemeProvider>
    </AppThemeContext.Provider>
  )
}

const useAppThemeContext = () => {
  const context = useContext(AppThemeContext)
  if (context === undefined) {
    throw new Error('useChannel must be used within a AppThemeProvider')
  }
  return context
}

useAppThemeContext.ACTION_TYPES = ACTION_TYPES

export { AppThemeProvider, useAppThemeContext }
