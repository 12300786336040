import styled from 'styled-components/macro'

export const VideoReviewPageDiv = styled.div`
  position: relative;
  display: grid;
  height: 100vh;
  width: 100%;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-template-columns: 300px 1fr 340px;
  grid-template-rows: 1fr 220px;
  grid-template-areas:
    'review-sidebar review-content review-chat'
    'review-sidebar review-timeline review-chat';
`

export const VideoReviewSidebarDiv = styled.div`
  position: relative;
  grid-area: review-sidebar;
  overflow: hidden;
`

export const ChatMomentFilterDiv = styled.div`
  position: relative;
  height: 52px;
  width: 100%;
  padding: 16px 16px;
`

export const VideoReviewContentDiv = styled.div`
  position: relative;
  grid-area: review-content;
`

export const VideoReviewTimelineDiv = styled.div`
  position: relative;
  display: flex;
  grid-area: review-timeline;
  padding-bottom: 20px;
`

export const VideoReviewChatDiv = styled.div`
  position: relative;
  grid-area: review-chat;
`

export const VideoReviewChatWrapper = styled.div`
  overflow-y: scroll;
  max-height: 100%;
  min-height: 100%;
  padding-bottom: 24px;

  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  &::-webkit-scrollbar-thumb {
    background: linear-gradient(45deg, #999, #888);
  }
`
