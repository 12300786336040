import { useCallback, useMemo } from 'react'

import { BrowsingMode, useVideoExplorerUpdater } from './context'

export const useVideoExplorerHelpers = () => {
  const dispatch = useVideoExplorerUpdater()

  const setBrowsingMode = useCallback(
    (browsingMode: BrowsingMode) => {
      dispatch({
        type: useVideoExplorerUpdater.ACTION_TYPES.SET_BROWSING_MODE,
        payload: {
          browsingMode,
        },
      })
    },
    [dispatch]
  )

  return useMemo(
    () => ({
      setBrowsingMode,
    }),
    [setBrowsingMode]
  )
}
