import styled, { useTheme } from 'styled-components/macro'

import { Spacing, VStack } from 'components/core/layout'
import { Text } from 'components/core/text'

import { ProfileImage, ProfileWrapper } from '../styled'
import { Link } from 'react-router-dom'

import { ReactComponent as NoImage } from 'icons/svgs/no-image/no-image.svg'

type Props = {
  name: string
  organization?: { name: string; slug: string }
  imageUrl?: string
}
export const ProfileItem = ({ name, organization, imageUrl }: Props) => {
  const theme = useTheme()
  return (
    <>
      <ProfileWrapper>
        <VStack>
          <Text
            color={theme.colors.text.secondary}
            variant="text-2"
            weight="regular"
          >
            {name}
          </Text>
          {organization && (
            <StyledLink to={`/o/${organization?.slug}`}>
              <Text mono variant="text-3" weight="regular" textAlign="right">
                {organization?.name}
              </Text>
            </StyledLink>
          )}
        </VStack>
        <Spacing left={16}>
          {imageUrl ? <ProfileImage src={imageUrl} /> : <NoImage />}
        </Spacing>
      </ProfileWrapper>
    </>
  )
}

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.text.tertiary};
  transition: color 0.2s ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.colors.blue};
  }
`
