import React, { useEffect, useMemo, useState } from 'react'
import momentjs from 'moment'

import { ChatMomentFragment, useGetEditingVideoQuery } from '__generated__'
import { Editor } from 'core/editor'
import { useTimelineUpdater } from 'core/editor/timeline/context'
import { Timeline } from 'core/editor/timeline'
import { SyncedVideo } from 'core/editor/synced-video'
import { AspectRatioContainer } from 'components/aspect-ratio/container'
import { StreamerAvatarWithVideo } from 'components/streamer-avatar'
import { TimelineZoom } from 'core/editor/timeline/zoom'
import { useEditorState } from 'core/editor/context'

import { VideoSidebar } from './sidebar'
import { useVideoExplorerUpdater } from '../context'
import { BrowsingTimelineDetails } from './timeline/browsing-details'
import {
  ActiveDuoDiv,
  EditorSideMenuDiv,
  PreviewPlayerContainerDiv,
  PreviewPlayerDiv,
  StreamerAvatarDiv,
  TimelineLegendDiv,
  VideoEditorContentsDiv,
  VideoEditorDiv,
  VideoEditorPageDiv,
  VideoPlayerSectionDiv,
  VideoTimelineContainerDiv,
  VideoTimelineZoomContainerDiv,
} from './styled'

interface VideoEditorProps {
  videoId: string
}

export const VideoEditor: React.FC<VideoEditorProps> = ({ videoId }) => {
  const { currentVideoIds, activePlayerData, previewVideosData, chatMoments } =
    useGetVideoEditorData(videoId)

  const allVideoIds = useMemo(
    () => currentVideoIds.map((vid) => vid.videoId),
    [currentVideoIds]
  )

  return (
    <Editor>
      <VideoEditorPageDiv>
        <EditorSideMenuDiv>
          <VideoSidebar chatMoments={chatMoments} videoId={videoId} />
        </EditorSideMenuDiv>
        <VideoEditorContentsDiv>
          <VideoPlayerSectionDiv>
            <AspectRatioContainer width={20} height={9}>
              <VideoEditorDiv>
                <ActiveDuoDiv>
                  <AspectRatioContainer width={16} height={9}>
                    <SyncedVideo
                      {...activePlayerData}
                      primaryVideoId={videoId}
                    />
                  </AspectRatioContainer>
                </ActiveDuoDiv>
                <PreviewPlayerContainerDiv>
                  {previewVideosData.map((vidData) => (
                    <PreviewPlayerDiv
                      key={vidData.id}
                      onClick={vidData.onClick}
                      $active={vidData.isActive}
                    >
                      <AspectRatioContainer width={16} height={9}>
                        <SyncedVideo
                          id={vidData.id}
                          videoId={vidData.videoId}
                          primaryVideoId={videoId}
                          offset={vidData.offset}
                          controller={vidData.videoId === videoId}
                        />
                      </AspectRatioContainer>
                    </PreviewPlayerDiv>
                  ))}
                </PreviewPlayerContainerDiv>
              </VideoEditorDiv>
            </AspectRatioContainer>
          </VideoPlayerSectionDiv>
          <VideoTimelineContainerDiv>
            <TimelineLegendDiv>
              {currentVideoIds.map((vid) => (
                <StreamerAvatarDiv key={vid.videoId}>
                  <StreamerAvatarWithVideo
                    videoId={vid.videoId}
                    width={50}
                    height={50}
                  />
                </StreamerAvatarDiv>
              ))}
              <VideoTimelineZoomContainerDiv>
                <TimelineZoom />
              </VideoTimelineZoomContainerDiv>
            </TimelineLegendDiv>
            <Timeline>
              <BrowsingTimelineDetails videoIds={allVideoIds} />
            </Timeline>
          </VideoTimelineContainerDiv>
        </VideoEditorContentsDiv>
      </VideoEditorPageDiv>
    </Editor>
  )
}

const useGetVideoEditorData = (videoId: string) => {
  const [activeVideoId, setActiveVideoId] = useState(videoId)
  const videoExplorerDispatch = useVideoExplorerUpdater()
  const timelineDispatch = useTimelineUpdater()
  const { selection } = useEditorState()
  const { data, loading } = useGetEditingVideoQuery({
    variables: { id: videoId },
    skip: videoId === undefined,
  })

  const startTime = momentjs(data?.video?.stream?.startedAt).valueOf()
  const endTime = momentjs(data?.video?.stream?.endedAt).valueOf()

  const { chatMoments } = useMemo(() => {
    const chatMomentData = (data?.video?.moments.data.filter(
      ({ __typename }) => __typename === 'ChatMoment'
    ) ?? []) as ChatMomentFragment[]
    // const multiMomentData = data?.video?.moments.data.filter(({__typename}) => __typename === 'MultiPerspectiveMoment')
    return { chatMoments: chatMomentData }
  }, [data])

  const relatedVideoData = useMemo(() => {
    return (
      data?.video?.relatedVideos.map((rv) => {
        return {
          videoId: rv.video.id,
          offset: rv.offset ?? 0,
          overlaps: rv.overlaps.map((overlap) => ({
            from: momentjs(overlap.from).valueOf(),
            to: momentjs(overlap.to).valueOf(),
          })),
        }
      }) ?? []
    )
  }, [data?.video?.relatedVideos])

  // find all videoids that are related in a given selection
  const selectionVideos = useMemo(() => {
    if (selection === undefined) return []
    return relatedVideoData
      .filter((rv) =>
        rv.overlaps.some(
          (overlap) =>
            overlap.from < selection.startTime && overlap.to > selection.endTime
        )
      )
      .map((vidData) => ({ videoId: vidData.videoId, offset: vidData.offset }))
  }, [relatedVideoData, selection])

  useEffect(() => {
    timelineDispatch({
      type: useTimelineUpdater.ACTION_TYPES.SET_TIMES,
      payload: {
        startTime,
        endTime,
      },
    })
  }, [startTime, endTime, timelineDispatch])

  const activePlayerData = useMemo(
    () => ({
      id: `active_${activeVideoId}`,
      videoId: activeVideoId,
      playerOptions: { muted: false },
    }),
    [activeVideoId]
  )

  const currentVideoIds = useMemo(
    () => [{ videoId: videoId, offset: 0 }, ...selectionVideos],
    [selectionVideos, videoId]
  )

  const previewVideosData = useMemo(
    () =>
      currentVideoIds.map((vid) => {
        const onClick = () => {
          setActiveVideoId(vid.videoId)
        }
        return {
          id: `preview_${vid}`,
          videoId: vid.videoId,
          offset: vid.offset,
          onClick,
          isActive: activeVideoId === vid.videoId,
        }
      }),
    [activeVideoId, currentVideoIds]
  )

  useEffect(() => {
    videoExplorerDispatch({
      type: useVideoExplorerUpdater.ACTION_TYPES.SET_VIDEO_ID,
      payload: {
        videoId,
      },
    })
  }, [videoExplorerDispatch, videoId])

  return useMemo(
    () => ({
      loading,
      currentVideoIds,
      activePlayerData,
      previewVideosData,
      relatedVideoData,
      chatMoments,
    }),
    [
      activePlayerData,
      currentVideoIds,
      loading,
      previewVideosData,
      relatedVideoData,
      chatMoments,
    ]
  )
}
