import { useCallback, useEffect, useMemo, useState } from 'react'
import { get, groupBy, sortBy, sum, uniqBy } from 'lodash'

import {
  GetMomentForViewerDocument,
  PreviewEditFragment,
  PreviewMomentFragment,
  ReactionDetailFragment,
  ReactionDetailFragmentDoc,
  useGetMomentForViewerQuery,
  useSetMomentRatingMutation,
  useSetMomentReadMutation,
} from '__generated__'
import {
  IG_SQUARE_FORMAT,
  STANDARD_FORMAT,
  TIKTOK_FORMAT,
} from 'core/exporter/constants'
import { useMomentViewerUpdater } from './context'
import { useHistory } from 'react-router'
import {
  translateRatingEnum,
  translateRatingInt,
} from 'core/signal-review/helpers'
import { useAuth0 } from '@auth0/auth0-react'

export const useMomentViewerData = (
  momentId: string,
  selectedEditId: string | undefined,
  includeUnapproved: boolean = true
) => {
  const { user } = useAuth0()
  const { data } = useGetMomentForViewerQuery({
    variables: { momentId },
  })
  const setRatingMutation = useSetMomentRating(momentId)
  const [rating, setRating] = useState<number>()
  const setRead = useSetMomentToRead(momentId)

  const momentData: PreviewMomentFragment | undefined = useMemo(() => {
    if (data?.moment?.__typename === 'MultiPerspectiveMoment') {
      return data.moment
    }
  }, [data?.moment])

  const signals = useMemo(() => momentData?.signals, [momentData?.signals])

  const mainChannelData = useMemo(() => {
    return momentData?.channel
  }, [momentData?.channel])

  const edits: PreviewEditFragment[] | undefined = useMemo(() => {
    if (data?.moment?.__typename === 'MultiPerspectiveMoment') {
      return data.moment.edits.filter((edit) => {
        if (edit.exports.length === 0) return false
        if (!includeUnapproved && !edit.approved) return false
        return true
      })
    }
  }, [data?.moment, includeUnapproved])

  const editsByFormat = useMemo(() => {
    const uniqueFormats = sortBy(
      uniqBy(
        (edits ?? []).map(({ format }) => format),
        ({ id }) => id
      ),
      (format) => {
        switch (format.id) {
          case STANDARD_FORMAT.id:
            return 1
          case TIKTOK_FORMAT.id:
            return 2
          case IG_SQUARE_FORMAT.id:
            return 3
          default:
            return 4
        }
      }
    )

    const editsByFormatId = groupBy(edits, ({ format }) => format.id)
    return uniqueFormats.map((format) => {
      return {
        format,
        edits: editsByFormatId[format.id],
      }
    })
  }, [edits])

  const defaultEdit = useMemo(() => {
    // maybe there is a reason to not aim for standard format eventually, and have some default priority
    for (const { format, edits } of editsByFormat) {
      if (format.id === STANDARD_FORMAT.id) {
        return sortBy(
          edits,
          ({ publishedAt }) => -new Date(publishedAt).getTime()
        )[0]
      }
    }
  }, [editsByFormat])

  const defaultExport = useMemo(() => {
    if (!defaultEdit) return
    return defaultEdit.exports[0]
  }, [defaultEdit])

  const defaultImg = useMemo(() => {
    return get(defaultExport, ['output', 'thumbnails', 0, 'url'], '')
  }, [defaultExport])

  const selectedExportData = useMemo(() => {
    if (!edits) return
    for (const edit of edits) {
      if (edit.id === selectedEditId) {
        return {
          videoUrl: get(edit, ['exports', 0, 'output', 'videos', 0, 'url'], ''),
          thumbnailUrl: get(edit, [
            'exports',
            '0',
            'output',
            'thumbnails',
            0,
            'url',
          ]),
          aspectRatio: edit.format.aspectRatio,
        }
      }
    }
  }, [edits, selectedEditId])

  const containerStyles = useMemo(
    () => ({ justifyContent: 'center', alignItems: 'flex-start' }),
    []
  )

  const videoData = useMemo(() => {
    return momentData?.videos.map((vid) => ({
      displayName: vid.user?.displayName ?? '',
      avatarUrl: vid.user?.profileImageUrl ?? '',
    }))
    // momentData.
  }, [momentData?.videos])

  useEffect(() => {
    if (momentData?.isUnread === true) {
      setRead()
    }
  }, [momentData?.isUnread, setRead])

  const { myRating, avgRating } = useMemo(() => {
    const myRating = momentData?.ratings.find(
      (rating) => rating.user.id === user?.sub
    )?.rating

    const allRatings = momentData?.ratings.map((rating) =>
      translateRatingEnum(rating.rating)
    )
    const avgRating = allRatings
      ? sum(allRatings) / allRatings.length
      : undefined

    return {
      myRating: myRating ? translateRatingEnum(myRating) : undefined,
      avgRating,
    }
  }, [momentData?.ratings, user?.sub])

  const rateMoment = useCallback(
    (nextRating: number | undefined) => {
      setRating(nextRating)
      setRatingMutation(nextRating)
    },
    [setRatingMutation]
  )

  // // using useState as a temp solution for proper caching
  useEffect(() => {
    setRating(myRating)
  }, [myRating])

  return useMemo(
    () => ({
      mainChannelData,
      momentData,
      edits,
      editsByFormat,
      defaultExport,
      defaultEdit,
      defaultImg,
      selectedExportData,
      containerStyles,
      rating,
      rateMoment,
      avgRating,
      videoData,
      signals,
    }),
    [
      avgRating,
      containerStyles,
      defaultEdit,
      defaultExport,
      defaultImg,
      edits,
      editsByFormat,
      mainChannelData,
      momentData,
      rateMoment,
      rating,
      selectedExportData,
      videoData,
      signals,
    ]
  )
}

export const useMomentViewerHelpers = () => {
  const dispatch = useMomentViewerUpdater()

  const setSelectedMomentId = useCallback(
    (momentId: string) => {
      dispatch({
        type: useMomentViewerUpdater.ACTION_TYPES.SET_SELECTED_MOMENT_ID,
        payload: {
          momentId,
        },
      })
    },
    [dispatch]
  )

  const setSelectedEditId = useCallback(
    (editId: string) => {
      dispatch({
        type: useMomentViewerUpdater.ACTION_TYPES.SET_SELECTED_EDIT_ID,
        payload: {
          editId,
        },
      })
    },
    [dispatch]
  )

  return useMemo(
    () => ({ setSelectedMomentId, setSelectedEditId }),
    [setSelectedEditId, setSelectedMomentId]
  )
}

export const useSetMomentRating = (momentId: string) => {
  const [mutation] = useSetMomentRatingMutation()
  return useCallback(
    (rating: number | undefined) => {
      mutation({
        variables: {
          rating: translateRatingInt(rating),
          momentId,
        },
        refetchQueries: [
          {
            query: GetMomentForViewerDocument,
            variables: {
              momentId,
            },
          },
        ],
      })
    },
    [momentId, mutation]
  )
}

export const useSetMomentToRead = (momentId: string) => {
  const [mutation] = useSetMomentReadMutation()

  return useCallback(() => {
    mutation({
      variables: { momentId },
      update: (cache, { errors }) => {
        if (errors) return
        const fragmentData: ReactionDetailFragment | null = cache.readFragment({
          id: `ChatMoment:${momentId}`,
          fragmentName: 'ReactionDetail',
          fragment: ReactionDetailFragmentDoc,
        })
        if (fragmentData) {
          cache.writeFragment({
            id: `ChatMoment:${momentId}`,
            fragmentName: 'ReactionDetail',
            fragment: ReactionDetailFragmentDoc,
            data: {
              ...fragmentData,
              isUnread: false,
            },
          })
        }
      },
    })
  }, [momentId, mutation])
}

export const useIsPublicView = () => {
  const history = useHistory()
  return useMemo(() => {
    return history.location.pathname.match(/^\/p\//) !== null
  }, [history])
}
