import { MomentChatDisplay } from 'core/review/chat-display'
import styled from 'styled-components/macro'

export const SignalReviewContainerDiv = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-rows: 92px 1fr;
  grid-template-columns: 300px 1fr 100px;
  grid-template-areas:
    'playlist controls  close'
    'subpage  subpage   subpage';
  background-color: black;
`

export const ValorantMatchOverviewDiv = styled.div`
  position: relative;
  display: grid;
  grid-area: subpage;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 280px;
  grid-template-areas: 'moment-player meta-data';
`

export const ControlsDiv = styled.div`
  position: relative;
  grid-area: controls;
`

export const CloseDiv = styled.div`
  grid-area: close;
`

export const PlayerDiv = styled.div`
  position: relative;
  grid-area: moment-player;
  overflow: hidden;
  border-top: 1px solid ${({ theme }) => theme.colors.neutrals.n5};
`

export const MetadataSidebarDiv = styled.div`
  position: relative;
  grid-area: meta-data;
  border: 1px solid ${({ theme }) => theme.colors.neutrals.n5};
  border-width: 1px 0 0 1px;
  background-color: ${({ theme }) => theme.colors.neutrals.n7};
`

export const RelatedVideoControlsDiv = styled.div`
  position: relative;
  margin: 12px;
`

export const SelectedRelatedOptionsDiv = styled.div`
  position: relative;
  width: 100%;
  height: 40px;
  border: 1px solid ${({ theme }) => theme.colors.neutrals.n5};
  border-radius: 4px;
  padding: 12px;
  cursor: pointer;
`

export const RelatedOptionsContainerDiv = styled.div`
  position: absolute;
  bottom: 100%;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.neutrals.n5};
  border-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
`

export const SingleRelatedVideoOptionDiv = styled.div`
  position: relative;
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  cursor: pointer;
`

export const RelatedSyncDiv = styled.div`
  position: relative;
  color: ${({ theme }) => theme.colors.green};
`

export const PlayerPerspectiveOptionsDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
`

export const StyledMomentChatDisplay = styled(MomentChatDisplay)`
  height: 1px;
  flex-grow: 1;
  min-height: initial;
`

export const ExportViewLoadingContainerDiv = styled.div`
  position: relative;
  grid-area: subpage;
  width: 100%;
  height: 100%;
`

export const ExportViewContainerDiv = styled.div`
  position: relative;
  grid-area: subpage;
  display: grid;
  width: 100%;
  height: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors.neutrals.n5};
  grid-template-columns: 280px 1fr;
  grid-template-rows: 1fr 60px;
  column-gap: 12px;
  padding: 12px;
  grid-template-areas:
    'export-sidebar export-preview'
    'export-sidebar  export-options';
`

export const CreateButtonDiv = styled.div`
  width: 100%;
  height: 40px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;

  & > button {
    width: 100%;
  }
`

export const ExportViewSidebarDiv = styled.div`
  position: relative;
  grid-area: export-sidebar;

  & > div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
  }
`

export const ExportViewCreateDiv = styled.div`
  grid-area: create-export;
  display: flex;
  align-items: center;
  justify-content: space-around;
`

export const ExportViewOptionsDiv = styled.div`
  grid-area: export-options;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const VideoPreviewContainerDiv = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  grid-area: export-preview;

  & > video {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100%;
  }
`
