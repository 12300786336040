import { useCallback, useEffect, useMemo, useRef } from 'react'
import momentjs from 'moment'

import { useGetVideoPlayerDataQuery } from '__generated__'

import {
  SyncedVideoStatus,
  SyncStatus,
  useEditorUpdater,
  useEditorState,
} from '../context'
import { useEditor, useEditorOffsets } from '../hooks'
import { useDimensions } from 'utils/browser'
import { CUSTOM_SOURCE, selectResolution } from './helpers'
import { VideoJsPlayer } from 'video.js'

export const useSyncedVideo = (
  key: string,
  videoId: string,
  primaryVideoId: string,
  videoJsPlayer: React.MutableRefObject<VideoJsPlayer>,
  controller = false, // controllers set time if primary video
  qualityHeight: number | undefined,
  offset: number | undefined,
  ready: boolean
) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const { data } = useGetVideoPlayerDataQuery({ variables: { videoId } })
  const [containerRef, containerRect] = useDimensions()

  const { seekToCurrentTime, setPlayWhenReady } = useEditor()
  const { videoStatuses, targetTime, playing } = useEditorState()
  const dispatch = useEditorUpdater()
  const offsets = useEditorOffsets()

  const videoStatus: SyncedVideoStatus | undefined = useMemo(
    () => videoStatuses[key],
    [videoStatuses, key]
  )

  const videoUrl = useMemo(() => {
    let vodSource:
      | {
          resolution: string
          url: string
        }[]
      | undefined = undefined
    if (CUSTOM_SOURCE[videoId]) {
      vodSource = CUSTOM_SOURCE[videoId]
    } else {
      vodSource = data?.video?.vod
    }
    const item = selectResolution(
      vodSource ?? [],
      qualityHeight ?? containerRect?.height ?? 0
    )
    return item?.url
  }, [containerRect?.height, data?.video?.vod, qualityHeight, videoId])

  const src = useMemo(
    () =>
      videoUrl ? { src: videoUrl, type: 'application/x-mpegURL' } : undefined,
    [videoUrl]
  )

  useEffect(() => {
    if (ready && src) {
      videoJsPlayer.current?.src([src])
      videoJsPlayer.current?.volume(0.25)
    }
  }, [videoJsPlayer, src, ready])

  const updateVideoStatus = useCallback(
    (nextVideoStatus: Partial<SyncedVideoStatus>) => {
      if (videoStatus) {
        dispatch({
          type: useEditorUpdater.ACTION_TYPES.UPDATE_VIDEO_STATUS,
          payload: {
            videoStatus: {
              ...videoStatus,
              ...nextVideoStatus,
            },
          },
        })
      }
    },
    [videoStatus, dispatch]
  )

  const seekToTargetTime = useCallback(() => {
    if (targetTime && data?.video && offsets) {
      videoRef.current?.pause()
      // console.log('setting target time', targetTime, key)
      const startTime = momentjs(data.video.stream?.startedAt).valueOf()
      let videoOffset = 0
      if (offset) {
        videoOffset = offset
      } else if (!(primaryVideoId === videoId) && offsets[primaryVideoId]) {
        // hackiest thing lol
        // currently offset object is shared between players and editor
        // they have different formats... Fix this
        if (typeof offsets[primaryVideoId] === 'object') {
          videoOffset = offsets[primaryVideoId][data.video.id] ?? 0
        } else {
          videoOffset = offsets[videoId] ?? 0
        }
      }
      const nextVideoTime = (targetTime - startTime) / 1000 - videoOffset
      if (videoRef.current) {
        videoRef.current.currentTime = nextVideoTime
      }
      updateVideoStatus({
        syncStatus: SyncStatus.Syncing,
      })
    }
  }, [
    targetTime,
    data?.video,
    offsets,
    offset,
    primaryVideoId,
    videoId,
    updateVideoStatus,
  ])

  // Add and remove video
  useEffect(() => {
    dispatch({
      type: useEditorUpdater.ACTION_TYPES.ADD_VIDEO,
      payload: {
        key,
        videoId,
      },
    })

    return () => {
      dispatch({
        type: useEditorUpdater.ACTION_TYPES.REMOVE_VIDEO,
        payload: {
          key,
        },
      })
    }
  }, [dispatch, key, videoId])

  const updateTime = useCallback(() => {
    const streamStart = momentjs(data?.video?.stream?.startedAt).valueOf()
    const nextTime = (videoRef.current?.currentTime ?? 0) * 1000 + streamStart
    dispatch({
      type: useEditorUpdater.ACTION_TYPES.SET_CURRENT_TIME,
      payload: {
        currentTime: nextTime,
      },
    })
  }, [dispatch, data?.video?.stream])

  useEffect(() => {
    if (controller && primaryVideoId === videoId && playing) {
      const interval = setInterval(() => {
        updateTime()
      }, 25)
      return () => {
        // updateTime() // thinking this might help it be as accurate as possible if it misses part of the last tick
        clearInterval(interval)
      }
    }
  }, [controller, dispatch, playing, primaryVideoId, updateTime, videoId])

  // this needs to happen once video source changes
  useEffect(() => {
    if (
      videoStatus !== undefined &&
      data?.video &&
      targetTime !== undefined &&
      videoStatus.syncStatus === SyncStatus.Loading
    ) {
      seekToTargetTime()
    }
  }, [data?.video, key, seekToTargetTime, targetTime, videoStatus])

  const loadedDataEvent = useCallback(() => {
    if (
      videoRef.current &&
      videoRef.current.readyState >= 2 &&
      videoStatus.syncStatus === SyncStatus.Syncing
    ) {
      updateVideoStatus({ syncStatus: SyncStatus.Synced })
    }
  }, [updateVideoStatus, videoStatus])

  // Should show when data is ready for playing
  useEffect(() => {
    const vref = videoRef.current
    vref?.addEventListener('canplay', loadedDataEvent)
    return () => vref?.removeEventListener('canplay', loadedDataEvent)
  }, [loadedDataEvent])

  const waitForBuffering = useCallback(() => {
    seekToCurrentTime(key)
    setPlayWhenReady(true)
  }, [key, seekToCurrentTime, setPlayWhenReady])

  // When it starts to buffer for more data, we want to pause and resync everything to that moment
  useEffect(() => {
    const vref = videoRef.current
    vref?.addEventListener('waiting', waitForBuffering)
    return () => vref?.removeEventListener('waiting', waitForBuffering)
  }, [waitForBuffering])

  // Whenever new src is set, this should fire
  useEffect(() => {
    const loadStartEvent = () => {
      seekToCurrentTime(key)
    }
    const vref = videoRef.current
    vref?.addEventListener('loadstart', loadStartEvent)
    return () => vref?.removeEventListener('loadstart', loadStartEvent)
  }, [key, seekToCurrentTime])

  // listens to play/pause actions from parent controller.
  useEffect(() => {
    const paused = videoRef.current?.paused
    if (paused !== undefined && playing !== !paused) {
      if (playing) {
        // console.log('playing', key, momentjs().format('SSSSS'))
        videoRef.current?.play()
      } else {
        videoRef.current?.pause()
      }
    }
  }, [playing, key])

  return useMemo(
    () => ({
      containerRef,
      videoRef,
      videoStatus,
      videoUrl,
      src,
    }),
    [containerRef, src, videoStatus, videoUrl]
  )
}
