import styled from 'styled-components/macro'

export const ValorantTimelineContainerDiv = styled.div`
  position: relative;
  height: 60px;
  width: 100%;
  margin: 4px 0;
`

export const ValorantTimelineStreamDiv = styled.div`
  position: absolute;
  height: 50px;
  top: 5px;
  border-radius: 8px;
  background-color: #bbb;
`

export const TimelineMatchContainerDiv = styled.div`
  position: absolute;
  height: 100%;
  background-color: #efefef;
  color: #222;
  display: flex;
  border: 2px solid #222;
  border-radius: 0px;
`

export const TimelineRoundContainerDiv = styled.div`
  position: relative;
  height: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  border-right: 1px dashed #333;
`

export const AgentSelectContainerDiv = styled.div<{ $won: boolean }>`
  position: relative;
  height: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  border-right: 1px dashed #333;
  background: linear-gradient(
    90deg,
    ${(p) => (p.$won ? '#5E8D79' : '#B05046')} 24.65%,
    rgba(82, 172, 129, 0) 100%
  );
`

export const AgentSelectImg = styled.img`
  position: absolute;
  left: -2px;
  top: -2px;
  height: 50px;
  width: 50px;
`

export const RoundCountDiv = styled.div`
  position: absolute;
  top: 5px;
  left: 5px;
  font-size: 12px;
  font-weight: 700;
`

export const KillCountDiv = styled.div`
  position: absolute;

  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;

  svg {
    fill: #222;
    margin-left: 2px;
    width: 16px;
    height: 16px;
  }
`

export const MatchStatsBreakdownDiv = styled.div`
  position: relative;
  margin-left: 60px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: white;
  font-weight: 700;

  & > div:first-child {
    margin-bottom: 4px;
  }

  & > div::last-child {
    font-size: 14px;
  }
`

export const KillNotchDiv = styled.div`
  position: absolute;
  bottom: 0;
  height: 10px;
  width: 1px;
  background-color: #333;
`
