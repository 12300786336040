import { ExportTemplate, TemplateApi } from 'core/exporter/constants'
import { desaturate } from 'polished'
import { LAYER_COLORS } from '../option'
import { CropContainerDiv } from './styled'

interface Props {
  template: ExportTemplate | TemplateApi
}

export const TemplatePreview = ({ template }: Props) => {
  return (
    <>
      {template.crops.map(({ position }, idx) => {
        const dynamicStyles = {
          top: `${position.y * 100}%`,
          left: `${position.x * 100}%`,
          width: `${position.width * 100}%`,
          height: `${position.height * 100}%`,
          backgroundColor: desaturate(0.33, LAYER_COLORS[idx] ?? 'white'),
        }

        return <CropContainerDiv key={idx} style={dynamicStyles} />
      })}
    </>
  )
}
