import React, { createContext, useContext, useReducer } from 'react'

export type AuthProvider = 'auth0' | 'custom'

type State = {
  accessToken: string | null
  provider: AuthProvider | null
}

const initialState: State = {
  accessToken: null,
  provider: null,
}

export enum AuthorizationAction {
  SetAccessToken = 'SetAccessToken',
}

interface SetAccessToken {
  type: AuthorizationAction.SetAccessToken
  payload: {
    accessToken: string | null
    provider: AuthProvider | null
  }
}

type ActionTypes = SetAccessToken

const reducer = (state: State, action: ActionTypes) => {
  switch (action.type) {
    case AuthorizationAction.SetAccessToken:
      return { ...state, ...action.payload }
  }
}

type Dispatch = (action: ActionTypes) => void

const AuthorizationContext = createContext(initialState)
const AuthorizationDispatchContext = createContext<Dispatch | undefined>(
  undefined
)

export const AuthorizationContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <AuthorizationContext.Provider value={state}>
      <AuthorizationDispatchContext.Provider value={dispatch}>
        {children}
      </AuthorizationDispatchContext.Provider>
    </AuthorizationContext.Provider>
  )
}

export const useAuthorizationContext = () => {
  const context = useContext(AuthorizationContext)
  if (context === undefined) {
    throw new Error(
      'useAuthorizationContext must be used within AuthorizationContextProvider'
    )
  }
  return context
}

export const useAuthorizationDispatch = () => {
  const context = useContext(AuthorizationDispatchContext)
  if (context === undefined) {
    throw new Error(
      'useAuthorizationDispatch must be used within AuthorizationContextProvider'
    )
  }
  return context
}
