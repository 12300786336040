import { Button } from 'components/core/button'
import { HStack, VStack } from 'components/core/layout'
import { Text } from 'components/core/text'
import { Justifications } from 'core/composer/template-sidebar/addons/killfeed'
import {
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
  useReducer,
  useCallback,
} from 'react'
import { KDARender } from './kda/render-page'
import { KillfeedTypes } from './kill-feed'
import { KillfeedRender } from './kill-feed/render-page'
import { MatchIntroRender } from './match-intro/render-page'
import { PlayerDisplayRender } from './player-display/render-page'
import { useParams } from 'react-router'
import { useGetVideoEditForAddonsQuery } from '__generated__'
import { SubtitlesRender } from './subtitles/render-page'

type KillfeedProps = {
  y: number
  just: Justifications
  type: KillfeedTypes
}

type PlayerInfoProps = {
  y: number
  just: Justifications
}

type KDAProps = {
  y: number
  x: number
}

type MatchIntroProps = {
  y: number
}

type SubtitlesProps = {
  y: number
}

//data looks like
type AddonData = {
  start: number
  end: number
  w: number
  h: number
  vId: string
  killfeed?: KillfeedProps
  playerInfo?: PlayerInfoProps
  kda?: KDAProps
  matchIntro?: MatchIntroProps
  subtitles?: SubtitlesProps
}

export type ActionsFactory = (key: string) => {
  addAddon: () => void
  updateReadyStatus: (ready: boolean) => void
  updateDiffTimes: (diffTimes: number[]) => void
}

export const AddonsRenderEditPage = () => {
  const { editId } = useParams<{ editId: string }>()
  const { data: editData } = useGetVideoEditForAddonsQuery({
    variables: { editId },
  })
  // walk through each scene that has addons
  const [scenesIdx, setScenesIdx] = useState<number | undefined>(undefined)
  const scenesIndexesWithAddons = useMemo(() => {
    if (!editData) return undefined

    const scenes = editData.momentEdit?.scenes
    if (!scenes) return []

    return scenes.reduce((acc, scene, index) => {
      if (scene.overlays?.length) acc.push(index)
      return acc
    }, [] as number[])
  }, [editData])

  // set the scene index to the first scene with addons
  useEffect(() => {
    if (!scenesIndexesWithAddons) return
    setScenesIdx(0)
  }, [scenesIndexesWithAddons])

  const currentSceneAddonData = useMemo(() => {
    if (
      !editData ||
      !scenesIndexesWithAddons ||
      scenesIdx === undefined ||
      !editData.momentEdit
    )
      return undefined
    const currentSceneIdx = scenesIndexesWithAddons[scenesIdx]
    const sceneData = editData.momentEdit?.scenes?.[currentSceneIdx]
    const cropData = sceneData.cropData.find(
      (cropData) => cropData.muted === false
    )
    let addonData: AddonData | undefined = undefined
    if (sceneData && cropData && sceneData.overlays) {
      // get primary videoId for scene
      const primaryVideoId = cropData.videoId
      addonData = {
        start: new Date(sceneData.start).getTime(),
        end: new Date(sceneData.end).getTime(),
        w: editData.momentEdit.format.aspectRatio.width === 16 ? 1920 : 1080,
        h: editData.momentEdit.format.aspectRatio.height === 16 ? 1920 : 1080,
        vId: primaryVideoId ?? '',
        kda: sceneData.overlays?.[0]?.data.kda as KDAProps | undefined,
        killfeed: sceneData.overlays?.[0]?.data.killfeed as
          | KillfeedProps
          | undefined,
        matchIntro: sceneData.overlays?.[0]?.data.matchIntro as
          | MatchIntroProps
          | undefined,
        playerInfo: sceneData.overlays?.[0]?.data.playerInfo as
          | PlayerInfoProps
          | undefined,
        subtitles: sceneData.overlays?.[0]?.data.subtitles as SubtitlesProps,
      }
    }
    return addonData
  }, [editData, scenesIdx, scenesIndexesWithAddons])
  const hasNextScene = useMemo(() => {
    if (!scenesIndexesWithAddons || scenesIdx === undefined) return undefined
    return scenesIdx < scenesIndexesWithAddons.length - 1
  }, [scenesIdx, scenesIndexesWithAddons])

  const nextScene = useCallback(() => {
    if (!scenesIndexesWithAddons || scenesIdx === undefined) return
    if (scenesIdx < scenesIndexesWithAddons.length - 1) {
      setScenesIdx(scenesIdx + 1)
    }
  }, [scenesIdx, scenesIndexesWithAddons])

  // set data for addons render scene
  // track progress through
  if (
    !editData ||
    !currentSceneAddonData ||
    scenesIdx === undefined ||
    hasNextScene === undefined
  )
    return null
  return (
    <AddonsRenderScene
      key={scenesIdx}
      sceneIdx={scenesIdx}
      nextScene={nextScene}
      hasNextScene={hasNextScene}
      data={currentSceneAddonData}
    />
  )
}

interface Props {
  sceneIdx: number
  data: AddonData
  nextScene: () => void
  hasNextScene: boolean
}

export const AddonsRenderScene = ({
  sceneIdx,
  data,
  nextScene,
  hasNextScene,
}: Props) => {
  const [state, dispatch] = useReducer(reducer, {})
  // fetch the edit data

  // Helper function that binds the key argument for the dispatch functions
  const actionsFactory = useCallback(
    (key: string) => ({
      addAddon: () => dispatch({ type: 'addAddon', key }),
      updateReadyStatus: (ready: boolean) =>
        dispatch({ type: 'updateReadyStatus', key, ready }),
      updateDiffTimes: (diffTimes: number[]) =>
        dispatch({ type: 'updateDiffTimes', key, diffTimes }),
    }),
    []
  )

  const [frameIdx, setFrameIdx] = useState(0)
  // const data = useMemo(
  //   () =>
  //     JSON.parse(
  //       atob(decodeURIComponent(params.get('data') ?? ''))
  //     ) as AddonData,
  //   [params]
  // )

  const includedAddons = useMemo(() => {
    const addons: string[] = []
    if (data.killfeed) addons.push('killfeed')
    if (data.playerInfo) addons.push('playerInfo')
    if (data.kda) addons.push('kda')
    if (data.matchIntro) addons.push('matchIntro')
    if (data.subtitles) addons.push('subtitles')
    return addons
  }, [data])

  const ready = useMemo(() => {
    const allReady = includedAddons.every((key) => state[key]?.ready)
    return allReady
  }, [includedAddons, state])

  const slideshowTimes = useMemo(() => {
    const diffTimes = includedAddons.map((key) => state[key]?.diffTimes ?? [])
    // append start and end times to the array of arrays
    const allTimes = [data.start, ...diffTimes, data.end]
    // Flatten the array of arrays, remove duplicates, and sort
    const times = Array.from(new Set(allTimes.flat())).sort((a, b) => a - b)
    // create an array with times and durations, durations are the time between each time, except the last one
    const timesWithDurations = times.map((time, idx) => ({
      time,
      duration: (times[idx + 1] ?? 0) - time,
    }))
    timesWithDurations.pop()

    return timesWithDurations
  }, [data.end, data.start, includedAddons, state])

  const selectedSlide = useMemo(
    () => slideshowTimes[frameIdx],
    [frameIdx, slideshowTimes]
  )

  useLayoutEffect(() => {
    // @ts-ignore
    window.ready = ready
    // @ts-ignore
    window.frameIdx = frameIdx
    // @ts-ignore
    window.done = frameIdx >= slideshowTimes.length - 1
    // @ts-ignore
    window.frameDuration = selectedSlide?.duration
    // @ts-ignore
    window.sceneIdx = sceneIdx
    // @ts-ignore
    window.scenesDone = !hasNextScene && frameIdx >= slideshowTimes.length - 1
  }, [
    frameIdx,
    hasNextScene,
    ready,
    sceneIdx,
    selectedSlide?.duration,
    slideshowTimes.length,
  ])

  useEffect(() => {
    const elem = window.document.getElementById('root')
    if (elem) {
      elem.style.backgroundColor = 'transparent'
    }
  }, [])

  return (
    <>
      {data.killfeed && (
        <KillfeedRender
          width={data.w}
          height={data.h}
          start={data.start}
          end={data.end}
          videoId={data.vId}
          y={data.killfeed.y}
          justify={data.killfeed.just}
          currentTime={selectedSlide?.time ?? 0}
          actionsFactory={actionsFactory}
          type={data.killfeed.type}
        />
      )}

      {data.playerInfo && (
        <PlayerDisplayRender
          width={data.w}
          height={data.h}
          start={data.start}
          end={data.end}
          videoId={data.vId}
          y={data.playerInfo.y}
          justify={data.playerInfo.just}
          actionsFactory={actionsFactory}
        />
      )}

      {data.kda && (
        <KDARender
          width={data.w}
          height={data.h}
          start={data.start}
          end={data.end}
          videoId={data.vId}
          currentTime={selectedSlide?.time ?? 0}
          y={data.kda.y}
          x={data.kda.x}
          actionsFactory={actionsFactory}
        />
      )}

      {data.matchIntro && (
        <MatchIntroRender
          width={data.w}
          height={data.h}
          start={data.start}
          end={data.end}
          videoId={data.vId}
          currentTime={selectedSlide?.time ?? 0}
          y={data.matchIntro.y}
          justify={Justifications.center}
          actionsFactory={actionsFactory}
        />
      )}

      {data.subtitles && (
        <SubtitlesRender
          actionsFactory={actionsFactory}
          width={data.w}
          height={data.h}
          start={data.start}
          end={data.end}
          videoId={data.vId}
          currentTime={selectedSlide?.time ?? 0}
          y={data.subtitles.y}
        />
      )}

      <div style={{ position: 'absolute', left: 1921 }}>
        <VStack>
          <HStack gap={8} alignItems="center">
            <Text variant="text-2">Scene:</Text>
            <div id="sceneIdx">{sceneIdx}</div>
          </HStack>
          <HStack gap={8} alignItems="center">
            <Text variant="text-2">Frame:</Text>
            <div id="frameIdx">{frameIdx}</div>
          </HStack>
          <HStack gap={8} alignItems="center">
            <Text variant="text-2">Duration:</Text>
            <div id="duration">{selectedSlide?.duration}</div>
          </HStack>
          <HStack gap={8} alignItems="center">
            <Text variant="text-2">Status:</Text>
            <div id="status">{ready ? 'Ready' : 'Loading'}</div>
          </HStack>
        </VStack>
        {frameIdx < slideshowTimes.length - 1 && (
          <Button
            id="nextFrameButton"
            variant="secondary"
            onClick={() => {
              setFrameIdx((currentIdx) => currentIdx + 1)
            }}
          >
            Next Slide
          </Button>
        )}
        <VStack>
          <HStack gap={8} alignItems="center">
            <Text variant="text-2">Scene:</Text>
            <div id="sceneIdx">{sceneIdx}</div>
          </HStack>
          {frameIdx === slideshowTimes.length - 1 && (
            <Button
              id="nextSceneButton"
              variant="secondary"
              onClick={nextScene}
              disabled={!hasNextScene}
            >
              Next Scene
            </Button>
          )}
        </VStack>
      </div>
    </>
  )
}

type State = { [key: string]: { ready: boolean; diffTimes: number[] } }

type Action =
  | { type: 'addAddon'; key: string }
  | { type: 'updateReadyStatus'; key: string; ready: boolean }
  | { type: 'updateDiffTimes'; key: string; diffTimes: number[] }

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'addAddon':
      return {
        ...state,
        [action.key]: { ready: false, diffTimes: [] },
      }
    case 'updateReadyStatus':
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          ready: action.ready,
        },
      }
    case 'updateDiffTimes':
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          diffTimes: action.diffTimes,
        },
      }
    default:
      return state
  }
}
