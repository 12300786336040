import { useCallback, useEffect } from 'react'

import { Content, HStack, Spacing } from 'components/core/layout'
import { FeedProvider, FeedType, useFeedState } from 'core/feed/context'
import { ValorantFeed } from 'page/feed/valorant-feed'
import { Text } from 'components/core/text'
import { ReactionFeed } from 'page/feed/reaction-feed'
import { useFeedHelpers } from 'core/feed/helpers'

import { Sidebar } from './sidebar'
import { useCurrentChannel } from './hooks'
import { ChannelNavigation } from './navigation'
import { ChannelContent, MainContent } from './styled'
import { ValorantFeedFilters } from 'core/feed/components/filters/valorant-filters'
import { MomentFeedFilters } from 'core/feed/components/filters/moments-filters'

export const ChannelValorantFeed = () => {
  return (
    <Content background={'secondary'}>
      <HStack gap={16}>
        <FeedProvider>
          <Sidebar>
            <MomentFeedFilters />
            <ValorantFeedFilters />
          </Sidebar>
          <MainContent>
            <ChannelContent>
              <ChannelNavigation />
              <Spacing top={16}>
                <ChannelFeed />
              </Spacing>
            </ChannelContent>
          </MainContent>
        </FeedProvider>
      </HStack>
    </Content>
  )
}

const ChannelFeed = () => {
  const { data } = useCurrentChannel()
  const { type } = useFeedState()
  const { setChannelIds, setFeedType: setType } = useFeedHelpers()

  const setFeedType = useCallback(
    (type: FeedType) => {
      setType(type)
    },
    [setType]
  )

  useEffect(() => {
    if (!data?.channel?.id) {
      return
    }
    setFeedType(FeedType.ValorantSignal)
    // setFilters(DEFAULT_VALORANT_FILTER)
    setChannelIds([data.channel.id])
  }, [data?.channel?.id, setChannelIds, setFeedType])

  return (
    <>
      <HStack alignItems="center" gap={8}>
        <Text variant="h4">Feed</Text>
      </HStack>

      {type === FeedType.ValorantSignal ? (
        <ValorantFeed hideChannel={true} />
      ) : (
        <ReactionFeed />
      )}
    </>
  )
}
