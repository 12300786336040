import { useMemo } from 'react'
import { generatePath, useParams } from 'react-router'
import { StreamingPlatform, useGetChannelQuery } from '__generated__'

export enum ChannelPageType {
  VIDEOS = 'VIDEOS',
  VIDEO_DETAIL = 'VIDEO_DETAIL',
  MATCHES = 'MATCHES',
  MATCH_DETAIL = 'MATCH_DETAIL',
  VALORANT = 'VALORANT',
}

const basePath = '/channel/:id'
export const useCurrentChannel = () => {
  const params = useParams<{
    id: string
    pageType?: string
  }>()

  const { data, loading, error } = useGetChannelQuery({
    variables: {
      name: params.id,
      platform: StreamingPlatform.Twitch,
    },
  })

  const hasValorantAccount = useMemo(() => {
    const linkedAccounts = data?.channel?.linkedAccounts
    if (linkedAccounts) {
      return (
        linkedAccounts.length > 0 &&
        linkedAccounts.some((acc) => acc.__typename === 'RiotAccount')
      )
    }
  }, [data?.channel?.linkedAccounts])

  const pageType = useMemo(
    () =>
      params.pageType === 'valorant'
        ? ChannelPageType.VALORANT
        : ChannelPageType.VIDEOS,
    [params]
  )

  const urls = useMemo(
    () => ({
      // default: generatePath(${path, { ...params, pageType: undefined }),
      valorant: generatePath(`${basePath}/valorant`, params),
      videos: generatePath(`${basePath}/videos`, params),
      matches: generatePath(`${basePath}/matches`, params),
    }),
    [params]
  )

  return useMemo(
    () => ({
      id: data?.channel?.id || '',
      platform: StreamingPlatform.Twitch,
      data,
      pageType,
      error,
      loading,
      urls,
      hasValorantAccount,
    }),
    [data, error, hasValorantAccount, loading, pageType, urls]
  )
}
