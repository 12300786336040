// maybe make rich wins full house
// maybe schlatt straight win
export const groupMoments = [
  '04d4104d-f36a-4c58-949d-1100c8e7dc53',
  'd0f4c4f1-ba1d-4817-beda-c1985d33b4cd',
  'bfc6b47e-e8f1-4d38-b5b3-d010a931d50f',
  'b0a6a079-e246-4dfb-ba99-c4d52d828f4a', // honest poker
  '4facc99f-15c4-4985-a12d-800bb80ee347', // emote callout
  'b148809c-2fe5-4693-a40d-54bee45b6d19',
  'd5fbf8f4-c66e-457a-9125-95235bfdbe74',
  'c04647d0-b260-4655-bbed-2cb0a68f813d',
]

// maybe make River Rat?
export const mobile = [
  '229774d3-3c0a-41d6-aba2-9d7ef7600a3c',
  '04f25f0f-7513-4c8a-9850-4da1b352ef11',
  '963ce11f-1aed-45e9-badf-1c2888e39be9', // honest poker
  '458277f0-8a97-450e-9d2a-e4775be22f8f',
  'd12bc518-9fd2-4fe4-aa0d-37d274c0f199',
  'e97d2004-4a7b-45bc-8af3-a0d781f69e51',
]

export const callout = [
  'bfc6b47e-e8f1-4d38-b5b3-d010a931d50f',
  '4facc99f-15c4-4985-a12d-800bb80ee347',
  'cea2849c-c457-4d03-a6e4-144593a0ad00',
  'cbdeac40-cee3-41fe-a51b-8e7af1405eb3',
  '3d659fae-6b9c-4afa-b1a2-17b74d135ff7',
  '5dbedd96-96f7-4744-a6b3-ab10e0faf6df',
  '40116f9b-e41c-4860-8265-daddc854ecde',
]

export const karlEdits = [
  'cea2849c-c457-4d03-a6e4-144593a0ad00',
  '2676aa70-db31-40e0-b85e-fe5d0a94963f',
  '66bc0413-78b1-4605-b127-f74e8a383b26',
  'b9a4521b-42bd-420d-b5f8-29734df37a55',
]
export const schlattEdits = [
  '8574b179-1e14-41d6-a783-c606e65dc758',
  '4f7511f2-3aa7-4b1f-a83c-0c4ba303a374',
  '43804ca5-ea0c-40b0-965a-f9df857ac1bc',
  '229d3e7f-4bbb-4f6a-8377-9f1be6a907fb',
  '43804ca5-ea0c-40b0-965a-f9df857ac1bc',
]
export const sapnapEdits = [
  'b148809c-2fe5-4693-a40d-54bee45b6d19',
  '699a2f9b-3861-455e-a057-57e1c87cb631',
  'b4426229-7422-48fa-98c1-2ebf710ca4cd',
  'ef3f6ace-bedc-4692-bd3d-f3696cf04a78',
  '6a63246c-205b-4421-ada2-f8df09e1c9c1',
]
export const neekoEdits = [
  'd0f4c4f1-ba1d-4817-beda-c1985d33b4cd',
  'bfc6b47e-e8f1-4d38-b5b3-d010a931d50f',
  'cbd22d4f-e8bf-437b-8a7d-f621246dc895',
  '66dc2ad8-b30d-4071-aaad-4d251acba75c',
]
export const richEdits = [
  '04d4104d-f36a-4c58-949d-1100c8e7dc53',
  'a3fddc59-7a13-4333-b1a7-d23f4c604db2',
  '8098906b-18c8-47bf-90ff-84e8f56d0392',
  '229d3e7f-4bbb-4f6a-8377-9f1be6a907fb',
  '251ef203-b3d7-4589-8015-88f48d0b7c3d',
  '9d9511e6-4a5f-4bff-aa96-fb0712da1f76',
]

const sapnaplive = '638077636'
const richwcampbell = '127463427'
const neeko = '169188075'
const karljacobs = '124442278'
const schlatt = '98125665'

export const STREAMER_ICONS = {
  [richwcampbell]:
    'https://static-cdn.jtvnw.net/jtv_user_pictures/b4fbeb77-2433-4151-85a3-475106e0bcb5-profile_image-300x300.png',
  [neeko]:
    'https://static-cdn.jtvnw.net/jtv_user_pictures/9908dd0c-4a02-4eeb-b21a-25688fea6ebb-profile_image-300x300.png',
  [karljacobs]:
    'https://static-cdn.jtvnw.net/jtv_user_pictures/8c2183e6-0deb-4377-a0bf-cbbf56ea70f3-profile_image-300x300.png',
  [schlatt]:
    'https://static-cdn.jtvnw.net/jtv_user_pictures/270294ae-af8f-45ad-b81a-685ecd4dae31-profile_image-300x300.png',
}

export const STREAMERS_IDS = [
  karljacobs,
  neeko,
  schlatt,
  richwcampbell,
  sapnaplive,
]

export const SECTIONS_WITH_TITLES = [
  {
    title: 'Group Moments',
    edits: groupMoments,
  },
  {
    title: 'Social Format',
    edits: mobile,
  },
  {
    title: 'Americas Cardroom Shoutouts',
    edits: callout,
  },
  {
    icon: STREAMER_ICONS[karljacobs],
    title: 'Karl Jacobs Highlights',
    edits: karlEdits,
  },
  {
    icon: STREAMER_ICONS[richwcampbell],
    title: 'Rich Campbell Highlights',
    edits: richEdits,
  },
  {
    icon: STREAMER_ICONS[schlatt],
    title: 'Schlatt Highlights',
    edits: schlattEdits,
  },
  {
    icon: STREAMER_ICONS[neeko],
    title: 'Neeko Highlights',
    edits: neekoEdits,
  },
  {
    title: 'SapNap Highlights',
    edits: sapnapEdits,
  },
]
