import React, { useMemo } from 'react'
import styled from 'styled-components/macro'
import {
  useGetEditingVideoQuery,
  useGetTwitchUserBasicsQuery,
} from '__generated__'

interface RequiredAvatarProps {
  width: number
  height: number
}

type StreamerAvatarWithVideoProps = RequiredAvatarProps & {
  videoId: string
}

export const StreamerAvatarWithVideo: React.FC<
  StreamerAvatarWithVideoProps
> = ({ width, height, videoId }) => {
  const { data } = useGetEditingVideoQuery({ variables: { id: videoId } })
  const imgUrl = useMemo(() => {
    return data?.video?.user?.profileImageUrl
  }, [data?.video?.user?.profileImageUrl])

  return (
    <StreamerAvatar
      height={height}
      width={width}
      profileImageUrl={imgUrl ?? undefined}
    />
  )
}

type StreamerAvatarWithUserProps = RequiredAvatarProps & {
  userId: string
}

export const StreamerAvatarWithUser: React.FC<StreamerAvatarWithUserProps> = ({
  width,
  height,
  userId,
}) => {
  const { data } = useGetTwitchUserBasicsQuery({ variables: { userId } })
  const imgUrl = useMemo(() => {
    return data?.channel?.profileImageUrl
  }, [data?.channel?.profileImageUrl])

  return (
    <StreamerAvatar
      height={height}
      width={width}
      profileImageUrl={imgUrl ?? undefined}
    />
  )
}

type StreamerAvatarProps = RequiredAvatarProps & {
  profileImageUrl?: string
}

export const StreamerAvatar = ({
  width,
  height,
  profileImageUrl,
}: StreamerAvatarProps) => {
  const styles = useMemo(() => {
    return {
      width,
      height,
    }
  }, [width, height])

  return (
    <StreamAvatarDiv style={styles}>
      {profileImageUrl ? (
        <img src={profileImageUrl} alt="streamer avatar" />
      ) : null}
    </StreamAvatarDiv>
  )
}

const StreamAvatarDiv = styled.div`
  position: relative;

  & > img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
`
