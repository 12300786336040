import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTheme } from 'styled-components/macro'
import { transparentize } from 'polished'

import { Button } from 'components/core/button'
import {
  SAVE_LEVELS,
  SAVE_LEVEL_OPTIONS,
} from 'core/shared/templates/constants'
import {
  templateLocalStorageKey,
  getTemplatesFromLocalStorage,
} from 'core/shared/templates/helpers'

import { useComposerState } from 'core/composer/context'
import {
  MultiPerspectiveMomentDetailFragment,
  useGetMomentDetailQuery,
} from '__generated__'
import { ExportFormat, TemplateApi } from 'core/exporter/constants'
import { SaveTemplateDiv } from './styled'

interface Props {
  format: ExportFormat
  template: TemplateApi
  close: () => void
}

export const SaveTemplateLevels = ({ format, template, close }: Props) => {
  const { momentId } = useComposerState()
  const theme = useTheme()
  const videosData = useGetVideoData(momentId)
  const [saveLevel, setSaveLevel] = useState<SAVE_LEVELS>(SAVE_LEVELS.MOMENT)
  const [saveVideoId, setSaveVideoId] = useState<string>('')
  const [saveUserId, setSaveUserId] = useState<string>('')

  const save = useCallback(() => {
    const saveLevelId = () => {
      switch (saveLevel) {
        case SAVE_LEVELS.MOMENT:
          return momentId

        case SAVE_LEVELS.STREAMER:
          return saveUserId

        case SAVE_LEVELS.VIDEO:
          return saveVideoId

        default:
          return ''
      }
    }
    // check for duplicate title in the same level
    const key = templateLocalStorageKey(format.id, saveLevel, saveLevelId())
    const templatesAtThisLevel = getTemplatesFromLocalStorage(
      format.id,
      saveLevel,
      saveLevelId()
    )

    // const hasTitleOverlap = templatesAtThisLevel.some((temp) => {
    //   return temp.title === title
    // })

    // if (hasTitleOverlap) {
    //   alert('This template title is already in use')
    //   return
    // }

    // Once a custom width/height is set, create the aspect ratio for the content
    const cropTemplatesToSave = template.crops.map((temp) => {
      let nextAspectRatio = temp.aspectRatio
      // if (temp.aspectRatio.width === 0 && temp.aspectRatio.height === 0) {
      //   // pull aspect ratio out of relative parent format
      //   const cropWidth = temp.position.width * format.aspectRatio.width
      //   const cropHeight = temp.position.height * format.aspectRatio.height
      //   // normalize one to 1
      //   if (cropWidth > cropHeight) {
      //     const nextHeightAR = cropHeight / cropWidth
      //     nextAspectRatio = { width: 1, height: nextHeightAR }
      //   } else {
      //     const nextWidthAR = cropWidth / cropHeight
      //     nextAspectRatio = { width: nextWidthAR, height: 1 }
      //   }
      // }

      return {
        ...temp,
        aspectRatio: nextAspectRatio,
      }
    })

    const nextTemplatesAtThisLevel = [
      ...templatesAtThisLevel,
      { title: 'untitled', crops: cropTemplatesToSave },
    ]

    localStorage.setItem(key, JSON.stringify(nextTemplatesAtThisLevel))
    close()
  }, [
    close,
    format.id,
    momentId,
    saveLevel,
    saveUserId,
    saveVideoId,
    template.crops,
  ])

  // initialize it when the type switches
  useEffect(() => {
    if (saveLevel === SAVE_LEVELS.VIDEO) {
      setSaveUserId('')
      setSaveVideoId(videosData[0]?.videoId ?? '')
    } else if (saveLevel === SAVE_LEVELS.STREAMER) {
      setSaveVideoId('')
      setSaveUserId(videosData[0]?.userId ?? '')
    }
  }, [saveLevel, videosData])

  return (
    <SaveTemplateDiv>
      <select
        value={saveLevel}
        onChange={(evt) => setSaveLevel(evt.currentTarget.value as SAVE_LEVELS)}
      >
        {SAVE_LEVEL_OPTIONS.map((sl) => (
          <option key={sl} value={sl}>
            {sl}
          </option>
        ))}
      </select>
      {saveLevel === SAVE_LEVELS.VIDEO ? (
        <select
          value={saveVideoId}
          onChange={(evt) => setSaveVideoId(evt.currentTarget.value)}
        >
          {videosData.map(({ videoId, displayName }) => (
            <option key={videoId} value={videoId}>
              {displayName}
            </option>
          ))}
        </select>
      ) : null}
      {saveLevel === SAVE_LEVELS.STREAMER ? (
        <select
          value={saveUserId}
          onChange={(evt) => setSaveUserId(evt.currentTarget.value)}
        >
          {videosData.map(({ userId, displayName }) => (
            <option key={userId} value={userId}>
              {displayName}
            </option>
          ))}
        </select>
      ) : null}

      <Button
        color={transparentize(0.5, theme.colors.static.neutrals.n4)}
        size="small"
        onClick={save}
      >
        Save Template
      </Button>
    </SaveTemplateDiv>
  )
}

const useGetVideoData = (momentId: string) => {
  const { data } = useGetMomentDetailQuery({ variables: { momentId } })

  const moment = useMemo(() => {
    return data?.moment as MultiPerspectiveMomentDetailFragment | undefined
  }, [data?.moment])

  return useMemo(
    () =>
      moment?.videos.map(({ id, user }) => ({
        videoId: id,
        userId: user?.id ?? '',
        displayName: user?.displayName ?? '',
      })) ?? [],
    [moment?.videos]
  )
}
