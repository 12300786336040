import { ReactNode, useMemo } from 'react'

import { PlaylistItemDiv } from './styled'
import { HStack, VStack } from 'components/core/layout'
import { Text } from 'components/core/text'
import { useGetReactionSignalQuery } from '__generated__'
import { RootOnly } from 'core/auth/components'

interface DisplayItemProps {
  children?: ReactNode
  signalId: string
  onClick?: () => void
  selected: boolean
}

export const ReactionMomentDisplayItem = ({
  children,
  signalId,
  onClick,
  selected,
}: DisplayItemProps) => {
  const { data } = useGetReactionSignalQuery({ variables: { signalId } })

  const moment = useMemo(() => {
    if (
      data?.moment &&
      (data.moment.__typename === 'ChatMoment' ||
        data.moment.__typename === 'ManualSignalMoment')
    )
      return data.moment
  }, [data?.moment])

  return moment ? (
    <PlaylistItemDiv onClick={onClick} $selected={selected}>
      <HStack alignItems="center" gap={16}>
        <img
          alt="profile"
          src={moment.channel.profileImageUrl ?? ''}
          width={30}
          height={30}
        />
        <VStack>
          <Text variant="text-2" weight="semibold">
            {moment.channel.name}
          </Text>
          <HStack gap={16}>
            <Text variant="small">{moment.type}</Text>
            <RootOnly>
              <Text variant="small">{moment.metadata?.score?.toFixed(2)}</Text>
            </RootOnly>
          </HStack>
        </VStack>
      </HStack>
      {children}
    </PlaylistItemDiv>
  ) : null
}
