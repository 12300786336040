import React, { useMemo } from 'react'
import { useResizeSelection } from './hooks'

import { SceneDragDiv, SceneIndicatorDiv } from './styled'

interface TimelineSelectionProps {
  selection: { startPct: number; endPct: number } | undefined
  setSelection: (nextSelection: { startPct: number; endPct: number }) => void
  onUpdateDone: () => void
  contentWidth: number
}

export const TimelineSelection: React.FC<TimelineSelectionProps> = ({
  selection,
  setSelection,
  contentWidth,
  onUpdateDone,
}) => {
  const { sliderRef: leftSlider } = useResizeSelection(
    contentWidth,
    'startPct',
    selection ?? { startPct: 0, endPct: 0 },
    setSelection,
    onUpdateDone
  )

  const { sliderRef: rightSlider } = useResizeSelection(
    contentWidth,
    'endPct',
    selection ?? { startPct: 0, endPct: 0 },
    setSelection,
    onUpdateDone
  )

  const selectionStyles = useMemo(() => {
    if (selection === undefined) {
      return {
        display: 'none',
      }
    }

    return {
      width: `${(selection.endPct - selection.startPct) * 100}%`,
      left: `${selection.startPct * 100}%`,
    }
  }, [selection])

  return (
    <>
      <SceneIndicatorDiv style={selectionStyles}>
        <SceneDragDiv $type="left" ref={leftSlider} />
        <SceneDragDiv $type="right" ref={rightSlider} />
      </SceneIndicatorDiv>
    </>
  )
}
