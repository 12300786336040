import styled from 'styled-components/macro'

const formatSizeProp = (v: string | number): string => {
  if (typeof v === 'number') {
    return `${v}px`
  }
  if (/(%|px)/.test(v)) {
    return v
  }
  return `${v}px`
}

type Props = {
  widthProp?: string | number
  heightProp?: string | number
}
const ImagePreviewWrapper = styled.div<Props>`
  position: relative;
  overflow: hidden;
  ${({ widthProp }) => widthProp && `width: ${formatSizeProp(widthProp)}; `}
  ${({ heightProp }) => heightProp && `height: ${formatSizeProp(heightProp)}; `}

  & > img {
    width: 100%;
    display: block;
    transition: transform 1s var(--common-ease-out);
  }

  &:hover > img {
    transform: scale(1.1);
  }
`

export { ImagePreviewWrapper }
