import { useMemo } from 'react'

import { Text } from 'components/core/text'
import { TemplateCropSettings } from 'core/exporter/constants'
import { ReactComponent as VolumeIcon } from 'icons/svgs/solid/volume.svg'

import { useComposerState } from '../context'
import { useComposerHelper } from '../hooks'
import { LayerOptionDiv } from './styled'
import { HStack } from 'components/core/layout'

interface Props {
  layerSettings: TemplateCropSettings
}

export const LAYER_COLORS = [
  '#E4D7CF',
  '#9757D7',
  '#EF466F',
  '#6CD640',
  '#4B51D6',
  '#D6C16B',
  '#2EF0B7',
  '#F07451',
]

export const LayerOption = ({ layerSettings }: Props) => {
  const { selectedTemplateCrop, cropSettings } = useComposerState()
  const { setSelectedTemplateCrop } = useComposerHelper()

  const settings = useMemo(
    () => cropSettings[layerSettings.title ?? ''],
    [cropSettings, layerSettings.title]
  )
  const isSelected = useMemo(() => {
    return selectedTemplateCrop?.title === layerSettings.title
  }, [layerSettings.title, selectedTemplateCrop?.title])

  return (
    <LayerOptionDiv
      $selected={isSelected}
      $layerColor={settings?.color ?? LAYER_COLORS[0]}
      onClick={() => setSelectedTemplateCrop(layerSettings)}
    >
      <HStack
        alignItems="center"
        justifyContent="space-between"
        fullWidth={true}
      >
        <Text variant="text-2" as="div">
          {layerSettings.title}
        </Text>
        {layerSettings.primary ? (
          <VolumeIcon width={16} height={16} fill="white" />
        ) : null}
      </HStack>
    </LayerOptionDiv>
  )
}
