import React, { useMemo } from 'react'

import { Text } from 'components/core/text'
import { useComposerState } from 'core/composer/context'
import { useEditorState } from 'core/editor/context'
import { RANK_ICONS } from 'components/valorant/rank-icon'
import { Justifications } from 'core/composer/template-sidebar/addons/killfeed'

import { HStack, VStack } from 'components/core/layout'
import { STANDARD_FORMAT } from 'core/exporter/constants'
import {
  AddonContainer,
  AgentImg,
  ContainerDiv,
  MatchIntroScaledContainer,
  ProfileImg,
  UserNameText,
} from './styled'
import { ContentsDiv } from '../player-display/styled'
import { useMatchIntroData } from './hooks'
import { PlayerHighlightData } from 'page/ranked-gods/matches/hooks'
import { AGENT_THUMB_IMAGES } from 'components/valorant/agent'
import { ScoreboardEntry } from 'core/feed/components/scoreboard/hooks'
import { MatchIntroTeams } from './teams'

export const MatchIntroWithComposerContext = () => {
  const { selection } = useEditorState()
  const { primaryVideoId, previewRect, matchIntroOptions, selectedFormat } =
    useComposerState()
  const { width, height } = useMemo(() => {
    const width = selectedFormat?.id === STANDARD_FORMAT.id ? 1920 : 1080
    const height = selectedFormat?.id === STANDARD_FORMAT.id ? 1080 : 1920
    return { width, height }
  }, [selectedFormat])

  const scale = useMemo(() => {
    const height = selectedFormat?.id === STANDARD_FORMAT.id ? 1080 : 1920
    return (previewRect?.height ?? 0) / height
  }, [previewRect?.height, selectedFormat])
  const props = {
    y: matchIntroOptions.y,
  }

  return selection?.startTime && primaryVideoId ? (
    <AddonContainer>
      <MatchIntro
        width={width}
        height={height}
        scale={scale}
        startTime={selection?.startTime ?? 0}
        videoId={primaryVideoId}
        {...props}
      />
    </AddonContainer>
  ) : (
    <React.Fragment />
  )
}

interface Props {
  width: number
  height: number
  scale: number
  videoId: string
  startTime: number
  y: number
}

export const MatchIntro = ({
  width,
  height,
  videoId,
  startTime,
  scale,
  y,
}: Props) => {
  const { displayName, players, profileImageUrl, teams } = useMatchIntroData(
    videoId,
    startTime
  )

  return (
    <MatchIntroStill
      width={width}
      height={height}
      players={players ?? []}
      teams={teams}
      currentTime={startTime}
      displayName={displayName}
      profileImageUrl={profileImageUrl}
      scale={scale}
      y={y}
      justify={Justifications.center}
    />
  )
}

interface StillProps {
  width: number
  height: number
  displayName?: string
  profileImageUrl?: string
  currentTime?: number
  scale: number
  y: number
  justify: Justifications
  players?: PlayerHighlightData[]
  teams?: Record<string, ScoreboardEntry[]>
}

export const MatchIntroStill = ({
  width,
  height,
  scale,
  y,
  justify,
  players,
  teams,
}: StillProps) => {
  const highlightedAccountIds = useMemo(() => {
    return players?.map((player) => player.accountId)
  }, [players])

  return (
    <MatchIntroScaledContainer
      $scale={scale}
      $top={y}
      $justify={justify}
      $width={width}
      $height={height}
    >
      <ContentsDiv $justify={justify}>
        <ContainerDiv>
          <VStack gap={48} justifyContent="center">
            {players?.map((player, index) => {
              return (
                <VStack gap={24} alignItems="center" key={player.accountId}>
                  <HStack gap={42} alignItems="center">
                    <ProfileImg src={player.profileImageUrl} alt="" />
                    <VStack gap={24} key={index} alignItems="flex-start">
                      <HStack gap={24} alignItems="center">
                        <UserNameText variant="h1" weight="bold" color="white">
                          {player.gameName}
                        </UserNameText>
                      </HStack>
                      {player.competitiveTier &&
                        player.competitiveTier !== 'NOT_RANKED' && (
                          <HStack
                            gap={12}
                            alignItems="center"
                            justifyContent="center"
                          >
                            <img
                              width={64}
                              height={64}
                              src={RANK_ICONS[player.competitiveTier]}
                              alt=""
                            />
                            {player.leaderboardRank && (
                              <VStack alignItems="center">
                                <Text variant="h5" weight="bold" color="white">
                                  RANK
                                </Text>
                                <Text variant="h3" weight="bold" color="white">
                                  {player.leaderboardRank}
                                </Text>
                              </VStack>
                            )}
                          </HStack>
                        )}
                    </VStack>
                  </HStack>
                  <HStack gap={24} alignItems="center">
                    <Text variant="h3" weight="bold" color="white">
                      <AgentImg
                        src={AGENT_THUMB_IMAGES[player.agent]}
                        width={120}
                        height={120}
                        alt=""
                      />
                    </Text>
                    <HStack gap={8}>
                      <Text variant="h1" weight="bold" color="white">
                        {player.kills}
                      </Text>
                      <Text variant="h1" weight="bold" color="white">
                        -
                      </Text>
                      <Text variant="h1" weight="bold" color="white">
                        {player.deaths}
                      </Text>
                    </HStack>
                  </HStack>
                </VStack>
              )
            })}
            {teams && (
              <MatchIntroTeams
                teams={teams}
                highlightedAccountIds={highlightedAccountIds}
              />
            )}
          </VStack>
        </ContainerDiv>
      </ContentsDiv>
    </MatchIntroScaledContainer>
  )
}
