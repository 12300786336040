import React, { useMemo } from 'react'

import { ReactionGridItem } from 'core/feed/components/grid/grid-item-reaction'
import { Spinner } from 'components/core/spinner'

import { GridContainer, GridSeparator } from './styles'
import {
  MomentThumbnailProvider,
  MomentThumbnailConsumer,
} from './components/moment-thumbnail-provider'
import { Container, Spacing } from 'components/core/layout'
import { Button } from 'components/core/button'
// import { useFeedFetchMore } from 'core/feed/hooks'
import { useFeedState } from 'core/feed/context'
import { useGetReactionSignalQuery } from '__generated__'
import { useFeed } from 'core/feed/controller'
import { useBucketFeed } from './valorant-feed'

interface Props {
  openReview?: (signalId: string) => void
}

export const ReactionFeed = ({ openReview }: Props) => {
  const { type } = useFeedState()
  const { data, loading } = useFeed()
  const buckets = useBucketFeed(data?.moments?.data, type)

  return (
    <Spacing bottom={32}>
      {loading && <Spinner />}
      {buckets.map((bucket) => (
        <React.Fragment key={bucket.label}>
          <GridSeparator>{bucket.label}</GridSeparator>
          <GridContainer>
            {bucket.data.map((moment) => (
              <ChatGridItem
                key={moment.id}
                momentId={moment.id}
                onClick={() => openReview && openReview(moment.id)}
              />
            ))}
          </GridContainer>
        </React.Fragment>
      ))}

      <Container direction="row" justifyContent="center">
        <Button
          // onClick={() => setPage(page + 1)}
          disabled={!data?.moments?.pagination.hasNextPage || loading}
          isLoading={loading}
        >
          Load more
        </Button>
      </Container>
    </Spacing>
  )
}

interface ChatGridItemProps {
  momentId: string
  onClick: () => void
}

export const ChatGridItem = ({ momentId, onClick }: ChatGridItemProps) => {
  const { data } = useGetReactionSignalQuery({
    variables: { signalId: momentId },
  })

  const moment = useMemo(() => {
    if (data?.moment && data.moment.__typename === 'ChatMoment') {
      return data?.moment
    }
  }, [data?.moment])

  return moment ? (
    <MomentThumbnailProvider id={moment.id} key={moment.id}>
      <MomentThumbnailConsumer>
        {({ thumbnail }) => (
          <ReactionGridItem
            onClick={onClick}
            channel={{
              id: moment.channel.id,
              name: moment.channel.name,
              profileImageUrl: moment.channel.profileImageUrl || '',
            }}
            at={moment.startsAt}
            metadata={moment.metadata}
            score={parseFloat(moment.metadata?.score ?? 0) * 100}
            thumbnail={thumbnail}
            type={moment.type}
            synced={false}
          />
        )}
      </MomentThumbnailConsumer>
    </MomentThumbnailProvider>
  ) : null
}
