import React, { useMemo } from 'react'

import { Text } from 'components/core/text'
import { useComposerState } from 'core/composer/context'
import { useEditorState } from 'core/editor/context'
import { RANK_ICONS } from 'components/valorant/rank-icon'
import { ValorantMatchPlayerPreviewFragment } from '__generated__'
import { Justifications } from 'core/composer/template-sidebar/addons/killfeed'

import { usePlayerDisplayData, useGetPlayerLeaderboardRank } from './hooks'
import {
  ContentsDiv,
  PlayerDisplayScaledContainer,
  PlayerInfoContainerDiv,
  ProfileImg,
  RankImg,
  RankingContainerDiv,
} from './styled'
import { AddonContainer } from './styled'
import { VStack } from 'components/core/layout'
import { STANDARD_FORMAT } from 'core/exporter/constants'

export const PlayerDisplayWithComposerContext = () => {
  const { selection } = useEditorState()
  const { primaryVideoId, previewRect, playerDisplayOptions, selectedFormat } =
    useComposerState()
  const { width, height } = useMemo(() => {
    const width = selectedFormat?.id === STANDARD_FORMAT.id ? 1920 : 1080
    const height = selectedFormat?.id === STANDARD_FORMAT.id ? 1080 : 1920
    return { width, height }
  }, [selectedFormat])

  const scale = useMemo(() => {
    const height = selectedFormat?.id === STANDARD_FORMAT.id ? 1080 : 1920
    return (previewRect?.height ?? 0) / height
  }, [previewRect?.height, selectedFormat])
  const props = {
    y: playerDisplayOptions.y,
    justify: playerDisplayOptions.justify,
  }

  return selection?.startTime && primaryVideoId ? (
    <AddonContainer>
      <PlayerDisplay
        width={width}
        height={height}
        scale={scale}
        startTime={selection?.startTime ?? 0}
        videoId={primaryVideoId}
        {...props}
      />
    </AddonContainer>
  ) : (
    <React.Fragment />
  )
}

interface Props {
  width: number
  height: number
  scale: number
  videoId: string
  startTime: number
  justify: Justifications
  y: number
}

export const PlayerDisplay = ({
  width,
  height,
  videoId,
  startTime,
  scale,
  y,
  justify,
}: Props) => {
  const { accountId, displayName, profileImageUrl, playerRanks, matchEnd } =
    usePlayerDisplayData(videoId, startTime)

  const { leaderboardRank } = useGetPlayerLeaderboardRank(accountId, matchEnd)

  return (
    <PlayerDisplayStill
      width={width}
      height={height}
      accountId={accountId}
      displayName={displayName}
      profileImageUrl={profileImageUrl}
      playerRanks={playerRanks}
      leaderboardRank={leaderboardRank}
      scale={scale}
      y={y}
      justify={justify}
    />
  )
}

interface StillProps {
  width: number
  height: number
  accountId?: string
  playerStats?: { [accountId: string]: ValorantMatchPlayerPreviewFragment }
  displayName?: string
  profileImageUrl?: string
  currentTime?: number
  scale: number
  y: number
  justify: Justifications
  playerRanks?: { [accountId: string]: string }
  leaderboardRank?: number
}

export const PlayerDisplayStill = ({
  width,
  height,
  accountId,
  displayName,
  profileImageUrl,
  playerRanks,
  leaderboardRank,
  scale,
  y,
  justify,
}: StillProps) => {
  const rank = useMemo(() => {
    if (accountId) {
      return playerRanks?.[accountId]
    }
  }, [accountId, playerRanks])

  return playerRanks ? (
    <PlayerDisplayScaledContainer
      $scale={scale}
      $top={y}
      $justify={justify}
      $width={width}
      $height={height}
    >
      <ContentsDiv $justify={justify}>
        {rank && rank !== 'NOT_RANKED' && (
          <RankingContainerDiv>
            {rank && RANK_ICONS[rank] && <RankImg src={RANK_ICONS[rank]} />}
            {leaderboardRank && (
              <Text variant="h3" color="white" weight="semibold">
                #{leaderboardRank}
              </Text>
            )}
          </RankingContainerDiv>
        )}

        <PlayerInfoContainerDiv>
          <ProfileImg src={profileImageUrl} />
          <VStack justifyContent="center" alignItems="flex-start" gap={12}>
            <Text variant="h2" color="white" weight="semibold">
              {displayName}
            </Text>
          </VStack>
        </PlayerInfoContainerDiv>
      </ContentsDiv>
    </PlayerDisplayScaledContainer>
  ) : (
    <div />
  )
}
