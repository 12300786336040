import { get } from 'lodash'

import { ReactComponent as VolumeIcon } from 'icons/svgs/solid/volume.svg'
import { ReactComponent as DownloadIcon } from 'icons/svgs/solid/folder-download.svg'
import { ReactComponent as MutedIcon } from 'icons/svgs/solid/volume-mute.svg'
import { PostBuyEditFragment } from '__generated__'

import {
  ActionItemDiv,
  HoverActionsContentDiv,
  HoverActionsDiv,
  OverlayDiv,
  PreviewImg,
  StreamerInfoDiv,
  VideoContainerDiv,
} from './styled'
import { useCallback, useEffect, useRef, useState } from 'react'
import { usePrevious } from 'utils'
import { Spinner } from 'components/core/spinner'
import { Text } from 'components/core/text'

interface Props {
  hovered: boolean
  muted: boolean
  setMuted: (muted: boolean) => void
  editFrag: PostBuyEditFragment
}

export const EditDisplay = ({ editFrag, hovered, muted, setMuted }: Props) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const previousHovered = usePrevious(hovered)
  const [loadingVideo, setLoadingVideo] = useState(false)
  const videoUrl = get(
    editFrag,
    ['exports', '0', 'output', 'videos', '0', 'url'],
    ''
  )
  const thumbUrl = get(
    editFrag,
    ['exports', '0', 'output', 'thumbnails', '0', 'url'],
    ''
  )

  // using this since json data might not have it
  const streamerPhoto = get(editFrag, ['moment', 'channel', 'profileImageUrl'])
  const streamerName = get(editFrag, ['moment', 'channel', 'displayName'])

  const loadStart = useCallback(() => {
    setLoadingVideo(true)
  }, [])
  const onCanPlaythrough = useCallback(() => {
    setLoadingVideo(false)
    videoRef.current?.play()
  }, [])

  useEffect(() => {
    if (hovered === false && previousHovered === true) {
      setLoadingVideo(false)
    }
  }, [hovered, previousHovered, setMuted])

  return (
    <>
      {hovered && (
        <VideoContainerDiv>
          <video
            ref={videoRef}
            src={videoUrl}
            poster={thumbUrl}
            muted={muted}
            onLoadStart={loadStart}
            onCanPlayThrough={onCanPlaythrough}
          />
          <HoverActionsDiv>
            <HoverActionsContentDiv>
              <ActionItemDiv>
                <a href={videoUrl} onClick={(e) => e.stopPropagation()}>
                  <DownloadIcon />
                </a>
              </ActionItemDiv>
              <ActionItemDiv
                onClick={(e) => {
                  e.stopPropagation()
                  setMuted(!muted)
                }}
              >
                {muted ? <MutedIcon /> : <VolumeIcon />}
              </ActionItemDiv>
            </HoverActionsContentDiv>
          </HoverActionsDiv>
          {loadingVideo && (
            <OverlayDiv>
              <Spinner size={32} color="white" />
            </OverlayDiv>
          )}
        </VideoContainerDiv>
      )}
      <PreviewImg src={thumbUrl} alt="" />
      {streamerPhoto && streamerName && (
        <StreamerInfoDiv $isHovered={hovered}>
          <img src={streamerPhoto} alt="" />
          <Text variant="small">{streamerName}</Text>
        </StreamerInfoDiv>
      )}
    </>
  )
}
