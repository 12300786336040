import { useEffect, useMemo } from 'react'

import {
  useGetLeaderBoardRankingsLazyQuery,
  useGetValorantMatchesForVideoWithUserInfoQuery,
  useGetValorantMatchPageDetailQuery,
} from '__generated__'

export const usePlayerDisplayData = (videoId: string, startsAt: number) => {
  const { data: videoMatchesData } =
    useGetValorantMatchesForVideoWithUserInfoQuery({
      variables: { videoId },
    })

  const userData = useMemo(() => {
    return {
      displayName: videoMatchesData?.video?.user?.displayName,
      profileImageUrl: videoMatchesData?.video?.user?.profileImageUrl,
    }
  }, [
    videoMatchesData?.video?.user?.displayName,
    videoMatchesData?.video?.user?.profileImageUrl,
  ])

  const riotAccountIds = useMemo(
    () => videoMatchesData?.video?.user?.linkedAccounts.map((acc) => acc.puuid),
    [videoMatchesData?.video?.user?.linkedAccounts]
  )

  const matchId = useMemo(() => {
    const matches = videoMatchesData?.video?.valorantMatches
    if (!matches) return undefined
    return matches.find(
      (m) =>
        startsAt >= new Date(m.startedAt).valueOf() &&
        startsAt <= new Date(m.endedAt).valueOf() + 120 * 1000 // add some padding
    )?.id
  }, [startsAt, videoMatchesData?.video?.valorantMatches])

  const { data: matchDetailsData } = useGetValorantMatchPageDetailQuery({
    variables: { matchId: matchId ?? '' },
    skip: matchId === undefined,
  })
  const playerRanks = useMemo(() => {
    return matchDetailsData?.valorantMatch?.players.reduce(
      (memo, p) => ({
        ...memo,
        [p.account.id]: p.competitiveTier,
      }),
      {}
    ) as { [accountId: string]: string } | undefined
  }, [matchDetailsData?.valorantMatch?.players])

  const playerInfo = useMemo(() => {
    return matchDetailsData?.valorantMatch?.players.find((pl) =>
      riotAccountIds?.includes(pl.account.id)
    )
  }, [matchDetailsData?.valorantMatch?.players, riotAccountIds])
  const accountId = useMemo(
    () => playerInfo?.account.id,
    [playerInfo?.account.id]
  )

  const gameName = useMemo(() => playerInfo?.gameName, [playerInfo?.gameName])

  return useMemo(
    () => ({
      accountId,
      gameName,
      displayName: userData.displayName ?? undefined,
      profileImageUrl: userData.profileImageUrl ?? undefined,
      playerRanks,
      matchStart: matchDetailsData?.valorantMatch?.startedAt,
      matchEnd: matchDetailsData?.valorantMatch?.endedAt,
    }),
    [
      accountId,
      gameName,
      userData.displayName,
      userData.profileImageUrl,
      playerRanks,
      matchDetailsData?.valorantMatch?.startedAt,
      matchDetailsData?.valorantMatch?.endedAt,
    ]
  )
}

export type KillsData = {
  victim: { id: string; name: string }
  time: number
  round: number
  weapon?: string
  type?: string
}

export type DeathsData = {
  killer: { id: string; name: string }
  time: number
  weapon?: string
  type?: string
}

export type KillsByPlayer = {
  [gameName: string]: KillsData[]
}
export type DeathsByPlayer = {
  [gameName: string]: DeathsData[]
}

export const useGetPlayerLeaderboardRank = (
  accountId?: string,
  matchEnd?: string
) => {
  const [query, { data, loading, called }] =
    useGetLeaderBoardRankingsLazyQuery()

  useEffect(() => {
    if (accountId && matchEnd) {
      query({ variables: { accountIds: [accountId], before: matchEnd } })
    }
  }, [accountId, matchEnd, query])

  const leaderboardRank = useMemo(() => {
    return data?.valorantLeaderboard?.data.find(
      (item) => item.account?.id === accountId
    )?.leaderboardRank
  }, [accountId, data?.valorantLeaderboard?.data])

  return useMemo(() => {
    return {
      ready: called && !loading && data,
      leaderboardRank: leaderboardRank,
    }
  }, [called, data, leaderboardRank, loading])
}
