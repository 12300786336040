import { AGENT_THUMB_IMAGES } from 'components/valorant/agent'
import { useEffect, useMemo, useState } from 'react'
import { KDAOverlayStill } from '.'
import { ActionsFactory } from '../render-addons'
import { useFindAllKillsAndDeathsSlideshow, useKDAData } from './hooks'

interface Props {
  width: number
  height: number
  start: number
  end: number
  videoId: string
  y: number
  x: number
  currentTime: number
  actionsFactory: ActionsFactory
}

export const KDARender = ({
  width,
  height,
  start,
  end,
  videoId,
  y,
  x,
  currentTime,
  actionsFactory,
}: Props) => {
  const actions = useMemo(() => actionsFactory('kda'), [actionsFactory])
  const [preloaded, setPreloaded] = useState(false)
  const { accountId, playerKills, playerDeaths, agent, syncedWithOffset } =
    useKDAData(videoId, start)

  const slideshowTimes = useFindAllKillsAndDeathsSlideshow(
    playerKills ?? {},
    playerDeaths ?? {},
    accountId ?? '',
    start,
    end
  )

  const ready = useMemo(() => {
    return (
      accountId !== undefined &&
      preloaded &&
      playerKills[accountId] !== undefined &&
      playerDeaths[accountId] !== undefined &&
      syncedWithOffset
    )
  }, [accountId, playerDeaths, playerKills, preloaded, syncedWithOffset])

  useEffect(() => {
    const preloadImage = (src) =>
      new Promise((resolve, reject) => {
        const image = new Image()
        image.onload = resolve
        image.onerror = reject
        image.src = src
      })

    const preloadAll = async () => {
      const images = Object.entries(AGENT_THUMB_IMAGES).map(([_, v]) => {
        return v
      })
      // Preload a bunch of images in parallel
      await Promise.all(images.map((x) => preloadImage(x)))
      setPreloaded(true)
    }
    preloadAll()
  }, [])

  useEffect(() => {
    actions.addAddon()
  }, [actions])

  useEffect(() => {
    if (ready && slideshowTimes.length > 0) {
      actions.updateDiffTimes(slideshowTimes)
      actions.updateReadyStatus(true)
    }
  }, [actions, ready, slideshowTimes])

  return (
    <KDAOverlayStill
      width={width}
      height={height}
      accountId={accountId}
      killsByPlayer={playerKills}
      deathsByPlayer={playerDeaths}
      currentTime={currentTime}
      agent={agent ?? undefined}
      scale={1}
      x={x}
      y={y}
    />
  )
}
