import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import { media } from 'theme/media'

export const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`

export const VideoTitleWrapper = styled.div`
  margin-top: 8px;
  margin-bottom: 2px;
  & > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const VideoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(100px, 1fr));
  column-gap: 16px;
  row-gap: 32px;
  margin-top: 16px;

  ${media.phablet`
    grid-template-columns: repeat(2, minmax(100px, 1fr));
  `}
`

export const VideoPreviewImage = styled.div<{ backgroundImageUrl: string }>`
  transition: transform 1s var(--common-ease-out);
  transform: scale(1.000001);
  padding-bottom: 56.25%;
  background-image: url(${({ backgroundImageUrl }) => backgroundImageUrl});
  background-size: cover;
`

export const VideoPreview = styled.div`
  overflow: hidden;
  border-radius: 16px;

  &:hover > ${VideoPreviewImage} {
    transform: scale(1.1);
  }
`

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.text.primary};
`

export const VideoHeadingContainerDiv = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  gap: 16px;

  background-color: ${({ theme }) => theme.colors.background.primary};
  border-radius: 32px;
  padding: 16px;

  box-shadow: ${({ theme }) => theme.elevation.depth3};
`

export const VideoHeadingImage = styled.div<{ backgroundImageUrl: string }>`
  padding-bottom: 56.25%;
  background-image: url(${({ backgroundImageUrl }) => backgroundImageUrl});
  background-size: cover;
`

export const VideoHeadingImageContainerDiv = styled.div`
  flex: 0 0 384px;
  overflow: hidden;
  border-radius: 16px;
`
