import { darken, transparentize } from 'polished'
import styled, { css } from 'styled-components/macro'
import { fonts, fontWeights } from 'theme/typography'

export type ButtonVariant = 'primary' | 'secondary' | 'plain'
export type ButtonSize = 'small' | 'large'
export const StyledButton = styled.button<{
  variant: ButtonVariant
  rounded: boolean
  size: ButtonSize
  hoverColor?: string
  noPadding: boolean
  regular: boolean
  isLoading: boolean
}>`
  ${({ variant, color, hoverColor, theme }) => {
    if (variant === 'primary') {
      return css`
        background-color: ${color || theme.colors.blue};
        box-shadow: 0;
        color: ${theme.colors.static.neutrals.n8};
        &:not(:disabled, [disabled]):hover {
          background-color: ${hoverColor
            ? hoverColor
            : darken(0.1, color || theme.colors.blue)};
        }
      `
    } else if (variant === 'secondary') {
      return css`
        background-color: transparent;
        box-shadow: 0 0 0 2px ${theme.colors.neutrals.n6} inset;
        color: ${theme.colors.neutrals.n2};

        &:not(:disabled, [disabled]):hover {
          color: ${theme.colors.neutrals.n8};
          background-color: ${hoverColor || theme.colors.blue};
          box-shadow: 0 0 0 2px ${hoverColor || theme.colors.blue} inset;
        }
      `
    } else {
      return css`
        background-color: transparent;
        color: ${color || theme.colors.neutrals.n4};

        &:not(:disabled, [disabled]):hover {
          color: ${hoverColor ||
          (color ? darken(0.1, color) : theme.colors.blue)};
        }
      `
    }
  }}

  ${({ size, noPadding }) => css`
    font-size: ${size === 'large'
      ? fonts.text['text-2']['font-size']
      : fonts.text['text-3']['font-size']};
    line-height: ${!noPadding
      ? size === 'large'
        ? '40px'
        : '32px'
      : size === 'large'
      ? fonts.text['text-2']['line-height']
      : fonts.text['text-3']['line-height']};
  `}
  position: relative;
  font-weight: ${({ regular }) =>
    regular ? fontWeights.regular : fontWeights.semibold};
  display: inline-block;
  border: 0;
  padding: 0 ${({ noPadding }) => !noPadding && '16px'};
  border-radius: ${({ rounded, size }) =>
    rounded ? (size === 'large' ? 40 : 32) / 2 : 8}px;
  cursor: pointer;
  text-decoration: none;

  transition: background-color var(--common-animation-duration) ease-in-out,
    box-shadow var(--common-animation-duration) ease-in-out,
    color var(--common-animation-duration) ease-in-out,
    padding-right var(--common-animation-duration) ease-in-out,
    border var(--common-animation-duration) ease-in-out,
    border-left-color var(--common-animation-duration) ease-in-out,
    border-top-color var(--common-animation-duration) ease-in-out;

  &:disabled,
  &[disabled] {
    cursor: default;
    opacity: ${({ isLoading }) => (!isLoading ? 0.8 : 1)};
  }

  // isLoading indicator

  ${({ isLoading, variant, theme, color, noPadding }) => {
    const col =
      variant === 'primary'
        ? theme.colors.neutrals.n8
        : variant === 'secondary'
        ? theme.colors.neutrals.n2
        : color || theme.colors.neutrals.n4
    return isLoading
      ? css`
          padding-right: calc(${noPadding ? 12 : 16}px + 20px);

          &::after {
            content: '';
            position: absolute;
            border-radius: 100%;
            right: 8px;
            top: calc(50% + 1px);
            width: 0px;
            height: 0px;
            margin-top: -2px;
            border: 2px solid ${transparentize(0.5, col)};
            border-left-color: ${col};
            border-top-color: ${col};
            animation: spin 0.6s infinite linear, grow 0.3s forwards ease-out;
          }
          ${variant === 'secondary' &&
          css`
            &:not(:disabled, [disabled]):hover:hover::after {
              border: 2px solid ${transparentize(0.5, theme.colors.neutrals.n8)};
              border-left-color: ${theme.colors.neutrals.n8};
              border-top-color: ${theme.colors.neutrals.n8};
            }
          `}

          @keyframes spin {
            to {
              transform: rotate(359deg);
            }
          }
          @keyframes grow {
            to {
              width: 14px;
              height: 14px;
              margin-top: -8px;
              right: 13px;
            }
          }
        `
      : undefined
  }}
`
