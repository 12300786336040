import { useEffect, useMemo } from 'react'
import { SubtitleStill } from '.'
import { ActionsFactory } from '../render-addons'
import { useSubtitles, useFindAllSubtitlesSlideshow } from './hooks'

interface Props {
  start: number
  end: number
  videoId: string
  currentTime: number
  y: number
  width: number
  height: number
  actionsFactory: ActionsFactory
}

export const SubtitlesRender = ({
  start,
  end,
  videoId,
  y,
  currentTime,
  width,
  height,
  actionsFactory,
}: Props) => {
  const actions = useMemo(() => actionsFactory('subtitles'), [actionsFactory])
  const { subtitleData } = useSubtitles(videoId, start, end)

  const slideshowTimes = useFindAllSubtitlesSlideshow(subtitleData, start, end)

  const ready = useMemo(() => {
    return subtitleData !== undefined && slideshowTimes.length > 0
  }, [slideshowTimes.length, subtitleData])

  useEffect(() => {
    actions.addAddon()
  }, [actions])

  useEffect(() => {
    if (ready) {
      actions.updateDiffTimes(slideshowTimes)
      actions.updateReadyStatus(true)
    }
  }, [actions, ready, slideshowTimes])

  return (
    <SubtitleStill
      width={width}
      height={height}
      currentTime={currentTime}
      subtitleData={subtitleData}
      scale={1}
      y={y}
    />
  )
}
