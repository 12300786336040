import { Container, Content } from 'components/core/layout'
import { CollectionContextProvider } from 'core/collections/collections-context-provider'
import { GridView } from './grid-view'
import { SidebarView } from './sidebar'

export const CollectionsPage = () => {
  return (
    <CollectionContextProvider>
      <Content background="secondary">
        <Container direction="row" gap={16}>
          <SidebarView />
          <GridView />
        </Container>
      </Content>
    </CollectionContextProvider>
  )
}
