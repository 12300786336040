import styled from 'styled-components/macro'

import { ReactComponent as CloseIcon } from 'icons/svgs/solid/times.svg'

interface Props {
  onClick: () => void
}

export const CloseModalButton = ({ onClick }: Props) => (
  <CloseButton onClick={onClick} style={{ zIndex: 3 }}>
    <CloseIcon />
  </CloseButton>
)

const CloseButton = styled.button`
  position: absolute;
  right: 16px;
  top: 16px;
  width: 32px;
  height: 32px;

  border: 0;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.static.neutrals.n4};
  z-index: 1;

  transition: background-color 0.15s ease-in-out, opacity 0.15s ease-in-out;

  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.static.neutrals.n5};
  }

  &:active {
    opacity: 0.75;
  }
`
