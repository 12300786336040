import { RANK_ICONS } from 'components/valorant/rank-icon'
import { Justifications } from 'core/composer/template-sidebar/addons/killfeed'
import { useEffect, useMemo, useState } from 'react'
import { PlayerDisplayStill } from '.'
import { ActionsFactory } from '../render-addons'
import { useGetPlayerLeaderboardRank, usePlayerDisplayData } from './hooks'

interface Props {
  start: number
  end: number
  videoId: string
  y: number
  justify: Justifications
  width: number
  height: number
  actionsFactory: ActionsFactory
}

export const PlayerDisplayRender = ({
  start,
  videoId,
  y,
  justify,
  width,
  height,
  actionsFactory,
}: Props) => {
  const actions = useMemo(() => actionsFactory('playerInfo'), [actionsFactory])
  const [preloaded, setPreloaded] = useState(false)
  const { accountId, playerRanks, displayName, profileImageUrl, matchEnd } =
    usePlayerDisplayData(videoId, start)
  const { ready: leaderboardReady, leaderboardRank } =
    useGetPlayerLeaderboardRank(accountId, matchEnd)

  const slideshowTimes = useMemo(() => [], [])
  const ready = useMemo(() => {
    return accountId !== undefined && preloaded && leaderboardReady
  }, [accountId, leaderboardReady, preloaded])

  useEffect(() => {
    if (profileImageUrl) {
      const preloadImage = (src) =>
        new Promise((resolve, reject) => {
          const image = new Image()
          image.onload = resolve
          image.onerror = reject
          image.src = src
        })

      const preloadAll = async () => {
        const images = [profileImageUrl, ...Object.values(RANK_ICONS)].map(
          (v) => {
            return v
          }
        )
        // Preload a bunch of images in parallel
        await Promise.all(images.map((x) => preloadImage(x)))
        setPreloaded(true)
      }
      preloadAll()
    }
  }, [profileImageUrl])

  useEffect(() => {
    actions.addAddon()
  }, [actions])

  useEffect(() => {
    if (ready) {
      actions.updateDiffTimes(slideshowTimes)
      actions.updateReadyStatus(true)
    }
  }, [actions, ready, slideshowTimes])

  return (
    <PlayerDisplayStill
      width={width}
      height={height}
      accountId={accountId}
      displayName={displayName}
      profileImageUrl={profileImageUrl}
      playerRanks={playerRanks}
      leaderboardRank={leaderboardRank}
      scale={1}
      justify={justify}
      y={y}
    />
  )
}
