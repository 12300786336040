import React, { useMemo } from 'react'
import styled from 'styled-components/macro'

import {
  useGetContentMomentDetailQuery,
  // useGetFeedValorantSignalQuery,
} from '__generated__'
import { Spinner } from 'components/core/spinner'
// import { GridItem } from 'core/feed/components/grid/grid-item'
import { Container, Spacing } from 'components/core/layout'
import { useFeedState } from 'core/feed/context'
import { useBucketFeed } from 'core/feed/hooks'
import {
  MomentThumbnailConsumer,
  MomentThumbnailProvider,
} from 'page/feed/components/moment-thumbnail-provider'
import { fontWeights, getFontStyle } from 'theme/typography'
import { GridItemMoment } from 'core/feed/components/grid/grid-item-moment'
// import { SyncPlaylist } from 'core/valorant-match/sync-playlist'
// import { RootOnly } from 'core/auth/components'

interface Props {
  selectMomentId: (momentId: string) => void
}

export const ContentGrid = ({ selectMomentId }: Props) => {
  const { loading } = useFeedState()
  const buckets = useBucketFeed()
  // const loadNextPage = useFeedFetchMore()

  return (
    <Spacing bottom={32}>
      {loading && <Spinner />}
      {buckets &&
        buckets.map((bucket) => {
          return (
            <React.Fragment key={bucket.label}>
              <GridSeparator>{bucket.label}</GridSeparator>
              <GridContainer>
                {bucket.data.map((moment) => (
                  <MomentGridItem
                    key={moment.id}
                    momentId={moment.id}
                    selectMomentId={selectMomentId}
                  />
                ))}
              </GridContainer>
            </React.Fragment>
          )
        })}
      <Container direction="row" justifyContent="center">
        {/* <Button
          onClick={loadNextPage}
          disabled={!hasNextPage || loading}
          isLoading={hasNextPage && loading}
        >
          Load more
        </Button> */}
      </Container>
      {/* <RootOnly>{syncData && <SyncPlaylist matchesInfo={syncData} />}</RootOnly> */}
    </Spacing>
  )
}

interface MomentGridItemProps {
  momentId: string
  selectMomentId: (signalId: string) => void
}

export const MomentGridItem = ({
  momentId,
  selectMomentId,
}: MomentGridItemProps) => {
  const { data } = useGetContentMomentDetailQuery({
    variables: { momentId },
  })
  const moment = useMemo(() => {
    if (data?.moment && data.moment.__typename === 'MultiPerspectiveMoment') {
      return data?.moment
    }
  }, [data?.moment])

  return moment ? (
    <MomentThumbnailProvider id={moment.id} key={moment.id}>
      <MomentThumbnailConsumer>
        {({ thumbnail }) => (
          <GridItemMoment
            onClick={() => selectMomentId(moment.id)}
            editCount={moment.edits.length}
            title={moment.title ?? ''}
            channel={{
              id: moment.channel.id,
              name: moment.channel.name,
              profileImageUrl: moment.channel.profileImageUrl || '',
            }}
            thumbnail={thumbnail}
            at={moment.startsAt}
            ratings={moment.ratings.map((item) => item.rating)}
          />
        )}
      </MomentThumbnailConsumer>
    </MomentThumbnailProvider>
  ) : null
}

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  padding-bottom: 64px;

  &:last-child {
    padding-bottom: 32px;
  }
`

export const GridSeparator = styled.div`
  ${getFontStyle('text-1', 'semibold')};

  padding-bottom: 16px;
  text-align: center;
  font-weight: ${fontWeights.semibold};
  color: ${({ theme }) => theme.colors.neutrals.n5};
`
