import { sortBy } from 'lodash'

export const selectResolution = (
  vods: { resolution: string; url: string }[],
  containerHeight: number
) => {
  if (vods.length === 0) return undefined
  // filter out non-resolution specific types
  const filteredVods = vods.filter(({ resolution }) => resolution.includes('p'))
  const splitData = filteredVods.map(({ resolution, url }) => {
    const splitResolution = resolution.split('p')
    return {
      resolution: parseInt(splitResolution[0], 10),
      frames: splitResolution[1] !== '' ? parseInt(splitResolution[1], 10) : 30,
      url,
      // url: url.replace(
      //   'https://p.mmnts.live',
      //   'https://mmnts-proxy-g4nvq.ondigitalocean.app'
      // ),
    }
  })

  const sorted = sortBy(
    splitData,
    ({ resolution }) => -resolution,
    ({ frames }) => -frames
  )

  let result = sorted[0]
  for (const item of sorted) {
    if (item.resolution < containerHeight) {
      break
    }
    result = item
  }

  return result
}

export const CUSTOM_SOURCE = {
  '1918716976': [
    {
      url: 'https://video.rankedgods.com/1918716976/1080p60/index-dvr.m3u8',
      resolution: 'audio',
    },
    {
      url: 'https://video.rankedgods.com/1918716976/1080p60/index-dvr.m3u8',
      resolution: '160p',
    },
    {
      url: 'https://video.rankedgods.com/1918716976/480p/index-dvr.m3u8',
      resolution: '360p',
    },
    {
      url: 'https://video.rankedgods.com/1918716976/1080p60/index-dvr.m3u8',
      resolution: '480p',
    },
    {
      url: 'https://video.rankedgods.com/1918716976/1080p60/index-dvr.m3u8',
      resolution: '720p60',
    },
    {
      url: 'https://video.rankedgods.com/1918716976/1080p60/index-dvr.m3u8',
      resolution: '1080p60',
    },
    {
      url: 'https://video.rankedgods.com/1918716976/1080p60/index-dvr.m3u8',
      resolution: 'worst',
    },
    {
      url: 'https://video.rankedgods.com/1918716976/1080p60/index-dvr.m3u8',
      resolution: 'best',
    },
  ],
  '1918825455': [
    {
      url: 'https://video.rankedgods.com/1918825455/1080p60/index-dvr.m3u8',
      resolution: 'audio',
    },
    {
      url: 'https://video.rankedgods.com/1918825455/1080p60/index-dvr.m3u8',
      resolution: '160p',
    },
    {
      url: 'https://video.rankedgods.com/1918825455/480p/index-dvr.m3u8',
      resolution: '360p',
    },
    {
      url: 'https://video.rankedgods.com/1918825455/1080p60/index-dvr.m3u8',
      resolution: '480p',
    },
    {
      url: 'https://video.rankedgods.com/1918825455/1080p60/index-dvr.m3u8',
      resolution: '720p60',
    },
    {
      url: 'https://video.rankedgods.com/1918825455/1080p60/index-dvr.m3u8',
      resolution: '1080p60',
    },
    {
      url: 'https://video.rankedgods.com/1918825455/1080p60/index-dvr.m3u8',
      resolution: 'worst',
    },
    {
      url: 'https://video.rankedgods.com/1918825455/1080p60/index-dvr.m3u8',
      resolution: 'best',
    },
  ],
  '1918838984': [
    {
      url: 'https://video.rankedgods.com/1918838984/1080p60/index-muted-CCVPW55DCW.m3u8',
      resolution: 'audio',
    },
    {
      url: 'https://video.rankedgods.com/1918838984/1080p60/index-muted-CCVPW55DCW.m3u8',
      resolution: '160p',
    },
    {
      url: 'https://video.rankedgods.com/1918838984/480p/index-muted-CCVPW55DCW.m3u8',
      resolution: '360p',
    },
    {
      url: 'https://video.rankedgods.com/1918838984/1080p60/index-muted-CCVPW55DCW.m3u8',
      resolution: '480p',
    },
    {
      url: 'https://video.rankedgods.com/1918838984/1080p60/index-muted-CCVPW55DCW.m3u8',
      resolution: '720p60',
    },
    {
      url: 'https://video.rankedgods.com/1918838984/1080p60/index-muted-CCVPW55DCW.m3u8',
      resolution: '1080p60',
    },
    {
      url: 'https://video.rankedgods.com/1918838984/1080p60/index-muted-CCVPW55DCW.m3u8',
      resolution: 'worst',
    },
    {
      url: 'https://video.rankedgods.com/1918838984/1080p60/index-muted-CCVPW55DCW.m3u8',
      resolution: 'best',
    },
  ],
}
