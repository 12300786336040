import styled from 'styled-components/macro'

// Button
export const SwitchSpan = styled.span`
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  border-radius: 100vw;
  transition: var(--common-animation-duration);
  background: ${({ theme }) => theme.colors.neutrals.n8};
`

// Track
export const SwitchLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 32px;
  height: 20px;
  background: ${({ theme }) => theme.colors.neutrals.n5};
  border-radius: 100vw;
  position: relative;
  transition: background-color 0.2s;

  &:active ${SwitchSpan} {
    width: 20px;
  }
`

// Checkbox
export const StyledInput = styled.input`
  width: 0;
  height: 0;
  visibility: hidden;

  &:checked + ${SwitchLabel} {
    background: ${({ theme }) => theme.colors.green};
  }

  &:checked + ${SwitchLabel} ${SwitchSpan} {
    left: calc(100% - 2px);
    transform: translateX(-100%);
  }
`
