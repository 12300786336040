import styled from 'styled-components/macro'

export const WinLossGridDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(20, 20px);
  grid-template-rows: auto;
  gap: 1px;
`

export const WinLossDiv = styled.div<{ $win: boolean }>`
  width: 20px;
  height: 20px;
  background-color: ${({ $win, theme }) =>
    $win ? theme.colors.green : theme.colors.rose};
  color: white;
`
