import { useState, RefCallback, useCallback, useMemo, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'

export function useDimensions(): [
  RefCallback<HTMLElement | null>,
  DOMRect | undefined
] {
  const [dimensions, setDimensions] = useState<DOMRect | undefined>()

  const ref: RefCallback<HTMLElement | null> = useCallback(
    (node: HTMLElement | null) => {
      if (node) {
        setDimensions(node.getBoundingClientRect().toJSON())

        const resizeObserver = new ResizeObserver((entries) => {
          if (entries.length) {
            setDimensions(entries[0].target.getBoundingClientRect().toJSON())
          }
        })

        resizeObserver.observe(node)

        return () => {
          resizeObserver.unobserve(node)
          resizeObserver.disconnect()
        }
      }
    },
    []
  )

  return [ref, dimensions]
}

export function useQuery() {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}

export function useQueryParamFilters() {
  const { replace } = useHistory()
  const params = useQuery()
  const results: { [key: string]: string | string[] } = useMemo(() => {
    // const params = new URLSearchParams(window.location.search)
    const currentParams = {}
    for (const [key, value] of params) {
      if (value.includes(',')) {
        currentParams[key] = value.split(',')
      } else {
        currentParams[key] = value
      }
    }
    return currentParams
  }, [params])

  const setParam = useCallback(
    (key: string, value: string | string[] | undefined) => {
      const nextParams = new URLSearchParams(window.location.search)
      if (value === undefined) {
        nextParams.delete(key)
      } else if (Array.isArray(value)) {
        nextParams.set(key, value.join(','))
      } else {
        nextParams.set(key, value.toString())
      }

      replace({
        search: nextParams.toString(),
      })
    },
    [replace]
  )

  return useMemo(
    () => ({ params: results, setParam: setParam }),
    [results, setParam]
  )
}

export function useWatchLocalStorage<T>(key: string) {
  // this will hold the current value of localstorage key
  const [value, setValue] = useState<T | undefined>()
  const onStorageChange = useCallback(() => {
    const storedItem = localStorage.getItem(key)
    if (storedItem === null) {
      setValue(undefined)
      return
    }
    const item = JSON.parse(storedItem) as T
    setValue(item)
  }, [key])

  useEffect(() => {
    window.addEventListener('storage', onStorageChange)
    return () => {
      window.removeEventListener('storage', onStorageChange)
    }
  }, [onStorageChange])

  useEffect(() => {
    onStorageChange()
  }, [onStorageChange])

  return useMemo(() => value, [value])
}

export function useBlockScroll() {
  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [])
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  useEffect(() => {
    function handleResize() {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}
