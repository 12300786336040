import { Button } from 'components/core/button'
import styled, { useTheme } from 'styled-components/macro'

import { ReactComponent as PlusCircleIcon } from 'icons/svgs/solid/plus-circle.svg'
import { ReactComponent as MinusCircleIcon } from 'icons/svgs/solid/minus-circle.svg'
import { Text } from 'components/core/text'

interface Props {
  momentId: string
  selected: boolean
  toggleSelected: (id: string) => void
}

export const SelectableItem = ({
  selected,
  toggleSelected,
  momentId,
}: Props) => {
  const { colors } = useTheme()
  return (
    <SelectItemDiv>
      <IconButton
        size="small"
        color={selected ? colors.green : colors.neutrals.n5}
        onClick={() => {
          toggleSelected(momentId)
        }}
      >
        {selected ? (
          <>
            <MinusCircleIcon />
            <Text variant="small">Remove</Text>
          </>
        ) : (
          <>
            <PlusCircleIcon />
            <Text variant="small">Add</Text>
          </>
        )}
      </IconButton>
    </SelectItemDiv>
  )
}

const SelectItemDiv = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
`

export const IconButton = styled(Button).attrs({
  size: 'small',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  color: white;
  padding: 6px;

  svg {
    width: 12px;
    height: 12px;
    fill: white;
  }
`
