import { sortBy } from 'lodash'
import styled, { useTheme } from 'styled-components/macro'
import momentjs from 'moment'

import { ValorantMatchTeam } from '__generated__'
import { AGENT_THUMB_IMAGES } from 'components/valorant/agent'
import {
  ScoreboardEntry,
  SyncStatus,
  useScoreboard,
} from 'core/feed/components/scoreboard/hooks'
import { HStack } from 'components/core/layout'
import { Text } from 'components/core/text'
import { Spinner } from 'components/core/spinner'
import { ReactComponent as PerspectiveIcon } from 'core/feed/components/scoreboard/perspective.svg'
import { useMemo, useState } from 'react'
import { ConnectRiotAccount } from 'core/feed/components/connect-account/riot'
import { useIsRootUser } from 'core/auth/hooks'

type Props = {
  matchId: string
}

export const MetaScoreboard = ({ matchId }: Props) => {
  const { startedAt, map, teams, loading, error } = useScoreboard(matchId)
  const [selectedRiotAccount, setSelectedRiotAccount] = useState<
    | {
        accountId: string
        channelId: string
        gameName: string
        tagLine?: string
      }
    | undefined
  >()
  const theme = useTheme()

  const startTimeFormated = useMemo(
    () => momentjs(startedAt).format('MMM Do'),
    [startedAt]
  )

  return (
    <>
      {loading && (
        <HStack alignItems="baseline" gap={4}>
          <Text variant="text-3" color={theme.colors.text.secondary}>
            Loading scoreboard
          </Text>
          <Spinner color={theme.colors.text.secondary} size={12} />
        </HStack>
      )}
      {error && (
        <Text variant="text-3" color={theme.colors.text.secondary}>
          Error loading scoreboard
        </Text>
      )}
      {teams && map && (
        <Container>
          <MapHeader>
            <Text variant="text-2" as="div">
              {startTimeFormated}
            </Text>
            <Text variant="text-3" as="div">
              {map}
            </Text>
          </MapHeader>
          <TeamContainer>
            <ScoreboardTeam
              players={teams[ValorantMatchTeam.Blue]}
              viewAccountDetails={setSelectedRiotAccount}
            />
          </TeamContainer>
          <VsContainer>
            <Text variant="text-2">vs</Text>
          </VsContainer>
          <TeamContainer>
            <ScoreboardTeam
              players={teams[ValorantMatchTeam.Red]}
              viewAccountDetails={setSelectedRiotAccount}
            />
          </TeamContainer>
        </Container>
      )}
      {selectedRiotAccount && (
        <ConnectRiotAccount
          {...selectedRiotAccount}
          close={() => setSelectedRiotAccount(undefined)}
        ></ConnectRiotAccount>
      )}
    </>
  )
}

type ScoreboardTeamProps = {
  players: ScoreboardEntry[]
  reverse?: boolean
  viewAccountDetails?: (
    connectProps:
      | {
          accountId: string
          channelId: string
          gameName: string
          tagLine?: string
        }
      | undefined
  ) => void
}
export const ScoreboardTeam = ({
  players,
  reverse,
  viewAccountDetails,
}: ScoreboardTeamProps) => {
  const isRootUser = useIsRootUser()
  const onClick = useMemo(() => {
    return isRootUser && viewAccountDetails
      ? (
          accountId: string,
          gameName: string,
          tagLine: string,
          channelId: string
        ) => viewAccountDetails({ accountId, gameName, tagLine, channelId })
      : () => {}
  }, [isRootUser, viewAccountDetails])
  const playerByScore = sortBy(players, (player) => -player.stats.score)
  return (
    <>
      {playerByScore.map((player) => (
        <TeamEntry
          key={player.name}
          reverse={reverse}
          onClick={() => {
            onClick(
              player.accountId,
              player.name,
              player.tagLine ?? '',
              player.channelId ?? ''
            )
          }}
        >
          <HStack alignItems="center">
            <AgentImage src={AGENT_THUMB_IMAGES[player.agent as string]} />
            <NameText>{player.name}</NameText>
            <PerspectiveStatus
              hasAccount={player.hasAccount}
              hasPerspective={player.hasPerspective}
              syncStatus={player.syncStatus}
            />
          </HStack>
          <Text variant="small" mono={true}>
            {player.stats.kills} - {player.stats.deaths}
          </Text>
        </TeamEntry>
      ))}
    </>
  )
}

interface PerspectiveProps {
  hasPerspective: boolean
  hasAccount: boolean
  syncStatus: SyncStatus | undefined
}

export const PerspectiveStatus = ({
  hasPerspective,
  hasAccount,
  syncStatus,
}: PerspectiveProps) => {
  const theme = useTheme()
  return (
    <>
      {hasPerspective && hasAccount && syncStatus === SyncStatus.SUCCESS && (
        <PerspectiveIcon fill={theme.colors.gold} />
      )}
      {hasAccount &&
        hasPerspective &&
        syncStatus === SyncStatus.IN_PROGRESS && (
          <PerspectiveIcon fill={theme.colors.gold} />
        )}
      {hasAccount && hasPerspective && syncStatus === SyncStatus.ERROR && (
        <PerspectiveIcon fill={theme.colors.neutrals.n6} />
      )}
      {hasAccount && hasPerspective && syncStatus === SyncStatus.NO_VIDEO && (
        <PerspectiveIcon fill={theme.colors.neutrals.n6} />
      )}

      {hasAccount && !hasPerspective && (
        <PerspectiveIcon fill={theme.colors.neutrals.n6} />
      )}
    </>
  )
}

export const Container = styled.div``

export const MapHeader = styled.div`
  padding: 12px;
`

export const TeamContainer = styled.div`
  padding: 12px 12px;
  border: 1px solid ${({ theme }) => theme.colors.neutrals.n6};
  border-width: 1px 0;
`

export const VsContainer = styled.div`
  padding: 8px 0 8px 12px;
`

export const TeamEntry = styled(HStack).attrs({
  alignItems: 'center',
  justifyContent: 'space-between',
})`
  padding: 2px 0;
  color: ${({ theme }) => theme.colors.text.secondary};
`

export const AgentImage = styled.img<{ $size?: number }>`
  width: ${({ $size }) => ($size ? `${$size}px` : '16px')};
  height: ${({ $size }) => ($size ? `${$size}px` : '16px')};
  background-color: ${({ theme }) => theme.colors.static.neutrals.n2};
  border-radius: 4px;
`

export const NameText = styled(Text).attrs({ variant: 'text-3' })`
  margin: 0 4px;
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
