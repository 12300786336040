import styled from 'styled-components/macro'

export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: baseline;
`
export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`
export const StyledCheckbox = styled.div<{ checked?: boolean }>`
  display: inline-block;
  position: relative;
  width: 12px;
  height: 12px;
  background: ${({ theme }) => theme.colors.neutrals.n8};
  border: 1px solid ${({ theme }) => theme.colors.neutrals.n5};
  border-radius: 3px;

  &:after {
    content: '';
    position: absolute;
    width: 6px;
    height: 6px;
    background: ${({ theme }) => theme.colors.blue};
    border-radius: 2px;
    transform: translate(-50%, -50%)
      scale(${({ checked }) => (checked ? '1' : '0')});
    left: 50%;
    top: 50%;
    transition: transform 0.15s ease-in-out;
  }
`
