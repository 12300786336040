export const MATCH_HIGHLIGHTS = [
  {
    matchId: 'a7d45008-b681-42bb-95fb-8ffbdd330d9b',
    accountIds: [
      'HyypWs1IIDvhknXQlnwXxM6XvSRDNijYLjqsTXznBaAUMwE8QzYavgfVhZdOaDhaW0TOiIpfuUhm1g',
    ],
    editIds: [
      '7595acd4-f536-4eb5-95dd-8090747f78c2',
      'e3d7f84f-30c4-4732-b6fe-67ac3f019e57',
      'd13c11c9-be2f-4fae-b27c-7f8c68aa3846',
      '1c33f655-7509-4c69-9832-9aae0edd17d1',
      '900f1fa5-e368-4e80-b157-f7270555905d',
    ],
  },
  {
    matchId: '8087968d-67bc-4b8b-8f56-5af6354dc47d',
    accountIds: [
      'MRMy3LLQHew5zG3bdCgLq0F_bb_JN5kXiwZvljlxe4IF82_FlOFav5d9nQnm0lcl_FDWVOvHq88z_g',
    ],
    editIds: [
      'a7b8db90-3530-4a5a-8461-64194f3ac1a5',
      'b9df1892-01fb-49bb-b920-b4dffa7a8e48',
      '2fb874bf-5fbd-4575-9d68-d4d1ddfe15ec',
      '4656f29a-5f6d-410d-9591-27731490ca90',
      'f128073c-38a7-4598-a647-1e67577549d1',
      'f62c09cb-7714-4383-a5c7-2b55ca68637e',
      '350b1825-2fc7-4295-82eb-9b90d2b816aa',
      '927f968f-97c2-4abe-b57c-ff55fd1c24a0',
      '9b8e1815-ef30-49c4-8350-92d12decf384',
      '221605cf-2399-4872-bafb-f945b94ffa28',
      '1c7b6aa0-714f-473f-b0f6-adac8e8626e7',
    ],
  },
]
export const MOBILE_MATCH_HIGHLIGHTS = [
  {
    matchId: '6c9a73c9-4b19-427e-a32f-bb092b751033',
    accountIds: [
      'uePUbWu2d_dLSyp_llX7Z1-NYXTiylSRArIOpuP4aEWbGC_oIAp4kDp5PHZrZxDCy2t8sUCzI0WUiw',
    ],
    editIds: [
      'e789a5c5-9f0f-4cfb-aea0-a129eecf83eb',
      'bcc982e6-ed27-4e04-98cd-4045dc37c247',
      'b1081efe-6104-4b70-a183-75258470cb29',
      '19096659-e07a-4341-ad16-1b850077f36b',
      '23fa8fce-93c8-4bdc-8870-fcf6a6e42e18',
      '337498e7-6dd3-4717-83ab-bf84d699c75e',
      '77f06bc5-817c-4742-bf40-f1e9d159dbcb',
      '29092221-1783-4a51-8736-dc28fb5c0b7f',
    ],
  },
  {
    matchId: 'bda8a886-b98c-40cb-8a38-7eed4c30d344',
    accountIds: [
      'q9aXQ9B3BCw_DONscoGlpXE8Ua-J4UeVqgzir7nW_LPXlI8CCPldK21qxgLjJD1GNcm2jZGwRXOpDg',
    ],
    editIds: [
      '95f8895b-5670-444b-a16f-2c6d81bb1431',
      'de06d1a4-3928-4950-ac1c-97168a11a9b7',
      '102934ba-507d-4569-a736-d0eda4d9aa46',
      '28bc3f5f-1937-4a95-a729-a2e905c22dba',
      'b451c530-affe-4bc7-810d-e93133c5cece',
      '4fc8738e-6d0e-4483-a352-0dfea801aeba',
      '02238c18-68eb-4efe-a4ac-d16e4fb8084c',
      'abf002d6-0353-4942-9acc-487378ec662d',
    ],
  },
  {
    matchId: '038bf3e6-3b81-4264-9ad0-f5770e6534cb',
    accountIds: [
      'd-EOrFaN2fCC9OTy0nNKDOmg6Wz4tE5EIytx-B7raqGh1i8lFMqcwFLijLEXjnaA6DYCbNDXisK81w',
    ],
    editIds: [
      'b43970e2-dc30-42ed-86cb-15266f4560ad',
      'e9a1da18-0a5e-4b8a-86be-eebd0c58384f',
      '7f1fec9e-5d8d-483f-bb42-22a686e645c0',
      'd8cb474f-4aea-411b-abee-588ceab8eac7',
      '5a277270-564e-4518-917f-3cbd2cb2c508',
    ],
  },
  {
    matchId: '42ae834a-e23d-4036-99e1-426f63811438',
    accountIds: [
      'uePUbWu2d_dLSyp_llX7Z1-NYXTiylSRArIOpuP4aEWbGC_oIAp4kDp5PHZrZxDCy2t8sUCzI0WUiw',
    ],
    editIds: [
      'b2d7963e-0d49-42a0-879c-3274a0f0e3f6',
      '51d629e6-069b-46ad-9df0-94d726054029',
      'c27fe028-7ff3-4808-8252-b3f70775dab7',
      '0faa362d-345b-4868-a352-30254511533b',
      '7a5a1146-1873-445e-a362-b797b9892560',
    ],
  },
]

export const RISING_GODS = [
  {
    channelId: '445768007',
    start: '2022-10-20T02:23:55.352Z',
    end: '2023-01-10T00:00:00.000Z',
    editIds: [
      'b5a3bf77-f076-4418-9e4b-c759d320d013',
      'c9d79ec2-97f8-4235-9650-4d9b8715f71f',
      '963fa4b5-512a-4467-8d91-06b459a18fcb',
      '3a0bf5ea-d8a2-4a88-b9e7-2e5c6a70ad1a',
      '45c816f1-6370-4eaf-8939-e26e6113f815',
      'c3979375-2d9d-47e7-ba9a-98611c204b8e',
      '1e10d4b5-f86a-48b5-b0dc-24d1633c5c42',
      '2f44b163-0e6c-494f-81a6-ac9e66671a87',
      '748b8a82-e42f-40f2-a658-72b57b23b673',
    ],
  },
  {
    channelId: '48389176',
    start: '2022-11-20T20:56:37.318Z',
    end: '2022-12-04T22:50:19.240Z',
    editIds: [
      '041cf8ba-bb55-4f69-9f38-38a2b9138a1d',
      '2533fa6e-0c97-43c9-b32a-22a62a617cde',
      '33e86874-1cdd-4243-8bf0-aa6e9771436e',
      '9fb61b8d-c7d1-4e34-99e0-1a490f558116',
      '05397cb4-3448-4419-88e5-37f3482fae94',
      '565c8f3a-b33a-416b-be07-364926efa336',
      '77595b42-179d-4b71-b4ad-b2971150c317',
    ],
  },
  {
    channelId: '99169317',
    start: '2022-12-16T00:57:19.338Z',
    end: '2022-12-24T14:22:59.440Z',
    editIds: [
      '127a5185-e1fc-45c5-a34f-229d023bfa5d',
      '9eeb18ee-b6fc-4cc7-888a-0359399d2b1f',
      'a598b733-0bb1-4b83-b42d-4d37366918fb',
      'dd297a5f-5cae-4591-a308-2ba0c0f858fb',
      'efd5bd00-b558-4184-bb21-9b26ffc2e5f9',
      '2aa905ea-f00c-4b74-9e7d-35e5c8691d4b',
      '760f2c45-78c9-4952-83f1-1a1361a8753d',
      'c04e558d-1306-443d-a031-7480ca2a1d5e',
      '2086479a-30b1-4279-939c-dc746adf2931',
      'cfce4de8-d4e1-4525-b9a3-4f9e1fcfc133',
      'd7b52253-ca79-40d9-a45c-09b7af065157',
      '1d816c07-b9d0-4a88-89c5-f6919ac8c3d5',
    ],
  },
  {
    channelId: '263962513',
    start: '2022-12-23T15:21:03.039Z',
    end: '2022-12-24T18:13:13.461Z',
    editIds: [
      'b6995509-d689-4e0b-8df1-bad9b5a03cec',
      '77b401b1-a3e2-40fc-9c43-b811d0203d5e',
      '17d53579-d3d4-4b2c-af46-cee26c9104c4',
      '19324fe4-90a3-45f9-bb39-784ee63d2554',
      'acb135c3-8c5a-4dd7-a648-abc9c193bd11',
      'cbeffdb4-8dae-4694-a75c-d54d4ccfcefe',
      '21d9e366-c68c-434a-9d9f-7c44ac03e7d6',
      'a8a1e2f0-a4bd-4a10-8604-22a004b5b67b',
      '09bb8907-6c6d-4189-9aa6-4dff7ca15704',
      '81c78fdb-e3ec-4b89-8de4-2b19b93c2d59',
    ],
  },
]

export const FUNNY = {
  title: 'Funny',
  editIds: [
    'c83d855a-197b-40ab-bf1e-3434346adb03',
    'ea054ca4-3f57-4847-b2fb-3bc6f554e5ef',
    '3ccedc26-ce9a-48cc-9e01-05ec694cd505',
    '131fe930-a3bd-40c3-84b4-44d313a2897c',
    'b821c6a9-e5ed-48da-8f1c-bae3a50b1944',
    '6aef046c-7013-454f-a68a-7fec90e0f9cc',
  ],
}

export const CLUTCHES = {
  title: 'Clutches',
  editIds: [
    'ad8cabaa-3468-45cd-801d-673cdd59b9a5',
    'a8e1d95d-c834-48ab-92ff-5b84a99e1876',
    'c20093b3-fa57-4c53-9717-52b7bb68b6e9',
    '513bad72-28ae-4190-9a74-a36ab618a9a0',
  ],
}

export const ACES = {
  title: 'Aces',
  editIds: [
    '3d41c5cc-855d-47c9-bd91-a5b9d8f5d131',
    '4e57f575-5f4b-4fb9-8459-ec2a3928a364',
    '949575d7-1968-42dc-b71e-744bdef3a05c',
    '265e166e-8fbf-4f96-9df3-14fbdbc5554f',
    '69328834-2136-47aa-9fca-3f8b2f6c9400',
    '041cf8ba-bb55-4f69-9f38-38a2b9138a1d',
  ],
}
