import { useMemo } from 'react'

import { Text } from 'components/core/text'
import { Button } from 'components/core/button'
import { Container, Content, HStack, Spacing } from 'components/core/layout'
import { StreamingPlatform, useGetChannelVideosQuery } from '__generated__'
import { useQueryParamFilters } from 'utils/browser'
import { Spinner } from 'components/core/spinner'

import { VideoCard } from './video-card'
import { SectionHeader, VideoGrid } from './styled'
import { useCurrentChannel } from '../hooks'
import { ChannelContent, MainContent } from '../styled'
import { Sidebar } from '../sidebar'
import { ChannelNavigation } from '../navigation'

export const ChannelVideoListPage = () => {
  return (
    <Content background={'secondary'}>
      <HStack gap={16}>
        <Sidebar />
        <MainContent>
          <ChannelContent>
            <ChannelNavigation />
            <Spacing top={16}>
              <VideoSection />
            </Spacing>
          </ChannelContent>
        </MainContent>
      </HStack>
    </Content>
  )
}

export const VideoSection = () => {
  const { id, platform, urls } = useCurrentChannel()
  const { params, setParam } = useQueryParamFilters()

  const currentPage = useMemo(() => {
    const pageStr = params['page']
    return pageStr && typeof pageStr === 'string' ? parseInt(pageStr, 10) : 0
  }, [params])

  const { data: videoData, loading } = useGetChannelVideosQuery({
    variables: {
      channelId: id,
      platform: platform.toUpperCase() as StreamingPlatform,
      first: 14,
      after: currentPage === 0 ? undefined : (currentPage * 14 - 1).toString(),
    },
  })

  const videos = (videoData?.channel?.videos?.data || []).filter(
    (v) => v.stream?.endedAt !== null
  )

  return (
    <section>
      <SectionHeader>
        <Text variant={'h4'}>Latest videos</Text>
      </SectionHeader>
      {loading && (
        <Spacing top={32}>
          <HStack justifyContent="center" fullWidth>
            <Spinner size={48} />
          </HStack>
        </Spacing>
      )}
      <VideoGrid>
        {videos.map((video) => (
          <VideoCard
            key={video.id}
            id={video.id}
            title={video.title}
            publishedAt={video.publishedAt}
            thumbnailUrl={video.thumbnailUrl || undefined}
            url={`${urls.videos}/${video.id}`}
          />
        ))}
      </VideoGrid>
      <Spacing top={32}>
        <Container direction="row" justifyContent="center" gap={8}>
          <Button
            onClick={() => {
              const nextPage = currentPage - 1
              setParam('page', nextPage === 0 ? undefined : nextPage.toString())
            }}
            variant={currentPage === 0 || loading ? 'secondary' : 'primary'}
            disabled={currentPage === 0 || loading}
          >
            Prev Page
          </Button>
          <Button
            onClick={() => setParam('page', (currentPage + 1).toString())}
            variant={
              !videoData?.channel?.videos.pagination.hasNextPage || loading
                ? 'secondary'
                : 'primary'
            }
            disabled={
              !videoData?.channel?.videos.pagination.hasNextPage || loading
            }
          >
            Next Page
          </Button>
        </Container>
      </Spacing>
    </section>
  )
}
