import { useCallback, useEffect, useState } from 'react'

import { Container, Content, HStack, Spacing } from 'components/core/layout'
import { Text } from 'components/core/text'
import { FeedType, useFeedState } from 'core/feed/context'
import { EditValorantMomentModal } from 'core/feed/components/edit-modal'
import { SignalData, SignalReviewPage } from 'core/signal-review'
import { useFeedHelpers } from 'core/feed/helpers'
import { Button } from 'components/core/button'
import { Portal } from 'components/dialog/portal'

import { ValorantFeed } from './valorant-feed'
import { ReactionFeed } from './reaction-feed'
import { FilterHeader, FixedSignalReviewDiv, GridView, Sidebar } from './styles'
import { FeedTypesTabs } from './feed-types'
import { ChannelCollectionFilter } from './components/collection-select'
import { ValorantFeedFilters } from 'core/feed/components/filters/valorant-filters'
import { ReactionFeedFilters } from 'core/feed/components/filters/reaction-filters'

export const FeedPage = () => {
  const { type } = useFeedState()
  const { setFeedType: setType } = useFeedHelpers()
  const [signalReviewOpen, setSignalReviewOpen] = useState(false)
  const [signalReviewInitData, setSignalReviewInitData] = useState<
    SignalData | undefined
  >()
  const [editingMoment, setEditingMoment] = useState<
    { matchId: string; userId: string; start: string; end: string } | undefined
  >()

  const setFeedType = useCallback(
    (type: FeedType) => {
      setType(type)
    },
    [setType]
  )

  const openValorantReview = useCallback((signalId: string) => {
    setSignalReviewOpen(true)
    setSignalReviewInitData({
      signalId,
      signalType: 'ValorantMoment',
    })
  }, [])

  const openReactionReview = useCallback((signalId: string) => {
    setSignalReviewOpen(true)
    setSignalReviewInitData({
      signalId,
      signalType: 'ChatMoment',
    })
  }, [])

  useEffect(() => {
    setType(FeedType.ValorantSignal)
  }, [setType])

  return (
    <>
      <Content background="secondary">
        <Container direction="row" gap={16}>
          <Sidebar>
            <Spacing left="16px" bottom="16px">
              <FilterHeader>Filter</FilterHeader>
            </Spacing>
            <ChannelCollectionFilter />

            {type === FeedType.ValorantSignal ? <ValorantFeedFilters /> : null}
            {type === FeedType.ChatSignal ? <ReactionFeedFilters /> : null}

            <Spacing vertical={8}>
              <HStack alignItems="center" justifyContent="center">
                <Button
                  size="small"
                  variant="secondary"
                  onClick={() => setSignalReviewOpen(true)}
                >
                  Open Signal Review
                </Button>
              </HStack>
            </Spacing>
          </Sidebar>
          <GridView>
            <Spacing top={16} bottom={32}>
              <FeedTypesTabs selectedType={type} setFeedType={setFeedType} />
            </Spacing>
            <HStack alignItems="center" gap={8}>
              <Text variant="h4">Feed</Text>
            </HStack>
            {type === FeedType.ValorantSignal ? (
              <ValorantFeed openReview={openValorantReview} />
            ) : null}
            {type === FeedType.ChatSignal ? (
              <ReactionFeed openReview={openReactionReview} />
            ) : null}
          </GridView>
        </Container>
      </Content>
      {signalReviewOpen && (
        <Portal>
          <FixedSignalReviewDiv>
            <SignalReviewPage initialSignalData={signalReviewInitData} />
          </FixedSignalReviewDiv>
        </Portal>
      )}
      {editingMoment && (
        <EditValorantMomentModal
          {...editingMoment}
          onClose={() => setEditingMoment(undefined)}
        />
      )}
    </>
  )
}
