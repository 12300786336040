import { createContext, useContext } from 'react'

type ChatDisplayContextState = {
  allowedChatMomentTypes: string[] | undefined
}

const ChatDisplayContext = createContext<ChatDisplayContextState | undefined>(
  undefined
)

export const ChatDisplayProvider = ChatDisplayContext.Provider

export const useChatDisplayContext = () => {
  const context = useContext(ChatDisplayContext)
  if (context === undefined) {
    throw new Error(
      'useChatDisplayContext must be used within a ChatDisplayProvider'
    )
  }
  return context
}
