import { Text } from 'components/core/text'
import { NameDiv } from 'core/shared/timeline/valorant/heading/styled'

import { useGetSignalPlayerVideoTimelineQuery } from '__generated__'
import {
  SignalPlayerHeadingDiv,
  StreamerThumbImg,
} from 'core/signal-player/styled'

interface Props {
  videoId: string
}

export const PlayerTimelineHeading = ({ videoId }: Props) => {
  const { data } = useGetSignalPlayerVideoTimelineQuery({
    variables: { videoId },
  })

  return (
    <SignalPlayerHeadingDiv $selected={false} $availablePerspective={true}>
      {data && (
        <StreamerThumbImg
          $selected={false}
          alt="streamer thumbnail"
          src={data?.video?.user?.profileImageUrl ?? ''}
        />
      )}
      <NameDiv>
        <Text variant="text-3">{data?.video?.user?.displayName} </Text>
      </NameDiv>
    </SignalPlayerHeadingDiv>
  )
}
