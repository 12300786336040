import { VideoHeading } from 'page/channel/videos/video-heading'
import { useParams } from 'react-router'
import { useGetVideoDetailQuery } from '__generated__'
import { PopularMoments } from './popular-moments'

interface Props {
  channelId: string
}

export const VideoDetailPage = ({ channelId }: Props) => {
  const { videoId } = useParams<{ videoId: string }>()
  const { data: videoData } = useGetVideoDetailQuery({
    variables: { videoId },
  })

  return (
    <div>
      {videoData && videoData.video && videoData.video.stream && (
        <>
          <VideoHeading
            title={videoData.video.title}
            startedAt={videoData.video.stream.startedAt}
            endedAt={videoData.video.stream.endedAt}
            thumbnailUrl={videoData.video.thumbnailUrl || undefined}
          />
          <PopularMoments
            channelId={channelId}
            videoStart={videoData.video.stream.startedAt}
            videoEnd={videoData.video.stream.endedAt ?? ''}
          />
        </>
      )}
    </div>
  )
}
