import styled from 'styled-components/macro'
import { getFontStyle } from 'theme/typography'

export const ListContainer = styled.div`
  padding: 0 16px;
`

export const Heading = styled.h5`
  ${getFontStyle('text-3', 'semibold')}
  padding: 16px;
`

type ListItemProps = {
  selected?: boolean
}

export const ListItem = styled.li<ListItemProps>`
  position: relative;

  button {
    width: 100%;
    padding: 12px 16px;

    background: ${({ theme, selected = false }) =>
      selected ? theme.colors.blue : 'transparent'};
    border: 0;
    outline: 0;
    border-radius: 8px;

    ${getFontStyle('text-3', 'semibold')}
    text-align: left;
    color: ${({ theme, selected = false }) =>
      selected ? theme.colors.neutrals.n8 : theme.colors.neutrals.n4};

    transition: color 0.2s ease-in-out;

    &:hover {
      cursor: pointer;

      color: ${({ theme, selected = false }) =>
        !selected && theme.colors.neutrals.n3};
    }
  }
`

export const BottomListItem = styled(ListItem)`
  border-top: 1px solid ${({ theme }) => theme.colors.neutrals.n6};

  button {
    color: ${({ theme }) => theme.colors.neutrals.n3};
    &::after {
      content: '+';
      position: absolute;
      left: 0;

      pointer-events: none;
      color: ${({ theme }) => theme.colors.neutrals.n3};
    }
  }
`

export const UnorderedList = styled.ul`
  ${ListItem}:not(:last-child) {
    margin-bottom: 8px;
  }
`
