import { useState } from 'react'
import { useParams } from 'react-router'

import {
  useFindValorantMomentsForVideo,
  ValorantMomentHighlight,
} from '../hooks'
import { ValorantMatchPage } from '../match'
import { ValorantMatchProvider } from '../match/context'
import { ValorantMomentsPlaylist } from '../playlist'
import { SidebarPlaylist, ValorantMatchPageDiv } from '../styled'

// 48389176 ASUNA
// 36340781 TARIK
// 103262684 JASONR
// 244407024 SYDEON
// 173758090 QUARTERJADE

export const VideoValorantMomentPlayer = () => {
  const { videoId } = useParams<{ videoId: string }>()
  const highlights = useFindValorantMomentsForVideo(videoId)
  const [selectedHighlight, setSelectedHighlight] = useState<
    ValorantMomentHighlight | undefined
  >()

  return (
    <ValorantMatchPageDiv>
      <SidebarPlaylist>
        <ValorantMomentsPlaylist
          selectedHighlight={selectedHighlight}
          setSelectedHighlight={setSelectedHighlight}
          highlights={highlights}
        ></ValorantMomentsPlaylist>
      </SidebarPlaylist>
      {selectedHighlight ? (
        <ValorantMatchProvider>
          <ValorantMatchPage
            matchId={selectedHighlight.matchId}
            highlight={selectedHighlight}
          />
        </ValorantMatchProvider>
      ) : null}
    </ValorantMatchPageDiv>
  )
}
