import { HStack } from 'components/core/layout'
import { Text } from 'components/core/text'
import { Justifications } from 'core/composer/template-sidebar/addons/killfeed'
import styled from 'styled-components/macro'

export const AddonContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
`

export const MatchIntroScaledContainer = styled.div<{
  $scale: number
  $top?: number
  $justify: Justifications
  $width?: number
  $height?: number
}>`
  position: absolute;
  width: ${({ $width }) => ($width ? `${$width}px` : '1080px')};
  height: ${({ $height }) => ($height ? `${$height}px` : '1920px')};
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.65);

  padding: 10px;
  transform-origin: top left;
  transform: scale(${({ $scale }) => $scale});
  pointer-events: none;
  padding: 0 ${({ $scale }) => $scale * 80}px; // padding for safe area
  padding-top: ${({ $top }) => ($top ? `${$top}px` : 0)};

  align-items: ${({ $justify }) =>
    $justify === Justifications.left
      ? 'flex-start'
      : $justify === Justifications.center
      ? 'center'
      : 'flex-end'};
`

export const ContentsDiv = styled.div<{ $justify: Justifications }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: ${({ $justify }) =>
    $justify === Justifications.left
      ? 'flex-start'
      : $justify === Justifications.center
      ? 'center'
      : 'flex-end'};
`

export const ContainerDiv = styled.div`
  padding: 64px 100px;
  border-radius: 64px;
  background-color: rgba(0, 0, 0, 0.75);
  border: 2px solid rgba(255, 255, 255, 0.5);
`

export const ProfileImg = styled.img`
  width: 180px;
  height: 180px;
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.5);
`

export const UserNameText = styled(Text)`
  font-size: 64px;
`

export const AgentImg = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.5);
`

export const TeamContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 0 8px;
  min-width: 460px;

  &:first-child {
    border-right: 1px solid ${({ theme }) => theme.colors.neutrals.n6};
  }
`

export const TeamEntry = styled.div<{ $highlighted: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 24px;
  padding: 8px 12px;
  border-radius: 8px;

  background-color: ${({ $highlighted }) =>
    $highlighted ? '#ffd166bd' : 'transparent'};
`

export const PlayerEntryDiv = styled(HStack).attrs({ alignItems: 'center' })`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 0;
  width: 100%;
`

export const AgentImage = styled.img`
  width: 32px;
  height: 32px;
  background-color: ${({ theme }) => theme.colors.static.neutrals.n2};
  border-radius: 16px;
`

export const PlayerNameText = styled(Text)`
  max-width: 220px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const PlayerScoreText = styled(Text)`
  min-width: 28px;
`
