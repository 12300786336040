import React from 'react'
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  RouteProps,
} from 'react-router-dom'

import './App.css'
import 'video.js/dist/video-js.css'
import 'simplebar/src/simplebar.css'
import { VideoPage } from 'page/video'
import { MomentPage } from 'page/moment'
import { LoginPage } from 'page/main/login'
import { MainPage } from 'page/main'
import { AuthPage, UnauthorizedPage } from 'page/auth'
import { ChannelPage } from 'page/channel'
import { OrganizationPage } from 'page/organization'
import { ProtectedRoute } from 'core/auth/protected-route'
import { VideoReviewPage } from 'page/video-review'
import { AdminPage } from 'page/admin'
import { ClientLoginPage } from 'page/main/client-login'
import { ClientCampaignPage } from 'page/client/campaign'
import { ValorantPage } from 'page/valorant'
import { ClientCollectionPage } from 'page/client/collection'
import { Auth0AuthProvider } from 'core/auth/auth0-auth-provider'
import { ClientAuthProvider } from 'core/auth/client-auth-provider'
import { MomentCollectionPage } from 'page/moment-collection'
import { EventCreator } from 'page/event-creator'
import { ComposerPage } from 'page/composer'
import { ChannelCollectionPage } from 'page/channel-collection'
import { SignalReviewRoutePage } from 'core/signal-review'
import AppNavigation from 'core/app-navigation'
import { Logout as ClientLogout } from 'page/client/logout/logout'
import { AddonsRenderPage } from 'core/video-addons/render-addons'
import { DarkTidePostBuyPage } from 'core/post-buy'
import { RosterPage } from 'page/roster'
import { GamesActivationPage } from 'page/games'
import { VideoSubsectionPage } from 'page/games/subsections'
import { AmericasCardroomPostBuyPage } from 'core/post-buy/americas-cardroom'
import { RankedGodsRouter } from 'page/ranked-gods'
import { AutoCutMatchPage } from 'core/auto-cut/match/match'
import { AddonsRenderEditPage } from 'core/video-addons/render-addons-edit'
import { RiotAuthCallback } from 'page/account-linking/riot'
import { TwitchAuthCallback } from 'page/account-linking/twitch'

const App: React.FC = () => {
  const { pathname } = useLocation()
  return (
    <>
      <Switch>
        {/* strip the trailing slash from the url */}
        <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
        <PublicRouter path="/p/*" />
        <AppRouter path="/" />
      </Switch>
    </>
  )
}

const PublicRouter = (props: RouteProps) => {
  return (
    <Route {...props}>
      <ClientAuthProvider>
        <Switch>
          <Route
            path="/p/collection/:organizationSlug/:momentCollectionSlug"
            exact
          >
            <ClientCollectionPage />
          </Route>
          <Route
            path={`/p/collection/:organizationSlug/:momentCollectionSlug/viewer`}
            exact
          >
            <MomentCollectionPage />
          </Route>

          <Route path="/p/logout" exact>
            <ClientLogout />
          </Route>
        </Switch>
      </ClientAuthProvider>
    </Route>
  )
}

const AppRouter = (props: RouteProps) => {
  return (
    <Route {...props}>
      <Auth0AuthProvider>
        <Switch>
          <Route path="/auth">
            <AuthPage />
          </Route>
          <Route path="/login" exact>
            <LoginPage />
          </Route>
          <Route path="/" exact>
            <MainPage />
          </Route>
          <Route path="/client" exact>
            <ClientLoginPage />
          </Route>
          <Route path="/client/campaigns/:campaignSlug" exact>
            <ClientCampaignPage />
          </Route>
          <Route path="/oauth-callback/riotgames">
            <RiotAuthCallback />
          </Route>
          <Route path="/oauth-callback/twitch">
            <TwitchAuthCallback />
          </Route>
          <ProtectedRoute path="/channel/:id">
            <AppNavigation />
            <ChannelPage />
          </ProtectedRoute>
          <Route path="/unauthorized">
            <UnauthorizedPage />
          </Route>

          <Route path="/events/americas-cardroom" exact>
            <AmericasCardroomPostBuyPage />
          </Route>
          <Route path="/events/darktide" exact>
            <DarkTidePostBuyPage />
          </Route>

          <ProtectedRoute path="/auto-cut/match/:matchId" exact>
            <AppNavigation />
            <AutoCutMatchPage />
          </ProtectedRoute>
          <ProtectedRoute path="/games/activations" exact>
            <AppNavigation />
            <GamesActivationPage />
          </ProtectedRoute>
          <ProtectedRoute path="/moments/subsection" exact>
            <AppNavigation />
            <VideoSubsectionPage />
          </ProtectedRoute>

          <ProtectedRoute permissions={['root']} path="/ranked-gods">
            <RankedGodsRouter />
          </ProtectedRoute>

          {/*
            This is only used for creating static json file for the postbuys 
            that can then be completely public while using all the api data it creates
            */}
          {/* <Route path={`/post-buy-generator`} exact>
            <PostBuyGenerator />
          </Route> */}

          <ProtectedRoute path="/addons/killfeed">
            <AddonsRenderPage />
          </ProtectedRoute>
          <ProtectedRoute path="/addons/edit/:editId">
            <AddonsRenderEditPage />
          </ProtectedRoute>
          <ProtectedRoute path="/video/:videoId">
            <VideoPage />
          </ProtectedRoute>
          <ProtectedRoute path="/moment/:momentId">
            <MomentPage />
          </ProtectedRoute>
          <ProtectedRoute path="/m/:momentId/editor/:editId">
            <ComposerPage />
          </ProtectedRoute>

          <ProtectedRoute path="/roster/:channelName?">
            <AppNavigation />
            <RosterPage />
          </ProtectedRoute>
          <ProtectedRoute path="/c/:collectionId">
            <AppNavigation />
            <ChannelCollectionPage />
          </ProtectedRoute>

          <ProtectedRoute permissions={['root']} path="/admin">
            <AdminPage />
          </ProtectedRoute>
          <ProtectedRoute permissions={['root']} path="/valorant">
            <ValorantPage />
          </ProtectedRoute>

          <ProtectedRoute permissions={['root']} path="/video-review/:videoId">
            <VideoReviewPage />
          </ProtectedRoute>

          <ProtectedRoute path="/review/:signalId">
            <SignalReviewRoutePage />
          </ProtectedRoute>

          <ProtectedRoute permissions={['root']} path="/event">
            <EventCreator />
          </ProtectedRoute>

          <ProtectedRoute path="/o/:organization">
            <AppNavigation />
            <OrganizationPage />
          </ProtectedRoute>
        </Switch>
      </Auth0AuthProvider>
    </Route>
  )
}

export default App
