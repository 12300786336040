import { Button } from 'components/core/button'
import { HStack, VStack } from 'components/core/layout'
import { Text } from 'components/core/text'
import { Justifications } from 'core/composer/template-sidebar/addons/killfeed'
import {
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
  useReducer,
  useCallback,
} from 'react'
import { useQuery } from 'utils/browser'
import { KDARender } from './kda/render-page'
import { KillfeedTypes } from './kill-feed'
import { KillfeedRender } from './kill-feed/render-page'
import { MatchIntroRender } from './match-intro/render-page'
import { PlayerDisplayRender } from './player-display/render-page'
import { SubtitlesRender } from './subtitles/render-page'

//data looks like
type AddonData = {
  start: number
  end: number
  w: number
  h: number
  vId: string
  killfeed?: {
    y: number
    just: Justifications
    type: KillfeedTypes
  }
  playerInfo?: {
    y: number
    just: Justifications
  }
  kda?: {
    y: number
    x: number
  }
  matchIntro?: {
    y: number
  }
  subtitles?: {
    y: number
  }
}

export type ActionsFactory = (key: string) => {
  addAddon: () => void
  updateReadyStatus: (ready: boolean) => void
  updateDiffTimes: (diffTimes: number[]) => void
}

export const AddonsRenderPage = () => {
  const params = useQuery()
  const [state, dispatch] = useReducer(reducer, {})

  // Helper function that binds the key argument for the dispatch functions
  const actionsFactory = useCallback(
    (key: string) => ({
      addAddon: () => dispatch({ type: 'addAddon', key }),
      updateReadyStatus: (ready: boolean) =>
        dispatch({ type: 'updateReadyStatus', key, ready }),
      updateDiffTimes: (diffTimes: number[]) =>
        dispatch({ type: 'updateDiffTimes', key, diffTimes }),
    }),
    []
  )

  const [frameIdx, setFrameIdx] = useState(0)
  const data = useMemo(
    () =>
      JSON.parse(
        atob(decodeURIComponent(params.get('data') ?? ''))
      ) as AddonData,
    [params]
  )

  const includedAddons = useMemo(() => {
    const addons: string[] = []
    if (data.killfeed) addons.push('killfeed')
    if (data.playerInfo) addons.push('playerInfo')
    if (data.kda) addons.push('kda')
    if (data.matchIntro) addons.push('matchIntro')
    if (data.subtitles) addons.push('subtitles')
    return addons
  }, [data])

  const ready = useMemo(() => {
    const allReady = includedAddons.every((key) => state[key]?.ready)
    return allReady
  }, [includedAddons, state])

  const slideshowTimes = useMemo(() => {
    const diffTimes = includedAddons.map((key) => state[key]?.diffTimes ?? [])
    // append start and end times to the array of arrays
    const allTimes = [data.start, ...diffTimes, data.end]
    // Flatten the array of arrays, remove duplicates, and sort
    const times = Array.from(new Set(allTimes.flat())).sort((a, b) => a - b)
    // create an array with times and durations, durations are the time between each time, except the last one
    const timesWithDurations = times.map((time, idx) => ({
      time,
      duration: (times[idx + 1] ?? 0) - time,
    }))
    timesWithDurations.pop()

    return timesWithDurations
  }, [data.end, data.start, includedAddons, state])

  const selectedSlide = useMemo(
    () => slideshowTimes[frameIdx],
    [frameIdx, slideshowTimes]
  )

  useLayoutEffect(() => {
    // @ts-ignore
    window.ready = ready
    // @ts-ignore
    window.frameIdx = frameIdx
    // @ts-ignore
    window.done = frameIdx >= slideshowTimes.length - 1
    // @ts-ignore
    window.frameDuration = selectedSlide?.duration
  }, [frameIdx, ready, selectedSlide?.duration, slideshowTimes.length])

  useEffect(() => {
    const elem = window.document.getElementById('root')
    if (elem) {
      elem.style.backgroundColor = 'transparent'
    }
  }, [])

  return (
    <>
      {data.killfeed && (
        <KillfeedRender
          width={data.w}
          height={data.h}
          start={data.start}
          end={data.end}
          videoId={data.vId}
          y={data.killfeed.y}
          justify={data.killfeed.just}
          currentTime={selectedSlide?.time ?? 0}
          actionsFactory={actionsFactory}
          type={data.killfeed.type}
        />
      )}

      {data.playerInfo && (
        <PlayerDisplayRender
          width={data.w}
          height={data.h}
          start={data.start}
          end={data.end}
          videoId={data.vId}
          y={data.playerInfo.y}
          justify={data.playerInfo.just}
          actionsFactory={actionsFactory}
        />
      )}

      {data.kda && (
        <KDARender
          width={data.w}
          height={data.h}
          start={data.start}
          end={data.end}
          videoId={data.vId}
          currentTime={selectedSlide?.time ?? 0}
          y={data.kda.y}
          x={data.kda.x}
          actionsFactory={actionsFactory}
        />
      )}

      {data.matchIntro && (
        <MatchIntroRender
          width={data.w}
          height={data.h}
          start={data.start}
          end={data.end}
          videoId={data.vId}
          currentTime={selectedSlide?.time ?? 0}
          y={data.matchIntro.y}
          justify={Justifications.center}
          actionsFactory={actionsFactory}
        />
      )}

      {data.subtitles && (
        <SubtitlesRender
          actionsFactory={actionsFactory}
          width={data.w}
          height={data.h}
          start={data.start}
          end={data.end}
          videoId={data.vId}
          currentTime={selectedSlide?.time ?? 0}
          y={data.subtitles.y}
        />
      )}

      <div style={{ position: 'absolute', left: 1921 }}>
        <VStack>
          <HStack gap={8} alignItems="center">
            <Text variant="text-2">Frame:</Text>
            <div id="frameIdx">{frameIdx}</div>
          </HStack>
          <HStack gap={8} alignItems="center">
            <Text variant="text-2">Duration:</Text>
            <div id="duration">{selectedSlide?.duration}</div>
          </HStack>
          <HStack gap={8} alignItems="center">
            <Text variant="text-2">Status:</Text>
            <div id="status">{ready ? 'Ready' : 'Loading'}</div>
          </HStack>
        </VStack>
        {frameIdx < slideshowTimes.length - 1 && (
          <Button
            variant="secondary"
            onClick={() => {
              setFrameIdx((currentIdx) => currentIdx + 1)
            }}
          >
            Next Slide
          </Button>
        )}
      </div>
    </>
  )
}

type State = { [key: string]: { ready: boolean; diffTimes: number[] } }

type Action =
  | { type: 'addAddon'; key: string }
  | { type: 'updateReadyStatus'; key: string; ready: boolean }
  | { type: 'updateDiffTimes'; key: string; diffTimes: number[] }

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'addAddon':
      return {
        ...state,
        [action.key]: { ready: false, diffTimes: [] },
      }
    case 'updateReadyStatus':
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          ready: action.ready,
        },
      }
    case 'updateDiffTimes':
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          diffTimes: action.diffTimes,
        },
      }
    default:
      return state
  }
}
