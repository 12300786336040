import { ChatTimeline } from 'core/editor/timeline/moments/chat'
import { ValorantGamesTimeline } from 'core/editor/timeline/valorant-games'
import { BrowsingMode, useVideoExplorerState } from 'page/video/context'
import React from 'react'

interface BrowsingTimelineDetailsProps {
  videoIds: string[]
}

export const BrowsingTimelineDetails: React.FC<BrowsingTimelineDetailsProps> =
  ({ videoIds }) => {
    const { browsingMode } = useVideoExplorerState()
    return (
      <>
        {browsingMode === BrowsingMode.VALORANT
          ? videoIds.map((vidId) => (
              <ValorantGamesTimeline key={vidId} videoId={vidId} />
            ))
          : null}

        {browsingMode === BrowsingMode.CHAT
          ? videoIds.map((vidId) => (
              <ChatTimeline key={vidId} videoId={vidId} />
            ))
          : null}
      </>
    )
  }
