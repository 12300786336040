export interface TitledAspectRatio {
  title: string
  width: number
  height: number
}

const OFF = { title: 'off', width: 0, height: 0 }
const CUSTOM = { title: 'custom', width: 1, height: 1 }
const AR16_9 = { title: '16_9', width: 16, height: 9 }
const AR9_16 = { title: '9_16', width: 9, height: 16 }
const AR1_1 = { title: '1_1', width: 1, height: 1 }
// const AR4_5 = { title: '4_5', width: 4, height: 5 }

export const ASPECT_RATIO_TYPES = {
  OFF: OFF,
  AR16_9: AR16_9,
  AR1_1: AR1_1,
  // AR4_5: AR4_5,
  AR9_16: AR9_16,
  CUSTOM: CUSTOM,
}

export const ASPECT_RATIO_PRESETS = [AR16_9, AR1_1]
