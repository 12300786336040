import styled, { css } from 'styled-components/macro'
import { colors } from 'utils/colors'

export const VideoEditorPageDiv = styled.div`
  position: relative;
  width: calc(100%);
  /* max-width: 1440; */
  margin: 0 auto;
  display: grid;
  grid-template-columns: 250px 1fr;
  grid-auto-rows: 1fr;
  height: 100vh;
`

export const EditorSideMenuDiv = styled.div`
  position: relative;
  width: 250px;
  height: 100%;
  border-right: 1px solid black;
`

export const VideoEditorContentsDiv = styled.div`
  position: relative;
  padding: 12px;
  display: grid;
  grid-template-rows: 1fr 400px;
  grid-template-columns: 1fr;
`

export const VideoPlayerSectionDiv = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

export const VideoEditorDiv = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 4fr 1fr;
`

export const ActiveDuoDiv = styled.div`
  position: relative;
  display: grid;
  grid-column: 1;
  grid-row: 1;
`

export const PreviewPlayerContainerDiv = styled.div`
  position: relative;
  grid-column: 2;
  grid-row: 1;

  display: grid;
  /* grid-template-columns: repeat(2, 1fr); */
  grid-template-rows: repeat(4, 1fr);
  overflow-y: auto;
  overflow-x: hidden;
`

export const PreviewPlayerDiv = styled.div<{ $active: boolean }>`
  position: relative;
  cursor: pointer;
  display: grid;

  ${(p) =>
    p.$active
      ? css`
          &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            box-shadow: inset 0 0 0px 2px ${colors.red.base};
          }
        `
      : ``}
`

export const VideoTimelineContainerDiv = styled.div`
  position: relative;
  width: 100%;
  display: flex;
`

export const TimelineLegendDiv = styled.div`
  position: relative;
  height: 100%;
  width: 60px;
  flex: 0 0 60px;
  padding-top: 93px;
`

export const VideoTimelineZoomContainerDiv = styled.div`
  position: absolute;
  bottom: 0;
  height: 40px;
  width: 50px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  & > button {
    width: 24px;
    height: 24px;
  }
`
export const StreamerAvatarDiv = styled.div`
  position: relative;
  margin-top: 14px;
`

export const MomentTypeSelectDiv = styled.div`
  position: relative;
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #ccc;
`

export const MomentTypeSelectItemDiv = styled.div<{ $selected?: boolean }>`
  height: 100%;
  width: 100%;
  padding: 8px;
  display: flex;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: #efefef;
  }

  ${(p) =>
    p.$selected
      ? css`
          background-color: #eee;
        `
      : ``}

  svg {
    height: 100%;
  }
`

export const MomentSidebarItemContainerDiv = styled.div`
  margin: 12px 0;
  padding: 8px;
  border: 1px solid #ccc;
`
