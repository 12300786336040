import { useHistory } from 'react-router-dom'
import { AppState, Auth0Provider } from '@auth0/auth0-react'
import { ApolloClient } from '@apollo/client'
import { LOGIN_PATH } from './constants'

type Props = {
  client?: ApolloClient<unknown>
}
const Auth0ProviderWithHistory: React.FC<Props> = ({ children }) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN || ''
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID || ''
  const history = useHistory()

  const onRedirectCallback = (appState: AppState) => {
    history.push(appState?.returnTo || window.location.pathname)
  }
  const redirectUri = new URL(LOGIN_PATH, window.location.origin)

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={redirectUri.toString()}
      onRedirectCallback={onRedirectCallback}
      audience="https://api.mmnts.live"
      useRefreshTokens
    >
      {children}
    </Auth0Provider>
  )
}

export default Auth0ProviderWithHistory
