export const COLLECTIONS = [
  'd2a420b8-0ddb-4605-b56a-d39db8365819',
  '9d5fbf1b-a6c3-4c28-9b4c-215a3507b7c2',
  'f8167cd5-8bac-46e2-b414-877984b514c5',
  'fe6f9990-69a7-4f0c-960c-2983283cd4ec',
  '516db646-9091-410e-a0db-0abfde3dc54e',
  '6e530f11-cd0a-4457-ab81-3da88ccc5c6b',
  '94f23c32-2ae3-4c58-b2b4-ed4f06056b76',
  '06449208-d6c0-4e6b-be61-825429fb32fa',
  'dae10ead-1aef-4a72-ae61-74dd73f38d6c',
  'db232829-10df-4aed-9dc3-e9798b7b75b1',
  '2c36b82d-97a5-4ee8-8a7e-63c710b452c2',
  'b33b38c6-631a-4e7b-9219-5c8b3ed3fd5a',
  'db00212e-8fc3-4381-a2d5-e42db9073fe9',
  'd2bf0ba3-22a0-4fc0-9d98-30efab102bb1',
  'd39584e8-0cdf-44ea-b3f2-8654226de043',
  'e15dacf7-772b-485d-bfc0-4465874a8e8f',
  '5cc51c5e-5916-4a3b-8e3f-c9b56e5de831',
  '6e227d10-4679-48ca-ab0c-53e5a29198c9',
  'af6ae7f8-69b8-413d-989f-b119555b45fa',
  '27cd268f-f318-42ee-96a9-a9454a3d3221',
  '7b677db9-0a9c-4086-833d-d75c8a88153d',
  'c8fa0141-1dc4-4c82-83e2-970471b1b6b9',
]

export const AllChannelIds = [
  '97672114',
  '28168379',
  '99169317',
  '157926817',
  '39350881',
  '62559237',
  '432532052',
  '445768007',
  '159131468',
  '42022822',
  '42933625',
  '131150258',
  '540056482',
  '41060155',
  '132172250',
  '84556362',
  '142307434',
  '49891804',
  '189121996',
  '121649373',
  '90667858',
  '90925399',
  '142307434',
  '49891804',
  '189121996',
  '121649373',
  '90667858',
  '90925399',
  '62405683',
  '94591664',
  '92571348',
  '226129682',
  '46199885',
  '36340781',
  '93785537',
  '147744209',
  '142614149',
  '71887407',
  '125788431',
  '110030663',
  '26679819',
  '128002336',
  '113410153',
  '75006135',
  '26202775',
  '70572789',
  '113178266',
  '45302947',
  '132408020',
  '432656898',
  '263962513',
  '148803899',
  '38433240',
  '77165632',
  '47180661',
  '46176210',
  '70225218',
  '27855530',
  '66418614',
  '18205415',
  '102166964',
  '87890182',
  '65060671',
  '26991127',
  '48389176',
  '114748815',
  '38433240',
  '77165632',
  '47180661',
  '46176210',
  '70225218',
  '27855530',
]
