import { Button } from 'components/core/button'
import { Container, HStack, Spacing } from 'components/core/layout'
import { Spinner } from 'components/core/spinner'
import { Text } from 'components/core/text'
import { SectionHeader, VideoGrid } from 'page/channel/videos/styled'
import { VideoCard } from 'page/channel/videos/video-card'
import { useMemo } from 'react'
import { useParams } from 'react-router'
import { useQueryParamFilters } from 'utils/browser'
import { useGetRecentChannelVideosQuery } from '__generated__'

export const VideoSection = () => {
  const { channelName } = useParams<{ channelName: string }>()
  const { params, setParam } = useQueryParamFilters()

  const currentPage = useMemo(() => {
    const pageStr = params['page']
    return pageStr && typeof pageStr === 'string' ? parseInt(pageStr, 10) : 0
  }, [params])

  const { data: videoData, loading } = useGetRecentChannelVideosQuery({
    variables: {
      channelName,
      first: 14,
      after: currentPage === 0 ? undefined : (currentPage * 14 - 1).toString(),
    },
  })

  const videos = (videoData?.channel?.videos?.data || []).filter(
    (v) => v.stream?.endedAt !== null
  )

  return (
    <section>
      <SectionHeader>
        <Text variant={'h4'}>Videos</Text>
      </SectionHeader>
      {loading && (
        <Spacing top={32}>
          <HStack justifyContent="center" fullWidth>
            <Spinner size={48} />
          </HStack>
        </Spacing>
      )}

      {videos?.length === 0 && (
        <HStack justifyContent="center" fullWidth>
          <Spacing top={16}>
            <Text variant="text-2">No Videos Available</Text>
          </Spacing>
        </HStack>
      )}
      <VideoGrid>
        {videos.map((video) => (
          <VideoCard
            key={video.id}
            id={video.id}
            title={video.title}
            publishedAt={video.publishedAt}
            thumbnailUrl={video.thumbnailUrl || undefined}
            url={`/roster/${channelName}/videos/${video.id}`}
          />
        ))}
      </VideoGrid>
      <Spacing top={32}>
        <Container direction="row" justifyContent="center" gap={8}>
          <Button
            onClick={() => {
              const nextPage = currentPage - 1
              setParam('page', nextPage === 0 ? undefined : nextPage.toString())
            }}
            variant={currentPage === 0 || loading ? 'secondary' : 'primary'}
            disabled={currentPage === 0 || loading}
          >
            Prev Page
          </Button>
          <Button
            onClick={() => setParam('page', (currentPage + 1).toString())}
            variant={
              !videoData?.channel?.videos.pagination.hasNextPage || loading
                ? 'secondary'
                : 'primary'
            }
            disabled={
              !videoData?.channel?.videos.pagination.hasNextPage || loading
            }
          >
            Next Page
          </Button>
        </Container>
      </Spacing>
    </section>
  )
}
