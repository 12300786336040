import React, { useMemo } from 'react'

import {
  CropDisabledDiv,
  CropResizeSquareDiv,
  CropSelectionContainerDiv,
  CropBackgroundDiv,
} from './styled'
import {
  CropRestrictions,
  useCropDrag,
  useCropResize,
} from 'core/shared/cropping/hooks'
import { ExportFormat, Rect, TemplateCrop } from 'core/exporter/constants'

interface CropOverlayProps {
  format: ExportFormat
  templateCrop: TemplateCrop
  crop: Rect
  containerRect: DOMRect | undefined
  setCropRect: (nextCrop: Rect) => void
  editing: boolean
}

export const CropOverlay: React.FC<CropOverlayProps> = ({
  format,
  templateCrop,
  containerRect,
  setCropRect,
  crop,
  editing,
}) => {
  const restrictions: CropRestrictions = useMemo(() => {
    let aspectRatio = templateCrop.aspectRatio

    // custom aspect ratio is not set on template crop, so we need to
    // use the width/height
    if (aspectRatio.height === 0 && aspectRatio.width === 0) {
      aspectRatio = {
        width: templateCrop.position.width * format.aspectRatio.width,
        height: templateCrop.position.height * format.aspectRatio.height,
      }
    }
    return {
      aspectRatio,
    }
  }, [
    format.aspectRatio.height,
    format.aspectRatio.width,
    templateCrop.aspectRatio,
    templateCrop.position.height,
    templateCrop.position.width,
  ])

  const { ref: dragRef, dragging } = useCropDrag(
    crop,
    containerRect,
    setCropRect,
    editing
  )
  const { ref: resizeRef } = useCropResize(
    crop,
    containerRect,
    setCropRect,
    restrictions,
    editing
  )

  const cropStyles = useMemo(
    () =>
      crop
        ? {
            left: `${crop.x * 100}%`,
            top: `${crop.y * 100}%`,
            width: `${crop.width * 100}%`,
            height: `${crop.height * 100}%`,
          }
        : {},
    [crop]
  )

  const backgroundStyles = useMemo(() => {
    if (!crop || !editing) return {}
    const polygon = `
      0 0,
      0 100%,
      100% 100%,
      100% 0,
      0 0,
      ${crop.x * 100}% ${crop.y * 100}%,
      ${(crop.x + crop.width) * 100}% ${crop.y * 100}%,
      ${(crop.x + crop.width) * 100}% ${(crop.y + crop.height) * 100}%,
      ${crop.x * 100}% ${(crop.y + crop.height) * 100}%,
      ${crop.x * 100}% ${crop.y * 100}%,
      0 0
    `
    return {
      clipPath: `polygon(${polygon})`,
    }
  }, [crop, editing])

  if (!crop) return null

  return editing ? (
    <>
      <CropBackgroundDiv style={backgroundStyles}></CropBackgroundDiv>
      <CropSelectionContainerDiv
        ref={dragRef}
        style={cropStyles}
        $dragging={dragging}
        $editing={editing}
      >
        <CropResizeSquareDiv ref={resizeRef} $editing={editing} />
      </CropSelectionContainerDiv>
    </>
  ) : (
    <CropDisabledDiv style={cropStyles} />
  )
}
