import { HStack } from 'components/core/layout'
import { Text } from 'components/core/text'
import { useCallback, useEffect, useState } from 'react'
import { useGetLeaderBoardRankingsAfterQuery } from '__generated__'
import { WinLossDiv } from '../styled'
import { MatchPlayerData } from './hooks'

interface Props {
  accountId: string
  match: MatchPlayerData
  setRank: (matchId: string, rank: number) => void
}

export const MatchDetailDisplay = ({ match, accountId, setRank }: Props) => {
  const [matchRank, setMatchRank] = useState<number | undefined>(undefined)
  const { data } = useGetLeaderBoardRankingsAfterQuery({
    variables: {
      accountIds: [accountId],
      after: match.end,
    },
  })

  const onClick = useCallback(() => {
    // console.log('match', match)
    window.open(
      `/auto-cut/match/${match.matchId}?minKills=3&reactionTypes=funny%2Chype&include=valorant`
    )
  }, [match])

  useEffect(() => {
    if (data) {
      const rank = data.valorantLeaderboard?.data.find(
        (d) => d.account?.id === accountId
      )?.leaderboardRank
      if (rank) {
        setMatchRank(rank)
        setRank(match.matchId, rank)
      }
    }
  }, [data, accountId, match.matchId, setRank])
  return (
    <WinLossDiv $win={match.win} key={match.matchId} onClick={onClick}>
      <HStack alignItems="center" justifyContent={'center'} fullHeight>
        <Text variant="small" weight="bold">
          {/* {match.position} */}
          {/* {match.isMVP ? 1 : match.isTeamMVP ? 2 : ''} */}
          {matchRank && matchRank}
        </Text>
      </HStack>
    </WinLossDiv>
  )
}
