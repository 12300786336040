import React, { useCallback, useMemo } from 'react'
import momentjs from 'moment'
import { Link } from 'react-router-dom'

import { MultiMomentFragment } from '__generated__'
import { durationFormatMinutes } from 'utils/time'
import { useTimelineHelpers } from 'core/editor/timeline/hooks'
import { MomentSidebarItemContainerDiv } from '../../styled'

interface MultiMomentsSidebarItemProps {
  moment: MultiMomentFragment
}

export const MultiMomentsSidebarItem: React.FC<MultiMomentsSidebarItemProps> =
  ({ moment }) => {
    const { startText, lengthText, jumpToMoment, title, momentDetailUrl } =
      useMultiMomentsSidebarItemData(moment)

    return (
      <MomentSidebarItemContainerDiv onClick={jumpToMoment}>
        <div>{title}</div>
        <div>
          {startText} - {lengthText}
        </div>
        <Link to={momentDetailUrl}>view</Link>
      </MomentSidebarItemContainerDiv>
    )
  }

const useMultiMomentsSidebarItemData = (moment: MultiMomentFragment) => {
  const { jumpToNewSelection } = useTimelineHelpers()
  const startTime = momentjs(moment.startsAt).valueOf()
  const endTime = momentjs(moment.endsAt).valueOf()
  const startText = momentjs(moment.startsAt).format('LT')
  const lengthText = durationFormatMinutes((endTime - startTime) / 1000)

  const jumpToMoment = useCallback(() => {
    jumpToNewSelection(startTime, endTime, true)
  }, [endTime, jumpToNewSelection, startTime])

  const momentDetailUrl = `/moment/${moment.id}`

  return useMemo(
    () => ({
      title: moment.title,
      startText,
      lengthText,
      jumpToMoment,
      momentDetailUrl,
    }),
    [jumpToMoment, lengthText, moment.title, momentDetailUrl, startText]
  )
}
