import { useCallback, useMemo } from 'react'
import { SyncStatus, useTimeRangePlayerUpdater } from './context'

export const useTimeRangePlayerHelpers = () => {
  const dispatch = useTimeRangePlayerUpdater()

  // absolute time in ms
  const seekTo = useCallback(
    (nextTargetTime: number) => {
      dispatch({
        type: useTimeRangePlayerUpdater.ACTION_TYPES.SEEK_TO,
        payload: {
          targetTime: nextTargetTime,
        },
      })
    },
    [dispatch]
  )

  const setVideoStartTime = useCallback(
    (videoStartTime: number) => {
      dispatch({
        type: useTimeRangePlayerUpdater.ACTION_TYPES.SET_VIDEO_START,
        payload: {
          videoStartTime,
        },
      })
    },
    [dispatch]
  )

  const setCurrentTime = useCallback(
    (nextCurrentTime: number) => {
      dispatch({
        type: useTimeRangePlayerUpdater.ACTION_TYPES.SET_CURRENT_TIME,
        payload: {
          currentTime: nextCurrentTime,
        },
      })
    },
    [dispatch]
  )

  const play = useCallback(() => {
    dispatch({
      type: useTimeRangePlayerUpdater.ACTION_TYPES.SET_PLAYING,
      payload: { playing: true },
    })
  }, [dispatch])

  const pause = useCallback(() => {
    dispatch({
      type: useTimeRangePlayerUpdater.ACTION_TYPES.SET_PLAYING,
      payload: { playing: false },
    })
  }, [dispatch])

  const setStatus = useCallback(
    (nextStatus: SyncStatus) => {
      dispatch({
        type: useTimeRangePlayerUpdater.ACTION_TYPES.SET_STATUS,
        payload: { status: nextStatus },
      })
    },
    [dispatch]
  )

  return useMemo(
    () => ({
      setVideoStartTime,
      setCurrentTime,
      play,
      pause,
      seekTo,
      setStatus,
    }),
    [pause, play, seekTo, setCurrentTime, setStatus, setVideoStartTime]
  )
}
