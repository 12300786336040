import { Text } from 'components/core/text'
import { ReactNode, useMemo } from 'react'
import SimpleBar from 'simplebar-react'
import { useDimensions } from 'utils/browser'
import { useCurrentChannel } from '../hooks'
import { ChannelAvatar } from './channel-avatar'
import { SocialLink } from './social-link'
import {
  BottomSidebarContainerDiv,
  BottomSidebarContainerHack,
  BottomSidebarContent,
  Hr,
  SidebarContainer,
  TopSidebarContent,
} from './styled'

interface Props {
  children?: ReactNode
}

export const Sidebar = ({ children }: Props) => {
  const { name, profileImageUrl, socialAccounts } = useSidebar()
  const [ref, rect] = useDimensions()

  // simplebar needs a pixel amount for maxHeight :(
  const simpleBarStyles = useMemo(
    () => ({
      maxHeight: rect?.height ? rect.height - 64 : '100%',
      width: `100%`,
    }),
    [rect?.height]
  )

  return (
    <section>
      <SidebarContainer>
        <TopSidebarContent>
          <ChannelAvatar src={profileImageUrl || undefined} />
          <Text variant="h3" as={'h3'} textAlign={'center'}>
            {name}
          </Text>
          <Hr />

          {socialAccounts && (
            <ul>
              {socialAccounts.map(({ label, type, to }, i) => (
                <SocialLink key={i} type={type} to={to}>
                  {label}
                </SocialLink>
              ))}
            </ul>
          )}
        </TopSidebarContent>
        {children && (
          <BottomSidebarContainerDiv>
            <BottomSidebarContainerHack ref={ref}>
              <BottomSidebarContent>
                <SimpleBar style={simpleBarStyles}>{children}</SimpleBar>
              </BottomSidebarContent>
            </BottomSidebarContainerHack>
          </BottomSidebarContainerDiv>
        )}
      </SidebarContainer>
    </section>
  )
}

type SocialLinkType = {
  label: string
  type: string
  to?: string
}

export const useSidebar = () => {
  const { data, loading } = useCurrentChannel()

  const riotAccounts = useMemo(
    () =>
      data?.channel?.linkedAccounts
        .filter((account) => account.__typename === 'RiotAccount')
        .map((acc) => ({
          label: `${acc.valorantUserInfo?.gameName}#${acc.valorantUserInfo?.tagLine}`,
          type: 'riot',
          to: undefined,
        })),
    [data?.channel?.linkedAccounts]
  )

  const twitchLink = useMemo(() => {
    if (!data?.channel) {
      return undefined
    }
    return {
      label: data?.channel?.login || '',
      to: `https://twitch.tv/${data?.channel?.login}`,
      type: 'twitch',
    }
  }, [data?.channel])

  const profileInfo = useMemo(
    () => ({
      name: data?.channel?.name,
      profileImageUrl: data?.channel?.profileImageUrl,
    }),
    [data?.channel]
  )

  const socialAccounts: SocialLinkType[] = [...(riotAccounts || [])]
  if (twitchLink) {
    socialAccounts.push(twitchLink)
  }

  return { loading, ...profileInfo, socialAccounts }
}
