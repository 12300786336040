import { createTheme } from 'styled-breakpoints'

const neutrals = {
  n1: '#141416',
  n2: '#23262F',
  n3: '#353945',
  n4: '#777E90',
  n5: '#B1B5C3',
  n6: '#E6E8EC',
  n7: '#F4F5F6',
  n8: '#FCFCFD',
}

export const colors = {
  white: '#FFFFFF',
  blue: '#3772FF',
  purple: '#9757D7',
  rose: '#EF466F',
  green: '#45B26B',
  beige: '#E4D7CF',
  gold: '#FFD166',
  static: {
    neutrals,
  },
}

const breakpoints = createTheme({
  mobileSm: '320px',
  mobileLg: '375px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1500px',
})

export const lightTheme = {
  ...breakpoints,
  colors: {
    background: {
      primary: neutrals.n8,
      secondary: neutrals.n7,
      tertiary: neutrals.n6,
    },
    text: {
      primary: neutrals.n1,
      secondary: neutrals.n4,
      tertiary: neutrals.n5,
    },
    neutrals,
    ...colors,
  },
  elevation: {
    depth1: `0px 10px 30px -24px rgba(75, 78, 83, 0.4)`,
    depth2: `0px 10px 40px -24px rgba(75, 78, 83, 0.4)`,
    depth3: `0px 16px 50px -24px rgba(75, 78, 83, 0.4)`,
  },
}

export const darkTheme = {
  ...breakpoints,
  colors: {
    background: {
      primary: neutrals.n1,
      secondary: neutrals.n2,
      tertiary: neutrals.n3,
    },
    text: {
      primary: neutrals.n8,
      secondary: neutrals.n6,
      tertiary: neutrals.n4,
    },
    neutrals: {
      n1: neutrals.n8,
      n2: neutrals.n7,
      n3: neutrals.n6,
      n4: neutrals.n5,
      n5: neutrals.n4,
      n6: neutrals.n3,
      n7: neutrals.n2,
      n8: neutrals.n1,
    },
    ...colors,
  },
  elevation: {
    depth1: `0px 10px 30px -24px rgba(75, 78, 83, 0.4)`,
    depth2: `0px 10px 40px -24px rgba(75, 78, 83, 0.4)`,
    depth3: `0px 16px 50px -24px rgba(75, 78, 83, 0.4)`,
  },
}

export type TextColor = keyof typeof lightTheme.colors.text
export type ThemeType = typeof lightTheme
