import { GearIcon } from '@primer/octicons-react'
import { HStack, Spacing } from 'components/core/layout'
import { Text } from 'components/core/text'
import { useCollectionContext } from 'core/collections/collections-context-provider'
import { CollectionGrid } from 'core/collections/grid'
import { Link, useRouteMatch } from 'react-router-dom'
import { useTheme } from 'styled-components/macro'
import { CollectionsListGrid } from './styled'

export const GridView = () => {
  const theme = useTheme()
  const { params } = useRouteMatch<{ organization: string }>()
  const { selected: collection } = useCollectionContext()
  return (
    <>
      {collection && (
        <CollectionsListGrid>
          <Spacing top={8} bottom={16}>
            <HStack justifyContent="space-between" alignItems="baseline">
              <Text
                variant="h4"
                weight="semibold"
                color={theme.colors.neutrals.n3}
              >
                {collection.title}
              </Text>
              <Link
                to={`/o/${params.organization}/collections/${collection.id}/settings`}
              >
                <GearIcon size={'small'} fill={theme.colors.neutrals.n3} />
              </Link>
            </HStack>
          </Spacing>
          <CollectionGrid collectionId={collection.id} />
        </CollectionsListGrid>
      )}
    </>
  )
}
