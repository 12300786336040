import { useCallback, useMemo } from 'react'
import { flatten } from 'lodash'
import momentjs from 'moment'

import {
  useGetValorantMatchByRoundsQuery,
  useGetValorantMatchDetailsQuery,
  ValorantAgent,
  ValorantMatchTeam,
  ValorantRoundCeremony,
  ValorantRoundResultType,
} from '__generated__'
import { useTimelineHelpers } from 'core/editor/timeline/hooks'
import { PlayerInfo } from 'page/video/editor/sidebar/valorant/match-preview'
import { useMatchStartRemote } from 'core/valorant-match/editor/hooks'

export interface ValorantBasicRoundData {
  roundNum: number
  roundResult: ValorantRoundResultType
  winningTeam: string
  roundCeremony: ValorantRoundCeremony
  roundStart: number
  roundLength: number
}

export interface ValorantRoundStats {
  roundNum: number
  roundResult: ValorantRoundResultType
  winningTeam: string
  roundCeremony: ValorantRoundCeremony
  roundStart: number
  roundLength: number
  stats: {
    killCount: number
    kills: {
      accountId: string
      gameName: string
      timeSinceGameStartMillis: number
      timeSinceRoundStartMillis: number
    }[]
  }
}

export const useValorantMatchRoundData = (matchId?: string) => {
  const { data: roundData } = useGetValorantMatchByRoundsQuery({
    variables: { valorantMatchId: matchId ?? '' },
    skip: matchId === undefined,
  })
  const { data: matchDetailsData } = useGetValorantMatchDetailsQuery({
    variables: { valorantMatchId: matchId ?? '' },
    skip: matchId === undefined,
  })

  const {
    winningTeam,
    playerDataByRound,
    gameLength,
    roundDataWithLength,
    expectedFirstRoundStart,
  } = useMemo(() => {
    if (!roundData?.valorantMatch || !matchDetailsData?.valorantMatch) {
      return {
        playerDataByRound: undefined,
        gameLength: undefined,
      }
    }

    const startTime = momentjs(roundData.valorantMatch.startedAt).valueOf()
    const endedAt = momentjs(roundData.valorantMatch.endedAt).valueOf()
    // const addedTime = 120 * 1000 // we add 20s right now
    const gameLength = endedAt - startTime

    let expectedFirstRoundStart = 0
    const basicRoundData = roundData.valorantMatch.roundResults.map(
      ({ roundNum, roundResult, winningTeam, playerStats, roundCeremony }) => {
        let roundStart: number | undefined = undefined
        const allKills = flatten(
          playerStats?.map((item) => {
            return flatten(item?.kills ?? [])
          }) ?? []
        )

        // we don't have explicit start times, so we can find it here
        if (roundStart === undefined && allKills.length > 0) {
          if (roundNum === 0) {
            expectedFirstRoundStart =
              (allKills[0]?.timeSinceGameStartMillis ?? 0) -
              (allKills[0]?.timeSinceRoundStartMillis ?? 0)
          }
          roundStart =
            (allKills[0]?.timeSinceGameStartMillis ?? 0) -
            (allKills[0]?.timeSinceRoundStartMillis ?? 0) -
            expectedFirstRoundStart
        }

        return {
          roundNum: roundNum ?? 0,
          roundResult: roundResult ?? ValorantRoundResultType.Eliminated,
          winningTeam: winningTeam ?? ValorantMatchTeam.Blue,
          roundCeremony: roundCeremony ?? ValorantRoundCeremony.Default,
          roundStart: roundStart ?? 0,
        }
      }
    )

    // now that we have start times, we can add length by calcing between rounds
    const roundDataWithLength: ValorantBasicRoundData[] = basicRoundData.map(
      (rd, idx) => {
        const nextRound = basicRoundData[idx + 1]
        if (nextRound !== undefined) {
          return {
            ...rd,
            roundLength: nextRound.roundStart - rd.roundStart,
          }
        } else {
          return {
            ...rd,
            roundLength: gameLength - rd.roundStart,
          }
        }
      }
    )

    const winningTeam =
      roundDataWithLength[roundDataWithLength.length - 1].winningTeam

    const playerMap = matchDetailsData.valorantMatch.players.reduce(
      (memo, item) => {
        return {
          ...memo,
          [item.account.id]: [],
        }
      },
      {}
    ) as { [accountId: string]: ValorantRoundStats[] }

    roundData.valorantMatch.roundResults.forEach(({ playerStats }, idx) => {
      playerStats?.forEach((currentPlayerStats) => {
        if (currentPlayerStats?.player?.account.id) {
          const killCount = currentPlayerStats.kills?.length ?? 0
          const kills =
            currentPlayerStats.kills?.map((k) => {
              return {
                accountId: k?.victim?.account.id ?? '',
                gameName: k?.victim?.gameName ?? '',
                timeSinceGameStartMillis: k?.timeSinceGameStartMillis ?? 0,
                timeSinceRoundStartMillis: k?.timeSinceRoundStartMillis ?? 0,
              }
            }) ?? []

          playerMap[currentPlayerStats.player.account.id].push({
            ...roundDataWithLength[idx],
            stats: {
              killCount,
              kills,
            },
          })
        }
      })
    })

    return {
      winningTeam,
      playerDataByRound: playerMap,
      gameLength,
      roundDataWithLength,
      expectedFirstRoundStart,
    }
  }, [matchDetailsData?.valorantMatch, roundData?.valorantMatch])

  const matchStart = useMemo(() => {
    return momentjs(roundData?.valorantMatch?.startedAt).valueOf()
  }, [roundData?.valorantMatch?.startedAt])

  return useMemo(
    () => ({
      roundDataWithLength,
      winningTeam,
      playerDataByRound,
      matchStart,
      gameLength,
      expectedFirstRoundStart,
    }),
    [
      gameLength,
      matchStart,
      playerDataByRound,
      expectedFirstRoundStart,
      roundDataWithLength,
      winningTeam,
    ]
  )
}

export const useValorantMatchPregameOffset = (
  matchId: string,
  videoId: string
) => {
  const { expectedFirstRoundStart, matchStart } =
    useValorantMatchRoundData(matchId)
  const { matchStart: matchStartRemote, loading } = useMatchStartRemote(
    matchId,
    videoId
  )

  const pregameTimeMs = useMemo(() => {
    if (loading || !matchStartRemote) return 0
    return matchStartRemote - matchStart
  }, [matchStartRemote, matchStart, loading])

  return expectedFirstRoundStart && !loading
    ? pregameTimeMs - expectedFirstRoundStart
    : undefined
}

export const useValorantPlayerMatchData = (matchId?: string) => {
  const { data } = useGetValorantMatchDetailsQuery({
    variables: { valorantMatchId: matchId ?? '' },
    errorPolicy: 'all',
    skip: matchId === undefined,
  })
  const { centerTimelineAtTime } = useTimelineHelpers()

  const playersById: undefined | { [puuid: string]: PlayerInfo } = useMemo(
    () =>
      data?.valorantMatch?.players.reduce(
        (memo, { agent, gameName, stats, account, team }) => {
          return {
            ...memo,
            [account.id]: {
              puuid: account.id,
              agent: agent ?? ValorantAgent.Astra,
              gameName: gameName ?? '',
              team,
              stats: stats ?? {
                kills: 0,
                score: 0,
                deaths: 0,
                assists: 0,
                'rounds-played': 0,
                'playtime-millis': 0,
              },
            },
          }
        },
        {}
      ),
    [data?.valorantMatch?.players]
  )

  const findPlayerInfoById = useCallback(
    (playerIds: string[]) => {
      if (!playersById) return undefined
      for (const id of playerIds) {
        if (playersById[id] !== undefined) return playersById[id]
      }
    },
    [playersById]
  )

  const jumpToMatch = useCallback(() => {
    const matchTime = momentjs(data?.valorantMatch?.startedAt).valueOf()
    centerTimelineAtTime(matchTime)
  }, [centerTimelineAtTime, data?.valorantMatch?.startedAt])

  return useMemo(
    () => ({
      playersById,
      jumpToMatch,
      findPlayerInfoById,
    }),
    [findPlayerInfoById, jumpToMatch, playersById]
  )
}

export const usePlayerMatchData = (matchId: string, accountIds: string[]) => {
  const { findPlayerInfoById } = useValorantPlayerMatchData(matchId)

  return useMemo(() => {
    return findPlayerInfoById(accountIds)
  }, [accountIds, findPlayerInfoById])
}
