import { useTheme } from 'styled-components/macro'

type IconProps = {
  fill: string
}

export const Twitch = ({ fill }: IconProps) => (
  <svg
    width="15"
    height="17"
    viewBox="0 0 15 17"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.7352 8.98934L10.4489 11.2755H6.85752L4.8983 13.2344V11.2755H1.95956V1.80609H12.7352V8.98934ZM0.652961 0.5L0 3.11235V14.867H2.93908V16.5H4.57124L6.2044 14.867H8.81624L14.0406 9.64331V0.5H0.652961Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.55127 8.33683H6.85737V4.41789H5.55127V8.33683ZM9.14282 8.33683H10.4489V4.41789H9.14282V8.33683Z"
    />
  </svg>
)

export const Riot = ({ fill }: IconProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8.04371 14.5136L7.3261 12.64L14.2617 13.0014L13.9503 16L8.04371 14.5136ZM9.70554 0L0 4.64611L1.50102 11.6472H3.48074L3.13883 6.63908L3.44257 6.54371L4.63295 11.6472H6.68967L6.61354 5.54683L6.91739 5.45135L7.95771 11.6472H10.1353L10.649 4.27851L10.9526 4.18291L11.4915 11.6472H14.3711L15.3846 1.40027L9.70554 0Z" />
  </svg>
)

export const YouTube = ({ fill }: IconProps) => (
  <svg
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.251 0.343331C14.9395 0.532246 15.4816 1.08888 15.6656 1.79576C16 3.0769 16 5.75 16 5.75C16 5.75 16 8.42301 15.6656 9.70424C15.4816 10.4111 14.9395 10.9678 14.251 11.1568C13.0034 11.5 8 11.5 8 11.5C8 11.5 2.99664 11.5 1.74891 11.1568C1.06045 10.9678 0.518273 10.4111 0.334273 9.70424C0 8.42301 0 5.75 0 5.75C0 5.75 0 3.0769 0.334273 1.79576C0.518273 1.08888 1.06045 0.532246 1.74891 0.343331C2.99664 0 8 0 8 0C8 0 13.0034 0 14.251 0.343331ZM6.50003 3.50005V8.50005L10.5 6.00014L6.50003 3.50005Z"
    />
  </svg>
)

type Props = {
  type: string
}
export const SocialIcon = ({ type }: Props) => {
  const theme = useTheme()
  const fill = theme.colors.neutrals.n4
  switch (type) {
    case 'twitch':
      return <Twitch fill={fill} />
    case 'riot':
      return <Riot fill={fill} />
    case 'youtube':
      return <YouTube fill={fill} />
    default:
      return <>?</>
  }
}
