import React, { useState } from 'react'

import { Button } from 'components/core/button'
// import { FormatSelector } from 'core/exporter/format'
// import { ExportFormat } from 'core/exporter/constants'
import { ReactComponent as CloseIcon } from 'icons/svgs/solid/times-circle.svg'

import {
  CreateNewEditDiv,
  FormatSelectorCloseDiv,
  FormatSelectorPopoverDiv,
} from '../styled'
// import { useCreateEdit } from '../exporter/scenes/hooks'
//
interface CreateEditProps {
  momentId: string
}

export const CreateEdit: React.FC<CreateEditProps> = () => {
  // const createEdit = useCreateEdit(momentId)
  const [formatSelectionOpen, setFormatSelectionOpen] = useState(false)
  const openFormatSelect = () => setFormatSelectionOpen(true)
  const closeFormatSelect = () => setFormatSelectionOpen(false)

  // const selectFormat = useCallback(
  //   (nextFormat: ExportFormat) => {
  //     // createEdit(nextFormat.id)
  //   },
  //   [createEdit]
  // )

  return (
    <CreateNewEditDiv>
      {formatSelectionOpen ? (
        <FormatSelectorPopoverDiv>
          {/* <FormatSelector selectFormat={selectFormat} /> */}
          <FormatSelectorCloseDiv onClick={closeFormatSelect}>
            <CloseIcon />
          </FormatSelectorCloseDiv>
        </FormatSelectorPopoverDiv>
      ) : null}
      <Button onClick={openFormatSelect}>Create Video</Button>
    </CreateNewEditDiv>
  )
}
