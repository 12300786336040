import React, { createContext, useContext, useReducer } from 'react'

interface MomentViewerState {
  selectedMomentId: string
  selectedEditId: string
  momentIds: string[]
}

const INITIAL_STATE: MomentViewerState = {
  selectedMomentId: '',
  selectedEditId: '',
  momentIds: [],
}

enum ACTION_TYPES {
  SET_SELECTED_MOMENT_ID = 'SET_SELECTED_MOMENT_ID',
  SET_SELECTED_EDIT_ID = 'SET_SELECTED_EDIT_ID',
  SET_MOMENT_IDS = 'SET_MOMENT_IDS',
}

interface SetSelectedMomentIdAction {
  type: ACTION_TYPES.SET_SELECTED_MOMENT_ID
  payload: {
    momentId: string
  }
}

interface SetSelecteEditIdAction {
  type: ACTION_TYPES.SET_SELECTED_EDIT_ID
  payload: {
    editId: string
  }
}

interface SetMomentIdsAction {
  type: ACTION_TYPES.SET_MOMENT_IDS
  payload: {
    momentIds: string[]
  }
}

type MomentViewerActions =
  | SetSelectedMomentIdAction
  | SetSelecteEditIdAction
  | SetMomentIdsAction

type Dispatch = (action: MomentViewerActions) => void

const reducer = (
  state: MomentViewerState,
  action: MomentViewerActions
): MomentViewerState => {
  switch (action.type) {
    case ACTION_TYPES.SET_SELECTED_MOMENT_ID: {
      return {
        ...state,
        selectedMomentId: action.payload.momentId,
      }
    }

    case ACTION_TYPES.SET_SELECTED_EDIT_ID: {
      return {
        ...state,
        selectedEditId: action.payload.editId,
      }
    }

    case ACTION_TYPES.SET_MOMENT_IDS: {
      return {
        ...state,
        momentIds: action.payload.momentIds,
      }
    }

    default:
      return state
  }
}

const MomentViewerContext = createContext<MomentViewerState | undefined>(
  undefined
)
const MomentViewerUpdaterContext = createContext<Dispatch | undefined>(
  undefined
)

export const MomentViewerProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE)

  return (
    <MomentViewerContext.Provider value={state}>
      <MomentViewerUpdaterContext.Provider value={dispatch}>
        {children}
      </MomentViewerUpdaterContext.Provider>
    </MomentViewerContext.Provider>
  )
}

export const useMomentViewerState = () => {
  const context = useContext(MomentViewerContext)
  if (context === undefined) {
    throw new Error(
      'useMomentViewerState must be used within a MomentViewerProvider'
    )
  }
  return context
}

export const useMomentViewerUpdater = () => {
  const context = useContext(MomentViewerUpdaterContext)
  if (context === undefined) {
    throw new Error(
      'useMomentViewerUpdater must be used within a MomentViewerProvider'
    )
  }
  return context
}

useMomentViewerUpdater.ACTION_TYPES = ACTION_TYPES
