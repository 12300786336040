import { useEffect, useMemo } from 'react'

import { HStack } from 'components/core/layout'
import { Spinner } from 'components/core/spinner'

import { SignalTypes } from '../context'
import { PlaylistContainerDiv } from './styled'
import { ValorantMultiKillDisplayItem } from './valorant-item'
import { useBucketFeed } from 'core/feed/hooks'
import { useFeedState } from 'core/feed/context'
// import { useValorantBucketFeed } from 'core/feed/components/hooks'

interface Props {
  setSignalData: (
    nextSignal: { signalType: SignalTypes; signalId: string } | undefined
  ) => void
  signalData: { signalType: SignalTypes; signalId: string } | undefined
}

export const ValorantSignalReviewPlaylist = ({
  setSignalData,
  signalData,
}: Props) => {
  // const [playlistOpen, setPlaylistOpen] = useState(false)
  const { loading } = useFeedState()
  const buckets = useBucketFeed()
  const feedData = useMemo(() => {
    return buckets?.map((bucket) => bucket.data).flat()
  }, [buckets])

  // const simplebarStyles = useMemo(() => ({ height: 400, width: '100%' }), [])

  // const selectValorantMoment = useCallback(
  //   (id: string) => {
  //     setSignalData({ signalType: 'ValorantMoment', signalId: id })
  //     setPlaylistOpen(false)
  //   },
  //   [setSignalData]
  // )

  useEffect(() => {
    if (feedData && feedData[0] && signalData?.signalId === undefined) {
      setSignalData({
        signalType: 'ValorantMoment',
        signalId: feedData[0].id,
      })
    }
  }, [feedData, setSignalData, signalData?.signalId])

  return (
    <PlaylistContainerDiv $open={false}>
      {loading && (
        <HStack fullWidth={true} justifyContent="center" alignItems="center">
          <Spinner color="white" size={24} />
        </HStack>
      )}
      {signalData?.signalId ? (
        <ValorantMultiKillDisplayItem
          selected={false}
          signalId={signalData.signalId}
        >
          {/* <Icon>
            <Chevron up={playlistOpen} />
          </Icon> */}
        </ValorantMultiKillDisplayItem>
      ) : null}
      {/* <PlaylistContentDiv $open={playlistOpen}>
        <SimpleBar style={simplebarStyles}>
          {feedData?.map((moment) => (
            <ValorantMultiKillDisplayItem
              key={moment.id}
              selected={moment.id === signalData?.signalId}
              signalId={moment.id}
              onClick={() => selectValorantMoment(moment.id)}
            />
          ))}
        </SimpleBar>
      </PlaylistContentDiv> */}
      {/* <RootOnly>{syncData && <SyncPlaylist matchesInfo={syncData} />}</RootOnly> */}
    </PlaylistContainerDiv>
  )
}
