import React from 'react'
import { ApolloClient, InMemoryCache } from '@apollo/client'
import { ApolloProvider } from '@apollo/client/react'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { setContext } from '@apollo/client/link/context'
import generatedIntrospection from '__generated__/possible-types'
import {
  AuthorizationContextProvider,
  useAuthorizationContext,
} from './context'

const AuthorizedApolloProvider: React.FC = ({ children }): JSX.Element => {
  return (
    <AuthorizationContextProvider>
      <Provider>{children}</Provider>
    </AuthorizationContextProvider>
  )
}

const Provider: React.FC = ({ children }) => {
  const { accessToken } = useAuthorizationContext()

  const cache = new InMemoryCache({
    possibleTypes: generatedIntrospection.possibleTypes,
    // typePolicies: {
    //   Query: {
    //     fields: {
    //       moments: {
    //         keyArgs: ['filter', 'orderBy'],
    //         merge(existing = {}, incoming) {
    //           const res = { ...existing }
    //           res.pagination = incoming.pagination
    //           const existingMap = keyBy(existing.data, ({ __ref }) => __ref)
    //           const nextData = [...(existing.data ?? [])]
    //           incoming.data.forEach((item) => {
    //             if (existingMap[item.__ref] === undefined) nextData.push(item)
    //           })
    //           res.data = nextData
    //           return res
    //         },
    //       },
    //     },
    //   },
    // },
  })

  const httpLink = new BatchHttpLink({
    uri: 'https://api.mmnts.live',
    // uri: 'http://localhost:4000',
    batchMax: 5, // No more than 5 operations per batch
    batchInterval: 20, // Wait no more than 20ms after first batched operation
  })

  const authLink = setContext((_, { headers, ...rest }) => {
    if (!accessToken) {
      return { headers, ...rest }
    }

    return {
      ...rest,
      headers: {
        ...headers,
        authorization: `Bearer ${accessToken}`,
      },
    }
  })

  const client = new ApolloClient({
    cache: cache,
    link: authLink.concat(httpLink),
    connectToDevTools: true,
  })
  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export default AuthorizedApolloProvider
