import { useCallback, useMemo } from 'react'

import {
  ConnectedMomentFragment,
  Rating,
  useGetSignalDetailQuery,
  ValorantMatchType,
  ValorantRoundKillFragment,
} from '__generated__'

import { useSignalReviewUpdater } from './context'

export const useSignalReviewHelpers = () => {
  const dispatch = useSignalReviewUpdater()

  const setRating = useCallback(
    (rating: number | undefined) => {
      dispatch({
        type: useSignalReviewUpdater.ACTION_TYPES.SET_RATING,
        payload: {
          rating,
        },
      })
    },
    [dispatch]
  )

  const setTags = useCallback(
    (tags: string[]) => {
      dispatch({
        type: useSignalReviewUpdater.ACTION_TYPES.SET_TAGS,
        payload: {
          tags,
        },
      })
    },
    [dispatch]
  )

  const setTitle = useCallback(
    (title: string) => {
      dispatch({
        type: useSignalReviewUpdater.ACTION_TYPES.SET_TITLE,
        payload: {
          title,
        },
      })
    },
    [dispatch]
  )

  const setMomentId = useCallback(
    (momentId: string | undefined) => {
      dispatch({
        type: useSignalReviewUpdater.ACTION_TYPES.SET_MOMENT_ID,
        payload: {
          momentId,
        },
      })
    },
    [dispatch]
  )

  const setSelectedRelatedVideoIds = useCallback(
    (relatedVideoIds: string[]) => {
      dispatch({
        type: useSignalReviewUpdater.ACTION_TYPES.SET_RELATED_VIDEO_IDS,
        payload: {
          relatedVideoIds,
        },
      })
    },
    [dispatch]
  )

  const setShowRelatedVideoModal = useCallback(
    (nextShow: boolean) => {
      dispatch({
        type: useSignalReviewUpdater.ACTION_TYPES.SET_SHOW_RELATED_VIDEO_MODAL,
        payload: {
          showRelatedVideoModal: nextShow,
        },
      })
    },
    [dispatch]
  )

  const setShowMomentActionsModal = useCallback(
    (nextShow: boolean) => {
      dispatch({
        type: useSignalReviewUpdater.ACTION_TYPES.SET_SHOW_MOMENT_ACTIONS_MODAL,
        payload: {
          showMomentActionsModal: nextShow,
        },
      })
    },
    [dispatch]
  )

  const setLocalOffsets = useCallback(
    (localOffsets: { [videoId: string]: number }) => {
      dispatch({
        type: useSignalReviewUpdater.ACTION_TYPES.SET_LOCAL_OFFSETS,
        payload: {
          offsets: localOffsets,
        },
      })
    },
    [dispatch]
  )

  return useMemo(
    () => ({
      setMomentId,
      setRating,
      setTags,
      setTitle,
      setSelectedRelatedVideoIds,
      setShowRelatedVideoModal,
      setShowMomentActionsModal,
      setLocalOffsets,
    }),
    [
      setLocalOffsets,
      setMomentId,
      setRating,
      setSelectedRelatedVideoIds,
      setShowMomentActionsModal,
      setShowRelatedVideoModal,
      setTags,
      setTitle,
    ]
  )
}

export const translateRatingInt = (numberRating: number | undefined) => {
  switch (numberRating) {
    case 1:
      return Rating.One
    case 2:
      return Rating.Two
    case 3:
      return Rating.Three
    case 4:
      return Rating.Four
    case 5:
      return Rating.Five
    default:
      return Rating.One
  }
}

export const translateRatingEnum = (enumRating: Rating) => {
  switch (enumRating) {
    case Rating.One:
      return 1
    case Rating.Two:
      return 2
    case Rating.Three:
      return 3
    case Rating.Four:
      return 4
    case Rating.Five:
      return 5
    default:
      return 1
  }
}

interface SharedMomentData {
  userId: string
  videoId: string
  signalStartTime: number
  signalEndTime: number
  relatedVideoData: { id: string; offset?: number }[]
  valorantMatchId: string | undefined
  connectedMoments: ConnectedMomentFragment[]
  isUnread: boolean
}

interface ChatSignalData extends SharedMomentData {
  metadata: any
}

interface ValorantSignalData extends SharedMomentData {
  kills: ValorantRoundKillFragment[]
}

export const useSignalData = (
  signalId: string
):
  | { type: 'ChatMoment'; data: ChatSignalData }
  | { type: 'ValorantMoment'; data: ValorantSignalData }
  | { type: 'ManualSignalMoment'; data: ChatSignalData }
  | undefined => {
  const { data, loading } = useGetSignalDetailQuery({
    variables: { id: signalId },
  })

  return useMemo(() => {
    if (
      loading ||
      !(
        data?.moment?.__typename === 'ChatMoment' ||
        data?.moment?.__typename === 'ValorantMoment' ||
        data?.moment?.__typename === 'ManualSignalMoment'
      )
    ) {
      return undefined
    }

    const signal = data.moment

    const videoId = signal.video.id
    const signalStartTime = new Date(signal.startsAt).getTime()
    const signalEndTime = new Date(signal.endsAt).getTime()
    const userId = signal.channel.id
    const connectedMoments = signal.usedIn

    // look for ValorantMatch
    const valorantMatchId = signal.video.valorantMatches
      .filter(
        ({ type }) =>
          type === ValorantMatchType.Competitive ||
          type === ValorantMatchType.Unrated ||
          type === ValorantMatchType.Custom ||
          type === ValorantMatchType.Premier
      )
      .find((valMatch) => {
        return (
          signalStartTime > new Date(valMatch.startedAt).getTime() &&
          signalEndTime < new Date(valMatch.endedAt).getTime()
        )
      })?.id

    let relatedVideoData: { id: string; offset: number }[] = []
    if (
      data.moment.__typename === 'ValorantMoment' ||
      (data.moment.__typename === 'ChatMoment' && valorantMatchId)
    ) {
      const vidSyncData =
        data.moment.__typename === 'ValorantMoment'
          ? data.moment.match.videoSyncData
          : data.moment.__typename === 'ChatMoment'
          ? data.moment.video.valorantMatches.find(
              ({ id }) => valorantMatchId === id
            )?.videoSyncData
          : undefined

      const matchStartMapping: { [videoId: string]: number } = vidSyncData
        ? vidSyncData?.reduce((acc, { video, matchStartTime }) => {
            return { ...acc, [video.id]: matchStartTime }
          }, {})
        : {}
      const primaryStart = matchStartMapping[videoId]
      relatedVideoData = signal.video.relatedVideos
        .filter((relatedVid) => {
          return relatedVid.overlaps.some((overlap) => {
            return (
              signalStartTime > new Date(overlap.from).getTime() &&
              signalEndTime < new Date(overlap.to).getTime()
            )
          })
        })
        .map((relatedVid) => {
          const relatedVidMatchStart = matchStartMapping[relatedVid.video.id]
          const offset =
            primaryStart && relatedVidMatchStart
              ? new Date(primaryStart).getTime() -
                new Date(relatedVidMatchStart).getTime()
              : 0
          return {
            id: relatedVid.video.id,
            offset: offset,
          }
        })
    } else if (
      data.moment.__typename === 'ChatMoment' ||
      data.moment.__typename === 'ManualSignalMoment'
    ) {
      // filter for overlapping related videos
      relatedVideoData = signal.video.relatedVideos
        .filter((relatedVid) => {
          return relatedVid.overlaps.some((overlap) => {
            return (
              signalStartTime > new Date(overlap.from).getTime() &&
              signalEndTime < new Date(overlap.to).getTime()
            )
          })
        })
        .map((relatedVid) => ({
          id: relatedVid.video.id,
          offset: relatedVid.offset ?? 0,
        }))
    }

    if (
      signal.__typename === 'ChatMoment' ||
      signal.__typename === 'ManualSignalMoment'
    ) {
      const metadata = signal.metadata

      return {
        type: signal.__typename,
        data: {
          userId,
          videoId,
          signalStartTime,
          signalEndTime,
          relatedVideoData,
          valorantMatchId,
          metadata,
          connectedMoments,
          isUnread: signal.isUnread,
        },
      }
    } else if (signal.__typename === 'ValorantMoment') {
      const kills = signal.kills
      const valorantMatchId = signal.match.id

      return {
        type: 'ValorantMoment',
        data: {
          userId,
          videoId,
          signalStartTime,
          signalEndTime,
          relatedVideoData,
          valorantMatchId,
          kills,
          connectedMoments,
          isUnread: signal.isUnread,
        },
      }
    }
  }, [data?.moment, loading])
}
