import React, { useCallback, useMemo } from 'react'

import { TemplateCropSettings } from 'core/exporter/constants'
import { ReactComponent as SoundOnIcon } from 'icons/svgs/solid/volume-up.svg'
import { ReactComponent as SoundOffIcon } from 'icons/svgs/solid/volume-slash.svg'
import { ReactComponent as CloseIcon } from 'icons/svgs/solid/times.svg'
import { Text } from 'components/core/text'
import { useTimelineState } from 'core/editor/timeline/context'

import { useTemplateEditorState } from './context'
import { useTemplateEditorHelpers } from './hooks'
import { CropSettingsDiv, PrimaryCropSelectDiv, RemoveCropDiv } from './styled'
import { PreviewContentSelect } from './content-select'

interface CropSettingsProps {
  templateCrop: TemplateCropSettings
}

export const CropSettings: React.FC<CropSettingsProps> = ({ templateCrop }) => {
  const {
    selected,
    selectThisTemplateCrop,
    aspectRatioDisplayText,
    setAsPrimary,
    removeThisTemplateCrop,
    videosData,
  } = useCropSettings(templateCrop)

  return (
    <CropSettingsDiv onClick={selectThisTemplateCrop} $selected={selected}>
      <Text variant="h5" as="h5">
        {templateCrop.title}
      </Text>
      <Text variant="text-2">{aspectRatioDisplayText}</Text>
      <br />
      <Text variant="text-2">{templateCrop.defaultContent}</Text>
      <PrimaryCropSelectDiv onClick={setAsPrimary}>
        {templateCrop.primary ? <SoundOnIcon /> : <SoundOffIcon />}
      </PrimaryCropSelectDiv>
      <RemoveCropDiv onClick={removeThisTemplateCrop}>
        <CloseIcon />
      </RemoveCropDiv>
      <PreviewContentSelect
        videosData={videosData}
        cropTitle={templateCrop.title}
      ></PreviewContentSelect>
    </CropSettingsDiv>
  )
}

const useCropSettings = (templateCrop: TemplateCropSettings) => {
  const { startTime: momentStart } = useTimelineState()
  const { selectedCrop, currentlyEditing } = useTemplateEditorState()
  const { selectTemplateCrop, setPrimaryCrop, removeTemplateCrop } =
    useTemplateEditorHelpers()

  const selected = useMemo(
    () => selectedCrop?.title === templateCrop.title,
    [selectedCrop?.title, templateCrop.title]
  )

  const selectThisTemplateCrop = useCallback(
    (evt: React.MouseEvent<HTMLDivElement>) => {
      evt.preventDefault()
      selectTemplateCrop(templateCrop)
    },
    [selectTemplateCrop, templateCrop]
  )

  const setAsPrimary = useCallback(
    (evt: React.MouseEvent) => {
      evt.stopPropagation()
      setPrimaryCrop(templateCrop.localId)
    },
    [setPrimaryCrop, templateCrop.localId]
  )

  const removeThisTemplateCrop = useCallback(() => {
    removeTemplateCrop(templateCrop.localId)
  }, [removeTemplateCrop, templateCrop.localId])

  const aspectRatioDisplayText = useMemo(() => {
    const { width, height } = templateCrop.aspectRatio
    // custom should get different text
    if (width === 0) {
      return 'Custom AR'
    } else {
      return `${width} X ${height}`
    }
  }, [templateCrop])

  return useMemo(
    () => ({
      momentStart,
      videosData: currentlyEditing.videosData,
      setAsPrimary,
      selected,
      selectThisTemplateCrop,
      aspectRatioDisplayText,
      removeThisTemplateCrop,
    }),
    [
      aspectRatioDisplayText,
      currentlyEditing.videosData,
      momentStart,
      removeThisTemplateCrop,
      selectThisTemplateCrop,
      selected,
      setAsPrimary,
    ]
  )
}
