import React, { useCallback, useMemo } from 'react'
import { useTimelineState } from './context'
import { useTimelineHelpers } from './hooks'

export const TimelineZoom: React.FC = () => {
  const { zoomOptions, timelineLength } = useTimelineState()
  const { setTimelineLength } = useTimelineHelpers()

  const currentIdx = useMemo(() => {
    return (zoomOptions ?? []).findIndex((item) => item === timelineLength)
  }, [timelineLength, zoomOptions])

  const setZoom = useCallback(
    (direction: 1 | -1) => {
      return () => {
        if (zoomOptions && zoomOptions[currentIdx + direction] !== undefined) {
          setTimelineLength(zoomOptions[currentIdx + direction])
        }
      }
    },
    [currentIdx, setTimelineLength, zoomOptions]
  )

  return (
    <>
      <button onClick={setZoom(1)}>-</button>
      <button onClick={setZoom(-1)}>+</button>
    </>
  )
}
