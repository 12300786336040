import styled from 'styled-components/macro'

export const AddonContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
`

export const SubtitlesScaledContainer = styled.div<{
  $scale: number
  $top?: number
  $width?: number
  $height?: number
}>`
  position: absolute;
  width: ${({ $width }) => ($width ? `${$width}px` : '1080px')};
  height: ${({ $height }) => ($height ? `${$height}px` : '1920px')};
  display: flex;
  flex-direction: column;

  padding: 10px;
  transform-origin: top left;
  transform: scale(${({ $scale }) => $scale});
  pointer-events: none;
  padding: 0 ${({ $scale }) => $scale * 80}px; // padding for safe area
  padding-top: ${({ $top }) => ($top ? `${$top}px` : 0)};

  align-items: 'center';
`

export const ContentsDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: 'center';
`

export const TextBackgroundDiv = styled.div`
  background-color: rgba(0, 0, 0, 0.75);
  padding: 8px 32px;
  border-radius: 16px;
`
