import { Button } from 'components/core/button'
import { Content, VStack } from 'components/core/layout'
import { Text } from 'components/core/text'
import styled from 'styled-components/macro'

const UnauthorizedDiv = styled.div`
  max-width: 320px;
  margin: 32px auto 0;
`
const UnauthorizedButtonWrapper = styled.div`
  margin: 16px 0 0;
`

export const Unauthorized = () => {
  return (
    <Content>
      <UnauthorizedDiv>
        <VStack>
          <Text variant="text-2">
            You're not authorized to access this page.
          </Text>
          <UnauthorizedButtonWrapper>
            <Button as="a" href="/">
              Return to home
            </Button>
          </UnauthorizedButtonWrapper>
        </VStack>
      </UnauthorizedDiv>
    </Content>
  )
}
