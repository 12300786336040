import { ReactNode } from 'react'

import { MetaDataContentDiv, SidebarContainerDiv } from './styled'

interface Props {
  children: ReactNode
}

export const Sidebar = ({ children }: Props) => {
  return (
    <SidebarContainerDiv>
      <MetaDataContentDiv>{children}</MetaDataContentDiv>
    </SidebarContainerDiv>
  )
}
