import { useMatchPlayerList } from 'core/shared/timeline/hooks'
import { useValorantMatchRoundData } from 'core/shared/valorant/hooks'
import { PlayerRoundTimelineDetail } from 'core/signal-review/valorant/timeline/detail/player-by-round'
import { useMemo } from 'react'
import { useGetMomentDetailQuery } from '__generated__'
import { useComposerState } from '../context'

interface Props {
  videoId: string
}
export const ValorantRoundDetailTimeline = ({ videoId }: Props) => {
  const { valorantMatchId, momentId, primaryVideoId } = useComposerState()

  const { data } = useGetMomentDetailQuery({ variables: { momentId } })
  const { playerDataByRound } = useValorantMatchRoundData(valorantMatchId)

  // make sure it is a moment and not a signal
  const moment = useMemo(() => {
    if (data?.moment?.__typename === 'MultiPerspectiveMoment') {
      return data.moment
    }
  }, [data?.moment])

  const { userId, primaryUserId } = useMemo(() => {
    const thisVideo = moment?.videos.find(({ id }) => videoId === id)
    const primaryVideo = moment?.videos.find(({ id }) => primaryVideoId === id)
    return {
      userId: thisVideo?.user?.id,
      primaryUserId: primaryVideo?.user?.id,
    }
  }, [moment?.videos, primaryVideoId, videoId])

  // valorantMatchId is always set when this component is rendered
  const { playerList } = useMatchPlayerList(
    valorantMatchId ?? '',
    primaryUserId ?? ''
  )

  const roundStats = useMemo(() => {
    const player = playerList.find(
      ({ account }) => account.channel?.id === userId
    )
    if (player && playerDataByRound && playerDataByRound[player?.account.id]) {
      return playerDataByRound[player?.account.id]
    }
  }, [playerDataByRound, playerList, userId])

  return valorantMatchId && userId && primaryUserId && roundStats ? (
    <PlayerRoundTimelineDetail
      matchId={valorantMatchId}
      primaryUserId={primaryUserId}
      roundStats={roundStats}
    />
  ) : null
}
