import { useAuth0 } from '@auth0/auth0-react'
import { HStack, VStack } from 'components/core/layout'
import { Text } from 'components/core/text'
import { AddToCollectionButton } from 'core/moment-collections/add-button'
import { RatingSystem } from 'core/signal-review/controls/rating'
import { translateRatingEnum } from 'core/signal-review/helpers'
import { sum } from 'lodash'
import { useMemo } from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components/macro'
import { useGetReactionSignalQuery } from '__generated__'
import { DownloadFirstExportButton } from './download-button'

export const SavedSignal = () => {
  const { signalId } = useParams<{ signalId: string }>()
  const { multiPerspectiveMoment, myRating, editId } =
    useSavedSignalData(signalId)

  return (
    <HStack gap={12} alignItems="center">
      <VStack alignItems="flex-end">
        <TitleContainerDiv>
          <Text variant="text-3" weight="semibold" as="p">
            {multiPerspectiveMoment?.title}
          </Text>
        </TitleContainerDiv>
        <RatingSystem starSize={12} rating={myRating} setRating={() => {}} />
      </VStack>
      <VStack gap={8} alignItems="flex-end">
        <DownloadFirstExportButton editId={editId} />
        {multiPerspectiveMoment && (
          <AddToCollectionButton
            momentId={multiPerspectiveMoment?.id}
            size="small"
            position="below"
          />
        )}
      </VStack>
    </HStack>
  )
}

const TitleContainerDiv = styled.div`
  position: relative;
  max-width: 280px;
  margin-right: 12px;
  overflow: hidden;

  & > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const useSavedSignalData = (signalId: string) => {
  const { user } = useAuth0()
  const { data } = useGetReactionSignalQuery({
    variables: { signalId },
  })

  const multiPerspectiveMoment = useMemo(() => {
    if (!data || data?.moment?.__typename !== 'ChatMoment') {
      return
    }
    const multi = data.moment.usedIn[0]
    if (multi && multi.__typename === 'MultiPerspectiveMoment') {
      return multi
    }
  }, [data])

  const editId = useMemo(() => {
    if (!multiPerspectiveMoment) return
    return multiPerspectiveMoment.edits[0]?.id
  }, [multiPerspectiveMoment])

  const { myRating, avgRating } = useMemo(() => {
    const myRating = multiPerspectiveMoment?.ratings.find(
      (rating) => rating.user.id === user?.sub
    )?.rating

    const allRatings = multiPerspectiveMoment?.ratings.map((rating) =>
      translateRatingEnum(rating.rating)
    )
    const avgRating = allRatings
      ? sum(allRatings) / allRatings.length
      : undefined

    return {
      myRating: myRating ? translateRatingEnum(myRating) : undefined,
      avgRating,
    }
  }, [multiPerspectiveMoment?.ratings, user?.sub])

  return useMemo(
    () => ({
      multiPerspectiveMoment,
      myRating,
      avgRating,
      editId,
    }),
    [avgRating, multiPerspectiveMoment, myRating, editId]
  )
}
