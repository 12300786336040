import styled, { css } from 'styled-components/macro'
import { Button } from 'components/core/button'
import { Card, Container, Content, Spacing } from 'components/core/layout'
import { Logo } from 'components/core/logo'
import { TextField } from 'components/core/textfield'
import {
  AuthorizationAction,
  useAuthorizationContext,
  useAuthorizationDispatch,
} from 'core/auth/context'
import { useCallback, useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router'
import {
  useCollectionLoginMutation,
  useGetMomentCollectionBySlugLazyQuery,
} from '__generated__'
import { CLIENT_TOKEN_KEY } from 'core/auth/constants'
import { CollectionGrid } from 'core/collections/grid'
import { Text } from 'components/core/text'

export const ClientCollectionPage = () => {
  const { accessToken } = useAuthorizationContext()
  const dispatch = useAuthorizationDispatch()
  const { params } =
    useRouteMatch<{ organizationSlug: string; momentCollectionSlug: string }>()
  const [query, { data, error }] = useGetMomentCollectionBySlugLazyQuery({
    variables: {
      collectionSlug: params.momentCollectionSlug,
      organizationSlug: params.organizationSlug,
    },
  })

  useEffect(() => {
    if (accessToken) {
      query()
    }
  }, [accessToken, query])

  useEffect(() => {
    if (!error) {
      return
    }

    if (error.message === 'unauthorized') {
      localStorage.removeItem(CLIENT_TOKEN_KEY)
      dispatch({
        type: AuthorizationAction.SetAccessToken,
        payload: { accessToken: null, provider: null },
      })
    }
  }, [accessToken, dispatch, error])

  return !accessToken ? (
    <ClientLoginPage />
  ) : (
    <Content>
      {data?.momentCollection && (
        <>
          <Spacing bottom="32px">
            <Text variant="h3">{data.momentCollection.title}</Text>
          </Spacing>
          <CollectionGrid collectionId={data.momentCollection.id} />
        </>
      )}
    </Content>
  )
}

export const ClientLoginPage: React.FC = () => {
  const [password, setPassword] = useState('')
  const [error, setError] = useState(false)
  const [mutation] = useCollectionLoginMutation()
  const { params } =
    useRouteMatch<{ organizationSlug: string; momentCollectionSlug: string }>()
  const dispatch = useAuthorizationDispatch()

  const onSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      const fn = async () => {
        const r = await mutation({
          variables: {
            password,
            organizationSlug: params.organizationSlug,
            collectionSlug: params.momentCollectionSlug,
          },
        })
        if (r.data?.authenticateClient?.accessToken) {
          const accessToken = r.data.authenticateClient.accessToken
          dispatch({
            payload: { accessToken, provider: 'custom' },
            type: AuthorizationAction.SetAccessToken,
          })
          localStorage.setItem('mmnts.client-token', accessToken)
        } else {
          setError(true)
        }
      }

      fn()
    },
    [
      dispatch,
      mutation,
      params.momentCollectionSlug,
      params.organizationSlug,
      password,
    ]
  )

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError(false)
    setPassword(event.target.value)
  }

  return (
    <Content>
      <Container alignItems="center" flex="1">
        <Logo />
      </Container>
      <StyledContainer alignSelf="center">
        <form onSubmit={onSubmit}>
          <Spacing top={128}>
            <Card>
              <Card.Body>
                <Card.Title>Client Login</Card.Title>
                <Card.Text>
                  Enter your password to login to your teams content hub
                </Card.Text>
                <Spacing top={16}>
                  <StyledTextField
                    label="Enter password"
                    name="password"
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={onChange}
                    error={error}
                  />
                </Spacing>
              </Card.Body>
              <Card.Footer alignItems="flex-end">
                <Button size="small">Log in</Button>
              </Card.Footer>
            </Card>
          </Spacing>
        </form>
      </StyledContainer>
    </Content>
  )
}

const StyledContainer = styled(Container)`
  max-width: 420px;
  width: 100%;
`

const StyledTextField = styled(TextField)<{ error?: boolean }>`
  ${({ error, theme }) =>
    error &&
    css`
      border: 2px solid ${theme.colors.rose};
      &:focus {
        border: 2px solid ${theme.colors.rose};
      }
    `}
`
