export const CloseIcon = () => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.17159 0.707102C1.78106 0.316581 1.1479 0.316587 0.757377 0.707116C0.366863 1.09764 0.366864 1.73079 0.757378 2.12132L3.58582 4.94981L0.757494 7.77818C0.366983 8.1687 0.366982 8.80185 0.757493 9.19237C1.14801 9.5829 1.78119 9.5829 2.17171 9.19237L5 6.36401L7.82844 9.19242C8.21896 9.58293 8.85212 9.58291 9.24265 9.19241C9.6332 8.80189 9.63323 8.16868 9.2427 7.77814L6.41431 4.94977L9.24274 2.12131C9.63326 1.73079 9.63326 1.09764 9.24275 0.70712C8.85223 0.31659 8.21906 0.316589 7.82854 0.707118L5.00012 3.53558L2.17159 0.707102Z"
      fill="#E6E8EC"
    />
  </svg>
)
