import { AGENT_THUMB_IMAGES } from 'components/valorant/agent'
import { RANK_ICONS } from 'components/valorant/rank-icon'
import { Justifications } from 'core/composer/template-sidebar/addons/killfeed'
import { useEffect, useMemo, useState } from 'react'
import { MatchIntroStill } from '.'
import { ActionsFactory } from '../render-addons'
import { useMatchIntroData } from './hooks'

interface Props {
  start: number
  end: number
  width: number
  height: number
  videoId: string
  y: number
  justify: Justifications
  currentTime: number
  actionsFactory: ActionsFactory
}

export const MatchIntroRender = ({
  start,
  width,
  height,
  videoId,
  y,
  currentTime,
  actionsFactory,
}: Props) => {
  const actions = useMemo(() => actionsFactory('matchIntro'), [actionsFactory])
  const [preloaded, setPreloaded] = useState(false)
  const { displayName, players, profileImageUrl, teams } = useMatchIntroData(
    videoId,
    currentTime
  )

  const slideshowTimes = useMemo(() => [start], [start])

  const ready = useMemo(() => {
    return (
      players !== undefined &&
      teams !== undefined &&
      Object.values(teams).length > 0 &&
      preloaded
    )
  }, [players, preloaded, teams])

  useEffect(() => {
    const preloadImage = (src) =>
      new Promise((resolve, reject) => {
        const image = new Image()
        image.onload = resolve
        image.onerror = reject
        image.src = src
      })

    const preloadAll = async () => {
      const images = [
        ...Object.values(RANK_ICONS),
        ...Object.values(AGENT_THUMB_IMAGES),
        profileImageUrl,
      ]
      // Preload a bunch of images in parallel
      await Promise.all(images.map((x) => preloadImage(x)))
      setPreloaded(true)
    }
    preloadAll()
  }, [profileImageUrl])

  useEffect(() => {
    actions.addAddon()
  }, [actions])

  useEffect(() => {
    if (ready && slideshowTimes.length > 0) {
      actions.updateDiffTimes(slideshowTimes)
      actions.updateReadyStatus(true)
    }
  }, [actions, ready, slideshowTimes])

  return (
    <MatchIntroStill
      width={width}
      height={height}
      players={players}
      teams={teams}
      currentTime={currentTime}
      displayName={displayName}
      profileImageUrl={profileImageUrl}
      scale={1}
      y={y}
      justify={Justifications.center}
    />
  )
}
