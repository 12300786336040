import { useCallback, useMemo, useRef, useState } from 'react'
import styled, { useTheme } from 'styled-components/macro'
import { InfoIcon } from '@primer/octicons-react'

import { useUpdatePartialMomentsInCollectionMutation } from '__generated__'

import { Button } from 'components/core/button'
import { Card, Container } from 'components/core/layout'
import { Text } from 'components/core/text'
import { TextField } from 'components/core/textfield'
import { useCollectionContext } from 'core/collections/collections-context-provider'

const passwordRegExp = /^.{5,}$/

export const PublicSettings = () => {
  const theme = useTheme()
  const {
    password,
    onChange,
    onSubmit,
    onUnset,
    onFocus,
    unsetDisabled,
    submitDisabled,
    loading,
  } = usePublicLinkSettings()
  return (
    <Card>
      <Card.Body>
        <Card.Title>Public link</Card.Title>
        <Card.Text>
          <Container gap={16}>
            <Text variant="text-2">
              Invite others to view all moments in this collection. A shareable
              url will be created where the visitor needs to enter this
              password. Only people with both this link and password can access
              the content.
            </Text>
            <StyledTextField
              type="password"
              placeholder="Password"
              value={password}
              onChange={onChange}
              onFocus={onFocus}
            />
          </Container>
        </Card.Text>
      </Card.Body>

      <Card.Footer>
        <Container direction="row" justifyContent="space-between">
          <Container direction="row" gap={8} alignItems="center">
            <InfoIcon fill={theme.colors.neutrals.n4} />
            <Text variant="text-3" color={theme.colors.neutrals.n4}>
              The password must be at least 5 characters.
            </Text>
          </Container>
          <Container direction="row" gap={16} flex="none">
            <Button
              size="small"
              color={theme.colors.rose}
              disabled={unsetDisabled}
              onClick={onUnset}
            >
              Unset
            </Button>
            <Button
              disabled={submitDisabled}
              isLoading={loading}
              size="small"
              onClick={onSubmit}
            >
              Set password
            </Button>
          </Container>
        </Container>
      </Card.Footer>
    </Card>
  )
}

const StyledTextField = styled(TextField)`
  width: 60%;
`

const usePublicLinkSettings = () => {
  const { selected: collection } = useCollectionContext()

  const [mutation, { loading }] = useUpdatePartialMomentsInCollectionMutation()
  const [password, setPassword] = useState(
    collection?.publicPassword ? 'strong and unknown' : ''
  )
  const clearOnFocus = useRef(collection?.publicPassword || false)

  const onFocus = useCallback(() => {
    if (clearOnFocus.current) {
      setPassword('')
      clearOnFocus.current = false
    }
  }, [])

  const onChange = useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
    const password = evt.target.value
    setPassword(password)
  }, [])

  const onSubmit = useCallback(() => {
    if (!collection) {
      return
    }
    mutation({ variables: { password, collectionId: collection.id } })
  }, [mutation, collection, password])

  const onUnset = useCallback(() => {
    if (!collection) {
      return
    }
    mutation({ variables: { password: null, collectionId: collection.id } })
    setPassword('')
  }, [mutation, collection])

  return useMemo(
    () => ({
      password,
      onChange,
      onSubmit,
      onUnset,
      onFocus,
      loading,
      submitDisabled: loading || !passwordRegExp.test(password),
      unsetDisabled: !collection?.publicPassword,
    }),
    [password, onChange, onSubmit, loading, collection, onUnset, onFocus]
  )
}
