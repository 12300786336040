import { useCallback, useMemo } from 'react'
import { desaturate } from 'polished'

import { CloseModalButton } from 'components/close-modal/close-button'
import { HStack, Spacing } from 'components/core/layout'
import { Text } from 'components/core/text'
import { Portal } from 'components/dialog/portal'
import { useComposerState } from 'core/composer/context'
import {
  ExportFormat,
  ExportTemplate,
  IG_FORMAT,
  IG_SQUARE_FORMAT,
  STANDARD_FORMAT,
  TIKTOK_FORMAT,
} from 'core/exporter/constants'
import {
  Backdrop,
  PreviewModalRoot,
} from 'core/feed/components/preview-modal/styled'
import { useEditScenes } from 'core/composer/scene-timeline/hooks'
import { ReactComponent as VolumeIcon } from 'icons/svgs/solid/volume.svg'

import { LAYER_COLORS } from '../option'
import {
  CropContainerDiv,
  LayerListDiv,
  ModalContainerDiv,
  ModalContentDiv,
  TemplateContainerDiv,
  TemplateSaveContainerDiv,
} from './styled'
import { TemplatePreview } from './preview'
import { LayerOptionDiv } from '../styled'
import { SaveTemplateLevels } from './save-levels'

interface TemplatePreviewProps {
  format: ExportFormat
  close: () => void
}

export const SavePreset: React.FC<TemplatePreviewProps> = ({
  format,
  close,
}) => {
  const { editId } = useComposerState()
  const { currentScene } = useEditScenes(editId)
  const formatWidth = useMemo(() => WIDTHS[format.id], [format.id])
  const containerDimensions = useMemo(
    () => ({
      height:
        (format.aspectRatio.height / format.aspectRatio.width) * formatWidth,
      width: formatWidth,
    }),
    [format.aspectRatio.height, format.aspectRatio.width, formatWidth]
  )

  return (
    <Portal>
      <PreviewModalRoot>
        <Backdrop $opacity={0.5} />
        <ModalContainerDiv>
          <Text variant="h5" textAlign="center">
            Save a Preset Template
          </Text>
          <ModalContentDiv>
            <Spacing top={32}>
              <HStack justifyContent="center" alignItems="flex-start" gap={24}>
                <LayerListDiv>
                  {currentScene?.template.crops.map((crop, idx) => (
                    <LayerOptionDiv
                      $selected={true}
                      $layerColor={LAYER_COLORS[idx]}
                    >
                      <HStack
                        alignItems="center"
                        justifyContent="space-between"
                        fullWidth={true}
                      >
                        <Text variant="text-2" as="div">
                          {crop.title}
                        </Text>
                        {idx === 0 ? (
                          <VolumeIcon width={16} height={16} fill="white" />
                        ) : null}
                      </HStack>
                    </LayerOptionDiv>
                  ))}
                </LayerListDiv>

                <TemplateSaveContainerDiv style={containerDimensions}>
                  {currentScene?.template && (
                    <TemplatePreview template={currentScene.template} />
                  )}
                </TemplateSaveContainerDiv>
              </HStack>
              {currentScene && (
                <SaveTemplateLevels
                  close={close}
                  format={format}
                  template={currentScene.template}
                />
              )}
            </Spacing>
          </ModalContentDiv>

          <CloseModalButton onClick={close} />
        </ModalContainerDiv>
      </PreviewModalRoot>
    </Portal>
  )
}

interface TemplateOptionProps {
  format: ExportFormat
  template: ExportTemplate
  setTemplate: (nextTemplate: ExportTemplate) => void
}

const WIDTHS = {
  [TIKTOK_FORMAT.id]: 120,
  [IG_SQUARE_FORMAT.id]: 200,
  [IG_FORMAT.id]: 200,
  [STANDARD_FORMAT.id]: 200,
}
export const TemplateOption = ({
  template,
  format,
  setTemplate,
}: TemplateOptionProps) => {
  const formatWidth = useMemo(() => WIDTHS[format.id], [format.id])
  const containerDimensions = useMemo(
    () => ({
      height:
        (format.aspectRatio.height / format.aspectRatio.width) * formatWidth,
      width: formatWidth,
    }),
    [format.aspectRatio.height, format.aspectRatio.width, formatWidth]
  )

  const selectTemplateOption = useCallback(() => {
    setTemplate(template)
  }, [setTemplate, template])

  return (
    <TemplateContainerDiv
      style={containerDimensions}
      onClick={selectTemplateOption}
    >
      <div>
        {template.crops.map(({ localId, position }, idx) => {
          const dynamicStyles = {
            top: `${position.y * 100}%`,
            left: `${position.x * 100}%`,
            width: `${position.width * 100}%`,
            height: `${position.height * 100}%`,
            backgroundColor: desaturate(0.33, LAYER_COLORS[idx]),
          }

          return <CropContainerDiv key={localId} style={dynamicStyles} />
        })}
      </div>
    </TemplateContainerDiv>
  )
}
