import styled from 'styled-components/macro'

export const NavigationBarWrapper = styled.nav`
  background-color: ${({ theme }) => theme.colors.background.primary};
  display: flex;
  height: 88px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.background.tertiary};
`

export const NavigationBarContainer = styled.div`
  max-width: 1136px;
  margin: 0 auto;
  padding: 0 16px;
  display: flex;
  flex: 1;
  align-items: center;

  & > * {
    padding: 0 16px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
`
