import { useCallback } from 'react'
import momentjs from 'moment'

import {
  OverlayType,
  useAddEditToMomentMutation,
  useCreateMultiPerspectiveMomentMutation,
  useExportEditMutation,
  VideoWithOffsetsInput,
} from '__generated__'
import {
  DefaultTemplateSetting,
  Rect,
  STANDARD_FORMAT,
} from 'core/exporter/constants'
import { DEFAULT_CONTENT_TYPES } from '../templates/constants'
import { AutoCutTypes } from 'core/signal-review/exports/hooks'

export interface CreateMomentExportsInput {
  title: string
  startTime: number // ISO date
  endTime: number // ISO date
  mainVideoId: string
  relatedVideoIds: string[]
  exportVideoInfo: { videoId: string; userId: string; name: string }[]
  offsets: VideoWithOffsetsInput[]
  signalIds?: string[]
}

export const useCreateMultiPerspectiveMoment = () => {
  const [createMomentMutation] = useCreateMultiPerspectiveMomentMutation()
  const [createEditMutation] = useAddEditToMomentMutation()
  const [exportEditMutation] = useExportEditMutation()

  const create = useCallback(
    async ({
      title,
      startTime,
      endTime,
      mainVideoId,
      exportVideoInfo,
      offsets,
      relatedVideoIds,
      signalIds,
    }: CreateMomentExportsInput) => {
      const startsAt = momentjs(startTime).toISOString()
      const endsAt = momentjs(endTime).toISOString()
      const input = {
        title,
        startsAt,
        endsAt,
        published: true,
        videoIds: relatedVideoIds,
        mainVideoId: mainVideoId,
        edits: [],
        offsets,
        tags: [],
        signalIds: signalIds ?? [],
      }

      // create moment
      const momentResult = await createMomentMutation({
        variables: { createMultiPerspectiveMomentInput: input },
      })
      const momentId = momentResult.data?.createMultiPerspectiveMoment?.id
      if (!momentId) {
        return
      }

      const edits = await Promise.all(
        exportVideoInfo.map(({ videoId, name }) => {
          const offset =
            offsets.find(
              ({ fromVideoId, toVideoId }) =>
                fromVideoId === mainVideoId && toVideoId === videoId
            )?.offsets.videoOffset ?? 0
          return createEditMutation(
            getEditInput({
              title: `${name} Perspective`,
              videoId,
              startsAt,
              endsAt,
              momentId,
              offset,
            })
          )
        })
      )

      await Promise.all(
        edits.map(({ data }) => {
          const editId = data?.addMomentEditToMoment?.id
          if (!editId) return Promise.reject()
          return exportEditMutation({
            variables: {
              editId,
            },
          })
        })
      )

      return momentId
    },
    [createEditMutation, createMomentMutation, exportEditMutation]
  )

  return create
}

interface EditInput {
  momentId: string
  startsAt: string
  endsAt: string
  title: string
  videoId: string
  offset: number
}

type MultiSceneEditInput = Omit<EditInput, 'startsAt' | 'endsAt'> & {
  sceneTimes: { start: string; end: string }[]
  template: DefaultTemplateSetting
}

export const getEditInput = ({
  title,
  momentId,
  startsAt,
  endsAt,
  videoId,
  offset,
}: EditInput) => {
  return {
    variables: {
      momentId,
      editInput: {
        title,
        formatId: STANDARD_FORMAT.id,
        published: true,
        scenes: [
          {
            start: startsAt,
            end: endsAt,
            index: 0,
            cropData: [
              {
                cropRect: { height: 1, width: 1, x: 0, y: 0 },
                offset,
                title: 'main',
                videoId,
                muted: false,
              },
            ],
            template: {
              title: 'default',
              crops: [
                {
                  title: 'main',
                  aspectRatio: STANDARD_FORMAT.aspectRatio,
                  position: { x: 0, y: 0, width: 1, height: 1 },
                },
              ],
            },
          },
        ],
        approved: false,
      },
    },
  }
}

const VIDEO_SIZE = { x: 0, y: 0, width: 1920, height: 1080 }

export const getEditMultiSceneInput = (
  {
    title,
    momentId,
    sceneTimes,
    videoId,
    offset,
    template,
  }: MultiSceneEditInput,
  type: AutoCutTypes
) => {
  // use our getCoverRect to get cropData for each scene
  const scenes = sceneTimes.map(({ start, end }, idx) => {
    const cropData = template.crops.map((crop) => {
      let coverRect = { height: 1, width: 1, x: 0, y: 0 }
      if (crop.defaultContent === DEFAULT_CONTENT_TYPES.GAMEPLAY) {
        coverRect = getCoverRectPct(
          template.format.aspectRatio,
          crop.position,
          VIDEO_SIZE,
          { x: 0.5, y: 0.5 }
        )
      } else if (crop.defaultContent === DEFAULT_CONTENT_TYPES.CAMERA) {
        const facecamRects = getFacecamRect(videoId)
        if (facecamRects) {
          coverRect = getFacecamCoverRectPct(
            facecamRects.facecamContainerRect,
            facecamRects.facecamRect,
            template.format.aspectRatio,
            crop.position
          )
        }
      }

      return {
        cropRect: coverRect,
        offset,
        title: crop.title,
        videoId,
        muted: crop.primary ? false : true,
      }
    })

    return {
      start: start,
      end: end,
      index: idx,
      cropData: cropData,
      template: {
        title: template.title,
        crops: template.crops.map((crop) => {
          return {
            title: crop.title,
            aspectRatio: crop.aspectRatio,
            position: crop.position,
          }
        }),
      },
      overlays:
        type === 'mobile'
          ? [
              {
                overlayType: OverlayType.Killfeed,
                data: {
                  h: 1920,
                  w: 1080,
                  end: new Date(end).getTime(),
                  vId: videoId,
                  start: new Date(start).getTime(),
                  killfeed: {
                    y: 630,
                    just: 'right',
                  },
                  playerInfo: {
                    y: 1400,
                    just: 'left',
                  },
                },
              },
            ]
          : type === 'mobile-no-cam'
          ? [
              {
                overlayType: OverlayType.Killfeed,
                data: {
                  h: 1920,
                  w: 1080,
                  end: new Date(end).getTime(),
                  vId: videoId,
                  start: new Date(start).getTime(),
                  killfeed: {
                    y: 140,
                    just: 'right',
                  },
                  playerInfo: {
                    y: 1400,
                    just: 'left',
                  },
                },
              },
            ]
          : [
              {
                overlayType: OverlayType.Killfeed,
                data: {
                  h: 1080,
                  w: 1920,
                  end: new Date(end).getTime(),
                  vId: videoId,
                  start: new Date(start).getTime(),
                  playerInfo: {
                    y: 760,
                    just: 'left',
                  },
                  kda: {
                    x: 850,
                    y: 80,
                  },
                },
              },
            ],
    }
  })

  return {
    variables: {
      momentId,
      editInput: {
        title,
        formatId: template.format.id,
        published: true,
        scenes: scenes,
        approved: false,
      },
    },
  }
}

export const getFacecamRect = (videoId: string) => {
  const facecamContainerLocalStorage = localStorage.getItem(
    'facecamContainerRect'
  )
  const facecamContainerRectObj = facecamContainerLocalStorage
    ? JSON.parse(facecamContainerLocalStorage)
    : null

  const facecamLocalStorage = localStorage.getItem('faceRect')
  const facecamRectObj = facecamLocalStorage
    ? JSON.parse(facecamLocalStorage)
    : null

  if (
    !facecamContainerRectObj ||
    !facecamContainerRectObj[`v_${videoId}`] ||
    !facecamRectObj ||
    !facecamRectObj[`v_${videoId}`]
  ) {
    return undefined
  }
  const facecamContainerRect = facecamContainerRectObj[`v_${videoId}`]
  const facecamRect = facecamRectObj[`v_${videoId}`]
  return { facecamContainerRect, facecamRect }
}

export const getFacecamCoverRectPct = (
  containerRectPct: Rect,
  faceRectPct: Rect,
  formatAR: { width: number; height: number },
  cropSizePct: { width: number; height: number }
) => {
  // we get the source rect from the container rect and the source video size
  const newSourceRect = {
    width: containerRectPct.width * VIDEO_SIZE.width,
    height: containerRectPct.height * VIDEO_SIZE.height,
  }

  // we only use the face rect to get the center of the face
  const center = {
    x: faceRectPct.x,
    y: faceRectPct.y,
  }

  // we use the cover rect to get the size of the crop
  const coverRectPct = getCoverRectPct(
    formatAR,
    cropSizePct,
    newSourceRect,
    center
  )

  // now we need to convert this back to a pixels relative to newSourceRect, then back to a pct for the original video size source
  const coverRect = {
    width: coverRectPct.width * newSourceRect.width,
    height: coverRectPct.height * newSourceRect.height,
    x: coverRectPct.x * newSourceRect.width,
    y: coverRectPct.y * newSourceRect.height,
  }

  return {
    width: coverRect.width / VIDEO_SIZE.width,
    height: coverRect.height / VIDEO_SIZE.height,
    x: coverRect.x / VIDEO_SIZE.width + containerRectPct.x,
    y: coverRect.y / VIDEO_SIZE.height + containerRectPct.y,
  }
}

export const getCoverRectPct = (
  formatAR: { width: number; height: number },
  cropSizePct: { width: number; height: number },
  source: {
    width: number
    height: number
  },
  center: {
    x: number
    y: number
  }
) => {
  // lets find aspect ratio of each crop so that we can fit that into the source size with our contain logic
  const cropAR = {
    width: cropSizePct.width * formatAR.width,
    height: cropSizePct.height * formatAR.height,
  }

  let rectInPixels:
    | { width: number; height: number; x: number; y: number }
    | undefined
  const idealWidth = source.width
  const height = (idealWidth * cropAR.height) / cropAR.width
  if (height <= source.height) {
    rectInPixels = {
      width: idealWidth,
      height,
      x: 0,
      y: (source.height - height) * center.y,
    }
  } else {
    const idealHeight = source.height
    const width = (idealHeight * cropAR.width) / cropAR.height
    rectInPixels = {
      width,
      height: idealHeight,
      x: (source.width - width) * center.x,
      y: 0,
    }
  }

  // now we need to convert these sizes into percentages
  const rectInPercentages = {
    width: rectInPixels.width / source.width,
    height: rectInPixels.height / source.height,
    x: rectInPixels.x / source.width,
    y: rectInPixels.y / source.height,
  }
  return rectInPercentages
}
