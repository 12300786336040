import { InputHTMLAttributes } from 'react'
import { CheckboxContainer, HiddenCheckbox, StyledCheckbox } from './styled'

type Props = Omit<InputHTMLAttributes<HTMLInputElement>, 'type'>

export const Checkbox = ({ className, checked, ...props }: Props) => {
  return (
    <CheckboxContainer>
      <HiddenCheckbox checked={checked} {...props} />
      <StyledCheckbox checked={checked} />
    </CheckboxContainer>
  )
}
