import { useMemo, useState } from 'react'

import { Container, Content, HStack, Spacing } from 'components/core/layout'
import { FilterGroup } from 'core/feed/components/filter-group'
import { Text } from 'components/core/text'
import { useFilterForm } from 'core/feed/helpers'
import { Sort } from '__generated__'

import { useCurrentCampaign } from '../hooks'
import { FeedView } from './feed-view'
import { FilterHeader, GridView, Sidebar } from './styled'
import { FeedProvider } from 'core/feed/context'
import { Portal } from 'components/dialog/portal'
import { FixedSignalReviewDiv } from 'page/feed/styles'
import { SignalReviewPage } from 'core/signal-review'
import { Button } from 'components/core/button'

type Props = {
  campaignSlug: string
}

export const CampaignFeedPage = ({ campaignSlug }: Props) => {
  const { data } = useCurrentCampaign(campaignSlug)
  const [signalReviewOpen, setSignalReviewOpen] = useState(false)

  const segmentOptions = useMemo(
    () =>
      (data?.campaign?.segments || []).map((segment) => ({
        value: segment,
        label: segment.title || 'Unknown segment',
      })),
    [data?.campaign?.segments]
  )

  const rosterOptions = useMemo(
    () =>
      (data?.campaign?.channels?.channels.data || []).map((channel) => ({
        value: channel.id,
        label: channel.name,
      })),
    [data?.campaign?.channels?.channels.data]
  )

  const orderOptions = useMemo(
    () => [
      { value: Sort.Asc, label: 'Ascending' },
      { value: Sort.Desc, label: 'Descending' },
    ],
    []
  )

  const {
    data: segmentsData,
    bind: segmentsBind,
    selected: segmentsSelected,
  } = useFilterForm(segmentOptions, false)

  const {
    data: rosterData,
    bind: rosterBind,
    selected: rosterSelected,
  } = useFilterForm(rosterOptions, true)

  const {
    data: orderData,
    bind: orderBind,
    selected: orderSelected,
  } = useFilterForm(orderOptions, false, [orderOptions[0].value])

  const channelIds = useMemo(
    () =>
      rosterSelected.length > 0
        ? rosterSelected
        : (data?.campaign?.channels?.channels.data || []).map(({ id }) => id),
    [data?.campaign?.channels?.channels.data, rosterSelected]
  )

  const { starts: startsAt, ends: endsAt } = useMemo(
    () =>
      segmentsSelected.length
        ? segmentsSelected[0]
        : { starts: undefined, ends: undefined },
    [segmentsSelected]
  )

  return (
    <FeedProvider>
      <Content background="secondary">
        <Container direction="row" gap={16}>
          <Sidebar>
            <Spacing left="16px" bottom="16px">
              <FilterHeader>Filter</FilterHeader>
            </Spacing>
            {data && (
              <>
                <FilterGroup
                  title="Segments"
                  data={segmentsData}
                  collapsed={false}
                  {...segmentsBind}
                />
                <FilterGroup title="Roster" data={rosterData} {...rosterBind} />
              </>
            )}

            <Spacing left="16px" top="32px" bottom="16px">
              <FilterHeader>Order</FilterHeader>
            </Spacing>
            <FilterGroup title="Sort By" data={orderData} {...orderBind} />
            <Spacing vertical={8}>
              <HStack alignItems="center" justifyContent="center">
                <Button
                  size="small"
                  variant="secondary"
                  onClick={() => setSignalReviewOpen(true)}
                >
                  Open Signal Review
                </Button>
              </HStack>
            </Spacing>
          </Sidebar>

          <GridView>
            <HStack alignItems="center" gap={8}>
              <Text variant="h4">{data?.campaign?.title}</Text>
            </HStack>
            <FeedProvider>
              {startsAt && endsAt && (
                <FeedView
                  channelIds={channelIds}
                  startsAt={startsAt}
                  endsAt={endsAt}
                  sort={orderSelected[0]}
                />
              )}
            </FeedProvider>
          </GridView>
        </Container>
      </Content>
      {signalReviewOpen && (
        <Portal>
          <FixedSignalReviewDiv>
            <SignalReviewPage />
          </FixedSignalReviewDiv>
        </Portal>
      )}
    </FeedProvider>
  )
}
