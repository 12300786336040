import { Text } from 'components/core/text'
import { useRouteMatch } from 'react-router'

import { SidebarWrapper, StyledNavLink } from './styled'

export const Sidebar = () => {
  const { path } = useRouteMatch()
  return (
    <SidebarWrapper>
      <StyledNavLink to={`${path}/channels`} exact>
        <Text variant="text-3">Channels</Text>
      </StyledNavLink>
      <StyledNavLink to={`${path}/events`}>
        <Text variant="text-3">Events</Text>
      </StyledNavLink>
      <StyledNavLink to={`${path}/campaigns`}>
        <Text variant="text-3">Campaigns</Text>
      </StyledNavLink>
      <StyledNavLink to={`${path}/organizations`}>
        <Text variant="text-3">Organizations</Text>
      </StyledNavLink>
      <StyledNavLink to={`${path}/channel-collections`}>
        <Text variant="text-3">Channel Collections</Text>
      </StyledNavLink>
      <StyledNavLink to={`${path}/leaderboard`}>
        <Text variant="text-3">Leaderboard</Text>
      </StyledNavLink>
    </SidebarWrapper>
  )
}
