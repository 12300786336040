import React, { useCallback, useMemo, useState } from 'react'

import { Button } from 'components/core/button'
import { TextField } from 'components/core/textfield'
import { TemplateCropSettings } from 'core/exporter/constants'

// import { useTemplateEditorHelpers } from './hooks'
import { CropSettingsActionsDiv, CropSettingsDiv } from '../styled'
import { ASPECT_RATIO_TYPES } from 'components/aspect-ratio/constants'
import { AspectRatioDropdown } from 'components/aspect-ratio/select'
import { DEFAULT_CONTENT_TYPES } from 'core/shared/templates/constants'
import { randomUniqueId } from 'utils'
import { useComposerHelper } from '../hooks'
import { HStack, VStack } from 'components/core/layout'
import { useComposerState } from '../context'
import { containWithAspectRatio } from 'utils/aspect-ratio'

interface CreateTemplateCropProps {
  close: () => void
}

export const CreateTemplateCrop = ({ close }: CreateTemplateCropProps) => {
  const [title, setTitle] = useState('')
  const [defaultContent, setDefaultContent] = useState(
    DEFAULT_CONTENT_TYPES.CUSTOM
  )
  const [aspectRatio, setAspectRatio] = useState(ASPECT_RATIO_TYPES.OFF)
  const { addNewTemplateCrop, setCropRectSetting, setSelectedTemplateCrop } =
    useComposerHelper()
  const { selectedFormat, selectedTemplate } = useComposerState()

  // const onDefaultContentChange = useCallback(
  //   (evt: ChangeEvent<HTMLSelectElement>) => {
  //     setDefaultContent(evt.currentTarget.value as DEFAULT_CONTENT_TYPES)
  //   },
  //   []
  // )

  const validTitle = useMemo(() => {
    const lowerTitle = title.toLocaleLowerCase()
    const currentLowerTitles = selectedTemplate?.crops.map(({ title }) =>
      title.toLowerCase()
    )
    const duplicateTitle = currentLowerTitles?.includes(lowerTitle) === true
    return title.length > 0 && !duplicateTitle
  }, [selectedTemplate?.crops, title])

  const createTemplateCrop = useCallback(() => {
    if (!selectedFormat || !validTitle) return
    // first we need to know what the aspect ratio of the container is
    // then we need to do the math within that to find a good percent size
    let initialDimension: { width: number; height: number }
    if (aspectRatio.title === ASPECT_RATIO_TYPES.OFF.title) {
      initialDimension = {
        width: 0.5,
        height: 0.5,
      }
    } else {
      initialDimension = containWithAspectRatio(
        selectedFormat.aspectRatio,
        aspectRatio
      )
    }

    const newTemplateCrop: TemplateCropSettings = {
      localId: randomUniqueId(),
      title,
      aspectRatio,
      position: {
        x: 0,
        y: 0,
        ...initialDimension,
      },
      defaultContent,
      primary: false,
    }

    //set default value for content
    const defaultContentSelection = {
      x: 0,
      y: 0,
      width: initialDimension.width * selectedFormat.aspectRatio.width,
      height: initialDimension.height * selectedFormat.aspectRatio.height,
    }

    addNewTemplateCrop(newTemplateCrop)
    setCropRectSetting(title, defaultContentSelection)
    setTitle('')
    setAspectRatio(ASPECT_RATIO_TYPES.OFF)
    setDefaultContent(DEFAULT_CONTENT_TYPES.CUSTOM)
    setSelectedTemplateCrop(newTemplateCrop)

    close()
  }, [
    addNewTemplateCrop,
    aspectRatio,
    close,
    defaultContent,
    validTitle,
    selectedFormat,
    setCropRectSetting,
    setSelectedTemplateCrop,
    title,
  ])

  return (
    <CropSettingsDiv $selected={false}>
      <TextField
        value={title}
        onChange={(evt) => setTitle(evt.currentTarget.value)}
        placeholder="Layer Title"
      />
      <CropSettingsActionsDiv>
        <VStack>
          <HStack gap={4}>
            <AspectRatioDropdown
              aspectRatio={aspectRatio}
              setAspectRatio={setAspectRatio}
            />
            {/* <Select value={defaultContent} onChange={onDefaultContentChange}>
              {AVAILABLE_DEFAULT_CONTENT_TYPES.map((dc) => (
                <option key={dc} value={dc}>
                  {dc}
                </option>
              ))}
            </Select> */}
            <Button
              size="small"
              onClick={createTemplateCrop}
              disabled={!validTitle}
            >
              Add Layer
            </Button>
          </HStack>
        </VStack>
      </CropSettingsActionsDiv>
    </CropSettingsDiv>
  )
}
