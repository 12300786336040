import { useCallback, useEffect, useMemo } from 'react'
import { useParams } from 'react-router'
import { DragDropContext } from 'react-beautiful-dnd'

import { Text } from 'components/core/text'
import { MomentViewer } from 'core/moment-viewer'
import { MomentViewerProvider } from 'core/moment-viewer/context'
import { useIsPublicView } from 'core/moment-viewer/hooks'
import {
  AuthorizationAction,
  useAuthorizationContext,
  useAuthorizationDispatch,
} from 'core/auth/context'

import {
  useGetMomentCollectionLazyQuery,
  useUpdateMomentCollectionMutation,
} from '__generated__'
import {
  MomentCollectionHeadingDiv,
  MomentCollectionMomentViewerDiv,
  MomentCollectionPageDiv,
} from './styled'
import { CLIENT_TOKEN_KEY } from 'core/auth/constants'
import { ClientLoginPage } from 'page/client/collection'

export const MomentCollectionPage = () => {
  const { accessToken } = useAuthorizationContext()
  const dispatch = useAuthorizationDispatch()
  const { query, title, momentIds, momentCollectionId, loading, error } =
    useMomentCollectionData()
  const [updateMomentCollection] = useUpdateMomentCollectionMutation()
  const isPublic = useIsPublicView()

  const onOrderEnd = useCallback(
    (result) => {
      if (!momentCollectionId) {
        return
      }
      const nextMomentIds = Array.from(momentIds)
      const [reorderedItem] = nextMomentIds.splice(result.source.index, 1)
      nextMomentIds.splice(result.destination.index, 0, reorderedItem)

      updateMomentCollection({
        variables: {
          momentCollectionId,
          momentCollectionInput: { momentIds: nextMomentIds },
        },
      })
      // dragEnd
      // const handleRemoveItem = _id => {
      // removeTask({
      //   variables: { _id },
      //   update(cache) {
      //     cache.modify({
      //       fields: {
      //         tasks(existingTaskRefs, { readField }) {
      //           return existingTaskRefs.filter(
      //             taskRef => _id !== readField('_id', taskRef),
      //           );
      //         },
      //       },
      //     });
      //   },
      // });
    },
    [momentCollectionId, momentIds, updateMomentCollection]
  )

  useEffect(() => {
    if (accessToken) {
      query()
    }
  }, [accessToken, query])

  useEffect(() => {
    if (!error) {
      return
    }

    if (error.message === 'unauthorized') {
      localStorage.removeItem(CLIENT_TOKEN_KEY)
      dispatch({
        type: AuthorizationAction.SetAccessToken,
        payload: { accessToken: null, provider: null },
      })
    }
  }, [accessToken, dispatch, error])

  return !accessToken ? (
    <ClientLoginPage />
  ) : (
    <MomentCollectionPageDiv $hasNavigation={!isPublic}>
      <MomentCollectionHeadingDiv>
        <Text variant="h3">{title}</Text>
      </MomentCollectionHeadingDiv>
      <MomentCollectionMomentViewerDiv>
        <MomentViewerProvider>
          <DragDropContext onDragEnd={onOrderEnd}>
            <MomentViewer loading={loading} momentIds={momentIds ?? []} />
          </DragDropContext>
        </MomentViewerProvider>
      </MomentCollectionMomentViewerDiv>
    </MomentCollectionPageDiv>
  )
}

const useMomentCollectionData = () => {
  const { momentCollectionId, momentCollectionSlug, organizationSlug } =
    useParams<{
      momentCollectionId?: string
      momentCollectionSlug?: string
      organizationSlug?: string
    }>()
  const [query, { data, loading, error }] = useGetMomentCollectionLazyQuery({
    variables: { momentCollectionId, momentCollectionSlug, organizationSlug },
  })

  const title = useMemo(
    () => data?.momentCollection?.title,
    [data?.momentCollection?.title]
  )
  const momentIds = useMemo(() => {
    return data?.momentCollection?.moments.data.map(({ id }) => id) ?? []
    // return sortBy(
    //   data?.momentCollection?.moments.data.filter(
    //     (m) => m.__typename === 'MultiPerspectiveMoment' && m.published
    //   ),
    //   (m) =>
    //     new Date(
    //       m.__typename === 'MultiPerspectiveMoment' ? m.startsAt : undefined
    //     )
    // ).map((m) => m.id)
  }, [data?.momentCollection?.moments.data])

  return useMemo(
    () => ({ query, title, momentIds, momentCollectionId, loading, error }),
    [query, title, momentIds, momentCollectionId, loading, error]
  )
}
