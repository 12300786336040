import styled from 'styled-components/macro'
import { NavLink as Link } from 'react-router-dom'

import { fonts, fontWeights } from 'theme/typography'

const NavLink = styled(Link)<{ text: string }>`
  display: block;
  ${fonts.text['text-2']}
  font-weight: normal;
  color: ${({ theme }) => theme.colors.neutrals.n4};
  text-align: center;
  text-decoration: none;
  transition: color var(--common-animation-duration) ease-in-out;

  &.active {
    font-weight: ${fontWeights.semibold};
    color: ${({ theme }) => theme.colors.text.primary};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.blue};
  }

  &:after {
    content: '${({ text }) => text}';
    font-weight: ${fontWeights.semibold};
    display: block;
    height: 0px;
    color: transparent;
    overflow: hidden;
    visibility: hidden;
    text-decoration: none;
  }
`
export default NavLink
