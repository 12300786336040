import { Switch } from 'components/core/switch'
import { Text } from 'components/core/text'
import { useCallback, useState } from 'react'

import { useAppTheme } from 'theme/useDarkMode'
import { DarkModeListItem } from './styled'

export const DarkModeItem = () => {
  const { setTheme, theme } = useAppTheme()
  const [checked, setChecked] = useState(theme === 'dark')
  const handleToggle = useCallback(() => {
    const nextTheme = theme === 'dark' ? 'light' : 'dark'
    setTheme(nextTheme)
    setChecked(nextTheme === 'dark')
  }, [theme, setChecked, setTheme])
  return (
    <DarkModeListItem>
      <Text variant={'text-2'}>Dark mode</Text>{' '}
      <Switch checked={checked} onChange={handleToggle} />
    </DarkModeListItem>
  )
}
