interface AspectRatio {
  width: number
  height: number
}

// Finding the contain for a child AR within another
// parent AR
/*
  ChildHeight       ChildWidth
  ------------  =   -----------
  ParentHeight          X

  ->

  X / ParentWidth = childWidthPct
  */

export const containWithAspectRatio = (
  parentAR: AspectRatio,
  childAR: AspectRatio
) => {
  // assume 100% height fill
  const widthFillPct =
    (childAR.width * parentAR.height) / childAR.height / parentAR.width
  if (widthFillPct < 1) {
    return { height: 1, width: widthFillPct }
  } else {
    const heightFillPct =
      (childAR.height * parentAR.width) / childAR.width / parentAR.height
    return { width: 1, height: heightFillPct }
  }
}

export const containDimensions = (
  rect: { width: number; height: number },
  aspectRatio: { width: number; height: number }
) => {
  const fullHeight = {
    height: rect.height,
    width: (rect.height * aspectRatio.width) / aspectRatio.height,
  }

  const fullWidth = {
    width: rect.width,
    height: (rect.width * aspectRatio.height) / aspectRatio.width,
  }

  if (fullHeight.width <= rect.width) {
    return fullHeight
  } else {
    return fullWidth
  }
}

export const findSameHeightFitAR = (
  first: AspectRatio,
  second: AspectRatio
) => {
  const newWidth = (first.height / second.height) * second.width
  return {
    height: first.height,
    width: newWidth + first.width,
  }
}
