import styled from 'styled-components/macro'
import { fontWeights, getFontStyle } from 'theme/typography'

export const Sidebar = styled.aside`
  max-width: 264px;
  width: 100%;
`

export const GridView = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const FilterHeader = styled.h4`
  margin-right: 16px;
  display: inline;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutrals.n5};
  color: ${({ theme }) => theme.colors.neutrals.n3};
  font-weight: 500;
`

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  padding-bottom: 64px;

  &:last-child {
    padding-bottom: 32px;
  }
`

export const GridSeparator = styled.div`
  ${getFontStyle('text-1', 'semibold')};

  padding-bottom: 16px;
  text-align: center;
  font-weight: ${fontWeights.semibold};
  color: ${({ theme }) => theme.colors.neutrals.n5};
`
