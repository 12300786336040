import { ReactNode } from 'react'

import { TimeRangePlayerProvider } from './context'
import { TimeRangeVideoController } from './controller'
import { SimpleTimeline } from './simple-timeline'

interface Props {
  videoId: string
  startTime: number
  endTime: number
  autoPlay?: boolean
  children?: ReactNode
}

export const TimeRangePlayer = ({
  startTime,
  endTime,
  videoId,
  autoPlay,
  children,
}: Props) => {
  return (
    <TimeRangePlayerProvider
      startTime={startTime}
      endTime={endTime}
      videoId={videoId}
      autoPlay={autoPlay}
    >
      <TimeRangeVideoController>
        {children ?? <SimpleTimeline />}
      </TimeRangeVideoController>
    </TimeRangePlayerProvider>
  )
}
