import { AGENT_THUMB_IMAGES } from 'components/valorant/agent'
import { Justifications } from 'core/composer/template-sidebar/addons/killfeed'
import { useEffect, useMemo, useState } from 'react'
import { KillFeedStill, KillfeedTypes } from '.'
import { ActionsFactory } from '../render-addons'
import { useFindAllKillsSlideshow, useKillFeedData } from './hooks'
import {
  KILL_FEED_ABILITIES_BY_AGENT,
  KILL_FEED_WEAPONS,
} from 'components/valorant/killfeed-weapon'

interface Props {
  start: number
  end: number
  width: number
  height: number
  videoId: string
  y: number
  justify: Justifications
  currentTime: number
  actionsFactory: ActionsFactory
  type: KillfeedTypes
}

export const KillfeedRender = ({
  start,
  end,
  width,
  height,
  videoId,
  y,
  justify,
  currentTime,
  type,
  actionsFactory,
}: Props) => {
  const actions = useMemo(() => actionsFactory('killfeed'), [actionsFactory])
  const [preloaded, setPreloaded] = useState(false)
  const { accountId, playerKills, round, gameName, playerStats } =
    useKillFeedData(videoId, start)

  const slideshowTimes = useFindAllKillsSlideshow(
    playerKills ?? {},
    accountId ?? '',
    start,
    end
  )

  const ready = useMemo(() => {
    return accountId !== undefined && preloaded
  }, [accountId, preloaded])

  useEffect(() => {
    const preloadImage = (src) =>
      new Promise((resolve, reject) => {
        const image = new Image()
        image.onload = resolve
        image.onerror = reject
        image.src = src
      })

    const preloadAll = async () => {
      const images = Object.entries(AGENT_THUMB_IMAGES).map(([_, v]) => {
        return v
      })
      const weapons = Object.entries(KILL_FEED_WEAPONS).map(([_, v]) => {
        return v
      })
      const abilities = Object.entries(KILL_FEED_ABILITIES_BY_AGENT).flatMap(
        ([_, agent]) => Object.entries(agent).flatMap(([_, a]) => a)
      )
      const allImages = [...images, ...weapons, ...abilities]

      // Preload a bunch of images in parallel
      await Promise.all(allImages.map((x) => preloadImage(x)))
      setPreloaded(true)
    }
    preloadAll()
  }, [])

  useEffect(() => {
    actions.addAddon()
  }, [actions])

  useEffect(() => {
    if (ready && slideshowTimes.length > 0) {
      actions.updateDiffTimes(slideshowTimes)
      actions.updateReadyStatus(true)
    }
  }, [actions, ready, slideshowTimes])

  return (
    <KillFeedStill
      accountId={accountId}
      gameName={gameName ?? ''}
      killsByPlayer={playerKills}
      width={width}
      height={height}
      round={round}
      currentTime={currentTime}
      playerStats={playerStats}
      scale={1}
      justify={justify}
      y={y}
      type={type ?? 'default'}
    />
  )
}
