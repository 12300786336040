import { useCallback, useMemo } from 'react'
import momentjs from 'moment'
import {
  OverlayInput,
  OverlayType,
  useAddSceneMutation,
  useGetMomentEditQuery,
  useRemoveSceneMutation,
  useUpdateSceneMutation,
} from '__generated__'
import { useEditorState } from 'core/editor/context'

import { useComposerState } from '../context'
import { ApiScene, Scene, STANDARD_FORMAT } from 'core/exporter/constants'
import { useComposerHelper } from '../hooks'
import { useTimelineHelpers } from 'core/editor/timeline/hooks'
import { useEditor } from 'core/editor/hooks'
import { useSceneDisplay } from './item'

export const useEditScenes = (editId: string) => {
  const { seekTo } = useEditor()
  const { setSelectionToTime } = useTimelineHelpers()
  const {
    selectedTemplate,
    cropSettings,
    scenes,
    primaryVideoId,
    killfeedOptions,
    killfeed,
    KDAAddon,
    KDAOptions,
    playerDisplayAddon,
    playerDisplayOptions,
    matchIntroAddon,
    matchIntroOptions,
    selectedFormat,
    subtitlesAddon,
    subtitlesOptions,
  } = useComposerState()
  const { setSceneOrder, setSelectedSceneId, setPrimaryVideoId } =
    useComposerHelper()
  const { selection, offsets } = useEditorState()
  const { refetch } = useGetMomentEditQuery({ variables: { editId } })
  const [removeSceneMutation] = useRemoveSceneMutation()
  const [addSceneMutation] = useAddSceneMutation()
  const [updateSceneMutation] = useUpdateSceneMutation()
  const { loadSceneData } = useSceneDisplay()

  const currentScene = useMemo(() => {
    if (!selection || !selectedTemplate || !primaryVideoId) return
    const primaryTitle = selectedTemplate.crops.find(
      ({ primary }) => primary
    )?.title

    const overlays: OverlayInput[] = []
    const overlayData = {
      start: selection.startTime,
      end: selection.endTime,
      vId: primaryVideoId,
      w: selectedFormat?.id === STANDARD_FORMAT.id ? 1920 : 1080,
      h: selectedFormat?.id === STANDARD_FORMAT.id ? 1080 : 1920,
    }

    if (killfeed) {
      overlayData['killfeed'] = {
        y: killfeedOptions.y,
        just: killfeedOptions.justify,
        type: killfeedOptions.type,
      }
    }

    if (KDAAddon) {
      overlayData['kda'] = {
        x: KDAOptions.x,
        y: KDAOptions.y,
      }
    }

    if (playerDisplayAddon) {
      overlayData['playerInfo'] = {
        y: playerDisplayOptions.y,
        just: playerDisplayOptions.justify,
      }
    }

    if (matchIntroAddon) {
      overlayData['matchIntro'] = {
        y: matchIntroOptions.y,
      }
    }

    if (subtitlesAddon) {
      overlayData['subtitles'] = {
        y: subtitlesOptions.y,
      }
    }

    if (
      killfeed ||
      KDAAddon ||
      playerDisplayAddon ||
      matchIntroAddon ||
      subtitlesAddon
    ) {
      overlays.push({
        overlayType: OverlayType.Killfeed,
        data: overlayData,
      })
    }

    const cropData = selectedTemplate.crops
      .map((tc) => {
        const contentSettings = cropSettings[tc.title]
        if (!contentSettings) {
          // this might happen on initial load, but shouldn't happen after
          return undefined
        }

        return {
          title: tc.title,
          videoId: contentSettings.videoId ?? '',
          cropRect: contentSettings.rect,
          muted: primaryTitle !== tc.title,
          offset:
            primaryVideoId === contentSettings.videoId
              ? 0
              : offsets[primaryVideoId]?.[contentSettings.videoId ?? ''] ?? 0,
        }
      })
      .filter((item) => item !== undefined) as ApiScene['cropData']

    const template = {
      title: selectedTemplate.title,
      crops: selectedTemplate.crops.map((crop) => ({
        title: crop.title,
        aspectRatio: {
          width: crop.aspectRatio.width,
          height: crop.aspectRatio.height,
        },
        position: crop.position,
      })),
    }

    const nextIndex =
      scenes.length > 0 ? scenes[scenes.length - 1].index + 1 : 0
    const scene: ApiScene = {
      start: momentjs(selection.startTime).toISOString(),
      end: momentjs(selection.endTime).toISOString(),
      index: nextIndex,
      cropData,
      template,
      overlays,
    }

    return scene
  }, [
    KDAAddon,
    KDAOptions.x,
    KDAOptions.y,
    cropSettings,
    killfeed,
    killfeedOptions.justify,
    killfeedOptions.type,
    killfeedOptions.y,
    matchIntroAddon,
    matchIntroOptions.y,
    offsets,
    playerDisplayAddon,
    playerDisplayOptions.justify,
    playerDisplayOptions.y,
    primaryVideoId,
    scenes,
    selectedFormat?.id,
    selectedTemplate,
    selection,
    subtitlesAddon,
    subtitlesOptions.y,
  ])

  const reorderScenes = useCallback(
    async (from: number, to: number) => {
      const nextSceneIds = scenes.map(({ id }) => id ?? '') ?? []
      const [reorderedItem] = nextSceneIds.splice(from, 1)
      nextSceneIds.splice(to, 0, reorderedItem)

      setSceneOrder(nextSceneIds)
      await nextSceneIds.map((id, idx) => {
        return updateSceneMutation({
          variables: {
            sceneId: id ?? '',
            sceneInput: {
              index: idx,
            },
          },
        })
      })
    },
    [scenes, setSceneOrder, updateSceneMutation]
  )

  const addScene = useCallback(async () => {
    if (currentScene) {
      if (scenes.length > 0) {
        currentScene.start = currentScene.end
        currentScene.end = momentjs(currentScene.start)
          .add(10, 'seconds')
          .toISOString()
      }
      const results = await addSceneMutation({
        variables: {
          editId: editId,
          sceneInput: currentScene,
        },
      })
      const sceneId = results.data?.addSceneToMomentEdit?.id
      if (sceneId) {
        const primaryCropTitle = currentScene.template.crops[0]?.title

        let primaryVideoId = ''
        currentScene.cropData.forEach((cropData) => {
          if (primaryCropTitle === cropData.title) {
            primaryVideoId = cropData.videoId
          }
        })

        // this should use loadSceneData, but take in API scene?
        setSelectedSceneId(sceneId)
        setPrimaryVideoId(primaryVideoId)
        setSelectionToTime(
          momentjs(currentScene.start).valueOf(),
          momentjs(currentScene.end).valueOf()
        )
        seekTo(momentjs(currentScene.start).valueOf())
      }
      refetch()
    }
  }, [
    addSceneMutation,
    currentScene,
    editId,
    refetch,
    scenes.length,
    seekTo,
    setPrimaryVideoId,
    setSelectedSceneId,
    setSelectionToTime,
  ])

  const updateScene = useCallback(
    async (sceneId: string) => {
      if (!currentScene || scenes.length === 0) return
      const oldScene = scenes.find(({ id }) => id === sceneId) as Scene

      if (currentScene.template.crops.length > 0) {
        await updateSceneMutation({
          variables: {
            sceneId,
            sceneInput: {
              ...currentScene,
              index: oldScene.index,
            },
          },
        })
      }
      refetch()
    },
    [scenes, currentScene, refetch, updateSceneMutation]
  )

  const removeScene = useCallback(
    async (sceneIdToRemove: string) => {
      const sceneIdx = scenes.findIndex(({ id }) => sceneIdToRemove === id)

      await removeSceneMutation({ variables: { sceneId: sceneIdToRemove } })
      const scenesToReorder = scenes.filter(({ index }) => index > sceneIdx)
      await Promise.all(
        scenesToReorder.map((scene) => {
          return updateSceneMutation({
            variables: {
              sceneId: scene.id ?? '', // this should always be set
              sceneInput: {
                index: scene.index - 1,
              },
            },
          })
        })
      )
      refetch()
      const nextSceneIdx = sceneIdx > 0 ? sceneIdx - 1 : sceneIdx + 1
      loadSceneData(scenes[nextSceneIdx])
    },
    [loadSceneData, refetch, removeSceneMutation, scenes, updateSceneMutation]
  )

  return useMemo(
    () => ({
      currentScene,
      reorderScenes,
      addScene,
      updateScene,
      removeScene,
    }),
    [addScene, currentScene, removeScene, reorderScenes, updateScene]
  )
}
