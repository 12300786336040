import styled from 'styled-components/macro'

export const MenuItemWrapper = styled.li`
  display: inline-block;
  margin-left: 16px;

  &:nth-child(1) {
    margin-left: 0;
  }
`
