import { useMemo } from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components/macro'

import { media } from 'theme/media'
import { Content, HStack } from 'components/core/layout'
import { useChannelCollectionDetailQuery } from '__generated__'

import { OrganizationFeed } from './feed'
import { FeedProvider } from 'core/feed/context'
import { OrganizationSidebar } from './sidebar'

const ChannelContent = styled.div`
  width: 100%;
  margin-top: 4px;
  padding: 16px;

  ${media.phablet`
    padding: 0;
    margin-top: 32px;
  `}
`

const MainContent = styled.div`
  margin-left: 272px;
  margin-top: 32px;
  width: 100%;
`
export const ChannelCollectionPage = () => {
  const { collectionId } = useParams<{ collectionId: string }>()
  return (
    <FeedProvider>
      <ChannelCollectionContent collectionId={collectionId} />
    </FeedProvider>
  )
}

interface Props {
  collectionId: string
}

export const ChannelCollectionContent = ({ collectionId }: Props) => {
  return (
    <Content background={'secondary'}>
      <HStack gap={16}>
        <FeedProvider>
          <OrganizationSidebar collectionId={collectionId} />
          <MainContent>
            <ChannelContent>
              <OrganizationFeed collectionId={collectionId} />
            </ChannelContent>
          </MainContent>
        </FeedProvider>
      </HStack>
    </Content>
  )
}

export const useChannelCollectionData = (channelCollectionId: string) => {
  const { data } = useChannelCollectionDetailQuery({
    variables: { id: channelCollectionId },
  })

  const { title } = useMemo(() => {
    return { title: data?.channelCollection?.title }
  }, [data?.channelCollection?.title])

  const channels = useMemo(() => {
    return data?.channelCollection?.channels.data
  }, [data?.channelCollection?.channels])

  const channelIds = useMemo(() => channels?.map(({ id }) => id), [channels])

  return useMemo(
    () => ({ title, channels, channelIds }),
    [channelIds, channels, title]
  )
}
