import { HStack, VStack } from 'components/core/layout'
import { Text } from 'components/core/text'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import {
  LeaderboardPlayerFragment,
  useGetTwitchVideosQuery,
} from '__generated__'

interface playerInfo {
  start: LeaderboardPlayerFragment
  end: LeaderboardPlayerFragment
  rankChange: number
}

interface Props {
  player: playerInfo
  start: string
  end: string
}

export const LeaderboardItem = ({ player, start, end }: Props) => {
  const { data: videosData } = useGetTwitchVideosQuery({
    variables: {
      start,
      end,
      userId: player.start.account?.channel?.id ?? '',
    },
    skip: !player.start.account?.channel?.id,
  })

  const videoDuration = useMemo(() => {
    return videosData?.channel?.videos?.data.reduce((acc, cur) => {
      return (
        acc +
        (new Date(cur.stream?.endedAt).getTime() -
          new Date(cur.stream?.startedAt).getTime())
      )
    }, 0)
  }, [videosData])

  return (
    <Link
      to={`/ranked-gods/rising?channelId=${
        player.start.account?.channel?.id ?? ''
      }&startTime=${start}&endTime=${end}`}
    >
      <VStack key={player.start.account?.id}>
        <HStack>
          <img
            src={player.start.account?.channel?.profileImageUrl ?? ''}
            width={50}
            height={50}
            style={{ borderRadius: '50%' }}
            alt=""
          />
          <Text variant="text-1">{player.start.account?.channel?.name}</Text>
        </HStack>
        <Text variant="text-2">Rank Change:{player.rankChange}</Text>
        <HStack>
          <Text variant="text-2">Start:{player.start.leaderboardRank}</Text>
          <Text variant="text-2">{'->'}</Text>
          <Text variant="text-2">End:{player.end.leaderboardRank}</Text>
        </HStack>
        <Text variant="text-2">
          Wins:{player.end.numberOfWins - player.start.numberOfWins}
        </Text>
        <Text variant="text-2">
          Time Streamed:{' '}
          {videoDuration ? Math.round(videoDuration / 1000 / 60) : 0} minutes
        </Text>
      </VStack>
    </Link>
  )
}
