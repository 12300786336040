import { useCallback, useMemo } from 'react'
import { ValorantVideoInfo } from '.'
import { useValorantMatchEditorUpdater } from './context'

export const useValorantMatchEditorHelpers = () => {
  const dispatch = useValorantMatchEditorUpdater()

  const setMatchId = useCallback(
    (matchId: string) => {
      dispatch({
        type: useValorantMatchEditorUpdater.ACTION_TYPES.SET_MATCH_ID,
        payload: {
          matchId,
        },
      })
    },
    [dispatch]
  )

  const setPrimaryVideoId = useCallback(
    (videoId: string) => {
      dispatch({
        type: useValorantMatchEditorUpdater.ACTION_TYPES.SET_PRIMARY_VIDEO_ID,
        payload: {
          videoId,
        },
      })
    },
    [dispatch]
  )

  const setSelectedVideoInfo = useCallback(
    (selectedVideoInfo: ValorantVideoInfo) => {
      dispatch({
        type: useValorantMatchEditorUpdater.ACTION_TYPES
          .SET_SELECTED_VIDEO_INFO,
        payload: {
          selectedVideoInfo,
        },
      })
    },
    [dispatch]
  )

  const setSelectedMomentTimes = useCallback(
    (selectedMomentTimes: { start: number; end: number }) => {
      dispatch({
        type: useValorantMatchEditorUpdater.ACTION_TYPES
          .SET_SELECTED_MOMENT_TIMES,
        payload: {
          selectedMomentTimes,
        },
      })
    },
    [dispatch]
  )

  const setMainPerspectiveInfo = useCallback(
    (mainPerspectiveInfo: ValorantVideoInfo) => {
      dispatch({
        type: useValorantMatchEditorUpdater.ACTION_TYPES
          .SET_MAIN_PERSPECTIVE_INFO,
        payload: {
          mainPerspectiveInfo,
        },
      })
    },
    [dispatch]
  )

  const setExportingOpen = useCallback(
    (nextExportOpen: boolean) => {
      dispatch({
        type: useValorantMatchEditorUpdater.ACTION_TYPES.SET_EXPORTING_OPEN,
        payload: {
          exportingOpen: nextExportOpen,
        },
      })
    },
    [dispatch]
  )

  return useMemo(
    () => ({
      setMatchId,
      setPrimaryVideoId,
      setSelectedVideoInfo,
      setSelectedMomentTimes,
      setMainPerspectiveInfo,
      setExportingOpen,
    }),
    [
      setExportingOpen,
      setMainPerspectiveInfo,
      setMatchId,
      setPrimaryVideoId,
      setSelectedMomentTimes,
      setSelectedVideoInfo,
    ]
  )
}
