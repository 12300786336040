import momentjs from 'moment'
import { useParams } from 'react-router'
import styled from 'styled-components/macro'
import { useEffect, useRef } from 'react'

import {
  useValorantMatchPreviewData,
  ValorantMatchEditor,
} from 'core/valorant-match/editor'
import { useQuery } from 'utils/browser'
import { useAppTheme } from 'theme/useDarkMode'

export const ValorantMatchEditorPage = () => {
  const { setLocalTheme, theme } = useAppTheme()
  const initialTheme = useRef(theme)
  const { matchId } = useParams<{ matchId: string }>()
  const query = useQuery()
  const userId = query.get('user') ?? ''
  const startUrl = query.get('start')
  const endUrl = query.get('end')
  const { previewData } = useValorantMatchPreviewData(matchId)

  const start = momentjs(startUrl).valueOf()
  const end = momentjs(endUrl).valueOf()

  useEffect(() => {
    const theme = initialTheme.current
    setLocalTheme('dark')
    return () => {
      theme && setLocalTheme(theme)
    }
  }, [setLocalTheme])

  return previewData ? (
    <ValorantPageDiv>
      <ValorantMatchEditor
        matchId={matchId}
        userId={userId}
        start={start}
        end={end}
      />
    </ValorantPageDiv>
  ) : null
}

const ValorantPageDiv = styled.div`
  width: 100vw;
  height: 100vh;
`
