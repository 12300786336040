import { SVGProps } from 'react'

const WIDTH = 15
const HEIGHT = 17

const TwitchIcon = ({
  width = WIDTH,
  height = HEIGHT,
  fill,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 15 17"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.7352 8.98934L10.4489 11.2755H6.85752L4.8983 13.2344V11.2755H1.95956V1.80609H12.7352V8.98934ZM0.652961 0.5L0 3.11235V14.867H2.93908V16.5H4.57124L6.2044 14.867H8.81624L14.0406 9.64331V0.5H0.652961Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.55127 8.33683H6.85737V4.41789H5.55127V8.33683ZM9.14282 8.33683H10.4489V4.41789H9.14282V8.33683Z"
    />
  </svg>
)

export { TwitchIcon }
