import { MomentItemDiv } from 'core/moment-viewer/styled'
import styled from 'styled-components/macro'

export const PreviewModalContentDiv = styled.div`
  position: relative;
  max-width: 848px;
  width: 100%;
  min-width: 800px;

  background-color: ${({ theme }) => theme.colors.background.primary};
  border-radius: 16px;
  box-shadow: ${({ theme }) => theme.elevation.depth3};

  overflow: hidden;
`

export const MomentEditsWrapperDiv = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.background.primary};
  border-radius: 16px;
  box-shadow: ${({ theme }) => theme.elevation.depth3};

  ${MomentItemDiv} {
    background: transparent;
    box-shadow: none;
    border-radius: 16px;
  }
`

export const MomentEditsContainerDiv = styled.div`
  width: 280px;
`

export const MomentPreviewImageDiv = styled.div<{ $backgroundUrl: string }>`
  position: relative;
  display: flex;
  flex: 0 0 64px;
  width: 64px;
  height: 64px;
  margin-right: 16px;
  border-radius: 8px;
  background-image: url(${(p) => p.$backgroundUrl});
  background-position: center center;
  background-size: cover;
`

// supported '16x9' | '1:1' | '9x16'
export const PreviewVideoEditContainerDiv = styled.div<{
  $format: string
}>`
  display: flex;
  justify-content: flex-end;
  // backgroundColor: '#FCFCFD',
  border-radius: 8;

  ${({ $format }) =>
    $format === '16x9'
      ? `
      width: 80vh;
      height: 45vh;
      aspect-ratio: 16/9;
`
      : ``}

  ${({ $format }) =>
    $format === '9x16'
      ? `
      width: 80vh;
      height: 70vh;
    `
      : ``}

${({ $format }) =>
    $format === '1x1'
      ? `
      width: 80vh;
      height: 60vh;
    `
      : ``}
`

export const CreateNewEditDiv = styled.div`
  width: 100%;
  cursor: pointer;
  display: flex;
  padding-top: 8px;
  justify-content: center;

  button {
    padding: 12px;
  }
`
