import React, { createContext, useContext, useReducer } from 'react'

import {
  ExportFormat,
  Rect,
  TemplateCropSettings,
} from 'core/exporter/constants'

// tracking context of where it is being edited, for saving options
export interface CurrentlyEditingContext {
  momentId: string
  videosData: {
    videoId: string
    userId: string
    displayName: string
  }[]
}

interface TemplateEditorState {
  open: boolean
  templateId?: string
  currentlyEditing: CurrentlyEditingContext
  format: ExportFormat
  templateCrops: TemplateCropSettings[]
  selectedCrop?: TemplateCropSettings
  previewContent: { [templateTitle: string]: string | undefined }
  title: string
}

const INITIAL_STATE: TemplateEditorState = {
  open: false,
  templateId: undefined,
  currentlyEditing: {
    momentId: '',
    videosData: [],
  },
  format: {
    id: '',
    title: '',
    aspectRatio: { width: 16, height: 9 },
  },
  previewContent: {},
  templateCrops: [],
  selectedCrop: undefined,
  title: 'Untitled',
}

enum ACTION_TYPES {
  LOAD_TEMPLATE_EDITOR = 'LOAD_TEMPLATE_EDITOR',
  SET_SELECTED_TEMPLATE_CROP = 'SET_SELECTED_TEMPLATE_CROP',
  SET_TEMPLATE_CROP_RECT = 'SET_TEMPLATE_CROP_RECT',
  ADD_TEMPLATE_CROP = 'ADD_TEMPLATE_CROP',
  SET_PRIMARY_CROP = 'SET_PRIMARY_CROP',
  SET_TITLE = 'SET_TITLE',
  REMOVE_CROP = 'REMOVE_CROP',
  SET_PREVIEW_CONTENT = 'SET_PREVIEW_CONTENT',
  CLOSE = 'CLOSE',
}

interface LoadTemplateEditorAction {
  type: ACTION_TYPES.LOAD_TEMPLATE_EDITOR
  payload: {
    currentlyEditing: CurrentlyEditingContext
    format: ExportFormat
    templateCrops?: TemplateCropSettings[]
    templateId?: string
    title?: string
  }
}

interface SelectTemplateCropAction {
  type: ACTION_TYPES.SET_SELECTED_TEMPLATE_CROP
  payload: {
    templateCrop?: TemplateCropSettings
  }
}

interface SetTemplateCropRectAction {
  type: ACTION_TYPES.SET_TEMPLATE_CROP_RECT
  payload: {
    title: string
    rect: Rect
  }
}

interface AddTemplateCropAction {
  type: ACTION_TYPES.ADD_TEMPLATE_CROP
  payload: {
    templateCrop: TemplateCropSettings
  }
}

interface SetPrimaryCropAction {
  type: ACTION_TYPES.SET_PRIMARY_CROP
  payload: {
    templateCropId: string
  }
}

interface SetTitleAction {
  type: ACTION_TYPES.SET_TITLE
  payload: {
    title: string
  }
}

interface RemoveCropAction {
  type: ACTION_TYPES.REMOVE_CROP
  payload: {
    templateCropId: string
  }
}

interface SetPreviewContentAction {
  type: ACTION_TYPES.SET_PREVIEW_CONTENT
  payload: {
    title: string
    videoId: string | undefined
  }
}

interface CloseAction {
  type: ACTION_TYPES.CLOSE
}

type TemplateEditorActions =
  | LoadTemplateEditorAction
  | SelectTemplateCropAction
  | SetTemplateCropRectAction
  | AddTemplateCropAction
  | SetPrimaryCropAction
  | SetTitleAction
  | RemoveCropAction
  | SetPreviewContentAction
  | CloseAction

type Dispatch = (action: TemplateEditorActions) => void

const reducer = (
  state: TemplateEditorState,
  action: TemplateEditorActions
): TemplateEditorState => {
  switch (action.type) {
    case ACTION_TYPES.LOAD_TEMPLATE_EDITOR: {
      const { currentlyEditing, format, templateCrops, templateId, title } =
        action.payload
      return {
        ...state,
        open: true,
        currentlyEditing,
        format,
        templateCrops: templateCrops ?? [],
        templateId: templateId,
        title: title ?? 'Untitled',
      }
    }

    case ACTION_TYPES.SET_SELECTED_TEMPLATE_CROP: {
      const { templateCrop } = action.payload
      return {
        ...state,
        selectedCrop: templateCrop,
      }
    }

    case ACTION_TYPES.SET_TEMPLATE_CROP_RECT: {
      const { title, rect } = action.payload

      const nextTemplateCrops = state.templateCrops.map((tc) => {
        const nextPosition = {
          position: tc.title === title ? rect : tc.position,
        }

        return {
          ...tc,
          ...nextPosition,
        }
      })

      return {
        ...state,
        templateCrops: nextTemplateCrops,
      }
    }

    case ACTION_TYPES.ADD_TEMPLATE_CROP: {
      const { templateCrop } = action.payload

      return {
        ...state,
        templateCrops: [...state.templateCrops, templateCrop],
      }
    }

    case ACTION_TYPES.SET_PRIMARY_CROP: {
      const { templateCropId } = action.payload

      const nextTemplateCrops = state.templateCrops.map((tc) => {
        return {
          ...tc,
          primary: tc.localId === templateCropId,
        }
      })

      return {
        ...state,
        templateCrops: nextTemplateCrops,
      }
    }

    case ACTION_TYPES.SET_TITLE: {
      return {
        ...state,
        title: action.payload.title,
      }
    }

    case ACTION_TYPES.REMOVE_CROP: {
      const nextTemplateCrops = state.templateCrops.filter(({ localId }) => {
        return localId !== action.payload.templateCropId
      })

      return {
        ...state,
        templateCrops: nextTemplateCrops,
      }
    }

    case ACTION_TYPES.SET_PREVIEW_CONTENT: {
      const { title, videoId } = action.payload
      const nextPreviewContent = { ...state.previewContent, [title]: videoId }

      return {
        ...state,
        previewContent: nextPreviewContent,
      }
    }

    case ACTION_TYPES.CLOSE: {
      return {
        ...INITIAL_STATE,
      }
    }

    default:
      return state
  }
}

const TemplateEditorContext = createContext<TemplateEditorState | undefined>(
  undefined
)
const TemplateEditorUpdaterContext = createContext<Dispatch | undefined>(
  undefined
)

export const TemplateEditorProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE)

  return (
    <TemplateEditorContext.Provider value={state}>
      <TemplateEditorUpdaterContext.Provider value={dispatch}>
        {children}
      </TemplateEditorUpdaterContext.Provider>
    </TemplateEditorContext.Provider>
  )
}

export const useTemplateEditorState = () => {
  const context = useContext(TemplateEditorContext)
  if (context === undefined) {
    throw new Error(
      'useTemplateEditorState must be used within a TemplateEditorProvider'
    )
  }
  return context
}

export const useTemplateEditorUpdater = () => {
  const context = useContext(TemplateEditorUpdaterContext)
  if (context === undefined) {
    throw new Error(
      'useTemplateEditorUpdater must be used within a TemplateEditorProvider'
    )
  }
  return context
}

useTemplateEditorUpdater.ACTION_TYPES = ACTION_TYPES
