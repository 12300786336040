import { transparentize } from 'polished'
import { useEffect, useMemo } from 'react'
import { useTheme } from 'styled-components/macro'

import { IconButton } from 'core/composer/template-sidebar/styled'
import { ReactComponent as CircleDownIcon } from 'icons/svgs/solid/arrow-alt-circle-down.svg'
import { RecordingStatus, useGetEditPlayerQuery } from '__generated__'

const PollingLength = 10 * 1000

interface Props {
  editId?: string
}

export const DownloadFirstExportButton = ({ editId }: Props) => {
  const { data, startPolling, stopPolling } = useGetEditPlayerQuery({
    variables: { editId: editId ?? '' },
    skip: editId === undefined,
  })

  const { status, url } = useMemo(() => {
    const recentExport = data?.momentEdit?.exports[0]
    if (data && recentExport) {
      return {
        url: recentExport.output?.videos?.[0]?.url,
        status: recentExport.status,
      }
    }
    return { status: undefined, url: undefined }
  }, [data])

  useEffect(() => {
    if (
      status === RecordingStatus.Inprogress ||
      status === RecordingStatus.Created
    ) {
      startPolling(PollingLength)
      return () => {
        stopPolling()
      }
    }
  }, [startPolling, status, stopPolling])

  return status === RecordingStatus.Completed && url ? (
    <a href={url}>
      <DownloadButton isLoading={false} />
    </a>
  ) : (
    <DownloadButton isLoading={true} />
  )
}

interface ButtonProps {
  isLoading: boolean
}

const DownloadButton = ({ isLoading }: ButtonProps) => {
  const theme = useTheme()
  return (
    <IconButton
      isLoading={isLoading}
      color={transparentize(0.2, theme.colors.static.neutrals.n4)}
      onClick={() => {}}
    >
      <CircleDownIcon fill={theme.colors.static.neutrals.n8} width={16} />
      <span>Download</span>
    </IconButton>
  )
}
