import React, { useMemo } from 'react'

import { ValorantRoundStats } from 'core/shared/valorant/hooks'
import { ReactComponent as CrosshairIcon } from 'icons/svgs/regular/crosshairs.svg'

import { useTimelineHelpers } from '../hooks'
import {
  KillCountDiv,
  KillNotchDiv,
  RoundCountDiv,
  TimelineRoundContainerDiv,
} from './styled'

//

interface ValorantRoundTimelineProps {
  data: ValorantRoundStats
}

export const ValorantRoundTimeline: React.FC<ValorantRoundTimelineProps> = ({
  data,
}) => {
  const { containerStyles, killNotchStyles } = useValorantRoundData(data)
  return (
    <TimelineRoundContainerDiv style={containerStyles}>
      <RoundCountDiv>#{data.roundNum + 1}</RoundCountDiv>
      <KillCountDiv>
        {data.stats.killCount}
        <CrosshairIcon />
      </KillCountDiv>
      {killNotchStyles.map((style, idx) => (
        <KillNotchDiv key={idx} style={style} />
      ))}
    </TimelineRoundContainerDiv>
  )
}

const useValorantRoundData = (data: ValorantRoundStats) => {
  const { getContentWidthFromTime } = useTimelineHelpers()
  const containerStyles = useMemo(
    () => ({
      width: getContentWidthFromTime(data.roundLength),
    }),
    [data.roundLength, getContentWidthFromTime]
  )

  const killNotchStyles = useMemo(() => {
    const killOffset = data.stats.kills.map(({ timeSinceRoundStartMillis }) =>
      getContentWidthFromTime(timeSinceRoundStartMillis)
    )
    return killOffset.map((pxOffset) => ({ left: pxOffset }))
  }, [data.stats.kills, getContentWidthFromTime])

  return useMemo(
    () => ({ killNotchStyles, containerStyles }),
    [containerStyles, killNotchStyles]
  )
}
