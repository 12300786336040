import { useCallback } from 'react'
import { useAppThemeContext } from './context'

export type Theme = 'light' | 'dark'

const useAppTheme = () => {
  const { theme, dispatch } = useAppThemeContext()

  const setThemeAction = useCallback(
    (theme: Theme) => {
      dispatch({
        type: useAppThemeContext.ACTION_TYPES.SET_THEME,
        payload: { theme },
      })
    },
    [dispatch]
  )

  const setLocalThemeAction = useCallback(
    (theme: Theme) => {
      dispatch({
        type: useAppThemeContext.ACTION_TYPES.SET_LOCAL_THEME,
        payload: { theme },
      })
    },
    [dispatch]
  )

  const toggleTheme = useCallback(() => {
    dispatch({ type: useAppThemeContext.ACTION_TYPES.TOGGLE_THEME })
  }, [dispatch])

  return {
    theme,
    setTheme: setThemeAction,
    toggleTheme,
    setLocalTheme: setLocalThemeAction,
  }
}

export { useAppTheme }
