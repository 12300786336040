import { useBreakpoint } from 'styled-breakpoints/react-styled'
import { up } from 'styled-breakpoints'
import { AspectRatioContainer } from 'components/aspect-ratio/container'
import { HStack, Spacing, VStack } from 'components/core/layout'
import { UserPreview } from 'components/user-preview'
import { AddToCollectionButton } from 'core/moment-collections/add-button'
import { RatingSystem } from 'core/signal-review/controls/rating'
import { ReactComponent as CircleDownIcon } from 'icons/svgs/solid/arrow-alt-circle-down.svg'

import { useMomentViewerState } from './context'
import { useIsPublicView, useMomentViewerData } from './hooks'
import {
  DownloadIconContainerDiv,
  PreviewDiv,
  PreviewWrapperDiv,
  UserListContainerDiv,
} from './styled'
import { Link } from 'react-router-dom'
import { Button } from 'components/core/button'

interface Props {
  includeUnapproved: Boolean
}

export const PreviewMomentPlayer = ({ includeUnapproved }: Props) => {
  const { selectedMomentId, selectedEditId } = useMomentViewerState()
  const {
    signals,
    selectedExportData,
    containerStyles,
    rating,
    rateMoment,
    avgRating,
    videoData,
  } = useMomentViewerData(selectedMomentId, selectedEditId)
  const isPublic = useIsPublicView()
  const isAboveMobile = useBreakpoint(up('sm')) || true

  // console.log(selectedEditId)

  return (
    <PreviewWrapperDiv>
      <PreviewDiv>
        {selectedExportData ? (
          <AspectRatioContainer
            containerStyles={containerStyles}
            width={selectedExportData.aspectRatio.width}
            height={selectedExportData.aspectRatio.height}
            forceFullWidth={!isAboveMobile}
          >
            <video
              src={selectedExportData.videoUrl}
              poster={selectedExportData.thumbnailUrl}
              controls={true}
              autoPlay={true}
              muted={false}
              playsInline
            />
          </AspectRatioContainer>
        ) : null}
      </PreviewDiv>
      <Spacing top={16} bottom={16}>
        <VStack gap={16}>
          <HStack alignItems="center">
            <HStack alignItems="center" gap={16}>
              <RatingSystem
                rating={rating}
                setRating={rateMoment}
                avgRating={avgRating}
              />
            </HStack>
            <HStack alignItems="center" flex="1" justifyContent="flex-end">
              <DownloadIconContainerDiv>
                <a href={selectedExportData?.videoUrl}>
                  <CircleDownIcon width={24} height={24} />
                </a>
              </DownloadIconContainerDiv>
              {!isPublic && signals && signals[0] && (
                <DownloadIconContainerDiv>
                  <Link to={`/review/${signals[0].id}`}>
                    <Button variant="secondary">Edit</Button>
                  </Link>
                </DownloadIconContainerDiv>
              )}
              {includeUnapproved && !isPublic ? (
                <AddToCollectionButton momentId={selectedMomentId} />
              ) : null}
            </HStack>
          </HStack>
          <HStack justifyContent="space-between">
            <UserListContainerDiv>
              {videoData?.map(({ displayName, avatarUrl }, idx) => (
                <UserPreview
                  key={idx}
                  avatarUrl={avatarUrl}
                  displayName={displayName}
                />
              ))}
            </UserListContainerDiv>
          </HStack>
        </VStack>
      </Spacing>
    </PreviewWrapperDiv>
  )
}
