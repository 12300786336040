import { useCallback, useEffect } from 'react'

import { HStack } from 'components/core/layout'
import { Text } from 'components/core/text'
import { FeedType, useFeedState } from 'core/feed/context'
import { useFeedHelpers } from 'core/feed/helpers'
import { ReactionFeed } from 'page/feed/reaction-feed'
import { ValorantFeed } from 'page/feed/valorant-feed'

import { useChannelCollectionData } from '.'

interface OrganizationFeedProps {
  collectionId: string
}

export const OrganizationFeed = ({ collectionId }: OrganizationFeedProps) => {
  const { channelIds } = useChannelCollectionData(collectionId)
  const { type } = useFeedState()
  const { setChannelIds, setFeedType: setType } = useFeedHelpers()

  const setFeedType = useCallback(
    (type: FeedType) => {
      setType(type)
    },
    [setType]
  )

  useEffect(() => {
    if (!channelIds) {
      return
    }
    setFeedType(FeedType.ValorantSignal)
    // setFilters(DEFAULT_VALORANT_FILTER)
    setChannelIds(channelIds)
  }, [channelIds, setChannelIds, setFeedType])

  return (
    <>
      <HStack alignItems="center" gap={8}>
        <Text variant="h4">Feed</Text>
      </HStack>

      {type === FeedType.ValorantSignal ? <ValorantFeed /> : <ReactionFeed />}
    </>
  )
}
