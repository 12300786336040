import styled from 'styled-components/macro'
import { HStack, VStack } from 'components/core/layout'
import { Spinner } from 'components/core/spinner'
import { Text } from 'components/core/text'
import { StreamerAvatar } from 'components/streamer-avatar'
import { useGetChannelInfoQuery } from '__generated__'
import { Link, NavLink } from 'react-router-dom'

interface Props {
  channelName: string
}

export const ChannelHeading = ({ channelName }: Props) => {
  const { data } = useGetChannelInfoQuery({
    variables: { channelName },
  })

  return data ? (
    <ChannelHeadingDiv>
      <HStack gap={16} alignItems="center">
        <AvatarContainerDiv>
          <StreamerAvatar
            height={64}
            width={64}
            profileImageUrl={data.channel?.profileImageUrl ?? ''}
          />
        </AvatarContainerDiv>
        <VStack gap={4}>
          <Link to={`/roster/${channelName}`}>
            <Text variant="h4">{data.channel?.displayName}</Text>
          </Link>
          <HeadingLinksDiv>
            <NavLink
              to={`/roster/${channelName}/saved`}
              activeClassName="active"
            >
              <Text variant="text-3" weight="semibold">
                Saved Moments
              </Text>
            </NavLink>
            <NavLink
              to={`/roster/${channelName}/videos`}
              activeClassName="active"
            >
              <Text variant="text-3" weight="semibold">
                Videos
              </Text>
            </NavLink>
          </HeadingLinksDiv>
        </VStack>
      </HStack>
    </ChannelHeadingDiv>
  ) : (
    <HStack fullHeight fullWidth justifyContent="center">
      <Spinner size={24} />
    </HStack>
  )
}

const HeadingLinksDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;

  a {
    span {
      color: ${({ theme }) => theme.colors.neutrals.n4};

      &:hover {
        color: ${({ theme }) => theme.colors.blue};
      }
    }
    &.active {
      span {
        color: ${({ theme }) => theme.colors.neutrals.n1};
      }
    }
  }
`

const ChannelHeadingDiv = styled.div`
  padding: 16px;
  padding-bottom: 12px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.neutrals.n8};
  box-shadow: ${({ theme }) => theme.elevation.depth3};
`

const AvatarContainerDiv = styled.div<{ size?: number }>`
  border-radius: 50%;
  width: ${({ size }) => size ?? 64}px;
  height: ${({ size }) => size ?? 64}px;
  overflow: hidden;
`
