import { css, ThemedCssFunction } from 'styled-components/macro'
import { ThemeType } from './theme'

const sizes = {
  giant: 11,
  desktop: 992,
  tablet: 768,
  phablet: 572,
  phone: 376,
}

export const media = (Object.keys(sizes) as (keyof typeof sizes)[]).reduce(
  (acc, label) => {
    acc[label] = (first: any, ...interpolations: any[]) => css`
      @media (max-width: ${sizes[label]}px) {
        ${css(first, ...interpolations)}
      }
    `

    return acc
  },
  {} as {
    [key in keyof typeof sizes]: ThemedCssFunction<ThemeType>
  }
)
