import React, { createContext, useContext, useReducer } from 'react'
import { uniq } from 'lodash'

export enum SyncStatus {
  Loading = 'Loading',
  Syncing = 'Syncing',
  Synced = 'Synced',
}

export interface SyncedVideoStatus {
  key: string
  videoId: string
  syncStatus: SyncStatus
  playing: boolean
  muted: boolean
}

// interface VideoOffsets {
//   gameplayOffset: number
//   audioOffset: number
// }

interface EditorState {
  primaryVideoId?: string
  videoIds: string[]
  videoStatuses: { [key: string]: SyncedVideoStatus }
  targetTime?: number
  playing: boolean
  currentTime: number
  status: SyncStatus
  offsets: { [relatedVideoId: string]: number }
  selection?: { startTime: number; endTime: number }
  playWhenReady: boolean
}

const INITIAL_STATE: EditorState = {
  primaryVideoId: undefined,
  videoIds: [],
  videoStatuses: {},
  targetTime: undefined,
  playing: false,
  currentTime: 0,
  status: SyncStatus.Loading,
  offsets: {},
  selection: undefined,
  playWhenReady: false,
}

enum ACTION_TYPES {
  SET_PRIMARY_VIDEO = 'SET_PRIMARY_VIDEO',
  ADD_VIDEO = 'ADD_VIDEO',
  REMOVE_VIDEO = 'REMOVE_VIDEO',
  UPDATE_VIDEO_STATUS = 'UPDATE_VIDEO_STATUS',
  SET_PLAYING = 'SET_PLAYING',
  SEEK_TO = 'SEEK_TO',
  SET_SYNC_STATUS = 'SET_SYNC_STATUS',
  SET_CURRENT_TIME = 'SET_CURRENT_TIME',
  SET_OFFSETS = 'SET_OFFSETS',
  ASSIGN_OFFSETS = 'ASSIGN_OFFSETS',
  SET_SELECTION = 'SET_SELECTION',
  SET_PLAY_WHEN_READY = 'SET_PLAY_WHEN_READY',
  SEEK_TO_CURRENT_TIME = 'SEEK_TO_CURRENT_TIME',
  SEEK_RELATIVE = 'SEEK_RELATIVE',
}

interface InitializeSegmentsAction {
  type: ACTION_TYPES.SET_PRIMARY_VIDEO
  payload: {
    primaryVideoId?: string
  }
}

interface AddVideoIdAction {
  type: ACTION_TYPES.ADD_VIDEO
  payload: {
    key: string
    videoId: string
  }
}

interface RemoveVideoIdAction {
  type: ACTION_TYPES.REMOVE_VIDEO
  payload: {
    key: string
  }
}

interface UpdateVideoStatusAction {
  type: ACTION_TYPES.UPDATE_VIDEO_STATUS
  payload: {
    videoStatus: SyncedVideoStatus
  }
}

interface SetPlayingAction {
  type: ACTION_TYPES.SET_PLAYING
  payload: {
    playing: boolean
  }
}

interface SeekToAction {
  type: ACTION_TYPES.SEEK_TO
  payload: {
    targetTime: number
  }
}

interface SetSyncStatusAction {
  type: ACTION_TYPES.SET_SYNC_STATUS
  payload: {
    status: SyncStatus
  }
}

interface SetCurrentTimeAction {
  type: ACTION_TYPES.SET_CURRENT_TIME
  payload: {
    currentTime: number
  }
}

interface SetOffsetsAction {
  type: ACTION_TYPES.SET_OFFSETS
  payload: {
    offsets: {
      [relatedVideoId: string]: number
    }
  }
}

interface SetSelectionAction {
  type: ACTION_TYPES.SET_SELECTION
  payload: {
    selection?: { startTime: number; endTime: number }
  }
}

interface SetPlayWhenReadyAction {
  type: ACTION_TYPES.SET_PLAY_WHEN_READY
  payload: {
    playWhenReady: boolean
  }
}

interface SetPlayWhenReadyAction {
  type: ACTION_TYPES.SET_PLAY_WHEN_READY
  payload: {
    playWhenReady: boolean
  }
}

interface SeekToCurrentTimeAction {
  type: ACTION_TYPES.SEEK_TO_CURRENT_TIME
  payload: {
    videoKey: string
  }
}

interface SeekToRelativeTimeAction {
  type: ACTION_TYPES.SEEK_RELATIVE
  payload: {
    timeDiff: number // in ms
  }
}

interface AssignOffsetsAction {
  type: ACTION_TYPES.ASSIGN_OFFSETS
  payload: {
    offsets: { [relatedVideoId: string]: number }
  }
}

type EditorActions =
  | InitializeSegmentsAction
  | AddVideoIdAction
  | RemoveVideoIdAction
  | UpdateVideoStatusAction
  | SetPlayingAction
  | SeekToAction
  | SetSyncStatusAction
  | SetCurrentTimeAction
  | SetOffsetsAction
  | SetSelectionAction
  | SetPlayWhenReadyAction
  | SeekToCurrentTimeAction
  | SeekToRelativeTimeAction
  | AssignOffsetsAction

type Dispatch = (action: EditorActions) => void

const reducer = (state: EditorState, action: EditorActions): EditorState => {
  switch (action.type) {
    case ACTION_TYPES.SET_PRIMARY_VIDEO: {
      return {
        ...state,
        primaryVideoId: action.payload.primaryVideoId,
      }
    }

    case ACTION_TYPES.ADD_VIDEO: {
      const { videoId, key } = action.payload
      const videoStatus: SyncedVideoStatus = {
        key,
        videoId,
        syncStatus: SyncStatus.Loading,
        playing: false,
        muted: true,
      }
      const nextVideoMap = { ...state.videoStatuses, [key]: videoStatus }
      const nextVideoIds = uniq(
        Object.values(nextVideoMap).map(({ videoId }) => videoId)
      )

      return {
        ...state,
        videoIds: nextVideoIds,
        videoStatuses: nextVideoMap,
      }
    }

    case ACTION_TYPES.REMOVE_VIDEO: {
      const { key } = action.payload
      const nextVideoMap = { ...state.videoStatuses }
      delete nextVideoMap[key]
      const nextVideoIds = uniq(
        Object.values(nextVideoMap).map(({ videoId }) => videoId)
      )

      return {
        ...state,
        videoIds: nextVideoIds,
        videoStatuses: nextVideoMap,
      }
    }

    case ACTION_TYPES.SET_PLAYING: {
      return {
        ...state,
        playing: action.payload.playing,
      }
    }

    case ACTION_TYPES.UPDATE_VIDEO_STATUS: {
      const nextVideoMap = {
        ...state.videoStatuses,
        [action.payload.videoStatus.key]: action.payload.videoStatus,
      }
      return {
        ...state,
        videoStatuses: nextVideoMap,
      }
    }

    case ACTION_TYPES.SEEK_TO: {
      const nextVideoStatusMap = Object.values(state.videoStatuses).reduce(
        (memo, vs) => {
          return {
            ...memo,
            [vs.key]: {
              ...vs,
              syncStatus: SyncStatus.Loading,
            },
          }
        },
        {}
      )

      return {
        ...state,
        playing: false,
        targetTime: action.payload.targetTime,
        videoStatuses: nextVideoStatusMap,
        status: SyncStatus.Syncing,
        currentTime: action.payload.targetTime,
      }
    }

    case ACTION_TYPES.SET_SYNC_STATUS: {
      const { status } = action.payload

      return {
        ...state,
        status,
        targetTime: status === SyncStatus.Synced ? undefined : state.targetTime,
      }
    }

    case ACTION_TYPES.SET_CURRENT_TIME: {
      return {
        ...state,
        currentTime: action.payload.currentTime,
      }
    }

    case ACTION_TYPES.SET_OFFSETS: {
      return {
        ...state,
        offsets: action.payload.offsets,
      }
    }

    case ACTION_TYPES.SET_SELECTION: {
      const { selection } = action.payload
      return {
        ...state,
        selection,
      }
    }

    case ACTION_TYPES.SET_PLAY_WHEN_READY: {
      return {
        ...state,
        playWhenReady: action.payload.playWhenReady,
      }
    }

    // used as a resync when switching layouts
    case ACTION_TYPES.SEEK_TO_CURRENT_TIME: {
      const { videoKey } = action.payload
      const nextVideoStatusMap = {
        ...state.videoStatuses,
        [videoKey]: {
          ...state.videoStatuses[videoKey],
          syncStatus: SyncStatus.Loading,
        },
      }

      return {
        ...state,
        playing: false,
        targetTime: state.currentTime,
        videoStatuses: { ...nextVideoStatusMap },
        status: SyncStatus.Syncing,
        currentTime: state.currentTime,
        playWhenReady: state.playing,
      }
    }

    case ACTION_TYPES.SEEK_RELATIVE: {
      const nextVideoStatusMap = Object.values(state.videoStatuses).reduce(
        (memo, vs) => {
          return {
            ...memo,
            [vs.key]: {
              ...vs,
              syncStatus: SyncStatus.Loading,
            },
          }
        },
        {}
      )

      return {
        ...state,
        playing: false,
        targetTime: state.currentTime + action.payload.timeDiff,
        videoStatuses: nextVideoStatusMap,
        status: SyncStatus.Syncing,
        currentTime: state.currentTime + action.payload.timeDiff,
      }
    }

    case ACTION_TYPES.ASSIGN_OFFSETS: {
      return {
        ...state,
        offsets: {
          ...state.offsets,
          ...action.payload.offsets,
        },
      }
    }

    default:
      return state
  }
}

const EditorContext = createContext<EditorState | undefined>(undefined)
const EditorUpdaterContext = createContext<Dispatch | undefined>(undefined)

export const EditorProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE)

  return (
    <EditorContext.Provider value={state}>
      <EditorUpdaterContext.Provider value={dispatch}>
        {children}
      </EditorUpdaterContext.Provider>
    </EditorContext.Provider>
  )
}

export const useEditorState = () => {
  const context = useContext(EditorContext)
  if (context === undefined) {
    throw new Error('useEditorState must be used within a EditorProvider')
  }
  return context
}

export const useEditorUpdater = () => {
  const context = useContext(EditorUpdaterContext)
  if (context === undefined) {
    throw new Error('useEditorUpdater must be used within a EditorProvider')
  }
  return context
}

useEditorUpdater.ACTION_TYPES = ACTION_TYPES
