import styled from 'styled-components/macro'

import { Container } from 'components/core/layout'

import { Dialog, DialogProps } from './dialog'
import { useCallback, useEffect } from 'react'
import { fonts } from 'theme/typography'

type ConfirmOptions = {
  title: string
  message: string
  confirmTitle?: string
  cancelTitle?: string
  onConfirm?: (value: boolean) => void
} & DialogProps

export const Confirm = ({
  title,
  message,
  confirmTitle = 'Confirm',
  cancelTitle = 'Cancel',
  onConfirm: callback = () => {},
  ...props
}: ConfirmOptions) => {
  const onCancel = useCallback(() => {
    callback(false)
  }, [callback])

  const onConfirm = useCallback(() => {
    callback(true)
  }, [callback])

  const onPressEsc = useCallback(
    (evt: KeyboardEvent) => {
      if (evt.code === 'Escape') {
        callback(false)
      }
    },
    [callback]
  )

  useEffect(() => {
    document.addEventListener('keydown', onPressEsc)

    return () => {
      document.removeEventListener('keydown', onPressEsc)
    }
  }, [onPressEsc])

  return (
    <Dialog {...props}>
      <Dialog.Title textAlign="center">{title}</Dialog.Title>
      <Dialog.Body>
        <Dialog.Text variant="text-1" textAlign="center">
          {message}
        </Dialog.Text>
      </Dialog.Body>
      <Dialog.Footer>
        <Container direction="row">
          <Button onClick={onCancel}>{cancelTitle}</Button>
          <Button onClick={onConfirm}>{confirmTitle}</Button>
        </Container>
      </Dialog.Footer>
    </Dialog>
  )
}

const Button = styled.button`
  width: 50%;
  padding: 16px;

  border: none;
  background-color: transparent;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.text.secondary};

  ${fonts.text['text-3']}

  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;

  &:first-child {
    border-right: 1px solid ${({ theme }) => theme.colors.neutrals.n6};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.neutrals.n7};
    color: ${({ theme }) => theme.colors.text.primary};
  }
`
