// There is probably a better way to do this but... i just want 00:00:00 time format
export const durationFormat = (duration: number) => {
  const hours = Math.floor(duration / 60 / 60)
    .toString()
    .padStart(2, '0')
  const minutes = Math.floor((duration / 60) % 60)
    .toString()
    .padStart(2, '0')
  const seconds = Math.floor(duration % 60)
    .toString()
    .padStart(2, '0')

  return `${hours}:${minutes}:${seconds}`
}

export const durationFormatHours = (duration: number) => {
  const hours = Math.floor(duration / 60 / 60)
    .toString()
    .padStart(2, '0')
  const minutes = Math.floor((duration / 60) % 60)
    .toString()
    .padStart(2, '0')
  const seconds = Math.floor(duration % 60)
    .toString()
    .padStart(2, '0')

  return `${hours}h ${minutes}m ${seconds}s`
}

export const durationFormatMinutes = (duration: number) => {
  const minutes = Math.floor((duration / 60) % 60)
    .toString()
    .padStart(2, '0')
  const seconds = Math.floor(duration % 60)
    .toString()
    .padStart(2, '0')

  return `${minutes}m ${seconds}s`
}
