import { Collapse } from 'components/collapse'
import { HStack, Spacing } from 'components/core/layout'
import { MomentFeedFilters } from 'core/feed/components/filters/moments-filters'
import { allKillOptions } from 'core/feed/components/filters/options'
import { ReactionFeedFilters } from 'core/feed/components/filters/reaction-filters'
import { SectionToggleTitle } from 'core/feed/components/filters/section-toggle'
import { ValorantFeedFilters } from 'core/feed/components/filters/valorant-filters'
import { FeedProvider } from 'core/feed/context'
import { useIncludeFilters } from 'page/channel/matches/hooks'
import { useMemo, useState } from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components/macro'
import { useMatchAutoCutMoments } from './hooks'
import { MatchDisplaySelection } from './match-display'
import { MatchFeed } from './match-feed'
import { MomentCollectionSidebar } from './moment-collection'

export const AutoCutMatchPage = () => {
  const { matchId } = useParams<{ matchId: string }>()
  const [selectedChannelIds, setSelectedChannelIds] = useState<string[]>([])
  const { valorant, toggleValorant, chat, toggleChat } = useIncludeFilters()
  const {
    moments,
    hasCollection,
    initializeCollection,
    toggleMoment,
    includedSignalIds,
  } = useMatchAutoCutMoments(matchId)

  const valorantFilterSettings = useMemo(
    () => ({
      minKills: { include: true, default: '2', options: allKillOptions },
      agents: { include: false },
      maps: { include: false },
    }),
    []
  )

  return (
    <FeedProvider>
      <PageContentDiv>
        <HStack gap={16}>
          <SidebarDiv>
            <SidebarContentDiv>
              <MatchDisplaySelection
                matchId={matchId}
                selectedChannelIds={selectedChannelIds}
                setSelectedChannelIds={setSelectedChannelIds}
              />
              <>
                <MomentFeedFilters />
                <Spacing vertical={8}>
                  <SectionToggleTitle
                    title="Valorant"
                    checked={valorant}
                    onClick={toggleValorant}
                  />
                </Spacing>
                <Collapse open={valorant}>
                  <ValorantFeedFilters settings={valorantFilterSettings} />
                </Collapse>
                <Spacing top={24} bottom={8}>
                  <SectionToggleTitle
                    title="Chat"
                    checked={chat}
                    onClick={toggleChat}
                  />
                </Spacing>
                <Collapse open={chat}>
                  <ReactionFeedFilters />
                </Collapse>
              </>
            </SidebarContentDiv>
          </SidebarDiv>
          <MatchFeedContainerDiv>
            <MatchFeed
              matchId={matchId}
              channelIds={selectedChannelIds}
              selectable={true}
              selectedMap={includedSignalIds}
              toggleSelected={toggleMoment}
            />
          </MatchFeedContainerDiv>
          <SelectionContainerDiv>
            <MomentsSidebarContentDiv>
              <MomentCollectionSidebar
                hasCollection={hasCollection}
                moments={moments}
                matchId={matchId}
                initializeCollection={initializeCollection}
              />
            </MomentsSidebarContentDiv>
          </SelectionContainerDiv>
        </HStack>
      </PageContentDiv>
    </FeedProvider>
  )
}

const PageContentDiv = styled.div`
  position: relative;
  padding: 16px;
  max-width: 1690px;
  margin: 0 auto;
`

const MatchFeedContainerDiv = styled.div`
  margin-left: 320px;
  width: 100%;
  max-width: 1024px;
  /* margin-right: 300px; */
`

const SidebarDiv = styled.div`
  width: 320px;
  position: fixed;
  // Height - container padding
  height: calc(100vh - 24px);
`

const SelectionContainerDiv = styled.div`
  /* right: 16px; */
  width: 300px;
  // 16 + 88
  top: 104px;
  /* position: fixed; */
  position: sticky;
  // Height - container padding
  height: calc(100vh - 24px);
`

export const SidebarContentDiv = styled.div`
  box-shadow: ${({ theme }) => theme.elevation.depth3};
  border-radius: 16px;
  padding: 8px 32px 32px 32px;
  background-color: ${({ theme }) => theme.colors.background.primary};
`

export const MomentsSidebarContentDiv = styled.div`
  width: 300px;
  box-shadow: ${({ theme }) => theme.elevation.depth3};
  border-radius: 16px;
  padding: 8px 24px 24px 24px;
  background-color: ${({ theme }) => theme.colors.background.primary};
`
