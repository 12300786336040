import { up } from 'styled-breakpoints'
import styled from 'styled-components/macro'

export const PostBuyPageDiv = styled.div`
  padding: 16px 16px 120px;
  overflow: hidden;
  ${up('md')} {
    position: relative;
    min-width: 1136px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding: 16px 0 120px 0;
    max-width: 1920px;
    margin: 20px auto;
    overflow: visible;
  }
`

export const ActivationHeadingDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 50px 0 30px 0;
  gap: 24px;

  ${up('md')} {
    gap: 32px;
  }

  & > img {
    filter: grayscale(100%) invert(100%) brightness(10);
  }
`

export const MomentPreviewHorizontalDiv = styled.div`
  ${up('md')} {
    position: relative;
    width: 272px;
    height: 153px;
    border-radius: 4px;
    overflow: hidden;
    filter: grayscale(40%);
    transform: scale() translateZ(0);
    transition: all 200ms ease-in-out;
    cursor: pointer;

    &:hover {
      z-index: 1;
      transform: scale(2);
      box-shadow: 0 0 100px rgba(0, 0, 0, 1);
      filter: grayscale(0);
    }

    &:first-child,
    &:nth-child(5n) {
      transform-origin: 15% 50%;
    }
    &:nth-child(4n) {
      transform-origin: 85% 50%;
    }
  }
`

export const HorizontalCollectionContainerDiv = styled.div`
  position: relative;
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(1, 100%);
  ${up('md')} {
    grid-template-columns: repeat(auto-fit, 272px);
  }
`

export const VerticalCollectionContainerDiv = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  ${up('md')} {
    grid-template-columns: repeat(auto-fit, 180px);
    gap: 11px;
  }
`

export const MomentPreviewVerticalDiv = styled.div`
  position: relative;
  border-radius: 4px;
  width: 100%;

  ${up('md')} {
    width: 180px;
    height: 320px;
    filter: grayscale(40%);
    transform: scale() translateZ(0);
    transition: all 200ms ease-in-out;
    cursor: pointer;
    overflow: hidden;

    &:hover {
      z-index: 1;
      filter: grayscale(0);
      transform: scale(1.75);
      box-shadow: 0 0 100px rgba(0, 0, 0, 1);
    }

    &:first-child {
      transform-origin: 15% 50%;
    }
    &:last-child {
      transform-origin: 85% 50%;
    }
  }
`

export const StreamerCreatorListDiv = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin-bottom: 0px;
  flex-wrap: wrap;

  img {
    border: 2px solid #fff;
    border-radius: 50%;
  }
`

export const StreamerAvatarWrapperDiv = styled.div`
  img {
    border: 2px solid #fff;
    border-radius: 50%;
  }
`
export const PreviewImg = styled.img`
  width: 100%;
  height: 100%;
`

export const VideoContainerDiv = styled.div`
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
`

export const HoverActionsDiv = styled.div`
  position: absolute;
  top: 2px;
  right: 0px;
`

export const HoverActionsContentDiv = styled.div`
  display: flex;
  gap: 2px;
  z-index: 1;
  justify-content: flex-end;
  align-items: flex-start;
  transform: translateZ(0);
  border-radius: 4px;
  padding: 0px 8px;
`

export const OverlayDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.1);
`

export const ActionItemDiv = styled.div`
  position: relative;
  width: 20px;
  height: 20px;
  opacity: 0.75;
  transform: translateZ(0);
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  padding: 3px;

  svg {
    fill: white;
    width: 100%;
    height: 100%;
  }

  &:hover {
    opacity: 1;
  }
`

export const StreamerInfoDiv = styled.div<{ $isHovered: boolean }>`
  position: absolute;
  left: 4px;
  bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  background-color: rgba(0, 0, 0, 0.65);
  padding: 4px 8px;
  border-radius: 4px;

  & > img {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.5);
    background-color: rgba(0, 0, 0, 0.5);
  }

  & > span {
    font-size: 12px;
    color: #fff;
  }

  ${({ $isHovered }) =>
    $isHovered &&
    `
    transform-origin: 0 100%;
    transform: translateZ(0) scale(0.6);
    `}
`
