import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { SyncedVideo } from 'core/editor/synced-video'
import { Timeline } from 'core/editor/timeline'
import { ChatTimeline } from 'core/editor/timeline/moments/chat'
import { useOffsetEditing } from './hooks'

import {
  SyncVideoCompareDiv,
  SyncVideoControlsDiv,
  SyncVideoPageDiv,
  SyncVideoTimelineControlsDiv,
} from './styled'
import { useEditor } from 'core/editor/hooks'
import { Editor } from 'core/editor'

interface VideoSyncEditorProps {
  momentId: string
  primaryVideoId: string
  secondaryVideoId: string
}

export const VideoSyncEditor: React.FC<VideoSyncEditorProps> = ({
  momentId,
  primaryVideoId,
  secondaryVideoId,
}) => {
  const { saveOffsets, currentOffsets } = useOffsetEditing(
    momentId,
    primaryVideoId,
    secondaryVideoId
  )
  const { restartSelection, setPlayWhenReady } = useEditor()
  const [offset, setOffset] = useState(0)
  const playerOptions = useMemo(() => {
    return {
      muted: false,
    }
  }, [])

  const adjustOffset = useCallback(
    (adjustment: number) => {
      return () => {
        setOffset((currentOffset) =>
          parseFloat((currentOffset + adjustment).toFixed(2))
        )
        setPlayWhenReady(true)
        restartSelection()
      }
    },
    [restartSelection, setPlayWhenReady]
  )

  const save = useCallback(() => {
    saveOffsets(offset)
  }, [offset, saveOffsets])

  useEffect(() => {
    setOffset(currentOffsets.audioOffset)
  }, [currentOffsets, setOffset])

  return (
    <Editor>
      <SyncVideoPageDiv>
        <SyncVideoCompareDiv>
          <SyncedVideo
            id={primaryVideoId}
            primaryVideoId={primaryVideoId}
            videoId={primaryVideoId}
            controller={true}
            playerOptions={playerOptions}
          />
          <SyncedVideo
            id={secondaryVideoId}
            primaryVideoId={primaryVideoId}
            videoId={secondaryVideoId}
            playerOptions={playerOptions}
            offset={offset}
          />
        </SyncVideoCompareDiv>
        <SyncVideoControlsDiv>
          <div>
            <button onClick={adjustOffset(-0.5)}>&lt;&lt;&lt;</button>
            <button onClick={adjustOffset(-0.1)}>&lt;&lt;</button>
            <button onClick={adjustOffset(-0.05)}>&lt;</button>
            {offset}
            <button onClick={adjustOffset(0.05)}>&gt;</button>
            <button onClick={adjustOffset(0.1)}>&gt;&gt;</button>
            <button onClick={adjustOffset(0.5)}>&gt;&gt;&gt;</button>
          </div>
          <div>
            <button onClick={save}>Save</button>
          </div>
        </SyncVideoControlsDiv>
        <SyncVideoTimelineControlsDiv>
          <Timeline>
            <ChatTimeline videoId={primaryVideoId} />
            <ChatTimeline videoId={secondaryVideoId} />
          </Timeline>
        </SyncVideoTimelineControlsDiv>
      </SyncVideoPageDiv>
    </Editor>
  )
}
