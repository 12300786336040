import React, { useMemo } from 'react'
import momentjs from 'moment'

import { ChatMomentFragment, useGetEditingVideoQuery } from '__generated__'
import { useTimelineState } from '../context'
import { useGetTimeToContentsWidth, useTimelineHelpers } from '../hooks'
import {
  ChatTimelineContainerDiv,
  ChatTimelineStreamDiv,
  MomentIndicatorDiv,
} from './styled'

interface ChatTimelineProps {
  videoId: string
  overlaps?: { from: number; to: number }[]
}

export const ChatTimeline: React.FC<ChatTimelineProps> = ({
  videoId,
  overlaps,
}) => {
  const { streamStart, streamEnd, styles, moments } = useChatTimelineData(
    videoId,
    overlaps
  )

  return (
    <ChatTimelineContainerDiv>
      <ChatTimelineStreamDiv style={styles}>
        {moments.map((moment) => (
          <TimelineChatMoment
            key={moment.id}
            moment={moment}
            streamStart={streamStart}
            streamEnd={streamEnd}
          />
        ))}
      </ChatTimelineStreamDiv>
    </ChatTimelineContainerDiv>
  )
}

interface TimelineChatMomentProps {
  moment: ChatMomentFragment
  streamStart: number
  streamEnd: number
}

export const TimelineChatMoment: React.FC<TimelineChatMomentProps> = ({
  moment,
  streamStart,
  streamEnd,
}) => {
  const { headingText, score } = useMemo(() => {
    if (moment.__typename === 'ChatMoment') {
      return {
        headingText: moment.type,
        score: moment.metadata.score.toFixed(2),
      }
    }

    return { headingText: '', score: '' }
  }, [moment])

  const styles = useMemo(() => {
    const momentStart = momentjs(moment.startsAt).valueOf()
    const momentEnd = momentjs(moment.endsAt).valueOf()
    const momentLength = momentEnd - momentStart
    const streamLength = streamEnd - streamStart
    return {
      left: `${((momentStart - streamStart) / streamLength) * 100}%`,
      width: `${(momentLength / streamLength) * 100}%`,
    }
  }, [moment, streamStart, streamEnd])

  return (
    <MomentIndicatorDiv style={styles}>
      <div>{headingText}</div>
      <div>{score}</div>
    </MomentIndicatorDiv>
  )
}

const useChatTimelineData = (
  videoId: string,
  _: { from: number; to: number }[] | undefined
) => {
  const { data } = useGetEditingVideoQuery({ variables: { id: videoId } })
  const { startTime, endTime } = useTimelineState()
  const { contentWidth } = useTimelineHelpers()

  const moments = useMemo(() => {
    return (data?.video?.moments.data.filter(
      (m) => m.__typename === 'ChatMoment'
    ) ?? []) as ChatMomentFragment[]
  }, [data?.video?.moments.data])

  const { streamStart, streamEnd } = useMemo(() => {
    return {
      streamStart: momentjs(data?.video?.stream?.startedAt).valueOf(),
      streamEnd: momentjs(data?.video?.stream?.endedAt).valueOf(),
    }
  }, [data])

  const getTimeToContentsWidth = useGetTimeToContentsWidth(
    contentWidth,
    endTime - startTime
  )

  const styles = useMemo(() => {
    if (
      data?.video?.stream?.startedAt === undefined ||
      data?.video?.stream?.endedAt === undefined
    )
      return {}
    const left = getTimeToContentsWidth(streamStart - startTime)
    const width = getTimeToContentsWidth(streamEnd - streamStart)

    return { left, width }
  }, [
    data?.video?.stream?.startedAt,
    data?.video?.stream?.endedAt,
    getTimeToContentsWidth,
    streamStart,
    startTime,
    streamEnd,
  ])

  return useMemo(
    () => ({
      moments,
      styles,
      startTime,
      endTime,
      streamStart,
      streamEnd,
    }),
    [endTime, moments, startTime, streamEnd, streamStart, styles]
  )
}
