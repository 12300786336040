import { useTheme } from 'styled-components/macro'

import { Button } from 'components/core/button'
import { Card, Container } from 'components/core/layout'
import { Text } from 'components/core/text'
import { useCollectionContext } from 'core/collections/collections-context-provider'
import {
  GetOrganizationCollectionsListDocument,
  GetOrganizationCollectionsListQuery,
  useDeleteMomentCollectionMutation,
} from '__generated__'
import { useCallback, useMemo, useState } from 'react'
import { Confirm } from 'components/dialog'
import { useHistory, useRouteMatch } from 'react-router'

export const DeleteSettings = () => {
  const theme = useTheme()

  const { title, onSubmit, confirmVisible, onConfirm, loading } =
    useDeleteSettings()

  return (
    <>
      <Card borderColor={theme.colors.rose}>
        <Card.Body>
          <Card.Title>Delete collection</Card.Title>
          <Card.Text>
            <Container gap={16}>
              <Text variant="text-2">
                The collection will be permanently deleted, including the public
                link. This process is irrevesible and cannot be undone. All
                moments will still be accessible through their campaigns
                respectivly.
              </Text>
            </Container>
          </Card.Text>
        </Card.Body>

        <Card.Footer>
          <Container direction="row" justifyContent="flex-end">
            <Container direction="row" gap={16}>
              <Button
                size="small"
                color={theme.colors.rose}
                onClick={onSubmit}
                isLoading={loading}
                disabled={loading}
              >
                Delete
              </Button>
            </Container>
          </Container>
        </Card.Footer>
      </Card>
      {confirmVisible && (
        <Confirm
          backdrop
          title="Delete collection"
          message={`Are you sure you want to delete the “${title}” collection?`}
          onConfirm={onConfirm}
        />
      )}
    </>
  )
}

const useDeleteSettings = () => {
  const { selected: collection } = useCollectionContext()
  const [confirmVisible, setConfirmVisible] = useState(false)
  const [mutation, { loading }] = useDeleteMomentCollectionMutation()

  const { push } = useHistory()
  const { params } = useRouteMatch<{ organization: string }>()

  const onSubmit = useCallback(() => {
    setConfirmVisible(true)
  }, [])

  const onDelete = useCallback(() => {
    if (!collection) {
      return
    }
    const fn = async () => {
      const response = await mutation({
        variables: { id: collection.id },
        update(cache, { data }) {
          if (!data?.deleteMomentCollection) {
            return
          }
          const res = cache.readQuery<GetOrganizationCollectionsListQuery>({
            query: GetOrganizationCollectionsListDocument,
            variables: { slug: params.organization },
          })
          const collections = res?.organization?.collections.data || []
          const newCollections = collections.filter(
            (collection) => collection.id !== data.deleteMomentCollection?.id
          )

          cache.writeQuery({
            query: GetOrganizationCollectionsListDocument,
            data: {
              ...res,
              organization: {
                ...res?.organization,
                collections: {
                  ...res?.organization?.collections,
                  data: newCollections,
                },
              },
            },
            variables: { slug: params.organization },
          })
        },
      })

      if (response.data) {
        push(`/o/${params.organization}/collections`)
      }
    }

    fn()
  }, [mutation, collection, params.organization, push])

  const onConfirm = useCallback(
    (value: boolean) => {
      setConfirmVisible(false)

      if (value) {
        onDelete()
      }
    },
    [onDelete]
  )

  return useMemo(
    () => ({
      title: collection?.title,
      loading,
      onSubmit,
      confirmVisible,
      onConfirm,
    }),
    [collection, loading, onSubmit, confirmVisible, onConfirm]
  )
}
