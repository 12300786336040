import {
  ValorantAgent,
  ValorantMap,
  ValorantRoundCeremony,
} from '__generated__'
import { FilterOptions } from 'core/feed/helpers'

export const allKillOptions = [
  { label: 'Single', value: 1 },
  { label: 'Double', value: 2 },
  { label: 'Triple', value: 3 },
  { label: 'Quad', value: 4 },
  { label: 'Ace', value: 5 },
]

export const doubleMinKillOptions = [
  { label: 'Double', value: 2 },
  { label: 'Triple', value: 3 },
  { label: 'Quad', value: 4 },
  { label: 'Ace', value: 5 },
]

export const killOptions = [
  { label: 'Triple kill', value: 3 },
  { label: 'Quad kill', value: 4 },
  { label: 'Ace', value: 5 },
]

// const matchTypeOptions = Object.entries(ValorantMatchType).map(([k, v]) => ({
//   label: k,
//   value: v,
// }))

export const tagsOptions = [
  { label: 'Clutch', value: 'CLUTCH' },
  { label: 'Planted', value: 'PLANTED' },
  { label: 'Defused', value: 'DEFUSED' },
]

export const roundCeremonyOptions = Object.keys(ValorantRoundCeremony)
  .filter((map) => map !== 'Default')
  .sort()
  .reduce<FilterOptions<ValorantRoundCeremony>>(
    (acc, key) => [...acc, { label: key, value: ValorantRoundCeremony[key] }],
    []
  )

export const mapOptions = Object.keys(ValorantMap)
  .filter((map) => map !== 'Range')
  .sort()
  .reduce<FilterOptions<ValorantMap>>(
    (acc, key) => [...acc, { label: key, value: ValorantMap[key] }],
    []
  )

export const agentOptions = Object.keys(ValorantAgent)
  .filter((map) => map !== 'Unknown')
  .sort()
  .reduce<FilterOptions<ValorantAgent>>(
    (acc, key) => [...acc, { label: key, value: ValorantAgent[key] }],
    []
  )

export const reactionOptions = [
  { label: 'Funny', value: 'funny' },
  { label: 'Hype', value: 'hype' },
]
