import styled from 'styled-components/macro'
import { TwitchIcon } from 'components/core/icons'

type Props = {
  src?: string
}
const ChannelAvatar = ({ src }: Props) => {
  return (
    <ChannelAvatarContainer>
      <ChannelAvatarImg src={src} alt="" />
      <ChannelAvatarBadge>
        <TwitchIcon fill={'#6441A5'} width={11} />
      </ChannelAvatarBadge>
    </ChannelAvatarContainer>
  )
}

const ChannelAvatarImg = styled.img`
  width: 100%;
  border-radius: 64px;
  border: 1px solid ${({ theme }) => theme.colors.neutrals.n5};
`

const ChannelAvatarContainer = styled.div`
  position: relative;
  width: 128px;
  height: 128px;
  margin: -48px auto 16px;
`

const ChannelAvatarBadge = styled.div`
  display: flex;
  position: absolute;
  background-color: ${({ theme }) => theme.colors.neutrals.n8};
  border: 1px solid ${({ theme }) => theme.colors.neutrals.n5};
  width: 24px;
  height: 24px;
  border-radius: 12px;
  align-items: center;
  justify-content: center;
  top: 98px;
  left: 96px;
  overflow: hidden;
`

export { ChannelAvatar }
