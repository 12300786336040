import { useMemo } from 'react'
import { sortBy } from 'lodash'
import { Link, Route, useHistory, useParams } from 'react-router-dom'

import { Spacing } from 'components/core/layout'
import { Spinner } from 'components/core/spinner'
import { ChatSignalGridItem } from 'core/feed/multi-feed'
import { GridContainer } from 'page/feed/styles'
import { useGetChatMomentsForVideoQuery } from '__generated__'

import { QuickPreviewModal } from './preview-modal'

interface Props {
  channelId: string
  videoStart: string
  videoEnd: string
}

export const PopularMoments = ({ channelId, videoStart, videoEnd }: Props) => {
  const { videoId, channelName } = useParams<{
    signalId: string
    videoId: string
    channelName: string
  }>()
  const { location, goBack, replace } = useHistory()
  const { data, loading } = useGetChatMomentsForVideoQuery({
    variables: { channelId, videoStart, videoEnd },
  })

  const hasHistory = useMemo(() => {
    const state = location?.state as { referrer: string } | undefined
    const referrer = state?.referrer as any
    return referrer !== undefined
  }, [location?.state])

  const popularMoments = useMemo(() => {
    const moments =
      (data?.moments?.data as { id: string; metadata: { score: number } }[]) ??
      []
    return sortBy(moments, (m) => -m.metadata.score)
  }, [data])

  const currentUrl = useMemo(() => {
    return `/roster/${channelName}/videos/${videoId}`
  }, [channelName, videoId])

  return (
    <>
      <Spacing bottom={32} />
      {loading && <Spinner />}
      <GridContainer>
        {popularMoments.map((signal) => (
          <Link
            key={signal.id}
            to={{
              pathname: `${currentUrl}/moment/${signal.id}`,
              state: { referrer: 'feed' },
            }}
          >
            <ChatSignalGridItem
              key={signal.id}
              momentId={signal.id}
              hideChannel={true}
            />
          </Link>
        ))}
      </GridContainer>
      <Route path="/roster/:channelName/videos/:videoId/moment/:signalId">
        <QuickPreviewModal
          onClose={() =>
            hasHistory
              ? goBack()
              : replace(`/roster/${channelName}/videos/${videoId}`)
          }
        />
      </Route>
    </>
  )
}
