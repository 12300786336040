import { Text } from 'components/core/text'
import { useTimelineState } from 'core/editor/timeline/context'
import { useTimelineHelpers } from 'core/editor/timeline/hooks'
import { useValorantMatchRoundData } from 'core/shared/valorant/hooks'
import { useMemo } from 'react'
import { useValorantMatchEditorState } from '../../context'
import { useMatchStartRemote } from '../../hooks'
import { RoundDisplayDiv } from './styled'

export const RoundDisplay = () => {
  const { matchId, primaryVideoId } = useValorantMatchEditorState()
  const { getContentWidthFromTime } = useTimelineHelpers()
  const { startTime: timelineStartTime } = useTimelineState()
  const { roundDataWithLength } = useValorantMatchRoundData(matchId)
  const { matchStart } = useMatchStartRemote(matchId, primaryVideoId)

  const pregameTime = useMemo(() => {
    if (!matchStart) return 0
    return matchStart - timelineStartTime
  }, [matchStart, timelineStartTime])

  const roundStyles = useMemo(() => {
    return roundDataWithLength?.map((rd) => {
      return {
        left: getContentWidthFromTime(rd.roundStart + pregameTime),
        width: getContentWidthFromTime(rd.roundLength),
      }
    })
  }, [getContentWidthFromTime, pregameTime, roundDataWithLength])
  return (
    <>
      {roundDataWithLength?.map((rd, idx) => {
        return (
          <RoundDisplayDiv key={rd.roundNum} style={roundStyles?.[idx]}>
            <Text variant="text-3" mono={true}>
              ROUND {rd.roundNum + 1}
            </Text>
          </RoundDisplayDiv>
        )
      })}
    </>
  )
}
