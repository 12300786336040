import { Button } from 'components/core/button'
import { HStack } from 'components/core/layout'
import { Text } from 'components/core/text'
import { useCallback } from 'react'
import { useCreateMultiCutEdit } from './hooks'

export const AutoCut = ({
  onCreate,
  videoId,
  momentId,
  startsAt,
}: {
  onCreate?: () => void
  videoId: string
  momentId: string
  startsAt: string
}) => {
  const createMobile = useCreateMultiCutEdit(
    videoId,
    momentId,
    startsAt,
    'mobile'
  )
  const createDesktop = useCreateMultiCutEdit(
    videoId,
    momentId,
    startsAt,
    'desktop'
  )
  const createMobileNoCam = useCreateMultiCutEdit(
    videoId,
    momentId,
    startsAt,
    'mobile-no-cam'
  )

  const handleCreateMobile = useCallback(async () => {
    await createMobile()
    if (onCreate) {
      onCreate()
    }
  }, [createMobile, onCreate])
  const handleCreateDesktop = useCallback(async () => {
    await createDesktop()
    if (onCreate) {
      onCreate()
    }
  }, [createDesktop, onCreate])
  const handleCreateMobileNoCam = useCallback(async () => {
    await createMobileNoCam()
    if (onCreate) {
      onCreate()
    }
  }, [createMobileNoCam, onCreate])

  return (
    <HStack gap={12} alignItems="center">
      <Text variant="text-2">Auto-Cut: </Text>
      <Button onClick={handleCreateDesktop}>Desktop</Button>
      <Button onClick={handleCreateMobile}>Mobile</Button>
      <Button onClick={handleCreateMobileNoCam}>Mobile No Cam</Button>
    </HStack>
  )
}
