import { Button } from 'components/core/button'
import styled from 'styled-components/macro'

export const LinkingPageDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.static.neutrals.n1};
  gap: 16px;
`

export const LinkingIconButton = styled(Button).attrs({
  variant: 'primary',
  size: 'small',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`
