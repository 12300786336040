import { sortBy } from 'lodash'
import { useMemo } from 'react'

import {
  ChannelPreviewFragment,
  useGetCampaignViewerQuery,
} from '__generated__'

export interface GroupedSegment {
  channels: ChannelPreviewFragment[]
  segment: { to: string; from: string }
}

export const useCurrentCampaign = (campaignSlug: string) => {
  const { data, loading } = useGetCampaignViewerQuery({
    variables: { slug: campaignSlug },
  })
  return useMemo(() => ({ data, loading }), [data, loading])
}

export const useCampaignData = (campaignSlug: string) => {
  const { data } = useCurrentCampaign(campaignSlug)

  const groupedSegments = useMemo(() => {
    const segments = data?.campaign?.channelCampaignSegments
    const groupingsByStart: { [startTime: string]: GroupedSegment } = {}
    for (const channelSegments of segments ?? []) {
      for (const seg of channelSegments.segments) {
        if (groupingsByStart[seg.from] === undefined) {
          groupingsByStart[seg.from] = { segment: seg, channels: [] }
        }
        groupingsByStart[seg.from].channels.push(channelSegments.channel)
      }
    }
    return sortBy(Object.values(groupingsByStart), ({ segment }) =>
      new Date(segment.to).getTime()
    )
  }, [data?.campaign?.channelCampaignSegments])

  return useMemo(
    () => ({ groupedSegments, title: data?.campaign?.title }),
    [data?.campaign?.title, groupedSegments]
  )
}
