import { NavLink } from 'react-router-dom'
import styled from 'styled-components/macro'
import { fontWeights } from 'theme/typography'

export const Header = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.background.tertiary};
`

export const SidebarWrapper = styled.div`
  width: 220px;
`

export const StyledNavLink = styled(NavLink)`
  display: block;
  padding: 8px 0;

  color: ${({ theme }) => theme.colors.neutrals.n4};
  text-decoration: none;

  transition: color 0.2s ease-in-out;

  &.active {
    font-weight: ${fontWeights['semibold']};
    color: ${({ theme }) => theme.colors.text.primary};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.text.primary};
  }
`

export const PageItemDiv = styled.div<{ $selected: boolean }>`
  border: 1px solid ${({ theme }) => theme.colors.neutrals.n6};
  border-radius: 8px;
  padding: 4px 12px;
  color: ${({ theme, $selected }) =>
    $selected ? theme.colors.neutrals.n2 : theme.colors.neutrals.n4};
  background-color: ${({ theme, $selected }) =>
    $selected ? theme.colors.neutrals.n6 : theme.colors.neutrals.n8};
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.colors.neutrals.n4};
    border-color: ${({ theme, $selected }) =>
      $selected ? theme.colors.neutrals.n8 : theme.colors.neutrals.n4};
  }
  cursor: pointer;
`
