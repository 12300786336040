import { useCallback, useMemo } from 'react'

import { ValorantMatchPlayerPreviewFragment } from '__generated__'
import {
  AgentThumbImg,
  NameDiv,
  TeamIndicatorDiv,
  ValorantHeadingDiv,
} from 'core/shared/timeline/valorant/heading/styled'
import { Text } from 'components/core/text'
import { AGENT_THUMB_IMAGES } from 'components/valorant/agent'

import { useValorantMatchPreviewData } from '../..'
import { useValorantMatchEditorState } from '../../context'
import { useValorantMatchEditorHelpers } from '../../helpers'

interface PlayerProps {
  playerData: ValorantMatchPlayerPreviewFragment
  winningTeam: boolean
}

export const ValorantMatchPlayerHeading = ({
  playerData,
  winningTeam,
}: PlayerProps) => {
  const { selectedVideoInfo, matchId } = useValorantMatchEditorState()
  const { videoData } = useValorantMatchPreviewData(matchId)
  const { setSelectedVideoInfo } = useValorantMatchEditorHelpers()

  const isSelected = useMemo(
    () => playerData.account.id === selectedVideoInfo?.accountId,
    [playerData.account.id, selectedVideoInfo?.accountId]
  )

  const streamInfo = useMemo(() => {
    return videoData?.find(
      ({ accountId }) => accountId === playerData.account.id
    )
  }, [playerData.account.id, videoData])

  const hasPerspective = useMemo(() => {
    return streamInfo !== undefined
  }, [streamInfo])

  const setSelected = useCallback(() => {
    if (!hasPerspective) return () => {}
    const valorantVideoInfo = videoData?.find(
      ({ accountId }) => accountId === playerData.account.id
    )
    if (valorantVideoInfo) {
      setSelectedVideoInfo(valorantVideoInfo)
    }
  }, [hasPerspective, playerData.account.id, setSelectedVideoInfo, videoData])

  return (
    <ValorantHeadingDiv
      $selected={isSelected}
      onClick={setSelected}
      $availablePerspective={hasPerspective}
    >
      <TeamIndicatorDiv $winner={winningTeam} />
      <AgentThumbImg
        $selected={isSelected}
        alt="agent thumbnail"
        src={AGENT_THUMB_IMAGES[playerData.agent ?? ''] ?? ''}
      />
      <NameDiv>
        <Text variant="text-3">{streamInfo?.displayName} </Text>
      </NameDiv>
    </ValorantHeadingDiv>
  )
}
