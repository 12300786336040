import React, { useMemo } from 'react'

import {
  TimeRangeFilterInput,
  useGetOrganzationCampaignDetailsQuery,
} from '__generated__'

import { useVideoMomentsData } from '../hooks'
import { ChatMomentFilterDiv } from '../styled'

interface CampaignOption {
  campaign: {
    id: string
    title: string
  }
  timeframe: {
    from: string
    to: string
  }
}

interface Props {
  videoId: string
  setSelectedCampaignId: (campaignId: string | undefined) => void
  selectedCampaignId: string | undefined
  timeframe: TimeRangeFilterInput | undefined
  setTimeframe: (timerange: { to: string; from: string } | undefined) => void
}

export const ChatMomentsFilter = ({
  videoId,
  selectedCampaignId,
  setSelectedCampaignId,
  setTimeframe,
}: Props) => {
  const { streamEnd, streamStart, channelId } = useVideoMomentsData(videoId)
  const { data } = useGetOrganzationCampaignDetailsQuery({
    variables: { slug: 'loaded' },
  })

  const campaignSegments = useMemo(() => {
    let results: CampaignOption[] = []

    for (const campaign of data?.organization?.campaigns ?? []) {
      for (const { channel, segments } of campaign.channelCampaignSegments) {
        if (channel.id === channelId) {
          const matchingTimeSegments = segments.filter((seg) => {
            return seg.from > streamStart && seg.to < streamEnd
          })
          const moreResults = matchingTimeSegments.map((timeSeg) => ({
            campaign: {
              id: campaign.id,
              title: campaign.title,
            },
            timeframe: {
              from: timeSeg.from,
              to: timeSeg.to,
            },
          }))

          results = [...results, ...moreResults]
        }
      }
    }

    return results
  }, [channelId, data?.organization?.campaigns, streamEnd, streamStart])

  const setCampaignSegment = (evt: React.ChangeEvent<HTMLSelectElement>) => {
    const nextId = evt.currentTarget.value
    if (nextId === '') {
      setSelectedCampaignId(undefined)
      setTimeframe(undefined)
    } else {
      setSelectedCampaignId(nextId)
      const seg = campaignSegments.find(({ campaign: { id } }) => {
        return nextId === id
      })
      setTimeframe(seg?.timeframe)
    }
  }

  return (
    <ChatMomentFilterDiv>
      {campaignSegments.length > 0 ? (
        <select value={selectedCampaignId} onChange={setCampaignSegment}>
          <option key="">Entire Video</option>
          {campaignSegments.map(({ campaign: { id, title } }) => {
            return (
              <option key={id} value={id}>
                {title}
              </option>
            )
          })}
        </select>
      ) : null}
    </ChatMomentFilterDiv>
  )
}
