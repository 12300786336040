import styled from 'styled-components/macro'
import React, { ChangeEvent, useCallback } from 'react'

import { Portal } from 'components/dialog/portal'
import { Backdrop, PreviewModalRoot } from '../preview-modal/styled'
import { Text } from 'components/core/text'
import { VStack } from 'components/core/layout'
import { TextField } from 'components/core/textfield'
import { useSearch } from 'page/admin/channels/hooks'
import { ReactComponent as LinkIcon } from 'icons/svgs/solid/link.svg'
import { useLinkRiotAccountMutation } from '__generated__'
import { CloseButton } from 'core/feed/components/preview-modal/styled'
import { CloseIcon } from 'core/feed/components/preview-modal/close-icon'

// ideally we just pass in riotAccountId, then query for the content
// we need, so that so much doesn't need to passed in, but we
// don't have an api for riotAccount yet
interface Props {
  accountId: string
  gameName: string
  tagLine?: string
  close: () => void
}

export const ConnectRiotAccount = ({
  accountId,
  gameName,
  tagLine,
  close,
}: Props) => {
  const { search, setSearch, results } = useSearch()
  const [mutation] = useLinkRiotAccountMutation()

  const linkAccount = useCallback(
    (twitchId: string) => {
      mutation({
        variables: {
          channelId: twitchId,
          riotId: accountId,
        },
      })
      close()
    },
    [accountId, close, mutation]
  )

  return (
    <Portal>
      <PreviewModalRoot>
        <Backdrop $opacity={0.8} />
        <ContainerDiv>
          <ContentsDiv>
            <VStack justifyContent="flex-start" gap={12}>
              <Text as="div" variant="text-3" weight="semibold">
                CONNECT RIOT ACCOUNT
              </Text>
              <Text as="div" variant="text-3" weight="bold">
                {gameName}#{tagLine}
              </Text>
              <CloseButton onClick={close}>
                <CloseIcon />
              </CloseButton>

              <TextField
                placeholder="Twitch Username"
                onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                  setSearch(evt.target.value)
                }
                value={search}
              />
              <div>
                {results.map(({ id, name }) => (
                  <OptionLineDiv onClick={() => linkAccount(id)}>
                    <Text as="div" variant="text-3">
                      {name}
                    </Text>
                    <LinkIcon width={12} height={12} fill="white" />
                  </OptionLineDiv>
                ))}
              </div>
            </VStack>
          </ContentsDiv>
        </ContainerDiv>
      </PreviewModalRoot>
    </Portal>
  )
}

const ContainerDiv = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`

const ContentsDiv = styled.div`
  position: relative;
  padding: 12px;
  width: 300px;
  height: 370px;
  background-color: ${({ theme }) => theme.colors.neutrals.n6};
  border-radius: 12px;
`

const OptionLineDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 8px;
  gap: 8px;
  margin-bottom: 4px;
  color: ${({ theme }) => theme.colors.neutrals.n3};
  cursor: pointer;

  & > svg {
    opacity: 0;
    transition: opacity 150ms ease-in-out;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.neutrals.n1};
    & > svg {
      opacity: 1;
    }
  }
`
