import React from 'react'

import { EditorProvider } from 'core/editor/context'
import { TimelineProvider } from 'core/editor/timeline/context'

import { useVideoSyncState } from './context'
import { VideoSyncController } from './controller'

export const SyncModal: React.FC = () => {
  const { momentId } = useVideoSyncState()

  return (
    <EditorProvider>
      <TimelineProvider>
        {momentId ? <VideoSyncController momentId={momentId} /> : null}
      </TimelineProvider>
    </EditorProvider>
  )
}
