import styled from 'styled-components/macro'

export const PlayerDataDisplayDiv = styled.div`
  padding: 21px 0;
`

export const TimelineMatchContentsDiv = styled.div`
  position: relative;
  margin: 2px 0;
  height: 32px;
  display: flex;
  vertical-align: top;
  border-bottom: 1px solid #353945;
`

export const MainPerspectiveSpacerDiv = styled.div`
  margin: 8px 0;
  height: 1px;
  width: 100%;
  background-color: #353945;
`

export const TimelineMomentSelectionDiv = styled.div`
  position: absolute;
  height: 32px;
  background: #353945;
  border-radius: 4px 4px 0px 0px;
`

export const TimelineRoundContainerDiv = styled.div`
  position: relative;
  height: 32px;
  flex-grow: 0;
  flex-shrink: 0;
`

export const KillIndicatorDiv = styled.div`
  position: absolute;
  bottom: 14px;
  height: 12px;
  width: 12px;
  transform: translateX(-50%);
  /* background-color: #333; */

  & > svg {
    width: 100%;
    height: 100%;
  }
`

export const RoundDisplayDiv = styled.div`
  position: absolute;
  border-left: 1px solid #353945;
  height: 100%;
  padding: 2px 4px;
  color: #353945;

  & > span {
    font-size: 10px;
  }
`

export const PregamePaddingDiv = styled.div`
  position: relative;
  height: 32px;
  flex-grow: 0;
  flex-shrink: 0;
`
