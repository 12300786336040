import { STANDARD_FORMAT } from 'core/exporter/constants'
import styled from 'styled-components/macro'
import { colors } from 'utils/colors'

export const MomentEditorPage = styled.div`
  position: relative;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background.primary};
`

export const MomentHomePageDiv = styled.div`
  background-color: ${({ theme }) => theme.colors.background.primary};
`

export const ExportPreviewContainerDiv = styled.div`
  position: relative;
  display: grid;
  height: calc(100vh - 48px);
  margin: 24px;
  grid-template-columns: 1fr;
  grid-template-rows: 40px 1fr 400px 100px;
  grid-template-areas:
    'template-select'
    'template-preview'
    'timeline-controls'
    'scene-display';
`

export const TemplateSelectDiv = styled.div`
  position: relative;
  width: 100%;
  grid-area: template-select;

  select {
    height: 32px;
    margin-right: 8px;
  }
`

export const TemplatePreviewDiv = styled.div<{ $formatId: string }>`
  position: relative;
  grid-area: template-preview;
  display: grid;
  overflow: hidden;
  grid-template-columns: ${(p) =>
    p.$formatId === STANDARD_FORMAT.id ? '16fr 1fr 16fr' : '28.44fr 1fr 9fr'};
  // grid-template-columns: ;
  height: 100%;
`

export const TemplateSelectedContainerDiv = styled.div`
  position: relative;
  display: grid;
`

export const TemplatePreviewContainerDiv = styled.div`
  position: relative;
  display: grid;
`

export const TimelineControlsDiv = styled.div`
  position: relative;
  grid-area: timeline-controls;
  display: flex;
`

export const SceneDisplayDiv = styled.div`
  position: relative;
  grid-area: scene-display;
  background-color: ${colors.blue.light};
`

export const MomentEditPreviewContainerDiv = styled.div`
  max-width: 200px;
  margin: 4px;
  padding: 4px;
  /* border: 1px solid ${colors.neutral.light}; */
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-items: flex-start;

  & > div {
  }
`

export const ExportContainerDiv = styled.div``

export const ExportContentDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const ShowAllExportsButtonDiv = styled.div`
  width: 200px;
`

export const MomentEditTitleDiv = styled.div`
  flex: 0 0 200px;
  width: 200px;
  align-self: flex-start;
`

export const SceneInfoDiv = styled.div`
  margin: 4px 0 8px;
`

export const CreateNewEditDiv = styled.div`
  position: relative;
`

export const FormatSelectorPopoverDiv = styled.div`
  position: absolute;
  padding: 16px;
  top: calc(100% + 8px);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: ${colors.neutral.lightest};
  border: 1px solid ${colors.neutral.base};
`

export const FormatSelectorCloseDiv = styled.div`
  position: absolute;
  width: 32px;
  height: 32px;
  right: -16px;
  top: -16px;
  border-radius: 50%;
  background-color: ${colors.neutral.lightest};
  cursor: pointer;

  & > svg {
    fill: ${colors.neutral.base};
    width: 100%;
    height: 100%;
  }
`

export const GridPlaceholder = styled.div`
  display: grid;
`

export const ExportPreviewDiv = styled.div`
  width: 200px;
  height: 200px;
  margin-right: 12px;

  img {
    width: 100%;
    height: 100%;
  }
`

export const ActionBarDiv = styled.div`
  position: absolute;
  bottom: 4px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  svg {
    fill: white;
    width: 24px;
    height: 24px;
    margin-right: 8px;
    cursor: pointer;
  }
`

export const PreviewVideoDiv = styled.div`
  position: absolute;
  left: 100%;
  top: 0;
  width: 500px;
  height: 500px;
  border: 1px solid #333;
  background-color: #000;
  z-index: 2;
`

export const ClosePreviewDiv = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  width: 24px;
  height: 24px;
  cursor: pointer;

  & > svg {
    fill: white;
    width: 100%;
    height: 100%;
  }
`

export const PreviewVideoContentDiv = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  & > video {
    width: 100%;
    height: 100%;
  }
`
