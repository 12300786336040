import { Button } from 'components/core/button'
import { HStack, VStack } from 'components/core/layout'
import { Spinner } from 'components/core/spinner'
import { Text } from 'components/core/text'
import { useExportEdit } from 'page/moment/exporter/hooks'
import { Link } from 'react-router-dom'
import { MomentEditFragment, RecordingStatus } from '__generated__'
import { VideoPreviewContainerDiv } from '../styled'

interface Props {
  momentId: string
  momentEdit: MomentEditFragment
}
export const VideoPreview = ({ momentId, momentEdit }: Props) => {
  const recentExport = momentEdit.exports[0]
  // is it loading?
  const downloadUrl = recentExport?.output?.videos?.[0]?.url
  const previewUrl = recentExport?.output?.thumbnails?.[0]?.url
  const exportEdit = useExportEdit(momentId, momentEdit.id)

  return recentExport ? (
    <VideoPreviewContainerDiv>
      {recentExport.status === RecordingStatus.Created ||
      recentExport.status === RecordingStatus.Inprogress ? (
        <HStack alignItems="center" justifyContent="center" fullHeight={true}>
          <Spinner size={48} color="white" />
        </HStack>
      ) : (
        <video src={downloadUrl} poster={previewUrl} controls />
      )}
    </VideoPreviewContainerDiv>
  ) : (
    <VStack alignItems="center" justifyContent="center" gap={32}>
      <Text variant="h5">There are no video exports from this edit yet.</Text>
      <HStack gap={8}>
        <Link
          to={{
            pathname: `/m/${momentId}/editor/${momentEdit.id}`,
            state: { referrer: 'exports' },
          }}
        >
          <Button variant="primary">Open in Editor</Button>
        </Link>
        <Button variant="primary" onClick={exportEdit}>
          Export This Edit
        </Button>
      </HStack>
    </VStack>
  )
}
