import { Column } from 'react-table'
import { useMemo } from 'react'

import {
  ChannelCollectionRowFragment,
  useGetAllChannelCollectionsQuery,
} from '__generated__'
import { Table } from 'components/core/table'

export const AdminChannelCollectionsPage = () => {
  const { data } = useGetAllChannelCollectionsQuery({
    variables: { first: 100 },
  })

  const columns: Column<ChannelCollectionRowFragment>[] = useMemo(
    () => [
      // {
      //   accessor: 'id',
      //   Header: 'id',
      // },
      {
        accessor: 'title',
        Header: 'title',
        width: 300,
        Cell: ({ row, value }) => {
          return (
            <a href={`/admin/channel-collections/${row.original.slug}`}>
              {value}
            </a>
          )
        },
      },
      {
        align: 'right',
        accessor: 'slug',
        Header: 'Slug',
        Cell: ({ row, value }) => {
          return <a href={`/c/${row.original.id}`}>{value}</a>
        },
      },
    ],
    []
  )

  return <Table data={data?.channelCollections?.data ?? []} columns={columns} />
}
