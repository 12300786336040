// import { useEffect, useMemo } from 'react'

import { FilterGroup } from 'core/feed/components/filter-group'
import { useFilterForm } from 'core/feed/helpers'

import { RatingOptions } from './options'

export const ContentFilters = () => {
  // const { setFilters } = useFeedHelpers()

  const { data: ratingData, bind: ratingBind } = useFilterForm(
    RatingOptions,
    false
  )

  // const momentFilters = useMemo(() => {
  //   return {
  //     rating: ratingSelected[0],
  //   }
  // }, [ratingSelected])

  // this keeps the context up to date with filters which requeries when it sees
  // changes
  // useEffect(() => {
  //   setFilters(momentFilters)
  // }, [momentFilters, setFilters])

  return (
    <>
      <FilterGroup title="Min Rating" data={ratingData} {...ratingBind} />
    </>
  )
}
