import { isEqual } from 'lodash'
import { useEffect, useMemo, useRef } from 'react'

import { usePrevious } from 'utils'
import { useQueryParamFilters } from 'utils/browser'
import {
  useFeedValorantMomentsLazyQuery,
  useGetReactionFeedLazyQuery,
} from '__generated__'

import { FeedType, useFeedState } from './context'

export const useFeed = (additionalFilters?: {
  [key: string]: string | { [key: string]: string }
}) => {
  const filters = useValorantFeedParamsToVariables()
  const { channelIds, type } = useFeedState()
  const prevChannelIds = usePrevious(channelIds)
  // const prevFilters = usePrevious(filters)
  const [valorantFetch, { data: valorantData, loading: valorantLoading }] =
    useFeedValorantMomentsLazyQuery()
  const [reactionFetch, { data: reactionData, loading: reactionLoading }] =
    useGetReactionFeedLazyQuery()
  const prevQueryVariables = useRef<any>()

  // requery based on filters/channels
  useEffect(() => {
    if (channelIds && filters) {
      const queryVariables = {
        variables: {
          channelIds: channelIds,
          ...filters,
          ...additionalFilters,
          first: 14,
        },
      }

      if (
        type === FeedType.ValorantSignal &&
        !isEqual(queryVariables, prevQueryVariables.current)
      ) {
        prevQueryVariables.current = queryVariables
        valorantFetch(queryVariables)
      }
    }
  }, [
    filters,
    channelIds,
    type,
    valorantFetch,
    reactionFetch,
    prevChannelIds,
    additionalFilters,
  ])

  return useMemo(
    () => ({
      data: type === FeedType.ValorantSignal ? valorantData : reactionData,
      loading:
        type === FeedType.ValorantSignal ? valorantLoading : reactionLoading,
    }),
    [reactionData, reactionLoading, type, valorantData, valorantLoading]
  )
}

const useValorantFeedParamsToVariables = () => {
  const { params } = useQueryParamFilters()

  return useMemo(() => {
    const results = {}

    const saved = params['saved']
    if (saved) {
      results['isUsed'] = saved === 'true'
    }

    const tags = params['tags']
    if (tags) {
      results['tags'] = tags
    }

    const minKillsStr = params['minKills']
    if (minKillsStr && typeof minKillsStr === 'string') {
      results['minKills'] = parseInt(minKillsStr, 10)
    } else {
      results['minKills'] = 4
    }

    const agents = params['agents']
    if (agents) {
      results['agents'] = agents
    }

    const maps = params['maps']
    if (maps) {
      results['maps'] = maps
    }

    const page = params['page']
    if (page && typeof page === 'string') {
      results['after'] = (parseInt(page, 10) * 14 - 1).toString()
    } else {
      results['after'] = undefined
    }

    return results
  }, [params])
}
