import { useAuth0 } from '@auth0/auth0-react'
import { UserPayload } from 'core/auth/protected-route'
import jwtDecode from 'jwt-decode'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import {
  useGetCurrentOrganizationLazyQuery,
  useGetOrganizationBySlugQuery,
} from '__generated__'

export const useAuthorizedOrganization = () => {
  const [loading, setLoading] = useState(true)
  const [authorized, setAuthorized] = useState<boolean | null>(null)
  const {
    isAuthenticated: auth0Authenticated,
    isLoading: auth0Loading,
    getAccessTokenSilently,
  } = useAuth0()
  const [currentUser, setCurrentUser] = useState<UserPayload | null>(null)
  const [fetch, { data, loading: queryLoading, called }] =
    useGetCurrentOrganizationLazyQuery()
  const { organization } = useParams<{ organization: string }>()

  useEffect(() => {
    if (auth0Loading) {
      return
    }

    if (!auth0Authenticated) {
      setLoading(false)
      setAuthorized(false)
      return
    }

    const fn = async () => {
      await fetch()
    }

    if (!queryLoading && !called) {
      fn()
    }
  }, [
    auth0Loading,
    auth0Authenticated,
    setLoading,
    setAuthorized,
    queryLoading,
    fetch,
    called,
  ])

  useEffect(() => {
    const fn = async () => {
      const token = await getAccessTokenSilently()
      const payload = jwtDecode<UserPayload>(token)
      setCurrentUser(payload)
    }
    fn()
  }, [getAccessTokenSilently])

  useEffect(() => {
    if (!data || !currentUser) {
      return
    }

    if (currentUser.permissions?.includes('root')) {
      setAuthorized(true)
    } else {
      const slug = data.viewer?.organization?.slug
      setAuthorized(slug === organization)
    }

    setLoading(false)
  }, [data, organization, currentUser])

  return {
    loading,
    authorized,
  }
}

export const useOrganization = (slug: string) => {
  const { data, loading } = useGetOrganizationBySlugQuery({
    variables: { slug },
  })
  return { organization: data?.organization, loading }
}
