import { useTheme } from 'styled-components/macro'

export const ListSkeleton = () => {
  const theme = useTheme()
  return (
    <svg
      width="232"
      height="153"
      viewBox="0 0 232 153"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="232" height="43" rx="8" fill={theme.colors.neutrals.n6} />
      <rect
        x="16"
        y="16"
        width="125"
        height="11"
        rx="3"
        fill={theme.colors.neutrals.n7}
      />
      <rect
        x="16"
        y="67"
        width="108"
        height="11"
        rx="3"
        fill={theme.colors.neutrals.n6}
      />
      <rect
        x="16"
        y="118"
        width="116"
        height="11"
        rx="3"
        fill={theme.colors.neutrals.n6}
      />
    </svg>
  )
}
