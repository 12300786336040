import { Editor } from 'core/editor'
import { useEditor } from 'core/editor/hooks'
import { useTimelineState } from 'core/editor/timeline/context'
import { useTimelineHelpers } from 'core/editor/timeline/hooks'
import { useCallback, useEffect, useMemo, useState } from 'react'
import momentjs from 'moment'
import {
  MultiPerspectiveMomentDetailFragment,
  useGetMomentDetailQuery,
  useGetMomentEditLazyQuery,
} from '__generated__'
import { TemplatePreviewContent } from './preview'
import {
  EditorPageContainerDiv,
  MomentEditorPage,
  PreviewContainerDiv,
  SceneEditorDiv,
  TemplatePreviewContainerDiv,
  TemplatePreviewContentsDiv,
  TimelineContainerDiv,
} from './styled'
import { EditorTimeline } from './timeline'
import { useEditorState } from 'core/editor/context'
import { TemplateSidebar } from './template-sidebar'
import { SceneEditingModes, useComposerState } from './context'
import { ContentModal } from './template/content-modal'
import { useComposerController, useComposerHelper } from './hooks'
import { usePrevious } from 'utils'
import { useDimensions } from 'utils/browser'

interface Props {
  momentId: string
  editId: string
}

export const SceneEditor = ({ momentId, editId }: Props) => {
  const { selectedTemplate, selectedFormat, primaryVideoId } =
    useComposerState()
  const { close, previewContainerRef } = useSceneEditor()
  const { videosData } = useGetMomentEditorData(momentId, editId)
  useComposerController()

  const videoIds = useMemo(
    () => videosData.map(({ videoId }) => videoId),
    [videosData]
  )

  return (
    <EditorPageContainerDiv>
      <SceneEditorDiv>
        <Editor>
          <MomentEditorPage>
            <PreviewContainerDiv>
              <TemplateSidebar />
              {selectedFormat && (
                <TemplatePreviewContainerDiv>
                  <TemplatePreviewContentsDiv ref={previewContainerRef}>
                    {selectedTemplate && <TemplatePreviewContent />}
                  </TemplatePreviewContentsDiv>
                </TemplatePreviewContainerDiv>
              )}
              <TimelineContainerDiv>
                <EditorTimeline
                  primaryVideoId={primaryVideoId}
                  videoIds={videoIds}
                />
              </TimelineContainerDiv>
            </PreviewContainerDiv>
          </MomentEditorPage>
          <ContentModal close={close} />
        </Editor>
      </SceneEditorDiv>
    </EditorPageContainerDiv>
  )
}

const useSceneEditor = () => {
  const [previewContainerRef, previewContainerDimensions] = useDimensions()
  const { setSceneEditingMode, setContainerPreviewRect } = useComposerHelper()
  const close = useCallback(() => {
    setSceneEditingMode(SceneEditingModes.Locked)
  }, [setSceneEditingMode])

  useEffect(() => {
    setContainerPreviewRect(previewContainerDimensions)
  }, [previewContainerDimensions, setContainerPreviewRect])

  return useMemo(
    () => ({
      previewContainerRef,
      close,
    }),
    [close, previewContainerRef]
  )
}

const useGetMomentEditorData = (momentId: string, editId: string) => {
  const { data } = useGetMomentDetailQuery({ variables: { momentId } })
  const [query, { data: editData, called: editCalled }] =
    useGetMomentEditLazyQuery()
  const { setOffsets } = useEditor()
  const { primaryVideoId } = useComposerState()
  const { selection } = useEditorState()
  const { setTimelineLength, setTimelineStartAndEnd, jumpToNewSelection } =
    useTimelineHelpers()
  const [timelineInitialized, setTimelineInitialized] = useState(false)
  const {
    timelineLength,
    startTime: timelineStart,
    endTime: timelineEnd,
  } = useTimelineState()

  useEffect(() => {
    if (editId && !editCalled) {
      query({ variables: { editId } })
    }
  }, [editCalled, editId, query])

  const moment = useMemo(() => {
    return data?.moment as MultiPerspectiveMomentDetailFragment | undefined
  }, [data?.moment])
  const startTime = momentjs(moment?.startsAt).valueOf()
  const endTime = momentjs(moment?.endsAt).valueOf()

  // a way for us to track when timeline is ready for actions
  const targetTimelineLength = endTime - startTime + 20 * 1000
  const paddedStart = startTime - 120 * 1000
  const paddedEnd = endTime + 120 * 1000
  const timelineReady =
    timelineStart === paddedStart && timelineEnd === paddedEnd
  const prevTimelineReady = usePrevious(timelineReady)

  const videosData = useMemo(() => {
    return (
      moment?.videos.map(({ id, user }) => ({
        videoId: id,
        userId: user?.id ?? '',
        displayName: user?.displayName ?? '',
      })) ?? []
    )
  }, [moment?.videos])

  useEffect(() => {
    const nextOffsets = (moment?.offsets ?? []).reduce((memo, offsetItem) => {
      const fromMap = memo[offsetItem.fromVideo.id]
      let nextFromMap = fromMap ? fromMap : {}
      nextFromMap[offsetItem.toVideo.id] = offsetItem.offsets.audioOffset
      return {
        ...memo,
        [offsetItem.fromVideo.id]: { ...nextFromMap },
      }
    }, {})
    setOffsets(nextOffsets)
  }, [moment?.offsets, setOffsets])

  // initialize size of timeline once data has been set
  useEffect(() => {
    if (timelineLength !== targetTimelineLength) {
      setTimelineStartAndEnd(paddedStart, paddedEnd)
      setTimelineLength(targetTimelineLength)
    }
  }, [
    startTime,
    endTime,
    setTimelineLength,
    timelineLength,
    setTimelineStartAndEnd,
    targetTimelineLength,
    paddedStart,
    paddedEnd,
  ])

  // race condition, this needs timeline length & start/end
  useEffect(() => {
    if (timelineReady && editData && !timelineInitialized) {
      setTimelineInitialized(true)
      if (editData?.momentEdit?.scenes[0]) {
        const scene = editData?.momentEdit.scenes[0]
        jumpToNewSelection(
          momentjs(scene.start).valueOf(),
          momentjs(scene.end).valueOf()
        )
      } else {
        jumpToNewSelection(startTime, endTime)
      }
    }
  }, [
    editData,
    endTime,
    jumpToNewSelection,
    prevTimelineReady,
    selection,
    startTime,
    timelineInitialized,
    timelineLength,
    timelineReady,
  ])

  return useMemo(
    () => ({
      videosData,
      primaryVideoId,
    }),
    [primaryVideoId, videosData]
  )
}
