import { ReactNode, useEffect } from 'react'

import { useTimelineUpdater } from 'core/editor/timeline/context'
import { useTimelineDetailData } from 'core/editor/timeline/hooks'

import {
  TimelineContainerDiv,
  TimelineContentDiv,
  TimelineMainContentDiv,
} from './styled'
import { CurrentTimeIndicator } from './current-time'
import { TimelineSelection } from './selection'
// import { ValorantTimelineTimeDisplay } from '../time-display'
import { useDragTimeline } from '../hooks'
import { TimelineTimeDisplay } from './time-display'

interface Props {
  timeDisplayNode?: ReactNode
  children: ReactNode
}

export const TimelineDetail = ({ children, timeDisplayNode }: Props) => {
  // const { ref: containerRef } = useTimeline()
  const { containerRef, containerStyles, containerRect, seekToPageX } =
    useTimelineDetailData()
  const { contentRef } = useDragTimeline(seekToPageX)
  useTimeline(containerRect)

  return (
    <TimelineContainerDiv ref={containerRef}>
      <TimelineContentDiv style={containerStyles}>
        {/* default should go here */}
        {timeDisplayNode ? timeDisplayNode : <TimelineTimeDisplay />}
        <TimelineMainContentDiv ref={contentRef}>
          {children}
        </TimelineMainContentDiv>
        <CurrentTimeIndicator />
        <TimelineSelection containerRect={containerRect} />
      </TimelineContentDiv>
    </TimelineContainerDiv>
  )
}

const useTimeline = (containerRect: DOMRect | undefined) => {
  const dispatch = useTimelineUpdater()

  useEffect(() => {
    dispatch({
      type: useTimelineUpdater.ACTION_TYPES.SET_TIMELINE_LENGTH,
      payload: {
        // timelineLength: 3 * 60 * 1000,
        timelineLength: 2 * 60 * 1000,
      },
    })
    dispatch({
      type: useTimelineUpdater.ACTION_TYPES.SET_CONTENT_RECT,
      payload: {
        containerRect,
      },
    })
  }, [containerRect, dispatch])
}
