import styled from 'styled-components/macro'
import SimpleBar from 'simplebar-react'

import { Switch } from 'components/core/switch'
import { Text } from 'components/core/text'

import { useRosterData } from '../hooks'
import { UserSidebarItem } from './user-item'
import { useHistory } from 'react-router'
import { useEffect, useMemo } from 'react'

interface Props {
  selectedChannelName?: string
}

export const RosterList = ({ selectedChannelName }: Props) => {
  const { replace } = useHistory()
  const { roster, onlyFavorites, toggleOnlyFavorites } = useRosterData()

  const simplebarStyles = useMemo(() => {
    return {
      maxHeight: `calc(100vh - 160px)`,
      width: 300,
    }
  }, [])

  // select the first channel if none is selected
  useEffect(() => {
    if (!selectedChannelName && roster?.length) {
      replace({ pathname: `/roster/${roster[0].name}` })
    }
  }, [replace, roster, selectedChannelName])

  return (
    <RosterSidebarDiv>
      <SidebarHeadingDiv>
        <OnlyFavoritesContainerDiv
          onClick={(e) => {
            e.preventDefault()
            toggleOnlyFavorites()
          }}
        >
          <Text variant="text-3">Only Favorites</Text>
          <Switch checked={onlyFavorites ?? false} onChange={() => {}} />
        </OnlyFavoritesContainerDiv>
      </SidebarHeadingDiv>
      <FixedSidebarContainerDiv>
        <SimpleBar style={simplebarStyles}>
          {roster?.map((user) => (
            <UserSidebarItem
              key={user.id}
              user={user}
              selected={user.name === selectedChannelName}
              setSelected={() => {
                replace({ pathname: `/roster/${user.name}` })
              }}
            />
          ))}
        </SimpleBar>
      </FixedSidebarContainerDiv>
    </RosterSidebarDiv>
  )
}

const RosterSidebarDiv = styled.div`
  grid-area: roster-sidebar;
  position: fixed;
`

const SidebarHeadingDiv = styled.div`
  padding: 0 16px 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const FixedSidebarContainerDiv = styled.div`
  background-color: ${({ theme }) => theme.colors.neutrals.n8};
  border-radius: 8px;
  overflow: hidden;
`

const OnlyFavoritesContainerDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  user-select: none;
`
