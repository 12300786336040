import { useMemo } from 'react'

import { ReactComponent as KillIcon } from 'assets/valorant/icons/kill.svg'
import { ValorantRoundStats } from 'core/shared/valorant/hooks'
import { useTimelineHelpers } from 'core/editor/timeline/hooks'
import { useTimelineState } from 'core/editor/timeline/context'

import {
  KillIndicatorDiv,
  PregamePaddingDiv,
  TimelineContainerDiv,
  TimelineMatchContentsDiv,
  TimelineMomentSelectionDiv,
  TimelineRoundContainerDiv,
} from './styled'
import { useMatchStartRemote } from 'core/valorant-match/editor/hooks'
import { useEditorState } from 'core/editor/context'
import { useMatchPlayerList } from 'core/shared/timeline/hooks'

interface Props {
  matchId: string
  primaryUserId: string
  roundStats: ValorantRoundStats[]
}

export const PlayerRoundTimelineDetail = ({
  roundStats,
  matchId,
  primaryUserId,
}: Props) => {
  const { selectedMomentStyles, pregamePaddingStyles, containerStyles } =
    usePlayerMatchDetails(matchId, primaryUserId)

  return (
    <TimelineMatchContentsDiv>
      <TimelineContainerDiv style={containerStyles}>
        <PregamePaddingDiv style={pregamePaddingStyles} />
        <TimelineMomentSelectionDiv style={selectedMomentStyles} />
        {roundStats?.map((roundData) => (
          <ValorantRoundTimeline key={roundData.roundNum} data={roundData} />
        ))}
      </TimelineContainerDiv>
    </TimelineMatchContentsDiv>
  )
}

const usePlayerMatchDetails = (matchId: string, primaryUserId: string) => {
  const { getContentWidthFromTime } = useTimelineHelpers()
  const { selection } = useEditorState()
  const { startTime: timelineStartTime } = useTimelineState()
  const { mainPerspectiveInfo } = useMatchPlayerList(matchId, primaryUserId)
  const { matchStart } = useMatchStartRemote(
    matchId,
    mainPerspectiveInfo?.videoId ?? ''
  )

  // enables us to put this match content into any timeline
  // so maybe we can show just a round in the middle of the match
  const containerStyles = useMemo(() => {
    if (!matchStart) return {}
    return { left: getContentWidthFromTime(matchStart - timelineStartTime) }
  }, [getContentWidthFromTime, matchStart, timelineStartTime])

  // this is only being used by composer right now, and it doesn't need this
  const pregameTime = useMemo(() => {
    return 0
    // if (!matchStart) return 0
    // return matchStart - timelineStartTime
  }, [])

  const pregamePaddingStyles = useMemo(() => {
    return {
      width: getContentWidthFromTime(pregameTime),
    }
  }, [getContentWidthFromTime, pregameTime])

  const selectedMomentStyles = useMemo(() => {
    if (!selection) return undefined
    return {
      left: getContentWidthFromTime(
        pregameTime + selection.startTime - timelineStartTime
      ),
      width: getContentWidthFromTime(selection.endTime - selection.startTime),
    }
  }, [getContentWidthFromTime, pregameTime, selection, timelineStartTime])

  return useMemo(
    () => ({ selectedMomentStyles, pregamePaddingStyles, containerStyles }),
    [containerStyles, pregamePaddingStyles, selectedMomentStyles]
  )
}

interface ValorantRoundTimelineProps {
  data: ValorantRoundStats
}

export const ValorantRoundTimeline: React.FC<ValorantRoundTimelineProps> = ({
  data,
}) => {
  const { containerStyles, killNotchStyles } = useValorantRoundData(data)
  return (
    <TimelineRoundContainerDiv style={containerStyles}>
      {killNotchStyles.map((style, idx) => (
        <KillIndicatorDiv key={idx} style={style}>
          <KillIcon />
        </KillIndicatorDiv>
      ))}
    </TimelineRoundContainerDiv>
  )
}

const useValorantRoundData = (data: ValorantRoundStats) => {
  const { getContentWidthFromTime } = useTimelineHelpers()
  const containerStyles = useMemo(
    () => ({
      width: getContentWidthFromTime(data.roundLength),
    }),
    [data.roundLength, getContentWidthFromTime]
  )

  const killNotchStyles = useMemo(() => {
    const killOffset = data.stats.kills.map(({ timeSinceRoundStartMillis }) =>
      getContentWidthFromTime(timeSinceRoundStartMillis)
    )
    return killOffset.map((pxOffset) => ({ left: pxOffset }))
  }, [data.stats.kills, getContentWidthFromTime])

  return useMemo(
    () => ({ killNotchStyles, containerStyles }),
    [containerStyles, killNotchStyles]
  )
}
