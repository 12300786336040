import { TextField } from 'components/core/textfield'
import React from 'react'

import { useTemplateEditorState } from './context'
import { CreateTemplateCrop } from './create-crop'
import { CropSettings } from './crop-settings'
import { useTemplateEditorHelpers } from './hooks'
import { SaveTemplate } from './save-template'
import { CropSettingsListDiv } from './styled'

interface CropSettingsListProps {}

export const CropSettingsList: React.FC<CropSettingsListProps> = () => {
  const { templateCrops, title } = useTemplateEditorState()
  const { setTemplateTitle } = useTemplateEditorHelpers()

  return (
    <CropSettingsListDiv>
      <div>
        <TextField
          value={title}
          onChange={(evt) => setTemplateTitle(evt.currentTarget.value)}
        />
      </div>
      {templateCrops.map((tc) => (
        <CropSettings key={tc.title} templateCrop={tc} />
      ))}
      <CreateTemplateCrop />
      <SaveTemplate />
    </CropSettingsListDiv>
  )
}
