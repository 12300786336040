import React, { useCallback, useMemo } from 'react'

import { SyncedVideo } from 'core/editor/synced-video'
import { AgentThumbImg } from 'core/shared/timeline/valorant/heading/styled'
import { Text } from 'components/core/text'
import { AGENT_THUMB_IMAGES } from 'components/valorant/agent'

import { ValorantVideoInfo } from '.'
import { useValorantMatchEditorState } from './context'
import { useValorantMatchEditorHelpers } from './helpers'
import {
  IgnoreActionDiv,
  PreviewVideoLabelDiv,
  SecondaryPlayerDiv,
  VideoGradientDiv,
} from 'core/shared/video-player/styled'
import { useRemoteMatchSync } from './hooks'
import { usePlayerList } from './timeline/hooks'
import { CloseIcon } from 'core/feed/components/preview-modal/close-icon'

interface Props {
  videoData: ValorantVideoInfo
  style: React.CSSProperties
}

export const ValorantMatchPreviewOption = ({ videoData, style }: Props) => {
  const { matchId, selectedVideoInfo, mainPerspectiveInfo, primaryVideoId } =
    useValorantMatchEditorState()
  const { setSelectedVideoInfo } = useValorantMatchEditorHelpers()
  const { offset } = useRemoteMatchSync(
    matchId,
    videoData.videoId,
    primaryVideoId
  )

  const { playerList } = usePlayerList()

  const playerInfo = useMemo(() => {
    return playerList.find(({ account }) => account.id === videoData.accountId)
  }, [playerList, videoData.accountId])

  const setSelected = useCallback(() => {
    setSelectedVideoInfo(videoData)
  }, [setSelectedVideoInfo, videoData])

  const isSelected = useMemo(() => {
    return selectedVideoInfo?.videoId === videoData.videoId
  }, [selectedVideoInfo?.videoId, videoData.videoId])

  const ignoreVideo = useCallback(
    (evt: React.MouseEvent<HTMLDivElement>) => {
      evt.stopPropagation()
      const oldList = localStorage.getItem('ignoredVideos')
      let nextList: string[]
      if (oldList === null) {
        nextList = []
      } else {
        nextList = JSON.parse(oldList)
      }

      localStorage.setItem(
        'ignoredVideos',
        JSON.stringify([...nextList, videoData.videoId])
      )
      window.dispatchEvent(new Event('storage'))
    },
    [videoData.videoId]
  )

  return (
    <SecondaryPlayerDiv
      style={style}
      onClick={setSelected}
      $selected={isSelected}
    >
      {selectedVideoInfo && mainPerspectiveInfo ? (
        <>
          <SyncedVideo
            id={`preview_${videoData.videoId}`}
            primaryVideoId={mainPerspectiveInfo.videoId}
            videoId={videoData.videoId}
            controller={mainPerspectiveInfo.videoId === videoData.videoId}
            offset={offset ? offset / 1000 : undefined}
          />
          <VideoGradientDiv />
          <PreviewVideoLabelDiv>
            {playerInfo && (
              <AgentThumbImg
                $selected={false}
                alt="agent thumbnail"
                src={AGENT_THUMB_IMAGES[playerInfo.agent ?? ''] ?? ''}
              />
            )}
            <Text variant="text-3">{videoData.displayName}</Text>
          </PreviewVideoLabelDiv>
        </>
      ) : null}
      <IgnoreActionDiv onClick={ignoreVideo}>
        <CloseIcon />
      </IgnoreActionDiv>
    </SecondaryPlayerDiv>
  )
}
