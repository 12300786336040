import { HStack } from 'components/core/layout'
import { Spinner } from 'components/core/spinner'
import React, { useEffect } from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router'
import { useCurrentChannel } from './hooks'
import { ChannelMatches } from './matches'
import { ChannelMatchDetailPage } from './matches/match-detail'
import { ChannelValorantFeed } from './valorant'
import { ChannelVideoListPage } from './videos'
import { ChannelVideoDetailPage } from './videos/video-detail'

export const ChannelPage = () => {
  const { path, url } = useRouteMatch()
  const { loading, error, hasValorantAccount } = useCurrentChannel()

  // lets default urls once we know if they have a riot account
  // connected or not
  useEffect(() => {}, [])

  // we shouldn't actually handle the error like this, but
  // we send the request before we have our token, we should
  // fix that too
  if (loading || error) {
    return (
      <HStack
        fullWidth={true}
        justifyContent="center"
        style={{ marginTop: 48 }}
      >
        <Spinner size={48} />
      </HStack>
    )
  }

  return (
    <Switch>
      <Route exact path={`${path}/`}>
        {hasValorantAccount === true ? (
          <Redirect to={`${url}/valorant`} />
        ) : hasValorantAccount === false ? (
          <Redirect to={`${url}/videos`} />
        ) : (
          <React.Fragment />
        )}
      </Route>
      <Route exact path={`${path}/valorant`} component={ChannelValorantFeed} />
      <Route exact path={`${path}/videos`} component={ChannelVideoListPage} />
      <Route
        path={`${path}/videos/:videoId`}
        component={ChannelVideoDetailPage}
      />
      <Route exact path={`${path}/matches`} component={ChannelMatches} />
      <Route
        path={`${path}/matches/:matchId`}
        component={ChannelMatchDetailPage}
      />
    </Switch>
  )
}
