import styled from 'styled-components/macro'

export const VideoSyncModalContainerDiv = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  color: white;
`

export const SyncVideoPageDiv = styled.div`
  position: relative;
  display: grid;
  height: calc(100vh - 48px);
  margin: 24px;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 80px 400px;
  grid-template-areas:
    'compare-sync'
    'sync-controls'
    'timeline-controls';
`

export const SyncVideoCompareDiv = styled.div`
  position: relative;
  width: 100%;
  grid-area: compare-sync;
  display: flex;
`

export const SyncVideoControlsDiv = styled.div`
  position: relative;
  display: grid;
  grid-area: sync-controls;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SyncVideoTimelineControlsDiv = styled.div`
  position: relative;
  display: grid;
  grid-area: timeline-controls;
`

export const SyncVideoContainerDiv = styled.div``
