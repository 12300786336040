import { ReactNode, useEffect } from 'react'
import styled from 'styled-components/macro'

import { Portal } from 'components/dialog/portal'

interface Props {
  children: ReactNode
}

export const PostBuyModal = ({ children }: Props) => {
  useEffect(() => {
    document.body.classList.add('hidden')
    return () => document.body.classList.remove('hidden')
  }, [])

  return (
    <Portal>
      <ModalRoot>
        <Backdrop />
        {children}
      </ModalRoot>
    </Portal>
  )
}

const ModalRoot = styled.div`
  position: fixed;
  inset: 0px;
  z-index: 1000;
`

const Backdrop = styled.div<{ $opacity?: number }>`
  background-color: rgba(
    0,
    0,
    0,
    ${({ $opacity }) => ($opacity ? $opacity : 0.75)}
  );
  position: fixed;
  inset: 0px;
  z-index: -1;
`
