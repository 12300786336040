import React from 'react'

import { ReactComponent as CloseIcon } from 'icons/svgs/solid/times.svg'

import { CropSettingsList } from './crop-list'
import { TemplateEditorPreview } from './preview'
import { TemplateEditorDiv, CloseButtonDiv } from './styled'
import { useTemplateEditorHelpers } from './hooks'

export const TemplateEditor: React.FC = () => {
  const { close } = useTemplateEditorHelpers()

  return (
    <TemplateEditorDiv>
      <TemplateEditorPreview />
      <CropSettingsList />
      <CloseButtonDiv>
        <CloseIcon onClick={close} />
      </CloseButtonDiv>
    </TemplateEditorDiv>
  )
}
