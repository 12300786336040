import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useEditor } from '../hooks'
import { useTimelineState } from './context'
import {
  useGetTimeToContentsWidth,
  useSelectPct,
  useTimelineHelpers,
} from './hooks'
import { TimelineSelection } from './selection'
import { SelectionAreaContainerDiv } from './styled'

interface TimelineSelectionAreaProps {
  parentRect?: DOMRect
}

export const TimelineSelectionArea: React.FC<TimelineSelectionAreaProps> = ({
  parentRect,
}) => {
  const {
    contentsRef,
    localSelection,
    updateLocalSelection,
    contentWidth,
    setGlobalSelection,
  } = useTimelineSelection(parentRect)
  return (
    <SelectionAreaContainerDiv ref={contentsRef}>
      {localSelection ? (
        <TimelineSelection
          selection={localSelection}
          setSelection={updateLocalSelection}
          contentWidth={contentWidth}
          onUpdateDone={setGlobalSelection}
        />
      ) : null}
    </SelectionAreaContainerDiv>
  )
}

export const useTimelineSelection = (parentRect?: DOMRect) => {
  const { selectionPct, timelineLength, startTime, endTime, offsetPct } =
    useTimelineState()
  const { setSelectionPct } = useTimelineHelpers()
  const { setSelection } = useEditor()
  const localSelectionRef = useRef({ startPct: 0, endPct: 0 })
  const [localSelection, setLocalSelection] = useState<{
    startPct: number
    endPct: number
  }>()

  // This is not ideal, right now we are setting both editor level and timeline level
  // one for pct and one for time. I could probably rework this a bit, but right now this should
  // be alright
  const setGlobalSelection = useCallback(() => {
    const { startPct, endPct } = localSelectionRef.current
    const timelineTotalTime = endTime - startTime
    setSelectionPct({ startPct, endPct })

    const nextStartTime = startTime + startPct * timelineTotalTime
    setSelection({
      startTime: nextStartTime,
      endTime: startTime + endPct * timelineTotalTime,
    })
    // seekTo(nextStartTime)
  }, [endTime, setSelection, setSelectionPct, startTime])

  // this could probably be fixed in another way, but currently we want to allow for:
  // - local update so we can display visual updates without sending to context
  // - once done, we can use the ref so we don't mess with usecallback stuff set on
  //    the mouseup/down/up stuf
  const updateLocalSelection = (nextSelection: {
    startPct: number
    endPct: number
  }) => {
    // for visual updates
    setLocalSelection(nextSelection)
    //for setting when done
    localSelectionRef.current = nextSelection
  }

  // I could probably actually figure this out with the contentsRef,
  // but this works for now
  const getTimeToContentsWidth = useGetTimeToContentsWidth(
    parentRect?.width ?? 1,
    timelineLength
  )
  const contentWidth = useMemo(
    () => getTimeToContentsWidth(endTime - startTime),
    [endTime, getTimeToContentsWidth, startTime]
  )

  const { ref: contentsRef } = useSelectPct(
    contentWidth,
    parentRect,
    offsetPct * (contentWidth - getTimeToContentsWidth(timelineLength)),
    updateLocalSelection,
    setGlobalSelection
  )

  // external changes should reflect on the visual marker we use.
  useEffect(() => {
    setLocalSelection(selectionPct)
  }, [selectionPct])

  return useMemo(
    () => ({
      contentsRef,
      localSelection,
      updateLocalSelection,
      contentWidth,
      setGlobalSelection,
    }),
    [contentWidth, contentsRef, localSelection, setGlobalSelection]
  )
}
