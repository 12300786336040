import React, { createContext, useContext, useReducer } from 'react'
import { ValorantVideoInfo } from '.'

interface ValorantMatchEditorState {
  matchId: string
  primaryVideoId: string
  timelineContainerRect?: DOMRect
  selectedVideoInfo?: ValorantVideoInfo
  selectedMomentTimes?: { start: number; end: number }
  mainPerspectiveInfo?: ValorantVideoInfo
  exportingOpen: boolean
}

const INITIAL_STATE: ValorantMatchEditorState = {
  matchId: '',
  primaryVideoId: '',
  timelineContainerRect: undefined,
  selectedVideoInfo: undefined,
  selectedMomentTimes: undefined,
  mainPerspectiveInfo: undefined,
  exportingOpen: false,
}

enum ACTION_TYPES {
  SET_MATCH_ID = 'SET_MATCH_ID',
  SET_PRIMARY_VIDEO_ID = 'SET_PRIMARY_VIDEO_ID',
  SET_TIMELINE_CONTAINER_RECT = 'SET_TIMELINE_CONTAINER_RECT',
  SET_SELECTED_VIDEO_INFO = 'SET_SELECTED_VIDEO_INFO',
  SET_SELECTED_MOMENT_TIMES = 'SET_SELECTED_MOMENT_TIMES',
  SET_MAIN_PERSPECTIVE_INFO = 'SET_MAIN_PERSPECTIVE_INFO',
  SET_EXPORTING_OPEN = 'SET_EXPORTING_OPEN',
}

interface SetMatchIdAction {
  type: ACTION_TYPES.SET_MATCH_ID
  payload: {
    matchId: string
  }
}

interface SetPrimaryVideoId {
  type: ACTION_TYPES.SET_PRIMARY_VIDEO_ID
  payload: {
    videoId: string
  }
}

interface SetMomentIdAction {
  type: ACTION_TYPES.SET_TIMELINE_CONTAINER_RECT
  payload: {
    timelineContainerRect?: DOMRect
  }
}

interface SetSelectedVideoInfoAction {
  type: ACTION_TYPES.SET_SELECTED_VIDEO_INFO
  payload: {
    selectedVideoInfo: ValorantVideoInfo
  }
}

interface SetSelectedMomentTimesAction {
  type: ACTION_TYPES.SET_SELECTED_MOMENT_TIMES
  payload: {
    selectedMomentTimes?: { start: number; end: number }
  }
}

interface SetMainPerspectiveInfoAction {
  type: ACTION_TYPES.SET_MAIN_PERSPECTIVE_INFO
  payload: {
    mainPerspectiveInfo?: ValorantVideoInfo
  }
}

interface SetExportingOpen {
  type: ACTION_TYPES.SET_EXPORTING_OPEN
  payload: {
    exportingOpen: boolean
  }
}

type ValorantMatchEditorActions =
  | SetMomentIdAction
  | SetMatchIdAction
  | SetSelectedVideoInfoAction
  | SetSelectedMomentTimesAction
  | SetMainPerspectiveInfoAction
  | SetExportingOpen
  | SetPrimaryVideoId

type Dispatch = (action: ValorantMatchEditorActions) => void

const reducer = (
  state: ValorantMatchEditorState,
  action: ValorantMatchEditorActions
): ValorantMatchEditorState => {
  switch (action.type) {
    case ACTION_TYPES.SET_TIMELINE_CONTAINER_RECT: {
      return {
        ...state,
        timelineContainerRect: action.payload.timelineContainerRect,
      }
    }
    case ACTION_TYPES.SET_MATCH_ID: {
      return {
        ...state,
        matchId: action.payload.matchId,
      }
    }

    case ACTION_TYPES.SET_PRIMARY_VIDEO_ID: {
      return {
        ...state,
        primaryVideoId: action.payload.videoId,
      }
    }

    case ACTION_TYPES.SET_SELECTED_VIDEO_INFO: {
      return {
        ...state,
        selectedVideoInfo: action.payload.selectedVideoInfo,
      }
    }

    case ACTION_TYPES.SET_SELECTED_MOMENT_TIMES: {
      return {
        ...state,
        selectedMomentTimes: action.payload.selectedMomentTimes,
      }
    }

    case ACTION_TYPES.SET_MAIN_PERSPECTIVE_INFO: {
      return {
        ...state,
        mainPerspectiveInfo: action.payload.mainPerspectiveInfo,
      }
    }

    case ACTION_TYPES.SET_EXPORTING_OPEN: {
      return {
        ...state,
        exportingOpen: action.payload.exportingOpen,
      }
    }

    default:
      return state
  }
}

const ValorantMatchEditorContext = createContext<
  ValorantMatchEditorState | undefined
>(undefined)
const ValorantMatchEditorUpdaterContext = createContext<Dispatch | undefined>(
  undefined
)

export const ValorantMatchEditorProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE)

  return (
    <ValorantMatchEditorContext.Provider value={state}>
      <ValorantMatchEditorUpdaterContext.Provider value={dispatch}>
        {children}
      </ValorantMatchEditorUpdaterContext.Provider>
    </ValorantMatchEditorContext.Provider>
  )
}

export const useValorantMatchEditorState = () => {
  const context = useContext(ValorantMatchEditorContext)
  if (context === undefined) {
    throw new Error(
      'useValorantMatchEditorState must be used within a ValorantMatchEditorProvider'
    )
  }
  return context
}

export const useValorantMatchEditorUpdater = () => {
  const context = useContext(ValorantMatchEditorUpdaterContext)
  if (context === undefined) {
    throw new Error(
      'useValorantMatchEditorUpdater must be used within a ValorantMatchEditorProvider'
    )
  }
  return context
}

useValorantMatchEditorUpdater.ACTION_TYPES = ACTION_TYPES
