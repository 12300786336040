import momentjs from 'moment'
import { transparentize } from 'polished'
import { useTheme } from 'styled-components/macro'
import { useEffect, useState } from 'react'

import { useGetValorantMatchPageDetailQuery } from '__generated__'
import { useTimelineUpdater } from 'core/editor/timeline/context'
import {
  TimelineAreaContentsDiv,
  TimelineDetailAreaDiv,
  TimelineHeadingAreaDiv,
  TimelineOptionsAreaDiv,
  TogglePerspectivesButton,
} from 'core/shared/timeline/styled'
import { Button } from 'components/core/button'

import { TimelineDetail } from 'core/shared/timeline/detail'
import { useValorantMatchEditorState } from '../context'
import { ValorantMatchTimelineHeading } from './heading'
import { ValorantTimelineContents } from './detail/contents'
import { useValorantMatchEditorHelpers } from '../helpers'
import { TimelinePlayerControls } from 'core/shared/timeline/controls'
import { ValorantTimelineTimeDisplay } from './time-display'
import { Switch } from 'components/core/switch'
import { HStack, Spacing } from 'components/core/layout'
import { Text } from 'components/core/text'

interface Props {
  matchId: string
  options?: {
    onExport?: (momentId: string) => void
  }
}

export const ValorantMatchTimeline = ({ matchId, options }: Props) => {
  const theme = useTheme()
  const { selectedVideoInfo } = useValorantMatchEditorState()
  const timelineDispatch = useTimelineUpdater()
  const { setExportingOpen } = useValorantMatchEditorHelpers()
  // hide/show stats without perspectives
  const [showAll, setShowAll] = useState<boolean>(true)
  const { data: previewData } = useGetValorantMatchPageDetailQuery({
    variables: { matchId },
  })

  useEffect(() => {
    if (previewData?.valorantMatch?.startedAt) {
      timelineDispatch({
        type: useTimelineUpdater.ACTION_TYPES.SET_TIMES,
        payload: {
          startTime: momentjs(previewData?.valorantMatch?.startedAt).valueOf(),
          endTime:
            momentjs(previewData?.valorantMatch?.startedAt).valueOf() +
            60 * 60 * 1000,
        },
      })
    }
  }, [previewData?.valorantMatch?.startedAt, timelineDispatch])

  return (
    <TimelineAreaContentsDiv>
      <TimelineOptionsAreaDiv>
        <TimelinePlayerControls />
        {selectedVideoInfo && options?.onExport ? (
          <div style={{ position: 'absolute', right: 16 }}>
            <Button size="small" onClick={() => setExportingOpen(true)}>
              Export
            </Button>
          </div>
        ) : null}
      </TimelineOptionsAreaDiv>
      <TogglePerspectivesButton
        color={transparentize(0.5, theme.colors.static.neutrals.n4)}
        onClick={(e) => {
          e.preventDefault()
          setShowAll((state) => !state)
        }}
      >
        <HStack alignItems="center" gap={8}>
          <Switch checked={showAll} onChange={() => {}} />
          <Spacing right={8}>
            <Text variant="small">Show All</Text>
          </Spacing>
        </HStack>
      </TogglePerspectivesButton>
      <TimelineHeadingAreaDiv>
        <ValorantMatchTimelineHeading matchId={matchId} showAll={showAll} />
      </TimelineHeadingAreaDiv>

      <TimelineDetailAreaDiv>
        <TimelineDetail timeDisplayNode={<ValorantTimelineTimeDisplay />}>
          <ValorantTimelineContents matchId={matchId} showAll={showAll} />
        </TimelineDetail>
      </TimelineDetailAreaDiv>
      {/* <TimelineScrollbarAreaDiv>
        <ValorantTimelineScrollbar />
      </TimelineScrollbarAreaDiv> */}
    </TimelineAreaContentsDiv>
  )
}
