import styled from 'styled-components/macro'

export const ChatTimelineContainerDiv = styled.div`
  position: relative;
  height: 60px;
  width: 100%;
  margin: 4px 0;
`

export const ChatTimelineStreamDiv = styled.div`
  position: absolute;
  height: 50px;
  top: 5px;
  border-radius: 8px;
  background-color: #bbb;
`

export const MomentIndicatorDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #666;
  color: white;
  padding: 4px;

  & > div:first-child {
    font-weight: 700;
    font-size: 14px;
  }

  & > div:nth-child(2) {
    margin-top: 8px;
    font-size: 12px;
  }
`
