import styled from 'styled-components/macro'
import { fonts, fontWeights } from 'theme/typography'
import { Container } from '.'

type Props = {
  neutralOffset?: 1 | 2 | 3 | 4 | 5 | 6
  borderColor?: string
  body?: boolean
}

interface CardCompound {
  Title: typeof Title
  Footer: typeof Footer
  Body: typeof Body
  Text: typeof Text
}

const CardTemplate = styled.div<Props>`
  border-radius: 16px;
  overflow: hidden;
  border: 1px solid
    ${({ borderColor, neutralOffset: n = 1, theme }) =>
      borderColor !== undefined
        ? borderColor
        : theme.colors.neutrals[`n${8 - Math.max(n, 2)}`]};
`

const Body = styled.div<Props>`
  padding: 16px;
  background-color: ${({ neutralOffset: n = 1, theme }) =>
    theme.colors.neutrals[`n${8 - (n - 1)}`]};
`
const Title = styled.div`
  ${fonts.headers.h4};
  font-weight: ${fontWeights.semibold};
  margin-bottom: 8px;
`

const Text = styled.div`
  ${fonts.text['text-2']};
`

const Footer = styled(Container)<Props>`
  padding: 16px;
  background-color: ${({ neutralOffset: n = 2, theme }) =>
    theme.colors.neutrals[`n${8 - (n - 1)}`]};
  border-top: 1px solid
    ${({ neutralOffset: n = 2, theme }) =>
      theme.colors.neutrals[`n${8 - Math.max(n, 2)}`]};
`

export const Card: React.FC<Props> & CardCompound = ({
  body = false,
  borderColor,
  neutralOffset,
  children,
}) => (
  <CardTemplate borderColor={borderColor} neutralOffset={neutralOffset}>
    {body ? <Body>{children}</Body> : <>{children}</>}
  </CardTemplate>
)

Card.Body = Body
Card.Footer = Footer
Card.Title = Title
Card.Text = Text
