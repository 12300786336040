import { transparentize } from 'polished'
import { useHistory } from 'react-router'
import { get } from 'lodash'

import { CloseModalButton } from 'components/close-modal/close-button'
import { IconButton } from 'core/composer/template-sidebar/styled'
import styled, { useTheme } from 'styled-components/macro'
import { ReactComponent as CircleDownIcon } from 'icons/svgs/solid/arrow-alt-circle-down.svg'
import { AspectRatioContainer } from 'components/aspect-ratio/container'

import { PostBuyEditFragment } from '__generated__'
import { up } from 'styled-breakpoints'

interface Props {
  editId: string
  editMap: { [key: string]: PostBuyEditFragment }
}

export const EditModalContent = ({ editMap, editId }: Props) => {
  const theme = useTheme()
  const editFragment = editMap[editId] as PostBuyEditFragment
  const { replace, location } = useHistory()
  const { width, height } = editFragment.format.aspectRatio
  const videoUrl = get(
    editFragment,
    ['exports', '0', 'output', 'videos', '0', 'url'],
    ''
  )
  const thumbUrl = get(
    editFragment,
    ['exports', '0', 'output', 'thumbnails', '0', 'url'],
    ''
  )
  return (
    <ContainerDiv>
      <VideoContainerDiv>
        <AspectRatioContainer width={width} height={height}>
          <video src={videoUrl} poster={thumbUrl} controls={true} />
          <ControlsDiv>
            <a href={videoUrl}>
              <IconButton
                color={transparentize(0.2, theme.colors.static.neutrals.n4)}
                onClick={() => {}}
              >
                <CircleDownIcon
                  fill={theme.colors.static.neutrals.n8}
                  width={16}
                />
                <span>Download</span>
              </IconButton>
            </a>
          </ControlsDiv>
        </AspectRatioContainer>
      </VideoContainerDiv>
      <CloseModalButton
        onClick={() => {
          replace(location.pathname)
        }}
      />
    </ContainerDiv>
  )
}

const ContainerDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const VideoContainerDiv = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  width: calc(100vw - 40px);
  height: calc(100vh - 40px);
  margin: 20px;
  ${up('md')} {
    width: calc(100vw - 200px);
    height: calc(100vh - 200px);
    margin: 100px;
  }

  video {
    position: relative;
    width: 100%;
    height: 100%;
  }
`

const ControlsDiv = styled.div`
  position: absolute;
  bottom: -40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`
