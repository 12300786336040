import { useMemo } from 'react'

import { Text } from 'components/core/text'
import { NameDiv } from 'core/shared/timeline/valorant/heading/styled'

import { useSignalPlayerState } from '../context'
import { useSignalPlayerHelpers, useVideoTimelineData } from '../helpers'
import { SignalPlayerHeadingDiv, StreamerThumbImg } from '../styled'

interface Props {
  videoId: string
}

export const SignalPlayerVideoTimelineHeading = ({ videoId }: Props) => {
  const { data } = useVideoTimelineData(videoId)
  const { selectedVideoId } = useSignalPlayerState()
  const { setSelectedVideo } = useSignalPlayerHelpers()

  const isSelected = useMemo(() => {
    return selectedVideoId === videoId
  }, [selectedVideoId, videoId])

  return (
    <SignalPlayerHeadingDiv
      $selected={isSelected}
      onClick={() => setSelectedVideo(videoId)}
      $availablePerspective={true}
    >
      {data && (
        <StreamerThumbImg
          $selected={isSelected}
          alt="streamer thumbnail"
          src={data?.video?.user?.profileImageUrl ?? ''}
        />
      )}
      <NameDiv>
        <Text variant="text-3">{data?.video?.user?.displayName} </Text>
      </NameDiv>
    </SignalPlayerHeadingDiv>
  )
}
