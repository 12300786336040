import styled from 'styled-components/macro'
import { monoFontStyle } from 'theme/typography'

export const ChatEntryTimestamp = styled.time`
  ${monoFontStyle}
  position: relative;
  padding-right: 4px;
  font-size: 13px;
  color: ${({ theme }) => theme.colors.neutrals.n4};
  align-self: flex-start;

  ::before {
    // content: attr(data-matching);
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 1;

    width: 100%;
    margin-left: -4px;
    margin-top: 4px;
    padding: 4px;

    display: none;
    color: ${({ theme }) => theme.colors.neutrals.n3};
    background-color: ${({ theme }) => theme.colors.background.secondary};
    border-radius: 0 0 4px 4px;
  }
`

export const ChatEntryText = styled.span`
  position: relative;
  padding-left: 4px;

  font-size: 13px;
  word-wrap: break-word;
  overflow-wrap: anywhere;

  color: ${({ theme }) => theme.colors.text.primary};
`

export const ChatEntryContainer = styled.div<{
  showMatching: boolean
  expanded: boolean
}>`
  display: flex;
  padding: 4px;
  border-radius: 4px;
  flex-direction: column;
  background-color: ${({ expanded, theme }) =>
    expanded && theme.colors.background.secondary};

  ${({ showMatching, theme }) =>
    showMatching &&
    `
	& ${ChatEntryText}::before {
		content: '';
		position: absolute;
		left: -1px;
		top: 0;
		width: 2px;
		height: 100%;
		background: ${theme.colors.neutrals.n5};
	}
`}

  &:hover {
    background: ${({ theme }) => theme.colors.background.secondary};
  }
`
