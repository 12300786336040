import React, { createContext, useContext, useReducer } from 'react'

import {
  ExportFormat,
  ExportTemplate,
  Rect,
  Scene,
  TemplateCrop,
} from 'core/exporter/constants'

export enum CropSettingType {
  Full = 'Full',
  Facecam = 'Facecam',
  Custom = 'Custom',
}

export enum CropSizeType {
  Contain = 'Contain',
  Cover = 'Cover',
}

export interface CropSettings {
  title: string
  videoId?: string
  type: CropSettingType
  size?: CropSizeType
  rect: Rect
}

interface ExporterState {
  momentId: string
  primaryVideoId: string
  selectedTemplate?: ExportTemplate
  selectedFormat?: ExportFormat
  cropSettings: { [key: string]: CropSettings }
  scenes: Scene[]
  previewRect?: DOMRect
  selectedTemplateCrop?: TemplateCrop
  title: string
}

const INITIAL_STATE: ExporterState = {
  momentId: '',
  primaryVideoId: '',
  selectedTemplate: undefined,
  selectedFormat: undefined,
  cropSettings: {},
  scenes: [],
  previewRect: undefined,
  selectedTemplateCrop: undefined,
  title: '',
}

enum ACTION_TYPES {
  SET_TEMPLATE = 'SET_TEMPLATE',
  SET_FORMAT = 'SET_FORMAT',
  SET_MOMENT_ID = 'SET_MOMENT_ID',
  SET_CROP_SETTINGS = 'SET_CROP_SETTINGS',
  SET_PRIMARY_VIDEO_ID = 'SET_PRIMARY_VIDEO_ID',
  ADD_SCENE = 'ADD_SCENE',
  EDIT_SCENE = 'EDIT_SCENE',
  DELETE_SCENE = 'DELETE_SCENE',
  CLEAR_SCENES = 'CLEAR_SCENES',
  SET_PREVIEW_RECT = 'SET_PREVIEW_RECT',
  SET_SELECTED_TEMPLATE_CROP = 'SET_SELECTED_TEMPLATE_CROP',
  SET_CROP_RECT_DATA = 'SET_CROP_RECT_DATA',
  SET_TITLE = 'SET_TITLE',
}

interface SetTitleAction {
  type: ACTION_TYPES.SET_TITLE
  payload: {
    title: string
  }
}

interface SetTemplateAction {
  type: ACTION_TYPES.SET_TEMPLATE
  payload: {
    selectedTemplate?: ExportTemplate
  }
}

interface SetFormatAction {
  type: ACTION_TYPES.SET_FORMAT
  payload: {
    selectedFormat?: ExportFormat
  }
}

interface SetMomentIdAction {
  type: ACTION_TYPES.SET_MOMENT_ID
  payload: {
    momentId: string
  }
}

interface SetCropSettingsAction {
  type: ACTION_TYPES.SET_CROP_SETTINGS
  payload: {
    cropSettings: CropSettings
  }
}

interface SetPrimaryVideoIdAction {
  type: ACTION_TYPES.SET_PRIMARY_VIDEO_ID
  payload: {
    primaryVideoId: string
  }
}

interface AddSceneAction {
  type: ACTION_TYPES.ADD_SCENE
  payload: {
    scene: Scene
  }
}

interface EditSceneAction {
  type: ACTION_TYPES.EDIT_SCENE
  payload: {
    idx: number
    scene: Scene
  }
}

interface DeleteSceneAction {
  type: ACTION_TYPES.DELETE_SCENE
  payload: {
    idx: number
  }
}

interface ClearScenesAction {
  type: ACTION_TYPES.CLEAR_SCENES
}

interface SetPreviewRectAction {
  type: ACTION_TYPES.SET_PREVIEW_RECT
  payload: {
    previewRect?: DOMRect
  }
}

interface SetSelectedTemplateCropAction {
  type: ACTION_TYPES.SET_SELECTED_TEMPLATE_CROP
  payload: {
    selectedTemplateCrop?: TemplateCrop
  }
}

interface SetCropRectDataAction {
  type: ACTION_TYPES.SET_CROP_RECT_DATA
  payload: {
    title: string
    rect: Rect
  }
}

type ExporterActions =
  | SetTemplateAction
  | SetFormatAction
  | SetMomentIdAction
  | SetCropSettingsAction
  | SetPrimaryVideoIdAction
  | AddSceneAction
  | EditSceneAction
  | DeleteSceneAction
  | ClearScenesAction
  | SetPreviewRectAction
  | SetSelectedTemplateCropAction
  | SetCropRectDataAction
  | SetTitleAction

type Dispatch = (action: ExporterActions) => void

const reducer = (
  state: ExporterState,
  action: ExporterActions
): ExporterState => {
  switch (action.type) {
    case ACTION_TYPES.SET_TITLE: {
      return {
        ...state,
        title: action.payload.title,
      }
    }

    case ACTION_TYPES.SET_TEMPLATE: {
      return {
        ...state,
        selectedTemplate: action.payload.selectedTemplate,
      }
    }

    case ACTION_TYPES.SET_FORMAT: {
      return {
        ...state,
        selectedFormat: action.payload.selectedFormat,
      }
    }

    case ACTION_TYPES.SET_MOMENT_ID: {
      return {
        ...state,
        momentId: action.payload.momentId,
      }
    }

    case ACTION_TYPES.SET_CROP_SETTINGS: {
      const data = action.payload.cropSettings
      const nextCropSettings = { ...state.cropSettings, [data.title]: data }

      return {
        ...state,
        cropSettings: nextCropSettings,
      }
    }

    case ACTION_TYPES.SET_PRIMARY_VIDEO_ID: {
      return {
        ...state,
        primaryVideoId: action.payload.primaryVideoId,
      }
    }

    case ACTION_TYPES.ADD_SCENE: {
      const nextScenes = [...state.scenes, action.payload.scene]

      return {
        ...state,
        scenes: nextScenes,
      }
    }

    case ACTION_TYPES.EDIT_SCENE: {
      const { idx } = action.payload
      const nextScenes = state.scenes.map((item, i) => {
        return idx === i ? action.payload.scene : item
      })

      return {
        ...state,
        scenes: nextScenes,
      }
    }

    case ACTION_TYPES.DELETE_SCENE: {
      const { idx } = action.payload
      const nextScenes = state.scenes.filter((_, i) => {
        return idx !== i
      })

      return {
        ...state,
        scenes: nextScenes,
      }
    }

    case ACTION_TYPES.CLEAR_SCENES: {
      return {
        ...state,
        scenes: [],
      }
    }

    case ACTION_TYPES.SET_PREVIEW_RECT: {
      return {
        ...state,
        previewRect: action.payload.previewRect,
      }
    }

    case ACTION_TYPES.SET_SELECTED_TEMPLATE_CROP: {
      return {
        ...state,
        selectedTemplateCrop: action.payload.selectedTemplateCrop,
      }
    }

    case ACTION_TYPES.SET_CROP_RECT_DATA: {
      const { title, rect } = action.payload
      const nextCropSettings = Object.values(state.cropSettings).reduce(
        (memo, item) => {
          const nextSetting =
            item.title === title
              ? { ...state.cropSettings[item.title], rect }
              : { ...state.cropSettings[item.title] }
          return {
            ...memo,
            [item.title]: nextSetting,
          }
        },
        {}
      )

      return {
        ...state,
        cropSettings: nextCropSettings,
      }
    }

    default:
      return state
  }
}

const ExporterContext = createContext<ExporterState | undefined>(undefined)
const ExporterUpdaterContext = createContext<Dispatch | undefined>(undefined)

export const ExporterProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE)

  return (
    <ExporterContext.Provider value={state}>
      <ExporterUpdaterContext.Provider value={dispatch}>
        {children}
      </ExporterUpdaterContext.Provider>
    </ExporterContext.Provider>
  )
}

export const useExporterState = () => {
  const context = useContext(ExporterContext)
  if (context === undefined) {
    throw new Error('useExporterState must be used within a ExporterProvider')
  }
  return context
}

export const useExporterUpdater = () => {
  const context = useContext(ExporterUpdaterContext)
  if (context === undefined) {
    throw new Error('useExporterUpdater must be used within a ExporterProvider')
  }
  return context
}

useExporterUpdater.ACTION_TYPES = ACTION_TYPES
