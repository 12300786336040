import momentjs from 'moment'
import { AspectRatioContainer } from 'components/aspect-ratio/container'
import { Container, HStack, Spacing, VStack } from 'components/core/layout'
import { Text } from 'components/core/text'
import { useMemo } from 'react'
import styled, { useTheme } from 'styled-components/macro'
import { MomentEditFragment, RecordingStatus } from '__generated__'
import { durationFormatMinutes } from 'utils/time'
import { GridItemInfo } from 'core/feed/components/grid/styled'
import { Spinner } from 'components/core/spinner'

interface Props {
  momentEdit: MomentEditFragment
  selected: boolean
  selectEdit: (editId: string) => void
}

export const EditItemPreview = ({
  momentEdit,
  selected,
  selectEdit,
}: Props) => {
  const theme = useTheme()
  const totalTime = useMemo(() => {
    const timeInMs = momentEdit.scenes.reduce((prev, scene) => {
      return (
        prev + (momentjs(scene.end).valueOf() - momentjs(scene.start).valueOf())
      )
    }, 0)
    return durationFormatMinutes(timeInMs / 1000)
  }, [momentEdit.scenes])

  return (
    <GridItemContainer
      $selected={selected}
      onClick={() => selectEdit(momentEdit.id)}
    >
      <ExportPreview momentEdit={momentEdit} />
      <GridItemInfo>
        <VStack>
          <Spacing bottom="4px">
            <HStack justifyContent="space-between" alignItems="center">
              <Container direction="row" alignItems="center">
                <Spacing left="8px">
                  <VStack>
                    <Text
                      variant="h5"
                      weight="bold"
                      color={theme.colors.text.primary}
                    >
                      {momentEdit.format.aspectRatio.width} x{' '}
                      {momentEdit.format.aspectRatio.height}
                    </Text>
                    <Text
                      variant="text-3"
                      color={theme.colors.text.secondary}
                    ></Text>
                  </VStack>
                </Spacing>
              </Container>
              <Text variant="text-2">
                {momentEdit.scenes.length} Scene
                {momentEdit.scenes.length > 1 ? 's' : null}
              </Text>
            </HStack>
          </Spacing>

          <HStack justifyContent="space-between">
            <Text variant="text-2">
              {momentEdit.exports[0]?.status === RecordingStatus.Inprogress
                ? 'In Progress'
                : momentEdit.exports[0]?.status === RecordingStatus.Failed
                ? 'Failed'
                : momentEdit.exports[0]?.status === RecordingStatus.Created
                ? 'Created'
                : ''}
            </Text>
            <Text variant="text-3">{totalTime} </Text>
          </HStack>
        </VStack>
      </GridItemInfo>
    </GridItemContainer>
  )
}

interface ExportPreviewProps {
  momentEdit: MomentEditFragment
}

export const ExportPreview = ({ momentEdit }: ExportPreviewProps) => {
  const firstExport = momentEdit.exports[0]
  const previewUrl = firstExport?.output?.thumbnails?.[0]?.url

  return (
    <PreviewImgContainerDiv>
      <AspectRatioContainer
        height={momentEdit.format.aspectRatio.height}
        width={momentEdit.format.aspectRatio.width}
      >
        {previewUrl ? (
          <img src={previewUrl} alt="export thumb" />
        ) : firstExport ? (
          <HStack alignItems="center" justifyContent="center" fullHeight={true}>
            <Spinner size={24} color="white" />
          </HStack>
        ) : (
          <HStack alignItems="center" justifyContent="center" fullHeight={true}>
            <Text variant="small">No Export</Text>
          </HStack>
        )}
      </AspectRatioContainer>
    </PreviewImgContainerDiv>
  )
}

const PreviewImgContainerDiv = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 16/9;
  background-color: #1d1d1f;
  /* background-color: #23262f; */
  /* border-radius: 16px; */

  img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
  }
`

const GridItemContainer = styled.div<{ $selected: boolean }>`
  border-radius: 16px;
  border: 1px solid
    ${({ $selected, theme }) =>
      $selected ? theme.colors.neutrals.n3 : theme.colors.neutrals.n6};
  box-shadow: ${({ theme }) => theme.elevation.depth2};
  overflow: hidden;
  background-color: ${({ $selected, theme }) =>
    $selected ? theme.colors.neutrals.n7 : theme.colors.neutrals.n8};

  cursor: pointer;
  transition: box-shadow 0.2s ease-in-out;
  margin-bottom: 8px;

  &:hover {
    box-shadow: ${({ theme }) => theme.elevation.depth3};
  }
`
