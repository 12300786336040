import { useMemo } from 'react'
import SimpleBar from 'simplebar-react'

import { ValorantMomentHighlight } from '../hooks'
import { ValorantPlaylistItem } from './item'

interface Props {
  highlights: ValorantMomentHighlight[]
  selectedHighlight?: ValorantMomentHighlight
  setSelectedHighlight: (nextHighlight: ValorantMomentHighlight) => void
}

export const ValorantMomentsPlaylist = ({
  selectedHighlight,
  setSelectedHighlight,
  highlights,
}: Props) => {
  const simplebarStyles = useMemo(
    () => ({ height: '100%', paddingRight: 16 }),
    []
  )
  return (
    <SimpleBar style={simplebarStyles}>
      {highlights.map((highlight, idx) => (
        <ValorantPlaylistItem
          key={idx}
          highlight={highlight}
          selectedHighlight={selectedHighlight}
          setSelectedHighlight={setSelectedHighlight}
        />
      ))}
    </SimpleBar>
  )
}
