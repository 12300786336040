import { useCallback, useEffect } from 'react'
import momentjs from 'moment'
import useLocalStorageState from 'use-local-storage-state'

import { useEditor } from 'core/editor/hooks'
import { useEditorState } from 'core/editor/context'
import { ReactComponent as StepForwardIcon } from 'icons/svgs/solid/step-forward.svg'
import { ReactComponent as SyncIcon } from 'icons/svgs/solid/sync.svg'
import { ReactComponent as StepBackwardIcon } from 'icons/svgs/solid/step-backward.svg'
import { ReactComponent as BanIcon } from 'icons/svgs/solid/ban.svg'
import { ReactComponent as LockIcon } from 'icons/svgs/solid/lock.svg'
import { ReactComponent as LockOpenIcon } from 'icons/svgs/solid/lock-open.svg'
import { ReactComponent as FlaskIcon } from 'icons/svgs/solid/flask.svg'

import { useValorantMatchEditorState } from './context'
import { SyncPlayerButtonDiv, VideoSyncControlsDiv } from './styled'
import { useRemoteMatchStartTime, useRemoteMatchSync } from './hooks'
import { useTimelineHelpers } from 'core/editor/timeline/hooks'
import { Text } from 'components/core/text'
import { useTimelineState } from 'core/editor/timeline/context'
import { useValorantMatchPreviewData } from '.'
import { RootOnly } from 'core/auth/components'
// import { useGetVideoSrcUrlsQuery } from '__generated__'

interface Props {
  videoId: string
}

export const ValorantVideoSyncControls = ({ videoId }: Props) => {
  // const { data: videoSrcData } = useGetVideoSrcUrlsQuery({
  //   variables: { videoId },
  // })
  const { relativeSeekTo, seekTo } = useEditor()
  const { centerTimelineAtTime } = useTimelineHelpers()
  const { currentTime } = useEditorState()
  const { startTime } = useTimelineState()
  const { matchId, primaryVideoId } = useValorantMatchEditorState()
  // take this out after testing
  const { videoData } = useValorantMatchPreviewData(matchId)

  const { data: matchStartTimes } = useRemoteMatchStartTime(matchId)
  const { offset, setMatchStartTime, clearMatchData } = useRemoteMatchSync(
    matchId,
    videoId,
    primaryVideoId
  )

  // const srcUrl = useMemo(
  //   () =>
  //     videoSrcData?.video?.vod.find(({ resolution }) => resolution === 'best')
  //       ?.url ?? '',
  //   [videoSrcData?.video?.vod]
  // )

  const [syncing, setSyncing] = useLocalStorageState('sync-mode', false)

  const experimentalSync = useCallback(async () => {
    const vidData = videoData?.find(({ videoId: vidId }) => vidId === videoId)
    if (!vidData) return
    // usually loads in ~100s
    const startsAt =
      (startTime - momentjs(vidData.videoStart).valueOf()) / 1000 + 90
    // before stream
    if (startsAt < 0) {
      return
    }
    // const test = `./node_modules/.bin/ts-node src/main.ts -u ${srcUrl} -r 2 -s ${startsAt} -d 70 -c 1 --debug`
    // console.log(test)
    // return

    const resp = await fetch(
      `http://localhost:3333/?videoId=${videoId}&startsAt=${startsAt}`
    )
    if (resp.status === 200) {
      const jsonData = await resp.json()
      seekTo(jsonData.startTime * 1000 + momentjs(vidData.videoStart).valueOf())
    }
  }, [seekTo, startTime, videoData, videoId])

  const setSync = useCallback(() => {
    let nextSync = currentTime
    // if there is a previous offset set, we should take
    // that into consideration
    if (offset) {
      nextSync -= offset
    }
    setMatchStartTime(nextSync)
  }, [currentTime, offset, setMatchStartTime])

  const onKeyDown = useCallback(
    (evt: KeyboardEvent) => {
      switch (evt.key) {
        case '1':
          relativeSeekTo(-10 * 1000)
          break
        case '2':
          relativeSeekTo(10 * 1000)
          break
        case 'q':
          relativeSeekTo(-5 * 1000)
          break
        case 'w':
          relativeSeekTo(5 * 1000)
          break
        case 'a':
          relativeSeekTo(-1 * 1000)
          break
        case 's':
          relativeSeekTo(1 * 1000)
          break
        case 'z':
          relativeSeekTo(-1000 / 30)
          break
        case 'x':
          relativeSeekTo(1000 / 30)
          break
        case 'c':
          relativeSeekTo(-1000 / 60)
          break
        case 'v':
          relativeSeekTo(1000 / 60)
          break
        case '~':
          setSync()
          break

        default:
          break
      }
    },
    [relativeSeekTo, setSync]
  )

  useEffect(() => {
    if (syncing) {
      document.addEventListener('keydown', onKeyDown, false)
      return () => document.removeEventListener('keydown', onKeyDown, false)
    }
  }, [onKeyDown, syncing])

  const stepToStart = useCallback(() => {
    const attemptMatchStart = matchStartTimes[videoId]
    const guessMatchStart = Object.values(matchStartTimes)[0]
    let nextSeek = startTime
    if (attemptMatchStart) {
      nextSeek = attemptMatchStart
    } else if (guessMatchStart) {
      nextSeek = guessMatchStart
    }
    seekTo(nextSeek)
    centerTimelineAtTime(nextSeek)
    // jumpToNewSelection(startTime, startTime + 10000)
  }, [centerTimelineAtTime, matchStartTimes, seekTo, startTime, videoId])

  return (
    <VideoSyncControlsDiv $hasOffset={false}>
      <SyncPlayerButtonDiv onClick={() => setSyncing((current) => !current)}>
        {syncing ? <LockIcon /> : <LockOpenIcon />}
      </SyncPlayerButtonDiv>
      <SyncPlayerButtonDiv onClick={stepToStart}>
        <StepBackwardIcon />
      </SyncPlayerButtonDiv>

      {syncing ? (
        <>
          <SyncPlayerButtonDiv onClick={() => relativeSeekTo(-1 * 1000)}>
            <StepBackwardIcon />
            <Text variant="small" mono={true}>
              1
            </Text>
          </SyncPlayerButtonDiv>

          <SyncPlayerButtonDiv onClick={() => relativeSeekTo(-1000 / 20)}>
            <StepBackwardIcon />
            <Text variant="small" mono={true}>
              1/30
            </Text>
          </SyncPlayerButtonDiv>
        </>
      ) : null}
      <SyncPlayerButtonDiv onClick={setSync} $active={offset !== undefined}>
        <SyncIcon />
      </SyncPlayerButtonDiv>
      {syncing ? (
        <>
          <SyncPlayerButtonDiv onClick={() => relativeSeekTo(1000 / 20)}>
            <Text variant="small" mono={true}>
              1/30
            </Text>
            <StepForwardIcon />
          </SyncPlayerButtonDiv>
          <SyncPlayerButtonDiv onClick={() => relativeSeekTo(1 * 1000)}>
            <Text variant="small" mono={true}>
              1
            </Text>
            <StepForwardIcon />
          </SyncPlayerButtonDiv>
          <SyncPlayerButtonDiv onClick={clearMatchData}>
            <BanIcon />
          </SyncPlayerButtonDiv>
          <RootOnly>
            <SyncPlayerButtonDiv onClick={experimentalSync}>
              <FlaskIcon />
            </SyncPlayerButtonDiv>
          </RootOnly>
        </>
      ) : null}
    </VideoSyncControlsDiv>
  )
}
