import React, { useCallback, useMemo } from 'react'
import momentjs from 'moment'
import styled from 'styled-components/macro'

import { DebugChatMomentFragment } from '__generated__'

import { useTimelineHelpers } from 'core/editor/timeline/hooks'
import { useEditorState } from 'core/editor/context'
import { Text } from 'components/core/text'

interface ReviewVideoMomentProps {
  momentData: DebugChatMomentFragment
}

export const ReviewVideoMoment: React.FC<ReviewVideoMomentProps> = ({
  momentData,
}) => {
  const { currentTime } = useEditorState()
  const { jumpToNewSelection } = useTimelineHelpers()

  const { startTime, endTime } = useMemo(
    () => ({
      startTime: momentjs(momentData.startsAt).valueOf(),
      endTime: momentjs(momentData.endsAt).valueOf(),
    }),
    [momentData.endsAt, momentData.startsAt]
  )

  const jumpToMoment = useCallback(() => {
    jumpToNewSelection(startTime, endTime, true)
  }, [endTime, jumpToNewSelection, startTime])

  const isActive = useMemo(() => {
    return currentTime <= endTime && currentTime >= startTime
  }, [currentTime, endTime, startTime])

  return (
    <MomentContainerDiv onClick={jumpToMoment} $active={isActive}>
      {isActive && (
        <ReviewVideoMomentProgress
          momentStart={startTime}
          momentEnd={endTime}
        />
      )}
      <MomentTitleDiv>
        <Text variant="text-3">
          {momentData.type} - {momentData.metadata.score.toFixed(2)}
        </Text>
        {isActive && (
          <ActivePreDiv>
            <Text variant="text-3" mono={true} as="pre">
              {JSON.stringify(momentData.metadata, null, 2)}
            </Text>
          </ActivePreDiv>
        )}
      </MomentTitleDiv>
    </MomentContainerDiv>
  )
}

const MomentContainerDiv = styled.div<{ $active: boolean }>`
  position: relative;
  margin: 4px;
  padding: 8px;
  border: 1px solid black;
  border-color: ${(p) => (p.$active ? `red` : `black`)};
  cursor: pointer;
`

const MomentTitleDiv = styled.div`
  position: relative;

  pre {
    font-size: 12px;
  }
`

const ActivePreDiv = styled.div`
  margin-top: 8px;
`

interface ReviewVideoMomentProgressProps {
  momentStart: number
  momentEnd: number
}

const ReviewVideoMomentProgress: React.FC<ReviewVideoMomentProgressProps> = ({
  momentStart,
  momentEnd,
}) => {
  const { currentTime } = useEditorState()

  const progressStyles = useMemo(() => {
    const length = momentEnd - momentStart
    return {
      width: `${((currentTime - momentStart) / length) * 100}%`,
    }
  }, [currentTime, momentEnd, momentStart])

  return (
    <ProgressContainerDiv>
      <div style={progressStyles} />
    </ProgressContainerDiv>
  )
}

const ProgressContainerDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  & > div {
    position: absolute;
    height: 100%;
    background-color: rgba(255, 0, 0, 0.25);
  }
`
