import React, { useCallback, useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { Card, Container, Content, Spacing } from 'components/core/layout'
import { Logo } from 'components/core/logo'
import { TextField } from 'components/core/textfield'
import { Button } from 'components/core/button'
import { useHistory } from 'react-router'

export const ClientLoginPage: React.FC = () => {
  const [password, setPassword] = useState('')
  const [error, setError] = useState(false)
  const history = useHistory()

  const onSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      if (password === 'client') {
        history.push('/client/campaigns/gillette')
      } else {
        setError(true)
      }
    },
    [password, history]
  )

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError(false)
    setPassword(event.target.value)
  }

  return (
    <Content>
      <Container alignItems="center" flex="1">
        <Logo />
      </Container>
      <StyledContainer alignSelf="center">
        <form onSubmit={onSubmit}>
          <Spacing top={128}>
            <Card>
              <Card.Body>
                <Card.Title>Client Login</Card.Title>
                <Card.Text>
                  Enter your password to login to your teams content hub
                </Card.Text>
                <Spacing top={16}>
                  <StyledTextField
                    label="Enter password"
                    name="password"
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={onChange}
                    error={error}
                  />
                </Spacing>
              </Card.Body>
              <Card.Footer alignItems="flex-end">
                <Button size="small">Log in</Button>
              </Card.Footer>
            </Card>
          </Spacing>
        </form>
      </StyledContainer>
    </Content>
  )
}

const StyledContainer = styled(Container)`
  max-width: 420px;
  width: 100%;
`

const StyledTextField = styled(TextField)<{ error?: boolean }>`
  ${({ error, theme }) =>
    error &&
    css`
      border: 2px solid ${theme.colors.rose};
      &:focus {
        border: 2px solid ${theme.colors.rose};
      }
    `}
`
