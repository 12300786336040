import styled, { css } from 'styled-components/macro'

type Props = {
  color?: string
  size?: number
}

export const Spinner = styled.div<Props>`
  ${({ size }) => css`
    width: ${size ? `${size}px` : '16px'};
    height: ${size ? `${size}px` : '16px'};
  `}

  background-color: transparent;
  border-radius: 50%;
  border: 2px solid ${({ color, theme }) => (color ? color : theme.colors.blue)};
  border-bottom-color: transparent;
  position: relative;
  animation: spinner 1s linear infinite;

  @keyframes spinner {
    to {
      transform: rotate(1turn);
    }
  }
`
