import { HStack } from 'components/core/layout'
import { Text } from 'components/core/text'
import { AGENT_THUMB_IMAGES } from 'components/valorant/agent'
import { ScoreboardEntry } from 'core/feed/components/scoreboard/hooks'
import { sortBy } from 'lodash'
import {
  AgentImage,
  PlayerNameText,
  PlayerScoreText,
  TeamContainer,
  TeamEntry,
} from './styled'

export const MatchIntroTeams = ({
  teams,
  highlightedAccountIds,
}: {
  teams: Record<string, ScoreboardEntry[]>
  highlightedAccountIds?: string[]
}) => {
  const allPlayers = Object.values(teams).flat()
  const playerByScore = sortBy(allPlayers, (player) => -player.stats.score)
  return (
    <TeamContainer>
      {playerByScore.map((player) => (
        <TeamEntry
          key={player.name}
          $highlighted={
            highlightedAccountIds !== undefined &&
            highlightedAccountIds.includes(player.accountId)
          }
        >
          <HStack alignItems="center" gap={16}>
            <AgentImage src={AGENT_THUMB_IMAGES[player.agent as string]} />
            <PlayerNameText variant="h4" color="white">
              {player.name}
            </PlayerNameText>
          </HStack>
          <HStack gap={8}>
            <PlayerScoreText
              textAlign="right"
              variant="h4"
              mono={true}
              color="white"
            >
              {player.stats.kills}
            </PlayerScoreText>
            <Text variant="h4" mono={true} color="white">
              -
            </Text>
            <PlayerScoreText
              textAlign="left"
              variant="h4"
              mono={true}
              color="white"
            >
              {player.stats.deaths}
            </PlayerScoreText>
          </HStack>
        </TeamEntry>
      ))}
    </TeamContainer>
  )
}
