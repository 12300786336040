import styled from 'styled-components/macro'
import { NavLink } from 'react-router-dom'

import { HStack } from 'components/core/layout'
import { fonts, fontWeights } from 'theme/typography'

import { useCurrentChannel } from './hooks'

const NavigationLink = styled(NavLink)`
  font-size: ${fonts.text['text-3']['font-size']};
  line-height: 32px;
  position: relative;
  font-weight: ${fontWeights.semibold};
  color: ${({ theme }) => theme.colors.neutrals.n3};
  padding: 0 8px;

  &.active {
    border-bottom: 2px solid ${({ theme }) => theme.colors.neutrals.n3};
  }
`

export const ChannelNavigation = () => {
  const { urls, hasValorantAccount } = useCurrentChannel()

  return (
    <HStack gap={8}>
      {hasValorantAccount && (
        <NavigationLink exact to={urls.valorant}>
          Valorant
        </NavigationLink>
      )}
      <NavigationLink to={urls.videos}>Videos</NavigationLink>
      {hasValorantAccount && (
        <NavigationLink to={urls.matches}>Matches</NavigationLink>
      )}
    </HStack>
  )
}
