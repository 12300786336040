import React from 'react'
import styled, { css } from 'styled-components/macro'

const formatUnit = (unit: string | number) =>
  typeof unit === 'number' ? `${unit}px` : unit

type Props = {
  gap?: React.CSSProperties['gap']
  alignItems?: React.CSSProperties['alignItems']
  justifyContent?: React.CSSProperties['justifyContent']
  flexWrap?: React.CSSProperties['flexWrap']
  fullWidth?: boolean
  fullHeight?: boolean
  reverse?: boolean
  flex?: React.CSSProperties['flex']
}
const stack = css<Props>`
  display: flex;
  ${({ flex }) => flex && `flex: ${flex};`}
  ${({ gap }) => gap && `gap: ${formatUnit(gap)};`}
  ${({ alignItems }) => alignItems && `align-items: ${alignItems};`}
  ${({ justifyContent }) =>
    justifyContent && `justify-content: ${justifyContent};`}
  ${({ flexWrap }) => flexWrap && `flex-wrap: ${flexWrap};`}
  ${({ fullWidth }) => fullWidth && `width: 100%;`}
  ${({ fullHeight }) => fullHeight && `height: 100%;`}
`

export const VStack = styled.div`
  ${stack}
  flex-direction: ${({ reverse = false }) =>
    !reverse ? 'column' : 'row-column'};
`

export const HStack = styled.div`
  ${stack}
  flex-direction: ${({ reverse = false }) =>
    !reverse ? 'row' : 'row-reverse'};
`
