
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Account": [
      "RiotAccount"
    ],
    "Asset": [
      "RecordingThumbnail",
      "RecordingVideo"
    ],
    "Channel": [
      "TwitchUser"
    ],
    "Edge": [
      "ChatMessage",
      "ChatMoment",
      "GroupedMoment",
      "ManualSignalMoment",
      "MultiPerspectiveMoment",
      "TwitchStream",
      "TwitchUser",
      "TwitchVideo",
      "ValorantMoment"
    ],
    "Moment": [
      "ChatMoment",
      "ManualSignalMoment",
      "MultiPerspectiveMoment",
      "ValorantMoment"
    ],
    "RelatedVideoReason": [
      "CustomReason",
      "PlayedInValorantMatches"
    ],
    "Stream": [
      "TwitchStream"
    ],
    "StreamConnection": [
      "MixedStreamConnection",
      "TwitchStreamConnection"
    ],
    "Unread": [
      "ChatMoment",
      "ManualSignalMoment",
      "MultiPerspectiveMoment",
      "TwitchStream",
      "TwitchVideo",
      "ValorantMoment"
    ],
    "ValorantMatchParticipant": [
      "RiotAccountInValorantMatch",
      "ValorantMatchPlayer"
    ],
    "Video": [
      "TwitchVideo"
    ],
    "VideoConnection": [
      "MixedVideoConnection",
      "TwitchVideoConnection"
    ]
  }
};
      export default result;
    