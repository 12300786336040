import { Justifications } from 'core/composer/template-sidebar/addons/killfeed'
import styled from 'styled-components/macro'

export const KillFeedContainer = styled.div<{ $scale?: number }>`
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
`

export const KillFeedScaledContainer = styled.div<{
  $scale: number
  $top?: number
  $justify: Justifications
  $width?: number
  $height?: number
}>`
  position: absolute;
  width: ${({ $width }) => ($width ? `${$width}px` : '1080px')};
  height: ${({ $height }) => ($height ? `${$height}px` : '1920px')};
  display: flex;
  flex-direction: column;

  padding: 10px;
  transform-origin: top left;
  transform: scale(${({ $scale }) => $scale});
  pointer-events: none;
  padding: 0 ${({ $scale }) => $scale * 80}px; // padding for safe area
  padding-top: ${({ $top }) => ($top ? `${$top}px` : 0)};

  align-items: ${({ $justify }) =>
    $justify === Justifications.left
      ? 'flex-start'
      : $justify === Justifications.center
      ? 'center'
      : 'flex-end'};
`

export const KillFeedAgentScaledContainer = styled.div<{
  $scale: number
  $top?: number
  $justify: Justifications
  $width?: number
  $height?: number
}>`
  position: absolute;
  width: ${({ $width }) => ($width ? `${$width}px` : '1080px')};
  height: ${({ $height }) => ($height ? `${$height}px` : '1920px')};
  display: flex;
  flex-direction: row;

  padding: 10px;
  transform-origin: top left;
  transform: scale(${({ $scale }) => $scale});
  pointer-events: none;
  padding: 0 ${({ $scale }) => $scale * 80}px; // padding for safe area
  padding-top: ${({ $top }) => ($top ? `${$top}px` : 0)};

  align-items: flex-start;
  justify-content: ${({ $justify }) =>
    $justify === Justifications.left
      ? 'flex-start'
      : $justify === Justifications.center
      ? 'center'
      : 'flex-end'};
`

export const FeedItemContainer = styled.div`
  color: white;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 2px;
  margin-top: 4px;
  gap: 0px;
  font-size: 32px;
  font-weight: 400;
  color: white;
  overflow: hidden;
`

export const KillerItemContainer = styled.div`
  position: relative;
  background: linear-gradient(
    90deg,
    rgba(119, 203, 177, 0.5) 0%,
    rgba(119, 203, 177, 1) 80%,
    rgba(119, 203, 177, 1) 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  height: 46px;
  padding: 12px 0 12px 0;
  gap: 12px;
  border: 2px solid;
  border-image-slice: 1;
  border-width: 2px 0 2px 2px;
  border-image-source: linear-gradient(
    to right,
    rgba(244, 236, 131, 1) 0%,
    rgba(244, 236, 131, 0) 60%
  );
`

export const RightArrowDiv = styled.div`
  z-index: 1;
  content: '';
  position: absolute;
  right: -14px;
  top: -2px;
  bottom: 0;
  width: 0px;
  height: 0px;
  border-top: 23px solid transparent;
  border-bottom: 23px solid transparent;
  border-left: 14px solid #77cbb1;
`

export const RightArrowTwoDiv = styled.div`
  z-index: 1;
  content: '';
  position: absolute;
  right: -13px;
  top: -2px;
  bottom: 0;
  width: 0px;
  height: 0px;
  border-top: 23px solid transparent;
  border-bottom: 23px solid transparent;
  border-left: 14px solid #77cbb1;
`

export const RightArrowThreeDiv = styled.div`
  z-index: 1;
  content: '';
  position: absolute;
  right: -12px;
  top: -2px;
  bottom: 0;
  width: 0px;
  height: 0px;
  border-top: 23px solid transparent;
  border-bottom: 23px solid transparent;
  border-left: 14px solid #77cbb1;
`

export const WeaponImg = styled.img`
  height: 30px;
  transform: scaleX(-1);
`

export const LeftAgentImg = styled.img`
  height: 42px;
  width: 42px;
  filter: drop-shadow(0px 0px 6px rgba(244, 236, 131, 1));
`

export const RightAgentImg = styled.img`
  height: 46px;
  width: 46px;
  transform: scaleX(-1);
  /* filter: drop-shadow(0px 0px 17px rgba(244, 236, 131, 1)); */
`

export const VictimItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 46px;
  padding: 10px 0 10px 0;
  gap: 12px;
  padding-left: 30px;
  position: relative;

  background: linear-gradient(
    -90deg,
    rgba(244, 101, 95, 0.5) 0%,
    rgba(244, 101, 95, 1) 80%,
    rgba(244, 101, 95, 1) 100%
  );
  background: linear-gradient(45deg);
`
