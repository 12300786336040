import React, { useMemo } from 'react'
import { useEditor } from '../hooks'

import {
  PlayerButtonDiv,
  SelectionPlayerContainerDiv,
  SelectionPositioningDiv,
} from './styled'
import { ReactComponent as PlayButton } from 'icons/svgs/solid/play.svg'
import { ReactComponent as PauseButton } from 'icons/svgs/solid/pause.svg'
import { ReactComponent as StepBackwardButton } from 'icons/svgs/solid/step-backward.svg'
import { ReactComponent as RepeatButton } from 'icons/svgs/solid/repeat.svg'
import { useTimelineState } from './context'

export const SelectionPlayer: React.FC = () => {
  const { play, pause, playing, ready, restartSelection } = useEditor()
  const { selectionPct } = useTimelineState()

  const styles = useMemo(() => {
    if (selectionPct === undefined) {
      return {
        display: 'none',
      }
    }

    return {
      width: `${(selectionPct.endPct - selectionPct.startPct) * 100}%`,
      left: `${selectionPct.startPct * 100}%`,
    }
  }, [selectionPct])

  return (
    <SelectionPositioningDiv style={styles}>
      <SelectionPlayerContainerDiv>
        {playing ? (
          <PlayerButtonDiv onClick={pause}>
            <PauseButton />
          </PlayerButtonDiv>
        ) : (
          <PlayerButtonDiv onClick={play} $disabled={ready === false}>
            <PlayButton />
          </PlayerButtonDiv>
        )}
        <PlayerButtonDiv onClick={restartSelection}>
          <StepBackwardButton />
        </PlayerButtonDiv>
        <PlayerButtonDiv onClick={() => {}}>
          <RepeatButton />
        </PlayerButtonDiv>
      </SelectionPlayerContainerDiv>
    </SelectionPositioningDiv>
  )
}
