import { useMemo } from 'react'

import { useValorantMatchRoundData } from 'core/shared/valorant/hooks'
import {
  useGetValorantMatchesForVideoWithUserInfoQuery,
  useGetValorantMatchPageDetailQuery,
  ValorantMatchDetailFragment,
  ValorantMatchPlayerPreviewFragment,
} from '__generated__'
// import matchData from './match-data.json'

export const useKDAData = (videoId: string, startsAt: number) => {
  const { data: videoMatchesData } =
    useGetValorantMatchesForVideoWithUserInfoQuery({
      variables: { videoId },
    })

  const userData = useMemo(() => {
    return {
      displayName: videoMatchesData?.video?.user?.displayName,
      profileImageUrl: videoMatchesData?.video?.user?.profileImageUrl,
    }
  }, [
    videoMatchesData?.video?.user?.displayName,
    videoMatchesData?.video?.user?.profileImageUrl,
  ])

  const riotAccountIds = useMemo(
    () => videoMatchesData?.video?.user?.linkedAccounts.map((acc) => acc.puuid),
    [videoMatchesData?.video?.user?.linkedAccounts]
  )

  const matchId = useMemo(() => {
    const matches = videoMatchesData?.video?.valorantMatches
    if (!matches) return undefined
    return matches.find(
      (m) =>
        startsAt >= new Date(m.startedAt).valueOf() &&
        startsAt <= new Date(m.endedAt).valueOf() + 120 * 1000 // add some padding
    )?.id
  }, [startsAt, videoMatchesData?.video?.valorantMatches])

  const { data: matchDetailsData } = useGetValorantMatchPageDetailQuery({
    variables: { matchId: matchId ?? '' },
    skip: matchId === undefined,
  })
  const { expectedFirstRoundStart } = useValorantMatchRoundData(matchId)
  const playerRanks = useMemo(() => {
    return matchDetailsData?.valorantMatch?.players.reduce(
      (memo, p) => ({
        ...memo,
        [p.account.id]: p.competitiveTier,
      }),
      {}
    ) as { [accountId: string]: string } | undefined
  }, [matchDetailsData?.valorantMatch?.players])

  const playerInfo = useMemo(() => {
    return matchDetailsData?.valorantMatch?.players.find((pl) =>
      riotAccountIds?.includes(pl.account.id)
    )
  }, [matchDetailsData?.valorantMatch?.players, riotAccountIds])
  const accountId = useMemo(
    () => playerInfo?.account.id,
    [playerInfo?.account.id]
  )

  const gameName = useMemo(() => playerInfo?.gameName, [playerInfo?.gameName])
  const roundResults = useMemo(
    () => matchDetailsData?.valorantMatch?.roundResults,
    [matchDetailsData]
  )
  const agent = useMemo(() => playerInfo?.agent, [playerInfo?.agent])
  const playerStats = matchDetailsData?.valorantMatch?.players.reduce(
    (acc, pl) => {
      return {
        ...acc,
        [pl.account.id]: pl,
      }
    },
    {} as { [accountId: string]: ValorantMatchPlayerPreviewFragment }
  )
  const killData = useMatchKillsByPlayer(
    videoId,
    matchDetailsData?.valorantMatch,
    expectedFirstRoundStart ?? 0
  )

  return useMemo(
    () => ({
      syncedWithOffset: expectedFirstRoundStart !== undefined,
      accountId,
      playerKills: killData?.killsByPlayer,
      playerDeaths: killData?.deathsByPlayer,
      roundResults,
      gameName,
      playerStats,
      displayName: userData.displayName ?? undefined,
      profileImageUrl: userData.profileImageUrl ?? undefined,
      playerRanks,
      agent,
    }),
    [
      expectedFirstRoundStart,
      accountId,
      killData?.killsByPlayer,
      killData?.deathsByPlayer,
      roundResults,
      gameName,
      playerStats,
      userData.displayName,
      userData.profileImageUrl,
      playerRanks,
      agent,
    ]
  )
}

export type KillsData = {
  victim: { id: string; name: string }
  time: number
  round: number
  weapon?: string
  type?: string
}

export type DeathsData = {
  killer: { id: string; name: string }
  time: number
  weapon?: string
  type?: string
}

export type KillsByPlayer = {
  [gameName: string]: KillsData[]
}
export type DeathsByPlayer = {
  [gameName: string]: DeathsData[]
}

// We should rework this for useMemo
export const useGetKD = (
  accountId: string,
  currentTime: number | undefined,
  killsByPlayer: KillsByPlayer,
  deathsByPlayer: DeathsByPlayer
) => {
  let kills: KillsData[] = []
  let deaths: DeathsData[] = []
  const killsData = killsByPlayer[accountId]
  const deathsData = deathsByPlayer[accountId]

  if (currentTime && killsData) {
    kills = killsData.filter(
      (k) => currentTime >= k.time && accountId !== k.victim.id
    )
    deaths = deathsData.filter((k) => currentTime >= k.time)
  }

  return useMemo(
    () => ({ numKills: kills.length, numDeaths: deaths.length }),
    [deaths.length, kills.length]
  )
}

export const useFindAllKillsAndDeathsSlideshow = (
  killsByPlayer: KillsByPlayer,
  deathsByPlayer: DeathsByPlayer,
  accountId: string,
  startsAt: number,
  endsAt: number
) => {
  const killTimes = useMemo(
    () =>
      killsByPlayer[accountId]?.filter((k) => {
        return k.time >= startsAt && k.time <= endsAt
      }),
    [accountId, endsAt, killsByPlayer, startsAt]
  )

  const deathTimes = useMemo(
    () =>
      deathsByPlayer[accountId]?.filter((k) => {
        return k.time >= startsAt && k.time <= endsAt
      }),
    [accountId, deathsByPlayer, endsAt, startsAt]
  )

  return useMemo(() => {
    // if no kills/deaths happen, we should give the start time
    if (
      killsByPlayer[accountId] !== undefined &&
      deathsByPlayer[accountId] !== undefined &&
      killTimes.length === 0 &&
      deathTimes.length === 0
    ) {
      return [startsAt]
    } else {
      return [
        ...(killTimes?.map(({ time }) => time) ?? []),
        ...(deathTimes?.map(({ time }) => time) ?? []),
      ]
    }
  }, [
    accountId,
    deathTimes,
    deathsByPlayer,
    killTimes,
    killsByPlayer,
    startsAt,
  ])
}

// We should rework this for useMemo
export const useMatchKillsByPlayer = (
  videoId: string,
  matchData: ValorantMatchDetailFragment | undefined | null,
  expectedFirstRoundStart: number
) => {
  const syncedStart = matchData?.videoSyncData.find(
    (vsync) => vsync.video.id === videoId
  )?.matchStartTime

  const syncedStartMillis = new Date(syncedStart).valueOf()
  const matchStart = new Date(matchData?.startedAt).valueOf()
  const syncedMatchStart =
    matchStart + (syncedStartMillis - matchStart - expectedFirstRoundStart)

  return useMemo(() => {
    const killsByPlayer: KillsByPlayer = {}
    const deathsByPlayer: DeathsByPlayer = {}
    for (const round of matchData?.roundResults ?? []) {
      for (const playerRoundStats of round.playerStats ?? []) {
        const killerId = playerRoundStats?.player?.account.id
        for (const kill of playerRoundStats?.kills ?? []) {
          if (!killerId || !kill || !kill.victim?.account.id) continue // this shouldn't happen, ty ts
          if (!killsByPlayer[killerId]) killsByPlayer[killerId] = []
          if (!deathsByPlayer[kill.victim.account.id])
            deathsByPlayer[kill.victim.account.id] = []
          deathsByPlayer[kill.victim?.account.id ?? '']?.push({
            killer: {
              id: killerId,
              name: playerRoundStats?.player?.gameName ?? '',
            },
            time: syncedMatchStart + (kill?.timeSinceGameStartMillis ?? 0),
            weapon: kill.finishingDamage?.damageItem ?? undefined,
            type: kill.finishingDamage?.damageType ?? undefined,
          })
          killsByPlayer[killerId]?.push({
            victim: {
              id: kill.victim?.account.id ?? '',
              name: kill.victim?.gameName ?? '',
            },
            time: syncedMatchStart + (kill?.timeSinceGameStartMillis ?? 0),
            round: (round?.roundNum ?? 0) + 1,
            weapon: kill.finishingDamage?.damageItem ?? undefined,
            type: kill.finishingDamage?.damageType ?? undefined,
          })
        }
      }
    }
    return {
      killsByPlayer,
      deathsByPlayer,
    }
  }, [matchData?.roundResults, syncedMatchStart])
}
