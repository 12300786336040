import { TextField } from 'components/core/textfield'
import useDebounce from 'hooks/use-debounce'
import React, { useEffect } from 'react'
import { usePrevious } from 'utils'
import { useUpdateMomentEditMutation } from '__generated__'
import { useExporterState } from '../context'
import { useExporterHelper } from '../hooks'
import { AddScene } from './add'
import { SceneDisplay } from './scene'
import { SceneListContainerDiv } from './styled'

interface SceneListProps {
  editId: string
}

export const SceneList: React.FC<SceneListProps> = ({ editId }) => {
  const { scenes } = useExporterState()
  return (
    <SceneListContainerDiv>
      {scenes.map((scene, idx) => (
        <SceneDisplay key={idx} idx={idx} scene={scene} editId={editId} />
      ))}
      <AddScene editId={editId} />
      <SaveEdit editId={editId} />
    </SceneListContainerDiv>
  )
}

interface SaveEditProps {
  editId: string
}

export const SaveEdit: React.FC<SaveEditProps> = ({ editId }) => {
  const { title } = useExporterState()
  const { setTitle } = useExporterHelper()
  const debouncedSetTitle = useDebounce(title, 250)
  const [mutation] = useUpdateMomentEditMutation()
  const lastTitle = usePrevious(title)

  useEffect(() => {
    if (editId && mutation && lastTitle !== undefined && title !== lastTitle) {
      mutation({ variables: { editId, editInput: { title } } })
    }
  }, [debouncedSetTitle, editId, lastTitle, mutation, title])

  return (
    <div>
      <TextField
        label="Title"
        value={title}
        onChange={(evt) => setTitle(evt.currentTarget.value)}
      />
      {/* <div>
        {editId ? <button onClick={updateEdit}>Update Edit</button> : null}
      </div> */}
    </div>
  )
}
