import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  AdminSearchResultFragment,
  useGetAdminSearchChannelLazyQuery,
  useUpdateTwitchUserChatStatusMutation,
  useUpdateValorantSyncStatusMutation,
} from '__generated__'

import { usePrevious } from 'utils'
import useDebounce from 'hooks/use-debounce'

const SEARCH_LIMIT = 10

export const useSearch = () => {
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 250)
  const prevDebouncedSearch = usePrevious(debouncedSearch)
  const [cursor, setCursor] = useState<string | null>(null)
  const [results, setResults] = useState<AdminSearchResultFragment[]>([])

  const [query, { data, loading }] = useGetAdminSearchChannelLazyQuery()
  const [updateChatStatus] = useUpdateTwitchUserChatStatusMutation()
  const [updateValorantSync] = useUpdateValorantSyncStatusMutation()

  useEffect(() => {
    if (prevDebouncedSearch !== debouncedSearch) {
      setCursor(null)
    }

    query({
      variables: {
        first: SEARCH_LIMIT,
        search: debouncedSearch.toLowerCase(),
        after: cursor,
      },
    })
  }, [debouncedSearch, query, cursor, prevDebouncedSearch])

  useEffect(() => {
    if (loading) {
      return
    }
    setResults(data?.channels?.data || [])
  }, [data, loading])

  const nextPage = useCallback(() => {
    if (!data?.channels?.pagination) {
      return
    }

    setCursor(data?.channels?.pagination.endCursor)
  }, [setCursor, data?.channels?.pagination])

  const prevPage = useCallback(() => {
    if (!cursor) {
      return
    }
    const offset = parseInt(cursor, 10)
    const newCursor = offset - SEARCH_LIMIT
    setCursor(`${newCursor}`)
  }, [setCursor, cursor])

  const hasNextPage = data?.channels?.pagination.hasNextPage || false
  const hasPrevPage = data?.channels?.pagination.hasPrevPage || false

  const onJoinChatChange = useCallback(
    (id: string, value: boolean) => {
      const index = results.findIndex((result) => result.id === id)
      if (index === -1) {
        return
      }
      const item = results[index]

      setResults((data) => [
        ...data.slice(0, index),
        {
          ...item,
          dontJoinChat: value,
        },
        ...data.slice(index + 1),
      ])

      updateChatStatus({ variables: { id, dontJoinChat: value } })
    },
    [results, updateChatStatus]
  )

  const onSyncValorantChange = useCallback(
    (id: string, value: boolean) => {
      const index = results.findIndex((result) => result.id === id)
      if (index === -1) {
        return
      }
      const item = results[index]

      setResults((data) => [
        ...data.slice(0, index),
        {
          ...item,
          autoSyncValorantMatches: value,
        },
        ...data.slice(index + 1),
      ])

      updateValorantSync({ variables: { id, autoSyncValorantMatches: value } })
    },
    [results, updateValorantSync]
  )

  const directHit = useMemo(
    () =>
      results.find(
        (result) => result.name.toLowerCase() === search.toLowerCase()
      ),
    [results, search]
  )

  return useMemo(
    () => ({
      search,
      setSearch,
      results,
      loading,
      nextPage,
      prevPage,
      hasNextPage,
      hasPrevPage,
      onJoinChatChange,
      onSyncValorantChange,
      directHit,
    }),
    [
      search,
      setSearch,
      results,
      loading,
      nextPage,
      prevPage,
      hasNextPage,
      hasPrevPage,
      onJoinChatChange,
      onSyncValorantChange,
      directHit,
    ]
  )
}
