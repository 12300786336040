import React from 'react'

import { durationFormatMinutes } from 'utils/time'
import { StreamerAvatarWithVideo } from 'components/streamer-avatar'
import { useEditorState } from 'core/editor/context'

import { useExporterState } from '../context'
import { AddSceneDisplayContainerDiv } from './styled'
import { useEditScenes } from './hooks'

interface AddSceneProps {
  editId: string
}

export const AddScene: React.FC<AddSceneProps> = ({ editId }) => {
  const { primaryVideoId } = useExporterState()
  const { selection } = useEditorState()
  const { addScene } = useEditScenes(editId)
  const length = selection
    ? durationFormatMinutes((selection.endTime - selection.startTime) / 1000)
    : 'N/A'

  return (
    <AddSceneDisplayContainerDiv>
      <div>
        <div>{length}</div>
        {primaryVideoId ? (
          <StreamerAvatarWithVideo
            videoId={primaryVideoId}
            width={40}
            height={40}
          />
        ) : null}
      </div>
      <button onClick={addScene}>Add Scene</button>
    </AddSceneDisplayContainerDiv>
  )
}
