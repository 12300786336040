import { Column, useRowSelect, useTable } from 'react-table'
import { useTheme } from 'styled-components/macro'
import { Text } from '../text'

import { StyledTable } from './styled'

type TableProps<T extends object = {}> = {
  data: Array<T>
  columns: Column<T>[]
}

export const Table = <T extends object>({
  data,
  columns,
}: TableProps<T>): JSX.Element => {
  const { getTableProps, rows, prepareRow, headerGroups } = useTable(
    {
      columns,
      data,
    },
    useRowSelect
  )

  const theme = useTheme()
  return (
    <StyledTable {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps({
                  style: {
                    width: column.width,
                    maxWidth: column.maxWidth,
                    minWidth: column.minWidth,
                  },
                })}
                style={{ width: column.width }}
              >
                <div>
                  <Text
                    weight={'regular'}
                    color={theme.colors.neutrals.n4}
                    variant="text-3"
                  >
                    {column.render('Header')}
                  </Text>
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>

      <tbody>
        {rows.map((row) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <td {...cell.getCellProps()}>
                    <div>{cell.render('Cell')}</div>
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </StyledTable>
  )
}
