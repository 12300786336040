import React from 'react'
import styled from 'styled-components/macro'
import { media } from 'theme/media'

type ContentWrapperProps = {
  backgroundColor: 'primary' | 'secondary' | 'tertiary'
}
const ContentWrapper = styled.div<ContentWrapperProps>`
  background-color: ${({ theme, backgroundColor }) =>
    theme.colors.background[backgroundColor]};
  flex-grow: 1;
`

const ContentContainer = styled.div`
  max-width: 1136px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 16px;

  ${media.phablet`
    flex-direction: column;
  `}
`

type Props = {
  background?: 'primary' | 'secondary' | 'tertiary'
}
export const Content: React.FC<Props> = ({
  background = 'primary',
  children,
  ...props
}) => (
  <ContentWrapper backgroundColor={background} {...props}>
    <ContentContainer>{children}</ContentContainer>
  </ContentWrapper>
)
