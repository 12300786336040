import React from 'react'

import Menu from './menu'
import MenuItem from './menu-item'
import Right from './styled/right'
import {
  NavigationBarWrapper,
  NavigationBarContainer,
} from './styled/navigation-bar'

interface NavigationBarCompound {
  Menu: typeof Menu
  MenuItem: typeof MenuItem
  Right: typeof Right
}

type Props = {}
const NavigationBar: React.FC<Props> & NavigationBarCompound = ({
  children,
}) => {
  return (
    <NavigationBarWrapper>
      <NavigationBarContainer>{children}</NavigationBarContainer>
    </NavigationBarWrapper>
  )
}

NavigationBar.Menu = Menu
NavigationBar.MenuItem = MenuItem
NavigationBar.Right = Right

export default NavigationBar
