import React, { createContext, useContext, useReducer } from 'react'

export enum BrowsingMode {
  CHAT = 'CHAT',
  VALORANT = 'VALORANT',
  MULTI = 'MULTI',
}

interface VideoExplorerState {
  videoId?: string
  browsingMode: BrowsingMode
  pregameLengthByMatch: { [matchId: string]: number }
}

const INITIAL_STATE: VideoExplorerState = {
  videoId: undefined,
  browsingMode: BrowsingMode.CHAT,
  pregameLengthByMatch: {},
}

enum ACTION_TYPES {
  SET_VIDEO_ID = 'SET_VIDEO_ID',
  SET_BROWSING_MODE = 'SET_BROWSING_MODE',
  SET_MATCH_PREGAME_LENGTH = 'SET_MATCH_PREGAME_LENGTH',
}

interface SetVideoIdAction {
  type: ACTION_TYPES.SET_VIDEO_ID
  payload: {
    videoId: string
  }
}

interface SetPregameLengthAction {
  type: ACTION_TYPES.SET_MATCH_PREGAME_LENGTH
  payload: {
    matchId: string
    length: number
  }
}

interface SetBrowsingModeAction {
  type: ACTION_TYPES.SET_BROWSING_MODE
  payload: {
    browsingMode: BrowsingMode
  }
}

type VideoActions =
  | SetVideoIdAction
  | SetPregameLengthAction
  | SetBrowsingModeAction

type Dispatch = (action: VideoActions) => void

const reducer = (
  state: VideoExplorerState,
  action: VideoActions
): VideoExplorerState => {
  switch (action.type) {
    case ACTION_TYPES.SET_VIDEO_ID: {
      const { videoId } = action.payload
      // temporarily lets load in pregame lengths from localstorage
      const storedPregameMap = localStorage.getItem(
        `pregameLengthsByMatch_v${videoId}`
      )
      const pregameLengthByMatch = storedPregameMap
        ? JSON.parse(storedPregameMap)
        : {}

      return {
        ...state,
        videoId,
        pregameLengthByMatch,
      }
    }

    case ACTION_TYPES.SET_MATCH_PREGAME_LENGTH: {
      const nextPregameLengthByMatch = {
        ...state.pregameLengthByMatch,
        [action.payload.matchId]: action.payload.length,
      }
      localStorage.setItem(
        `pregameLengthsByMatch_v${state.videoId}`,
        JSON.stringify(nextPregameLengthByMatch)
      )

      return {
        ...state,
        pregameLengthByMatch: nextPregameLengthByMatch,
      }
    }

    case ACTION_TYPES.SET_BROWSING_MODE: {
      const { browsingMode } = action.payload

      return {
        ...state,
        browsingMode,
      }
    }

    default:
      return state
  }
}

const VideoExplorerContext = createContext<VideoExplorerState | undefined>(
  undefined
)
const VideoExplorerUpdatedContext = createContext<Dispatch | undefined>(
  undefined
)

export const VideoExplorerProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE)

  return (
    <VideoExplorerContext.Provider value={state}>
      <VideoExplorerUpdatedContext.Provider value={dispatch}>
        {children}
      </VideoExplorerUpdatedContext.Provider>
    </VideoExplorerContext.Provider>
  )
}

export const useVideoExplorerState = () => {
  const context = useContext(VideoExplorerContext)
  if (context === undefined) {
    throw new Error(
      'useVideoExplorerState must be used within a VideoExplorerProvider'
    )
  }
  return context
}

export const useVideoExplorerUpdater = () => {
  const context = useContext(VideoExplorerUpdatedContext)
  if (context === undefined) {
    throw new Error(
      'useVideoExplorerUpdater must be used within a VideoExplorerProvider'
    )
  }
  return context
}

useVideoExplorerUpdater.ACTION_TYPES = ACTION_TYPES
