import { useCallback, useMemo, useState } from 'react'
import { OnChangeFunction } from '.'

export const useFilterGroup = (
  data: string[],
  callback: OnChangeFunction = () => {},
  isMultiSelect = true,
  collpased = true,
  selected: number[] = []
) => {
  const [checkedState, setCheckedState] = useState(
    Array.from<boolean>({ length: data.length }).map((_, i) =>
      selected.includes(i)
    )
  )
  const [_collapsed, setCollapsed] = useState(collpased)

  const onChange = useCallback(
    (i: number) => {
      return () => {
        const newChecked = checkedState.map((v, index) =>
          isMultiSelect ? (index === i ? !v : v) : index === i ? !v : false
        )
        setCheckedState(newChecked)

        callback(
          newChecked.reduce((acc, v, i) => ({ ...acc, [data[i]]: v }), {})
        )
      }
    },
    [callback, checkedState, data, isMultiSelect]
  )

  const checked = useCallback(
    (i: number) => {
      return checkedState[i]
    },
    [checkedState]
  )

  const toggleCollapsed = useCallback(() => {
    setCollapsed(!_collapsed)
  }, [_collapsed])

  const hasActiveSelection = useMemo(
    () => checkedState.some((v) => v),
    [checkedState]
  )

  return {
    onChange,
    checked,
    collapsed: _collapsed,
    toggleCollapsed,
    hasActiveSelection,
  }
}
