import { useCallback, useEffect, useMemo } from 'react'
import momentjs from 'moment'
import styled from 'styled-components/macro'

import { EditorProvider } from 'core/editor/context'
import { TimelineProvider } from 'core/editor/timeline/context'
import { ValorantMatchEditor } from 'core/valorant-match/editor'
import { ValorantMatchEditorProvider } from 'core/valorant-match/editor/context'
import { Portal } from 'components/dialog/portal'
import { Button } from 'components/core/button'
import {
  useValorantMultiPerspectiveMoments,
  valorantMomentIdHash,
} from 'page/feed/helpers'

interface Props {
  matchId: string
  userId: string
  start: string
  end: string
  onClose: () => void
}

export const EditValorantMomentModal = ({
  matchId,
  userId,
  start,
  end,
  onClose,
}: Props) => {
  const [_, setValorantMultiPerspectiveMoments] =
    useValorantMultiPerspectiveMoments()
  useEffect(() => {
    document.body.classList.add('hidden')
    return () => document.body.classList.remove('hidden')
  }, [])

  const onExport = useCallback(
    (multiPerspectiveId: string) => {
      const hashId = valorantMomentIdHash(userId, start, end)
      setValorantMultiPerspectiveMoments((currentState) => {
        let nextArr: string[] = []
        if (currentState[hashId]) {
          nextArr = [...currentState[hashId]]
        }
        nextArr = [...nextArr, multiPerspectiveId]
        return {
          ...currentState,
          [hashId]: nextArr,
        }
      })
      onClose()
    },
    [end, onClose, setValorantMultiPerspectiveMoments, start, userId]
  )
  const options = useMemo(() => ({ onExport }), [onExport])

  return (
    <Portal>
      <ModalRoot>
        <EditorContainerDiv>
          <EditorProvider>
            <TimelineProvider>
              <ValorantMatchEditorProvider>
                <ValorantMatchEditor
                  matchId={matchId}
                  userId={userId}
                  start={momentjs(start).valueOf()}
                  end={momentjs(end).valueOf()}
                  options={options}
                />
              </ValorantMatchEditorProvider>
            </TimelineProvider>
          </EditorProvider>
        </EditorContainerDiv>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </ModalRoot>
    </Portal>
  )
}

const ModalRoot = styled.div`
  position: fixed;
  inset: 0px;
  z-index: 1000;
`

const EditorContainerDiv = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`
