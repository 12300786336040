import { CollectionsMenu } from 'core/collections/menu'
import { useRouteMatch } from 'react-router'
import { CollectionsListContainer } from './styled'

export const SidebarView = () => {
  const { params } = useRouteMatch<{ organization: string }>()
  const slug = params.organization.toLowerCase()
  return (
    <CollectionsListContainer>
      <CollectionsMenu organizationSlug={slug} />
    </CollectionsListContainer>
  )
}
