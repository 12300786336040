import styled from 'styled-components/macro'

export const ModalContainerDiv = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  width: 600px;
  background-color: #353945;
  transform: translate(-50%, -50%);
  padding-top: 32px;
  border-radius: 12px;
`

export const ModalContentDiv = styled.div`
  position: relative;
  width: 100%;
  max-height: 600px;
  overflow: auto;
  padding: 0 32px 32px 32px;
`

export const TemplateContainerDiv = styled.div`
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  transition: transform 150ms ease-in-out, box-shadow 150ms ease-in-out;
  border-radius: 8px;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 8px;
    box-shadow: 0 0 0 0px red;
    transition: box-shadow 150ms ease-in-out;
  }

  &:hover {
    box-shadow: -6px 6px 10px -5px #000000;

    transform: translate(2px, -2px);
    &:after {
      box-shadow: inset 0 0 0 2px ${({ theme }) => theme.colors.white};
    }
  }
`

export const CropContainerDiv = styled.div`
  position: absolute;
`

export const TemplateSaveContainerDiv = styled.div`
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  transition: transform 150ms ease-in-out, box-shadow 150ms ease-in-out;
  border-radius: 8px;
  overflow: hidden;
`

export const LayerListDiv = styled.div`
  position: relative;
  width: 200px;
`

export const SaveTemplateDiv = styled.div`
  margin-top: 12px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  select {
    height: 32px;
    margin-right: 12px;
  }
`
