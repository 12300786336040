import { Collapse } from 'components/collapse'
import { Checkbox } from 'components/core/checkbox'
import { Text } from 'components/core/text'

import { Chevron } from './chevron'
import { useFilterGroup } from './hooks'
import {
  FilterGroupContainer,
  FilterGroupTitle,
  FilterList,
  FilterRow,
  FilterRowLabel,
  Icon,
} from './styled'

type Selection = { [key: string]: boolean }
export type OnChangeFunction = (data: Selection) => void

type Props = {
  data: string[]
  title: string
  collapsed?: boolean
  isMultiSelect?: boolean
  onChange?: OnChangeFunction
  selected?: number[]
}

export const FilterGroup = ({
  data,
  title,
  isMultiSelect = true,
  collapsed: _collapsed = true,
  onChange: onChangeCallback,
  selected,
}: Props) => {
  const { onChange, checked, collapsed, toggleCollapsed, hasActiveSelection } =
    useFilterGroup(data, onChangeCallback, isMultiSelect, _collapsed, selected)
  return (
    <FilterGroupContainer>
      <FilterGroupTitle
        indicator={hasActiveSelection}
        onClick={toggleCollapsed}
      >
        {title}
        <Icon>
          <Chevron up={!collapsed} />
        </Icon>
      </FilterGroupTitle>
      <Collapse open={!collapsed}>
        <FilterList>
          {data.map((v, i) => (
            <FilterRow key={`${i}`}>
              <label>
                <Checkbox checked={checked(i)} onChange={onChange(i)} />
                <FilterRowLabel>
                  <Text variant="text-3">{v}</Text>
                </FilterRowLabel>
              </label>
            </FilterRow>
          ))}
        </FilterList>
      </Collapse>
    </FilterGroupContainer>
  )
}
