import { useCallback, useEffect, useMemo, useRef } from 'react'
import videojs, { VideoJsPlayer } from 'video.js'

import { SyncStatus, useTimeRangePlayerState } from './context'
import { useTimeRangePlayerHelpers } from './helpers'

interface Props {
  src: string
}

export const TimeRangeVideoPlayer = ({ src }: Props) => {
  const player = useRef<VideoJsPlayer>()
  const { videoRef } = useTimeRangeVideoPlayer(player)

  useEffect(() => {
    const videoElement = videoRef.current
    const videoJsPlayer = videojs(videoElement, {
      autoplay: false,
      controls: false,
      muted: false,
    })

    const srcObj = { src, type: 'application/x-mpegURL' }
    videoJsPlayer.src([srcObj])

    // set the ref, since we need to dispose it
    player.current = videoJsPlayer
  }, [src, videoRef])

  useEffect(() => {
    return () => {
      player.current?.dispose()
      player.current = null
    }
  }, [])

  const containerStyles = useMemo(() => ({ width: '100%', height: '100%' }), [])

  return (
    <div data-vjs-player style={containerStyles}>
      <video ref={videoRef} className="video-js" />
    </div>
  )
}

const useTimeRangeVideoPlayer = (
  player: React.MutableRefObject<VideoJsPlayer>
) => {
  const {
    startTime,
    videoStartTime,
    status,
    playing,
    targetTime,
    playbackRate,
    autoPlay,
  } = useTimeRangePlayerState()
  const { setCurrentTime, setStatus, play } = useTimeRangePlayerHelpers()
  const videoRef = useRef<HTMLVideoElement>(null)

  const updateTime = useCallback(() => {
    const nextTime =
      (videoRef.current?.currentTime ?? 0) * 1000 + videoStartTime
    setCurrentTime(nextTime)
  }, [videoStartTime, setCurrentTime])

  // initial load and sync
  useEffect(() => {
    const loadStartEvent = () => {
      if (videoRef.current) {
        videoRef.current.currentTime = (startTime - videoStartTime) / 1000
      }
    }
    const vref = videoRef.current
    vref?.addEventListener('loadedmetadata', loadStartEvent)
    return () => vref?.removeEventListener('loadedmetadata', loadStartEvent)
  }, [player, startTime, videoStartTime])

  // loop that runs while "playing"
  useEffect(() => {
    if (playing) {
      const interval = setInterval(() => {
        updateTime()
      }, 25)
      return () => {
        clearInterval(interval)
      }
    }
  }, [playing, updateTime])

  // listens to play/pause actions from parent controller.
  useEffect(() => {
    const paused = videoRef.current?.paused
    if (paused !== undefined && playing !== !paused) {
      if (playing) {
        videoRef.current?.play()
      } else {
        videoRef.current?.pause()
      }
    }
  }, [playing])

  useEffect(() => {
    if (videoRef.current && autoPlay) {
      videoRef.current.oncanplaythrough = () => {
        play()
      }
    }
  }, [autoPlay, play])

  // updates the playback rate
  useEffect(() => {
    const videoElement = videoRef.current
    if (!videoElement) {
      return
    }
    videoElement.playbackRate = playbackRate
  }, [playbackRate])

  useEffect(() => {
    if (
      targetTime !== undefined &&
      status === SyncStatus.Seeking &&
      videoRef.current
    ) {
      videoRef.current.currentTime = (targetTime - videoStartTime) / 1000
      setStatus(SyncStatus.Ready)
    }
  }, [setStatus, status, targetTime, videoStartTime])

  return useMemo(
    () => ({
      videoRef,
      autoPlay,
    }),
    [autoPlay]
  )
}
