import { useParams } from 'react-router'
import { Composer } from 'core/composer'
import { TimelineProvider } from 'core/editor/timeline/context'
import { EditorProvider } from 'core/editor/context'
import { ComposerProvider } from 'core/composer/context'

export const ComposerPage = () => {
  const { momentId, editId } = useParams<{ momentId: string; editId: string }>()
  return (
    <ComposerProvider>
      <EditorProvider>
        <TimelineProvider>
          <Composer momentId={momentId} editId={editId} />
        </TimelineProvider>
      </EditorProvider>
    </ComposerProvider>
  )
}
