import {
  TimelineAreaContentsDiv,
  TimelineDetailAreaDiv,
  TimelineHeadingAreaDiv,
  TimelineOptionsAreaDiv,
  TogglePerspectivesButton,
} from 'core/shared/timeline/styled'
import { TimelinePlayerControls } from 'core/shared/timeline/controls'
import { TimelineDetail } from 'core/shared/timeline/detail'
import { PlayerTimelineHeading } from './heading'
import { SignalPlayerTimelineContentDiv } from 'core/signal-player/styled'
import { SignalPlayerChatTimeline } from 'core/signal-player/timeline/chat-timeline'
import { useComposerState } from '../context'
import { ValorantRoundDetailTimeline } from './valorant-rounds'
import { transparentize } from 'polished'
import { useTheme } from 'styled-components/macro'
import { useMemo, useState } from 'react'
import { HStack, Spacing } from 'components/core/layout'
import { Switch } from 'components/core/switch'
import { Text } from 'components/core/text'

interface Props {
  primaryVideoId: string
  videoIds: string[]
}

export const EditorTimeline = ({ videoIds, primaryVideoId }: Props) => {
  const theme = useTheme()
  const { valorantMatchId } = useComposerState()
  const [showAll, setShowAll] = useState<boolean>(true)

  const sortedVideoIds = useMemo(() => {
    return videoIds.sort((a, b) => {
      if (a === primaryVideoId) {
        return -1
      }
      if (b === primaryVideoId) {
        return 1
      }
      return 0
    })
  }, [videoIds, primaryVideoId])
  const filteredVideoIds = useMemo(
    () =>
      showAll
        ? sortedVideoIds
        : sortedVideoIds.filter((vidId) => vidId === primaryVideoId),
    [showAll, sortedVideoIds, primaryVideoId]
  )

  return (
    <TimelineAreaContentsDiv>
      <TimelineOptionsAreaDiv>
        <TimelinePlayerControls></TimelinePlayerControls>
      </TimelineOptionsAreaDiv>
      {videoIds.length > 1 && (
        <TogglePerspectivesButton
          color={transparentize(0.5, theme.colors.static.neutrals.n4)}
          onClick={(e) => {
            e.preventDefault()
            setShowAll((state) => !state)
          }}
        >
          <HStack alignItems="center" gap={8}>
            <Switch checked={showAll} onChange={() => {}} />
            <Spacing right={8}>
              <Text variant="small">Show All</Text>
            </Spacing>
          </HStack>
        </TogglePerspectivesButton>
      )}
      <TimelineHeadingAreaDiv>
        {/* <PlayerTimelineHeading videoId={primaryVideoId} /> */}
        {filteredVideoIds.map((videoId) => (
          <PlayerTimelineHeading key={videoId} videoId={videoId} />
        ))}
      </TimelineHeadingAreaDiv>
      <TimelineDetailAreaDiv>
        <TimelineDetail>
          <SignalPlayerTimelineContentDiv>
            {/* <SignalPlayerChatTimeline videoId={primaryVideoId} /> */}
            {filteredVideoIds.map((videoId) =>
              valorantMatchId ? (
                <ValorantRoundDetailTimeline key={videoId} videoId={videoId} />
              ) : (
                <SignalPlayerChatTimeline key={videoId} videoId={videoId} />
              )
            )}
          </SignalPlayerTimelineContentDiv>
        </TimelineDetail>
      </TimelineDetailAreaDiv>
    </TimelineAreaContentsDiv>
  )
}
