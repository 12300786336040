import React, { useCallback, useContext, useMemo, useState } from 'react'
import momentjs from 'moment'
import { get, sortBy } from 'lodash'

import { HStack, Spacing } from 'components/core/layout'
import { Text } from 'components/core/text'
import { ExportFormat, STANDARD_FORMAT } from 'core/exporter/constants'
import { ReactComponent as PlayCircleIcon } from 'icons/svgs/regular/play-circle.svg'
import { ReactComponent as CircleDownIcon } from 'icons/svgs/solid/arrow-alt-circle-down.svg'
import {
  PreviewEditFragment,
  RecordingStatus,
  useSetEditApprovalMutation,
} from '__generated__'
import { durationFormatMinutes } from 'utils/time'
import { useMomentViewerData } from 'core/moment-viewer/hooks'
import {
  DownloadEditDiv,
  EditOptionContainerDiv,
  EditOptionPlayIconDiv,
  EditOptionTimeDiv,
  EditOptionTitleDiv,
  EditSelectorDiv,
  FormatOptionDiv,
  FormatSelectorDiv,
} from 'core/moment-viewer/styled'
import { Spinner } from 'components/core/spinner'

import { MomentModalContext } from './moment-modal'

interface Props {
  includeUnapproved: boolean
}

export const EditSelector = ({ includeUnapproved }: Props) => {
  const { momentId, selectedEditId } = useContext(MomentModalContext)
  const { editsByFormat } = useMomentViewerData(
    momentId,
    selectedEditId ?? '',
    includeUnapproved
  )
  const [selectedFormat, setSelectedFormat] =
    useState<ExportFormat>(STANDARD_FORMAT)

  const availableEdits = useMemo(() => {
    const selectedEdits = editsByFormat.find(({ format }) => {
      return format.id === selectedFormat.id
    })

    return selectedEdits
      ? sortBy(
          selectedEdits.edits,
          ({ publishedAt }) => -new Date(publishedAt).getTime()
        )
      : undefined
  }, [editsByFormat, selectedFormat.id])

  return (
    <EditSelectorDiv>
      <FormatSelectorDiv>
        <HStack alignItems="center" justifyContent="space-between">
          {editsByFormat.map(({ format }) => (
            <FormatOptionDiv
              key={format.id}
              $selected={format.id === selectedFormat.id}
              onClick={() => setSelectedFormat(format)}
            >
              <Text variant="text-3">
                {format.aspectRatio.width} x {format.aspectRatio.height}
              </Text>
            </FormatOptionDiv>
          ))}
        </HStack>
      </FormatSelectorDiv>
      <Spacing vertical={8}>
        {availableEdits?.map((edit) => {
          return (
            <EditOption
              key={edit.id}
              editData={edit}
              showApproval={includeUnapproved}
            />
          )
        })}
      </Spacing>
    </EditSelectorDiv>
  )
}

interface EditOptionProps {
  editData: PreviewEditFragment
  showApproval: boolean
}

const EditOption: React.FC<EditOptionProps> = ({ editData }) => {
  const { selectedEditId, setSelectedEditId } = useContext(MomentModalContext)
  const [isDownloaded, setIsDownloaded] = useState(
    window.localStorage.getItem(`downloaded_${editData.id}`) === 'true'
  )

  const { isSelected, editLength, inProgress, downloadLink } =
    useEditOptionData(editData, selectedEditId, setSelectedEditId)

  const selectThisEdit = useCallback(() => {
    setSelectedEditId(editData.id)
  }, [editData.id, setSelectedEditId])

  return (
    <EditOptionContainerDiv $selected={isSelected} onClick={selectThisEdit}>
      <EditOptionPlayIconDiv>
        {inProgress ? <Spinner /> : <PlayCircleIcon />}
      </EditOptionPlayIconDiv>
      <EditOptionTitleDiv>
        <Text variant="text-3" as="p">
          {editData.title}
        </Text>
      </EditOptionTitleDiv>
      <EditOptionTimeDiv>
        <Text variant="text-3">{editLength}</Text>
      </EditOptionTimeDiv>
      <DownloadEditDiv
        onClick={() => {
          window.localStorage.setItem(`downloaded_${editData.id}`, 'true')
          setIsDownloaded(true)
        }}
        $downloaded={isDownloaded}
      >
        <a href={downloadLink}>
          <CircleDownIcon />
        </a>
      </DownloadEditDiv>
    </EditOptionContainerDiv>
  )
}

const useEditOptionData = (
  editData: PreviewEditFragment,
  selectedEditId: string | undefined,
  setSelectedEditId: (editId: string) => void
) => {
  const [setApproval] = useSetEditApprovalMutation()

  const isSelected = useMemo(
    () => selectedEditId === editData.id,
    [editData.id, selectedEditId]
  )

  const setEdit = useCallback(() => {
    setSelectedEditId(editData.id)
  }, [editData.id, setSelectedEditId])

  const editLength = useMemo(() => {
    return durationFormatMinutes(
      Math.ceil(
        editData.scenes.reduce((memo, scene) => {
          return (
            memo +
            (momentjs(scene.end).valueOf() - momentjs(scene.start).valueOf())
          )
        }, 0) / 1000
      )
    )
  }, [editData.scenes])

  const toggleApproval = useCallback(() => {
    setApproval({
      variables: { editId: editData.id, nextApproval: !editData.approved },
    })
  }, [editData.approved, editData.id, setApproval])

  const downloadLink = useMemo(() => {
    return get(editData, ['exports', 0, 'output', 'videos', 0, 'url'], '')
  }, [editData])

  const inProgress = [
    RecordingStatus.Inprogress,
    RecordingStatus.Created,
  ].includes(editData.exports[0]?.status)
  // const inProgress = editData.exports.some((v) =>
  //   [RecordingStatus.Inprogress, RecordingStatus.Created].includes(v.status)
  // )

  return useMemo(
    () => ({
      isSelected,
      isApproved: editData.approved,
      setEdit,
      editLength,
      downloadLink,
      toggleApproval,
      inProgress,
    }),
    [
      downloadLink,
      editData.approved,
      editLength,
      isSelected,
      setEdit,
      toggleApproval,
      inProgress,
    ]
  )
}
