import { useCallback, useMemo } from 'react'
import { useTheme } from 'styled-components/macro'

import { Button } from 'components/core/button'
import { Container, HStack, Spacing } from 'components/core/layout'
import { Spinner } from 'components/core/spinner'
import { Text } from 'components/core/text'
import { Dialog } from 'components/dialog'
import { Portal } from 'components/dialog/portal'
import { CustomCheckbox } from 'core/valorant-match/editor/exporting-modal'
import { useGetVideosInfoQuery } from '__generated__'

import { PlayerPerspectiveOptionsDiv } from '../styled'
import { useSignalReviewState } from '../context'
import { useSignalData, useSignalReviewHelpers } from '../helpers'
import { useEditorOffsets } from 'core/editor/hooks'

export const RelatedVideoSelectorModal = () => {
  const theme = useTheme()
  const { relatedVideoInfo, loading, closeModal, toggleVisibleRelatedVideo } =
    useRelatedVideoData()

  return (
    <Portal>
      <Dialog backdrop>
        <Dialog.Title>Show Other Perspectives</Dialog.Title>
        <>
          <Dialog.Body>
            <Container gap={16} direction="column">
              {loading && <Spinner size={18} color="white" />}
              {!loading && relatedVideoInfo && (
                <Spacing top={8}>
                  <PlayerPerspectiveOptionsDiv>
                    {relatedVideoInfo.map(({ active, data, offset }) => (
                      <HStack
                        key={data.id}
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <CustomCheckbox
                          checked={active}
                          onClick={() => {
                            toggleVisibleRelatedVideo(data.id)
                          }}
                        >
                          <Text variant="text-2">{data.user?.displayName}</Text>
                        </CustomCheckbox>
                        <Text variant="small" color={theme.colors.green}>
                          {offset ? offset.toFixed(2) : 0}
                        </Text>
                      </HStack>
                    ))}
                  </PlayerPerspectiveOptionsDiv>
                </Spacing>
              )}
            </Container>
          </Dialog.Body>
          <Dialog.Footer inset>
            <Container direction="row" gap={16} justifyContent="flex-end">
              <Button variant="secondary" onClick={closeModal}>
                Cancel
              </Button>
            </Container>
          </Dialog.Footer>
        </>
      </Dialog>
    </Portal>
  )
}

const useRelatedVideoData = () => {
  const { signalId, relatedVideoIds } = useSignalReviewState()
  const offsetByVideoId = useEditorOffsets()
  const { setSelectedRelatedVideoIds, setShowRelatedVideoModal } =
    useSignalReviewHelpers()
  const signalData = useSignalData(signalId)

  const allRelatedVideoIds = useMemo(() => {
    return signalData?.data.relatedVideoData.map(({ id }) => id)
  }, [signalData?.data.relatedVideoData])
  const { data, loading } = useGetVideosInfoQuery({
    variables: { videoIds: allRelatedVideoIds ?? [] },
    skip: allRelatedVideoIds === undefined,
  })

  const relatedVideoInfo = useMemo(() => {
    if (!data || allRelatedVideoIds?.length === 0) return undefined

    return data.videos?.data.map((dataFragment) => ({
      active: relatedVideoIds.includes(dataFragment.id),
      data: dataFragment,
      offset: offsetByVideoId[dataFragment.id],
    }))
  }, [allRelatedVideoIds?.length, data, offsetByVideoId, relatedVideoIds])

  const closeModal = useCallback(() => {
    setShowRelatedVideoModal(false)
  }, [setShowRelatedVideoModal])

  const toggleVisibleRelatedVideo = useCallback(
    (videoId: string) => {
      const idx = relatedVideoIds.indexOf(videoId)
      let nextRelatedVideoIds: string[] = []
      if (idx === -1) {
        nextRelatedVideoIds = [...relatedVideoIds, videoId]
      } else {
        nextRelatedVideoIds = relatedVideoIds.filter(
          (vidId) => vidId !== videoId
        )
      }
      setSelectedRelatedVideoIds(nextRelatedVideoIds)
    },
    [setSelectedRelatedVideoIds, relatedVideoIds]
  )

  return useMemo(
    () => ({
      relatedVideoInfo,
      loading,
      closeModal,
      toggleVisibleRelatedVideo,
    }),
    [relatedVideoInfo, loading, closeModal, toggleVisibleRelatedVideo]
  )
}
