import { Collapse } from 'components/collapse'
import { Content, HStack, Spacing } from 'components/core/layout'
import { Spinner } from 'components/core/spinner'
import { Text } from 'components/core/text'
import { MomentFeedFilters } from 'core/feed/components/filters/moments-filters'
import { allKillOptions } from 'core/feed/components/filters/options'
import { ReactionFeedFilters } from 'core/feed/components/filters/reaction-filters'
import { SectionToggleTitle } from 'core/feed/components/filters/section-toggle'
import { ValorantFeedFilters } from 'core/feed/components/filters/valorant-filters'
import { FeedProvider } from 'core/feed/context'
import { useFeedHelpers } from 'core/feed/helpers'
import { MultiFeed } from 'core/feed/multi-feed'
import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router'
import { useQueryParamFilters } from 'utils/browser'
import { useGetMatchQuery } from '__generated__'
import { useCurrentChannel } from '../hooks'
import { ChannelNavigation } from '../navigation'
import { Sidebar } from '../sidebar'
import { ChannelContent, MainContent } from '../styled'
import { includeKey, useIncludeFilters } from './hooks'
import { MatchCard } from './match-card'

export const ChannelMatchDetailPage = () => {
  const { data: channelData } = useCurrentChannel()
  const { params, setParam } = useQueryParamFilters()
  const { matchId } = useParams<{ matchId: string }>()
  const { valorant, toggleValorant, chat, toggleChat } = useIncludeFilters()

  useEffect(() => {
    const currentParams = params[includeKey] as string[] | undefined
    if (!currentParams) {
      setParam(includeKey, ['valorant', 'chat'])
    }
  }, [params, setParam])

  const accountIds = useMemo(() => {
    return channelData?.channel?.linkedAccounts.map(({ id }) => id)
  }, [channelData?.channel?.linkedAccounts])

  const valorantFilterSettings = useMemo(
    () => ({
      minKills: { include: true, default: '2', options: allKillOptions },
      agents: { include: false },
      maps: { include: false },
    }),
    []
  )

  return (
    <Content background={'secondary'}>
      <HStack gap={16}>
        <FeedProvider>
          <Sidebar>
            <MomentFeedFilters />
            <Spacing vertical={8}>
              <SectionToggleTitle
                title="Valorant"
                checked={valorant}
                onClick={toggleValorant}
              />
            </Spacing>
            <Collapse open={valorant}>
              <ValorantFeedFilters settings={valorantFilterSettings} />
            </Collapse>
            <Spacing top={24} bottom={8}>
              <SectionToggleTitle
                title="Chat"
                checked={chat}
                onClick={toggleChat}
              />
            </Spacing>
            <Collapse open={chat}>
              <ReactionFeedFilters />
            </Collapse>
          </Sidebar>
          <MainContent>
            <ChannelContent>
              <ChannelNavigation />
              <Spacing top={16}>
                <MatchCard
                  disabled
                  matchId={matchId}
                  accountIds={accountIds ?? []}
                />
              </Spacing>
              <Spacing top={16}>
                <MatchFeed matchId={matchId} />
              </Spacing>
            </ChannelContent>
          </MainContent>
        </FeedProvider>
      </HStack>
    </Content>
  )
}

interface Props {
  matchId: string
}

const MatchFeed = ({ matchId }: Props) => {
  const { data: channelData } = useCurrentChannel()
  const { setChannelIds } = useFeedHelpers()
  const { data: matchData } = useGetMatchQuery({ variables: { matchId } })

  const additionalFilters = useMemo(() => {
    if (matchData?.valorantMatch) {
      return {
        startsAt: {
          from: matchData.valorantMatch.startedAt,
          to: matchData.valorantMatch.endedAt,
        },
      }
    }
    return undefined
  }, [matchData?.valorantMatch])

  useEffect(() => {
    if (!channelData?.channel?.id) {
      return
    }
    setChannelIds([channelData.channel.id])
  }, [channelData?.channel?.id, setChannelIds])

  return (
    <>
      <Spacing bottom={8}>
        <HStack alignItems="center" gap={8}>
          <Text variant="text-2" weight="bold">
            Feed
          </Text>
        </HStack>
      </Spacing>
      {additionalFilters ? (
        <MultiFeed additionalFilters={additionalFilters} hideChannel={true} />
      ) : (
        <Spacing top={32}>
          <HStack justifyContent="center" fullWidth>
            <Spinner size={48} />
          </HStack>
        </Spacing>
      )}
    </>
  )
}
