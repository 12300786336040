import styled from 'styled-components/macro'

export const SidebarContainerDiv = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-areas:
    'data'
    'controls';
  overflow: hidden;
`

export const MetaDataContentDiv = styled.div`
  width: 100%;
  grid-area: data;
  overflow: hidden;

  display: flex;
  flex-direction: column;
`

export const ControlsDiv = styled.div`
  width: 100%;
  grid-area: controls;
`
