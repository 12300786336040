import React, { useCallback, useMemo } from 'react'

import { Rect, TemplateCropSettings } from 'core/exporter/constants'
import { useTemplateEditorState } from './context'
import { TemplateEditorPreviewCropDiv } from './styled'
import { useTemplateEditorHelpers } from './hooks'
import {
  CropRestrictions,
  useCropDrag,
  useCropResize,
} from 'core/shared/cropping/hooks'
import { CropResizeSquareDiv } from 'core/exporter/template/styled'
import { ContentPreview } from './content-preview'

interface PreviewCropProps {
  templateCrop: TemplateCropSettings
  containerRect: DOMRect
}

export const PreviewCrop: React.FC<PreviewCropProps> = ({
  templateCrop,
  containerRect,
}) => {
  const {
    selected,
    styles,
    selectThisTemplateCrop,
    resizeRef,
    dragRef,
    dragging,
  } = usePreviewCrop(templateCrop, containerRect)

  const selectTemplateCrop = useCallback(
    (evt: React.MouseEvent<HTMLDivElement>) => {
      if (!selected) {
        selectThisTemplateCrop(evt)
      }
    },
    [selectThisTemplateCrop, selected]
  )

  return (
    <TemplateEditorPreviewCropDiv
      ref={dragRef}
      style={styles}
      onClick={selectTemplateCrop}
      $selected={selected}
      $dragging={dragging}
    >
      <ContentPreview
        templateCrop={templateCrop}
        containerRect={containerRect}
      />
      {selected ? (
        <CropResizeSquareDiv ref={resizeRef} $editing={selected} />
      ) : null}
    </TemplateEditorPreviewCropDiv>
  )
}

const usePreviewCrop = (
  templateCrop: TemplateCropSettings,
  containerRect: DOMRect
) => {
  const { selectedCrop } = useTemplateEditorState()
  const { selectTemplateCrop, setCropRect } = useTemplateEditorHelpers()

  const selected = useMemo(
    () => selectedCrop?.title === templateCrop.title,
    [selectedCrop?.title, templateCrop.title]
  )

  const setNextCropRect = useMemo(() => {
    return (nextRect: Rect) => setCropRect(templateCrop.title, nextRect)
  }, [setCropRect, templateCrop.title])

  const selectThisTemplateCrop = useCallback(
    (evt: React.MouseEvent<HTMLDivElement>) => {
      evt.preventDefault()
      selectTemplateCrop(templateCrop)
    },
    [selectTemplateCrop, templateCrop]
  )

  const styles = useMemo(() => {
    const { x, y, width, height } = templateCrop.position
    return {
      width: `${width * 100}%`,
      height: `${height * 100}%`,
      left: `${x * 100}%`,
      top: `${y * 100}%`,
    }
  }, [templateCrop.position])

  const restrictions: CropRestrictions = useMemo(() => {
    if (
      templateCrop.aspectRatio.width === 0 &&
      templateCrop.aspectRatio.height === 0
    ) {
      return {}
    }
    return {
      aspectRatio: templateCrop.aspectRatio,
    }
  }, [templateCrop.aspectRatio])

  const { ref: dragRef, dragging } = useCropDrag(
    templateCrop.position,
    containerRect,
    setNextCropRect,
    selected
  )
  const { ref: resizeRef } = useCropResize(
    templateCrop.position,
    containerRect,
    setNextCropRect,
    restrictions,
    selected
  )

  return useMemo(
    () => ({
      selectThisTemplateCrop,
      selected,
      styles,
      dragRef,
      resizeRef,
      dragging,
    }),
    [dragRef, dragging, resizeRef, selectThisTemplateCrop, selected, styles]
  )
}
