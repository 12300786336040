import React, { useEffect } from 'react'
import { CLIENT_TOKEN_KEY } from './constants'
import {
  AuthorizationAction,
  useAuthorizationContext,
  useAuthorizationDispatch,
} from './context'

export const ClientAuthProvider: React.FC = ({ children }) => {
  const { accessToken } = useAuthorizationContext()
  const dispatch = useAuthorizationDispatch()
  useEffect(() => {
    const clientToken = localStorage.getItem(CLIENT_TOKEN_KEY)
    if (clientToken) {
      if (clientToken !== accessToken) {
        dispatch({
          type: AuthorizationAction.SetAccessToken,
          payload: { accessToken: clientToken, provider: 'custom' },
        })
      }
    }
  }, [accessToken, dispatch])
  return <>{children}</>
}
